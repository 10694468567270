import { useAppSelector } from '@store/hooks';
import { Link } from 'react-router-dom';
import { routes } from '@common/utils/route-names';
import logo from '@assets/images/logo.svg';
import styles from './logo.module.scss';

const Logo = ({ className = '' }: ClassName) => {
  const customerId = useAppSelector(state => state.customer.id);

  return (
    <>
      {customerId ? (
        <Link to={routes.dashboard} className={`${styles.container} ${className}`}>
          <picture className={`${styles.logo}`}>
            <img src={logo} alt="logo" />
          </picture>
        </Link>
      ) : (
        <div className={`${styles.container} ${className}`}>
          <picture className={`${styles.logo}`}>
            <img src={logo} alt="logo" />
          </picture>
        </div>
      )}
    </>
  );
};

export default Logo;
