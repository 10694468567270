/* eslint-disable */
import Modal from '@common/ui/Modal/Modal';
import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { MotionDiv } from '@pages/tables/layouts/web-portal/components/modals/RowModal/shared';
import Button from '@common/ui/Button/Button';
import { SessionTimeoutProps } from './types';
import styles from './session.module.scss';

const SessionTimeout: FC<SessionTimeoutProps> = ({ isModalOpen, customCloseFunc }) => {
  return (
    <>
      {isModalOpen && (
        <Modal
          className={styles.modal}
          primaryButton
          hasHeader={false}
          hasFooter={false}
          // helpLocation="vehicle_"
        >
          <AnimatePresence exitBeforeEnter initial={false}>
            <MotionDiv activeTitle="activeTitle" prevTitle="prevTitle">
              <div className={styles['modal-content']}>
                <p className={styles['header']}>Session Timeout</p>
                <div className={styles['body']}>
                  Your session has expired due to inactivity. To continue using this application,
                  please log in again.
                </div>
                <Button
                  className={styles['login-btn']}
                  appearance="button"
                  variant="primary"
                  label="Login"
                  size="s"
                  // onClick={() => navigate(routes.login, { replace: true })}
                  onClick={customCloseFunc}
                />
              </div>
            </MotionDiv>
          </AnimatePresence>
        </Modal>
      )}
    </>
  );
};

export default SessionTimeout;
