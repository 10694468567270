export function VehicleName({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 8"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 5.5H1C0.734784 5.5 0.48043 5.60536 0.292893 5.79289C0.105357 5.98043 0 6.23478 0 6.5C0 6.76522 0.105357 7.01957 0.292893 7.20711C0.48043 7.39464 0.734784 7.5 1 7.5H11C11.2652 7.5 11.5196 7.39464 11.7071 7.20711C11.8946 7.01957 12 6.76522 12 6.5C12 6.23478 11.8946 5.98043 11.7071 5.79289C11.5196 5.60536 11.2652 5.5 11 5.5ZM19 0.5H1C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 1.76522 0.105357 2.01957 0.292893 2.20711C0.48043 2.39464 0.734784 2.5 1 2.5H19C19.2652 2.5 19.5196 2.39464 19.7071 2.20711C19.8946 2.01957 20 1.76522 20 1.5C20 1.23478 19.8946 0.98043 19.7071 0.792893C19.5196 0.605357 19.2652 0.5 19 0.5Z" />
    </svg>
  );
}
