/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const errorMessage = (error: unknown | any) => {
  return (
    (error.data.displayedErrorMessage as string) ||
    (error.data.message as string) ||
    (error.data.errorMessage as string) ||
    (error.errorMessage as string) ||
    (error.data as string) ||
    (error as string)
  );
};
