import { FC } from 'react';
import styles from './custom-header.module.scss';
import SelectionBox from './SelectionBox';
import { CustomHeaderProps } from './types';

const CustomHeader: FC<CustomHeaderProps> = ({ date, months, years, changeMonth, changeYear }) => {
  return (
    <section className={styles.container}>
      <SelectionBox
        values={months}
        selected={months[date.getMonth()]}
        handleSelection={value => changeMonth(months.indexOf(value.toString()))}
        placeholder="month"
      />
      <SelectionBox
        values={years}
        selected={date.getFullYear()}
        handleSelection={value => changeYear(Number(value))}
        placeholder="year"
      />
    </section>
  );
};

export default CustomHeader;
