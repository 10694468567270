export interface IData {
  id: string;
  title: string;
  savings: string;
  momInPercent: string;
  status: string;
  isOpen: boolean;
  score: number;
  category: string;
  content: string;
}

export interface ApiResponse {
  isSuccess: boolean;
  start_date: string;
  end_date: string;
  last_updated_date: string;
  data: {
    category: string;
    category_desc: string;
    category_name: string;
    mom_change_perc: string;
    score: number;
    score_text: string;
    total_amount: string;
  }[];
}

export const Title = {
  OVERCHARGES: 'OVERCHARGES',
  POTENTIALSAVINGS: 'POTENTIALSAVINGS',
  TRIPS: 'TRIPS',
  VIOLATIONS: 'VIOLATIONS'
};

export interface ITollInsightsProps {
  selectedMonth: string;
}
