/* eslint-disable react-hooks/exhaustive-deps */
import { v4 as uuid } from 'uuid';
import { memo, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { UnionDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';
import Loader from '@common/ui/Loader';
// import { initSearch } from '@store/features/search';
import { closeModal, openModal } from '@store/features/modal';
import { rowModalId } from '@common/services/format';
import { ApiResponseModal, ConfirmDeleteModal } from '@components/modals';
import { ETableName } from '@common/utils/enums';
import SubscribeModal from '@pages/tables/layouts/commonComponents/subscribe/subscribe';
import styles from './table.module.scss';
import { TableProps } from './types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Header from './header/Header';
import Data from './data/Data';
import MobileTable from './mobile/MobileTable';
import Empty from './empty/Empty';

const Table = ({ data, tableName, loading, ViewModal }: TableProps) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  let headings = useAppSelector(state => state.headings[tableName]) as IHeading[];
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  if (tableName === ETableName.TRANSACTIONS) {
    if (isActualTollTab) {
      const fieldsToRemove = ['trip_id'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    } else {
      const fieldsToRemove = ['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    }
  }
  const search = useAppSelector(state => state.search[tableName]);
  const filters = useAppSelector(state => state.filters[tableName]);
  const dispatch = useAppDispatch();
  const isFirstRender = useRef(true);
  const location = useLocation();
  const {
    activeModal: modal,
    id: modalId,
    responseType,
    responseAction,
    responseMessage
  } = useAppSelector(state => state.modal);
  const status = useAppSelector(state => state.subscribe.open);
  const dates = filters?.by && 'date' in filters.by ? filters?.by.date : null;
  const [lazyData, setLazyData] = useState<UnionDataInterface[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const loadMore = async () => {
    if (lazyData.length < data.length) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await setLazyData(prev => prev.concat(data.slice(prev.length, prev.length + 20)));
    }
  };
  const mobileDetailDataAction = (headerIndex: number) => {
    setSelectedRowIndex(headerIndex);
    return dispatch(openModal(['data', rowModalId(tableName, data[headerIndex]?.id || '')]));
  };

  useEffect(() => {
    setLazyData(data.slice(0, 20));
  }, [data]);
  useEffect(() => {
    setHasMore(!(lazyData.length >= data.length));
  }, [lazyData.length, data.length]);

  const [matches, setMatches] = useState<boolean>(window.matchMedia('(max-width: 480px)').matches);

  useEffect(() => {
    window.matchMedia('(max-width: 640px)').addEventListener('change', e => setMatches(e.matches));
    setMatches(window.matchMedia('(max-width: 640px)').matches);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (
      dispatch &&
      isFirstRender.current &&
      tableName === ETableName.TRIPS &&
      location.search &&
      queryParams?.get('tripId')
    ) {
      isFirstRender.current = false;
      if (data.length === 1) {
        dispatch(
          openModal(['data', rowModalId(ETableName.TRIPS, queryParams?.get('tripId') || '')])
        );
      } else if (data.length === 0) {
        toast.error('Trip not found', { position: 'top-center' });
      }
    }
  }, [dispatch, tableName, location.search]);

  return (
    <div
      id="cl-table"
      className={`${styles.wrapper} ${
        tableName === ETableName.TRIPS ? styles['cust-wrapper-ht'] : ''
      } ${loading ? styles.loading : ''}`}
    >
      {!matches ? (
        <div ref={tableContainerRef} className={styles.container}>
          <Header headings={headings} tableName={tableName} />
          {!loading && !data.length && (search.active || filters?.active) && (
            <Empty
              by={
                search?.field === 'trip duration' ? search?.by || '' : search?.by?.toString() || ''
              }
              field={search?.field || ''}
              dateRange={dates}
            />
          )}
          {!loading ? (
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              loadMore={loadMore}
              hasMore={hasMore}
              loader={<Loader key={uuid()} className={styles['infinite-loader']} />}
              useWindow={false}
              threshold={100}
            >
              {status && <SubscribeModal />}
              {lazyData &&
                lazyData.map(row => {
                  return (
                    <Data
                      tableName={tableName}
                      key={uuid()}
                      data={row}
                      headings={headings}
                      dropdownContainerEl={tableContainerRef}
                      ViewModal={ViewModal}
                    />
                  );
                })}
            </InfiniteScroll>
          ) : (
            <Loader className={styles.loader} />
          )}
        </div>
      ) : (
        <MobileTable
          data={lazyData}
          headings={headings}
          tableName={tableName}
          detailAction={mobileDetailDataAction}
        />
      )}
      <AnimatePresence exitBeforeEnter>
        {modal === 'data' &&
          modalId === rowModalId(tableName, data[selectedRowIndex]?.id) &&
          ViewModal && (
            <ViewModal
              key={tableName.toLowerCase()}
              data={data[selectedRowIndex]}
              closeModal={() => dispatch(closeModal())}
            />
          )}
        {responseType &&
          modal === 'api-response' &&
          modalId === `row-modal/${data[selectedRowIndex]?.id}` && (
            <ApiResponseModal
              key="api-response"
              type={responseType}
              action={responseAction as string}
              message={responseMessage}
            />
          )}
        {modal === 'delete' && modalId === data[selectedRowIndex]?.id && (
          <ConfirmDeleteModal
            key="delete"
            tableName={tableName}
            data={data ? [data[selectedRowIndex]] : []}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(Table);
