/* eslint-disable */
import _ from 'lodash';
import { CustomerDataInterface } from '@common/types/data';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { store } from '@store/index';
import { usersApi } from './users';
import { baseApi, CUSTOMERS_API } from '../config';
import { BEResponse } from '../types';
import { routes } from '@common/utils/route-names';

export type ICreateCustomer = Omit<
  CustomerDataInterface,
  'id' | 'statusCode' | 'sourceType' | 'logoContent' | 'isSuperAdmin'
>;

export const customersApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Customers', 'Customer', 'Users']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getCustomers: builder.query<BEResponse<CustomerDataInterface>, [number, number]>({
        queryFn: async ([page_index, page_size], _queryApi, _extraOptions, fetchWithBQ) => {
          const getCustomers = await fetchWithBQ({
            url: CUSTOMERS_API,
            params: { 'page-index': page_index, 'page-size': page_size }
          });
          if (getCustomers.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (getCustomers.meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (getCustomers.error) {
            return { error: getCustomers.error };
          }
          const customersData = getCustomers.data as BEResponse<CustomerDataInterface>;
          const filteredCustomers = customersData.pageContent.filter(
            customer => !customer.statusCode.match(/deleted/i)
          );
          const customersWithLogo = await Promise.all([
            ...filteredCustomers.map(async customer => {
              let logo;
              if (customer.companyLogo) {
                logo = await fetchWithBQ({
                  url: `${CUSTOMERS_API}/${customer.id}/company-logo`,
                  responseHandler: response => response.blob()
                });
              }
              const logoBlob = logo && (logo?.data as Blob).size ? (logo?.data as Blob) : null;
              return { ...customer, logoContent: logoBlob ? URL.createObjectURL(logoBlob) : '' };
            })
          ]);
          return { data: { ...customersData, pageContent: customersWithLogo } };
        },
        providesTags: result =>
          result
            ? [
                ...result.pageContent.map(({ id }) => ({ type: 'Customers' as const, id })),
                { type: 'Customers', id: 'LIST' }
              ]
            : [{ type: 'Customers', id: 'LIST' }]
      }),
      getSingleCustomer: builder.query<CustomerDataInterface, string>({
        queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
          const getCustomer = await fetchWithBQ(`${CUSTOMERS_API}/${_arg}`);
          if (getCustomer.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }

          if (getCustomer.error) {
            return { error: getCustomer.error };
          }
          const customerData = getCustomer.data as CustomerDataInterface;
          let logo;
          if (customerData.companyLogo) {
            logo = await fetchWithBQ({
              url: `${CUSTOMERS_API}/${customerData.id}/company-logo`,
              responseHandler: response => response.blob()
            });
          }
          const logoBlob = logo && (logo?.data as Blob).size ? (logo?.data as Blob) : null;
          return {
            data: { ...customerData, logoContent: logoBlob ? URL.createObjectURL(logoBlob) : '' }
          };
        },
        providesTags: ['Customer']
      }),
      createCustomer: builder.mutation<unknown, [ICreateCustomer, FormData | null]>({
        queryFn: async ([newCustomer, logo], _queryApi, _extraOptions, fetchWithBQ) => {
          const createBECustomer = await fetchWithBQ({
            url: CUSTOMERS_API,
            method: 'post',
            body: {
              ...newCustomer,
              statusCode: 'INVITE_SENT',
              sourceType: 'DIRECT'
            },
            responseHandler: response => response.json()
          });

          if (createBECustomer.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }

          if (createBECustomer.error) {
            return { error: createBECustomer.error };
          }
          const customerId = (createBECustomer.data as CustomerDataInterface)?.id;
          if (logo) {
            await fetchWithBQ({
              url: `${CUSTOMERS_API}/${customerId}/company-logo`,
              method: 'put',
              body: logo,
              headers: {
                'Content-type': 'file'
              }
            });
          }
          const userObject = {
            name: 'Admin',
            email: newCustomer.adminEmail,
            app_role: 'ADMINISTRATOR',
            statusCode: 'INVITE_SENT',
            sourceType: 'DIRECT',
            customerId
          };
          const createBEUser = await _queryApi.dispatch(
            usersApi.endpoints.createUser.initiate(userObject)
          );
          if ('error' in createBEUser) {
            return { error: createBEUser.error as FetchBaseQueryError };
          }
          return { data: createBECustomer.data };
        },
        invalidatesTags: [{ type: 'Customers', id: 'LIST' }, 'Users']
      }),
      updateCustomer: builder.mutation<unknown, [CustomerDataInterface, FormData | null]>({
        queryFn: async ([updateCustomerData, logo], _queryApi, _extraOptions, fetchWithBQ) => {
          const { logoContent, ...customer } = updateCustomerData;
          const updateCustomer = await fetchWithBQ({
            url: `${CUSTOMERS_API}/${updateCustomerData.id}`,
            method: 'put',
            body: customer
          });
          if (updateCustomer.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }

          if (updateCustomer.error) {
            return { error: updateCustomer.error };
          }
          if (logo) {
            await fetchWithBQ({
              url: `${CUSTOMERS_API}/${customer.id}/company-logo`,
              method: 'put',
              body: logo,
              headers: {
                'Content-type': 'file'
              }
            });
          }
          return { data: updateCustomer.data as CustomerDataInterface };
        },
        invalidatesTags: [{ type: 'Customers', id: 'LIST' }, 'Customer']
      }),
      deleteCustomer: builder.mutation<unknown, string | number>({
        queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
          const deleteCustomer = await fetchWithBQ({
            url: `${CUSTOMERS_API}/${_arg}`,
            method: 'delete'
          });
          if (deleteCustomer.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }

          if (deleteCustomer.error) {
            return { error: deleteCustomer.error };
          }
          await fetchWithBQ({
            url: `${CUSTOMERS_API}/${_arg}/company-logo`,
            method: 'delete'
          });
          return { data: deleteCustomer.data };
        },
        invalidatesTags: [{ type: 'Customers', id: 'LIST' }, 'Customer', 'Users']
      }),
      getCustomerLogo: builder.query<unknown, string>({
        query: customer_id => ({
          url: `${CUSTOMERS_API}/${customer_id}/company-logo`
        })
      }),
      uploadCustomerLogo: builder.mutation<unknown, [string, File]>({
        query: ([customer_id, logo]) => ({
          url: `${CUSTOMERS_API}/${customer_id}/company-logo`,
          method: 'put',
          body: logo
        }),
        invalidatesTags: [{ type: 'Customers', id: 'LIST' }, 'Customer']
      }),
      deleteCustomerLogo: builder.mutation<unknown, string>({
        query: ([customer_id]) => ({
          url: `${CUSTOMERS_API}/${customer_id}/company-logo`,
          method: 'delete'
        }),
        invalidatesTags: [{ type: 'Customers', id: 'LIST' }, 'Customer']
      })
    })
  });

export const {
  useGetCustomersQuery,
  useGetSingleCustomerQuery,
  useLazyGetSingleCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomerLogoQuery,
  useLazyGetCustomerLogoQuery,
  useUploadCustomerLogoMutation
} = customersApi;
