import { AnimatePresence } from 'framer-motion';
import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import SelectedEntries from './SelectedEntries/SelectedEntries';
import LoadedObjects from './LoadedObjects/LoadedObjects';

const Panel = () => {
  const table = useAppSelector(state => state.table);
  const dataLength = useAppSelector(state => state.pagination[table.name as ETableName].dataLength);
  const selected = useAppSelector(state => state.selectionIds[table.name as ETableName]);
  const search = useAppSelector(state => state.search[table.name as ETableName]);
  const filtering = useAppSelector(state => state.filters[table.name as ETableName]);

  return (
    <AnimatePresence initial={false}>
      {/* {selected && selected?.length > 0 && ( */}
      <SelectedEntries key="selected" table={table} selected={selected} />
      {/* )} */}
      {(search.active || filtering?.active) && (
        <LoadedObjects key="loaded" dataLength={dataLength} tableName={table.name as ETableName} />
      )}
    </AnimatePresence>
  );
};

export default Panel;
