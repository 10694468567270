import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import Tag from '@common/ui/Tag';
import styles from './select-input-content.module.scss';
import { SelectInputContentProps } from './types';

const SelectInputContent: FC<SelectInputContentProps> = ({
  className = '',
  selection,
  placeholder,
  handleSelectionState
}) => {
  const isTags = Array.isArray(selection);

  const selectionBoxTextStyles = selection
    ? `${styles.container} ${styles['container-value']} ${className}`
    : `${styles.container} ${className}`;

  if (!isTags) {
    return (
      <span aria-label="select-display-content" className={selectionBoxTextStyles}>
        {selection || <span className={styles.placeholder}>{placeholder}</span> || 'Select'}
      </span>
    );
  }

  return (
    <div
      data-select-is-tags={isTags}
      aria-label="select-display-content"
      className={`${selectionBoxTextStyles} ${styles.tags}`}
    >
      {isTags && selection.length !== 0
        ? selection.map(item => (
            <Tag
              key={uuid()}
              item={item}
              handleSelectionState={handleSelectionState as (value: (string | number)[]) => void}
              selection={selection}
              inSelect
            />
          ))
        : <span className={styles.placeholder}>{placeholder}</span> || 'Select'}
    </div>
  );
};

export default SelectInputContent;
