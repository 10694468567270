import { RoleDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<RoleDataInterface>[] = [
  {
    heading: 'name',
    field: 'name',
    active: true,
    filterType: 'text',
    placeholder: 'search name'
  },
  {
    heading: 'description',
    field: 'description',
    active: true,
    filterType: 'text',
    placeholder: 'search description'
  },
  {
    heading: 'users',
    field: 'users',
    active: true,
    filterType: 'text',
    placeholder: 'search users'
  }
];
