/* eslint-disable */
import { Form, Formik } from 'formik';
import { SelectInput, TextInput } from '@common/ui/Form';
import styles from './company.module.scss';
import { initialValues, validationSchema } from './utils/form';
import Footer from '../layout/footer/Footer';
import { useSSOContext } from '../../context/SSOContext';
import { IGeotabUser } from '@pages/geotab/types';
import { getStatesByCountryName } from '@common/utils/constants/helpers';
import { useRegisterGeotabUser } from '@pages/geotab/hooks/useRegisterGeotabUser';

const Company = () => {
  const { isCustomerNew, isOrdernowCustomer, gUser, handleUserDetails, handleNextTab } =
    useSSOContext();
  const { handleRegistration } = useRegisterGeotabUser();

  const CompanyUI = ({ isCustomerNew }: { isCustomerNew: boolean }) => {
    const registerState = getStatesByCountryName('United States')
      ?.map(x => `${x.isoCode}-${x.name}`)
      .filter(x => x && x !== '-None');

    return (
      <div className={styles.inputs}>
        {isCustomerNew && <h2>Organization</h2>}
        <div className={styles.row}>
          <TextInput
            className={styles.input}
            label="Company Name*"
            name="companyName"
            placeholder="Enter company name"
            disabled={!isCustomerNew}
            manualTouchEnable
          />
          {isCustomerNew ? (
            <>
              <TextInput
                className={styles.input}
                label="City*"
                name="companyRegAddress.city"
                placeholder="Enter city"
                manualTouchEnable
              />
              <SelectInput
                className={styles.input}
                label="State*"
                type="simple"
                includeSearch
                formField="companyRegAddress.state"
                values={registerState}
                placeholder="Select register state"
                manualTouchEnable
              />
            </>
          ) : (
            <>
              <TextInput
                className={styles.input}
                label="User (E-mail)*"
                name="email"
                placeholder="Enter user E-mail"
                disabled={!isCustomerNew}
                manualTouchEnable
              />
            </>
          )}
        </div>
        {isCustomerNew && (
          <>
            <div className={styles.row}>
              <TextInput
                className={styles.input}
                label="Address line 1*"
                name="companyRegAddress.street1"
                placeholder="Enter address line 1"
                manualTouchEnable
              />
              <TextInput
                className={styles.input}
                label="Address line 2"
                name="companyRegAddress.street2"
                placeholder="Enter address line 2"
                manualTouchEnable
              />
              <TextInput
                className={styles.input}
                label="Zip*"
                name="companyRegAddress.zipCode"
                placeholder="Enter Zip code"
                manualTouchEnable
              />
            </div>
            <>
              <h2>Account Admin</h2>
              <div className={styles.row}>
                <TextInput
                  className={styles.input}
                  label="Full Name*"
                  name="contactName"
                  placeholder="Enter  full name"
                  manualTouchEnable
                />

                <TextInput
                  className={styles.input}
                  label="Phone*"
                  name="contactPhone"
                  placeholder="Enter admin phone"
                  manualTouchEnable
                />

                <TextInput
                  className={styles.input}
                  label="E-mail*"
                  name="contactEmail"
                  placeholder="Enter admin Email"
                  manualTouchEnable
                />
              </div>
            </>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          gUser.email
            ? { ...gUser, contactEmail: gUser.email, consent: gUser.consent || !isCustomerNew }
            : { ...initialValues, consent: gUser.consent || !isCustomerNew }
        }
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          handleUserDetails({ ...values, email: values.contactEmail });
          setSubmitting(false);
          const userDetails: IGeotabUser = {
            companyName: values.companyName,
            companyRegAddress: values.companyRegAddress,
            contactName: values.contactName,
            contactPhone: values.contactPhone,
            contactEmail: values.contactEmail
          };
          handleNextTab(userDetails, null);
          if (isOrdernowCustomer) {
            console.log('userDetails', userDetails);
            await handleRegistration({ ...userDetails }).catch(e => console.log(e));
          }
        }}
      >
        {formik => (
          <Form className={styles.container}>
            <CompanyUI isCustomerNew={isCustomerNew} />
            <Footer submitForm={formik.submitForm} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Company;
