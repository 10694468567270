/*eslint-disable */
import { FC } from 'react';
import { MinMaxNumberInput, SelectInput } from '@common/ui/Form';
import { TIME_ZONES } from '@common/utils/constants';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { OptionsByTableProps } from './types';
import StartAndEndDate from '../StartAndEndDate/StartAndEndDate';

const Transactions: FC<OptionsByTableProps> = ({ ...props }) => {
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  return (
    <>
      {!isGeotab ? (
        <StartAndEndDate
          startLabel="Transaction Date From"
          startPlaceholder="Transaction Date From"
          endLabel="Transaction Date To"
          endPlaceholder="Transaction Date To"
          startField="date.from"
          endField="date.to"
          {...props}
        />
      ) : (
        ''
      )}
      <MinMaxNumberInput
        className={props.className}
        minName="toll.from"
        maxName="toll.to"
        label="Toll"
      />
    </>
  );
};

export default Transactions;
