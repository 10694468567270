import {
  Calendar,
  Dollar,
  PlateNumber,
  Plaza,
  TollAgency,
  TollID,
  VehicleAxles,
  VehicleClass,
  PlateState
} from '@assets/icons';
import { Form } from 'formik';
import { useRef } from 'react';
import { TextInput } from '@common/ui/Form';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';
import { useAppSelector } from '@store/hooks';
import styles from './update-transaction.module.scss';

const UpdateTransaction = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const hasEditAccess = !useEditPermission(routes.transactions);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);

  return (
    <Form className={styles.container} ref={formRef}>
      <TextInput
        className={styles.input}
        label="Toll Agency"
        name="tollAgency"
        icon={<TollAgency />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Plaza"
        name="plaza"
        icon={<Plaza />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Facility"
        name="facility"
        icon={<Plaza />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Transaction Date"
        name="date"
        icon={<Calendar />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Amount"
        name="calculatedAmount"
        icon={<Dollar />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Tag/Transponder"
        name="tagTransponder"
        icon={<TollID />}
        disabled={hasEditAccess}
      />
      {!isActualTollTab && (
        <TextInput
          className={styles.input}
          label="License Plate Number"
          name="licensePlate"
          icon={<PlateNumber />}
          disabled={hasEditAccess}
        />
      )}
      {/* <TextInput
        className={styles.input}
        label="License Plate"
        name="licensePlate"
        icon={<PlateNumber />}
        disabled={hasEditAccess}
      /> */}
      {isActualTollTab && (
        <>
          <TextInput
            className={styles.input}
            label="License Plate State"
            name="licenseState"
            icon={<PlateState />}
            disabled={hasEditAccess}
          />

          <TextInput
            className={styles.input}
            label="License Plate Number"
            name="licenseNumber"
            icon={<PlateNumber />}
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label="Upload Date"
            name="uploadDate"
            icon={<Calendar />}
            disabled={hasEditAccess}
          />

          <TextInput
            className={styles.input}
            label="Fee"
            name="feeType"
            icon={<TollID />}
            disabled={hasEditAccess}
          />

          <TextInput
            className={styles.input}
            label="Fee Sub Type"
            name="feeSubType"
            icon={<TollID />}
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label="Axle Count"
            name="axles"
            icon={<VehicleAxles />}
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label="Vehicle Class"
            name="vehicleClass"
            icon={<VehicleClass />}
            disabled={hasEditAccess}
          />
        </>
      )}
    </Form>
  );
};

export default UpdateTransaction;
