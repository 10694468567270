import { SSOContextProvider } from './context/SSOContext';
import Content from './components/content/Content';

const GeoTabSso = () => {
  return (
    <SSOContextProvider>
      <Content />
    </SSOContextProvider>
  );
};

export default GeoTabSso;
