import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Vehicle Name is a required'),
  vehicleClass: Yup.string().required('Vehicle Class is a required'),
  vin: Yup.string().nullable(),
  type: Yup.string().nullable(),
  weight: Yup.number()
    // .integer('value must be an integer')
    // .positive('only positive numbers allowed')
    .nullable(),
  height: Yup.number()
    // .positive('only positive numbers allowed')
    .nullable(),
  axles: Yup.number()
    // .positive('only positive numbers allowed')
    .nullable(),
  tagType: Yup.string().nullable(),
  tagTransponder: Yup.string().nullable(),
  tollConsolidator: Yup.boolean().default(true),
  issuingAgencyId: Yup.string().nullable(),
  plateState: Yup.string().nullable(),
  plateNumber: Yup.string().nullable()
});
