import Logo from '@common/ui/Logo';
import styles from './nav-logo.module.scss';

const NavLogo = () => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
    </div>
  );
};

export default NavLogo;
