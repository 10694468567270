/* eslint-disable jsx-a11y/control-has-associated-label */
import { ChevronRight } from '@assets/icons';
import { FC } from 'react';
import styles from './nav-control.module.scss';
import { NavControlProps } from './types';

const NavControl: FC<NavControlProps> = ({
  navCollapse,
  handleNavCollapse,
  isSplitter = false
}) => {
  return (
    <button
      type="button"
      className={`${!isSplitter ? styles.container : styles['splitter-container']}`}
      onClick={() => handleNavCollapse(!navCollapse)}
    >
      <ChevronRight className={!navCollapse ? `${styles.icon} ${styles.open}` : `${styles.icon}`} />
    </button>
  );
};

export default NavControl;
