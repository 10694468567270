/* eslint-disable */
import { FC } from 'react';
import { UnionDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings';
import SmartFilters from '@pages/tables/layouts/web-portal/components/smart-filters';
import { formatFieldData } from '@common/services/format';
import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import styles from './mobile-table.module.scss';

interface MobileTableProps {
  data: UnionDataInterface[];
  headings: IHeading[];
  tableName: ETableName;
  detailAction?: (tableIndex: number) => void;
}

const MobileTable: FC<MobileTableProps> = ({ data, headings, tableName, detailAction }) => {
  const isMetric: boolean = useAppSelector((state: any) => state.auth.isMetric);
  const timeZone: string = useAppSelector((state: any) => state.auth.timeZoneId);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const handleDataModal = (index: number) => {
    if (detailAction) {
      detailAction(index);
    }
  };
  return (
    <>
      {tableName !== ETableName.TOLLDOCUMENTS && tableName !== ETableName.USERS && <SmartFilters />}
      <div className={styles.container}>
        {data &&
          data.map((item, itemIndex) => {
            return (
              <div
                className={styles.cell}
                onClick={() => handleDataModal(itemIndex)}
                onKeyDown={() => handleDataModal(itemIndex)}
              >
                {headings
                  ?.filter(header => header.active)
                  .map(header => (
                    <div className={styles.row}>
                      <p className={styles.header}>{header.heading}</p>
                      <p className={styles.content}>
                        {/* {item[header.field] || '-'} */}
                        {item[header.field] ? (
                          <>
                            {formatFieldData(
                              item[header.field],
                              header.heading,
                              tableName,
                              isMetric,
                              timeZone,
                              header.field,
                              data,
                              isActualTollTab
                            )}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  ))}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MobileTable;
