/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDataInterface } from '@common/types/data';
import { usersApi } from '@api/services/users';

const initialState: UserDataInterface = {
  id: '',
  name: '',
  email: '',
  app_role: '',
  statusCode: '',
  customerId: '',
  sourceType: ''
};

export const getUserData = createAsyncThunk(
  'user/fetchById',
  async ([userId, token]: [string, string | undefined], thunkAPI) => {
    const user = await thunkAPI.dispatch(
      usersApi.endpoints.getUserById.initiate({ id: userId, token })
    );
    const userData = user.data || ({} as UserDataInterface);
    if (!userData.statusCode?.match(/active/i)) {
      await thunkAPI
        .dispatch(usersApi.endpoints.updateUser.initiate({ ...userData, statusCode: 'ACTIVE' }))
        .catch(e => console.log(e));
    }
    return userData;
  }
);

export const userSlice = createSlice({
  name: 'current user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDataInterface>) => action.payload,
    clearUser: () => initialState
  },
  extraReducers(builder) {
    builder.addCase(getUserData.fulfilled, (state, action) => action.payload);
  }
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
