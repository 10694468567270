/* eslint-disable */
import Papaparse from 'papaparse';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from 'lodash';
import { UnionDataInterface, UserDataInterface } from '@common/types/data';
import { useAppSelector } from '@store/hooks';
import { IHeading } from '@store/features/headings/types';
import { EAuthRole, ETableName } from '@common/utils/enums';
import { usePromiseToastNotify } from '@common/hooks';
import { getSearchResults } from '@common/services/search';
import { sortData } from '@common/services/sort';
import exportApi from '../../api/services/export';
import {
  FLEET_API,
  GPS_TOLLS_API,
  SUPERADMIN_API,
  TOLL_DOCUMENTS_API,
  TRANSACTIONS_API,
  TRIPS_API,
  USERS_API
} from '../../api/config';
import { useModifyData } from '../../pages/tables/hooks/useModifyData';
import {
  formatDetailTimeZone,
  formatTransactionDate,
  GPSTollTimezone,
  ExportDateTimeformatHelper,
  ExportDateObject
} from '../services/format/formatTimeZone';
import { useSelector } from 'react-redux';
import { tollDocumentStatuses } from '@common/utils/constants';
import dayjs from 'dayjs';
import { vehicleClassesList } from '@common/utils/constants/lists';
import { secondsToHms } from '@common/services/format/time';
import { formatNumber } from '@common/services/format';
import { convertBillingStatus, convertDevicePlan } from '@common/utils/constants/helpers';
import moment from 'moment-timezone';

export type ExportFileType = 'pdf' | 'xlsx' | 'csv';

export const useDataExport = () => {
  const toastify = usePromiseToastNotify();
  const table = useAppSelector(state => state.table);
  const customerId = useAppSelector(state => state.customer.id);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const auth = useAppSelector(state => state.auth.app_role);

  const tableName = table.name as ETableName;
  let headerNames = useAppSelector(state => state.headings[table.name as ETableName]) as IHeading[];
  if (tableName === ETableName.TRANSACTIONS) {
    if (isActualTollTab) {
      const fieldsToRemove = ['trip_id'];
      headerNames = headerNames.filter(item => !fieldsToRemove.includes(item.field));
    } else {
      const fieldsToRemove = ['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'];
      headerNames = headerNames.filter(item => !fieldsToRemove.includes(item.field));
    }
  }
  const pagination = useAppSelector(state => state.pagination[tableName]);
  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const isMetric = useSelector((state: any) => state.auth.isMetric);
  const body = useModifyData(tableName);

  const userHeadings = useAppSelector(state => state.headings[ETableName.USERS]);
  const search = useAppSelector(state => state.search[ETableName.USERS]);
  const filters = useAppSelector(state => state.filters[ETableName.USERS]);
  const sort = useAppSelector(state => state.sort[ETableName.USERS]);
  body.pageSize = pagination?.dataLength || 0;
  body.pageIndex = 0;

  function diffLessThan65Days(startDate: any, endDate: any) {
    function isValidDateOrEmptyString(startDate: any, endDate: any) {
      const dayjsDate1 = dayjs(startDate);
      const dayjsDate2 = dayjs(endDate);
      return dayjsDate1.isValid() && dayjsDate2.isValid() && startDate !== '' && endDate !== '';
    }
    const validate = isValidDateOrEmptyString(startDate, endDate);
    if (validate) {
      const diffInDays = dayjs(endDate).diff(startDate, 'day');
      return diffInDays < 65;
    } else {
      return false;
    }
  }

  function tripDetailDataConversion(data: any) {
    if (isMetric) {
      data.forEach((item: any) => {
        formatDetailTimeZone(item, true);
        item.timeSpent = secondsToHms(item.timeSpent);
        item.distanceD = `${item.distance} km`;
        item.crossedAt = GPSTollTimezone(item, undefined, true);
      });
    } else {
      data.forEach((item: any) => {
        formatDetailTimeZone(item, true);
        item.timeSpent = secondsToHms(item.timeSpent);
        item.distanceD = `${item.distanceInMiles} mi`;
        item.crossedAt = GPSTollTimezone(item, undefined, true);
      });
    }
    return data;
  }

  function tripDataConversion(data: any) {
    if (isMetric) {
      data.forEach((item: any) => {
        formatDetailTimeZone(item, true);
        item.timeSpent = secondsToHms(item.timeSpent);
        item.distanceD = `${item.distance} km`;
        item.moneySpent = formatNumber(item.moneySpent, 2);
      });
    } else {
      data.forEach((item: any) => {
        formatDetailTimeZone(item, true);
        item.timeSpent = secondsToHms(item.timeSpent);
        item.distanceD = `${item.distanceInMiles} mi`;
        item.moneySpent = formatNumber(item.moneySpent, 2);
      });
    }
    return data;
  }

  const handleDataExport = (
    fileType: ExportFileType,
    fileName: string,
    exportData: UnionDataInterface[],
    selectedData?: any,
    DetailsExport?: boolean
  ) => {
    toastify(
      async () => {
        let headings: string[] = headerNames
          .filter(title => title.active)
          .map(title => title.field || title.heading);
        let headingsUF = headerNames
          .filter(title => title.active)
          .map(title => title.heading.toUpperCase());
        let data;
        let newHeadings = [] as string[];
        if (DetailsExport) {
          if (body.pageSize > 0) {
            headings = headings.filter(item => item !== 'id');
            newHeadings = [
              'crossedAt',
              'facility',
              'plaza',
              'calculatedAmount',
              'id'
            ] as (keyof UnionDataInterface)[];
            headings.push(...newHeadings);
            headingsUF = headingsUF.filter(item => item !== 'TRIP ID');
            let newHeadingUF = [
              'Toll Plaza Date/Time',
              'Facility',
              'Plaza',
              'GPS Calculated Tolls',
              'TRIP ID'
            ];
            headingsUF.push(...newHeadingUF);
            if (selectedData.length > 0) {
              let tripId: any[] = [];
              selectedData.forEach((item: any) => {
                tripId.push(item.id);
              });
              body.tripId = tripId;
              const currentData = await exportApi.post(`${TRIPS_API}/filter`, body);
              if (currentData.pageContent.length > 0) {
                const convertData = tripDetailDataConversion(currentData.pageContent);
                data = convertData;
              }
            } else {
              if (diffLessThan65Days(body?.date?.startDate, body?.date?.endDate)) {
                body.tripId = null;
                let localData: any = [];
                const pageSize = 2500;
                const totalPages = Math.ceil(body.pageSize / pageSize);
                body.pageSize = pageSize;

                for (let i = 0; i < totalPages; ++i) {
                  body.pageIndex = i;
                  const currentData = await exportApi.post(`${TRIPS_API}/filter`, body);
                  localData = localData.concat(currentData.pageContent);
                }

                if (localData.length > 0) {
                  const convertData = tripDetailDataConversion(localData);
                  data = convertData;
                }
              } else {
                throw { data: 'kindly select date for maximum 2 months before exporting the data' };
              }
            }
          } else {
            throw { data: 'No record for export' };
          }
        } else {
          if (fileName === ETableName.TRIPS && !selectedData?.length) {
            if (body.pageSize > 0) {
              if (diffLessThan65Days(body?.date?.startDate, body?.date?.endDate)) {
                const currentData = await exportApi.post(
                  `${TRIPS_API}/${body?.searchableField ? 'searchAll' : 'search'}`,
                  body
                );
                if (currentData?.pageContent.length > 0) {
                  const convertData = tripDataConversion(currentData.pageContent);
                  data = convertData;
                }
              } else {
                throw { data: 'kindly select date for maximum 2 months before exporting the data' };
              }
            } else {
              throw { data: 'No record for export' };
            }
          } else if (fileName === ETableName.FLEET && !selectedData?.length) {
            if (body.pageSize > 0) {
              headings?.forEach(header => {
                if (header === ('vehicleClassD' as keyof UnionDataInterface)) {
                  newHeadings.push('vehicleClass' as keyof UnionDataInterface);
                } else if (header === ('tagTypeD' as keyof UnionDataInterface)) {
                  newHeadings.push('tagType' as keyof UnionDataInterface);
                } else if (header === ('issuingAgencyIdD' as keyof UnionDataInterface)) {
                  newHeadings.push('issuingAgencyId' as keyof UnionDataInterface);
                } else if (header === ('devicePlanD' as keyof UnionDataInterface)) {
                  newHeadings.push('devicePlan' as keyof UnionDataInterface);
                } else if (header === ('billingStatusD' as keyof UnionDataInterface)) {
                  newHeadings.push('billingStatus' as keyof UnionDataInterface);
                } else if (header === ('secIssuingAgencyIdD' as keyof UnionDataInterface)) {
                  newHeadings.push('secAgencyName' as keyof UnionDataInterface);
                } else if (header === ('trdIssuingAgencyIdD' as keyof UnionDataInterface)) {
                  newHeadings.push('trdAgencyName' as keyof UnionDataInterface);
                } else {
                  newHeadings.push(header);
                }
              });
              headings = newHeadings;
              const currentData = await exportApi.post(
                `${FLEET_API}/${body?.searchableField ? 'searchAll' : 'search'}`,
                body
              );

              currentData.pageContent.forEach((item: any) => {
                item.devicePlan = convertDevicePlan(item.devicePlan);
                item.billingStatus = convertBillingStatus(item.billingStatus);
                // item.createdAt = dayjs(item.createdAt as string).format('MM/DD/YYYY, hh:mm A');
                // item.createdAt = `${dayjs(item.createdAt as string).format(
                //   'MM/DD/YYYY, HH:mm'
                // )} UTC`;
                item.createdAt = item.createdAt ? ExportDateTimeformatHelper(item.createdAt) : '';
                // item.billingStatusUpdatedAt = dayjs(item.billingStatusUpdatedAt as string).format(
                //   'MM/DD/YYYY, hh:mm A'
                // );
                // item.billingStatusUpdatedAt = `${dayjs(
                //   item.billingStatusUpdatedAt as string
                // ).format('MM/DD/YYYY, HH:mm')} UTC`;
                item.billingStatusUpdatedAt = item?.billingStatusUpdatedAt
                  ? ExportDateObject(item.billingStatusUpdatedAt)
                  : '';
                item.tagType = item.tagType === 'None' ? '' : item.tagType;
                item.agencyName = item.agencyName === 'None' ? '' : item.agencyName;
                item.secTagType = item.secTagType === 'None' ? '' : item.secTagType;
                item.secAgencyName = item.secAgencyName === 'None' ? '' : item.secAgencyName;
                item.trdTagType = item.trdTagType === 'None' ? '' : item.trdTagType;
                item.trdAgencyName = item.trdAgencyName === 'None' ? '' : item.trdAgencyName;
                item.licensePlate = item.licensePlate === '-' ? '' : item.licensePlate;
              });
              data = currentData.pageContent;
            } else {
              throw { data: 'No record for export' };
            }
          } else if (fileName === ETableName.TRANSACTIONS && !selectedData?.length) {
            if (body.pageSize > 0) {
              if (diffLessThan65Days(body?.date?.startDate, body?.date?.endDate)) {
                let localData: any = [];
                const pageSize = 10000;
                const totalPages = Math.ceil(body.pageSize / pageSize);
                body.pageSize = pageSize;

                if (body.sort?.fieldName && body.sort?.fieldName === 'amount') {
                  body.sort.fieldName = 'toll';
                }

                if (body?.searchableField && body.searchableField === 'AMOUNT') {
                  body.searchableField = 'TOLL';
                }

                for (let i = 0; i < totalPages; ++i) {
                  body.pageIndex = i;
                  const currentData = await exportApi.post(
                    `${
                      isActualTollTab
                        ? `${TRANSACTIONS_API}/${body?.searchableField ? 'searchAll' : 'search'}`
                        : `${GPS_TOLLS_API}`
                    }`,
                    body
                  );
                  currentData.pageContent.forEach((item: any) => {
                    item.date = formatTransactionDate(item, true, isActualTollTab);
                    item.uploadDate = item?.uploadDate
                      ? ExportDateTimeformatHelper(item.uploadDate)
                      : '';
                    item.licensePlate = isActualTollTab
                      ? item.licensePlate === '-'
                        ? ''
                        : item.licensePlate
                      : item.license_plate
                      ? item.license_plate
                      : '';
                    item.tollAgency = isActualTollTab ? item.tollAgency : item.toll_agency;
                    item.calculatedAmount = isActualTollTab
                      ? item.calculatedAmount
                      : item.calculated_amount;
                    item.tagNumber = isActualTollTab ? item.tagNumber : item.tag_number;
                    item.feeType = item?.feeType
                      ? item.feeType === 'NONE'
                        ? ''
                        : item?.feeType
                      : '';
                    if (!isActualTollTab) {
                      item.tripId = item.trip_id;
                    }
                  });
                  localData = localData.concat(currentData.pageContent);
                }
                data = localData;
              } else {
                throw { data: 'kindly select date for maximum 2 months before exporting the data' };
              }
            } else {
              throw { data: 'No record for export' };
            }
          } else if (fileName === ETableName.TOLLDOCUMENTS && !selectedData?.length) {
            if (body.pageSize > 0) {
              if (diffLessThan65Days(body?.date?.startDate, body?.date?.endDate)) {
                const currentData = await exportApi.post(`${TOLL_DOCUMENTS_API}/list`, body);
                data = currentData.data.pageContent;
              } else {
                throw { data: 'kindly select date for maximum 2 months before exporting the data' };
              }
            } else {
              throw { data: 'No record for export' };
            }
          } else if (fileName === ETableName.USERS && !selectedData?.length) {
            if (table?.data && table.data?.length > 0) {
              const isEmptyCustomer = auth === EAuthRole.SUPER_ADMIN && !customerId;

              let currentData: UserDataInterface[];

              if (isEmptyCustomer) {
                currentData = await exportApi.get(SUPERADMIN_API);
              } else {
                currentData = await exportApi.get(`${USERS_API}?customer-id=${customerId}`);
              }

              if (currentData?.length > 0) {
                const activeHeadings = [...userHeadings]
                  ?.filter(heading => heading.active)
                  .filter(heading =>
                    ['name', 'email address'].includes(_.lowerCase(heading.heading))
                  )
                  .map(heading => heading.field);
                const activeFiltering = filters?.active;
                const filteredValues =
                  filters?.active && filters.by && currentData
                    ? _.uniqBy(
                        Object.entries(filters?.by || {})
                          .map(keyValue => {
                            const filtered = currentData.filter(
                              (row: any) =>
                                _.kebabCase(`${row[keyValue[0] as keyof UnionDataInterface]}`) ===
                                _.kebabCase(`${keyValue[1] as string | number}`)
                            );
                            return filtered;
                          })
                          .reduce((prev, next) => prev.concat(next), []),
                        'id'
                      )
                    : currentData;
                const checkFiltering = activeFiltering ? filteredValues : currentData;
                const searchValues = getSearchResults(
                  checkFiltering || [],
                  search?.by as string | number,
                  activeHeadings
                ) as UnionDataInterface[];
                const values = search?.active ? searchValues : checkFiltering;
                const sortedValues = sortData(
                  values || [],
                  sort?.by as string,
                  sort?.order as 'asc' | 'desc'
                ) as UnionDataInterface[];
                data = sortedValues;
              } else {
                throw { data: 'error in  export' };
              }
            } else {
              throw { data: 'No record for export' };
            }
          } else if (fileName === ETableName.CUSTOMERS && !selectedData?.length) {
            if (table?.data && table.data?.length > 0) {
              const currentData = table.data;
              data = currentData;
            } else {
              throw { data: 'No record for export' };
            }
          } else if (selectedData?.length > 0) {
            let modify_selectedData: any[] = [];
            if (fileName === ETableName.TRIPS) {
              selectedData.forEach((item: any) => {
                let newItem = { ...item };
                modify_selectedData.push(newItem);
              });
              const convertData = tripDataConversion(modify_selectedData);
              data = convertData;
            } else if (fileName === ETableName.FLEET) {
              selectedData.forEach((item: any) => {
                let newItem = { ...item };
                newItem.tagType = newItem.tagType === 'None' ? '' : newItem.tagType;
                newItem.agencyName = newItem.agencyName === 'None' ? '' : newItem.agencyName;
                newItem.secTagType = newItem.secTagType === 'None' ? '' : newItem.secTagType;
                newItem.secAgencyName =
                  newItem.secAgencyName === 'None' ? '' : newItem.secAgencyName;
                newItem.trdTagType = newItem.trdTagType === 'None' ? '' : newItem.trdTagType;
                newItem.trdAgencyName =
                  newItem.trdAgencyName === 'None' ? '' : newItem.trdAgencyName;
                newItem.licensePlate = newItem?.licensePlate === '-' ? '' : newItem?.licensePlate;
                newItem.issuingAgencyIdD =
                  newItem.issuingAgencyIdD === 'None' ? '' : newItem?.issuingAgencyIdD;
                modify_selectedData.push(newItem);
              });

              data = modify_selectedData;
            } else if (fileName === ETableName.TRANSACTIONS) {
              selectedData.forEach((item: any) => {
                let newItem = { ...item };
                newItem.date = item?.date
                  ? formatTransactionDate(newItem, true, isActualTollTab)
                  : item?.date;

                newItem.uploadDate = item?.uploadDate
                  ? ExportDateTimeformatHelper(item.uploadDate)
                  : '';
                newItem.licensePlate = newItem?.licensePlate === '-' ? '' : newItem?.licensePlate;
                modify_selectedData.push(newItem);
              });

              data = modify_selectedData;
            } else {
              data = selectedData;
            }
          } else {
            data = exportData;
          }
        }
        data = data.map((obj: any) =>
          headings.map(heading => {
            let value = obj[
              tableName === ETableName.TOLLDOCUMENTS
                ? (_.snakeCase(heading) as keyof UnionDataInterface)
                : (_.camelCase(heading) as keyof UnionDataInterface)
            ] as any | any[];
            if (tableName === ETableName.TOLLDOCUMENTS) {
              if (heading === 'status') {
                value = tollDocumentStatuses[value as number];
              }
              if (heading === 'uploaded_date') {
                value = ExportDateTimeformatHelper(value);
              }
            }
            if (tableName === ETableName.FLEET) {
              if (heading === 'vehicleClass') {
                value =
                  vehicleClassesList.find(vehicleClass => vehicleClass.classCode === value)
                    ?.displayName || value;
              }
              if (heading === 'issuingAgencyId') {
                value = tagIssuingAgencies?.find(
                  agency => Number(agency.id) === Number(value)
                )?.name;
                value = value === 'None' ? '' : value;
              }
            }

            let retval;

            if (value && value instanceof Date) retval = value;
            else if (value && typeof value === 'object') retval = value.join(', ');
            else if (value === 0) retval = value;
            else retval = value || '';

            return retval;
          })
        );

        if (fileType === 'csv') {
          const csvString = Papaparse.unparse(
            { fields: headingsUF, data },
            { escapeFormulae: true }
          );
          const file = new Blob([csvString], { type: 'text/csv' });
          const csvURL = window.URL.createObjectURL(file);
          const tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', `${fileName}.csv`);
          tempLink.click();
        }
        if (fileType === 'xlsx') {
          const preparedData = data.map((row: any) => {
            const obj: AnyObject = {};
            headingsUF.forEach((col, index) => {
              obj[col] = row[index];
            });
            return obj;
          });
          const wb = XLSX.utils.book_new();
          let ws1;
          if (tableName === ETableName.FLEET) {
            ws1 = XLSX.utils.json_to_sheet(preparedData, {
              cellDates: true,
              dateNF: 'mm/dd/yyyy hh:mm AM/PM', //yyyy-mm-dd hh:mm:ss // mm/dd/yyyy hh:mm AM/PM
              header: headingsUF
            });
          } else {
            ws1 = XLSX.utils.json_to_sheet(preparedData, {
              header: headingsUF
            });
          }
          XLSX.utils.book_append_sheet(wb, ws1, `${_.capitalize(fileName)} Data`);
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          return false;
        }
        if (fileType === 'pdf') {
          const doc = new JsPDF({ orientation: 'l' });
          autoTable(doc, {
            head: [headingsUF],
            headStyles: {
              fillColor: '#2a28f3'
            },
            body: data,
            styles: {
              minCellHeight: 9,
              halign: 'left',
              valign: 'middle',
              fontSize: 11
            }
          });
          doc.save(`${fileName}.pdf`);
          return false;
        }
        return false;
      },
      'export',
      'data'
    );
  };
  return { handleDataExport };
};
