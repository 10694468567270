/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETableName } from '@common/utils/enums';
import Cookies from 'js-cookie';
import { cookieSearch } from '@common/services/auth';

interface ISearch {
  active: boolean;
  field?: string;
  by: string | number | null;
}

const initialTableState: ISearch = {
  active: false,
  field: undefined,
  by: null
};

export const initialState: { [key in ETableName]: ISearch } = {
  fleet: initialTableState,
  customers: initialTableState,
  roles: initialTableState,
  trailers: initialTableState,
  transactions: initialTableState,
  trips: initialTableState,
  users: initialTableState,
  toll_documents: initialTableState
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    initSearch: (
      state,
      action: PayloadAction<{
        table: ETableName;
        field?: string;
        searchBy: string | number;
      }>
    ) => {
      const search = state[action.payload.table];
      if (action.payload.field !== 'trip duration') {
        if (!action.payload.searchBy.toString()) {
          search.active = false;
          search.field = undefined;
          search.by = null;
        } else {
          search.active = true;
          search.field = action.payload.field;
          search.by = action.payload.searchBy;
        }
      } else {
        if (!action.payload.searchBy) {
          search.active = false;
          search.field = undefined;
          search.by = null;
        } else {
          search.active = true;
          search.field = action.payload.field;
          search.by = action.payload.searchBy;
        }
      }
      Cookies.set(cookieSearch, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearSearch: (state, action: { payload: ETableName }) => {
      const search = state[action.payload];
      search.active = false;
      search.field = undefined;
      search.by = null;
      Cookies.set(cookieSearch, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearAllSearch: (state, action: { payload: ETableName | undefined }) => {
      if (action.payload) {
        const reserveState = state[action.payload];
        state = { ...initialState, [action.payload]: reserveState };
        return state;
      }
      Cookies.set(cookieSearch, JSON.stringify(state), { sameSite: 'None', secure: true });
      return initialState;
    },
    setSessionSearch: (state, action) => {
      state = JSON.parse(action.payload);
      return state;
    }
  }
});

export const { initSearch, clearSearch, clearAllSearch, setSessionSearch } = searchSlice.actions;

export default searchSlice.reducer;
