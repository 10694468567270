import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';
import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({
  buttonType = 'button',
  variant = 'primary',
  appearance = 'button',
  icon,
  disabled,
  size = 'l',
  link,
  linkState,
  className = '',
  ...props
}) => {
  const mode = `${styles[`container--${variant}`]}`;

  const renderButton = () => (
    <button
      aria-label={`button-${variant}`}
      type={buttonType === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      data-button-size={size}
      data-button-appearance={appearance}
      className={`${styles.container} ${mode} ${className}`}
      {...props}
    >
      {icon ? (
        <span data-testid="button-icon" className={styles.icon}>
          {icon}
        </span>
      ) : null}
      {'label' in props && props.label ? props.label : null}
    </button>
  );

  return (
    <>
      {link ? (
        <Link data-testid="button-link" to={link} state={linkState}>
          {renderButton()}
        </Link>
      ) : (
        renderButton()
      )}
    </>
  );
};

export default Button;
