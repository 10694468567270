import { TrailerDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<TrailerDataInterface>[] = [
  {
    heading: 'trailer id',
    field: 'id',
    active: true,
    filterType: 'text',
    placeholder: 'search trailer id'
  }
];
