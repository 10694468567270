/* eslint-disable no-multi-str */
export const errorsContent = [
  {
    code: 403,
    title: 'Access Denied',
    text: 'Sorry, but you don’t have permission to access this page. \
    You can go back to previous page.'
  },
  {
    code: 404,
    title: 'Page not found',
    text: 'This page you requested could not be found. You can go back to home page.'
  },
  {
    code: 501,
    title: 'Server error',
    text: 'We apologize and are fixing the problem. Please try again at a later time.'
  }
];
