/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/services/common';

const initialState: any = {
  loading: false,
  saTemplate: null
};

export const saTemplate = createAsyncThunk('getServiceAgreement', async (data: any, thunkAPI) => {
  const res = await api.post('/eula/template', data);
  return res.data;
});

export const serviceAgreementSlice = createSlice({
  name: 'serviceAgreement',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(saTemplate.pending, (state, action) => {
      state.loading = true;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(saTemplate.fulfilled, (state, action) => {
      // Add user to the state array
      state.saTemplate = action.payload;
      state.loading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {} = serviceAgreementSlice.actions;

export default serviceAgreementSlice.reducer;
