import { FC, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIcon } from '@assets/icons';
import Switch from '../../Switch';
import styles from './card.module.scss';
import { CardProps } from './types';

const Card: FC<CardProps> = ({ text, active, handleActiveState, id }) => {
  const toggle = useMemo(() => active, [active]);
  const handleToggle = () => {
    handleActiveState(`${text}`, !toggle);
  };
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <section className={styles['container-wrapper']}>
      <div
        ref={setNodeRef}
        style={style}
        className={isDragging ? `${styles.container} ${styles.dragging}` : styles.container}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        role="button"
        tabIndex={0}
      >
        <section className={styles.title}>
          <Switch toggle={toggle} handleToggle={handleToggle} />
          <h3>{text}</h3>
        </section>
        <span {...attributes} {...listeners} className={styles['drag-box']}>
          <DragIcon />
        </span>
      </div>
    </section>
  );
};
export default Card;
