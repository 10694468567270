import { Plus } from '@assets/icons';
import Modal from '@common/ui/Modal';
import { SubmitButton } from '../../../layouts/geotab-addin/components/form';
import { UploadTransaction, UploadTransactionFormik } from './create';

const AddNewTransaction = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <UploadTransactionFormik>
      <Modal
        activeTitle="singleHighlight"
        navigation={['New transaction']}
        customPrimaryButton={
          <SubmitButton
            label="Upload transaction"
            icon={<Plus />}
            type="create"
            closeModal={closeModal}
          />
        }
        customCloseFunc={closeModal}
        cancelButton={false}
      >
        <UploadTransaction />
      </Modal>
    </UploadTransactionFormik>
  );
};

export default AddNewTransaction;
