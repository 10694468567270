import { useAppDispatch, useAppSelector } from '@store/hooks';
import { CheckboxStateType } from '@common/ui/Checkbox/types';
import { UnionDataInterface } from '@common/types/data';
import {
  addSelectionID,
  clearSelectionIDs,
  removeSelectionID,
  setSelectionIDs
} from '@store/features/selection';
import { ITable } from '@store/data';
import { ETableName } from '@common/utils/enums';
import { RowVariant } from '../types';

export const useRowsSelection = (
  checkboxState: CheckboxStateType,
  variant: RowVariant,
  table: ITable,
  data?: UnionDataInterface
) => {
  const dispatch = useAppDispatch();
  const tableName = table.name as ETableName;
  const selectedRowsInTable = useAppSelector(state => state.selectionIds[tableName]);
  const selectAllRows = () => {
    if (
      checkboxState === 'checked' ||
      (selectedRowsInTable.length === (table?.data?.length as number) &&
        checkboxState === 'intermediate')
    ) {
      dispatch(
        clearSelectionIDs({
          table: tableName,
          value: table?.data || []
        })
      );
    } else {
      dispatch(
        setSelectionIDs({
          table: tableName,
          value: table?.data || []
        })
      );
    }
  };
  const selectSingleRow = () => {
    if (selectedRowsInTable?.find(el => el.id === data?.id)) {
      if (data?.id)
        dispatch(
          removeSelectionID({
            table: tableName,
            value: data
          })
        );
    } else {
      return (
        data?.id &&
        dispatch(
          addSelectionID({
            table: tableName,
            value: data
          })
        )
      );
    }
    return null;
  };

  let selectRow;
  if (variant === 'header') selectRow = selectAllRows;
  else if (variant === 'data') {
    selectRow = selectSingleRow;
  } else {
    selectRow = () => null;
  }
  return { selectRow };
};
