import { useState } from 'react';
import { EmailSent, AuthBody } from '../../../layout/components';
import FormComp from '../Form/Form';
import styles from './card.module.scss';

const Card = () => {
  const [emailSent, setEmailSent] = useState<[boolean, string]>([false, '']);
  const handleEmailSent = (value: [boolean, string]) => setEmailSent(value);
  return (
    <div className={styles['main-container']}>
      <AuthBody
        className={`${styles.body} ${emailSent[0] ? styles['email-sent'] : ''}`}
        title={emailSent[0] ? 'Check your E-mail' : ''}
      >
        <div className={styles['forgot-password']}>
          <p>
            Please contact your <b>Administrator</b> or send an email to
          </p>
          <h3>
            <a href="mailto:support@commerce-logic.com">support@commerce-logic.com</a>
          </h3>
        </div>
        <div className={styles.container}>
          {emailSent[0] ? (
            <EmailSent handleEmailSent={handleEmailSent} />
          ) : (
            <FormComp handleEmailSent={handleEmailSent} />
          )}
        </div>
      </AuthBody>
    </div>
  );
};

export default Card;
