import { Formik, Form } from 'formik';
import { TextInput } from '@common/ui/Form';
import Button from '@common/ui/Button';
import _ from 'lodash';
import * as Yup from 'yup';
import { routes } from '@common/utils/route-names';
import styles from './form.module.scss';
import { useLogin } from './hooks';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('enter a valid email').required('email is required'),
  password: Yup.string().required('password is required')
});

const FormComp = () => {
  const { handleLogin, error, loading } = useLogin();
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        handleLogin(values.email, values.password, setSubmitting);
      }}
    >
      {({ errors, values }) => (
        <Form className={styles.container}>
          <TextInput className={styles.input} label="E-mail" name="email" type="email" />
          <TextInput
            className={styles.input}
            label="Password"
            name="password"
            type="password"
            enableTooltip={false}
          />
          {error[0] && typeof error[1] === 'string' && <p className={styles.error}>{error[1]}</p>}
          {error[0] && typeof error[1] !== 'string' && (
            <p className={styles.error}>An Error Occurred. Please Try Again</p>
          )}
          <Button
            appearance="button"
            disabled={
              _.compact(Object.values(values || {})).length < 2 ||
              _.compact(Object.values(errors || {})).length > 0 ||
              loading
            }
            size="s"
            label={loading ? 'Signing in...' : 'Sign in'}
            buttonType="submit"
          />
          <Button
            appearance="text"
            link={routes.forgot_password}
            size="s"
            label="Forgot password?"
          />
        </Form>
      )}
    </Formik>
  );
};

export default FormComp;
