/* eslint-disable */
import { useState, useEffect } from 'react';
import Modal from '@common/ui/Modal';
import { VehicleDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { history, trips } from '@store/data/fleetTabs/selector';
import { AnimatePresence } from 'framer-motion';
import { fleetHistory, fleetTrips } from '@store/data/fleetTabs';
import { useAppSelector } from '@store/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { FormSubmitButton, CrossFilterButtons } from '../../../buttons';
import { UpdateVehicle, UpdateVehicleFormik } from '../../../forms/Update';
import { MotionDiv } from '../shared';
import TripsList from './TripsList/TripsList';
import styles from './vehicle.module.scss';
import { useHandleActiveTitle } from '../shared/hooks/useHandleActiveTitle';
import History from './History/History';
import Alert from './Alert';

const Vehicle = ({ data }: { data: VehicleDataInterface }) => {
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleActiveTitle('details');
  const [modify_data] = useState<
    Omit<VehicleDataInterface, 'height' | 'weight'> & {
      height: string | number | null;
      weight: string | number | null;
    }
  >({ ...data });
  const isMetric = useSelector((state: any) => state.auth.isMetric);
  const customerId = useAppSelector(state => state.customer.id);
  const dispatch = useDispatch();
  const historyData = useSelector(history);
  const tripData = useSelector(trips);

  useEffect(() => {
    //@ts-ignore
    dispatch(fleetHistory({ id: modify_data.id, customerId }));
    dispatch(
      //@ts-ignore
      fleetTrips({
        date: {
          startDate: '',
          endDate: ''
        },
        toll: null,
        tripDistance: null,
        tripDuration: null,
        customerId: modify_data.customerId,
        pageIndex: 0,
        pageSize: 10,
        searchKey: modify_data.name,
        sort: {
          fieldName: '',
          order: ''
        }
      })
    );
  }, []);

  if (isMetric) {
    (modify_data.height = data.height && Math.round((data.height / 39.37) * 100) / 100), 2;
    modify_data.weight = data.weight && Math.round((data.weight / 2.205) * 100) / 100;
  } else {
    (modify_data.height = data.height), 2;
    (modify_data.weight = data.weight), 2;
  }
  modify_data.licensePlate = modify_data.licensePlate?.toUpperCase();
  modify_data.plateStateD = modify_data.plateState?.length > 0 ? modify_data.plateState : 'None';
  return (
    <UpdateVehicleFormik data={modify_data}>
      <Modal
        activeTitle={activeTitle}
        navigation={['details', 'trips', 'history', 'alerts']}
        customPrimaryButton={activeTitle === 'details' ? <FormSubmitButton fleet={true} /> : <></>}
        customExtraButtons={<CrossFilterButtons tableName={ETableName.FLEET} entryData={[data]} />}
        cancelButton={false}
        className={styles.modal}
        handleActiveTitle={handleActiveTitle}
        helpLocation="vehicle_"
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
            {activeTitle === 'details' && <UpdateVehicle updatedAt={data.updatedAt} />}
            {activeTitle === 'trips' && <TripsList tripsData={tripData} />}
            {activeTitle === 'history' && <History historyData={historyData} />}
            {activeTitle === 'alerts' && <Alert commonTab vehicleId={data.id} />}
          </MotionDiv>
        </AnimatePresence>
      </Modal>
    </UpdateVehicleFormik>
  );
};

export default Vehicle;
