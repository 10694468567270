import { motion } from 'framer-motion';
import { FC } from 'react';
import { useModalMotionVariants } from '../hooks/useModalMotionVariants';
import { MotionDivProps } from './types';

const MotionDiv: FC<MotionDivProps> = ({ activeTitle, prevTitle, children }) => {
  const [motionInitial, motionExit] = useModalMotionVariants(activeTitle, prevTitle);
  return (
    <motion.div
      initial={{ x: motionInitial, opacity: 0 }}
      animate={{
        x: 0,
        opacity: 1
      }}
      exit={{ x: motionExit, opacity: 0 }}
      transition={{ duration: 0.2, delay: 0 }}
      key={activeTitle}
    >
      {children}
    </motion.div>
  );
};

export default MotionDiv;
