import { useEffect, useState } from 'react';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import Carousel from 'react-material-ui-carousel';
import { Grid, Typography, Skeleton, Box, useTheme, useMediaQuery } from '@mui/material';
import HtmlParse from 'html-react-parser';
import Modal from '@common/ui/Modal';
import { useAppDispatch } from '@store/hooks';
import { AnimatePresence } from 'framer-motion';
import { changeShowBy, setCurrentPage } from '@store/features/pagination';
import { clearAlertFilters } from '@store/data/fleetTabs';
import { ETableName } from '@common/utils/enums';
import Alert from '@pages/tables/layouts/web-portal/components/modals/RowModal/Vehicle/Alert';
import styles from './recent-alerts.module.scss';
import { useGetRecentAlerts } from './hooks';

const RecentAlerts = () => {
  const [groupSize, setGroupSize] = useState(3);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.between(850, 1000));
  const isLg = useMediaQuery(theme.breakpoints.between(1000, 7000));

  const dispatch = useAppDispatch();
  const { data, loader } = useGetRecentAlerts(groupSize);
  const [showAlert, setShowAlert] = useState(false);
  const [alertId, setAlertId] = useState('');

  useEffect(() => {
    if (isLg) {
      setGroupSize(3);
    } else if (isMd) {
      setGroupSize(2);
    } else {
      setGroupSize(1);
    }
  }, [isMd, isLg]);

  return (
    <div className={styles.container}>
      <MaterialCard
        title="Recent Alerts"
        helpLocation="recent_alerts"
        hasBorder
        noGrid
        cardHeight="39rem"
        cardVariant="parent"
      >
        {loader ? (
          <Box component="div" className={styles.loader}>
            {Array.from({ length: groupSize }).map(() => (
              <Skeleton height="20rem" width={`${100 / groupSize}%`} variant="rectangular" />
            ))}
          </Box>
        ) : (
          <>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  className={styles.viewall}
                  onClick={() => {
                    dispatch(changeShowBy({ table: 'alerts' as ETableName, showBy: 20 }));
                    setShowAlert(true);
                  }}
                  component="div"
                >
                  View All
                </Typography>
              </Grid>
              <Carousel
                indicators={false}
                navButtonsAlwaysVisible
                autoPlay={false}
                animation="slide"
                className={styles['carousel-container']}
                height="26rem"
              >
                {data.map(item => (
                  <Grid container direction="row" justifyContent="center">
                    <Grid style={{ width: '90%' }} container direction="row" spacing={2}>
                      {item.map(value => (
                        <Grid xs={12 / groupSize} item>
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                              if (item[0].description !== 'View All') {
                                dispatch(
                                  changeShowBy({ table: 'alerts' as ETableName, showBy: 20 })
                                );
                                setAlertId(value.id);
                                setShowAlert(true);
                              }
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                console.log('Enter key pressed', item);
                              }
                            }}
                            className={styles['card-wrapper']}
                          >
                            <MaterialCard
                              showHeaderArrow={value.alert_subtype !== 0}
                              className={styles.card}
                              title={value.alert_type}
                              noGrid
                              cardVariant="child"
                              overflow="hidden"
                            >
                              {value.alert_subtype === 0 ? (
                                <Box
                                  className={styles.centeredTypography}
                                  onClick={() => {
                                    dispatch(
                                      changeShowBy({ table: 'alerts' as ETableName, showBy: 20 })
                                    );
                                    setShowAlert(true);
                                  }}
                                  component="div"
                                >
                                  <Typography className={styles['custom-font-size']}>
                                    {value.description}
                                  </Typography>
                                </Box>
                              ) : (
                                <Box component="div" className={styles.content}>
                                  <Typography className={styles['text-space']}>
                                    {HtmlParse((value.description || '').trim())}
                                  </Typography>
                                </Box>
                              )}
                            </MaterialCard>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Carousel>
            </Grid>
            {showAlert && (
              <Modal
                activeTitle="Alerts"
                navigation={['Alerts']}
                primaryButton={false}
                customCloseFunc={() => {
                  dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 1 }));
                  dispatch(changeShowBy({ table: 'alerts' as ETableName, showBy: 10 }));
                  dispatch(clearAlertFilters());
                  setShowAlert(false);
                }}
              >
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Alert alertId={alertId} />
                </AnimatePresence>
              </Modal>
            )}
          </>
        )}
      </MaterialCard>
    </div>
  );
};

export default RecentAlerts;
