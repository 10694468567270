export function Dot({
  color,
  width = '2em',
  height = '2em'
}: {
  color: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      id="bullet"
      fill={color ? color : 'black'}
    >
      <path d="M24 30c-3.312 0-6-2.688-6-6s2.688-6 6-6 6 2.688 6 6-2.688 6-6 6z"></path>
    </svg>
  );
}
