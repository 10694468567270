import { Form } from 'formik';
import { useEffect, useState } from 'react';
import { FileInput, SelectInput } from '@common/ui/Form';
import { statementTypesList } from '@common/utils/constants/lists';
// import { useGetTollDocumentTypesQuery } from '@api/services/lists';
import styles from './upload-transaction.module.scss';

const UploadTransaction = () => {
  const [tollDocumentTypes, setTollDocumentTypes] = useState<string[]>([]);
  // const { data } = useGetTollDocumentTypesQuery();
  useEffect(() => {
    setTollDocumentTypes(statementTypesList.map(tollDocument => tollDocument.type));
  }, []);

  return (
    <Form className={styles.container}>
      <FileInput
        className={styles.input}
        label="Transaction File"
        field="file"
        format={['.csv', '.xlsx', '.xls', '.pdf']}
      />
      <SelectInput
        values={tollDocumentTypes}
        label="Toll Document Type"
        formField="tollDocumentType"
        className={styles.input}
        placeholder="Select Toll Document Type"
      />
    </Form>
  );
};

export default UploadTransaction;
