import { ExternalLink } from '@assets/icons';
import Modal from '@common/ui/Modal';
import { FormSubmitButton } from '../../../buttons';
import { CreateCustomer, CreateCustomerFormik } from '../../../forms/Create';

const Customer = () => {
  return (
    <CreateCustomerFormik>
      <Modal
        activeTitle="singleHighlight"
        navigation={['New customer']}
        customPrimaryButton={
          <FormSubmitButton label="Send invite" icon={<ExternalLink />} type="create" />
        }
        cancelButton={false}
      >
        <CreateCustomer />
      </Modal>
    </CreateCustomerFormik>
  );
};

export default Customer;
