import { FC } from 'react';
import _ from 'lodash';
import { Close } from '@assets/icons';
import { DATE_KEYS_ARRAY, NUMBER_KEYS_ARRAY } from '@common/utils/constants';
import styles from './tag.module.scss';
import { TagItemGroupProps, TagProps } from './types';

const Tag: FC<TagProps> = ({
  item,
  handleSelectionState,
  selection,
  size = 's',
  itemGroup,
  itemDisplayName,
  inSelect,
  separator = ' - ',
  ignoreCheck = false,
  disabled
}) => {
  const isDate = itemGroup && DATE_KEYS_ARRAY.includes(itemGroup);
  const isNumber = itemGroup && NUMBER_KEYS_ARRAY.includes(itemGroup);
  const SPECIFIC_BOOLEAN_KEYS_ARRAY = ['trailers', 'blocked', 'deleted'];
  const isSpecificBoolean = itemGroup && SPECIFIC_BOOLEAN_KEYS_ARRAY.includes(itemGroup);
  const removeTag = () => {
    if (isSpecificBoolean) {
      (handleSelectionState as VoidFuncSP<TagItemGroupProps[]>)(
        (selection as TagItemGroupProps[]).filter(value => {
          if (value.itemGroup === 'billingStatus') {
            if (_.kebabCase(item.toString()).includes(_.kebabCase(value.item.toString()))) {
              return false;
            }
            return true;
          }
          return _.kebabCase(value.itemGroup) !== _.kebabCase(itemGroup);
        })
      );
    } else if (!itemGroup) {
      (handleSelectionState as VoidFuncSP<(string | number)[]>)(
        (selection as (string | number)[]).filter(
          value => _.kebabCase(`${value}`) !== _.kebabCase(`${item}`)
        )
      );
    } else if (!ignoreCheck && (isDate || isNumber)) {
      (handleSelectionState as VoidFuncSP<TagItemGroupProps[]>)(
        (selection as TagItemGroupProps[]).filter(
          value => _.kebabCase(value.itemGroup) !== _.kebabCase(itemGroup)
        )
      );
    } else if (itemGroup && ignoreCheck) {
      (handleSelectionState as VoidFuncSP<TagItemGroupProps[]>)(
        (selection as TagItemGroupProps[]).filter(
          value => _.kebabCase(`${value.item}`) !== _.kebabCase(`${item}`)
        )
      );
    }
  };
  return (
    <section
      aria-label="tag"
      data-tag-size={size}
      data-tag-disabled={disabled}
      className={styles.container}
    >
      {(isSpecificBoolean || !ignoreCheck) && (isDate || isNumber || isSpecificBoolean)
        ? `${item}`
        : `${
            itemGroup ? `${_.upperFirst(itemDisplayName || itemGroup)}${separator}${item}` : item
          }`}
      <button
        aria-label="tag-remove"
        type="button"
        onClick={e => {
          if (!disabled) {
            e.stopPropagation();
            removeTag();
          }
        }}
        className={inSelect ? `${styles.remove}  ignore-onclickoutside` : styles.remove}
      >
        <Close />
      </button>
    </section>
  );
};

export default Tag;
