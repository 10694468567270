/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import { useAppSelector } from '@store/hooks';
import api from '@api/services/common';
import { ColorMap, IApiResponse, ITbsMod, ITreeChartData } from '../type';

const useTollBillSummaryData = (
  selectedMonth: string
): {
  tbsModData: ITbsMod | undefined;
  chartData: ITreeChartData[];
  isLoading: boolean;
  showError: boolean;
  colorMapping: ColorMap;
  fetchTollBillSummaryData: () => void;
} => {
  const customerId = useAppSelector(state => state.customer.id);
  const [tbsModData, setTbsModData] = useState<ITbsMod>();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [chartData, setChartData] = useState<ITreeChartData[]>([]);
  const [colorMapping, setColorMapping] = useState<ColorMap>({});
  const fetchTollBillSummaryData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setShowError(false);
      const response: IApiResponse = await api.post('/insights/toll-bill-summary', {
        moduleId: selectedMonth.length > 0 ? 'MONTHLY_DETAIL' : 'INSIGHTS',
        monthDate: selectedMonth
      });
      if (response && response.isSuccess && response.data) {
        const structuredData: ITbsMod = {
          expense: {
            amount:
              response.data.toll_bill_summary.find(x => x.type === 0)?.amount.toString() || '0',
            percent:
              response.data.toll_bill_summary
                .find(x => x.type === 0)
                ?.percentage_value.toString() || '0'
          },
          subTypes: response.data.toll_bill_summary
            .filter(x => [1, 2, 3, 4].includes(x.type))
            .map(item => ({
              label: item.label,
              amount: item.amount.toString(),
              percent: item.percentage_value.toString()
            })),
          correction: {
            amount:
              response.data.toll_bill_summary.find(x => x.type === 5)?.amount.toString() || '0',
            percent:
              response.data.toll_bill_summary
                .find(x => x.type === 5)
                ?.percentage_value.toString() || '0'
          },
          actualTollBill: {
            amount:
              response.data.toll_bill_summary.find(x => x.type === 7)?.amount.toString() || '0',
            percent:
              response.data.toll_bill_summary
                .find(x => x.type === 7)
                ?.percentage_value.toString() || '0'
          },
          managedTollBill: {
            amount:
              response.data.toll_bill_summary.find(x => x.type === 6)?.amount.toString() || '0',
            percent:
              response.data.toll_bill_summary
                .find(x => x.type === 6)
                ?.percentage_value.toString() || '0'
          },
          tollBillDate: {
            from: response.data.date.from,
            to: response.data.date.to,
            updated: response.data.date.to
          }
        };
        const findPercent = (amount: number, percent: string): number => {
          const percentageValue = parseFloat(percent);
          const filteredItems = response.data.toll_bill_summary.filter(x =>
            [1, 2, 3, 4].includes(x.type)
          );
          const nonZeroPercentages = filteredItems
            .map(item => parseFloat(item.percentage_value))
            .filter(value => value > 0);

          const leastNonZeroPercent = Math.min(...nonZeroPercentages);
          if (leastNonZeroPercent === percentageValue) {
            return Math.max(4, Math.min(70, percentageValue));
          }
          if (amount === 0) {
            return Math.max(2, Math.min(70, percentageValue));
          }
          return Math.max(10, Math.min(70, percentageValue));
        };
        const structuredChartData = response.data.toll_bill_summary
          .filter(x => [1, 2, 3, 4].includes(x.type))
          .map(item => ({
            name: item.label,
            // Percentage ranges between 10 - 70
            // Math.max(10, Math.min(70, parseFloat(item.percentage_value)))
            percent: findPercent(item.amount, item.percentage_value),
            value: item.amount.toString()
          }));

        const colorData = {
          [structuredChartData[0].name]: '#ABC8E5',
          [structuredChartData[1].name]: '#1F4C6B',
          [structuredChartData[2].name]: '#4F7292',
          [structuredChartData[3].name]: '#7C9CBB'
        };
        setColorMapping(colorData);
        setChartData(structuredChartData);
        setTbsModData(structuredData);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Error in calling toll bill summary data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchTollBillSummaryData().catch(err => {
        console.log('Error in toll bill summary data ==> ', err);
      });
    }
  }, [customerId, selectedMonth]);

  return { tbsModData, colorMapping, chartData, isLoading, showError, fetchTollBillSummaryData };
};

export default useTollBillSummaryData;
