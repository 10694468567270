import { FC } from 'react';
import { Formik, Form } from 'formik';
import Button from '@common/ui/Button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@common/utils/route-names';
import { useForgotPasswordMutation } from '@api/services/auth';
import * as Yup from 'yup';
import styles from './form.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('enter a valid email').required('email is required')
});

const FormComp: FC<{ handleEmailSent: (value: [boolean, string]) => void }> = () => {
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        forgotPassword(values.email)
          .unwrap()
          .then(() => {
            navigate(routes.reset_password, { replace: true, state: { email: values.email } });
          })
          .catch(err => {
            console.log(err);
            setFieldError('email', 'an error occurred, please try again');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        resetForm
        //  isSubmitting
      }) => (
        <Form className={styles.container}>
          {/* <TextInput className={styles.input} label="E-mail" name="email" type="email" />
          <Button
            appearance="button"
            disabled={isSubmitting}
            size="s"
            label={isSubmitting ? 'Submitting...' : 'Reset Password'}
            buttonType="submit"
          /> */}
          <Button
            appearance="text"
            link={routes.login}
            onClick={resetForm}
            size="s"
            label="Go Back"
          />
        </Form>
      )}
    </Formik>
  );
};

export default FormComp;
