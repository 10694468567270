/**
 * Formats a number
 * @param num - The number to be formatted
 * @param digits - The number of digits that should come after the decimal point (optional),
 * defaults to 2
 * @param prefix - The prefix to add to the number (optional)
 * @param suffix - The suffix to add to the number (optional)
 * @returns the formatted entry 'num'
 */
export const formatNumber = (
  num: number | string,
  digits?: number,
  prefix?: string,
  suffix?: string
) => {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  const parsedNum = typeof num === 'string' ? parseFloat(num) : num;

  for (let i = units.length - 1; i >= 0; i -= 1) {
    const decimal = 1000 ** (i + 1);

    if (parsedNum <= -decimal || parsedNum >= decimal) {
      return `${prefix || ''}${+(parsedNum / decimal).toFixed(digits !== undefined ? digits : 2)}${
        units[i]
      } ${suffix || ''}`.trim();
    }
  }

  return prefix || suffix
    ? `${prefix || ''}${parsedNum?.toFixed(digits !== undefined ? digits : 2)} ${
        suffix || ''
      }`.trim()
    : parsedNum?.toFixed(digits !== undefined ? digits : 2);
};
