import { FC } from 'react';
import styles from './toggle.module.scss';
import { ToggleProps } from './types';

const Toggle: FC<ToggleProps> = ({
  state,
  handleToggle,
  disabled,
  className = '',
  label1,
  label2
}) => (
  <>
    {disabled ? (
      <section
        aria-label="toggle"
        data-toggle-state={state}
        className={`${styles.container} ${className}`}
        data-toggle-disabled={disabled}
      >
        <button
          type="button"
          onClick={() => !disabled && handleToggle(true)}
          data-toggle-label="true"
          className={state ? styles.active : ''}
        >
          {label1 ?? 'Yes'}
        </button>
        <button
          type="button"
          onClick={() => !disabled && handleToggle(false)}
          data-toggle-label="false"
          className={!state ? styles.active : ''}
        >
          {label2 ?? 'No'}
        </button>
      </section>
    ) : (
      <section
        aria-label="toggle"
        data-toggle-state={state}
        className={`${styles.container} ${className}`}
      >
        <button
          type="button"
          onClick={() => !disabled && handleToggle(true)}
          data-toggle-label="true"
          className={state ? styles.active : ''}
        >
          {label1 ?? 'Yes'}
        </button>
        <button
          type="button"
          onClick={() => !disabled && handleToggle(false)}
          data-toggle-label="false"
          className={!state ? styles.active : ''}
        >
          {label2 ?? 'No'}
        </button>
      </section>
    )}
  </>
);

export default Toggle;
