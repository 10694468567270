import CLlogo from '@assets/images/logo.svg';
import GTlogo from '@assets/images/geotab-logo.png';
import styles from './loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>Validating user</div>
      <div className={styles.body}>
        <img className={styles['geotab-logo']} src={GTlogo} alt="geotab-logo" />
        <div className={styles['sso-loader']} />
        <img className={styles['cl-logo']} src={CLlogo} alt="cl-logo" />
      </div>
    </div>
  );
};

export default Loader;
