import { FC } from 'react';
import Legend from './Legend/Legend';
import styles from './map-header.module.scss';
import TripType from './TripType/TripType';
import { MapHeaderProps } from './types';

const MapHeader: FC<MapHeaderProps> = ({ headerDetails, handleTripType, tripType }) => {
  return (
    <section className={styles.container}>
      <TripType headerDetails={headerDetails} tripType={tripType} handleTripType={handleTripType} />
      <Legend />
    </section>
  );
};

export default MapHeader;
