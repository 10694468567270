import { VehicleDataInterface } from '@common/types/data';

export const initialValues: Omit<
  VehicleDataInterface,
  'id' | 'updatedAt' | 'dateCreated' | 'customerId' | 'createdAt' | 'deviceId' | 'tires' | 'wheels'
> & { plateStateD: string } = {
  isTouched: false,
  name: '',
  vehicleClass: '',
  vehicleClassD: '',
  vin: '',
  type: '',
  weight: 0,
  height: 0,
  axles: 0,
  tagType: '',
  tagTypeD: '',
  tagTypeId: null,
  tagTransponder: '',
  agencyName: '',
  issuingAgencyId: null,
  issuingAgencyIdD: '',
  plateState: '',
  plateStateD: '',
  plateNumber: '',
  tollConsolidator: false,
  secondTagExists: false,
  secAgencyName: '',
  secIssuingAgencyIdD: '',
  secTagTypeD: '',
  secTagType: '',
  secTagTypeId: null,
  secTagTransponder: '',
  secIssuingAgencyId: null,
  trdAgencyName: '',
  trdIssuingAgencyIdD: '',
  trdTagTypeD: '',
  trdTagType: '',
  trdTagTypeId: null,
  trdTagTransponder: '',
  trdIssuingAgencyId: null,
  childTags: null,
  currentDriver: '',
  vehicleModel: '',
  licensePlate: '',
  devicePlan: '',
  devicePlanD: '',
  billingStatus: '',
  billingStatusD: '',
  billingStatusUpdatedAt: '',
  gpsReliability: ''
};
