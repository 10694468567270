import { Formik, Form } from 'formik';
import { TextInput } from '@common/ui/Form';
import { useCreatePasswordMutation } from '@api/services/auth';
import Button from '@common/ui/Button';
import _ from 'lodash';
import { createInitialValues } from './utils/initialValues';
import { createValidationSchema } from './utils/validationSchema';
import styles from './form.module.scss';

const FormComp = ({
  handlePasswordCreated,
  email
}: {
  handlePasswordCreated: (value: boolean) => void;
  email: string;
}) => {
  const [createPassword] = useCreatePasswordMutation();
  return (
    <Formik
      initialValues={createInitialValues}
      validationSchema={createValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          email,
          password: values.password
        };
        createPassword(data)
          .unwrap()
          .then(() => {
            handlePasswordCreated(true);
          })
          .catch((error: unknown) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ resetForm, errors, values, isSubmitting }) => (
        <Form className={styles.container}>
          <TextInput
            className={styles.input}
            label="New password"
            name="password"
            type="password"
          />
          <TextInput
            className={styles.input}
            label="Repeat password"
            name="repeat_password"
            type="password"
          />
          <Button
            appearance="button"
            disabled={
              _.compact(Object.values(values || {})).length < 2 ||
              _.compact(Object.values(errors || {})).length > 0 ||
              isSubmitting
            }
            size="s"
            label={isSubmitting ? 'Creating...' : 'Confirm password'}
            buttonType="submit"
          />
          <Button appearance="text" size="s" label="Cancel" onClick={resetForm} />
        </Form>
      )}
    </Formik>
  );
};

export default FormComp;
