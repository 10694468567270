import { useRef, FC } from 'react';
import styles from './select-input-display.module.scss';
import { SelectInputDisplayProps } from './types';
import SelectInputIcons from '../SelectInputIcons';
import SelectInputContent from '../SelectInputContent';

const SelectInputDisplay: FC<SelectInputDisplayProps> = ({
  isOpen,
  disabled,
  selection,
  icon,
  placeholder,
  error,
  enableTooltip,
  handleSelectionState
}) => {
  const selectionBoxRef = useRef<HTMLDivElement>(null);
  const selectionBoxStyles =
    isOpen && !error && !disabled
      ? `${styles.container} ${styles['container-active']}`
      : styles.container;

  const getFieldTooltip = () => {
    if (enableTooltip) {
      if (selection !== '' && (typeof selection === 'string' || typeof selection === 'number')) {
        return `${selection}`;
      }
      return placeholder;
    }
    return '';
  };

  return (
    <div
      aria-label="select-input-display"
      data-select-error={!disabled && error}
      className={selectionBoxStyles}
      ref={selectionBoxRef}
      title={getFieldTooltip()}
    >
      {icon && <span className={styles['icon-container']}>{icon}</span>}
      <SelectInputContent
        placeholder={placeholder}
        className={styles['container-selection']}
        selection={selection}
        handleSelectionState={handleSelectionState}
      />
      <SelectInputIcons
        selection={selection}
        handleSelectionState={handleSelectionState}
        isOpen={isOpen}
      />
    </div>
  );
};

export default SelectInputDisplay;
