import Logo from '@common/ui/Logo';
import styles from './not-enrolled.module.scss';

const NotEnrolled = () => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <h2 className={styles.title}>Warning</h2>
      <div className={styles.text}>
        <h3>Your organization is not enrolled to tollogic.</h3>
        <p>Due to insufficient privileges, we are unable to proceed further.</p>
        <p>
          Please contact your account administrator to make sure you are authorized to view this
          content.
        </p>
      </div>
    </div>
  );
};

export default NotEnrolled;
