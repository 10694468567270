import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { Approve } from '@assets/icons';
import styles from './select-menu.module.scss';
import { SelectMenuProps } from './types';

const SelectMenu: FC<SelectMenuProps> = ({
  selection,
  searchResults,
  values,
  className = '',
  handleSelectionState,
  handleOpenState
}) => {
  const isTags = Array.isArray(selection);
  const listStyles = (value: string | number) =>
    !isTags && value === selection ? `${styles.container} ${styles.selected}` : styles.container;

  const tagListStyles = (value: string | number) =>
    Array.isArray(selection) && selection.find(item => item === value)
      ? `${styles.container} ${styles.selected}`
      : styles.container;

  const onListItemClick = (value: string | number) => {
    if (isTags) {
      (handleSelectionState as (value: (string | number)[]) => void)(
        selection.find(item => item === value)
          ? selection.filter(item => item !== value)
          : [...selection, value]
      );
    } else {
      (handleSelectionState as (value: string | number) => void)(value);
      handleOpenState(false);
    }
  };

  return (
    <>
      {(searchResults !== null ? searchResults : values).map((value: string | number) => (
        <li aria-label="dropdown-select-item" key={uuid()}>
          <button
            type="button"
            data-is-tags={isTags}
            className={
              isTags ? `${tagListStyles(value)} ${className}` : `${listStyles(value)} ${className}`
            }
            onClick={() => onListItemClick(value)}
          >
            {isTags && (
              <span className={styles.checkbox}>
                {selection.find((item: string | number) => item === value) && (
                  <Approve className={styles['check-icon']} />
                )}
              </span>
            )}
            {value}
            {!isTags && value === selection && <Approve className={styles.approve} />}
          </button>
        </li>
      ))}
    </>
  );
};

export default SelectMenu;
