/* eslint-disable */
import { FC, memo, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Skeleton } from '@mui/material';
import { TreeMapProps } from './type';
import { Chart as ChartJS, ChartData, CategoryScale, BarElement, Tooltip, Legend } from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { LinearScale } from 'chart.js';
import { Title } from 'chart.js';
import { formatCurrency } from '@pages/dashboard/common/helper';

const TreemapChart: FC<TreeMapProps> = ({ data, options, colorMap, hasAmount }) => {
  const [chartLoader, setChartLoader] = useState(true);

  // Function to determine if a color is light or dark
  const  getTextColor=(backgroundColor:any)=> {
    const color = backgroundColor.substring(1); // strip #
    const rgb = parseInt(color, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >>  8) & 0xff; // extract green
    const b = (rgb >>  0) & 0xff; // extract blue

    const luma = 0.299 * r + 0.587 * g + 0.114 * b; // calculate luma
    return luma > 150 ? 'black' : 'white'; // bright colors have luma > 186
}

function truncateText(ctx:any, text:any, maxWidth:any) {
  let actualtext = text;
  const ellipsis = '...';
  ctx.font = '13px Inter, sans-serif';
  let width = ctx.measureText(text).width;
  
  if (width <= maxWidth) {
      return text;
  }
  let isbreak=false;
  while (width > maxWidth) {
    if (!text) { isbreak=true;break;}
      text = text.slice(0, -1);
      width = ctx.measureText(text + ellipsis+ ".").width;
      
  }
  return isbreak? actualtext: text + ellipsis;
}

      
  const treeMapData: ChartData<'treemap'> = {
    datasets: [
      {
        data: [],
        tree: data,
        key: 'percent',
        labels: {
          display: true,
          padding:8,
          font: [{
            size: 13,
            family: "Inter, sans-serif"
            //weight: "bold"
            },
            {
               family: "Inter, sans-serif",
              size: 12
            }
          ],
          color: (ctx:any,options)=>
          {
          
          return  getTextColor(ctx.element.options.backgroundColor);
          },
          formatter: (context: any) => {
            const text = context.raw._data.name;
            const boxWidth =  context.raw.w // context.element.width;
            const chartContext = context.chart.ctx;
            let cutText = truncateText(chartContext, text, boxWidth);

            if (hasAmount) {
              
               const originalLabel = context.raw._data || null;
              // if (!originalLabel) {
              //   return context.raw._data.name;
              // }
              // let customLabel = `${
              //   cutText
              // }`;
              // const labelLimitter = window.innerWidth <= 1500 ? 8 : 12;
              // if (
              //   originalLabel?.percent &&
              //   originalLabel?.percent <= 10 &&
              //   customLabel.length > labelLimitter
              // ) {
              //   customLabel = customLabel.substring(0, labelLimitter) + '...';
              // }
              return [  formatCurrency(originalLabel.value, true),cutText];
            }
            return cutText;
          },
          overflow: 'cut',
          align: 'left',
          position: 'top'
        },
        spacing: 1,
        backgroundColor: (context: any) => {
          if (context.type !== 'data') return 'transparent';
          const color = colorMap[context.raw._data.name];
          return color ? color : 'grey';
        }
      }
    ]
  };
  useEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
      TreemapController,
      TreemapElement
    );

    const timeoutId = setTimeout(() => {
      setChartLoader(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data, options, colorMap, hasAmount]);

  return (
    <>
      {chartLoader ? (
        <Skeleton style={{ marginTop: '1rem' }} height="30rem" variant="rectangular" />
      ) : (
        <Chart type="treemap" data={treeMapData} options={options} />
      )}
    </>
  );
};

export default memo(TreemapChart);
