/* eslint-disable no-nested-ternary */
import { ArrowsFilled } from '@assets/icons';
// import { UserDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
// import { sortUsers } from '@pages/tables/users/helpers/sortUsers';
// import { clearTable, setTable } from '@store/data';
import { setCurrentPage } from '@store/features/pagination';
import { enableSorting } from '@store/features/sort';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
import styles from '../column.module.scss';

const Header = ({
  tableName,
  field,
  heading
}: {
  tableName: ETableName;
  field?: string;
  heading: string;
}) => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(state => state.sort[tableName]);
  // const table = useAppSelector(state => state.table);
  // ? handle header sorting and sorted header styles
  const isSortedStyles =
    ([ETableName.TOLLDOCUMENTS, ETableName.CUSTOMERS, ETableName.USERS].includes(tableName) &&
      sorting.by === (field || _.camelCase(heading))) ||
    sorting.by === (heading || field || _.camelCase(heading)) ||
    (tableName === ETableName.TRIPS &&
      sorting.by === 'estimated toll' &&
      _.kebabCase(heading) === 'tollogic™-tolls') ||
    (tableName === ETableName.TRANSACTIONS &&
      sorting.by === 'statusMessage' &&
      _.kebabCase(heading) === 'status')
      ? `${styles.container} ${styles['sort-by']}`
      : styles.container;

  const handleSort = () => {
    // if (tableName === ETableName.USERS) {
    //   const sortedData = sortUsers(heading, table.data as UserDataInterface[]);
    //   const isLoading = false;
    //   const isError = false;
    //   const isSuccess = true;
    //   const isFetching = false;
    //   const error = undefined;
    //   dispatch(clearTable());
    //   dispatch(
    //     setTable({
    //       data: sortedData,
    //       name: ETableName.USERS,
    //       isLoading,
    //       isError,
    //       isSuccess,
    //       isFetching,
    //       error
    //     })
    //   );
    // }
    dispatch(
      enableSorting({
        table: tableName,
        sortBy: [ETableName.TOLLDOCUMENTS, ETableName.CUSTOMERS, ETableName.USERS].includes(
          tableName
        )
          ? field || ''
          : tableName === ETableName.TRIPS && _.kebabCase(heading) === 'tollogic™-tolls'
          ? 'estimated toll'
          : tableName === ETableName.TRANSACTIONS && _.kebabCase(heading) === 'status'
          ? 'statusMessage'
          : heading || field || ''
      })
    );
    dispatch(
      setCurrentPage({
        table: tableName,
        currentPage: 1
      })
    );
  };
  return (
    <button
      className={`${isSortedStyles} ${styles[_.kebabCase(`${tableName} ${heading}`)]}`}
      onClick={handleSort}
      type="button"
    >
      <h4 className={styles.heading} title={heading}>
        {_.kebabCase(heading) === 'gps-calculated-tolls' ? <>tollogic&#8482; Tolls</> : heading}
      </h4>
      <span
        className={
          sorting.active
            ? `${styles.icon} ${sorting.order ? styles[sorting.order] : ''}`
            : styles.icon
        }
      >
        <ArrowsFilled />
      </span>
    </button>
  );
};

export default Header;
