/* eslint-disable */
import Button from '@common/ui/Button';
import { routes } from '@common/utils/route-names';
import { Link, useLocation } from 'react-router-dom';
import { AuthBody } from '../../../layout/components';
import styles from './card.module.scss';

const Card = () => {
  const location = useLocation();
  return (
    <AuthBody className={styles.body} title="Welcome to commercelogic">
      <div className={styles.container}>
        <p>
          You have been invited to use commerce<b>logic</b> services. Please log in for the first time and
          create password.
        </p>
        <Button
          size="s"
          appearance="button"
          link={routes.create_password}
          linkState={location.state as unknown}
          label="Continue to website"
        />
        <small>
          You can also use this{' '}
          <Link to={routes.create_password} state={location.state as unknown}>
            link
          </Link>{' '}
          to access the website.
        </small>
      </div>
    </AuthBody>
  );
};

export default Card;
