import { FC } from 'react';
import Button from '@common/ui/Button';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch } from '@store/hooks';
import { openModal } from '@store/features/modal';
import { Settings } from '@assets/icons';
import { TableMenu } from './TableMenu/TableMenu';
import { MenuIconProps } from './types';
import styles from './menu-icon.module.scss';
import ExportDropdown from '../../../export/ExportDropdown';

const MenuIcon: FC<MenuIconProps> = ({
  checkboxState,
  variant,
  data,
  table,
  dropdownContainerEL,
  height,
  isHovered
}) => {
  const tableName = table.name as ETableName;
  const dispatch = useAppDispatch();
  const isTripsTable = table.name === ETableName.TRIPS;

  return (
    <div
      className={`${styles.container} ${isHovered ? styles.checked : ''} ${
        styles[`container-${variant}`]
      } ${styles[checkboxState]}`}
      style={{ height }}
    >
      <div className={styles['row-gradient']} style={{ height }} />
      {![ETableName.USERS].includes(tableName) &&
        (variant === 'header' ? (
          <div
            className={`${styles['button-wrapper']} ${
              isTripsTable ? styles['export-button-wrapper'] : styles['default-button-wrapper']
            }`}
            style={{ height }}
          >
            {isTripsTable && <ExportDropdown className={styles.export} />}
            <Button
              appearance="icon"
              icon={<Settings />}
              onClick={() => dispatch(openModal(['table-display', tableName]))}
              variant="tertiary"
              size="xs"
            />
          </div>
        ) : (
          <div
            className={`${styles['button-wrapper']} ${
              isTripsTable ? styles['nonexport-button-wrapper'] : styles['default-button-wrapper']
            }`}
            style={{ height }}
          >
            <TableMenu
              dropdownContainerEl={dropdownContainerEL}
              data={data}
              tableName={tableName}
            />
          </div>
        ))}
    </div>
  );
};

export default MenuIcon;
