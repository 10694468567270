import Modal from '@common/ui/Modal';
import { UserDataInterface } from '@common/types/data';
import { FormSubmitButton } from '../../../buttons';
import { UpdateUser, UpdateUserFormik } from '../../../forms/Update';
import styles from './user.module.scss';

const User = ({ data }: { data: UserDataInterface }) => {
  return (
    <UpdateUserFormik data={data}>
      <Modal
        activeTitle="singleHighlight"
        navigation={[data?.name || 'Details']}
        customPrimaryButton={<FormSubmitButton label="Update" type="update" fleet />}
        cancelButton={false}
        className={styles.modal}
      >
        <UpdateUser />
      </Modal>
    </UpdateUserFormik>
  );
};

export default User;
