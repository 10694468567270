/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import styles from './footer.module.scss';
import { useSSOContext } from '../../../context/SSOContext';
import { useRegisterGeotabUser } from '../../../hooks/useRegisterGeotabUser';

interface FooterProps {
  isEULAFullyScrolled?: boolean;
  submitForm?: () => void; // Receive submitForm via props
}
const Footer = ({ isEULAFullyScrolled, submitForm }: FooterProps) => {
  const {
    isCustomerNew,
    isOrdernowCustomer,
    activeTabIndex,
    handleAccept,
    handleOverviewAccept,
    handlePrevTab,
    registerLoader,
    gUser,
    gVehicles
  } = useSSOContext();
  const { handleRegistration } = useRegisterGeotabUser();
  const [isClicked, setIsclicked] = useState(false);

  const handleRegistrationProcess = async () => {
    try {
      if (isOrdernowCustomer) {
        if (submitForm) {
          submitForm();
        }
        // await handleRegistration({ ...gUser }).catch(e => console.log(e));
      } else {
        await handleRegistration({ ...gUser, vehicles: gVehicles }).catch(e => console.log(e));
      }
    } catch (e) {
      console.error('Error during registration:', e);
    }
  };

  return (
    <div className={styles.container}>
      {isCustomerNew ? (
        <>
          {activeTabIndex !== 0 && activeTabIndex !== (isOrdernowCustomer ? 3 : 4) && (
            <button type="button" className={styles.back} onClick={handlePrevTab}>
              Back
            </button>
          )}
          {activeTabIndex === (isOrdernowCustomer ? 2 : 3) && (
            <button
              type="submit"
              className={styles.action}
              disabled={registerLoader}
              onClick={() => {
                handleRegistrationProcess().catch(e => console.error(e));
              }}
            >
              {registerLoader ? (
                <span>Please wait...</span>
              ) : (
                <>{isOrdernowCustomer ? 'Continue' : 'Start Trial'}</>
              )}
            </button>
          )}
          {activeTabIndex > 1 && activeTabIndex < (isOrdernowCustomer ? 2 : 3) && (
            <button type="submit" className={styles.action}>
              Next
            </button>
          )}
          {activeTabIndex === 1 && (
            <button
              className={`${styles.action} ${styles.disabled}`}
              onClick={handleAccept}
              disabled={isEULAFullyScrolled}
              type="button"
            >
              Accept
            </button>
          )}
          {activeTabIndex === 0 && (
            <button className={styles.action} type="button" onClick={handleOverviewAccept}>
              Proceed
            </button>
          )}
        </>
      ) : (
        <>
          {activeTabIndex === 0 && (
            <button
              type="submit"
              className={styles.action}
              disabled={registerLoader}
              onClick={() => {
                handleRegistration({ ...gUser, vehicles: gVehicles }).catch(e => console.log(e));
              }}
            >
              {registerLoader ? <span>Please wait...</span> : <>Register</>}
            </button>
          )}
          {activeTabIndex === 1 && (
            <button
              type="submit"
              className={styles.action}
              onClick={() => {
                window.location.reload();
                setIsclicked(true);
              }}
              disabled={isClicked || registerLoader}
            >
              {registerLoader ? <span>Please wait...</span> : <>Get started</>}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Footer;
