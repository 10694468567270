/** eslint-disable @typescript-eslint/no-unsafe-assignment */
import { routes } from '@common/utils/route-names';
import { userCanAccessRoute } from '@common/services/auth/can-access-route';
import {
  GMap,
  Truck,
  Dollar,
  Customers,
  Logout,
  Invoice,
  AdditionalServices,
  Settings,
  Support,
  NoteClipBoard
} from '@assets/icons';

export const navigationTop = [
  {
    link: routes.trips,
    title: 'trips',
    icon: GMap,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.fleet,
    title: 'fleet',
    icon: Truck,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.transactions,
    title: 'toll transactions',
    icon: Dollar,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.toll_documents,
    title: 'toll documents',
    icon: Invoice,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.customers,
    title: 'customers',
    icon: Customers,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.additional_services,
    title: 'additional services',
    icon: AdditionalServices,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.disputereports,
    title: 'dispute reports',
    icon: NoteClipBoard,
    isPermitted: userCanAccessRoute
  }
];

export const navigationBottom = [
  {
    link: routes.support,
    // position: 'right',
    title: 'Support',
    icon: Support,
    isPermitted: userCanAccessRoute
  },
  {
    link: routes.settings,
    title: 'Settings',
    icon: Settings,
    isPermitted: userCanAccessRoute
  },
  {
    link: '',
    title: 'signout',
    icon: Logout,
    isPermitted: userCanAccessRoute
  }
];
