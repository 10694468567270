import * as Yup from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
  vehicleName: Yup.string().required('vehicle name is required'),
  licensePlate: Yup.string().required('license plate is required'),
  startAddress: Yup.string().required('start point is required'),
  endAddress: Yup.string().required('end point is required'),
  startDateTime: Yup.string().required('start date is required'),
  stopDateTime: Yup.string().required('end date is required'),
  tagType: Yup.string().required('tag type is required'),
  tagTypeD: Yup.string().required('tag type is required'),
  axles: Yup.number().required('vehicle axles is required'),
  distance: Yup.number().required('distance is required'),
  timeSpent: Yup.number().required('time spent is required'),
  moneySpent: Yup.number().required('money spent is required'),
  gpsReliability: Yup.string().required('gps reliability is required'),
  tollCostPerMinute: Yup.number().required('toll cost per minute is required'),
  tollCostPerMile: Yup.number().required('toll cost per mile is required')
});
