/* eslint-disable */
import { VehicleDataInterface } from '@common/types/data';
import { useUpdateVehicleMutation } from '@api/services/fleet';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { closeModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { refetchTableData } from '@store/data';
// import { getStateByCodeAndCountry } from '@common/utils/constants/helpers';

export const useHandleSubmit = () => {
  const [updateVehicle] = useUpdateVehicleMutation();
  const toastify = usePromiseToastNotify();
  const dispatch = useAppDispatch();
  const { handleError } = useHandleApiResponse();
  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const tagTypes = useAppSelector(state => state.fleetTabs.tagTypes);
  const handleSubmit = (
    values: VehicleDataInterface,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const {
      tagTypeD,
      secTagTypeD,
      vehicleClassD,
      issuingAgencyIdD,
      secIssuingAgencyIdD,
      plateStateD,
      ...remaining
    } = values;
    const rest = { ...remaining };
    delete rest.licensePlate;
    delete rest.isTouched;
    // const plateNumber = rest.licensePlate ? rest.licensePlate.split(' ')[1] : rest.plateNumber;
    // const plateState = rest.licensePlate
    //   ? getStateByCodeAndCountry(rest.licensePlate.split(' ')[0], 'US').isoCode
    //   : rest.plateState;
    // rest.plateNumber = plateNumber;
    // rest.plateState = plateState;

    rest.issuingAgencyId =
      values.issuingAgencyIdD && values.issuingAgencyIdD.toLowerCase() !== 'none'
        ? Number(tagIssuingAgencies?.find(x => x.name === values.issuingAgencyIdD)?.id)
        : null;

    rest.secIssuingAgencyId =
      values.secIssuingAgencyIdD && values.secIssuingAgencyIdD.toLowerCase() !== 'none'
        ? Number(tagIssuingAgencies?.find(x => x.name === values.secIssuingAgencyIdD)?.id)
        : null;

    rest.tagTypeId =
      values.tagType && values.tagType.toLowerCase() !== 'none'
        ? tagTypes?.find(item => item.tag_type_name.toLowerCase() === values.tagType?.toLowerCase())
            ?.tag_type_id || null
        : null;

    rest.secTagTypeId =
      values.secTagType && values.secTagType.toLowerCase() !== 'none'
        ? tagTypes?.find(x => x.tag_type_name.toLowerCase() === values.secTagType?.toLowerCase())
            ?.tag_type_id || null
        : null;

    if (
      values.childTags &&
      values.childTags?.length > 0 &&
      rest.childTags &&
      rest.childTags?.length > 0
    ) {
      rest.tollConsolidator = true;
      const updatedChildTags = rest.childTags.map(childTag => {
        const updatedChildTag = { ...childTag }; // Create a new object with the same properties

        updatedChildTag.issuingAgencyId =
          updatedChildTag.issuingAgencyIdD &&
          updatedChildTag.issuingAgencyIdD.toLowerCase() !== 'none'
            ? Number(tagIssuingAgencies?.find(x => x.name === updatedChildTag.issuingAgencyIdD)?.id)
            : null;

        updatedChildTag.tagTypeId =
          updatedChildTag.tagType && updatedChildTag.tagType.toLowerCase() !== 'none'
            ? tagTypes?.find(item => item.tag_type_name === updatedChildTag.tagType)?.tag_type_id ||
              ''
            : null;

        return updatedChildTag;
      });
      rest.childTags = updatedChildTags;
    }
    toastify(
      updateVehicle({ ...rest })
        .unwrap()
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'update', 'customer');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(refetchTableData(true));
          dispatch(closeModal());
        }),
      'update',
      'vehicle'
    );
  };

  return { handleSubmit };
};
