/* eslint-disable */
import {
  Calendar,
  Dollar,
  Distance,
  EndPoint,
  PlateState,
  PlateNumber,
  StartPoint,
  Time,
  VehicleName,
  TollAgency,
  VehicleAxles
} from '@assets/icons';
import { TextInput } from '@common/ui/Form';
import { Form } from 'formik';
import styles from './update-trip.module.scss';
import { useSelector } from 'react-redux';
import { routes } from '@common/utils/route-names';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';

const UpdateTrip = () => {
  const isMetric = useSelector((state: any) => state.auth.isMetric);
  const hasEditAccess = !useEditPermission(routes.trips);
  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="Vehicle Name"
        name="vehicleName"
        icon={<VehicleName />}
        disabled={hasEditAccess}
      />
      {/* <TextInput
        className={styles.input}
        label="License Plate"
        name="licensePlate"
        icon={<PlateNumber />}
        disabled={hasEditAccess}
      /> */}
      <TextInput
        className={styles.input}
        label="License Plate State"
        name="plateState"
        icon={<PlateState />}
        disabled={hasEditAccess}
      />

      <TextInput
        className={styles.input}
        label="License Plate Number"
        name="plateNumber"
        icon={<PlateNumber />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Start Point"
        name="startAddress"
        icon={<StartPoint />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="End Point"
        name="endAddress"
        icon={<EndPoint />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Start Date"
        name="startDateTime"
        icon={<Calendar />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="End Date"
        name="stopDateTime"
        icon={<Calendar />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label={`Trip Distance, ${isMetric ? 'km' : 'mi'}`}
        name="distance"
        icon={<Distance />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Trip Duration, mins"
        name="timeSpent"
        icon={<Time />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="GPS Calculated Tolls"
        name="moneySpent"
        icon={<Dollar />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Tag Type"
        name="tagType"
        icon={<TollAgency />}
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Axle Count"
        name="axles"
        icon={<VehicleAxles />}
        disabled={hasEditAccess}
      />
    </Form>
  );
};

export default UpdateTrip;
