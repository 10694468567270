import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import Portal from '@common/ui/Portal';
import PopperControl from './PopperControl/PopperControl';
import { PopperProps } from './types';
import styles from './popper.module.scss';
import { usePopperOutsideClick, usePopperWrapper } from './hooks';

const Popper: FC<PopperProps> = ({
  position,
  offset,
  sameWidth,
  boundaryElement,
  handleOpenState,
  isOpen,
  control,
  children,
  usePortal,
  controlDisabled
}) => {
  const { handlePopperElement, handleReferenceElement, popperStyles, popperAttributes } =
    usePopperWrapper(boundaryElement, offset, position, sameWidth);
  const wrapperRef = usePopperOutsideClick(handleOpenState);

  return (
    <div ref={wrapperRef} className={styles.container}>
      <PopperControl
        handleReferenceElement={handleReferenceElement}
        handleOpenState={() => handleOpenState(!isOpen)}
        disabled={controlDisabled}
      >
        {control}
      </PopperControl>
      {usePortal ? (
        <Portal>
          <AnimatePresence>
            {isOpen && children(handlePopperElement, popperStyles, popperAttributes)}
          </AnimatePresence>
        </Portal>
      ) : (
        <AnimatePresence>
          {isOpen && children(handlePopperElement, popperStyles, popperAttributes)}
        </AnimatePresence>
      )}
    </div>
  );
};

export default Popper;
