import { useState, useEffect } from 'react';
import { CheckboxStateType } from '@common/ui/Checkbox/types';
import { UnionDataInterface } from '@common/types/data';
import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import { RowVariant } from './types';

export const useCheckboxState = (
  variant: RowVariant,
  tableName: ETableName,
  tableData: UnionDataInterface[],
  data?: UnionDataInterface
) => {
  const [checkboxState, setCheckboxState] = useState<CheckboxStateType>('unchecked');
  const selected = useAppSelector(state => state.selectionIds[tableName]);
  const isRowSelected = selected?.find(el => el.id === data?.id);
  const areAllRowsSelected =
    tableData.every(item => selected.find(el => el.id === item.id)) && selected.length !== 0;
  const areSomeRowsSelected = tableData.some(item => selected.find(el => el.id === item.id));

  useEffect(() => {
    if (isRowSelected && variant === 'data') {
      setCheckboxState('checked');
    } else if (areAllRowsSelected && variant === 'header') {
      setCheckboxState('checked');
    } else if (areSomeRowsSelected && variant === 'header') {
      setCheckboxState('intermediate');
    } else {
      setCheckboxState('unchecked');
    }
  }, [isRowSelected, areAllRowsSelected, areSomeRowsSelected, data?.id, variant]);

  return { checkboxState };
};
