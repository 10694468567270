import { Export } from '@assets/icons';
import { useDataExport } from '@common/hooks';
import Button from '@common/ui/Button';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import styles from '../actions.module.scss';

const ExportButtons = ({ tableName }: { tableName: ETableName }) => {
  const { handleDataExport } = useDataExport();
  const table = useAppSelector(state => state.table);
  const selected = useAppSelector(state => state.selectionIds[table.name as ETableName]);
  return (
    <div className={styles['button-container']}>
      <Button
        appearance="button"
        label="Export"
        icon={<Export />}
        disabled={!table.data?.length}
        variant="g-tertiary"
        size="s"
        onClick={() => handleDataExport('xlsx', tableName, table.data || [], selected)}
      />
      {tableName === ETableName.TRIPS && (
        <Button
          appearance="button"
          label="Export Detail Data"
          icon={<Export />}
          disabled={!table.data?.length}
          variant="tertiary"
          size="s"
          onClick={() => handleDataExport('xlsx', tableName, table.data || [], selected, true)}
        />
      )}
    </div>
  );
};

export default ExportButtons;
