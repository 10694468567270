/* eslint-disable */
// react-hooks/exhaustive-deps
// import {
//   useGetAgenciesQuery,
//   useGetTagTypesQuery,
//   useGetVehicleClassesQuery
// } from '@api/services/lists';
import { useEffect, useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { VehicleDataInterface } from '@common/types/data';
import { getStatesOfCountry } from '@common/utils/constants/helpers';
import {
  billingStatusList,
  devicePlansList,
  tagTypesList,
  vehicleClassesList
} from '@common/utils/constants/lists';
import { useAppSelector } from '@store/hooks';

export const useGetListsData = () => {
  const states = useMemo(() => getStatesOfCountry('US') || [], []);
  // const { data: agenciesData } = useGetAgenciesQuery();
  // const { data: vehicleClassesData } = useGetVehicleClassesQuery();
  // const { data: tagTypesData } = useGetTagTypesQuery();
  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const agencyValues = tagIssuingAgencies?.map(agency => agency.name) || [];
  const secAgencyValues =
    tagIssuingAgencies?.filter(x => !x.is_toll_consolidator).map(agency => agency.name) || [];
  const tagTypes = useAppSelector(state => state.fleetTabs.tagTypes);
  const tagValues = tagTypes?.map(tag => tag.tag_type_name) || [];
  // const [tagTypeValues, setTagTypeValues] = useState<string[]>([]);
  const [vehicleClassValues, setVehicleClassesValues] = useState<string[]>([]);
  const [plateStates, setPlateStates] = useState<string[]>([]);
  const [devicePlanValues, setDevicePlanValues] = useState<string[]>([]);
  const [billingStatusValues, setBillingStatusValues] = useState<string[]>([]);
  const formik = useFormikContext<VehicleDataInterface>();

  // useEffect(() => {
  //   setTagTypeValues(tagTypesList?.map(tagType => tagType.displayName) || []);
  // }, []);

  useEffect(() => {
    setVehicleClassesValues(
      vehicleClassesList?.map(vehicleClass => vehicleClass.displayName) || []
    );
  }, []);

  useEffect(() => {
    setPlateStates(
      states.map(state =>
        state.name === 'None' ? state.name : `${state.isoCode} - ${state.name}`
      ) || []
    );
  }, [states]);

  useEffect(() => {
    setDevicePlanValues(devicePlansList?.map(devicePlan => devicePlan.displayName) || []);
  }, []);

  useEffect(() => {
    setBillingStatusValues(
      billingStatusList?.map(billingStatus => billingStatus.displayName) || []
    );
  }, []);

  useEffect(() => {
    if (formik.values.issuingAgencyIdD) {
      formik.setFieldValue(
        'issuingAgencyId',
        tagIssuingAgencies?.find(agency => agency.name === formik.values.issuingAgencyIdD)?.id
      );
      formik.setFieldValue(
        'agencyName',
        tagIssuingAgencies?.find(agency => agency.name === formik.values.issuingAgencyIdD)?.name
      );
    }
  }, [formik.values.issuingAgencyIdD]);
  useEffect(() => {
    if (formik.values.vehicleClassD) {
      formik.setFieldValue(
        'vehicleClass',
        vehicleClassesList?.find(
          vehicleClass => vehicleClass.displayName === formik.values.vehicleClassD
        )?.classCode
      );
    }
  }, [formik.values.vehicleClassD]);
  useEffect(() => {
    if (formik.values.tagTypeD) {
      formik.setFieldValue('tagType', formik.values.tagTypeD[0]);
    }
  }, [formik.values.tagTypeD]);
  useEffect(() => {
    if (formik.values.plateStateD) {
      if (formik.values.plateStateD === 'None') {
        formik.setFieldValue('plateState', '');
      } else {
        formik.setFieldValue(
          'plateState',
          states?.find(state => state.isoCode === formik.values.plateStateD?.split(/ -/)[0])
            ?.isoCode || formik.values.plateState
        );
      }
    }
  }, [states, formik.values.plateStateD]);
  useEffect(() => {
    if (formik.values.devicePlanD) {
      formik.setFieldValue(
        'devicePlan',
        devicePlansList?.find(plan => plan.displayName === formik.values.devicePlanD)?.planCode
      );
    }
  }, [formik.values.devicePlanD]);
  useEffect(() => {
    if (formik.values.billingStatusD) {
      formik.setFieldValue(
        'billingStatus',
        billingStatusList?.find(
          billingStatus => billingStatus.displayName === formik.values.billingStatusD
        )?.statusCode
      );
    }
  }, [formik.values.billingStatusD]);

  return {
    agencyList: tagIssuingAgencies,
    agencies: agencyValues,
    secAgencies: secAgencyValues,
    vehicleClasses: vehicleClassValues,
    tagTypes: tagValues,
    plateStates,
    devicePlans: devicePlanValues,
    billingStatuses: billingStatusValues
  };
};
