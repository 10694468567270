import { useId } from 'react';
import styles from './min-max-number-input.module.scss';
import TextInput from '../TextInput';

const MinMaxNumberInput = ({
  label,
  minName,
  maxName,
  className = '',
  minPlaceholder,
  maxPlaceholder
}: {
  label: string;
  minName: string;
  maxName: string;
  className?: string;
  minPlaceholder?: string;
  maxPlaceholder?: string;
}) => {
  const id = useId();
  return (
    <div className={`${styles.container} ${className}`}>
      {label && (
        <label htmlFor={id || label} className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.group}>
        <TextInput
          name={`${minName || ''}`}
          placeholder={minPlaceholder || 'from'}
          type="number"
          className={styles.input}
        />
        <TextInput
          name={`${maxName || ''}`}
          placeholder={maxPlaceholder || 'to'}
          type="number"
          className={styles.input}
        />
      </div>
    </div>
  );
};

export default MinMaxNumberInput;
