import { Route } from 'react-router-dom';
import { SecondaryLayout } from '@app/layouts';
import OpenSourceLicenses from '@pages/opensource-license';

const PublicRoutes = () => {
  return (
    <Route element={<SecondaryLayout />}>
      <Route path="/open-source-licenses" element={<OpenSourceLicenses />} />
    </Route>
  );
};

export default PublicRoutes;
