import { Formik, Form } from 'formik';
import { useHandleSubmit } from './hooks/useHandleSubmit';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';

const UploadTollDocumentFormik = ({ children }: ReactChildren) => {
  const { handleSubmit } = useHandleSubmit();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default UploadTollDocumentFormik;
