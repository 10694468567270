import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { IHeading } from '@store/features/headings';
import { UnionDataInterface } from '@common/types/data';
import { rowModalId } from '@common/services/format';
import { openModal } from '@store/features/modal';
import Column from '../../Column/Column';
import { useRowTextContent } from './hooks';
import { RowTextContentProps } from './types';
import styles from './row-text-content.module.scss';
import { RowModal } from '../../../modals';

const RowTextContent: FC<RowTextContentProps> = ({ variant, table, data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableName = table.name as ETableName;
  const headings = useAppSelector(state => state.headings[tableName]) as IHeading[];
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);

  const filteredHeadings = useMemo(() => {
    if (tableName === ETableName.TRANSACTIONS) {
      if (isActualTollTab) {
        return headings.filter(item => !['trip_id'].includes(item.field));
      }
      return headings.filter(
        item =>
          !['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'].includes(
            item.field
          )
      );
    }
    return headings;
  }, [tableName, isActualTollTab, headings]);

  const handleDataModal = () => {
    dispatch(openModal(['data', rowModalId(tableName, data?.id || '')]));
    if (table.name === ETableName.TRIPS && data?.id) {
      navigate(`/trips/${data.id}`);
    }
  };
  const { textContent } = useRowTextContent(filteredHeadings, data, tableName);

  // console.log('textContent ><LO', textContent);

  return (
    <>
      <div
        className={`${styles.container} ${styles[`container-${variant}`]}`}
        onClick={() => variant === 'data' && handleDataModal()}
        onKeyDown={() => variant === 'data' && handleDataModal()}
        role="button"
        tabIndex={0}
      >
        {variant === 'header' &&
          filteredHeadings.length !== 0 &&
          filteredHeadings.map(
            item =>
              item.active && <Column key={uuid()} {...item} table={table} rowVariant={variant} />
          )}

        {variant === 'data' &&
          textContent &&
          textContent.map(
            row =>
              row.active && (
                <Column
                  key={uuid()}
                  {...row}
                  value={row.value}
                  table={table}
                  rowVariant={variant}
                  rowData={data}
                />
              )
          )}
      </div>
      {variant === 'data' && tableName !== ETableName.TOLLDOCUMENTS && (
        <RowModal data={data as UnionDataInterface} />
      )}
    </>
  );
};

export default RowTextContent;
