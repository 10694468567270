/* eslint-disable */
import api from '@api/services/common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISubscribe {
  loading: boolean;
  open: boolean;
  data: any;
  subscribedVehicle: any[];
  unSubscribedVehicle: any[];
  previouslySubscribedVehicle: number;
  billSummary: any;
  sorting: {
    sort_by: string;
    sort_order: string;
  };
  isSuccess: boolean;
  successVin: [];
}

const initialState: ISubscribe = {
  loading: false,
  open: false,
  data: [],
  subscribedVehicle: [],
  unSubscribedVehicle: [],
  previouslySubscribedVehicle: 0,
  billSummary: null,
  sorting: {
    sort_by: '',
    sort_order: ''
  },
  isSuccess: false,
  successVin: []
};

export const getUnSubscribedVehicle = createAsyncThunk(
  'getUnSubscribedVehicle',
  async (data, thunkAPI) => {
    const res = await api.post('/vehicle/subcribe-vehicles', data);
    return res;
  }
);

export const getBillSummary = createAsyncThunk('getBillSummary', async () => {
  const res = await api.get('/bill-summary');
  return res?.data;
});

export const saveSubscribedVehicle = createAsyncThunk(
  'saveSubscribedVehicle',
  async (data, thunkAPI) => {
    const res = await api.post('/vehicle/subscription-request', data);
    return res;
  }
);

export const subscribeSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    openSubscribe: (state, action: PayloadAction<boolean>) => {
      const status = state;
      status.open = action.payload;
    },
    closeSubscribe: () => {
      return initialState;
    },
    addToSubscribe: (state, action: PayloadAction<any>) => {
      const updatedVehicles = [...state.subscribedVehicle];
      if (!updatedVehicles.find(el => el.vehicle_id === action.payload.vehicle_id)) {
        updatedVehicles.push(action.payload);
      }
      state.subscribedVehicle = updatedVehicles;
    },
    addToUnSubscribe: (state, action: PayloadAction<any>) => {
      const updatedVehicles = [...state.unSubscribedVehicle];
      if (!updatedVehicles.find(el => el.vehicle_id === action.payload.vehicle_id)) {
        updatedVehicles.push(action.payload);
      }
      state.unSubscribedVehicle = updatedVehicles;
    },
    removeFromSubscribe: (state, action: PayloadAction<any>) => {
      const updatedVehicles = state.subscribedVehicle.filter(
        el => el.vehicle_id !== action.payload.vehicle_id
      );
      state.subscribedVehicle = updatedVehicles;
    },
    removeFromUnSubscribe: (state, action: PayloadAction<any>) => {
      const updatedVehicles = state.unSubscribedVehicle.filter(
        el => el.vehicle_id !== action.payload.vehicle_id
      );
      state.unSubscribedVehicle = updatedVehicles;
    },
    setSortingValue: (
      state,
      action: PayloadAction<{
        sort_by: string;
        sort_order: string;
      }>
    ) => {
      state.sorting = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getUnSubscribedVehicle.pending, (state, action) => {
      state.loading = true;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUnSubscribedVehicle.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload.data;
      state.previouslySubscribedVehicle = action.payload.subscribedVehiclesCount;
      state.loading = false;
    });
    builder.addCase(getBillSummary.pending, (state, action) => {
      state.loading = true;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBillSummary.fulfilled, (state, action) => {
      // Add user to the state array
      state.billSummary = action.payload;
      state.loading = false;
    });
    builder.addCase(saveSubscribedVehicle.pending, (state, action) => {
      state.loading = true;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(saveSubscribedVehicle.fulfilled, (state, action) => {
      state.successVin = action.payload?.data?.vehicle_vins;
      state.isSuccess = action.payload?.isSuccess;
      state.loading = false;
    });
  }
});

export const {
  openSubscribe,
  closeSubscribe,
  addToSubscribe,
  addToUnSubscribe,
  removeFromSubscribe,
  removeFromUnSubscribe,
  setSortingValue
} = subscribeSlice.actions;

export default subscribeSlice.reducer;
