/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */

import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { ArrowLeft, Close } from '@assets/icons';
import { useAppDispatch } from '@store/hooks';
import { closeModal } from '@store/features/modal';
import Button from '@common/ui/Button';
import Help from '@components/help';
import _ from 'lodash';
import { ModalHeaderProps } from './types';
import styles from './modal-header.module.scss';

const ModalHeader: FC<ModalHeaderProps> = ({
  headerClassName = '',
  title,
  withBackButton,
  navigation,
  activeTitle,
  handleActiveTitle,
  handleBackButtonClick,
  customCloseFunc,
  helpLocation,
  selectAllElement
}) => {
  const dispatch = useAppDispatch();

  return (
    <header className={`${styles.container} ${headerClassName}`}>
      <section className={styles.title}>
        {withBackButton && (
          <button type="button" className={styles.back} onClick={handleBackButtonClick}>
            <ArrowLeft />
          </button>
        )}
        {navigation && navigation.length > 1 ? (
          <ul className={styles.navigation}>
            {navigation.map(item => (
              <li className={activeTitle === item ? styles.active : ''} key={uuid()}>
                <button
                  aria-label={`modal-title-${item}`}
                  onClick={() => handleActiveTitle && handleActiveTitle(item)}
                  type="button"
                >
                  {item}
                </button>
                {helpLocation && (
                  <Help location={`${helpLocation}${_.snakeCase(item)}`} className={styles.help} />
                )}
              </li>
            ))}
          </ul>
        ) : (
          <h3 className={activeTitle === 'singleHighlight' ? styles.active : ''}>
            {navigation?.length === 1 ? navigation[0] : title}
            {helpLocation && (
              <Help
                location={`${helpLocation}${_.snakeCase(
                  navigation?.length === 1 ? navigation[0] : title
                )}`}
                className={styles.help}
              />
            )}
          </h3>
        )}
      </section>
      {selectAllElement ? selectAllElement : ''}
      <Button
        appearance="icon"
        size="xs"
        icon={<Close />}
        variant="tertiary"
        onClick={() => (customCloseFunc ? customCloseFunc() : dispatch(closeModal()))}
      />
    </header>
  );
};

export default ModalHeader;
