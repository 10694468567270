/* eslint-disable */
import { FC } from 'react';
import { Grid } from '@mui/material';
import { Distance, Dollar, EngineHours, GMap, Plaza, Time } from '@assets/icons';
import { useAppSelector } from '@store/hooks';
import { secondsToHms } from '@common/services/format/time';
import styles from './map-footer.module.scss';
import { MapFooterProps } from './types';

const MapFooter: FC<MapFooterProps> = ({ actualTrip, altTrip, tollCostPerMinute, tollCostPerMile }) => {
  const isMetric = useAppSelector(state => state.auth.isMetric);

  return (
    <div className={styles.container}>
      <Grid container direction="row" spacing={2}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <GMap className={styles.icon} />
          <span className={styles.title}>Trip</span>
        </Grid>
        <Grid className={styles.actual} xs={4} lg={4} xl={4} sm={4} item>
          Actual
        </Grid>
        <Grid className={styles.alternative} xs={4} lg={4} xl={4} sm={4} item>
          Alternative
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} sx={{ marginTop: '0.1rem' }}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <Dollar className={styles.icon} />
          <span className={styles.title}>Tolls</span>
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {actualTrip.cost ? `$${actualTrip.cost.toFixed(2)}` : '$0.00'}
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          -
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} sx={{ marginTop: '0.1rem' }}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <Time className={styles['cust-icon']} />
          <span className={styles.title}>Duration</span>
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {secondsToHms(actualTrip.duration, true)}
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {secondsToHms(altTrip.duration, true)}
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} sx={{ marginTop: '0.1rem' }}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <Distance className={styles['cust-icon']} />
          <span className={styles.title}>Distance</span>
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {`${actualTrip.distance?.toFixed(2)} ${isMetric ? 'km' : 'mi'}`}
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {`${altTrip.distance?.toFixed(2)} ${isMetric ? 'km' : 'mi'}`}
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} sx={{ marginTop: '0.1rem' }}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <EngineHours className={styles['cust-icon']} />
          <span className={styles.title}>Toll Cost / Minute</span>
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {tollCostPerMinute ? (
            <div>
              <span
                className={
                  tollCostPerMinute < 0
                    ? styles['npvt-negative-text']
                    : styles['npvt-positive-text']
                }
              >
                {`$${Number(tollCostPerMinute).toFixed(2)}`}
              </span>
              /min
            </div>
          ) : (
            '$0.00/min'
          )}
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          -
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} sx={{ marginTop: '0.1rem' }}>
        <Grid xs={4} lg={4} xl={4} sm={4} item className={styles['header-title']}>
          <Plaza className={styles['cust-icon']} />
          <span className={styles.title}>Toll Cost / Mile</span>
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          {tollCostPerMile ? (
            <div>
              <span
                className={
                  tollCostPerMile < 0
                    ? styles['npvt-negative-text']
                    : styles['npvt-positive-text']
                }
              >
                {`$${Number(tollCostPerMile).toFixed(2)}`}
              </span>
              /mile
            </div>
          ) : (
            '$0.00/mile'
          )}
        </Grid>
        <Grid xs={4} lg={4} xl={4} sm={4} item>
          -
        </Grid>
      </Grid>
    </div>
  );
};

export default MapFooter;
