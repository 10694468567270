import { Plus } from '@assets/icons';
import Modal from '@common/ui/Modal';
import { UploadTransaction, UploadTransactionFormik } from '../../../forms/Create';
import { FormSubmitButton } from '../../../buttons';

const Transaction = () => {
  return (
    <UploadTransactionFormik>
      <Modal
        activeTitle="singleHighlight"
        navigation={['New transaction']}
        customPrimaryButton={
          <FormSubmitButton label="Upload transaction" icon={<Plus />} type="create" />
        }
        cancelButton={false}
      >
        <UploadTransaction />
      </Modal>
    </UploadTransactionFormik>
  );
};

export default Transaction;
