export function Weight({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 22 19"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2688 0.527036L4.28143 3.52413C4.265 3.52781 4.2487 3.5319 4.23254 3.53639C3.93472 3.6188 3.70374 3.82945 3.58598 4.09413L0.684108 10.3815C0.572391 10.6236 0.474785 10.9572 0.521474 11.3398C0.610336 12.068 0.978257 12.9505 1.60615 13.6628C2.25287 14.3965 3.22507 15 4.50003 15C5.77499 15 6.74719 14.3965 7.3939 13.6628C8.0218 12.9505 8.38972 12.068 8.47858 11.3398C8.52527 10.9572 8.42766 10.6236 8.31595 10.3815L5.92345 5.19777L9.99998 4.25703V17H6.99998C6.44769 17 5.99998 17.4477 5.99998 18C5.99998 18.5523 6.44769 19 6.99998 19H15C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17H12V3.79549L15.7372 2.93304L13.6841 7.38151C13.5724 7.62356 13.4748 7.95718 13.5215 8.33978C13.6103 9.06799 13.9783 9.95048 14.6062 10.6628C15.2529 11.3965 16.2251 12 17.5 12C18.775 12 19.7472 11.3965 20.3939 10.6628C21.0218 9.95048 21.3897 9.06799 21.4786 8.33978C21.5253 7.95718 21.4277 7.62356 21.3159 7.38151L18.415 1.09608C18.3472 0.942178 18.2432 0.811437 18.1157 0.711946C18.065 0.672399 18.0107 0.637805 17.9534 0.608664C17.764 0.512178 17.543 0.475269 17.3198 0.51632C17.3027 0.519455 17.2857 0.52303 17.2688 0.527036ZM2.51918 11.1781C2.57793 11.4968 2.76876 11.9572 3.1065 12.3403C3.45508 12.7358 3.91202 13 4.50003 13C5.08803 13 5.54497 12.7358 5.89356 12.3403C6.23129 11.9572 6.42212 11.4968 6.48087 11.1781L4.50003 6.88629L2.51918 11.1781ZM16.1065 9.34033C15.7688 8.95717 15.5779 8.49683 15.5192 8.17811L17.5 3.88629L19.4809 8.17811C19.4221 8.49683 19.2313 8.95717 18.8936 9.34033C18.545 9.7358 18.088 9.99998 17.5 9.99998C16.912 9.99998 16.4551 9.7358 16.1065 9.34033Z"
      />
    </svg>
  );
}
