import styles from './payment-loader.module.scss';

const PaymentLoader = () => {
  return (
    <div className={styles.container}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default PaymentLoader;
