import { useState } from 'react';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { PasswordAction, AuthBody } from '../../../layout/components';
import FormComp from '../Form/Form';
import styles from './card.module.scss';

const Card = () => {
  const [passwordCreated, setPasswordCreated] = useState(false);
  const handlePasswordCreated = (value: boolean) => setPasswordCreated(value);
  const location: Location = useLocation();
  if (!(location.state as { email: string })?.email) {
    return <Navigate to="/404" replace />;
  }
  return (
    <AuthBody
      className={styles.body}
      title={passwordCreated ? 'Password Created' : 'Welcome to commercelogic'}
    >
      <section className={styles.container}>
        {passwordCreated ? (
          <PasswordAction action="created" />
        ) : (
          <>
            <h2>Please create your password</h2>
            <FormComp
              handlePasswordCreated={handlePasswordCreated}
              email={(location.state as { email: string }).email}
            />
          </>
        )}
      </section>
    </AuthBody>
  );
};

export default Card;
