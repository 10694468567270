/* eslint-disable */
import { ArrowsFilled, FilledCancel } from '@assets/icons';
import SearchBar from '@common/ui/SearchBar';
import { ETableName } from '@common/utils/enums';
import { IHeading } from '@store/features/headings';
import { setCurrentPage } from '@store/features/pagination';
import { enableSorting } from '@store/features/sort';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { useMemo } from 'react';
import { CustomDatePicker, DurationPickerInput } from '@common/ui/Form';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useColumnSearch } from './hooks';
import styles from './column.module.scss';

const Column = ({
  filterType = 'text',
  ...props
}: IHeading & {
  tableName: ETableName;
  filterType?: IHeading['filterType'];
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.filters[props.tableName]?.by);
  const dates = filters && 'date' in filters ? filters.date : { to: '', from: '' };
  const { searchValue, handleSearchValue } = useColumnSearch();
  const searchBy = useMemo(() => {
    if (searchValue.field !== 'trip duration' && searchValue.field !== 'timeSaved') {
      return searchValue.by?.toString() &&
        _.kebabCase(searchValue.field) === _.kebabCase(props.heading)
        ? searchValue.by
        : '';
    } else {
      return searchValue.by && _.kebabCase(searchValue.field) === _.kebabCase(props.heading)
        ? searchValue.by
        : '';
    }
  }, [props.heading, searchValue.by, searchValue.field]);
  const handleChange = (value: string | number) => {
    if (filterType === 'number' && value.toString().length > 0) {
      handleSearchValue(value, props.heading);
    } else {
      handleSearchValue(value, props.heading);
    }
  };
  const sorting = useAppSelector(state => state.sort[props.tableName]);
  // ? handle header sorting and sorted header styles
  const isSortedStyles =
    sorting.by === (props.heading || props.field || _.camelCase(props.heading)) ||
    (props.tableName === ETableName.TRIPS &&
      sorting.by === 'estimated toll' &&
      _.kebabCase(props.heading) === 'tollogic™-tolls') ||
    (props.tableName === ETableName.TRANSACTIONS &&
      sorting.by === 'statusMessage' &&
      _.kebabCase(props.heading) === 'status')
      ? `${styles.top} ${styles['sort-by']}`
      : styles.top;
  const handleSort = () => {
    dispatch(
      enableSorting({
        table: props.tableName,
        sortBy:
          props.tableName === ETableName.TOLLDOCUMENTS
            ? props.field || ''
            : props.tableName === ETableName.TRIPS &&
              _.kebabCase(props.heading) === 'tollogic™-tolls'
            ? 'estimated toll'
            : props.tableName === ETableName.TRANSACTIONS && _.kebabCase(props.heading) === 'status'
            ? 'statusMessage'
            : props.heading || props.field || ''
      })
    );
    dispatch(
      setCurrentPage({
        table: props.tableName,
        currentPage: 1
      })
    );
  };
  return (
    <div
      className={`${styles.container} ${
        styles[_.kebabCase(`${props.tableName} ${props.heading}`)]
      }`}
    >
      <button className={`${isSortedStyles}`} onClick={handleSort} type="button">
        <h4 className={styles.heading} title={props.heading}>
            {_.kebabCase(props.heading) === 'gps-calculated-tolls' ? <>tollogic&#8482; Tolls</> : props.heading}
        </h4>
        <span
          className={
            sorting.active
              ? `${styles.icon} ${sorting.order ? styles[sorting.order] : ''}`
              : styles.icon
          }
        >
          <ArrowsFilled />
        </span>
      </button>
      <div className={styles.bottom}>
        {filterType === 'text' && (
          <SearchBar
            className={styles.searchbar}
            stayOpen
            searchValue={searchBy}
            handleSearchValue={handleChange}
            showSearchIcon={false}
            clearIcon={
              <span className={styles['clear-icon']}>
                <FilledCancel />
              </span>
            }
            placeholder={props.placeholder}
          />
        )}
        {filterType === 'duration' && (
          <DurationPickerInput
            initial={Number(searchBy)}
            handleDurationChange={handleChange}
            className={styles.duration}
          />
        )}
        {filterType === 'number' && (
          <SearchBar
            className={styles.searchbar}
            stayOpen
            type="number"
            searchValue={searchBy}
            handleSearchValue={handleChange}
            showSearchIcon={false}
            clearIcon={
              <span className={styles['clear-icon']}>
                <FilledCancel />
              </span>
            }
            placeholder={props.placeholder}
          />
        )}
        {filterType === 'date' && (
          <CustomDatePicker
            initialDate={dayjs(searchBy).isValid() ? (searchBy as string) : ''}
            handleDate={(date: string) => handleChange(date)}
            showTime={false}
            className={styles.datepicker}
            dateFormat="MM/dd/yyyy"
            placeholder={props.placeholder}
            minDate={
              dates?.from && dayjs(dates.from).isValid() ? dayjs(dayjs(dates.from)).toDate() : null
            }
            maxDate={
              dates?.to && dayjs(dates.to).isValid() ? dayjs(dayjs(dates.to)).toDate() : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default Column;
