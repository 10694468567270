/* eslint-disable no-nested-ternary */
/* eslint-disable */
import { FC } from 'react';
import { ETableName } from '@common/utils/enums';
import { ColumnProps } from './types';
import Header from './Header/Header';
import DataField from './DataField/DataField';

const Column: FC<ColumnProps> = ({ heading, table, rowVariant, rowData, ...props }) => {
  const tableName = table.name as ETableName;

  return (
    <>
      {rowVariant === 'header' ? (
        <Header tableName={tableName} field={props.field} heading={heading} />
      ) : (
        <DataField
          tableName={tableName}
          field={props.field}
          heading={heading}
          value={'value' in props ? props.value : ''}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default Column;
