import { useMemo } from 'react';
import NoQuery from './no-query/NoQuery';
import Map from './map/Map';
import MapHeader from './map-header/MapHeader';
import { useQuickSightMap } from './hooks';
import styles from './quicksight.module.scss';

const QuickSight = () => {
  const { originalTrip, queries, altTrips, headerDetails, tripType, handleTripType } =
    useQuickSightMap();
  const hasQuery = useMemo(() => Object.values(queries).length > 0, [queries]);
  return (
    <div className={styles.container}>
      {hasQuery ? (
        <>
          <MapHeader
            headerDetails={headerDetails}
            tripType={tripType}
            handleTripType={handleTripType}
          />
          <Map
            handleTripType={handleTripType}
            originalTrip={originalTrip}
            alternativeTrips={altTrips}
            tripType={tripType}
          />
        </>
      ) : (
        <NoQuery />
      )}
    </div>
  );
};

export default QuickSight;
