/* eslint-disable jsx-a11y/control-has-associated-label */
import { QuestionMark } from '@assets/icons';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import styles from './help.module.scss';
import { helpLinks } from './links';

const Help = ({ location, className = '' }: { location: string; className?: string }) => {
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  return (
    <div className={`${styles.container} ${isGeotab ? styles['is-geotab'] : ''} ${className}`}>
      <a href={`${helpLinks[location]}`} target="_blank" rel="noreferrer" className={styles.icon}>
        <QuestionMark />
      </a>
    </div>
  );
};

export default Help;
