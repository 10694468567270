export const transactionHelperTypes = [
  {
    label: 'Maximum Tolls',
    value: 1
  },
  {
    label: 'Duplicates',
    value: 2
  },
  {
    label: 'License Plate Tolls',
    value: 3
  },
  {
    label: 'Misclassifications',
    value: 4
  },
  {
    label: 'Potential Misuse',
    value: 5
  },
  {
    label: 'Delayed Postings',
    value: 6
  },
  {
    label: 'Toll Transponder Fixes',
    value: 7
  },
  {
    label: 'License Plate Fixes',
    value: 8
  },
  {
    label: 'Potential Violations',
    value: 9
  },
  {
    label: 'Violations',
    value: 10
  },
  {
    label: 'Mischarges',
    value: 11
  }
];

export const fleetHelperTypes = [
  {
    label: 'Missing Transponder ',
    value: 1
  },
  {
    label: 'Missing License Plate ',
    value: 2
  }
];

export const getTransactionTypes = (isActual: boolean) => {
  const excludedValues = [6, 9];
  return transactionHelperTypes
    .filter(x => (isActual ? !excludedValues.includes(x.value) : excludedValues.includes(x.value)))
    .map(x => x.label)
    .sort((a, b) => a.localeCompare(b));
};
