import Meta from '@common/components/meta';
import { useSetTable } from '@common/hooks/useSetTable';
import { useAppSelector } from '@store/hooks';
import { useGetRolesQuery } from '@api/services/roles';
import { ETableName } from '@common/utils/enums';
// import Help from '@components/help';
import WebPortalTable from '../layouts/web-portal';

const Roles = () => {
  const metaOptions = {
    title: 'Roles',
    keywords: 'Roles'
  };

  const customerId = useAppSelector(state => state.customer.id);
  const { data, isLoading, isSuccess, isError, error, isFetching } = useGetRolesQuery(customerId, {
    skip: !customerId,
    pollingInterval: 600000
  });
  const name = ETableName.ROLES;
  useSetTable({ data, isLoading, isError, isSuccess, error, name, isFetching });

  return (
    <>
      <Meta {...metaOptions} />
      {/* <Help location="roles" /> */}
      <WebPortalTable title="roles" />
    </>
  );
};

export default Roles;
