import { EAuthRole, ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';

export const useAddNewVisible = (tableName: ETableName) => {
  const auth = useAppSelector(state => state.auth);
  const customerId = useAppSelector(state => state.customer.id);
  const canSeeAddNew =
    (auth.app_role === EAuthRole.SUPER_ADMIN && customerId && tableName === ETableName.CUSTOMERS) ||
    [ETableName.TOLLDOCUMENTS, ETableName.USERS].includes(tableName);

  return canSeeAddNew;
};
