export function AdditionalServices({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 1C8.88071 1 10 2.11929 10 3.5V9H15.5C16.8807 9 18 10.1193 18 11.5V16.5C18 17.8807 16.8807 19 15.5 19H2.5C1.11929 19 0 17.8807 0 16.5V3.5C0 2.11929 1.11929 1 2.5 1H7.5ZM8 11H2V16.5C2 16.7761 2.22386 17 2.5 17H8V11ZM15.5 11H10V17H15.5C15.7761 17 16 16.7761 16 16.5V11.5C16 11.2239 15.7761 11 15.5 11ZM7.5 3H2.5C2.22386 3 2 3.22386 2 3.5V9H8V3.5C8 3.22386 7.77614 3 7.5 3ZM14.8834 0.0067277L15 0C15.5128 0 15.9355 0.38604 15.9933 0.883379L16 1V3H18C18.5128 3 18.9355 3.38604 18.9933 3.88338L19 4C19 4.51284 18.614 4.93551 18.1166 4.99327L18 5H16V7C16 7.51284 15.614 7.93551 15.1166 7.99327L15 8C14.4872 8 14.0645 7.61396 14.0067 7.11662L14 7V5H12C11.4872 5 11.0645 4.61396 11.0067 4.11662L11 4C11 3.48716 11.386 3.06449 11.8834 3.00673L12 3H14V1C14 0.487164 14.386 0.0644928 14.8834 0.0067277L15 0L14.8834 0.0067277Z" />
    </svg>
  );
}
