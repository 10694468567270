import Button from '@common/ui/Button';
import { Approve } from '@assets/icons';
import { useFormikContext } from 'formik';
import Loader from '@common/ui/Loader';
import { useAppDispatch } from '@store/hooks';
import { closeModal } from '@store/features/modal';

const FormSubmit = ({
  label = 'Done',
  icon,
  type = 'update',
  fleet,
  customer
}: {
  label?: string;
  icon?: JSX.Element;
  type?: 'create' | 'update';
  fleet?: boolean;
  customer?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const formik = useFormikContext();
  return (
    <>
      {type === 'update' && (
        <>
          {!fleet ? (
            <Button
              icon={icon}
              appearance="button"
              size="xs"
              label={label}
              disabled={formik.isSubmitting || customer ? !formik.dirty : false}
              variant="primary"
              onClick={() => dispatch(closeModal())}
            />
          ) : (
            <Button
              appearance="button"
              buttonType="submit"
              size="xs"
              label={formik.isSubmitting ? 'Saving...' : 'Save'}
              icon={formik.isSubmitting ? <Loader /> : <Approve />}
              variant="success-primary"
              disabled={formik.isSubmitting || !formik.dirty}
              onClick={formik.handleSubmit}
            />
          )}
        </>
      )}
      {type === 'create' && (
        <Button
          appearance="button"
          label={formik.isSubmitting ? 'Please wait...' : label}
          icon={formik.isSubmitting ? <Loader /> : icon}
          disabled={formik.isSubmitting || !formik.dirty}
          onClick={formik.handleSubmit}
          size="xs"
        />
      )}
    </>
  );
};

export default FormSubmit;
