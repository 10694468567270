/* eslint-disable */
import moment from 'moment-timezone';

export const formatValue = (heading: string, value: any): string | any => {
  if (value === '' || value === null || value === undefined) return '-';
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';

  switch (heading) {
    case 'toll_posted_date':
      // Format date as MM/DD/YYYY
      return moment(value).isValid() ? moment(value).format('MM/DD/YYYY') : '-';

    case 'posted_toll_exit_date_time':
    case 'start_ts':
    case 'end_ts':
    case 'posted_toll_entry_date_time':
      // Format datetime as MM/DD/YYYY HH:mm:ss
      return moment(value).isValid() ? moment(value).format('MM/DD/YYYY HH:mm:ss') : '-';

    case 'posted_toll_amount':
    case 'expected_toll':
    case 'posted_toll':
    case 'disputable_amount':
    case 'tag_cost':
      return value && !isNaN(Number(value)) ? `$${Number(value).toFixed(2)}` : '-';

    case 'dispute_type':
      switch (value) {
        case 'LPT-TOLL':
          return 'License Plate Tolls';
        case 'MAX-TOLL':
          return 'Maxtoll';
        case 'MISCHARGE':
          return 'Mischarge';
        case 'XTA-DUPE':
          return 'Duplicate';
        default:
          return value;
      }

    default:
      return value;
  }
};
