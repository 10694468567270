import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { DragProps } from './types';
import Card from './Card/Card';

const Drag: FC<DragProps> = ({ cards, handleOnDragEnd, handleActiveState, className }) => {
  const identifiers = cards?.map(card => `dnd-${card.text}-${uuid()}`);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  return (
    <>
      {cards && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={event => handleOnDragEnd(event, identifiers)}
        >
          <SortableContext items={identifiers} strategy={verticalListSortingStrategy}>
            <section className={className}>
              {cards?.map((card, index) => (
                <Card
                  key={uuid()}
                  {...card}
                  handleActiveState={handleActiveState}
                  id={identifiers[index]}
                />
              ))}
            </section>
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};

export default Drag;
