/* eslint-disable */
import { AUTH_BE_URL } from '@api/config';
import { usePromiseToastNotify } from '@common/hooks';
import axios from 'axios';
import { FormikProps } from 'formik';
import { ChangeEvent, useState } from 'react';
import { IGeotabSuggestion, IGeotabVehicle } from '../types';

export const useVehicleFileActions = (
  vehicleDetails: Partial<IGeotabVehicle>[],
  handleSetVehicleD: (values: IGeotabVehicle[]) => void,
  initialVehicles: Partial<IGeotabVehicle>[]
) => {
  const [vehicles, setVehicles] = useState(vehicleDetails);

  const [isExported, setIsExported] = useState(false);

  const [hasSuggestion, setHasSuggestion] = useState(false);

  const [isImported, setIsImported] = useState(false);

  const [suggestionList, setSuggestionList] = useState<IGeotabSuggestion[]>([]);

  const fileReader = new FileReader();
  const toastify = usePromiseToastNotify();

  const exportVehicles = () =>
    toastify(
      async () => {
        setIsExported(true);
        const response = (
          await axios.post(
            `${AUTH_BE_URL}/downloadVehicles`,
            initialVehicles.filter(x => x.SUBSCRIPTION === 'ACTIVE')
          )
        ).data;
        if (response.status == 'OK') {

          // Convert the base64 data to a Blob
          const blob = new Blob([Uint8Array.from(atob(response.data), c => c.charCodeAt(0))], {
            type: 'application/csv'
          });

          // Create a temporary object URL
          const url = URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = 'vehicles.csv';
          a.style.display = 'none';

          // Append the anchor element to the document and trigger the click event
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }
      },
      'export',
      'vehicles'
    );

  const importVehicle = () => {
    document.getElementById('cl-import')?.click();
  };

  const startImport = (
    event: ChangeEvent<HTMLInputElement>,
    formik: FormikProps<{
      vehicles: IGeotabVehicle[];
    }>
  ) => {
    try {
      if (event.target.files && event.target.files[0]) {
        fileReader.readAsDataURL(event.target.files[0]);
        fileReader.onload = () =>
          toastify(
            async () => {
              const body = {
                data: fileReader.result
              };
              const response = (await axios.post(`${AUTH_BE_URL}/importVehicles`, body)).data;
              if (response.isSuccess) {
                const importedVehicles = response.data.parsedData;
                const newVehicles = importedVehicles.map((item: IGeotabVehicle) => {
                  const currentVehicle = vehicles.find(
                    x =>
                      x['VEHICLE NAME']?.trim()?.toLowerCase() ===
                      item['VEHICLE NAME'].trim().toLowerCase()
                  );
                  if (currentVehicle) {
                    currentVehicle['VEHICLE CLASS'] = item['VEHICLE CLASS']?.trim();
                    currentVehicle['VEHICLE TYPE'] = item['VEHICLE TYPE']?.trim();
                    currentVehicle['AXLE COUNT'] = item['AXLE COUNT'];
                    currentVehicle['TIRE COUNT'] = item['TIRE COUNT'];
                    currentVehicle['TAG TYPE'] = item['TAG TYPE']?.trim();
                    currentVehicle['TAG #'] = item['TAG #']?.trim();
                    currentVehicle['LICENSE PLATE STATE'] = item['LICENSE PLATE STATE']?.trim();
                    currentVehicle['LICENSE PLATE NUMBER'] = item['LICENSE PLATE NUMBER']?.trim();
                    currentVehicle['TAG ISSUING AGENCY'] = item['TAG ISSUING AGENCY']?.trim();
                    currentVehicle['VEHICLE HEIGHT'] = item['VEHICLE HEIGHT'];
                    currentVehicle['VEHICLE WEIGHT'] = item['VEHICLE WEIGHT'];
                    return currentVehicle;
                  }
                  return item;
                });
                if (!response.data.hasChanges) {
                  formik.setFieldValue('vehicles', newVehicles);
                } else {
                  const modVehicles: IGeotabSuggestion[] = [];
                  response.data.parsedData.forEach((vehicle: any) => {
                    console.log('Parsable vehicle ==> ', vehicle);
                    if (
                      (vehicle['PARSED TAG ISSUING AGENCY'] &&
                        vehicle['PARSED TAG ISSUING AGENCY'] !== vehicle['TAG ISSUING AGENCY']) ||
                      vehicle['PARSED TAG TYPES']
                    ) {
                      modVehicles.push({
                        vehicleName: vehicle['VEHICLE NAME'],
                        issuingAgency: vehicle['TAG ISSUING AGENCY'] || '-',
                        tagType: vehicle['TAG TYPE'] || '-',
                        parsedIssuingAgency:
                          vehicle['PARSED TAG ISSUING AGENCY'] &&
                          vehicle['PARSED TAG ISSUING AGENCY'] !== vehicle['TAG ISSUING AGENCY']
                            ? vehicle['PARSED TAG ISSUING AGENCY']
                            : null,
                        parsedTagTypeList: vehicle['PARSED TAG TYPES']
                          ? vehicle['PARSED TAG TYPES']
                          : null,
                        parsedTagType: null,
                        isAccepted: false
                      });
                    }
                  });
                  const hasParsedData =
                    modVehicles.findIndex(
                      x =>
                        x.parsedIssuingAgency ||
                        (x.parsedTagTypeList &&
                          x.parsedTagTypeList.length > 0 &&
                          x.parsedTagTypeList.length === 1 &&
                          x.tagType !== x.parsedTagTypeList[0])
                    ) !== -1;
                  if (hasParsedData) {
                    setHasSuggestion(true);
                  }
                  setSuggestionList(modVehicles);
                  setVehicles(newVehicles);
                }
                handleSetVehicleD(newVehicles);
                setVehicles(formik.values.vehicles);
                setIsImported(true);
              } else {
                throw new Error(response.message);
              }
            },
            'import',
            'vehicles',
            false
          );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    startImport,
    importVehicle,
    exportVehicles,
    vehicles,
    hasSuggestion,
    suggestionList,
    isExported,
    isImported
  };
};
