import Meta from '@common/components/meta';
import { ETableName } from '@common/utils/enums';
import { useSetTable } from './hooks';
// import Help from '@components/help';
import WebPortalTable from '../layouts/web-portal';

const Customers = () => {
  const metaOptions = {
    title: 'Customers',
    keywords: 'Customers'
  };

  const name = ETableName.CUSTOMERS;
  useSetTable({ name, searchableColumns: ['company name'] });

  return (
    <>
      <Meta {...metaOptions} />
      {/* <Help location="customers" /> */}
      <WebPortalTable title="customers" />
    </>
  );
};

export default Customers;
