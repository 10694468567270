import Meta from '@components/meta';
import Card from './components/Card/Card';

const CreatePassword = () => {
  const metaOptions = {
    title: 'Create Password',
    keywords: 'authentication, create password, new user'
  };
  return (
    <>
      <Meta {...metaOptions} />
      <Card />
    </>
  );
};

export default CreatePassword;
