import { FormikDatePicker } from '@common/ui/Form';
import { Calendar } from '@assets/icons';
import React from 'react';

const StartAndEndDate = ({
  startField,
  endField,
  startLabel,
  endLabel,
  startPlaceholder,
  endPlaceholder,
  showIcon = true,
  disabled = false,
  ...props
}: {
  className?: string | undefined;
  dropdownContainerEl?: React.RefObject<HTMLElement> | undefined;
  dropdownClassName?: string | undefined;
  startField: string;
  endField: string;
  startLabel?: string;
  endLabel?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
  showIcon?: boolean;
  disabled?: boolean;
}) => {
  return (
    <>
      <FormikDatePicker
        {...props}
        label={startLabel || `Start Date`}
        formField={startField}
        icon={showIcon ? <Calendar /> : undefined}
        placeholder={startPlaceholder || `Select Start Date`}
        disabled={disabled}
      />
      <FormikDatePicker
        {...props}
        label={endLabel || `End Date`}
        formField={endField}
        icon={showIcon ? <Calendar /> : undefined}
        placeholder={endPlaceholder || `Select End Date`}
        disabled={disabled}
      />
    </>
  );
};

export default StartAndEndDate;
