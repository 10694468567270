/* eslint-disable */
import { useEffect, useState } from 'react';
import styles from './confirm.module.scss';
import { ArrowsFilled } from '@assets/icons';

const BillTable = ({ data }: any) => {
  const header = ['name', 'vin', 'state', 'license plate', 'new billing status'];
  const [statusModifiedData, setModData] = useState(data);
  const [sortValue, setSortValue] = useState({
    sort_by: '',
    sort_order: ''
  });

  const getUsableStatus = (incomingStatus: string) => {
    if (incomingStatus === 'ACTIVE') {
      return 'Unsubscribed';
    } else {
      return 'Subscribed';
    }
  };

  useEffect(() => {
    if (data) {
      const newData = data?.map((item: any) => ({
        ...item,
        billing_status: getUsableStatus(item.billing_status)
      }));
      const sortNewData = newData.sort((a: any, b: any) => {
        return a['billing_status'].localeCompare(b['billing_status']);
      });
      setModData(sortNewData);
    }
  }, [data]);
  const sortHelper = (value: string) => {
    let sortObject = {
      sort_by: '',
      sort_order: ''
    };

    if (sortValue.sort_by === value) {
      if (sortValue.sort_order === '') {
        sortObject.sort_by = value;
        sortObject.sort_order = 'ASC';
      } else if (sortValue.sort_order === 'ASC') {
        sortObject.sort_by = value;
        sortObject.sort_order = 'DESC';
      }
    } else {
      sortObject.sort_by = value;
      sortObject.sort_order = 'ASC';
    }

    setSortValue(sortObject);

    const data = statusModifiedData.sort((a: any, b: any) => {
      const sortByField = sortObject.sort_by;
      const sortOrder = sortObject.sort_order;

      if (!sortByField && !sortOrder) {
        // Default to sorting by 'billing_status' in ascending order
        return a['billing_status'].localeCompare(b['billing_status']);
      }

      const compareResult = (order: string) => {
        const aValue = String(a[sortByField] || '');
        const bValue = String(b[sortByField] || '');

        if (order === 'ASC') {
          return aValue.localeCompare(bValue) || aValue.length - bValue.length;
        } else if (order === 'DESC') {
          if (bValue === null) {
            return 1; // Treat null values as having higher sort order
          } else if (aValue === null) {
            return -1;
          }
          return bValue.localeCompare(aValue) || bValue.length - aValue.length;
        } else {
          return a['billing_status'].localeCompare(b['billing_status']);
        }
      };

      const isEmptyString = (value: any) => value === '' || value === null || value === undefined;

      const isEmptyA = isEmptyString(a[sortByField]);
      const isEmptyB = isEmptyString(b[sortByField]);

      if (isEmptyA && isEmptyB) {
        return 0;
      } else if (isEmptyA) {
        return sortOrder === 'ASC' ? 1 : -1;
      } else if (isEmptyB) {
        return sortOrder === 'ASC' ? -1 : 1;
      }

      if (sortOrder === 'ASC' || sortOrder === 'DESC') {
        return compareResult(sortOrder);
      } else {
        return a['billing_status'].localeCompare(b['billing_status']);
      }
    });

    setModData(data);
  };

  const iconStyle = `${styles.icon} ${sortValue.sort_order ? styles[sortValue.sort_order] : ''}`;

  return (
    <div className={styles.leftComponent}>
      <section className={styles.rightContainer}>
        <div className={styles.header}>
          <section className={styles.row}>
            <section className={styles.text}>
              {header.map(heading => (
                <>
                  <h3
                    onClick={() => {
                      sortHelper(
                        heading === 'state'
                          ? 'plate_state'
                          : heading === 'license plate'
                          ? 'plate_number'
                          : heading === 'new billing status'
                          ? 'billing_status'
                          : heading
                      );
                    }}
                    className={
                      sortValue.sort_by.length > 0 && heading === sortValue.sort_by
                        ? iconStyle
                        : heading === 'state' && 'plate_state' === sortValue.sort_by
                        ? iconStyle
                        : heading === 'license plate' && 'plate_number' === sortValue.sort_by
                        ? iconStyle
                        : heading === 'new billing status' && 'billing_status' === sortValue.sort_by
                        ? iconStyle
                        : styles.icon
                    }
                  >
                    {heading} <ArrowsFilled />
                  </h3>
                </>
              ))}
            </section>
          </section>
        </div>
        <div className={styles.body}>
          {statusModifiedData &&
            statusModifiedData.map((item: any) => (
              <section className={styles.row}>
                <section className={styles.text}>
                  <p className={styles.name}>{item?.name ? item.name : '-'}</p>
                  <p className={styles.vin}>{item?.vin ? item.vin : '-'}</p>
                  <p className={styles.state}>
                    {item?.plate_state ? item.plate_state.toUpperCase() : '-'}
                  </p>
                  <p className={styles.licensePlate}>
                    {item?.plate_number ? item.plate_number.toUpperCase() : '-'}
                  </p>
                  <p className={styles.billing_status}>
                    {item?.billing_status ? item.billing_status : '-'}
                  </p>
                </section>
              </section>
            ))}
        </div>
      </section>
    </div>
  );
};

export default BillTable;
