import { ETableName } from '@common/utils/enums';
import { CustomerDataInterface, UnionDataInterface } from '@common/types/data';
import { useDataDelete, useCrossFiltering, useDataExport, useSelectCustomer } from '@common/hooks';
import { rowModalId } from '@common/services/format';
import { routes } from '@common/utils/route-names';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store/hooks';
import { openModal } from '@store/features/modal';
import { clearFilters } from '@store/features/filters';
import { clearSearch } from '@store/features/search';
import { setCurrentPage } from '@store/features/pagination';

export const useMenuActions = (tableName: ETableName, data: UnionDataInterface) => {
  const dispatch = useAppDispatch();
  const handleDataModal = () => dispatch(openModal(['data', rowModalId(tableName, data?.id)]));
  const { handleDelete } = useDataDelete([data], data?.id);
  const { handleDataExport } = useDataExport();
  const { crossFilter } = useCrossFiltering();
  const navigate = useNavigate();
  // ? handle company table selection
  const selectCustomer = useSelectCustomer();
  const selectCustomerTable = (tableLink: string) => {
    selectCustomer(data as CustomerDataInterface);
    navigate(tableLink);
  };

  // ? handle view toll document
  const handleViewTollDocument = () => {
    if ('statement_id' in data) navigate(`${routes.transactions}?document=${data.statement_id}`);
    dispatch(clearSearch(ETableName.TRANSACTIONS));
    dispatch(clearFilters(ETableName.TRANSACTIONS));
    dispatch(
      setCurrentPage({
        table: ETableName.TRANSACTIONS,
        currentPage: 1
      })
    );
  };
  return {
    handleOnDeleteClick: handleDelete,
    handleOnEditClick: handleDataModal,
    handleDataExport: () => handleDataExport('xlsx', tableName, [data], data),
    handleCrossFilter: crossFilter,
    handleCustomerTable: selectCustomerTable,
    handleViewTollDocument
  };
};
