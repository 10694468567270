/* eslint-disable import/no-cycle */
import { routes } from '@common/utils/route-names';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from '@store/hooks';
import { rtkqController } from '@api/config/base-api';
import { cookiesSessionUser } from '@common/services/auth';

export const useIsTokenValid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const nonErrorLocation = !['/403', '/404', '/501'].includes(location.pathname)
    ? location.pathname
    : '';
  const auth = useAppSelector(state => state.auth);

  useEffect(() => {
    const cookieSession = cookiesSessionUser.get();
    if (!auth.loading && !cookieSession.email && !auth.sessionIsValid) {
      rtkqController.abort();
      navigate(routes.login, { replace: true, state: { from: nonErrorLocation } });
    }
  }, [auth.sessionIsValid, auth.loading, navigate, nonErrorLocation]);

  return auth.sessionIsValid;
};
