/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, memo } from 'react';
import { v4 as uuid } from 'uuid';
import { UnionDataInterface } from '@common/types/data';
import Loader from '@common/ui/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { toast } from 'react-toastify';
import MobileTable from '@pages/tables/layouts/geotab-addin/components/table/mobile/MobileTable';
import Actions from '@pages/tables/layouts/geotab-addin/components/actions-block/actions/Actions';
import DisplayIndexes from '@pages/tables/layouts/geotab-addin/components/actions-block/display-indexes/DisplayIndexes';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { IHeading } from '@store/features/headings';
import { EAuthRole, ETableName } from '@common/utils/enums';
import SubscribeModal from '@pages/tables/layouts/commonComponents/subscribe/subscribe';
import AdminSubscribeModal from '@pages/tables/layouts/commonComponents/adminSubscribe/subscribe';
import { rowModalId } from '@common/services/format';
import { openModal } from '@store/features/modal';
// import { initSearch } from '@store/features/search';
import Panels from '../panels';
import Row from './Row/Row';
import styles from './table.module.scss';
import { RowModal } from '../modals';

const Table = ({ pageData, loading }: { pageData: UnionDataInterface[]; loading: boolean }) => {
  const dispatch = useAppDispatch();
  const table = useAppSelector(state => state.table);
  const role = useAppSelector(state => state.auth.app_role);
  const location = useLocation();
  // const customerId = useAppSelector(state => state.customer.id);
  const status = useAppSelector(state => state.subscribe.open);
  const tableName = table.name as ETableName;
  let headings = useAppSelector(state => state.headings[tableName]) as IHeading[];
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  if (tableName === ETableName.TRANSACTIONS) {
    if (isActualTollTab) {
      const fieldsToRemove = ['trip_id'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    } else {
      const fieldsToRemove = ['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    }
  }
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [lazyData, setLazyData] = useState<UnionDataInterface[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const loadMore = async () => {
    if (lazyData.length < pageData.length) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await setLazyData(prev => prev.concat(pageData.slice(prev.length, prev.length + 20)));
    }
  };

  const [matches, setMatches] = useState<boolean>(window.matchMedia('(max-width: 480px)').matches);

  const isFirstRender = useRef(true);

  useEffect(() => {
    setLazyData(pageData.slice(0, 20));
    setHasMore(!(lazyData.length >= pageData.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  useEffect(() => {
    window.matchMedia('(max-width: 640px)').addEventListener('change', e => setMatches(e.matches));
    setMatches(window.matchMedia('(max-width: 640px)').matches);
  }, []);

  const filteredHeadings = headings.filter(header => header.active);

  // Generate a unique key for the MobileTable component based on the headings
  const mobileTableKey = filteredHeadings.map(header => header.field).join('-');

  const { activeModal: modal, id: modalId } = useAppSelector(state => state.modal);

  const mobileDetailDataAction = (headerIndex: number) => {
    setSelectedRowIndex(headerIndex);
    return dispatch(openModal(['data', rowModalId(tableName, lazyData[headerIndex]?.id || '')]));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (
      dispatch &&
      isFirstRender.current &&
      table.name === ETableName.TRIPS &&
      location.search &&
      queryParams?.get('tripId')
    ) {
      isFirstRender.current = false;
      if (pageData.length === 1) {
        dispatch(openModal(['data', rowModalId(table.name, queryParams?.get('tripId') || '')]));
      } else if (pageData.length === 0) {
        toast.error('Trip not found', { position: 'top-center' });
      }
    }
  }, [dispatch, table.name, location.search]);
  return (
    <>
      {!matches ? (
        <div
          id="cl-table"
          style={{ height: `${table.name === ETableName.TRIPS ? '68vh' : '20vh'} ` }}
          className={`${styles.wrapper} ${loading ? styles.loading : ''}`}
        >
          {tableName !== ETableName.TRIPS && <Panels />}
          {status &&
            (role === EAuthRole.SUPER_ADMIN ? <AdminSubscribeModal /> : <SubscribeModal />)}
          <div ref={tableContainerRef} className={styles.container}>
            <Row variant="header" />
            {!loading ? (
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                loadMore={loadMore}
                hasMore={hasMore}
                // loader={<Loader key={uuid()} className={styles['infinite-loader']} />}
                useWindow={false}
                threshold={100}
              >
                {lazyData.map(data => {
                  return (
                    <Row
                      key={uuid()}
                      variant="data"
                      data={data}
                      dropdownContainerEL={tableContainerRef}
                    />
                  );
                })}
              </InfiniteScroll>
            ) : (
              <Loader className={styles.loader} />
            )}
          </div>
        </div>
      ) : (
        <>
          <Actions tableName={tableName} />
          <DisplayIndexes tableName={tableName} />
          <MobileTable
            key={mobileTableKey}
            data={lazyData}
            headings={filteredHeadings}
            tableName={tableName}
            detailAction={mobileDetailDataAction}
          />
          <AnimatePresence exitBeforeEnter>
            {modal === 'data' &&
              modalId === rowModalId(tableName, lazyData[selectedRowIndex]?.id) && (
                <RowModal data={lazyData[selectedRowIndex]} />
              )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default memo(Table);
