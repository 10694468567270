import { FC } from 'react';
import { motion } from 'framer-motion';
import SearchBar from '@common/ui/SearchBar';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Export } from '@assets/icons';
import { standardDropdownMotionVariants } from '@common/utils/motion-variants';
import Loader from '@common/ui/Loader';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { useDropdownSearch } from './hooks';
import styles from './dropdown.module.scss';
import { DropdownProps } from './types';
import SelectMenu from './SelectMenu/SelectMenu';
import ContextMenu from './ContextMenu/ContextMenu';
import { ContextMenuItemProps } from './ContextMenu/types';
import { Popper } from '../Popper';

const Dropdown: FC<DropdownProps> = ({
  values,
  type,
  motionVariants = standardDropdownMotionVariants,
  className = '',
  includeSearch,
  handleListSelection,
  loading,
  ...props
}) => {
  const { searchValue, searchResults, handleSearchValue } = useDropdownSearch(values, type);
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <Popper
      controlDisabled={props.disabled}
      position={props.position}
      offset={props.offset}
      sameWidth={props.sameWidth}
      boundaryElement={props.boundaryElement}
      control={props.children}
      handleOpenState={props.handleOpenState}
      isOpen={props.isOpen}
      usePortal={props.usePortal}
    >
      {(handlePopperElement, popperStyles, popperAttributes) => (
        <motion.div
          ref={handlePopperElement}
          aria-label="dropdown"
          variants={motionVariants}
          {...motionVariants}
          {...popperAttributes.popper}
          style={popperStyles.popper}
          className={`${styles.container} ${
            isGeotab ? styles['is-geotab'] : ''
          } ${className} ignore-onclickoutside`}
          data-dropdown-type={type}
        >
          {includeSearch && (
            <SearchBar
              size="xs"
              className={styles['search-bar']}
              collapsed={false}
              searchValue={searchValue}
              handleSearchValue={handleSearchValue}
              stayOpen
              inDropdown
              autoComplete={false}
            />
          )}

          {props.customDropdownItems ? (
            props.customDropdownItems
          ) : (
            <ul className={styles.list}>
              {type === 'select' && 'selection' in props && (
                <SelectMenu
                  selection={props.selection}
                  searchResults={searchResults as string[] | null}
                  values={values}
                  className={props.dropdownItemClassName}
                  handleSelectionState={props.handleSelectionState}
                  handleOpenState={props.handleOpenState}
                />
              )}
              {type === 'context' && (
                <ContextMenu
                  searchResults={searchResults as ContextMenuItemProps[] | null}
                  values={values}
                  className={props.dropdownItemClassName}
                  handleOpenState={props.handleOpenState}
                />
              )}
              {type === 'list' && values && values.length > 0 && (
                <List
                  className={styles['list-item']}
                  component="nav"
                  aria-label="list-selection"
                  dense
                  disablePadding
                >
                  {values.map((listItem: string | number, listIndex: number) => (
                    <>
                      <ListItemButton
                        className={styles['list-item-btn']}
                        sx={{ fontWeight: '800', fontSize: '1.5rem' }}
                        title={listItem.toString()}
                        onClick={() => {
                          if (handleListSelection) {
                            const listValue =
                              typeof listItem === 'string' ? _.camelCase(listItem) : listItem;
                            handleListSelection(listValue);
                          }
                        }}
                      >
                        {typeof listItem === 'string' &&
                          listItem.toLowerCase().includes('export') && (
                            <ListItemIcon sx={{ minWidth: '3rem' }}>
                              <Export />
                            </ListItemIcon>
                          )}
                        <ListItemText
                          primary={listItem}
                          sx={{
                            '& .MuiTypography-root': {
                              fontWeight: '600 !important',
                              fontSize: '1.3rem !important'
                            }
                          }}
                        />
                      </ListItemButton>
                      {listIndex !== values.length - 1 && <Divider />}
                    </>
                  ))}
                </List>
              )}
              {loading && (
                <span className={styles['loader-box']}>
                  <Loader className={styles.loader} />
                </span>
              )}
            </ul>
          )}
        </motion.div>
      )}
    </Popper>
  );
};

export default Dropdown;
