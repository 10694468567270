/* eslint-disable */
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import Accordion from '@common/ui/Accordion';
import { ETableName } from '@common/utils/enums';
import { v4 as uuid } from 'uuid';
import styles from './trips-list.module.scss';
import Trip from '@pages/tables/layouts/web-portal/components/modals/RowModal/Trip/Trip';
import { formatTimeZone } from '@common/services/format/formatTimeZone';
// import { placeholder } from './utils/placeholder';
// import ModalActions from '../../../../components/modal-actions/ModalActions';

const TripsList = ({ tripsData }: { tripsData: any }) => {
  const [tabActive, setActive] = useState(false);
  const [ind, setInd] = useState<number>(0);
  return (
    <div className={styles.container}>
      {/* <ModalActions type="searching" /> */}
      <ul className={styles.list}>
        {tripsData?.pageContent?.length > 0 ? (
          tripsData?.pageContent?.map((trip: any, index: number) => (
            <>
              {tabActive && index === ind && (
                <AnimatePresence exitBeforeEnter initial={false}>
                  <Trip
                    data={tripsData?.pageContent[ind]}
                    customCloseFunc={() => {
                      setInd(index);
                      setActive(!tabActive);
                    }}
                    hasFooter={false}
                  />
                </AnimatePresence>
              )}
              <li key={uuid()}>
                <Accordion
                  label={`${trip.startAddress} ---> ${trip.endAddress}`}
                  tableName={ETableName.FLEET}
                  className={styles.accordion}
                  onclick={() => {
                    setInd(index);
                    setActive(!tabActive);
                  }}
                >
                  <div className={styles.content}>
                    <div className={styles.start}>
                      <h3>Start</h3>
                      <p>
                        {formatTimeZone(
                          'startDateTime',
                          trip.startDateTime,
                          trip.startTimeZone,
                          trip
                        )}
                      </p>
                    </div>
                    <div className={styles.end}>
                      <h3>End</h3>
                      <p>
                        {formatTimeZone('stopDateTime', trip.stopDateTime, trip.endTimeZone, trip)}
                      </p>
                    </div>
                  </div>
                </Accordion>
              </li>
            </>
          ))
        ) : (
          <span>No trips available for this vehicle</span>
        )}
      </ul>
    </div>
  );
};

export default TripsList;
