/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import { ChartOptions } from 'chart.js';
import { memo, useMemo, useState } from 'react';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { createTheme, Grid, useMediaQuery } from '@mui/material';
import styles from '../trip-value.module.scss';
import {
  formatMonth,
  formatNumberToCurrency,
  formatNumberToCurrencyNoDecimal,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Plugin } from 'chart.js';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image

const AverageTollPerMile = (chartData: any) => {
  const width = '100%';
  const height = '31rem';

  const data = chartData?.chart_data;
  const value_total = data?.datasets[0]?.avgtoll_per_mile || 0;
  const no_value_total = data?.datasets[1]?.avgtoll_per_mile || 0;
  const total = Number(value_total) + Number(no_value_total) || 0;

  const theme = createTheme();
  const useResponsiveFontSize = () => {
    const xs = useMediaQuery(theme.breakpoints.down('xs')); // Extra-small screens
    const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm')); // Small screens
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Medium screens
    const lg = useMediaQuery(theme.breakpoints.between('md', 'lg')); // Large screens
    const xl = useMediaQuery(theme.breakpoints.up('lg')); // Extra-large screens

    if (xs) return 6;
    if (sm) return 7;
    if (md) return 7;
    if (lg) return 7;
    return 9; // xl
  };
  const fontSize = useResponsiveFontSize();

  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map<number, HTMLImageElement>();
    const imagePositions = new Map<number, { x: number; y: number }>();

    return {
      id: 'customSymbol',
      afterDatasetsDraw: chart => {
        const ctx = chart.ctx;
        // Enable high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              let data: any = dataset.data[index];
              if (Number(data.valued_avgtoll_per_mile) || Number(data.novalued_avgtoll_per_mile)) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0); // Get meta for the first dataset to access bar coordinates
              const bar = meta.data[index];

              const x = bar.x - 15; // Adjust to center the image
              const y = chart.scales.y.getPixelForValue(0) - 30; // Adjust to position the image above the x-axis

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  imagePositions.set(index, { x, y });
                  ctx.drawImage(image, x, y, 30, 30); // Adjust the size of the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                if (cachedImage) {
                  ctx.drawImage(cachedImage, x, y, 30, 30); // Draw the cached image
                }
              }
            }
          });
        }
      },
      afterDraw: chart => {
        chart.canvas.addEventListener('mousemove', event => {
          const mouseX = event.offsetX;
          const mouseY = event.offsetY;

          let tooltipEl = document.getElementById('custom-tooltip');

          // Create tooltip element if it does not exist
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'custom-tooltip';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.padding = '5px';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.opacity = '0';
            document.body.appendChild(tooltipEl);
          }

          // Check if mouse is over any image
          let isTooltipVisible = false;
          imagePositions.forEach((position, index) => {
            const { x, y } = position;

            if (mouseX > x && mouseX < x + 40 && mouseY > y && mouseY < y + 40) {
              if (tooltipEl) {
                // Check if tooltipEl is not null
                tooltipEl.innerHTML = 'No toll bill uploaded.'; // Set the text for the tooltip

                // Position tooltip above the image
                tooltipEl.style.opacity = '1';
                tooltipEl.style.left = event.clientX + 10 + 'px'; // Add some padding to position
                tooltipEl.style.top = event.clientY - 50 + 'px'; // Position above the image (adjust -50 based on your image height and tooltip size)
                isTooltipVisible = true;
              }
            }
          });

          if (!isTooltipVisible && tooltipEl) {
            // Ensure tooltipEl is not null
            tooltipEl.style.opacity = '0';
          }
        });
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    parsing: {
      xAxisKey: 'xaxis' // Add xAxisKey if you want to parse the x values as well
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
        // anchor: 'end',
        // align: 'top',
        // offset: 0,
        // textAlign: 'right',
        // font: {
        //   size: fontSize
        // },
        // formatter: (value, context) => {
        //   let datasets: any = [context.chart.data.datasets[0]]; //take first array item only and convert to array.
        //   const valuedtotal = datasets.reduce(
        //     (sum: number, dataset: any) =>
        //       sum + Number(dataset.data[context.dataIndex]['valued_avgtoll_per_mile']),

        //     0
        //   );

        //   datasets = [context.chart.data.datasets[1]]; //take 2nd array item only and convert to array.
        //   const novaluedtotal = datasets.reduce(
        //     (sum: number, dataset: any) =>
        //       sum + Number(dataset.data[context.dataIndex]['novalued_avgtoll_per_mile']),
        //     0
        //   );

        //   let sum = valuedtotal + novaluedtotal;
        //   if (context.datasetIndex === 1 && Number(sum)) {
        //     return `$${sum.toLocaleString(undefined, {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2
        //     })}`;
        //   } else {
        //     return '';
        //   }
        //   // return context.datasetIndex === 1 ? valuedtotal + novaluedtotal: '';
        // }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },

          label: function (context) {
            //console.log(context);
            const keyprefix = context.datasetIndex === 0 ? 'valued_' : 'novalued_';
            const rawdata: any = context.raw !== undefined ? context.raw : {};

            const avgtoll_per_mile = rawdata[keyprefix + 'avgtoll_per_mile'];
            const per_mile = `${formatNumberToCurrency(Number(avgtoll_per_mile), '$')}/mile`;

            return [per_mile];
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: false
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `${(value / 1000).toLocaleString()}K`;
              }
              return `$${value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });
  return (
    <MaterialCard
      title="Average Toll Per Mile"
      cardVariant="child"
      cardWidth={width}
      cardHeight={height}
      noGrid
      showHeaderArrow
    >
      <Grid container spacing={2} className={styles.gridContainer}>
        <Grid item xs={9} sm={9} md={8} lg={9} xl={9.7}>
          <BarChart data={data} width="100%" options={chartOptions} plugins={[customSymbol]} />
        </Grid>
        <Grid item xs={3} sm={3} md={4} lg={3} xl={2.3}>
          <div className={styles.total}>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.invoiced}`} />
              <h4>No-value</h4>
            </div>
            <p className={styles.value}>
              {formatNumberToCurrency(Number(no_value_total), '$')}/mile
            </p>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.tollogic}`} />
              <h4>Value</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(Number(value_total), '$')}/mile</p>
            <div className={styles.totalItem}>
              <div className={styles.squareBox} />
              <h4>Total</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(total, '$')}/mile</p>
          </div>
        </Grid>
      </Grid>
    </MaterialCard>
  );
};
export default memo(AverageTollPerMile);
