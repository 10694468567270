/* eslint-disable no-alert */
import {
  Dollar,
  Edit,
  // Export,
  GMap,
  Truck,
  Delete
} from '@assets/icons';
import { UnionDataInterface, VehicleDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { routes } from '@common/utils/route-names';
import { EDeviceType } from '@common/utils/enums/tablenames';
import { useMenuActions } from './useMenuActions';

export const useMenuValues = (tableName: ETableName, data?: UnionDataInterface) => {
  const {
    handleOnDeleteClick: handleDelete,
    handleOnEditClick: handleEdit,
    // handleDataExport,
    handleCrossFilter,
    handleCustomerTable,
    handleViewTollDocument
  } = useMenuActions(tableName, data as UnionDataInterface);
  const fleetFilter = data
    ? [data].map(objItem => ('vehicleName' in objItem ? objItem.vehicleName : ''))[0]
    : '';
  const tripsFilter = data ? [data].map(objItem => ('name' in objItem ? objItem.name : ''))[0] : '';

  const filterTrips = {
    text: 'Show Trip',
    icon: <GMap />,
    className: '',
    onClick: () => handleCrossFilter(tripsFilter, ETableName.TRIPS)
  };
  const filterFleet = {
    text: 'Show Vehicle',
    icon: <Truck />,
    className: '',
    onClick: () => handleCrossFilter(fleetFilter, ETableName.FLEET)
  };
  const showCustomerTrips = {
    text: 'Show Trips',
    icon: <GMap />,
    className: '',
    onClick: () => handleCustomerTable(routes.trips)
  };
  const showCustomerFleet = {
    text: 'Show Fleet',
    icon: <Truck />,
    className: '',
    onClick: () => handleCustomerTable(routes.fleet)
  };
  const showCustomerTransactions = {
    text: 'Show Transactions',
    icon: <Dollar />,
    className: '',
    onClick: () => handleCustomerTable(routes.transactions)
  };
  // const downloadTollDocument = {
  //   text: 'Download',
  //   icon: <Download />,
  //   className: '',
  //   onClick: () => alert('downloaded')
  // };
  // const exportRow = {
  //   text: 'Export',
  //   icon: <Export />,
  //   className: '',
  //   onClick: () => handleDataExport()
  // };
  const viewTransactions = {
    text: 'Show Transactions',
    icon: <Truck />,
    className: '',
    onClick: () => handleViewTollDocument()
  };
  const editRow = {
    text: 'Edit',
    icon: <Edit />,
    className: '',
    onClick: () => handleEdit()
  };
  const deleteRow = {
    text: 'Delete',
    icon: <Delete />,
    className: '',
    onClick: () => handleDelete()
  };

  const commonActions = [editRow];

  if (tableName === ETableName.TRIPS) {
    return [filterFleet];
  }
  if (tableName === ETableName.FLEET) {
    return (data as VehicleDataInterface).deviceType === EDeviceType.MANUAL
      ? [filterTrips, editRow, deleteRow]
      : [filterTrips, editRow];
  }
  if (tableName === ETableName.TOLLDOCUMENTS) {
    return [viewTransactions];
  }
  if (tableName === ETableName.CUSTOMERS) {
    return [showCustomerTrips, showCustomerFleet, showCustomerTransactions, ...commonActions];
  }
  return commonActions;
};
