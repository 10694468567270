// import { dashboardTabs } from '@common/utils/dashboard-tabs';
import { insightsDashboardTabs } from '@common/utils/insights-tabs';
// import { EModule } from '@common/utils/enums';
import { routes } from '@common/utils/route-names';
import { useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
// import { userCanAccessRoute } from '@common/services/auth';
import styles from './geotab-navigation.module.scss';

const DashboardNavigation = () => {
  const location = useLocation();
  const [queryParamsValue, setQueryParamsValue] = useState('');
  const isNavCollapse = useAppSelector(state => state.helpers.isNavCollapse);

  // const getNavPerms = (tabTitle: string): boolean => {
  //   const navPerms = userCanAccessRoute(`/${tabTitle}`);
  //   return navPerms || false;
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get('tab');

    if (queryValue !== null) {
      setQueryParamsValue(queryValue);
    }
  }, [location.search]);

  const activeClassName = (isActive: boolean) => {
    return `${isActive ? styles.active : ''}`;
  };

  if (location.pathname === routes.dashboard) {
    return (
      <div
        className={`${styles['dashboard-tab']} ${
          isNavCollapse ? styles['nav-non-collapsed'] : styles['nav-collapsed']
        }`}
      >
        <NavLink
          to={`${routes.newDashboard}?tab=${_.kebabCase(insightsDashboardTabs[0].label)}`}
          className={activeClassName(
            queryParamsValue === _.kebabCase(insightsDashboardTabs[0].label) ||
              queryParamsValue === ''
          )}
        >
          {insightsDashboardTabs[0].label}
        </NavLink>
        <NavLink
          to={`${routes.newDashboard}?tab=${_.kebabCase(insightsDashboardTabs[1].label)}`}
          className={activeClassName(
            queryParamsValue === _.kebabCase(insightsDashboardTabs[1].label)
          )}
        >
          {insightsDashboardTabs[1].label}
        </NavLink>
      </div>
    );
  }

  return <> </>;
};

export default DashboardNavigation;
