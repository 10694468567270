import { FC } from 'react';
import { ETableName } from '@common/utils/enums';
import { Fleet, Trips, Transactions, Customers, Users, TollDocuments } from './OptionsByTable';
import styles from './options.module.scss';
import { OptionsProps } from './types';

const Options: FC<OptionsProps> = ({
  inputClassName,
  dropdownClassName = '',
  tableType,
  dropdownContainerEl
}) => {
  return (
    <section className={styles.container}>
      {tableType === ETableName.FLEET && (
        <Fleet
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
      {tableType === ETableName.TRIPS && (
        <Trips
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
      {tableType === ETableName.TRANSACTIONS && (
        <Transactions
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
      {tableType === ETableName.CUSTOMERS && (
        <Customers
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
      {tableType === ETableName.USERS && (
        <Users
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
      {tableType === ETableName.TOLLDOCUMENTS && (
        <TollDocuments
          className={inputClassName}
          dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
          dropdownContainerEl={dropdownContainerEl}
        />
      )}
    </section>
  );
};

export default Options;
