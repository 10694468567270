import { TransactionDataInterface } from '@common/types/data';
import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';

const UploadTransactionFormik = ({
  children,
  data
}: ReactChildren & { data: TransactionDataInterface }) => {
  return (
    <Formik
      initialValues={data || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default UploadTransactionFormik;
