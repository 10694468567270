/* eslint-disable */
import { Form, Formik, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { SelectInput, TextInput } from '@common/ui/Form';
import { countries } from '@common/utils/constants';
import { getStatesByCountryName } from '@common/utils/constants/helpers';
import { IProfile } from '@store/data/profile';
import styles from './editInfo.module.scss';
import { useEffect, useState } from 'react';

export const EditOrganization = () => {
  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="Company Name"
        name="company_name"
        type="text"
        placeholder="Enter Company name"
        disabled
      />
      <TextInput
        className={styles.input}
        label="DBA Name"
        name="geotab_db"
        type="text"
        placeholder="Enter DBA name"
        disabled
      />
      <TextInput
        className={styles.input}
        label="Federal ID"
        name="federal_id"
        type="text"
        placeholder="Enter Federal ID"
      />
    </Form>
  );
};

export const EditAddress = () => {
  const formik = useFormikContext<AnyObject>();

  const data = formik.values as IProfile;

  const [matches, setMatches] = useState<boolean>(window.matchMedia('(max-width: 900px)').matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 950px)');

    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQueryList.addEventListener('change', handleMediaQueryChange);
    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const { values, setFieldValue } = useFormik<any>({
    initialValues: {
      company_register_address: {
        street1: data?.company_register_address?.street1,
        street2: data?.company_register_address?.street2,
        city: data?.company_register_address?.city,
        state: data?.company_register_address?.state,
        zipCode: data?.company_register_address?.zipCode,
        country: data?.company_register_address?.country
      },
      company_billing_address: {
        street1: data?.company_billing_address?.street1,
        street2: data?.company_billing_address?.street2,
        city: data?.company_billing_address?.city,
        state: data?.company_billing_address?.state,
        zipCode: data?.company_billing_address?.zipCode,
        country: data?.company_billing_address?.country
      },
      company_shipping_address: {
        street1: data?.company_shipping_address?.street1,
        street2: data?.company_shipping_address?.street2,
        city: data?.company_shipping_address?.city,
        state: data?.company_shipping_address?.state,
        zipCode: data?.company_shipping_address?.zipCode,
        country: data?.company_shipping_address?.country
      }
    },
    validationSchema,
    onSubmit: () => {}
  });

  const registerState = getStatesByCountryName(
    (formik.values as IProfile)?.company_register_address?.country
  );

  const billingState = getStatesByCountryName(
    (formik.values as IProfile)?.company_billing_address?.country
  );

  const shippingState = getStatesByCountryName(
    (formik.values as IProfile)?.company_shipping_address?.country
  );

  const [checked, setChecked] = useState(
    (formik.values as IProfile)?.company_register_address &&
      (formik.values as IProfile)?.company_register_address?.street1 ===
        (formik.values as IProfile)?.company_billing_address?.street1 &&
      (formik.values as IProfile)?.company_register_address?.street2 ===
        (formik.values as IProfile)?.company_billing_address?.street2 &&
      (formik.values as IProfile)?.company_register_address?.country ===
        (formik.values as IProfile)?.company_billing_address?.country &&
      (formik.values as IProfile)?.company_register_address?.city ===
        (formik.values as IProfile)?.company_billing_address?.city &&
      (formik.values as IProfile)?.company_register_address?.state ===
        (formik.values as IProfile)?.company_billing_address?.state &&
      (formik.values as IProfile)?.company_register_address?.zipCode ===
        (formik.values as IProfile)?.company_billing_address?.zipCode
  );

  const [checked1, setChecked1] = useState(
    (formik.values as IProfile)?.company_register_address &&
      (formik.values as IProfile)?.company_register_address?.street1 ===
        (formik.values as IProfile)?.company_shipping_address?.street1 &&
      (formik.values as IProfile)?.company_register_address?.street2 ===
        (formik.values as IProfile)?.company_shipping_address?.street2 &&
      (formik.values as IProfile)?.company_register_address?.country ===
        (formik.values as IProfile)?.company_shipping_address?.country &&
      (formik.values as IProfile)?.company_register_address?.city ===
        (formik.values as IProfile)?.company_shipping_address?.city &&
      (formik.values as IProfile)?.company_register_address?.state ===
        (formik.values as IProfile)?.company_shipping_address?.state &&
      (formik.values as IProfile)?.company_register_address?.zipCode ===
        (formik.values as IProfile)?.company_shipping_address?.zipCode
  );

  const [checked2, setChecked2] = useState(
    (formik.values as IProfile)?.company_billing_address &&
      (formik.values as IProfile)?.company_billing_address?.street1 ===
        (formik.values as IProfile)?.company_shipping_address?.street1 &&
      (formik.values as IProfile)?.company_billing_address?.street2 ===
        (formik.values as IProfile)?.company_shipping_address?.street2 &&
      (formik.values as IProfile)?.company_billing_address?.country ===
        (formik.values as IProfile)?.company_shipping_address?.country &&
      (formik.values as IProfile)?.company_billing_address?.city ===
        (formik.values as IProfile)?.company_shipping_address?.city &&
      (formik.values as IProfile)?.company_billing_address?.state ===
        (formik.values as IProfile)?.company_shipping_address?.state &&
      (formik.values as IProfile)?.company_billing_address?.zipCode ===
        (formik.values as IProfile)?.company_shipping_address?.zipCode
  );

  useEffect(() => {
    if (checked) {
      formik.setFieldValue(
        'company_billing_address.country',
        formik.values.company_register_address?.country
      );
      formik.setFieldValue(
        'company_billing_address.state',
        formik.values.company_register_address?.state
      );
    }
    if (checked1) {
      formik.setFieldValue(
        'company_shipping_address.country',
        formik.values.company_register_address?.country
      );
      formik.setFieldValue(
        'company_shipping_address.state',
        formik.values.company_register_address?.state
      );
    }
    if (checked2) {
      formik.setFieldValue(
        'company_shipping_address.country',
        formik.values.company_billing_address?.country
      );
      formik.setFieldValue(
        'company_shipping_address.state',
        formik.values.company_billing_address?.state
      );
    }
  }, [
    checked && formik.values.company_register_address?.country,
    checked && formik.values.company_register_address?.state,
    checked1 && formik.values.company_register_address?.country,
    checked1 && formik.values.company_register_address?.state,
    checked2 && formik.values.company_billing_address?.country,
    checked2 && formik.values.company_billing_address?.state
  ]);

  return (
    <div className={styles['address-container']}>
      <Form className={`${styles.container} ${matches ? styles['small-screen'] : ''}`}>
        <div className={styles['checkbox-header']}>
          <p className={styles['sub-header']}>Registered</p>
        </div>
        <TextInput
          className={styles.input}
          label="Address line 1"
          name="company_register_address.street1"
          type="text"
          placeholder="Enter Company name"
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_register_address.street1', e.target.value);
            formik.setFieldValue('company_register_address.street1', e.target.value);
            if (checked) {
              formik.setFieldValue('company_billing_address.street1', e.target.value);
              setFieldValue('company_billing_address.street1', e.target.value);
            }
            if (checked1) {
              formik.setFieldValue('company_shipping_address.street1', e.target.value);
            }
          }}
        />
        <TextInput
          className={styles.input}
          label="Address line 2"
          name="company_register_address.street2"
          type="text"
          placeholder="Enter Company name"
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_register_address.street2', e.target.value);
            formik.setFieldValue('company_register_address.street2', e.target.value);
            if (checked) {
              formik.setFieldValue('company_billing_address.street2', e.target.value);
              setFieldValue('company_billing_address.street2', e.target.value);
            }
            if (checked1) {
              formik.setFieldValue('company_shipping_address.street2', e.target.value);
            }
          }}
        />
        <SelectInput
          values={countries.map(x => x.name)}
          label="Country"
          className={styles.input}
          formField="company_register_address.country"
          placeholder="Select Country"
          includeSearch
        />
        <SelectInput
          values={registerState.map(x => `${x.isoCode}-${x.name}`).filter(x => x && x !== 'None')}
          label="State"
          className={styles.input}
          formField="company_register_address.state"
          placeholder="Select State"
          includeSearch
        />
        <TextInput
          className={styles.input}
          label="City"
          name="company_register_address.city"
          type="text"
          placeholder="Enter Company name"
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_register_address.city', e.target.value);
            formik.setFieldValue('company_register_address.city', e.target.value);
            if (checked) {
              formik.setFieldValue('company_billing_address.city', e.target.value);
              setFieldValue('company_billing_address.city', e.target.value);
            }
            if (checked1) {
              formik.setFieldValue('company_shipping_address.city', e.target.value);
            }
          }}
        />
        <TextInput
          className={styles.input}
          label="Zipcode"
          name="company_register_address.zipCode"
          type="text"
          placeholder="Enter Company name"
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_register_address.zipCode', e.target.value);
            formik.setFieldValue('company_register_address.zipCode', e.target.value);
            if (checked) {
              formik.setFieldValue('company_billing_address.zipCode', e.target.value);
              setFieldValue('company_billing_address.zipCode', e.target.value);
            }
            if (checked1) {
              formik.setFieldValue('company_shipping_address.zipCode', e.target.value);
            }
          }}
        />
      </Form>

      <Form className={`${styles.container} ${matches ? styles['small-screen'] : ''}`}>
        <div className={styles['checkbox-header']}>
          <p className={styles['sub-header']}>Billing</p>
          <div>
            <div className={styles['checkbox-name']}>
              <input
                type="checkbox"
                className="field"
                // value={values.companyBillingAddress.street1}
                checked={checked}
                onChange={e => {
                  setChecked(!checked);
                  checked2 && setChecked1(true);
                  checked && setChecked2(false);
                  if (!checked) {
                    formik.setFieldValue(
                      'company_billing_address.street1',
                      values.company_register_address.street1
                    );
                    setFieldValue(
                      'company_billing_address.street1',
                      values.company_register_address.street1
                    );
                    formik.setFieldValue(
                      'company_billing_address.street2',
                      values.company_register_address.street2
                    );
                    setFieldValue(
                      'company_billing_address.street2',
                      values.company_register_address.street2
                    );
                    formik.setFieldValue(
                      'company_billing_address.city',
                      values.company_register_address.city
                    );
                    setFieldValue(
                      'company_billing_address.city',
                      values.company_register_address.city
                    );
                    formik.setFieldValue(
                      'company_billing_address.state',
                      formik.values.company_register_address.state
                    );
                    setFieldValue(
                      'company_billing_address.state',
                      formik.values.company_register_address.state
                    );
                    formik.setFieldValue(
                      'company_billing_address.country',
                      formik.values.company_register_address.country
                    );
                    setFieldValue(
                      'company_billing_address.country',
                      formik.values.company_register_address.country
                    );
                    formik.setFieldValue(
                      'company_billing_address.zipCode',
                      values.company_register_address.zipCode
                    );
                    setFieldValue(
                      'company_billing_address.zipCode',
                      values.company_register_address.zipCode
                    );
                  }
                  if (checked2) {
                    formik.setFieldValue(
                      'company_shipping_address.street1',
                      values.company_register_address.street1
                    );
                    setFieldValue(
                      'company_shipping_address.street1',
                      values.company_register_address.street1
                    );
                    formik.setFieldValue(
                      'company_shipping_address.street2',
                      values.company_register_address.street2
                    );
                    setFieldValue(
                      'company_shipping_address.street2',
                      values.company_register_address.street2
                    );
                    formik.setFieldValue(
                      'company_shipping_address.city',
                      values.company_register_address.city
                    );
                    setFieldValue(
                      'company_shipping_address.city',
                      values.company_register_address.city
                    );
                    formik.setFieldValue(
                      'company_shipping_address.state',
                      formik.values.company_register_address.state
                    );
                    setFieldValue(
                      'company_shipping_address.state',
                      formik.values.company_register_address.state
                    );
                    formik.setFieldValue(
                      'company_shipping_address.country',
                      formik.values.company_register_address.country
                    );
                    setFieldValue(
                      'company_shipping_address.country',
                      formik.values.company_register_address.country
                    );
                    formik.setFieldValue(
                      'company_shipping_address.zipCode',
                      values.company_register_address.zipCode
                    );
                    setFieldValue(
                      'company_shipping_address.zipCode',
                      values.company_register_address.zipCode
                    );
                  }
                }}
              />
              <label>Same as register</label>
            </div>
          </div>
        </div>

        <TextInput
          className={styles.input}
          label="Address line 1"
          name="company_billing_address.street1"
          type="text"
          placeholder="Enter Company name"
          disabled={checked}
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_billing_address.street1', e.target.value);
            formik.setFieldValue('company_billing_address.street1', e.target.value);
            if (checked2) {
              formik.setFieldValue('company_shipping_address.street1', e.target.value);
            }
          }}
        />
        <TextInput
          className={styles.input}
          label="Address line 2"
          name="company_billing_address.street2"
          type="text"
          placeholder="Enter Company name"
          disabled={checked}
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_billing_address.street2', e.target.value);
            formik.setFieldValue('company_billing_address.street2', e.target.value);
            if (checked2) {
              formik.setFieldValue('company_shipping_address.street2', e.target.value);
            }
          }}
        />
        <SelectInput
          values={countries.map(x => x.name)}
          label="Country"
          className={styles.input}
          formField="company_billing_address.country"
          placeholder="Select Country"
          includeSearch
          disabled={checked}
        />
        <SelectInput
          values={billingState.map(x => `${x.isoCode}-${x.name}`).filter(x => x && x !== 'None')}
          label="State"
          className={styles.input}
          formField="company_billing_address.state"
          placeholder="Select State"
          includeSearch
          disabled={checked}
        />
        <TextInput
          className={styles.input}
          label="City"
          name="company_billing_address.city"
          type="text"
          placeholder="Enter Company name"
          disabled={checked}
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_billing_address.city', e.target.value);
            formik.setFieldValue('company_billing_address.city', e.target.value);
            if (checked2) {
              formik.setFieldValue('company_shipping_address.city', e.target.value);
            }
          }}
        />
        <TextInput
          className={styles.input}
          label="Zipcode"
          name="company_billing_address.zipCode"
          type="text"
          placeholder="Enter Company name"
          disabled={checked}
          onSetValue={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('company_billing_address.zipCode', e.target.value);
            formik.setFieldValue('company_billing_address.zipCode', e.target.value);
            if (checked2) {
              formik.setFieldValue('company_shipping_address.zipCode', e.target.value);
            }
          }}
        />
      </Form>

      <Form className={`${styles.container} ${matches ? styles['small-screen'] : ''}`}>
        <div className={styles['checkbox-header']}>
          <p className={styles['sub-header']}>Shipping</p>
          <div>
            <div className={styles['checkbox-name']}>
              <input
                type="checkbox"
                className="field"
                // value={values.companyBillingAddress.street1}
                checked={checked1}
                onChange={() => {
                  setChecked1(!checked1);
                  !checked && !checked1 && setChecked2(false);
                  if (!checked1) {
                    formik.setFieldValue(
                      'company_shipping_address.street1',
                      values.company_register_address.street1
                    );
                    formik.setFieldValue(
                      'company_shipping_address.street2',
                      values.company_register_address.street2
                    );
                    formik.setFieldValue(
                      'company_shipping_address.city',
                      values.company_register_address.city
                    );
                    formik.setFieldValue(
                      'company_shipping_address.state',
                      formik.values.company_register_address.state
                    );
                    formik.setFieldValue(
                      'company_shipping_address.country',
                      formik.values.company_register_address.country
                    );
                    formik.setFieldValue(
                      'company_shipping_address.zipCode',
                      values.company_register_address.zipCode
                    );
                  }
                }}
              />
              <label>Same as register</label>
            </div>

            {!checked && (
              <div className={styles['checkbox-name']}>
                <input
                  type="checkbox"
                  className="field"
                  // value={values.companyBillingAddress.street1}
                  checked={checked2}
                  onChange={() => {
                    setChecked2(!checked2);
                    !checked && !checked2 && setChecked1(false);
                    if (!checked2) {
                      formik.setFieldValue(
                        'company_shipping_address.street1',
                        values.company_billing_address.street1
                      );
                      formik.setFieldValue(
                        'company_shipping_address.street2',
                        values.company_billing_address.street2
                      );
                      formik.setFieldValue(
                        'company_shipping_address.city',
                        values.company_billing_address.city
                      );
                      formik.setFieldValue(
                        'company_shipping_address.state',
                        formik.values.company_billing_address.state
                      );
                      formik.setFieldValue(
                        'company_shipping_address.country',
                        formik.values.company_billing_address.country
                      );
                      formik.setFieldValue(
                        'company_shipping_address.zipCode',
                        values.company_billing_address.zipCode
                      );
                    }
                  }}
                />
                <label>Same as billing</label>
              </div>
            )}
          </div>
        </div>
        <TextInput
          className={styles.input}
          label="Address line 1"
          name="company_shipping_address.street1"
          type="text"
          placeholder="Enter Company name"
          disabled={checked1 || checked2}
        />
        <TextInput
          className={styles.input}
          label="Address line 2"
          name="company_shipping_address.street2"
          type="text"
          placeholder="Enter Company name"
          disabled={checked1 || checked2}
        />
        <SelectInput
          values={countries.map(x => x.name)}
          label="Country"
          className={styles.input}
          formField="company_shipping_address.country"
          placeholder="Select Country"
          includeSearch
          disabled={checked1 || checked2}
        />
        <SelectInput
          values={shippingState.map(x => `${x.isoCode}-${x.name}`).filter(x => x && x !== 'None')}
          label="State"
          className={styles.input}
          formField="company_shipping_address.state"
          placeholder="Select State"
          includeSearch
          disabled={checked1 || checked2}
        />
        <TextInput
          className={styles.input}
          label="City"
          name="company_shipping_address.city"
          type="text"
          placeholder="Enter Company name"
          disabled={checked1 || checked2}
        />
        <TextInput
          className={styles.input}
          label="Zipcode"
          name="company_shipping_address.zipCode"
          type="text"
          placeholder="Enter Company name"
          disabled={checked1 || checked2}
        />
      </Form>
    </div>
  );
};

export const EditContacts = () => {
  return (
    <div className={styles['address-container']}>
      <Form className={styles.container}>
        <p className={styles['sub-header']}>Account Admin</p>
        <TextInput
          className={styles.input}
          label="Name"
          name="admin_name"
          type="text"
          placeholder="Enter name"
        />
        <TextInput
          className={styles.input}
          label="Phone"
          name="admin_phone"
          type="text"
          placeholder="Enter phone number"
          // disabled
        />
        <TextInput
          className={styles.input}
          label="Email"
          name="admin_email"
          type="text"
          placeholder="Enter email"
          disabled
        />
      </Form>
      <Form className={styles.container}>
        <p className={styles['sub-header']}>Billing</p>
        <TextInput
          className={styles.input}
          label="Name"
          name="contact_name"
          type="text"
          placeholder="Enter name"
        />
        <TextInput
          className={styles.input}
          label="Phone"
          name="contact_phone"
          type="text"
          placeholder="Enter phone number"
        />
        <TextInput
          className={styles.input}
          label="Email"
          name="contact_email"
          type="text"
          placeholder="Enter email"
        />
      </Form>
    </div>
  );
};

const phoneRegExp = /^(?:\+1)?[2-9]\d{2}[.-]?\d{3}[.-]?\d{4}$/;

const validationSchema = Yup.object({
  contact_email: Yup.string()
    .nullable()
    .email('Should be an email')
    .max(255, 'Must be shorter than 500 character'),
  contact_phone: Yup.string().nullable().matches(phoneRegExp, 'Phone number is not valid'),
  admin_phone: Yup.string().nullable().matches(phoneRegExp, 'Phone number is not valid')
});

export const EditCompanyFormik = ({
  children,
  data,
  checked,
  onValueSubmit
}: ReactChildren & {
  data: IProfile | null;
  checked?: boolean;
  onValueSubmit: (values: IProfile, setSubmitting: (isSubmitting: boolean) => void) => void;
}) => {
  const newUpdateData = { ...data };
  return (
    <Formik
      initialValues={newUpdateData}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => onValueSubmit(values, setSubmitting)}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};
