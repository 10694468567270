/* eslint-disable */
import _ from 'lodash';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { format } from 'path';

export const useFormikDatePicker = (name = '') => {
  const [field, meta, helper] = useField({ name });
  const startDate = (field.value as string) ? dayjs(field.value as string).toDate() : null;
  const validDate = dayjs(field.value as string).isValid() ? startDate : null;
  const years = _.range(2015, new Date().getFullYear() + 2, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const onChange = (date: Date | null) => {
    if (name) {
      if (name === 'date.to' && date !== null) {
        if (dayjs(date).isBefore(dayjs().endOf('day').add(-1, 'day'))) {
          helper.setValue(dayjs(date).isValid() ? dayjs(date).endOf('day').toISOString() : '');
        } else {
          helper.setValue(
            field.value === ''
              ? dayjs().toISOString()
              : dayjs(date).isAfter(dayjs())
              ? dayjs().toISOString()
              : dayjs(date).toISOString()
          );
        }
      } else {
        helper.setValue(dayjs(date).isValid() ? dayjs(date).toISOString() : '');
      }
    }
  };

  return { startDate: validDate, years, months, onChange, field, meta };
};

export const useCustomDatePicker = (initialDate?: string, handleDate?: (date: string) => void) => {
  const startDate = (initialDate as string) ? dayjs(initialDate as string).toDate() : null;
  const validDate = dayjs(initialDate as string).isValid() ? startDate : null;
  const years = _.range(2015, new Date().getFullYear() + 2, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const onChange = (date: Date | null, isEndOfDay = false) => {
    if (isEndOfDay) {
      if (handleDate)
        handleDate(dayjs(date).isValid() ? dayjs(date).endOf('day').toISOString() : '');
    } else {
      if (handleDate) handleDate(dayjs(date).isValid() ? dayjs(date).toISOString() : '');
    }
  };

  return { startDate: validDate, years, months, onChange };
};
