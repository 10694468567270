/* eslint-disable */
import { FC } from 'react';
import Modal from '@common/ui/Modal';
import { useAppDispatch } from '@store/hooks';
import { modifyActiveHeadings, resetToDefault, saveSettings } from '@store/features/headings';
import Drag, { useDragActions } from '@common/ui/Drag';
import { closeModal } from '@store/features/modal';
import Button from '@common/ui/Button';
import { TableDisplayModalProps } from './types';
import styles from './table-display-modal.module.scss';
import { UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import Switch from '@common/ui/Switch';

const TableDisplayModal: FC<TableDisplayModalProps> = ({ headings, tableName }) => {
  const dispatch = useAppDispatch();
  const {
    cards,
    isFalseToggle,
    isTrueToggle,
    handleOnDragEnd,
    handleActiveState,
    deselectAllItems,
    selectAllItems
  } = useDragActions(
    headings.map(heading => ({
      id: heading.field as string,
      text: heading.heading === 'GPS Calculated Tolls' ? 'tollogic™ Tolls' : heading.heading ,
      active: heading.active
    }))
  );

  const handleViewUpdate = () => {
    const changedHead = cards.map(card => ({
      active: card.active,
      heading: card.text,
      field: card.id as keyof UnionDataInterface,
      filterType: headings.find(heading => heading.field === card.id)?.filterType || undefined,
      placeholder: headings.find(heading => heading.field === card.id)?.placeholder || undefined
    }));

    dispatch(
      modifyActiveHeadings({
        table: tableName,
        modHeadings: changedHead
      })
    );
    function saveHead() {
      switch (tableName) {
        case ETableName.TRIPS:
          return { trips: changedHead };
        case ETableName.FLEET:
          return { fleet: changedHead };
        case ETableName.TRANSACTIONS:
          return { transactions: changedHead };
        case ETableName.TOLLDOCUMENTS:
          return { toll_documents: changedHead };
        case ETableName.USERS:
          return { users: changedHead };
        default:
          return { customers: changedHead };
      }
    }

    //@ts-ignore
    dispatch(saveSettings(saveHead()));
    dispatch(closeModal());
  };

  const setToDefault = () => {
    //@ts-ignore
    dispatch(resetToDefault({ screen: tableName }));
    dispatch(closeModal());
  };

  return (
    <Modal
      title="Change table view"
      onActionClick={handleViewUpdate}
      customExtraButtons={
        <Button
          appearance="button"
          label="Set to default"
          variant="tertiary"
          size="xs"
          onClick={setToDefault}
        />
      }
      selectAllElement={
        <div className={styles['select-container']}>
          <span className={`${styles['toggle-span']} ${isTrueToggle ? styles.disabled : ''}`}>
            <p>Select All</p>
            <Switch toggle={isTrueToggle} handleToggle={selectAllItems} />
          </span>
          <span className={`${styles['toggle-span']} ${isFalseToggle ? styles.disabled : ''}`}>
            <p>Deselect All</p>
            <Switch toggle={isFalseToggle} handleToggle={deselectAllItems} />
          </span>
        </div>
      }
      className={styles.modal}
    >
      <section
        ref={el =>
          (el?.scrollHeight || 0) > (el?.clientHeight || 0)
            ? el?.classList?.add(styles['with-scrollbar'])
            : el?.classList?.remove(styles['with-scrollbar'])
        }
        className={styles.wrapper}
      >
        <Drag
          className={styles.container}
          cards={cards}
          handleOnDragEnd={handleOnDragEnd}
          handleActiveState={handleActiveState}
        />
      </section>
    </Modal>
  );
};

export default TableDisplayModal;
