import { PaginationProps } from './types';

export const usePageActions = (
  handlePageState: PaginationProps['handlePageState'],
  pagesArray: number[],
  page: number,
  totalPages: number
) => {
  const prevPage = () => {
    handlePageState(page !== 1 ? page - 1 : page);
  };

  const nextPage = () => {
    handlePageState(page !== totalPages ? page + 1 : page);
  };

  const handleChange = (value: number) => {
    handlePageState(value);
  };

  const start = page === pagesArray[0] || page === pagesArray[1] || page === pagesArray[2];

  const end =
    page === pagesArray[pagesArray.length - 3] ||
    page === pagesArray[pagesArray.length - 2] ||
    page === pagesArray[pagesArray.length - 1];

  return { pagesArray, prevPage, nextPage, handleChange, start, end };
};
