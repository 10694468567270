export function Distance({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 0C2 0 0 2 0 4.5C0 7.87 4.5 12.86 4.5 12.86C4.5 12.86 9 7.87 9 4.5C9 2 7 0 4.5 0ZM15.5 0C13 0 11 2 11 4.5C11 7.87 15.5 12.86 15.5 12.86C15.5 12.86 20 7.87 20 4.5C20 2 18 0 15.5 0ZM15.5 14C14.23 14 13.1 14.8 12.68 16H7.32C7.05502 15.2516 6.50416 14.6387 5.78813 14.2957C5.0721 13.9527 4.24929 13.9075 3.5 14.17C3.12758 14.3005 2.78453 14.5031 2.49047 14.7663C2.19641 15.0294 1.95709 15.3479 1.78621 15.7036C1.61532 16.0593 1.51622 16.4452 1.49456 16.8393C1.47291 17.2333 1.52913 17.6277 1.66 18C1.92857 18.7504 2.48314 19.364 3.20261 19.7069C3.92209 20.0498 4.748 20.094 5.5 19.83C6.35 19.53 7 18.85 7.32 18H12.69C13.24 19.56 14.96 20.38 16.5 19.83C16.8736 19.7008 17.2179 19.4992 17.5133 19.2365C17.8086 18.9739 18.0492 18.6554 18.2211 18.2995C18.393 17.9436 18.4929 17.5573 18.515 17.1626C18.5371 16.768 18.481 16.3729 18.35 16C17.92 14.8 16.78 14 15.5 14ZM15.5 18.5C15.1022 18.5 14.7206 18.342 14.4393 18.0607C14.158 17.7794 14 17.3978 14 17C14 16.6022 14.158 16.2206 14.4393 15.9393C14.7206 15.658 15.1022 15.5 15.5 15.5C15.8978 15.5 16.2794 15.658 16.5607 15.9393C16.842 16.2206 17 16.6022 17 17C17 17.3978 16.842 17.7794 16.5607 18.0607C16.2794 18.342 15.8978 18.5 15.5 18.5ZM7 4.5C7 5.88071 5.88071 7 4.5 7C3.11929 7 2 5.88071 2 4.5C2 3.11929 3.11929 2 4.5 2C5.88071 2 7 3.11929 7 4.5ZM15.5 7C16.8807 7 18 5.88071 18 4.5C18 3.11929 16.8807 2 15.5 2C14.1193 2 13 3.11929 13 4.5C13 5.88071 14.1193 7 15.5 7Z"
      />
    </svg>
  );
}
