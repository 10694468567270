import { Link } from 'react-router-dom';
import { routes } from '@common/utils/route-names';
import logo from '@assets/images/logo-with-text.svg';
import styles from '../logo.module.scss';

const LogoWithText = ({ className = '' }: ClassName) => {
  return (
    <Link to={routes.dashboard} className={`${styles.container} ${className}`}>
      <picture className={`${styles['logo-with-text']}`}>
        <img src={logo} alt="logo" />
      </picture>
    </Link>
  );
};

export default LogoWithText;
