/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useRef, CSSProperties, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { ETableName } from '@common/utils/enums';
import useOnclickOutside from 'react-cool-onclickoutside';
import styles from './filter-form.module.scss';
import Options from '../Options/Options';
import Footer from './Footer/Footer';
import { FilterFormProps } from './types';
import { useFiltersForm } from './hooks';
import {
  tripsFilterValidation,
  transactionsFilterValidation,
  tollDocumentsFilterValidation
} from './utils/formValidation';
import dayjs from 'dayjs';

const FilterForm: FC<FilterFormProps> = ({ tableType, className, handleOpenState }) => {
  const formRef = useRef<HTMLFormElement>(null);

  const { initialValues, showResults, clearResults } = useFiltersForm();

  const resetFilters = () => {
    clearResults();
    handleOpenState(false);
  };
  const customDatePickerRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(0);
  useEffect(() => {
    setInputWidth(customDatePickerRef.current?.getBoundingClientRect().width || 0);
  }, []);
  const validationSchema =
    tableType === ETableName.TRIPS
      ? tripsFilterValidation
      : tableType === ETableName.TRANSACTIONS
      ? transactionsFilterValidation
      : tableType === ETableName.TOLLDOCUMENTS
      ? tollDocumentsFilterValidation
      : undefined;
  useOnclickOutside(() => handleOpenState(false), { refs: [formRef] });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        if ('date' in values && values.date?.to) {
          if (dayjs(values.date.to).isAfter(dayjs())) {
            setFieldError('date.to', 'should not be more than current date');
          } else {
            setSubmitting(false);
            handleOpenState(false);
            showResults(values);
          }
        } else {
          setSubmitting(false);
          handleOpenState(false);
          showResults(values);
        }
      }}
    >
      {({ resetForm }) => (
        <Form ref={formRef} className={`${styles.container} ${className || ''}`}>
          <section className={styles.body}>
            <h4 className={styles.heading}>Filters</h4>
            <section
              className={styles['inputs-container']}
              ref={customDatePickerRef}
              style={{ '--date-picker-width': `${inputWidth}px` } as CSSProperties}
            >
              <Options
                tableType={tableType}
                inputClassName={styles.input}
                dropdownContainerEl={formRef}
              />
            </section>
          </section>
          <Footer tableType={tableType} resetForm={resetForm} resetFilters={resetFilters} />
        </Form>
      )}
    </Formik>
  );
};

export default FilterForm;
