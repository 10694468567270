/*eslint-disable */
import Modal from '@common/ui/Modal';
import { TripDataInterface, UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import { formatDistance } from '@common/services/format';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { CrossFilter, SubmitButton } from '../../../layouts/geotab-addin/components/form';
import MotionDiv from '../../../layouts/geotab-addin/components/motion-div/index';
import { useHandleActiveTitle } from '../../../hooks/useHandleActiveTitle';
import { UpdateTrip, UpdateTripFormik } from './update';
import TollsList from './tolls-list/TollsList';
import TripsMap from './map/TripsMap';
import styles from './view.module.scss';
import { formatDetailTimeZone } from '@common/services/format/formatTimeZone';
import { secondsToHms } from '@common/services/format/time';

const ViewTrip = ({ data, closeModal }: { data: UnionDataInterface; closeModal: () => void }) => {
  const nData = data as TripDataInterface;
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleActiveTitle('details');
  let modifyData = { ...nData };
  const isMetric = useAppSelector(state => state.auth.isMetric);

  if (modifyData?.distance && !isMetric) {
    // modifyData.distance = Math.ceil(formatDistance(nData.distance, 'kilometres'));
    modifyData.distance = nData.distanceInMiles;
  } else {
    modifyData.distance = nData.distance;
  }
  modifyData = formatDetailTimeZone(modifyData);
  //@ts-ignore
  modifyData.timeSpent = secondsToHms(modifyData.timeSpent);
  //@ts-ignore
  modifyData.moneySpent = modifyData.moneySpent?.toFixed(2);
  modifyData.licensePlate = modifyData.licensePlate?.toUpperCase();
  const timezone = modifyData.startDateTime.split(' ')[3];
  return (
    <UpdateTripFormik data={modifyData}>
      <Modal
        activeTitle={activeTitle}
        navigation={['details', 'GPS Calculated Tolls', 'map']}
        customPrimaryButton={<SubmitButton closeModal={closeModal} />}
        customExtraButtons={<CrossFilter tableName={ETableName.TRIPS} entryData={[data]} />}
        cancelButton={false}
        className={styles.modal}
        handleActiveTitle={handleActiveTitle}
        helpLocation="trip_"
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
            {activeTitle === 'details' && <UpdateTrip />}
            {activeTitle === 'GPS Calculated Tolls' && (
              <TollsList tripId={data.id} timezone={timezone} />
            )}
            {activeTitle === 'map' && <TripsMap data={nData} timezone={timezone} />}
          </MotionDiv>
        </AnimatePresence>
      </Modal>
    </UpdateTripFormik>
  );
};

export default ViewTrip;
