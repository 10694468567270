import { useEffect, useState } from 'react';

export const useModalMotionVariants = (activeTitle: string, prevTitle: string) => {
  const [motionInitial, setMotionInitial] = useState('50%');
  const [motionExit, setMotionExit] = useState('-50%');

  useEffect(() => {
    if (
      (activeTitle === 'details' && !['trips', 'tolls'].includes(prevTitle)) ||
      (['trips', 'tolls'].includes(activeTitle) && !['history', 'map'].includes(prevTitle)) ||
      (['history', 'map'].includes(activeTitle) && !['details'].includes(prevTitle))
    ) {
      setMotionInitial('-50%');
      setMotionExit('50%');
    } else {
      setMotionInitial('50%');
      setMotionExit('-50%');
    }
  }, [activeTitle, prevTitle]);
  return [motionInitial, motionExit];
};
