import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';

const SecondaryLayout = () => {
  return (
    <section className={styles.container}>
      <Outlet />
      <small className={styles.copyright}>
        commerce
        <b>logic&#8482;</b>, Inc. All Rights Reserved
      </small>
    </section>
  );
};

export default SecondaryLayout;
