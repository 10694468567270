/* eslint-disable */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { setAuthUser } from '@store/features/auth';
import {
  authObj,
  cookiesSessionUser,
  cookieFilters,
  cookieSearch,
  cookieSort,
  cookiePagination,
  cookieCustomer
} from '@common/services/auth';
import { EAuthRole } from '@common/utils/enums/roles';
import { useIsTokenValid } from '@common/hooks/useIsTokenValid';
import { useGetUserRolePermsMutation, useGetUserSessionMutation } from '@api/services/auth';
import { useLogOut } from '@common/hooks';
import Cookies from 'js-cookie';
import { setSessionFilters } from '@store/features/filters';
import { setSessionSearch } from '@store/features/search';
import { setSessionSort } from '@store/features/sort';
import { setSessionPagination } from '@store/features/pagination';
import { setDefaultHeadings } from '@store/features/headings';
import { getTagTypes, getStatementTypes } from '@store/data/fleetTabs';
import { setCustomer } from '@store/data';

export const useProtectedRoute = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const [getSessionUser] = useGetUserSessionMutation();
  const [getUserRolePerms] = useGetUserRolePermsMutation();
  const handleLogOut = useLogOut();

  useEffect(() => {
    const cookieSession = cookiesSessionUser.get();
    if (!auth.id) {
      const checkSession = async () => {
        try {
          if (cookieSession.email) {
            const { email, accessToken } = cookieSession;
            const sessionUser = await getSessionUser({ email, accessToken }).unwrap();
            if (sessionUser.isSuccess) {
              const userRolePerms = await getUserRolePerms({
                accessToken: sessionUser.data.data.accessToken
              }).unwrap();

              const modAuthObj: any = {
                data: {
                  ...sessionUser.data.data
                }
              };
              if (userRolePerms.isSuccess) {
                modAuthObj.data.modules = userRolePerms.data;
              }
              const newAuth = authObj(modAuthObj);
              await dispatch(setAuthUser([newAuth, sessionUser.data.data.userId])).unwrap();
              dispatch(setDefaultHeadings(sessionUser?.data?.ui_table_settings));
              dispatch(getTagTypes()).catch(err => {
                console.log('Err in fetching issuing tag types ==> ', err);
              });
              dispatch(getStatementTypes()).catch(err => {
                console.log('Err in fetching statement types ==> ', err);
              });
              if (Cookies.get(cookieCustomer)) {
                const customerData = JSON.parse(Cookies.get(cookieCustomer) as string);
                dispatch(setCustomer(customerData));
              }
              if (Cookies.get(cookieFilters)) {
                dispatch(setSessionFilters(Cookies.get(cookieFilters)));
              }
              if (Cookies.get(cookieSearch)) {
                dispatch(setSessionSearch(Cookies.get(cookieSearch)));
              }
              if (Cookies.get(cookieSort)) {
                dispatch(setSessionSort(Cookies.get(cookieSort)));
              }
              if (Cookies.get(cookiePagination)) {
                dispatch(setSessionPagination(Cookies.get(cookiePagination)));
              }
            } else {
              handleLogOut();
            }
          } else {
            handleLogOut();
          }
        } catch (e) {
          console.log(e);
          handleLogOut();
        }
      };
      checkSession().catch((e: unknown) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getSessionUser, auth.id]);

  useIsTokenValid();

  return {
    isValid: auth.sessionIsValid,
    app_role: auth.app_role as EAuthRole,
    isLoading: auth.loading
  };
};
