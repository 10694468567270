import Modal from '@common/ui/Modal/Modal';
import { FC } from 'react';
import { tableNameByLength } from '@common/services/format';
import { AnimatePresence } from 'framer-motion';
import { useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button';
import { useDataDelete } from '@common/hooks';
import { ConfirmDeleteModalProps } from './types';
import styles from './confirm-delete-modal.module.scss';
import { useDontShowAgain } from './hooks';

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({ tableName, data, modalId }) => {
  const { activeModal: modal, id: storeModalId } = useAppSelector(state => state.modal);
  const { isChecked, handleCheckbox } = useDontShowAgain();
  const { handleDelete, deleting } = useDataDelete(data, modalId, true);

  return (
    <AnimatePresence>
      {modal === 'delete' && modalId === storeModalId && (
        <Modal
          className={styles.modal}
          headerClassName={styles['modal-header']}
          footerClassName={styles['modal-footer']}
          title={`Delete ${tableNameByLength(data.length, tableName)}`}
          withCheckbox
          checkboxLabel="Don't ask again"
          isChecked={isChecked}
          handleCheckbox={handleCheckbox}
          customPrimaryButton={
            <Button
              appearance="button"
              label={deleting ? 'Deleting...' : 'Delete'}
              variant="danger-primary"
              size="xs"
              onClick={handleDelete}
              disabled={deleting}
            />
          }
        >
          <article className={styles.container}>
            <p>
              Are you sure you want to delete the selected{' '}
              {tableNameByLength(data.length, tableName)}? Recovery will be impossible.
            </p>
          </article>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ConfirmDeleteModal;
