import Button from '@common/ui/Button';
import { routes } from '@common/utils/route-names';
import { useNavigate } from 'react-router-dom';
import styles from './password-action.module.scss';

const PasswordAction = ({ action }: { action: 'created' | 'changed' }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <p>Your password has been successfully {action}, proceed to log in.</p>
      <Button
        appearance="button"
        label="Proceed to log in"
        onClick={() => navigate(routes.login, { replace: true })}
      />
    </div>
  );
};

export default PasswordAction;
