/* eslint-disable */
import { stateList, countries } from './index';

export const findStateByCodeAndCountryCode = (
  stateList: any,
  code: string,
  countryCode: string
) => {
  if (stateList && code && countryCode) {
    const currentState = stateList.find(
      (x: AnyObject) => x.isoCode == code && x.countryCode == countryCode
    );
    return currentState;
  }
  return null;
};

export function getStateByCodeAndCountry(stateCode: any, countryCode: any) {
  if (!stateCode) return undefined;
  if (!countryCode) return undefined;
  return findStateByCodeAndCountryCode(stateList, stateCode, countryCode);
}

export function getStatesOfCountry(countryCode = '') {
  if (!countryCode) return [];
  const states = stateList.filter(value => {
    return value.countryCode === countryCode;
  });
  return states.sort((a: any, b: any) => 1 | 0 | -1);
}

export function getCountryCode(name = '') {
  if (!name) return [];
  const countryCode = countries.filter(value => {
    return value.name === name;
  });
  return countryCode[0]?.isoCode;
}

export function getStatesByCountryName(countryName = '') {
  if (!countryName) return [];
  const countryCode = countries.filter(value => {
    return value.name === countryName;
  });

  if (!countryCode[0]?.isoCode) return [];
  const states = stateList.filter(value => {
    return value.countryCode === countryCode[0]?.isoCode;
  });
  return states.sort((a, b) => a.name.localeCompare(b.name));
}

export function getStateCodeByStateNameandCountry(stateName: any, countryCode: any) {
  if (!stateName) return undefined;
  if (!countryCode) return undefined;
  const stateCode = stateList.find(
    (x: AnyObject) => x.name == stateName && x.countryCode == countryCode
  );
  return stateCode;
}

export function checkStateCode(value: any, countryCode: any) {
  if (value === '') return true;
  const stateCode = value.split(' ')[0];
  if (stateCode === value) return false;
  const check = stateList.find(
    (x: AnyObject) => x.isoCode == stateCode && x.countryCode == countryCode
  );
  if (check) {
    return true;
  } else {
    return false;
  }
}

export function convertDevicePlan(plan: string) {
  if (plan === 'REGULAR') {
    return 'Regular GeoTab';
  } else if (plan === 'PRO_PLUS') {
    return 'GeoTab Pro Plus';
  } else if (plan === 'VOLVO_OEM') {
    return 'Volvo OEM';
  } else if (plan === 'OEM') {
    return 'Non-Volvo OEM';
  }
}

export function convertBillingStatus(status: string) {
  if (status === 'ACTIVE') {
    return 'Subscribed';
  } else if (status === 'MONITORED') {
    return 'Monitored';
  } else if (status === 'INACTIVE_TOLLING') {
    return 'Unsubscribed Tolling';
  } else if (status === 'INACTIVE') {
    return 'Unsubscribed';
  } else if (status === 'BLOCKED') {
    return 'Unsubscribed Permanently';
  } else if (status === 'DELETED') {
    return 'Deleted';
  }
}
