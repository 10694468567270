/* eslint-disable */
import { VehicleDataInterface } from '@common/types/data';
import _ from 'lodash';
import { RootState } from '@store/types';
import { baseApi, FLEET_API } from '../config';
import { BEResponse } from '../types';
import {
  billingStatusList,
  devicePlansList,
  tagTypesList,
  vehicleClassesList
} from '@common/utils/constants/lists';
import { store } from '@store/index';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import Cookies from 'js-cookie';
import { geotabGroups } from '@common/services/auth';
import { TagInterface } from '@common/types/data/fleet.interface';
import { routes } from '@common/utils/route-names';

export type ICreateVehicle = Omit<
  VehicleDataInterface,
  | 'id'
  | 'licensePlate'
  | 'tagTypeD'
  | 'issuingAgencyIdD'
  | 'vehicleClassD'
  | 'wheels'
  | 'tires'
  | 'deviceId'
  | 'secTagTypeD'
  | 'trdTagTypeD'
  | 'secIssuingAgencyIdD'
  | 'secIssuingAgencyId'
  | 'trdIssuingAgencyIdD'
  | 'createdAt'
  | 'updatedAt'
  | 'devicePlanD'
  | 'billingStatusD'
  | 'secondTagExists'
  | 'secAgencyName'
  | 'trdAgencyName'
  | 'secTagType'
  | 'secTagTypeId'
  | 'secTagTransponder'
  | 'trdTagType'
  | 'trdTagTypeId'
  | 'trdTagTransponder'
  | 'trdIssuingAgencyId'
  | 'devicePlan'
  | 'billingStatus'
  | 'billingStatusUpdatedAt'
  | 'gpsReliability'
  | 'plateStateD'
>;

export type IUpdateVehicle = ICreateVehicle & { id: string };
export const fleetApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Fleet', 'Vehicle']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getFleet: builder.query<BEResponse<VehicleDataInterface>, [string, number, number]>({
        queryFn: async (
          [customer_id, page_index, page_size],
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) => {
          const getFleet = await fetchWithBQ({
            url: FLEET_API,
            params: { 'customer-id': customer_id, 'page-index': page_index, 'page-size': page_size }
          });
          if (getFleet.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (getFleet.error) {
            return { error: getFleet.error };
          }
          const fleetRes = getFleet.data as BEResponse<VehicleDataInterface>;
          const fleet = fleetRes.pageContent;
          const modFleet = fleet.map(
            vehicle =>
              ({
                ...vehicle,
                issuingAgencyIdD: vehicle.agencyName,
                secIssuingAgencyIdD: vehicle.secAgencyName,
                vehicleClassD: vehicleClassesList.find(
                  vehicleClass => vehicleClass.classCode === vehicle.vehicleClass
                )?.displayName,
                tagTypeD: tagTypesList.find(tagType => tagType.tagCode === vehicle.tagType)
                  ?.displayName,
                devicePlanD: devicePlansList.find(
                  devicePlan => devicePlan.planCode === vehicle.devicePlan
                )?.displayName,
                billingStatusD: billingStatusList.find(
                  billingStatus => billingStatus.statusCode === vehicle.billingStatus
                )?.displayName
              } as VehicleDataInterface)
          );
          return { data: { ...fleetRes, pageContent: modFleet } };
        },
        providesTags: result =>
          result
            ? [
                ...result.pageContent.map(({ id }) => ({ type: 'Fleet' as const, id })),
                { type: 'Fleet', id: 'LIST' }
              ]
            : [{ type: 'Fleet', id: 'LIST' }]
      }),
      searchFleet: builder.mutation<BEResponse<VehicleDataInterface>, unknown>({
        queryFn: async (body: any, _queryApi, _extraOptions, fetchWithBQ) => {
          const isGeotab = (_queryApi.getState() as RootState).user?.sourceType
            ? _.kebabCase((_queryApi.getState() as RootState).user.sourceType) ===
              _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          const isMobileView = window.matchMedia('(max-width: 480px)').matches;

          const searchGeotabGroups = Cookies.get(geotabGroups)
            ? JSON.parse(Cookies.get(geotabGroups) || '')
            : null;
          if (
            searchGeotabGroups?.groupsFilter?.groups &&
            searchGeotabGroups?.groupsFilter?.operator
          ) {
            body['groupsFilter'] = {
              groups: searchGeotabGroups.groupsFilter.groups,
              operator: searchGeotabGroups.groupsFilter.operator
            };
          }

          const getFleet = await fetchWithBQ({
            url: `${FLEET_API}/${
              (isGeotab || body.crossFilterSource) && !isMobileView ? 'searchAll' : 'search'
            }`,
            method: 'post',
            body
          });
          if (getFleet.meta?.response?.status === 401) {
            SessionLogOut(isGeotab);
          }
          if (getFleet.meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (getFleet.error) {
            return { error: getFleet.error };
          }
          const fleetRes = getFleet.data as BEResponse<VehicleDataInterface>;
          const fleet = fleetRes.pageContent;
          const modFleet = fleet.map(vehicle => {
            if (vehicle.childTags) {
              vehicle.childTags.forEach((childTag: TagInterface) => {
                childTag.issuingAgencyIdD = childTag?.agencyName ? childTag?.agencyName : null;
              });
            }
            const vehicleReturn = {
              ...vehicle,
              issuingAgencyIdD: vehicle.agencyName,
              // tagTypeId: vehicle.tagType,
              tollConsolidator: vehicle.tollConsolidator,
              secIssuingAgencyIdD: vehicle.secIssuingAgencyId ? vehicle.secAgencyName : '',
              trdIssuingAgencyIdD: vehicle.trdIssuingAgencyId ? vehicle.trdAgencyName : '',
              vehicleClassD: vehicleClassesList.find(
                vehicleClass => vehicleClass.classCode === vehicle.vehicleClass
              )?.displayName,
              // tagTypeD: _.compact([
              //   tagTypesList.find(tagType => tagType.tagCode === vehicle.tagType)?.displayName
              // ]),
              tagType: vehicle.tagType,
              devicePlanD: devicePlansList.find(
                devicePlan => devicePlan.planCode === vehicle.devicePlan
              )?.displayName,
              billingStatusD: billingStatusList.find(
                billingStatus => billingStatus.statusCode === vehicle.billingStatus
              )?.displayName
            } as VehicleDataInterface;
            return vehicleReturn;
          });
          return { data: { ...fleetRes, pageContent: modFleet } };
        },
        invalidatesTags: [{ type: 'Fleet', id: 'LIST' }]
      }),
      getVehicle: builder.query<VehicleDataInterface, string>({
        query: vehicle_id => `${FLEET_API}/${vehicle_id}`,
        providesTags: ['Vehicle']
      }),
      createVehicle: builder.mutation<unknown, ICreateVehicle>({
        query: body => ({
          url: FLEET_API,
          method: 'post',
          body,
          responseHandler: response => response.json()
        }),
        invalidatesTags: [{ type: 'Fleet', id: 'LIST' }]
      }),
      updateVehicle: builder.mutation<unknown, any>({
        query: body => ({
          url: `${FLEET_API}/${body.id}`,
          method: 'put',
          body,
          responseHandler: async response => {
            if (response?.status === 401) {
              const isGeotab = store.getState().user?.sourceType
                ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
                : window.location !== window.parent.location;
              SessionLogOut(isGeotab);
            } else {
              const data = await response.json();
              return data;
            }
          }
        }),
        invalidatesTags: [{ type: 'Fleet', id: 'LIST' }, 'Vehicle']
      }),
      deleteVehicle: builder.mutation<unknown, string | number>({
        query: vehicle_id => ({
          url: `${FLEET_API}/${vehicle_id}`,
          method: 'delete',
          headers: {
            'Content-type': 'application/json'
          }
        }),
        invalidatesTags: [{ type: 'Fleet', id: 'LIST' }, 'Vehicle']
      })
    })
  });

export const {
  useGetFleetQuery,
  useSearchFleetMutation,
  useGetVehicleQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation
} = fleetApi;
