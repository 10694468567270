import { FC } from 'react';
import { Distance, Dollar, Time } from '@assets/icons';
import { formatNumber, formatTime } from '@common/services/format';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { DetailsProps } from './types';
import styles from './details.module.scss';

const Details: FC<DetailsProps> = ({ className = '', duration, cost, tripType, distance }) => {
  const isMetric = useAppSelector(state => state.auth.isMetric);
  return (
    <>
      {(duration || cost || distance) && (
        <section
          className={`${styles.container} ${styles[_.kebabCase(`${tripType}`)]} ${className}`}
        >
          {cost !== undefined && (
            <p className={styles.money} title="cost">
              <span className={styles.icon}>
                <Dollar />
              </span>
              <span className={styles.text}>{formatNumber(cost, 2)}</span>
            </p>
          )}
          {duration && (
            <p className={styles.time} title="duration">
              <span className={styles.icon}>
                <Time />
              </span>
              <span className={styles.text}>
                {formatTime(duration, tripType === 'alt' ? 'mins' : 'secs', 'hrs')}
              </span>
            </p>
          )}
          {distance && (
            <p className={styles.time} title="distance">
              <span className={styles.icon}>
                <Distance />
              </span>
              <span className={styles.text}>{`${distance?.toFixed(2)} ${
                isMetric ? 'km' : 'mi'
              }`}</span>
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default Details;
