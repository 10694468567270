export function StartPoint({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 14 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 4V9C0 9.26522 0.105357 9.51957 0.292893 9.70711C0.48043 9.89464 0.734784 10 1 10H4V18H2C1.73478 18 1.48043 18.1054 1.29289 18.2929C1.10536 18.4804 1 18.7348 1 19C1 19.2652 1.10536 19.5196 1.29289 19.7071C1.48043 19.8946 1.73478 20 2 20H8C8.26522 20 8.51957 19.8946 8.70711 19.7071C8.89464 19.5196 9 19.2652 9 19C9 18.7348 8.89464 18.4804 8.70711 18.2929C8.51957 18.1054 8.26522 18 8 18H6V10H11C11.1494 10.0005 11.297 9.96754 11.432 9.90351C11.567 9.83948 11.6859 9.74602 11.78 9.63L13.78 7.13C13.922 6.95264 13.9994 6.73221 13.9994 6.505C13.9994 6.27779 13.922 6.05736 13.78 5.88L11.78 3.38C11.6869 3.26215 11.5684 3.16679 11.4334 3.101C11.2983 3.03521 11.1502 3.00069 11 3H6V1C6 0.734784 5.89464 0.48043 5.70711 0.292893C5.51957 0.105357 5.26522 0 5 0C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V3H1C0.734784 3 0.48043 3.10536 0.292893 3.29289C0.105357 3.48043 0 3.73478 0 4ZM2 5H10.52L11.72 6.5L10.52 8H2V5Z" />
    </svg>
  );
}
