import { Plus } from '@assets/icons';
import { tableNameByLength } from '@common/services/format';
import { ETableName } from '@common/utils/enums';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button';

const AddNew = () => {
  const dispatch = useAppDispatch();
  const tableName = useAppSelector(state => state.table.name as ETableName);
  const addButtonLabel = `Add new ${tableNameByLength(1, tableName)}`;
  return (
    <Button
      label={addButtonLabel}
      icon={<Plus />}
      appearance="button"
      variant="primary"
      size="s"
      onClick={() => dispatch(openModal(['add-new', tableName]))}
    />
  );
};

export default AddNew;
