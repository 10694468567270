/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useGetCustomersQuery } from '@api/services/customers';
import { useSelectCustomer } from '@common/hooks';
import { useAppSelector } from '@store/hooks';
import CustomerIcon from '@common/ui/CustomerIcon';

export const useMenuValues = () => {
  const customerId = useAppSelector(state => state.customer.id);
  const { data } = useGetCustomersQuery([0, 1000], {
    pollingInterval: 600000
  });
  const customers = data?.pageContent || [];
  const selectCustomer = useSelectCustomer();
  const values = customers?.map(customer => ({
    text: customer.companyName,
    title: customer.companyName,
    icon: <CustomerIcon logo={customer.logoContent as string} name={customer.companyName} />,
    onClick: () => customerId !== customer.id && selectCustomer(customer)
  }));

  return values;
};
