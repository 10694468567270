import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useUploadTransactionMutation } from '@api/services/transactions';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { refetchTableData } from '@store/data';

export const useHandleSubmit = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);
  const [uploadTransaction] = useUploadTransactionMutation();
  const toastify = usePromiseToastNotify();
  const { handleError, handleSuccess } = useHandleApiResponse();

  const handleSubmit = (
    values: {
      file: File | null;
      tollDocumentType: string | null;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const formData = new FormData();
    const data = {
      file: values.file as File,
      fileName: values.file?.name || '',
      tollDocumentType: values.tollDocumentType as string
    };
    formData.set('file', data.file, data.fileName);
    formData.set('fileName', data.fileName);
    toastify(
      uploadTransaction([formData, customerId, data.tollDocumentType])
        .unwrap()
        .then(() => {
          handleSuccess('Transactions added and matched successfully');
        })
        .catch((error: FetchBaseQueryError) => {
          console.log(error);
          handleError(error, 'upload', 'transaction');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(refetchTableData(true));
          dispatch(openModal(['api-response', 'add-new']));
        }),
      'upload',
      'transaction file'
    );
  };

  return { handleSubmit };
};
