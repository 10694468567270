/* eslint-disable import/no-cycle */
import { configureStore, PayloadAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { combinedMiddlewares, combinedReducers } from './config';
import { logOut } from './features/auth';

const rootReducer = (
  state: ReturnType<typeof combinedReducers> | undefined,
  action: PayloadAction<unknown>
) => {
  let modState = state;
  if (logOut.match(action)) {
    modState = {} as typeof state;
  }

  return combinedReducers(modState, action);
};

export const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: combinedMiddlewares,
    devTools: process.env.NODE_ENV !== 'production'
  });

export const store = createStore();

setupListeners(store.dispatch);
