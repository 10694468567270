import * as Yup from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
  axles: Yup.number().required('vehicle axles is required'),
  vehicleClass: Yup.string().required('vehicle class is required'),
  tollAgency: Yup.string().required('toll agency is required'),
  plaza: Yup.string().required('plaza is required'),
  licensePlate: Yup.string().required('license plate is required'),
  facility: Yup.string().required('facility is required'),
  tagTransponder: Yup.string().required('tag transponder is required'),
  toll: Yup.number().required('toll is required')
});
