/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useField } from 'formik';

export const useSelectionState = (name: string, type: 'simple' | 'tags') => {
  const [field, , helper] = useField({ name });
  const defaultSelection = name && field?.value;
  const stringSelection = (defaultSelection as string | number) || '';
  const tagsSelection = (defaultSelection as (string | number)[]) || [];
  const handleStringSelectionState = (value: string | number) => {
    if (name) helper.setValue(value);
  };

  const handleTagsSelectionState = (value: (string | number)[]) => {
    if (name) helper.setValue(value);
  };

  const processedSelection = {
    selection: type === 'simple' ? stringSelection : tagsSelection,
    handleSelectionState: type === 'simple' ? handleStringSelectionState : handleTagsSelectionState
  };

  if (name === 'tagTypeD') {
    processedSelection.selection = '';
  }

  return processedSelection;
};
