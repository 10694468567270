/* eslint-disable no-plusplus */
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clearFilters, setFilters } from '@store/features/filters';
import { ETableName } from '@common/utils/enums';
import { TagItemGroupProps } from '@common/ui/Tag/types';
import { IUnionFilters } from '@common/types/filters';
import { setCurrentPage } from '@store/features/pagination';
import dayjs from 'dayjs';
import { useGetUsersQuery } from '@api/services/users';
import { setTable } from '@store/data';

type FilterKey = keyof IUnionFilters;
type FilterValue =
  | { from?: string | number; to?: string | number }
  | string
  | number
  | boolean
  | undefined;

export const useFilterTags = () => {
  const tableName = useAppSelector(state => state.table.name) as ETableName;
  const [filtersTags, updateFiltersTags] = useState<TagItemGroupProps[]>([]);

  const dispatch = useAppDispatch();
  const filtering = useAppSelector(state => state.filters[tableName]);

  const customerId = useAppSelector(state => state.customer.id);
  const { data, isLoading, isSuccess, isError, error, isFetching } = useGetUsersQuery(
    [customerId],
    {
      skip: tableName !== ETableName.USERS,
      pollingInterval: 600000
    }
  );

  const handleRemoveFilter = (currentTags: TagItemGroupProps[]) => {
    if (tableName === ETableName.USERS) {
      dispatch(
        setTable({ data, isLoading, isError, isSuccess, error, name: ETableName.USERS, isFetching })
      );
    }
    const currentItems = currentTags.map(tag => tag.itemGroup);
    let currentFilters = { ...filtering?.by } as IUnionFilters;
    const currentFiltersKeys = Object.keys(currentFilters);
    currentFiltersKeys.forEach(key => {
      if (!currentItems.includes(key)) {
        let value = currentFilters[key as FilterKey] as FilterValue;
        if (typeof value === 'object') {
          value = {
            from: typeof value.from === 'string' ? '' : undefined,
            to: typeof value.from === 'string' ? '' : undefined
          };
        } else if (typeof value === 'boolean') {
          value = false;
        } else {
          value = typeof value === 'string' ? '' : undefined;
        }
        currentFilters = { ...currentFilters, [key]: value };
      }
    });
    if (currentTags.length !== 0) {
      dispatch(
        setFilters({
          table: tableName,
          filters: currentFilters
        })
      );
    } else {
      dispatch(clearFilters(tableName));
    }
    dispatch(
      setCurrentPage({
        table: tableName,
        currentPage: 1
      })
    );
  };

  useEffect(() => {
    const currentFilters = filtering?.by || {};
    const currentFiltersKeys = Object.keys(currentFilters);
    const tags = currentFiltersKeys
      .map(key => {
        const value = currentFilters[key as FilterKey] as FilterValue;
        if (key === 'date' && typeof value === 'object') {
          return {
            itemGroup: key,
            item: `${value?.from ? dayjs(value.from?.toString()).format('MM/DD/YYYY') || '' : ''}${
              value?.from && value?.to ? ' - ' : ''
            }${value?.to ? dayjs(value.to?.toString()).format('MM/DD/YYYY') || '' : ''}`
          };
        }
        if (typeof value === 'object') {
          return {
            itemGroup: key,
            item: `${
              value.from || (value.from === 0 && value.to !== 0) ? value.from?.toString() || '' : ''
            }${(value.from || (value.from === 0 && value.to !== 0)) && value.to ? ' - ' : ''}${
              value.to || value.to !== 0 ? value.to?.toString() || '' : ''
            }`
          };
        }
        if (key === 'trailers' && typeof value === 'boolean' && value) {
          return {
            itemGroup: 'trailers',
            item: 'Trailers Included'
          };
        }
        if (key === 'deleted' && typeof value === 'boolean' && value) {
          return {
            itemGroup: 'deleted',
            item: 'Deleted Included'
          };
        }
        if (key === 'blocked' && typeof value === 'boolean' && value) {
          return {
            itemGroup: 'blocked',
            item: 'Unsubscribed Permanently Included'
          };
        }
        return {
          itemGroup: key,
          item: typeof value !== 'boolean' && typeof value !== undefined ? value || '' : ''
        };
      })
      .filter(tag => tag.item);
    updateFiltersTags(tags);
  }, [filtering?.active, filtering?.by]);

  return { filters: filtersTags, handleRemoveFilter };
};
