/*eslint-disable */
import * as Yup from 'yup';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import moment from 'moment-timezone';

dayjs.extend(duration);
const isMobileView = window.matchMedia('(max-width: 480px)').matches;

export const tripsFilterValidation = Yup.object().shape({
  date: Yup.object().shape({
    from: !isMobileView
      ? Yup.date()
          .max(dayjs(), 'should not be more than current date')
          .test('from-to-required', 'required', function (value) {
            const { to } = this.parent;
            return !to || !!value;
          })
          .test(
            'within-last-six-months',
            'Trips within the last six months can only be filtered',
            value => {
              const sixMonthsAgo = dayjs().subtract(6, 'months').startOf('month');
              const zeroHouredDate = new Date(dayjs(sixMonthsAgo).toDate());
              const startDate = moment(zeroHouredDate);
              const endDate = moment(value);
              // Calculate the duration between the two dates
              const duration = moment.duration(endDate.diff(startDate));
              // Get the number of months in the duration
              const monthsDifference = duration.asMonths();
              if (Number(monthsDifference) >= 0) {
                return true;
              }
              return false;
            }
          )
      : Yup.date().max(dayjs(), 'should not be more than current date'),
    to: Yup.date()
      // .max(dayjs().add(2, 'minutes'), 'should not be more than current date')
      .when(
        'from',
        (
          startDate: Date,
          schema: Yup.DateSchema<Date | undefined, AnyObject, Date | undefined>
        ) => {
          if (startDate) {
            const after = dayjs(startDate).add(1, 'minute');
            if (!isMobileView) {
              return schema.min(after, 'should be more than start date').required('required');
            } else {
              return schema.min(after, 'should be more than start date');
            }
          }
          return schema;
        }
      )
  }),
  // timeZone: Yup.string().test('date-check', 'required field', (val, context) => {
  //   const { date } = context.parent;
  //   if ((date.from || date.to) && !val) {
  //     return false;
  //   }
  //   return true;
  // }),
  toll: Yup.object().shape({
    from: Yup.number(),
    to: Yup.mixed<number | undefined>().when('from', {
      is: (val: number) => !!(val || val === 0),
      then: Yup.number()
        .moreThan(Yup.ref('from'), val => (val.value < val.more ? 'less than min' : 'same as min'))
        .required('required'),
      otherwise: Yup.mixed<undefined>().oneOf([Yup.ref('from')], 'min is required')
    })
  }),
  tripDistance: Yup.object().shape({
    from: Yup.number().integer('should be a whole number'),
    to: Yup.mixed<number | undefined>().when('from', {
      is: (val: number) => !!(val || val === 0),
      then: Yup.number()
        .integer('should be a whole number')
        .moreThan(Yup.ref('from'), val => (val.value < val.more ? 'less than min' : 'same as min'))
        .required('required'),
      otherwise: Yup.mixed<undefined>().oneOf([Yup.ref('from')], 'min is required')
    })
  }),
  tripDuration: Yup.object().shape({
    from: Yup.number().integer('should be a whole number'),
    to: Yup.mixed<number | undefined>().when('from', {
      is: (val: number) => !!(val || val === 0),
      then: Yup.number()
        .integer('should be a whole number')
        .moreThan(Yup.ref('from'), val => (val.value < val.more ? 'less than min' : 'same as min'))
        .required('required'),
      otherwise: Yup.mixed<undefined>().oneOf([Yup.ref('from')], 'min is required')
    })
  })
});

export const transactionsFilterValidation = Yup.object().shape({
  date: Yup.object().shape({
    from: Yup.date()
      .max(dayjs(), 'should not be more than current date')
      .test('from-to-required', 'required', function (value) {
        const { to } = this.parent;
        return !to || !!value;
      }),
    to: Yup.date()
      // .max(dayjs().add(2, 'minutes'), 'should not be more than current date')
      .when(
        'from',
        (
          startDate: Date,
          schema: Yup.DateSchema<Date | undefined, AnyObject, Date | undefined>
        ) => {
          if (startDate) {
            const after = dayjs(startDate).add(1, 'minute');
            return schema.min(after, 'should be more than start date').required('required');
          }
          return schema;
        }
      )
  }),
  toll: Yup.object().shape({
    from: Yup.number(),
    to: Yup.mixed<number | undefined>().when('from', {
      is: (val: number) => !!(val || val === 0),
      then: Yup.number()
        .moreThan(Yup.ref('from'), val => (val.value < val.more ? 'less than min' : 'same as min'))
        .required('required'),
      otherwise: Yup.mixed<undefined>().oneOf([Yup.ref('from')], 'min is required')
    })
  })
});

export const tollDocumentsFilterValidation = Yup.object().shape({
  date: Yup.object().shape({
    from: Yup.date()
      .max(dayjs(), 'should not be more than current date')
      .test('from-to-required', 'required', function (value) {
        const { to } = this.parent;
        return !to || !!value;
      }),
    to: Yup.date()
      // .max(dayjs().add(2, 'minutes'), 'should not be more than current date')
      .when(
        'from',
        (
          startDate: Date,
          schema: Yup.DateSchema<Date | undefined, AnyObject, Date | undefined>
        ) => {
          if (startDate) {
            const after = dayjs(startDate).add(1, 'minute');
            return schema.min(after, 'should be more than start date').required('required');
          }
          return schema;
        }
      )
  }),
  status: Yup.string(),
  invoiceIssuer: Yup.string()
});
