export function ChevronUp({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14.83C7.18739 15.0162 7.44088 15.1207 7.70505 15.1207C7.96923 15.1207 8.22269 15.0162 8.41 14.83L12 11.29L15.54 14.83C15.7274 15.0162 15.9809 15.1207 16.2451 15.1207C16.5092 15.1207 16.7627 15.0162 16.95 14.83C17.0437 14.7371 17.1182 14.6265 17.1689 14.5046C17.2197 14.3827 17.2459 14.252 17.2459 14.12C17.2459 13.988 17.2197 13.8573 17.1689 13.7354C17.1182 13.6136 17.0437 13.503 16.95 13.41L12.71 9.17C12.617 9.07629 12.5064 9.00189 12.3845 8.95113C12.2627 8.90036 12.132 8.87422 12 8.87422C11.8679 8.87422 11.7372 8.90036 11.6154 8.95113C11.4935 9.00189 11.3829 9.07629 11.29 9.17L7 13.41C6.90627 13.503 6.83183 13.6136 6.78106 13.7354C6.73029 13.8573 6.70415 13.988 6.70415 14.12C6.70415 14.252 6.73029 14.3827 6.78106 14.5046C6.83183 14.6265 6.90627 14.7371 7 14.83Z" />
    </svg>
  );
}
