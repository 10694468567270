import styles from './empty.module.scss';

const Empty = () => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>No vehicles added.</p>
    </div>
  );
};

export default Empty;
