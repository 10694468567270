/* eslint-disable @typescript-eslint/no-unused-vars */
import MaterialAccordion from '@common/ui/Mui/Accordion/MaterialAccordion';
import { TollID, Invoice, NoteClipBoard, TollAgency, Distance } from '@assets/icons';
import { formatCurrency, formatPercentage } from '@pages/dashboard/common/helper';
import { Chip } from '@mui/material';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { ITollInsightsProps, Title } from './types';
import styles from './savings.module.scss';
import Category from './category/Category';
import { useGetSOData } from './hooks';

const SavingsOpportunities = ({ selectedMonth }: ITollInsightsProps) => {
  const { data, loader, handleActiveTab } = useGetSOData(selectedMonth);
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    setIsInitialized(false);
  }, [selectedMonth]);

  useEffect(() => {
    if (data.length > 0 && !isInitialized) {
      setOpenAccordionId(data[0].category);
      setIsInitialized(true);
    }
  }, [data, isInitialized]);

  const titleName = selectedMonth.length > 0 ? `${selectedMonth.slice(0, -4)} Detail` : 'tesxt';
  const totalAmountSum = data.reduce((sum, item) => {
    const amount = parseFloat(item.savings);

    if (!Number.isNaN(amount)) {
      return sum + amount;
    }
    return sum;
  }, 0);

  const findChipStatusColor = (status: string) => {
    switch (status) {
      case 'Great':
        return {
          backgroundColor: '#EBF9EE',
          color: '#026c1d',
          border: '#026c1d'
        };
      case 'Fair':
        return {
          backgroundColor: '#FFF4E6',
          color: '#c87605',
          border: '#c87605'
        };
      case 'Bad':
        return {
          backgroundColor: '#FFE8E7',
          color: '#ff0e00',
          border: '#ff0e00'
        };
      default:
        return {
          backgroundColor: '#757575',
          color: '#ffffff',
          border: '#ffffff'
        };
    }
  };

  const getPerStyle = (sign: string) => {
    switch (sign) {
      case '-':
        return {
          color: 'red'
        };
      case '+':
        return {
          color: 'green'
        };
      default:
        return {
          color: 'black'
        };
    }
  };

  const getIconByCategory = (value: string): JSX.Element => {
    const category = value.toUpperCase();
    switch (category) {
      case Title.OVERCHARGES:
        return <TollAgency className={styles.icon} />;
      case Title.POTENTIALSAVINGS:
        return <Invoice className={styles.icon} />;
      case Title.TRIPS:
        return <Distance className={styles.icon} />;
      case Title.VIOLATIONS:
        return <NoteClipBoard className={styles.icon} />;
      default:
        return <TollID className={styles.icon} />;
    }
  };

  const addSign = (str: string) => {
    const number = Number(str);

    if (Number.isNaN(number)) {
      return '';
    }

    if (number > 0) {
      return '+';
    }

    if (number < 0) {
      return '-';
    }

    return '';
  };

  return (
    <div className={styles.container}>
      <MaterialCard
        title={titleName}
        helpLocation="month_detail"
        hasBorder
        noGrid
        cardHeight="100%"
      >
        {/* {loader ? (
          <Skeleton style={{ marginRight: '25rem', marginLeft: '50rem' }} variant="rectangular" />
        ) : (
          <p className={styles['potential-savings']}>
            Total <span className={styles.value}>{formatCurrency(totalAmountSum.toString())}</span>
          </p>
        )} */}

        <div className={styles.content}>
          {loader ? (
            [1, 2, 3, 4].map(() => (
              <Skeleton
                style={{ marginTop: '1rem' }}
                height="4rem"
                width="100%"
                variant="rectangular"
              />
            ))
          ) : (
            <>
              {data.map(item => (
                <MaterialAccordion
                  key={item.id}
                  height="5rem"
                  elevation={0}
                  onClick={(evt, value) => {
                    setOpenAccordionId(value ? item.category : null);
                    handleActiveTab(item.id, value);
                  }}
                  isOpen={item.category === openAccordionId}
                  title={
                    <p className={styles.title}>
                      {getIconByCategory(item.category)}
                      {item.title}
                    </p>
                  }
                  summary={
                    <div className={styles.summary}>
                      <span className={styles.savings}>{formatCurrency(item.savings)}</span>
                      <span className={styles['mom-percent']}>
                        <div className={styles['mom-number']}>
                          <p
                            className={styles['mom-value']}
                            title={`${addSign(item.momInPercent)}${formatPercentage(
                              Math.round(Number(item.momInPercent.replace('-', ''))).toString()
                            )}`}
                            style={getPerStyle(addSign(item.momInPercent))}
                          >
                            {addSign(item.momInPercent)}
                            {`${formatPercentage(
                              Math.round(Number(item.momInPercent.replace('-', ''))).toString(),
                              false
                            )}`}
                            <span className={styles['mom-per']}>MoM</span>
                          </p>
                        </div>
                      </span>
                      <div className={styles['chip-wrapper']}>
                        <Chip
                          className={styles['status-chip']}
                          label={item.status}
                          variant="outlined"
                          size="small"
                          style={findChipStatusColor(item.status)}
                        />
                      </div>
                    </div>
                  }
                  iconPosition="start"
                >
                  <div>
                    {item.category === Title.OVERCHARGES && (
                      <Category
                        content={item.content}
                        url="so-overcharges"
                        selectedMonth={selectedMonth}
                      />
                    )}
                    {item.category === Title.POTENTIALSAVINGS && (
                      <Category
                        content={item.content}
                        url="so-potential-savings"
                        selectedMonth={selectedMonth}
                      />
                    )}
                    {item.category === Title.TRIPS && (
                      <Category
                        content={item.content}
                        url="so-trips"
                        selectedMonth={selectedMonth}
                      />
                    )}
                    {item.category === Title.VIOLATIONS && (
                      <Category
                        content={item.content}
                        url="so-violations"
                        selectedMonth={selectedMonth}
                      />
                    )}
                  </div>
                </MaterialAccordion>
              ))}
            </>
          )}
        </div>
      </MaterialCard>
    </div>
  );
};

export default SavingsOpportunities;
