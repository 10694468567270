import { FC } from 'react';
import Details from '../Details/Details';
import styles from './trip-type.module.scss';
import { TripTypeProps } from './types';

const TripType: FC<TripTypeProps> = ({ tripType, handleTripType, headerDetails }) => {
  return (
    <section className={styles.container}>
      <button
        type="button"
        onClick={() => handleTripType('actual')}
        className={tripType === 'actual' ? `${styles.actual} ${styles.active}` : styles.actual}
      >
        Actual trip
      </button>
      <button
        type="button"
        onClick={() => handleTripType('alt')}
        className={
          tripType === 'alt' ? `${styles.alternative} ${styles.active}` : styles.alternative
        }
      >
        Alternative trip
      </button>
      <Details
        className={styles.details}
        tripType={tripType}
        duration={headerDetails.duration}
        cost={headerDetails.cost}
        distance={headerDetails.distance}
      />
    </section>
  );
};

export default TripType;
