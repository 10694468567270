/* eslint-disable @typescript-eslint/no-floating-promises */
import { SelectInput, TextInput } from '@common/ui/Form';
import Toggle from '@common/ui/Toggle';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { UserDataInterface } from '@common/types/data';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';
import { useAppSelector } from '@store/hooks';
import { EAuthRole } from '@common/utils/enums';
import Button from '@common/ui/Button/Button';
import styles from './update-user.module.scss';

const UpdateUser = () => {
  const formik = useFormikContext<UserDataInterface>();
  const hasEditAccess = useEditPermission(routes.users);
  const [toggle, setToggle] = useState<boolean>(false);
  const handleToggle = (value: boolean) => {
    setToggle(value);
    formik.setFieldValue('statusCode', value ? 'ACTIVE' : 'INACTIVE');
  };
  const [passwordChange, setPasswordChange] = useState<boolean>(false);

  const roleValues = ['ADMINISTRATOR', 'SUPERVISOR', 'VIEWER', 'SUPERADMIN', 'DEFAULTUSER'];

  const userRole = useAppSelector(state => state.auth.app_role);
  const sourceType = useAppSelector(state => state.user.sourceType);

  const customerId = useAppSelector(state => state.customer.id);
  const isEmptyCustomer = userRole === EAuthRole.SUPER_ADMIN && !customerId;

  const isGeotab = sourceType ? sourceType.toUpperCase() === 'GEOTAB' : false;

  let roles;
  if (userRole === EAuthRole.VIEWER || userRole === EAuthRole.DEFAULTUSER) {
    roles = roleValues.filter(
      item => !['ADMINISTRATOR', 'SUPERVISOR', 'SUPERADMIN'].includes(item)
    );
  } else if (userRole === EAuthRole.SUPERVISOR) {
    roles = roleValues.filter(item => !['SUPERADMIN', 'ADMINISTRATOR'].includes(item));
  } else if (userRole === EAuthRole.CUSTOMER_ADMIN) {
    roles = roleValues.filter(item => !['SUPERADMIN'].includes(item));
  } else {
    roles = roleValues;
  }

  useEffect(() => {
    setToggle(formik.values.statusCode === 'ACTIVE');
  }, [formik.values.statusCode]);

  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="Name*"
        name="name"
        type="text"
        placeholder="Enter name"
        disabled={isGeotab || !hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="E-mail"
        name="email"
        type="email"
        placeholder="Enter email address"
        disabled
      />
      <SelectInput
        values={roles}
        label="Role*"
        className={styles.input}
        formField="roleCode"
        placeholder="Select user role"
        disabled={isGeotab || !hasEditAccess || isEmptyCustomer}
      />
      {formik.values.statusCode !== 'INVITE_SENT' && (
        <div className={`${styles['status-toggle']} ${styles.input}`}>
          <span className={styles.label}>Active</span>
          <Toggle
            disabled={isGeotab || !hasEditAccess}
            className={styles.toggle}
            state={toggle}
            handleToggle={handleToggle}
          />
        </div>
      )}
      <div className={styles.button}>
        {!isGeotab && (
          <Button
            appearance="button"
            label={passwordChange ? 'Discard Password Change' : 'Change password'}
            disabled={!hasEditAccess}
            variant={passwordChange ? 'danger-secondary' : 'success-secondary'}
            size="xs"
            onClick={() => {
              if (passwordChange) {
                formik.setFieldValue('isPasswordChanged', false);
                setPasswordChange(false);
              } else {
                formik.setFieldValue('isPasswordChanged', true);
                setPasswordChange(true);
              }
            }}
          />
        )}
        {passwordChange && (
          <TextInput
            className={styles.passwordInput}
            label=""
            name="password"
            type="password"
            placeholder="Enter new password"
            autoComplete={false}
          />
        )}
      </div>
    </Form>
  );
};

export default UpdateUser;
