/* eslint-disable */
import { useState, useEffect, useMemo } from 'react';
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Tabs,
  Tab,
  Select,
  MenuItem,
  TableContainer,
  Table,
  Typography
} from '@mui/material';
import Button from '@common/ui/Button';
import { useAppSelector } from '@store/hooks';
import { ApproveCircle, ArrowsFilled, ExclamationMark, Export } from '@assets/icons';
import { ITollDisputeReports } from '@common/types/data/dispute-report.interface';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import _ from 'lodash';
import styles from './disputes.module.scss';
import Title from '../layouts/web-portal/components/title-block/title/Title';
import Loading from '../layouts/web-portal/components/loading-states/TableLoading';
import { BE_URL, DISPUTE_REPORTS_API } from '@api/config';
import { EAuthRole } from '@common/utils/enums';
import {
  columnWidths,
  dataAlignment,
  includedDuplicateHeadings,
  includedLicensePlateHeadings,
  includedMaxTollHeadings,
  includedMischargeHeadings,
  labelMapping,
  LabelType
} from './helper';
import { formatValue } from './utils';

const DisputesTable = ({ title }: { title: string }) => {
  const [disputes, setDisputes] = useState<ITollDisputeReports[]>([]);
  const [selectedTab, setSelectedTab] = useState<LabelType>('LPT-TOLL');
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMMM 'YY"));
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('toll_posted_date');
  const customerId = useAppSelector(state => state.customer.id);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(true);
  const auth = useAppSelector(state => state.auth);
  const appRole = useAppSelector(state => state.auth.app_role);
  const isSuperAdmin = appRole === EAuthRole.SUPER_ADMIN;
  const [counts, setCounts] = useState({
    'LPT-TOLL': 0,
    MISCHARGE: 0,
    'MAX-TOLL': 0,
    'XTA-DUPE': 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const parsedMonth = moment(selectedMonth, "MMMM 'YY");
      const startDate = parsedMonth.startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const endDate = parsedMonth.endOf('month').format('YYYY-MM-DD HH:mm:ss');
      setIsLoading(true);
      setHasError(false);
      try {
        const body = {
          customerId,
          start_date: startDate,
          end_date: endDate
        };

        const baseApi = BE_URL;
        const disputeApi = DISPUTE_REPORTS_API;
        const url = `${baseApi + disputeApi}/list`;

        const headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('email', auth.email);
        if (auth.accessToken) {
          headers.set('authorization', auth.accessToken);
        }
        headers.set('X-Content-Type-Options', 'nosniff');

        if (auth.app_role === EAuthRole.SUPER_ADMIN) {
          if (auth.id) headers.set('userId', auth.id);
          if (customerId) headers.set('customerId', customerId);
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        });

        if (!response.ok) {
          throw new Error('Failed to fetch disputes');
        }

        const data = await response.json();
        setDisputes(data.data.disputes);
        setCounts(data.data.counts);
        if (data.data.disputes && data.data.disputes.length > 0) setIsExportDisabled(false);
      } catch (error) {
        setHasError(true);
        console.error('Error fetching disputes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, customerId]);

  const filteredDisputes = useMemo(
    () =>
      Array.isArray(disputes)
        ? disputes.filter(dispute => dispute.dispute_type === selectedTab)
        : [],
    [disputes, selectedTab]
  );
  // const headings = useMemo(
  //   () => (filteredDisputes[0] ? Object.keys(filteredDisputes[0]) : []),
  //   [filteredDisputes]
  // );

  const headings = useMemo(() => {
    switch (selectedTab) {
      case 'LPT-TOLL':
        return includedLicensePlateHeadings;
      case 'MISCHARGE':
        return includedMischargeHeadings;
      case 'MAX-TOLL':
        return includedMaxTollHeadings;
      case 'XTA-DUPE':
        return includedDuplicateHeadings;
      default:
        return [];
    }
  }, [selectedTab]);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedDisputes = useMemo(() => {
    return _.orderBy(filteredDisputes, [orderBy], [order]);
  }, [filteredDisputes, orderBy, order]);

  const disputeTypes = useMemo(() => [...new Set(disputes.map(d => d.dispute_type))], [disputes]);

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    disputeTypes.forEach(type => {
      if (type === 'All') return;

      // const filteredSheetData = disputes.filter(d => d.dispute_type === type).map(d => ({ ...d }));

      const filteredSheetData = disputes
        .filter(d => d.dispute_type === type)
        .map(d => {
          // Format the fields using `formatValue`
          const formattedDispute: Record<string, any> = {};
          Object.keys(d).forEach(heading => {
            formattedDispute[heading] = formatValue(
              heading,
              d[heading as keyof ITollDisputeReports]
            );
          });
          return formattedDispute;
        });

      const worksheet = XLSX.utils.json_to_sheet(filteredSheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, type);
    });
    XLSX.writeFile(workbook, 'Disputes.xlsx');
  };

  const months = Array.from({ length: 12 }, (_, i) =>
    moment().subtract(i, 'months').format("MMMM 'YY")
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px',
        height: '100vh'
      }}
    >
      <Title title={title} hideButtons />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 'auto'
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          {(['LPT-TOLL', 'MISCHARGE', 'MAX-TOLL', 'XTA-DUPE'] as LabelType[]).map(type => (
            <Tab
              key={type}
              // label={labelMapping[type]}
              label={
                counts[type] === 0 ? (
                  <span>{labelMapping[type]}</span>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span>{labelMapping[type]}</span>
                    <span
                      style={{
                        backgroundColor: '#f44336',
                        color: '#fff',
                        borderRadius: '12px',
                        padding: '2px 6px',
                        fontSize: '10px',
                        fontWeight: 'bold'
                      }}
                    >
                      {counts[type] || 0}
                    </span>
                  </div>
                )
              }
              value={type}
              sx={{
                fontSize: '12px',
                minWidth: 'auto',
                padding: '10px 10px',
                textTransform: 'none'
              }}
            />
          ))}
        </Tabs>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Box display="flex" alignItems="center" gap={2}>
            <Select
              value={selectedMonth}
              onChange={e => setSelectedMonth(e.target.value)}
              // variant="outlined"
              // size="medium"
              sx={{
                width: '140px',
                height: '40px',
                backgroundColor: '#f9f9f9',
                fontSize: '12px',
                color: '#333'
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: '12px'
                    }
                  }
                }
              }}
            >
              {months.map(month => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
            <Button
              appearance="button"
              disabled={isExportDisabled}
              label="Export"
              icon={<Export />}
              variant="g-tertiary"
              size="s"
              onClick={handleExport}
            />
          </Box>
        </div>
      </div>

      {isLoading && <Loading />}
      {!isLoading && !hasError && (!sortedDisputes || sortedDisputes.length === 0) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <Typography style={{ fontSize: '1.5rem' }}>
            No {labelMapping[selectedTab]} data available to show
          </Typography>
        </div>
      )}
      {/* Table */}
      <TableContainer style={{ flex: 1, maxHeight: '80vh', border: '1px solid #dcdce4' }}>
        <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {headings.map(heading => (
                <TableCell
                  key={heading}
                  sortDirection={orderBy === heading ? order : false}
                  style={{
                    textAlign: dataAlignment[heading] || 'left',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    width: columnWidths[heading] || '200px'
                  }}
                >
                  {isSuperAdmin && heading === 'action_type' ? (
                    <h4
                      className={orderBy === heading ? styles.headinga : styles.heading}
                      title={heading}
                    >
                      {/* {heading.replace(/_/g, ' ').toUpperCase()} */}
                      {'Verification Status'}
                    </h4>
                  ) : (
                    <TableSortLabel
                      hideSortIcon
                      direction={orderBy === heading ? order : 'asc'}
                      onClick={() => handleSort(heading)}
                    >
                      <h4
                        className={orderBy === heading ? styles.headinga : styles.heading}
                        title={heading}
                      >
                        {heading.replace(/_/g, ' ').toUpperCase()}
                      </h4>
                      <span
                        className={
                          orderBy === heading ? `${styles.icon} ${styles[order]}` : styles.iconb
                        }
                      >
                        <ArrowsFilled />
                      </span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDisputes.map((dispute: Record<string, any>) => (
              <TableRow key={dispute.record_id}>
                {headings.map(heading => (
                  <TableCell
                    key={heading}
                    style={{
                      textAlign: dataAlignment[heading] || 'left',
                      fontSize: '12px',
                      whiteSpace: 'wrap',
                      width: columnWidths[heading] || '200px'
                    }}
                  >
                    {isSuperAdmin && heading === 'action_type' ? (
                      dispute[heading] === 'MANUAL_REVIEW' ? (
                        <>
                          <span
                            style={{
                              color: 'red',
                              fontSize: '20px',
                              display: 'inline-flex',
                              verticalAlign: 'middle'
                            }}
                          >
                            <ExclamationMark />
                          </span>
                          <span
                            style={{ fontSize: '12px', marginLeft: '5px', verticalAlign: 'middle' }}
                          >
                            {dispute.flag_type}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              color: 'green',
                              fontSize: '20px'
                              // width: '120px'
                            }}
                          >
                            <ApproveCircle /> {/* Replace with your green tick icon */}
                          </span>
                        </>
                      )
                    ) : (
                      <>{formatValue(heading, dispute[heading])}</>
                    )}
                    {/* <ApproveCircle/> */}
                    {/* {dispute[heading] ? dispute[heading] : '-'} */}
                    {/* {heading==='action_type' ? <ApproveCircle/> : <>{formatValue(heading, dispute[heading])}</>} */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DisputesTable;
