/* eslint-disable */
import { RootState } from '@store/types';
import _ from 'lodash';
import { tagTypesList } from '@common/utils/constants/lists';
import { TripDataInterface } from '@common/types/data';
import { baseApi, TRIPS_API, WEB_BE_URL } from '../config';
import { BEResponse, IAltTrip, IOriginalTrip, ICalculatedToll } from '../types';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { store } from '@store/index';
import Cookies from 'js-cookie';
import { geotabGroups } from '@common/services/auth';
import { routes } from '@common/utils/route-names';

export const tripsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [
      'Trips',
      'Trip',
      'AltTrips',
      'OriginalTrip',
      'Calculated Tolls',
      'Billed Tolls',
      'Calculated Toll'
    ]
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
      getTrips: builder.query<BEResponse<TripDataInterface>, [string, number, number]>({
        queryFn: async (
          [customer_id, page_index, page_size],
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) => {
          const tripsResponse = await fetchWithBQ({
            url: TRIPS_API,
            params: { 'customer-id': customer_id, 'page-index': page_index, 'page-size': page_size }
          });
          if (tripsResponse.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (tripsResponse.error) {
            return { error: tripsResponse.error };
          }
          const trips = tripsResponse.data as BEResponse<TripDataInterface>;
          const tripsData = trips.pageContent;
          const newTrips = tripsData.map(trip => ({
            ...trip,
            tagTypeD:
              tagTypesList.find(tagType => tagType.tagCode === trip.tagType)?.displayName || ''
          }));
          return { data: { ...trips, pageContent: newTrips } };
        },
        providesTags: result =>
          result
            ? [
                ...result.pageContent.map(({ id }) => ({ type: 'Trips' as const, id })),
                { type: 'Trips', id: 'LIST' }
              ]
            : [{ type: 'Trips', id: 'LIST' }]
      }),
      searchTrips: builder.mutation<BEResponse<TripDataInterface>, unknown>({
        queryFn: async (body: any, _queryApi, _extraOptions, fetchWithBQ) => {
          const isGeotab = (_queryApi.getState() as RootState).user?.sourceType
            ? _.kebabCase((_queryApi.getState() as RootState).user.sourceType) ===
              _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          const isMobileView = window.matchMedia('(max-width: 480px)').matches;

          const searchGeotabGroups = Cookies.get(geotabGroups)
            ? JSON.parse(Cookies.get(geotabGroups) || '')
            : null;
          if (
            searchGeotabGroups?.groupsFilter?.groups &&
            searchGeotabGroups?.groupsFilter?.operator
          ) {
            body['groupsFilter'] = {
              groups: searchGeotabGroups.groupsFilter.groups,
              operator: searchGeotabGroups.groupsFilter.operator
            };
          }


          const tripsResponse = await fetchWithBQ({
            url: `${TRIPS_API}/${
              (isGeotab || body.crossFilterSource) && !isMobileView ? 'searchAll' : 'search'
            }`,
            method: 'post',
            body
          });
          if (tripsResponse.meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (tripsResponse.meta?.response?.status === 401) {
            SessionLogOut(isGeotab);
          }
          if (tripsResponse.error) {
            return { error: tripsResponse.error };
          }
          const trips = tripsResponse.data as BEResponse<TripDataInterface>;
          const tripsData = trips.pageContent;
          const { isMetric } = (_queryApi.getState() as RootState).auth;
          const newTrips = tripsData.map(trip => ({
            ...trip,
            tagTypeD:
              tagTypesList.find(tagType => tagType.tagCode === trip.tagType)?.displayName || '',
            distanceD: isMetric ? trip.distance : trip.distanceInMiles
          }));
          return { data: { ...trips, pageContent: newTrips } };
        },
        invalidatesTags: [{ type: 'Trips', id: 'LIST' }]
      }),
      getSingleTrip: builder.query<TripDataInterface, string>({
        query: trip_id => `${TRIPS_API}/${trip_id}`,
        providesTags: ['Trip'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getAlternativeTripsByTripId: builder.query<IAltTrip[], string | number>({
        query: trip_id => `${TRIPS_API}/${trip_id}/alternative-trip`,
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'AltTrips' as const, id })),
                { type: 'AltTrips', id: 'LIST' }
              ]
            : [{ type: 'AltTrips', id: 'LIST' }],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getOriginalTripsByTripId: builder.query<IOriginalTrip, string | number>({
        query: trip_id => `${TRIPS_API}/${trip_id}/original-trip`,
        providesTags: ['OriginalTrip'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getCalculatedTolls: builder.query<ICalculatedToll[], string | number>({
        query: trip_id => ({ url: `/calc-tolls/v1`, params: { 'trip-id': trip_id } }),
        providesTags: ['Calculated Tolls'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getBilledTolls: builder.query<ICalculatedToll[], string | number>({
        query: trip_id => ({
          url: `${WEB_BE_URL}/trips/billed-tolls`,
          params: { trip_id: trip_id }
        }),
        providesTags: ['Billed Tolls'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getSingleCalculatedToll: builder.query<ICalculatedToll, string | number>({
        query: toll_id => ({ url: `/calc-tolls/v1/${toll_id}` }),
        providesTags: ['Calculated Toll'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      })
    })
  });

export const {
  useGetTripsQuery,
  useSearchTripsMutation,
  useGetSingleTripQuery,
  useLazyGetSingleTripQuery,
  useGetOriginalTripsByTripIdQuery,
  useGetAlternativeTripsByTripIdQuery,
  useGetCalculatedTollsQuery,
  useGetBilledTollsQuery,
  useLazyGetCalculatedTollsQuery,
  useGetSingleCalculatedTollQuery,
  useLazyGetSingleCalculatedTollQuery
} = tripsApi;
