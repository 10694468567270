import Modal from '@common/ui/Modal';
import { TransactionDataInterface, UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { formatTransactionDate } from '@common/services/format/formatTimeZone';
import { useAppSelector } from '@store/hooks';
import { CrossFilter, SubmitButton } from '../../../layouts/geotab-addin/components/form';
import { UpdateTransaction, UpdateTransactionFormik } from './update';
import styles from './view.module.scss';

const ViewTransaction = ({
  data,
  closeModal
}: {
  data: UnionDataInterface;
  closeModal: () => void;
}) => {
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const modifyData = { ...(data as TransactionDataInterface) };
  modifyData.date = formatTransactionDate(modifyData, false, isActualTollTab);
  modifyData.calculatedAmount = Number(modifyData.calculatedAmount).toFixed(2);
  return (
    <UpdateTransactionFormik data={modifyData as TransactionDataInterface}>
      <Modal
        activeTitle="singleHighlight"
        navigation={['Details']}
        customPrimaryButton={<SubmitButton closeModal={closeModal} />}
        customExtraButtons={
          <CrossFilter tableName={ETableName.TRANSACTIONS} entryData={[modifyData]} />
        }
        cancelButton={false}
        className={styles.modal}
        helpLocation="transaction_"
      >
        <UpdateTransaction />
      </Modal>
    </UpdateTransactionFormik>
  );
};

export default ViewTransaction;
