/*eslint-disable */
import { More } from '@assets/icons';
import { rowModalId } from '@common/services/format';
import { UnionDataInterface } from '@common/types/data';
import Dropdown from '@common/ui/Dropdown';
import { ETableName } from '@common/utils/enums';
import { ApiResponseModal, ConfirmDeleteModal } from '@components/modals';
import { closeModal, openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import RowCheckbox from '@pages/tables/layouts/web-portal/components/table/Row/Checkbox/RowCheckbox';
import { useCheckboxState } from '@pages/tables/layouts/web-portal/components/table/Row/hooks';
import { v4 as uuid } from 'uuid';
import Column from './Column/Column';
import styles from './data.module.scss';
import { useMenuValues } from './hooks/useMenuValues';
import { DataProps } from './types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Data = ({ data, headings, tableName, dropdownContainerEl, ViewModal }: DataProps) => {
  const table = useAppSelector(state => state.table);
  const values = useMenuValues(tableName, data);
  const navigate = useNavigate();
  // ? handle dropdown
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => {
    if (![ETableName.TRANSACTIONS].includes(tableName)) setDropdownIsOpen(value);
  };
  const dispatch = useAppDispatch();
  const handleDataModal = () =>{
    dispatch(openModal(['data', rowModalId(tableName, data?.id || '')]));
    {tableName === 'trips' && navigate(`/trips/${data.id}`);}
  }
  const {
    activeModal: modal,
    id: modalId,
    responseType,
    responseAction,
    responseMessage
  } = useAppSelector(state => state.modal);
  const { checkboxState } = useCheckboxState('data', tableName, table.data || [], data);
  const rowRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(20);
  useEffect(() => {
    setHeight(rowRef.current?.clientHeight || 20);
  }, [rowRef.current]);

  const { id } = useParams();
  const [isHovered, setIsHovered] = useState(false);
  const [isTripToBeHighlighted, setIsTripToBeHighlighted] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    if (id && tableName) {
      setIsTripToBeHighlighted(tableName === ETableName.TRIPS && data?.id === id);
    }
  }, [id, table.name]);

  return (
    <>
    <motion.section
        ref={rowRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`${styles.container} ${id == data?.id || isHovered ? styles.checked : ''} ${
          styles[`container-data`]
        } ${styles[checkboxState]} ${isTripToBeHighlighted ? styles['highlight'] : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
      <div className={`${styles.container} ${id == data?.id || isHovered ? styles.checked : ''}`}>
        <RowCheckbox
          height={height}
          checkboxState={checkboxState}
          variant="data"
          data={data}
          table={table}
          geoTab
        />
        <div
          className={styles.columns}
          onClick={() => handleDataModal()}
          onKeyDown={() => handleDataModal()}
          role="button"
          tabIndex={0}
        >
          {headings
            ?.filter(header => header.active)
            .map(header => (
              <Column
                key={uuid()}
                value={data[header.field as keyof UnionDataInterface]}
                field={header.field}
                heading={header.heading}
                tableName={tableName}
                rowData={data}
              />
            ))}
        </div>
        
        {![ETableName.USERS].includes(tableName) && (
          <div className={`${styles['context-control-wrapper']} ${id == data?.id || isHovered ? styles.checked : ''}`}>
            <div className={styles['context-control']}>
              {![ETableName.TRANSACTIONS].includes(tableName) && (
                <Dropdown
                  type="context"
                  isOpen={dropdownIsOpen}
                  handleOpenState={handleDropdownOpenState}
                  values={values}
                  className={styles.dropdown}
                  boundaryElement={dropdownContainerEl}
                  position="bottom-end"
                  offset={[-20, 0]}
                  usePortal
                >
                  <span className={`${styles.button} ${id == data?.id || isHovered ? styles.checked : ''}`}>
                    <More />
                  </span>
                </Dropdown>
              )}
            </div>
          </div>
        )}
      </div>
      </motion.section>
      <AnimatePresence exitBeforeEnter>
        {modal === 'data' && modalId === rowModalId(tableName, data.id) && ViewModal && (
          <ViewModal
            key={tableName.toLowerCase()}
            data={data}
            closeModal={() => dispatch(closeModal())}
          />
        )}
        {responseType && modal === 'api-response' && modalId === `row-modal/${data.id}` && (
          <ApiResponseModal
            key="api-response"
            type={responseType}
            action={responseAction as string}
            message={responseMessage}
          />
        )}
        {modal === 'delete' && modalId === data.id && (
          <ConfirmDeleteModal key="delete" tableName={tableName} data={data ? [data] : []} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Data;
