/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable */
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useProtectedRoute } from './hooks';
import { ProtectedRouteProps } from './types';

const ProtectedRoute: FC<ProtectedRouteProps> = () => {
  const { isValid, app_role, isLoading } = useProtectedRoute();

  if (!isLoading && isValid && !app_role) {
    return <Navigate to="/403" replace />;
  }
  if (!isLoading && isValid) {
    return <Outlet />;
  }
  return null;
};

export default ProtectedRoute;
