/* eslint-disable import/no-cycle */
import { EModule } from '@common/utils/enums';
import { routes } from '@common/utils/route-names';
import { store } from '@store/index';

export const userCanAccessRoute = (route?: string) => {
  const modules = [...store.getState().auth.modules];

  const hasAccess = () => {
    if (route) {
      if (route === routes.support) {
        return true;
      }
      const hashRemovedRoute = route.replace(/^\/+/, '');
      return (
        modules.find(x => EModule[x.module_id] === hashRemovedRoute)?.permission.can_view || false
      );
    }
    return false;
  };
  return route ? hasAccess() : false;
};
