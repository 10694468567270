import { disableVisibleCustomerOption } from '@store/data';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useRef } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

export const usePopperOutsideClick = (handleOpenState: VoidFuncSP<boolean>) => {
  const dispatch = useAppDispatch();
  const menuVisible: boolean | undefined = useAppSelector(state => state.customer.isVisible);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnclickOutside(
    () => {
      handleOpenState(false);
      if (menuVisible) {
        dispatch(disableVisibleCustomerOption());
      }
    },
    { refs: [wrapperRef] }
  );
  return wrapperRef;
};
