import {
  billingStatusList,
  devicePlansList,
  tagTypesList,
  vehicleClassesList
} from '@common/utils/constants/lists';
import { useAppSelector } from '@store/hooks';

export const useGetListsData = () => {
  // const getAgency = (displayName?: string, exact = true) => {
  //   const agency = issuingAgenciesList?.find(agencyI =>
  //     exact ? agencyI.name === displayName : agencyI.name.includes(displayName || '')
  //   );
  //   return agency?.id || '';
  // };
  const tagTypes = useAppSelector(state => state.fleetTabs.tagTypes);
  const getVehicleClass = (displayName?: string) => {
    const vClass = vehicleClassesList?.find(vClassI => vClassI.displayName === displayName);
    return vClass?.classCode || '';
  };
  const getTagType = (displayName?: string, exact = true) => {
    const tagType = tagTypesList?.find(tagTypeI =>
      exact
        ? tagTypeI.displayName === displayName
        : tagTypeI.displayName.includes(displayName || '')
    );
    return tagType?.tagCode || '';
  };
  const getTagTypeID = (tagTypeName?: string) => {
    if (tagTypeName) {
      if (tagTypeName.toLowerCase() !== 'none') {
        const tagTypeId = tagTypes?.find(x => x.tag_type_name === tagTypeName)?.tag_type_id;
        return tagTypeId;
      }
    }
    return null;
  };
  const getDevicePlan = (displayName?: string, exact = true) => {
    const devicePlan = devicePlansList?.find(devicePlanI =>
      exact
        ? devicePlanI.displayName === displayName
        : devicePlanI.displayName.includes(displayName || '')
    );
    return devicePlan?.planCode || '';
  };
  const getBillingStatus = (displayName?: string, exact = true) => {
    const billingStatus = billingStatusList?.find(billingStatusI =>
      exact
        ? billingStatusI.displayName === displayName
        : billingStatusI.displayName.includes(displayName || '')
    );
    return billingStatus?.statusCode || '';
  };

  return {
    getVehicleClass,
    getTagType,
    getTagTypeID,
    getDevicePlan,
    getBillingStatus
  };
};
