/* eslint-disable */
import moment from 'moment-timezone';
import 'dayjs/locale/en';
import dayjs from 'dayjs';

export const formatTimeZone = (
  field: string,
  item: any,
  timeZone: string,
  rowData?: any,
  isExporting?: boolean
) => {
  let val = item;
  if (field && rowData && (rowData?.startTimeZone || rowData?.endTimeZone)) {
    val =
      field === 'startDateTime'
        ? moment
            .utc(item)
            .tz(rowData?.startTimeZone)
            ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`)
        : moment
            .utc(item)
            .tz(rowData?.endTimeZone)
            ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`);
  } else {
    val = moment.utc(item)?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`);
  }
  return val;
};

export const formatDetailTimeZone = (data: any, isExporting?: boolean) => {
  if (data?.startTimeZone || data?.endTimeZone) {
    data.startDateTime = moment
      .utc(data.startDateTime)
      .tz(data?.startTimeZone)
      ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`);
    data.stopDateTime = moment
      .utc(data.stopDateTime)
      .tz(data?.endTimeZone)
      ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : ' MM/DD/yyyy HH:mm'} z`);
  } else {
    data.startDateTime = moment
      .utc(data.startDateTime)
      ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`);
    data.stopDateTime = moment
      .utc(data.stopDateTime)
      ?.format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'} z`);
  }
  return data;
};

export const GPSTollTimezone = (data: any, timezone?: string, isExporting?: boolean) => {
  if (timezone) {
    return `${moment
      .utc(data.exitTime)
      .format(` ${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'}`)} ${timezone}`;
  }
  if (data?.exitTime && data?.endTimeZone) {
    const tz = moment.utc(data?.exitTime).tz(data?.endTimeZone).format('z');
    return `${moment
      .utc(data.exitTime)
      .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'}`)} ${tz}`;
  } else if (data?.src_exit_date_time && data?.timezone) {
    const tz = moment.utc(data?.src_exit_date_time).tz(data?.timezone).format('z');
    return `${moment
      .utc(data.src_exit_date_time)
      .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'}`)} ${tz}`;
  } else {
    if (data?.exitDateTime && data?.endTimeZone) {
      const tz = moment.utc(data?.exitDateTime).tz(data?.endTimeZone).format('z');
      return `${moment
        .utc(data.exitDateTime)
        .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'}`)} ${tz}`;
    } else {
      return moment
        .utc(data.exitTime)
        .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy HH:mm'}`);
    }
  }
};

export const formatTransactionDate = (
  data: any,
  isExporting?: boolean,
  isActualTollTab?: boolean
) => {
  if (isActualTollTab) {
    return `${
      data?.date
        ? `${moment
            .utc(!isExporting ? data?.date : data?.timeZoneConvertedDate)
            .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy hh:mm A'} `)}${
            data.endTimeZone ? moment(data?.date).tz(data.endTimeZone).format('z') : ''
          }`
        : ''
    }`;
  }

  return `${
    data?.date
      ? `${moment
          .utc(data?.date)
          .format(`${!isExporting ? 'M/D/yyyy hh:mm A' : 'MM/DD/yyyy hh:mm A'} `)}${
          data.time_zone ? moment(data?.date).tz(data.time_zone).format('z') : ''
        }`
      : ''
  }`;
};

export const UIDateTimeformatHelper = (inputDate: string | Date | number, timezone?: string) => {
  if (inputDate) {
    if (timezone) {
      return moment.utc(inputDate).tz(timezone).format('M/D/yyyy hh:mm A z');
    }
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(inputDate).tz(browserTimezone).format('M/D/yyyy hh:mm A z');
  }
  return '';
};

export const UIDateTimeformatHelper2 = (
  inputDate: string | Date | number,
  timezone?: string,
  hideTime: boolean = false
) => {
  if (inputDate) {
    if (timezone) {
      return moment
        .utc(inputDate)
        .tz(timezone)
        .format(hideTime ? 'M/D/yyyy' : 'M/D/yyyy hh:mm A z');
    }
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment
      .utc(inputDate)
      .tz(browserTimezone)
      .format(hideTime ? 'M/D/yyyy' : 'M/D/yyyy hh:mm A z');
  }
  return '';
};

export const ExportDateTimeformatHelper = (
  inputDate: string | Date | number,
  timezone?: string
) => {
  if (inputDate) {
    if (timezone) {
      return moment.utc(inputDate).tz(timezone).format('MM/DD/yyyy hh:mm A z');
    }
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(inputDate).tz(browserTimezone).format('MM/DD/yyyy hh:mm A z');
  }
  return '';
};

export const ExportDateObject = (inputDate: string | Date | number) => {
  if (inputDate) {
    return moment.utc(inputDate).toDate();
  }
  return '';
};

export const formatAlertDate = (inputDate?: string, time_zone?: string) => {
  if (time_zone) {
    return `${
      inputDate
        ? `${moment.utc(inputDate).format('M/D/yyyy hh:mm A')} ${moment(inputDate)
            .tz(time_zone)
            .format('z')}`
        : ''
    }`;
  }

  return `${inputDate ? `${moment.utc(inputDate).format('M/D/yyyy hh:mm A')}` : ''}`;
};
