import { FC } from 'react';
import { Profile } from '@assets/icons';
import { SelectInput } from '@common/ui/Form';
import { OptionsByTableProps } from './types';

const Users: FC<OptionsByTableProps> = ({ ...props }) => {
  return (
    <SelectInput
      {...props}
      type="simple"
      label="Role"
      formField="roleCode"
      icon={<Profile />}
      values={['ADMINISTRATOR', 'SUPERVISOR', 'VIEWER']}
      placeholder="Select User Role"
    />
  );
};

export default Users;
