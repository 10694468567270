/* eslint-disable */
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { ChevronDown } from '@assets/icons';
import 'react-datepicker/dist/react-datepicker.css';
import './custom-date-picker.scss';
import moment from 'moment-timezone';
import { CustomDatePickerProps } from './types';
import CustomHeader from './CustomHeader';
import { useCustomDatePicker, useFormikDatePicker } from './hooks';
import styles from './custom-date-input.module.scss';

export const FormikDatePicker = ({
  formField,
  label,
  disabled,
  icon,
  className = '',
  placeholder,
  showTime = true
}: CustomDatePickerProps) => {
  const { startDate, months, years, onChange, field, meta } = useFormikDatePicker(formField);
  const datePickerRef = useRef<ReactDatePicker>(null);
  const [focus, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(!!datePickerRef?.current?.props?.open);
  return (
    <div
      className={`${styles.container} ${
        meta?.touched && meta?.error && !disabled ? styles['container-error'] : ''
      } ${disabled ? styles.disabled : ''} ${className}`}
    >
      <label htmlFor={label}>{label}</label>
      <div className={`${styles['container-display']} ${focus && !disabled ? styles.focused : ''}`}>
        {icon && (
          <button
            type="button"
            className={`${styles['icon-container']} ${isOpen ? styles.open : ''}`}
            onClick={() => datePickerRef.current?.setOpen(true)}
          >
            {icon}
          </button>
        )}
        <DatePicker
          // withPortal
          // portalId="cl-portal"
          autoComplete="off"
          dateFormat={showTime ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy'}
          isClearable
          adjustDateOnChange={false}
          minDate={dayjs().subtract(6, 'months').startOf('month').toDate()}
          popperPlacement="top-start"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 6]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: true,
                altAxis: true,
                boundary: 'clippingParents'
              }
            }
          ]}
          ref={datePickerRef}
          onCalendarOpen={() => setIsOpen(true)}
          onCalendarClose={() => setIsOpen(false)}
          closeOnScroll
          timeFormat="hh:mm a"
          // closeOnScroll
          // showTimeSelect
          showTimeInput={showTime}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <CustomHeader
              date={date}
              months={months}
              years={years}
              changeYear={changeYear}
              changeMonth={changeMonth}
            />
          )}
          name={formField || label}
          selected={startDate}
          onChange={value => {
            onChange(value);
          }}
          showPopperArrow={false}
          disabled={disabled}
          placeholderText={placeholder}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={e => {
            setFocus(false);
            field?.onBlur(e);
          }}
          onInputError={err => console.log(err)}
          clearButtonClassName="custom-datepicker-clear-button"
          className="custom-datepicker-input"
          calendarClassName="custom-datepicker-calendar"
          timeClassName={() => 'custom-datepicker-time'}
        />
        <button
          className={styles.arrow}
          type="button"
          onClick={() => datePickerRef.current?.setOpen(true)}
        >
          <ChevronDown className={isOpen ? `${styles.icon} ${styles['icon-open']}` : styles.icon} />
        </button>
      </div>
      {meta?.touched && meta?.error && !disabled && (
        <span className={styles['error-description']}>
          {typeof meta?.error === 'string' ? meta?.error : 'error in input'}
        </span>
      )}
    </div>
  );
};

export const CustomDatePicker = ({
  initialDate,
  handleDate,
  label = '',
  disabled,
  icon,
  className = '',
  placeholder,
  showTime,
  dateFormat,
  minDate,
  maxDate,
  isEndOfDay,
  dates,
  isStart,
  errorMessage,
  isEnd
}: CustomDatePickerProps) => {
  const { startDate, months, years, onChange } = useCustomDatePicker(initialDate, handleDate);
  const datePickerRef = useRef<ReactDatePicker>(null);
  const [focus, setFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(!!datePickerRef?.current?.props?.open);
  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''} ${className}`}>
      {label && <label htmlFor={label}>{label}</label>}
      <div className={`${styles['container-display']} ${focus && !disabled ? styles.focused : ''}`}>
        {icon && (
          <button
            type="button"
            className={`${styles['icon-container']} ${isOpen ? styles.open : ''}`}
            onClick={() => datePickerRef.current?.setOpen(true)}
          >
            {icon}
          </button>
        )}
        <DatePicker
          // withPortal
          // portalId="cl-portal"
          autoComplete="off"
          dateFormat={dateFormat || 'MM/dd/yyyy hh:mm a'}
          isClearable
          adjustDateOnChange={false}
          popperPlacement="top-start"
          minDate={minDate}
          //@ts-ignore
          maxDate={moment(maxDate).isValid() ? maxDate : new Date()}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 6]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: true,
                altAxis: true
              }
            }
          ]}
          ref={datePickerRef}
          // showTimeSelect
          onCalendarOpen={() => setIsOpen(true)}
          onCalendarClose={() => setIsOpen(false)}
          // closeOnScroll
          showTimeInput={showTime}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <CustomHeader
              date={date}
              months={months}
              years={years}
              changeYear={changeYear}
              changeMonth={changeMonth}
            />
          )}
          name={label}
          selected={startDate}
          onChange={value => {
            onChange(value, isEndOfDay);
          }}
          showPopperArrow={false}
          disabled={disabled}
          placeholderText={placeholder}
          title={placeholder}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          onInputError={err => console.log(err)}
          clearButtonClassName="custom-datepicker-clear-button"
          className="custom-datepicker-input"
          calendarClassName="custom-datepicker-calendar"
          timeClassName={() => 'custom-datepicker-time'}
        />

        <button
          className={styles.arrow}
          type="button"
          onClick={() => datePickerRef.current?.setOpen(true)}
        >
          <ChevronDown className={isOpen ? `${styles.icon} ${styles['icon-open']}` : styles.icon} />
        </button>
      </div>
      {isEnd && dates?.from?.length > 0 && dates?.to?.length === 0 && (
        <span className={styles['date-picker-error']}>* required</span>
      )}
      {isStart && dates?.to?.length > 0 && dates?.from?.length === 0 && (
        <span className={styles['date-picker-error']}>* required</span>
      )}
      {errorMessage && <span className={styles['date-picker-error']}>{errorMessage}</span>}
    </div>
  );
};
