export const File = ({ className }: ClassName) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74997 17.2499C9.74997 17.4488 9.67095 17.6396 9.5303 17.7802C9.38965 17.9209 9.19888 17.9999 8.99997 17.9999C8.80106 17.9999 8.61029 17.9209 8.46964 17.7802C8.32899 17.6396 8.24997 17.4488 8.24997 17.2499V11.5604L6.53097 13.2809C6.39014 13.4217 6.19913 13.5008 5.99997 13.5008C5.80081 13.5008 5.6098 13.4217 5.46897 13.2809C5.32814 13.14 5.24902 12.949 5.24902 12.7499C5.24902 12.5507 5.32814 12.3597 5.46897 12.2189L8.46897 9.21888C8.53864 9.14903 8.6214 9.09362 8.71252 9.05581C8.80364 9.018 8.90132 8.99854 8.99997 8.99854C9.09862 8.99854 9.1963 9.018 9.28742 9.05581C9.37854 9.09362 9.4613 9.14903 9.53097 9.21888L12.531 12.2189C12.6718 12.3597 12.7509 12.5507 12.7509 12.7499C12.7509 12.949 12.6718 13.14 12.531 13.2809C12.3901 13.4217 12.1991 13.5008 12 13.5008C11.8008 13.5008 11.6098 13.4217 11.469 13.2809L9.74997 11.5604V17.2499Z"
        fill="#2A28F3"
      />
      <path
        d="M18 21V6.75L11.25 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H15C15.7956 24 16.5587 23.6839 17.1213 23.1213C17.6839 22.5587 18 21.7956 18 21ZM11.25 4.5C11.25 5.09674 11.4871 5.66903 11.909 6.09099C12.331 6.51295 12.9033 6.75 13.5 6.75H16.5V21C16.5 21.3978 16.342 21.7794 16.0607 22.0607C15.7794 22.342 15.3978 22.5 15 22.5H3C2.60218 22.5 2.22064 22.342 1.93934 22.0607C1.65804 21.7794 1.5 21.3978 1.5 21V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11.25V4.5Z"
        fill="#2A28F3"
      />
    </svg>
  );
};
