/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import styles from './slider-input.module.scss';
import Slider from './Slider';
import { SliderInputProps } from './types';

const SliderInput: FC<SliderInputProps> = ({
  label,
  icon,
  disabled,
  min,
  max,
  userMin,
  userMax,
  handleUserSlider,
  className = '',
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const handleInputFocus = (value: boolean) => setFocused(value);
  const handleUserMin = (value: number) => handleUserSlider('min', value);
  const handleUserMax = (value: number) => handleUserSlider('max', value);

  return (
    <section aria-label="slider-input" className={`${styles.container} ${className}`}>
      <label htmlFor={label}>{label}</label>
      <div className={styles['input-container']} data-slider-input-disabled={disabled}>
        {icon && <span className={styles['icon-container']}>{icon}</span>}
        <div
          aria-label="slider-wrapper"
          className={focused ? `${styles.wrapper} ${styles.focused}` : styles.wrapper}
        >
          <div aria-label="slider-display" className={styles.display}>
            {new Intl.NumberFormat().format(userMin)} - {new Intl.NumberFormat().format(userMax)}
          </div>
          <span className={styles['slider-rail']} />
          <Slider
            min={min}
            max={max}
            step={props.step}
            className={styles.slider}
            handleInputFocus={data => {
              handleInputFocus(data);
              if (props.handleInputFocus) {
                props.handleInputFocus(data);
              }
            }}
            userMin={userMin}
            userMax={userMax}
            handleUserMin={handleUserMin}
            handleUserMax={handleUserMax}
            disabled={disabled}
          />
        </div>
      </div>
    </section>
  );
};

export default SliderInput;
