import { Form } from 'formik';
import { TextInput, SelectInput } from '@common/ui/Form';
import { useAppSelector } from '@store/hooks';
import { EAuthRole } from '@common/utils/enums';
import styles from './create-user.module.scss';

const CreateUser = () => {
  const roleValues = ['ADMINISTRATOR', 'SUPERVISOR', 'VIEWER', 'SUPERADMIN', 'DEFAULTUSER'];

  const userRole = useAppSelector(state => state.auth.app_role);

  const customerId = useAppSelector(state => state.customer.id);
  const isEmptyCustomer = userRole === EAuthRole.SUPER_ADMIN && !customerId;

  let roles;
  if (userRole === EAuthRole.VIEWER || userRole === EAuthRole.DEFAULTUSER) {
    roles = roleValues.filter(
      item => !['ADMINISTRATOR', 'SUPERVISOR', 'SUPERADMIN'].includes(item)
    );
  } else if (userRole === EAuthRole.SUPERVISOR) {
    roles = roleValues.filter(item => !['SUPERADMIN', 'ADMINISTRATOR'].includes(item));
  } else if (userRole === EAuthRole.CUSTOMER_ADMIN) {
    roles = roleValues.filter(item => !['SUPERADMIN'].includes(item));
  } else {
    roles = roleValues;
  }

  // const Values = roleValues.filter

  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="First Name*"
        name="firstName"
        type="text"
        placeholder="Enter first name"
      />
      <TextInput
        className={styles.input}
        label="Last Name*"
        name="lastName"
        type="text"
        placeholder="Enter last name"
      />
      <TextInput
        className={styles.input}
        label="E-mail*"
        name="email"
        type="email"
        placeholder="Enter email address"
        autoComplete={false}
      />
      <TextInput
        className={styles.input}
        label="Password*"
        name="password"
        type="password"
        placeholder="Enter password"
        autoComplete={false}
      />
      <SelectInput
        values={roles}
        label="Role*"
        className={styles.input}
        formField="app_role"
        placeholder="Select user role"
        disabled={isEmptyCustomer}
      />
    </Form>
  );
};

export default CreateUser;
