import { CustomerDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<CustomerDataInterface>[] = [
  {
    heading: 'company name',
    field: 'companyName',
    active: true,
    filterType: 'text',
    placeholder: 'search name'
  },
  {
    heading: 'admin e-mail address',
    field: 'adminEmail',
    active: true,
    filterType: 'text',
    placeholder: 'search email'
  },
  {
    heading: 'address',
    field: 'companyAddress',
    active: true,
    filterType: 'text',
    placeholder: 'search address'
  },
  {
    heading: 'status',
    field: 'statusCode',
    active: true,
    filterType: 'text',
    placeholder: 'search status'
  }
];
