import * as Yup from 'yup';
import { createInitialValues } from './initialValues';

export const createValidationSchema: Yup.SchemaOf<typeof createInitialValues> = Yup.object().shape({
  password: Yup.string()
    .required('password is required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
      'At least 8 Characters including one uppercase, one lowercase, one number and one special character(#?!@$ %^&*-)'
    ),
  repeat_password: Yup.string()
    .required('repeat password here')
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both passwords should be the same')
    })
});
