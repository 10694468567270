import * as Yup from 'yup';

interface IAddress {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export const initialValues: {
  companyName: string;
  federalID: string;
  dbaName: string;
  companyRegAddress: IAddress;
  companyBillingAddress: IAddress;
  companyShippingAddress: IAddress;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  billingName: string;
  billingPhone: string;
  billingEmail: string;
  consent: boolean;
} = {
  companyName: '',
  federalID: '',
  dbaName: '',
  companyRegAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  companyBillingAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  companyShippingAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  },
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  billingName: '',
  billingPhone: '',
  billingEmail: '',
  consent: false
};

export const validationSchema = Yup.object({
  companyName: Yup.string().required('Company name is required'),
  companyRegAddress: Yup.object({
    street1: Yup.string().required('Address line 1 is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zipcode  is required')
  }),
  contactName: Yup.string().required('Name is required'),
  contactPhone: Yup.string().required('Phone is required'),
  contactEmail: Yup.string().email('Enter valid email').required('Email is required')
});
