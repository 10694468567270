import { EAuthRole, ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';

export const useCanShowButtons = (
  filterSource: ETableName | undefined,
  tableName: ETableName,
  pageTitle?: string
) => {
  const auth = useAppSelector(state => state.auth);
  const customerId = useAppSelector(state => state.customer.id);
  const canShowHeaderButtons =
    pageTitle === 'dashboard' ||
    (auth.app_role === EAuthRole.SUPER_ADMIN &&
      !customerId &&
      tableName !== ETableName.CUSTOMERS) ||
    (auth.app_role === EAuthRole.SUPER_ADMIN && tableName === ETableName.TOLLDOCUMENTS) ||
    (!filterSource &&
      ![ETableName.TOLLDOCUMENTS, ETableName.USERS, ETableName.CUSTOMERS].includes(tableName));

  return canShowHeaderButtons;
};
