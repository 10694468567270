/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { EAuthRole } from '@common/utils/enums';
import { getCustomerData, getUserData } from '@store/data';
import { EDbModule } from '@common/utils/enums/modules';

export interface IAuth {
  id: string;
  email: string;
  modules: IAuthModules[];
  app_role: string;
  sessionIsValid: boolean;
  accessToken?: string;
  loading: boolean;
  isMetric?: boolean;
  timeZoneId?: string;
}

export interface IAuthModules {
  role_id: EAuthRole;
  module_id: EDbModule;
  permission: {
    can_edit: boolean;
    can_view: boolean;
  };
  rank: number;
  role_name: string;
}

export const authIState: IAuth = {
  id: '',
  email: '',
  app_role: '',
  modules: [],
  sessionIsValid: false,
  accessToken: '',
  loading: true,
  isMetric: false,
  timeZoneId: ''
};

export const setAuthUser = createAsyncThunk(
  'auth/fetchUserDetails',
  async ([newAuth, userId]: [IAuth, string], thunkAPI) => {
    const modifiedAuth = newAuth;
    modifiedAuth.id = userId;

    if (modifiedAuth.app_role === EAuthRole.SUPER_ADMIN) {
      thunkAPI.dispatch(setAuth(modifiedAuth));
    } else if (modifiedAuth.app_role !== EAuthRole.SUPER_ADMIN && modifiedAuth.email) {
      const { app_role, ...rest } = modifiedAuth;
      const user = await thunkAPI.dispatch(getUserData([userId, rest.accessToken])).unwrap();
      if (user) {
        thunkAPI.dispatch(setAuth({ ...rest, app_role }));
        await thunkAPI
          .dispatch(getCustomerData([user.customerId]))
          .unwrap()
          .catch(e => console.log(e));
      }
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: authIState,
  reducers: {
    setAuth: (state, action: PayloadAction<IAuth>) => {
      const auth = state;
      const { id, app_role, sessionIsValid, accessToken, loading, email, modules } = action.payload;
      auth.sessionIsValid = sessionIsValid || false;
      auth.id = id;
      auth.email = email;
      auth.accessToken = accessToken;
      auth.loading = loading;
      auth.modules = modules;
      auth.app_role = app_role;
    },
    setAuthLoaded: (state, action: PayloadAction<boolean>) => {
      const auth = state;
      auth.loading = action.payload;
    },
    clearAuth: () => {
      return authIState;
    },

    setIsMetric: (state, action: PayloadAction<boolean>) => {
      const auth = state;
      auth.isMetric = action.payload;
    },
    setTimeZoneId: (state, action: PayloadAction<string>) => {
      const auth = state;
      auth.timeZoneId = action.payload;
    },
    logOut: () => {},
    sessionLogOut: state => {
      return { ...state, sessionIsValid: false, accessToken: '' };
    }
  },
  extraReducers(builder) {
    builder.addCase(setAuthUser.fulfilled, (state, action) => action.payload);
  }
});

export const {
  setAuth,
  setAuthLoaded,
  clearAuth,
  logOut,
  sessionLogOut,
  setIsMetric,
  setTimeZoneId
} = authSlice.actions;
export default authSlice.reducer;
