/** eslint-disable @typescript-eslint/no-unsafe-assignment */
import { routes } from '@common/utils/route-names';
import { userCanAccessRoute } from '@common/services/auth/can-access-route';
import {
  GMap,
  Truck,
  Dollar,
  Invoice,
  AdditionalServices,
  Graph,
  Support,
  Settings,
  NoteClipBoard
  // PieChart
} from '@assets/icons';

export const navigationLinks = [
  // {
  //   link: routes.dashboard,
  //   position: 'left',
  //   title: 'dashboard',
  //   icon: Graph,
  //   isPermitted: userCanAccessRoute,
  //   mobileVisible: true
  // },
  {
    link: routes.dashboard,
    position: 'left',
    title: 'dashboard',
    icon: Graph,
    isPermitted: userCanAccessRoute,
    mobileVisible: true
  },
  {
    link: routes.trips,
    position: 'left',
    title: 'trips',
    icon: GMap,
    isPermitted: userCanAccessRoute,
    mobileVisible: true
  },
  {
    link: routes.fleet,
    position: 'left',
    title: 'fleet',
    icon: Truck,
    isPermitted: userCanAccessRoute,
    mobileVisible: true
  },
  {
    link: routes.transactions,
    position: 'left',
    title: 'toll transactions',
    altTitle: 'transactions',
    icon: Dollar,
    isPermitted: userCanAccessRoute,
    mobileVisible: true
  },
  {
    link: routes.toll_documents,
    position: 'left',
    title: 'toll documents',
    altTitle: 'toll document management',
    icon: Invoice,
    isPermitted: userCanAccessRoute,
    mobileVisible: false
  },
  {
    link: routes.additional_services,
    position: 'left',
    title: 'additional services',
    icon: AdditionalServices,
    isPermitted: userCanAccessRoute,
    mobileVisible: false
  },
  {
    link: routes.disputereports,
    position: 'left',
    title: 'dispute reports',
    altTitle: 'dispute reports',
    icon: NoteClipBoard,
    isPermitted: userCanAccessRoute,
    mobileVisible: false
  },
  {
    link: routes.settings,
    position: 'right',
    title: 'Settings',
    icon: Settings,
    isPermitted: userCanAccessRoute,
    mobileVisible: false
  },
  {
    link: routes.support,
    position: 'right',
    title: 'support',
    icon: Support,
    isPermitted: userCanAccessRoute,
    mobileVisible: false
  }
];
