import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { openModal } from '@store/features/modal';
import styles from './display-indexes.module.scss';

const DisplayIndexes = ({ tableName }: { tableName: ETableName }) => {
  const pagination = useAppSelector(state => state.pagination[tableName]);
  const dispatch = useAppDispatch();
  return (
    <div className={styles.container}>
      {false && (
        <p className={styles['display-container']}>
          {pagination.dataLength
            ? // eslint-disable-next-line max-len
              `Showing ${pagination.firstRowIndex}-${pagination.lastRowIndex} of ${pagination.dataLength}`
            : 'No results'}
        </p>
      )}
      {![ETableName.USERS].includes(tableName) && (
        <div className={styles['columns-control']}>
          <button onClick={() => dispatch(openModal(['table-display', tableName]))} type="button">
            Show/Hide Columns
          </button>
        </div>
      )}
    </div>
  );
};

export default DisplayIndexes;
