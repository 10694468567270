/* eslint-disable */
import { EyeHide, EyeShow } from '@assets/icons';
import { formatNumber } from '@common/services/format';
import { DATE_KEYS_ARRAY } from '@common/utils/constants';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { FC, useId, useMemo, useState } from 'react';
import styles from './text-input.module.scss';
import { TextInputProps } from './types';
import { UIDateTimeformatHelper } from '@common/services/format/formatTimeZone';

const TextInput: FC<TextInputProps> = ({
  label,
  icon,
  className = '',
  name,
  onSetValue,
  id,
  autoComplete = true,
  enableTooltip = true,
  manualTouchEnable,
  ...props
}) => {
  const [{ value, ...field }, meta] = useField({ name, type: props.type || 'text' });
  const [showPassword, setShowPassword] = useState(false);
  const passwordType = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword]);
  const fieldId = useId();
  const [tooltipFieldValue, settooltipFieldValue] = useState('');

  const getFieldTooltip = () => {
    if (enableTooltip) {
      if (tooltipFieldValue) {
        return tooltipFieldValue;
      }
      if (value && props.type !== 'password') {
        return value;
      }
      return props?.placeholder;
    }
    return '';
  };

  if (manualTouchEnable) {
    meta.touched = true;
  }

  return (
    <section
      aria-label="text-input-container"
      className={
        meta.touched && meta.error
          ? `${styles.container} ${className} ${styles['container-error']}`
          : `${styles.container} ${className}`
      }
    >
      {label && <label htmlFor={`${id || name}${fieldId}`}>{label}</label>}
      <div
        className={styles['input-container']}
        data-text-input-disabled={props.disabled}
        title={getFieldTooltip()}
      >
        {icon && <span className={styles['icon-container']}>{icon}</span>}
        {!props.disabled ? (
          !onSetValue ? (
            <input
              id={`${id || name}${fieldId}`}
              {...field}
              value={typeof value === 'number' ? value : value || ''}
              {...props}
              type={props.type === 'password' ? passwordType : props.type}
              autoComplete={autoComplete ? 'on' : 'new-password'}
            />
          ) : (
            <input
              id={`${id || name}${fieldId}`}
              onChange={onSetValue}
              defaultValue={value}
              {...props}
              onBlur={e => settooltipFieldValue(e.target.value)}
              autoComplete={autoComplete ? 'on' : 'new-password'}
            ></input>
          )
        ) : (
          <div
            className={styles['disabled-input']}
            title={
              DATE_KEYS_ARRAY.includes(name) &&
              field.name !== 'startDateTime' &&
              field.name !== 'stopDateTime' &&
              field.name !== 'date' &&
              field.name !== 'uploadDate' &&
              field.name !== 'billingStatusUpdatedAt' &&
              field.name !== 'createdAt'
                ? dayjs(value as string).format('MM/DD/YYYY, hh:mm A')
                : field.name === 'billingStatusUpdatedAt' ||
                  field.name === 'createdAt' ||
                  field.name === 'uploadDate'
                ? UIDateTimeformatHelper(value)
                : value
            }
          >
            {DATE_KEYS_ARRAY.includes(name) &&
            field.name !== 'startDateTime' &&
            field.name !== 'stopDateTime' &&
            field.name !== 'date' &&
            field.name !== 'uploadDate' &&
            field.name !== 'billingStatusUpdatedAt' &&
            field.name !== 'createdAt'
              ? dayjs(value as string).format('MM/DD/YYYY, hh:mm A')
              : field.name === 'billingStatusUpdatedAt' ||
                field.name === 'createdAt' ||
                field.name === 'uploadDate'
              ? UIDateTimeformatHelper(value)
              : value}
          </div>
        )}
        {props.type === 'password' && (
          <button
            type="button"
            className={styles['password-icon']}
            onClick={() => setShowPassword(prev => !prev)}
          >
            {!showPassword ? <EyeShow /> : <EyeHide />}
          </button>
        )}
      </div>
      {meta.touched && meta.error && (
        <span aria-label="text-input-error" className={styles['error-description']}>
          {meta.error}
        </span>
      )}
    </section>
  );
};

export default TextInput;
