/* eslint-disable @typescript-eslint/no-floating-promises */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ICreateUser, useCreateUserMutation } from '@api/services/users';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { userData } from '@store/data';

export const useHandleSubmit = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);
  const appRole = useAppSelector(state => state.auth.app_role);
  const [createUser] = useCreateUserMutation();
  const { handleError, handleSuccess } = useHandleApiResponse();
  const toastify = usePromiseToastNotify();

  const handleSubmit = (
    values: Omit<ICreateUser, 'customerId'>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    toastify(
      createUser({ ...values, customerId })
        .unwrap()
        .then(() => {
          handleSuccess(
            'User added successfully',
            'A message has been sent to the user by email with login instructions.'
          );
        })
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'create', 'user');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(openModal(['api-response', 'add-new']));
          dispatch(userData({ customerId, appRole }));
        }),
      'create',
      'user'
    );
  };

  return { handleSubmit };
};
