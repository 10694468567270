import { AuthBody } from '../../../layout/components';
import FormComp from '../Form/Form';
import RefreshGeotab from '../RefreshGeotab/RefreshGeotab';
import styles from './card.module.scss';

const Card = () => {
  const isGeotab = window.location !== window.parent.location;
  return (
    <>
      {isGeotab ? (
        <RefreshGeotab />
      ) : (
        <AuthBody className={styles.body} title="Log in">
          <section className={styles.container}>
            <FormComp />
          </section>
        </AuthBody>
      )}
    </>
  );
};

export default Card;
