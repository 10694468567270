import TripSplitPaneView from '@pages/tables/trips/TripSplitPaneView';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Table from '../table';
import styles from './content.module.scss';
import Empty from './empty';
import Loading from './loading';
import { ContentProps } from './types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Content = ({ ViewModal }: ContentProps) => {
  const table = useAppSelector(state => state.table);
  const search = useAppSelector(state => state.search[table.name as ETableName]);
  const filters = useAppSelector(state => state.filters[table.name as ETableName]);

  return (
    <div className={table.name === ETableName.TRIPS ? styles['trip-container'] : styles.container}>
      {(table.isLoading || (table.isFetching && (!table.data || table.data.length === 0))) && (
        <Loading />
      )}
      {!table.isLoading &&
        !search?.active &&
        !filters?.active &&
        (!table.data || table.data.length === 0) && <Empty tableType={table.name as ETableName} />}
      {!table.isLoading &&
        table.data &&
        table.name === 'trips' &&
        (search?.active || filters?.active || table.data.length > 0) && (
          <TripSplitPaneView
            pageData={_.uniqBy(table.data, 'id') || []}
            loading={table.isFetching}
          />
        )}
      {!table.isLoading &&
        table.data &&
        table.name !== 'trips' &&
        (search?.active || filters?.active || table.data.length > 0) && (
          <Table
            data={_.uniqBy(table.data, 'id') || []}
            loading={table.isFetching}
            tableName={table.name as ETableName}
            ViewModal={ViewModal}
          />
        )}
    </div>
  );
};

export default Content;
