import { Approve, Plus } from '@assets/icons';
import Button from '@common/ui/Button';
import { clearSearch } from '@store/features/search';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { closeModal, openModal } from '@store/features/modal';
import ApiResponse from '@components/modals/ApiResponse';
import styles from './buttons.module.scss';
import { ButtonsProps } from './types';

const Buttons = ({
  icon = <Plus />,
  label = 'Add New',
  Modal,
  hide,
  crossFilterActive,
  tableName
}: ButtonsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    activeModal: modal,
    id: modalId,
    responseType,
    responseAction,
    responseMessage
  } = useAppSelector(state => state.modal);
  return (
    <>
      <div className={styles.container}>
        {!hide && !crossFilterActive && (
          <Button
            label={label}
            icon={icon}
            appearance="button"
            variant="g-secondary"
            size="s"
            onClick={() => {
              if (Modal) dispatch(openModal(['add-new', tableName]));
            }}
          />
        )}
        {crossFilterActive && (
          <Button
            label="Done"
            icon={<Approve />}
            appearance="button"
            variant="g-secondary"
            size="s"
            onClick={() => {
              dispatch(clearSearch(tableName));
              navigate(location.pathname);
            }}
          />
        )}
      </div>
      {/* //? modals: add new and api response */}
      <AnimatePresence exitBeforeEnter>
        {modal === 'add-new' && modalId === _.snakeCase(tableName) && Modal && (
          <Modal closeModal={() => dispatch(closeModal())} />
        )}
        {responseType && modal === 'api-response' && modalId === 'add-new' && (
          <ApiResponse
            key="api-response"
            type={responseType}
            action={responseAction as string}
            message={responseMessage}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Buttons;
