/* eslint-disable */
import Cookies from 'js-cookie';
import { store } from '../../store';
import { EAuthRole } from '@common/utils/enums';
import { cookieCustomer } from '@common/services/auth';

export function setHeaders() {
  const token = store.getState().auth.accessToken;
  const userId = store.getState().auth.id;
  const userRole = store.getState().auth.app_role;
  const customerId = store.getState().customer.id;
  let head: any = {
    'Content-Type': 'application/json',
    accept: '*/*',
    'X-Content-Type-Options': 'nosniff'
  };
  if (token) {
    head.authorization = token;
  }
  if (userRole === EAuthRole.SUPER_ADMIN) {
    if (userId) {
      head.userId = userId;
    }
    if (customerId) {
      head.customerId = customerId;
    } else if (Cookies.get(cookieCustomer)) {
      const CustomerData = JSON.parse(Cookies.get(cookieCustomer) as string);
      head.customerId = CustomerData.id;
    }
  }
  return head;
}
