import { FC, useState } from 'react';
import { ChevronDown } from '@assets/icons';
import Dropdown from '@common/ui/Dropdown';
import styles from './show-by.module.scss';
import { ShowByProps } from './types';

const ShowBy: FC<ShowByProps> = ({
  disabled,
  selection,
  handleSelectionChange,
  values,
  label,
  motionVariants
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => setIsOpen(value);
  const selectBoxStyles =
    isOpen && !disabled
      ? `${styles['display-container-box']} ${styles['display-container-box-active']}`
      : styles['display-container-box'];

  return (
    <section aria-label="show-by" className={`${styles.container}`}>
      <span className={styles.label}>{label}</span>
      <div data-disabled={disabled} className={`${styles['display-container']}`}>
        <Dropdown
          isOpen={isOpen}
          position="top"
          className={styles.dropdown}
          selection={selection.toString()}
          type="select"
          handleSelectionState={handleSelectionChange}
          values={values}
          handleOpenState={handleDropdownOpenState}
          motionVariants={motionVariants}
          offset={[0, 4]}
          disabled={disabled}
        >
          <span aria-label="show-by-display" className={selectBoxStyles}>
            <span className={styles.value}>{selection}</span>
            <span className={styles.icon}>
              <ChevronDown
                className={isOpen ? `${styles.arrow} ${styles['arrow-open']}` : styles.icon}
              />
            </span>
          </span>
        </Dropdown>
      </div>
    </section>
  );
};

export default ShowBy;
