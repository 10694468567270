/*eslint-disable */
import { useState } from 'react';
import Modal from '@common/ui/Modal';
import { TripDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { AnimatePresence } from 'framer-motion';
// import { formatDistance } from '@common/services/format';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { CrossFilterButtons, FormSubmitButton } from '../../../buttons';
import Map from './TripsMap/TripsMap';
import { UpdateTrip, UpdateTripFormik } from '../../../forms/Update';
import { MotionDiv } from '../shared';
import { useHandleActiveTitle } from '../shared/hooks/useHandleActiveTitle';
import TollsList from './TollsList/TollsList';
import styles from './trip.module.scss';
import { formatDetailTimeZone } from '@common/services/format/formatTimeZone';
import { secondsToHms } from '@common/services/format/time';
import Loading from '@pages/tables/layouts/geotab-addin/components/content/loading';

const Trip = ({
  data,
  customCloseFunc,
  hasFooter,
  loading
}: {
  data: TripDataInterface;
  customCloseFunc?: any;
  hasFooter?: boolean;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <Modal
        customCloseFunc={() => {}}
        activeTitle={''}
        navigation={['details', 'GPS Calculated Tolls', 'map']}
        customPrimaryButton={<FormSubmitButton />}
        customExtraButtons={
          <CrossFilterButtons tableName={ETableName.TRIPS} entryData={!loading ? [data] : []} />
        }
        cancelButton={false}
        className={styles.modal}
        handleActiveTitle={() => {}}
        helpLocation="trip_"
        hasFooter={hasFooter}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <div className={styles.loader}>
            <Loading />
          </div>
        </AnimatePresence>
      </Modal>
    );
  }
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleActiveTitle('details');
  let modify_data = { ...data };
  const isMetric = useSelector((state: any) => state.auth.isMetric);
  if (modify_data?.distance && !isMetric) {
    // modify_data.distance = Math.ceil(formatDistance(data.distance, 'kilometres'));
    modify_data.distance = data.distanceInMiles;
  } else {
    modify_data.distance = data.distance;
  }
  modify_data = formatDetailTimeZone(modify_data);
  //@ts-ignore
  modify_data.timeSpent = secondsToHms(modify_data.timeSpent);
  //@ts-ignore
  modify_data.moneySpent = modify_data.moneySpent?.toFixed(2);
  modify_data.licensePlate = modify_data.licensePlate?.toUpperCase();

  const timezone = modify_data.startDateTime.split(' ')[3];
  return (
    <UpdateTripFormik data={modify_data}>
      <Modal
        customCloseFunc={customCloseFunc}
        activeTitle={activeTitle}
        navigation={['details', 'GPS Calculated Tolls', 'map']}
        customPrimaryButton={<FormSubmitButton />}
        customExtraButtons={<CrossFilterButtons tableName={ETableName.TRIPS} entryData={[data]} />}
        cancelButton={false}
        className={styles.modal}
        handleActiveTitle={handleActiveTitle}
        helpLocation="trip_"
        hasFooter={hasFooter}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
            {activeTitle === 'details' && <UpdateTrip />}
            {activeTitle === 'GPS Calculated Tolls' && (
              <TollsList tripId={data.id} timezone={timezone} />
            )}
            {activeTitle === 'map' && <Map data={data} timezone={timezone} />}
          </MotionDiv>
        </AnimatePresence>
      </Modal>
    </UpdateTripFormik>
  );
};

export default Trip;
