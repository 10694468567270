import { CustomerDataInterface } from '@common/types/data';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useUpdateCustomerMutation } from '@api/services/customers';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { closeModal } from '@store/features/modal';
import { useAppDispatch } from '@store/hooks';

export const useHandleSubmit = () => {
  const [updateCustomer] = useUpdateCustomerMutation();
  const toastify = usePromiseToastNotify();
  const dispatch = useAppDispatch();
  const { handleError } = useHandleApiResponse();

  const handleSubmit = (
    values: CustomerDataInterface & { logo?: File | null },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const geotabIntegration = values.geotabIntegration || false;
    const formData = new FormData();
    formData.set('file', values.logo as File);
    toastify(
      updateCustomer([{ ...values, geotabIntegration }, values.logo ? formData : null])
        .unwrap()
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'update', 'customer');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(closeModal());
        }),
      'update',
      'customer'
    );
  };

  return { handleSubmit };
};
