/* eslint-disable import/no-cycle */
import { CustomerDataInterface } from '@common/types/data';
import { setCustomer } from '@store/data';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { EAuthRole, ETableName } from '@common/utils/enums';
import { useClearStore } from './useClearStore';

export const useSelectCustomer = () => {
  const dispatch = useAppDispatch();
  const { clearFeatures } = useClearStore();
  const appRole = useAppSelector(state => state.auth.app_role);

  const selectCustomer = (customer: CustomerDataInterface) => {
    clearFeatures(ETableName.CUSTOMERS);
    const clonedCustData = { ...customer };
    clonedCustData.isSuperAdmin = appRole === EAuthRole.SUPER_ADMIN;

    dispatch(setCustomer(clonedCustData));
  };

  return selectCustomer;
};
