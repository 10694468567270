import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { ChevronDown, DoubleArrows } from '@assets/icons';
import { usePageActions } from './hooks';
import styles from './pagination.module.scss';
import { PaginationProps } from './types';

const Pagination: FC<PaginationProps> = ({ page, handlePageState, totalPages, pagesArray }) => {
  const { prevPage, nextPage, handleChange, start, end } = usePageActions(
    handlePageState,
    pagesArray,
    page,
    totalPages
  );

  const buttonBaseProps = (currentValue: number) => ({
    onClick: ({ currentTarget }: { currentTarget: EventTarget & HTMLButtonElement }) =>
      handleChange(Number(currentTarget.value)),
    value: currentValue,
    className: page === currentValue ? `${styles.active}` : ''
  });

  return (
    <div className={styles.container}>
      {/* first */}

      <button
        aria-label="pagination-prev-button"
        onClick={() => {
          if (page > 1) handleChange(1);
        }}
        disabled={page === 1}
        type="button"
        className={`${styles.first}`}
      >
        <span className={`${styles.arrow} ${page === 1 ? styles.disabled : ''} ${styles.left}`}>
          <DoubleArrows />
        </span>
        <span className={styles.text}>First</span>
      </button>
      <div className={styles.box}>
        {/* left arrow */}
        <button
          aria-label="pagination-prev-button"
          onClick={() => {
            if (page > 1) prevPage();
          }}
          type="button"
          disabled={page === 1}
          className={`${styles.arrow} ${page === 1 ? styles.disabled : ''} ${styles.left}`}
        >
          <ChevronDown />
        </button>
        {totalPages > 5 ? (
          <ul className={styles.buttons}>
            {/* first page button */}
            <li>
              <button
                aria-label={`pagination-button-${pagesArray[0]}`}
                type="button"
                {...buttonBaseProps(pagesArray[0])}
              >
                {pagesArray[0]}
              </button>
            </li>
            {/* close to first page buttons */}
            {start &&
              pagesArray.slice(1, 3).map(item => (
                <li key={uuid()}>
                  <button
                    aria-label={`pagination-button-${item}`}
                    type="button"
                    {...buttonBaseProps(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            {/* ellipses */}
            {!start && <span className={styles.ellipses}>...</span>}
            {/* active page, but not in the starting pages or ending pages, button */}
            {!end && !start && (
              <li>
                <button
                  aria-label={`pagination-button-${page}`}
                  type="button"
                  {...buttonBaseProps(page)}
                >
                  {page}
                </button>
              </li>
            )}
            {/* ellipses */}
            {!end && <span className={styles.ellipses}>...</span>}
            {/* close to last page buttons */}
            {end &&
              pagesArray.slice(-3, -1).map(item => (
                <li key={uuid()}>
                  <button
                    aria-label={`pagination-button-${item}`}
                    type="button"
                    key={uuid()}
                    {...buttonBaseProps(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            {/* last page button */}
            <li>
              <button
                aria-label={`pagination-button-${totalPages}`}
                type="button"
                {...buttonBaseProps(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          </ul>
        ) : (
          <ul className={styles.buttons}>
            {pagesArray &&
              pagesArray.map(item => (
                <li key={uuid()}>
                  <button
                    aria-label={`pagination-button-${item}`}
                    type="button"
                    {...buttonBaseProps(item)}
                  >
                    {item}
                  </button>
                </li>
              ))}
          </ul>
        )}
        {/* right arrow */}
        <button
          aria-label="pagination-next-button"
          onClick={() => {
            if (page < totalPages) nextPage();
          }}
          disabled={page >= totalPages}
          type="button"
          className={`${styles.arrow}  ${page >= totalPages ? styles.disabled : ''} ${
            styles.right
          }`}
        >
          <ChevronDown />
        </button>
      </div>
      {/* last */}

      <button
        aria-label="pagination-prev-button"
        onClick={() => {
          if (page < totalPages) handleChange(totalPages);
        }}
        disabled={page >= totalPages}
        type="button"
        className={`${styles.last}`}
      >
        <span className={styles.text}>Last</span>
        <span
          className={`${styles.arrow} ${page >= totalPages ? styles.disabled : ''} ${styles.right}`}
        >
          <DoubleArrows />
        </span>
      </button>
    </div>
  );
};

export default Pagination;
