import Cookies from 'js-cookie';
import { GEO_TAB_GROUPS, SESSION_USER } from '@common/utils/constants';

export const isGeotabCookie = `commerce-logic-is-geotab`;
export const emailCookie = `commerce-logic-${SESSION_USER}-email`;
export const accessTokenCookie = `commerce-logic-${SESSION_USER}-access-token`;
export const idTokenCookie = `commerce-logic-${SESSION_USER}-id-token`;
export const refreshTokenCookie = `commerce-logic-${SESSION_USER}-refresh-token`;
export const credentials = `commerce-logic-${SESSION_USER}-Geotab`;
export const geotabGroups = `commerce-logic-${GEO_TAB_GROUPS}`;
export const cookieFilters = `commerce-logic-${SESSION_USER}-filters`;
export const cookieSearch = `commerce-logic-${SESSION_USER}-search`;
export const cookieSort = `commerce-logic-${SESSION_USER}-sort`;
export const cookiePagination = `commerce-logic-${SESSION_USER}-pagination`;
export const cookieCustomer = `commerce-logic-${SESSION_USER}-customer`;
export const cookieTripAllotment = `commerce-logic-${SESSION_USER}-trip-allotment`;
export const cookieTripTabAllotment = `commerce-logic-${SESSION_USER}-trip-tab-allotment`;
export const cookieGeoTabPath = `commerce-logic-${SESSION_USER}-geotab-path`;
export const cookieGeoTabOrderNowPath = `commerce-logic-${SESSION_USER}-geotab-ordernow-path`;

export const cookiesSessionUser = {
  set: (email: string, accessToken: string, idToken: string, refreshToken: string) => {
    Cookies.set(emailCookie, email, { sameSite: 'None', secure: true });
    Cookies.set(accessTokenCookie, accessToken, { sameSite: 'None', secure: true });
    Cookies.set(idTokenCookie, idToken, { sameSite: 'None', secure: true });
    Cookies.set(refreshTokenCookie, refreshToken, { sameSite: 'None', secure: true });
  },
  get: () => ({
    email: Cookies.get(emailCookie) || '',
    accessToken: Cookies.get(accessTokenCookie) || '',
    idToken: Cookies.get(idTokenCookie) || '',
    refreshToken: Cookies.get(refreshTokenCookie) || ''
  }),
  clear: () => {
    Cookies.remove(emailCookie, { sameSite: 'None', secure: true });
    Cookies.remove(accessTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(idTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(refreshTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(credentials, { sameSite: 'None', secure: true });
    Cookies.remove(cookieFilters, { sameSite: 'None', secure: true });
    Cookies.remove(cookieSearch, { sameSite: 'None', secure: true });
    Cookies.remove(cookieSort, { sameSite: 'None', secure: true });
    Cookies.remove(cookiePagination, { sameSite: 'None', secure: true });
    Cookies.remove(cookieCustomer, { sameSite: 'None', secure: true });
    Cookies.remove(cookieTripAllotment, { sameSite: 'None', secure: true });
    Cookies.remove(cookieTripTabAllotment, { sameSite: 'None', secure: true });
    Cookies.remove(cookieGeoTabPath, { sameSite: 'None', secure: true });
    Cookies.remove(cookieGeoTabOrderNowPath, { sameSite: 'None', secure: true });
  },
  clearSession: () => {
    Cookies.remove(emailCookie, { sameSite: 'None', secure: true });
    Cookies.remove(accessTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(idTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(refreshTokenCookie, { sameSite: 'None', secure: true });
    Cookies.remove(credentials, { sameSite: 'None', secure: true });
  }
};

export const cookiesIsGeotab = {
  set: (isGeotab: boolean) =>
    isGeotab
      ? Cookies.set(isGeotabCookie, `${isGeotab ? 'commerce-logic-is-geotab' : ''}`, {
          sameSite: 'None',
          secure: true
        })
      : Cookies.remove(isGeotabCookie, { sameSite: 'None', secure: true }),
  get: () => !!Cookies.get(isGeotabCookie),
  clear: () => Cookies.remove(isGeotabCookie, { sameSite: 'None', secure: true })
};
