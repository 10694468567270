/* eslint-disable @typescript-eslint/no-floating-promises */
import { FileInput, TextInput } from '@common/ui/Form';
import Toggle from '@common/ui/Toggle';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { CustomerDataInterface } from '@common/types/data';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';
import styles from './update-customer.module.scss';

const UpdateCustomer = () => {
  const formik = useFormikContext<CustomerDataInterface>();
  const hasEditAccess = !useEditPermission(routes.customers);
  const [geotabToggle, setGeotabToggle] = useState<boolean>(false);
  const handleGeotabToggle = (value: boolean) => {
    setGeotabToggle(value);
    formik.setFieldValue('geotabIntegration', value);
  };
  useEffect(() => {
    setGeotabToggle(formik.values.geotabIntegration);
  }, [formik.values.geotabIntegration]);

  const [statusToggle, setStatusToggle] = useState<boolean>(false);
  const handleStatusToggle = (value: boolean) => {
    setStatusToggle(value);
    formik.setFieldValue('statusCode', value ? 'ACTIVE' : 'INACTIVE');
  };
  useEffect(() => {
    setStatusToggle(formik.values.statusCode === 'ACTIVE');
  }, [formik.values.statusCode]);

  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="Company Name*"
        name="companyName"
        type="text"
        placeholder="Enter company name"
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Admin E-mail*"
        name="adminEmail"
        type="email"
        placeholder="Enter admin E-mail"
        disabled={hasEditAccess}
      />
      <TextInput
        className={styles.input}
        label="Company Address*"
        name="companyAddress"
        type="text"
        placeholder="Enter company address"
        disabled={hasEditAccess}
      />
      <FileInput
        className={styles.input}
        label="Company Logo"
        field="logo"
        format={['.jpeg', '.jpg', '.png', '.svg', '.webp']}
        disabled={hasEditAccess}
      />
      {formik.values.statusCode !== 'INVITE_SENT' && (
        <div className={`${styles['status-toggle']} ${styles.input}`}>
          <span className={styles.label}>Active</span>
          <Toggle
            className={styles.toggle}
            state={statusToggle}
            handleToggle={handleStatusToggle}
            disabled={hasEditAccess}
          />
        </div>
      )}
      <TextInput
        className={styles.input}
        label="GeoTab Database"
        name="geotabDatabase"
        type="text"
        placeholder="Enter database name"
        disabled={hasEditAccess}
      />
      <div className={`${styles['geotab-toggle']} ${styles.input}`}>
        <span className={styles.label}>GeoTab Integration</span>
        <Toggle
          disabled={hasEditAccess}
          className={styles.toggle}
          state={geotabToggle}
          handleToggle={handleGeotabToggle}
        />
      </div>
      <TextInput
        className={styles.input}
        label="GeoTab Service User"
        name="geotabServiceUser"
        type="text"
        placeholder="Enter service user"
        disabled={hasEditAccess}
      />
      <TextInput
        type="password"
        className={styles.input}
        label="GeoTab Service Password"
        name="geotabServicePassword"
        placeholder="Enter service password"
        disabled={hasEditAccess}
      />
    </Form>
  );
};

export default UpdateCustomer;
