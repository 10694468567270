/* eslint-disable */
import { FC } from 'react';
import Details from '../Details/Details';
import styles from './trip-type.module.scss';
import { TripTypeProps } from './types';

const TripType: FC<TripTypeProps> = ({
  tripType,
  handleTripType,
  headerDetails,
  altTrips
}) => {
  return (
    <section className={styles.container}>
      <button
        type="button"
        onClick={() => handleTripType('actual')}
        className={tripType === 'actual' ? `${styles.actual} ${styles.active}` : styles.actual}
      >
        Actual trip
      </button>
      <button
        type="button"
        onClick={() => handleTripType('alt')}
        className={
          tripType === 'alt'
            ? `${styles.alternative} ${styles.active}`
            : !(altTrips !== undefined && altTrips[0]?.logRecords?.length > 0)
            ? `${styles.noData} ${styles.active}`
            : styles.alternative
        }
        disabled={!(altTrips !== undefined && altTrips[0]?.logRecords?.length > 0)}
      >
        Alternative trip
      </button>
      <Details
        className={styles.details}
        tripType={tripType}
        duration={headerDetails.duration}
        cost={headerDetails.cost}
        distance={headerDetails.distance}
        tollCostPerMinute={headerDetails.tollCostPerMinute}
        tollCostPerMile={headerDetails.tollCostperMile}
      />
    </section>
  );
};

export default TripType;
