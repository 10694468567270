import {
  useGetAlternativeTripsByTripIdQuery,
  useGetOriginalTripsByTripIdQuery
} from '@api/services/trips';
import { formatDistance } from '@common/services/format';
import { TripDataInterface } from '@common/types/data';
import { useAppSelector } from '@store/hooks';
import { useState } from 'react';

export const useTripsMap = (data: TripDataInterface) => {
  const { data: originalTrip } = useGetOriginalTripsByTripIdQuery(data.id, {
    refetchOnReconnect: true
  });
  const { data: altTrips } = useGetAlternativeTripsByTripIdQuery(data.id, {
    refetchOnReconnect: true
  });

  const [tripType, setTripType] = useState<'actual' | 'alt'>('actual');
  const handleTripType = (value: 'actual' | 'alt') => {
    setTripType(value);
  };

  const isMetric = useAppSelector(state => state.auth.isMetric);
  const actualTripDistance = isMetric ? data.distance : data.distanceInMiles;
  let altTripDistance = altTrips && altTrips[0] && altTrips[0].distance;
  if (altTripDistance && isMetric) {
    altTripDistance = formatDistance(altTripDistance, 'miles');
  }

  const headerDetails = {
    duration:
      tripType === 'actual'
        ? data.timeSpent
        : (altTrips && altTrips[0] && altTrips[0].duration) || undefined,
    cost: tripType === 'actual' ? data.moneySpent : undefined,
    distance: tripType === 'actual' ? actualTripDistance : altTripDistance || undefined,
    tollCostPerMinute: tripType === 'actual' ? data.tollCostPerMinute : undefined,
    tollCostPerMile: tripType === 'actual' ? data.tollCostPerMile : undefined
  };

  const altTripDetails = {
    duration: altTrips && altTrips[0] ? altTrips[0].duration : 0,
    cost: 0,
    distance: altTripDistance || 0
  };

  const actualTripDetails = {
    duration: data.timeSpent,
    cost: data.moneySpent,
    distance: actualTripDistance || 0
  };

  return {
    altTrips,
    originalTrip,
    tripType,
    handleTripType,
    headerDetails,
    altTripDetails,
    actualTripDetails
  };
};
