/* eslint-disable @typescript-eslint/no-floating-promises */
import { VehicleAxles, VehicleClass, TollID, Truck, TollAgency, VehicleTires } from '@assets/icons';
// import { useGetTagTypesQuery, useGetVehicleClassesQuery } from '@api/services/lists';
import { FC } from 'react';
import { SelectInput } from '@common/ui/Form';
// eslint-disable-next-line max-len, prettier/prettier
import {
  vehicleClassesList,
  devicePlansList,
  billingStatusList
} from '@common/utils/constants/lists';
import Checkbox from '@common/ui/Checkbox';
import { useFormikContext } from 'formik';
import { useAppSelector } from '@store/hooks';
import { OptionsByTableProps } from './types';
import StartAndEndDate from '../StartAndEndDate/StartAndEndDate';
import { useGetListsData } from '../../../forms/Update/Vehicle/hooks/useGetListsData';
import styles from '../options.module.scss';

const Fleet: FC<OptionsByTableProps> = ({ ...props }) => {
  // const { data: tagTypes } = useGetTagTypesQuery();
  // const { data: vehicleClasses } = useGetVehicleClassesQuery();
  const orderNow = useAppSelector(state => state.customer.geotabOrderNow);
  const { tagTypes } = useGetListsData();
  const formik = useFormikContext<AnyObject>();
  return (
    <>
      <div>
        <Checkbox
          label="Show Trailers"
          className={styles.filterCheckbox}
          state={formik.values && formik.values?.trailers ? 'checked' : 'unchecked'}
          onClick={() => {
            formik.setFieldValue('trailers', !formik.values?.trailers || false);
          }}
        />
        <Checkbox
          label="Show Deleted"
          className={styles.filterCheckbox}
          state={formik.values && formik.values?.deleted ? 'checked' : 'unchecked'}
          onClick={() => {
            formik.setFieldValue('deleted', !formik.values?.deleted || false);
            if (!formik.values?.deleted) {
              formik.setFieldValue('deleted', true);
            }
            if (formik.values?.deleted && formik.values?.billingStatus === 'Deleted') {
              formik.setFieldValue('billingStatus', '');
            }
          }}
        />
      </div>
      <Checkbox
        label="Show Unsubscribed Permanently"
        className={styles.filterCheckbox}
        state={formik.values && formik.values?.blocked ? 'checked' : 'unchecked'}
        onClick={() => {
          formik.setFieldValue('blocked', !formik.values?.blocked || false);
          if (!formik.values?.blocked) {
            formik.setFieldValue('blocked', true);
          }
          if (
            formik.values?.blocked &&
            formik.values?.billingStatus === 'Unsubscribed Permanently'
          ) {
            formik.setFieldValue('billingStatus', '');
          }
        }}
      />
      <SelectInput
        {...props}
        type="simple"
        label="Vehicle axles"
        formField="vehicleAxles"
        icon={<VehicleAxles />}
        values={[2, 3, 4, 5]}
        placeholder="Select Vehicle Axles"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Vehicle Class"
        formField="vehicleClass"
        icon={<VehicleClass />}
        values={vehicleClassesList.map(vehicleClass => vehicleClass.displayName)}
        placeholder="Select Vehicle Class"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Tag Type"
        formField="tagType"
        includeSearch
        icon={<TollID />}
        values={tagTypes.filter(x => x.toLowerCase() !== 'none')}
        placeholder="Select Tag Type"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Device Plan"
        formField="devicePlan"
        icon={<Truck />}
        values={devicePlansList.map(devicePlan => devicePlan.displayName)}
        placeholder="Select Device Plan"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Billing Status"
        formField="billingStatus"
        icon={<TollAgency />}
        values={billingStatusList
          .filter(billingStatus => {
            if (billingStatus.displayName === 'Monitored' && orderNow) {
              return false;
            }
            if (billingStatus.displayName === 'Unsubscribed Tolling' && !orderNow) {
              return false;
            }
            if (billingStatus.displayName === 'Unsubscribed Permanently') {
              return formik.values?.blocked;
            }
            if (billingStatus.displayName === 'Deleted') {
              return formik.values?.deleted;
            }
            return true;
          })
          .map(billingStatus => billingStatus.displayName)}
        placeholder="Select Billing Status"
      />
      <StartAndEndDate
        startLabel="Billing Status Updated Date From"
        startPlaceholder="Billing Status Updated Date From"
        endLabel="Billing Status Updated Date To"
        endPlaceholder="Billing Status Updated Date To"
        startField="date.from"
        endField="date.to"
        {...props}
      />
      <SelectInput
        {...props}
        type="simple"
        label="GPS Reliability"
        formField="gpsReliability"
        icon={<VehicleTires />}
        values={['HIGH', 'MEDIUM', 'LOW', 'UNKNOWN']}
        placeholder="Select GPS Reliability"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Device Type"
        formField="deviceType"
        icon={<TollID />}
        values={['GeoTab', 'CL - Manual']}
        placeholder="Select Device Types"
      />
    </>
  );
};

export default Fleet;
