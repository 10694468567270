import { GOOGLE_MAPS_LIBRARIES, MAPS_API_KEY } from '@common/utils/constants';
import { GoogleMap, useJsApiLoader, Polyline, MarkerF } from '@react-google-maps/api';
import { memo, useCallback, useState, FC, useEffect, Fragment } from 'react';
import pin from '@assets/images/pin.svg';
import actualMarker from '@assets/images/qs-active-marker.svg';
import alternativeMarker from '@assets/images/qs-alternative-marker.svg';
import { ITripPoint, IOriginalTrip, IAltTrip } from '@api/types';
import Skeleton from 'react-loading-skeleton';
import styles from './map.module.scss';
import { MapProps } from './types';

const defaultProps = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 10
};

const Map: FC<MapProps> = ({ alternativeTrips, originalTrip, tripType }) => {
  const [ogTrip, setOgTrip] = useState<IOriginalTrip>([] as IOriginalTrip);
  const [altTrips, setAltTrips] = useState<IAltTrip[]>([]);
  const [center, setCenter] = useState(defaultProps.center);

  const { isLoaded } = useJsApiLoader({
    id: 'qs-google-map-script',
    googleMapsApiKey: MAPS_API_KEY || '',
    libraries: GOOGLE_MAPS_LIBRARIES
  });
  // ? trips
  useEffect(() => {
    setOgTrip(originalTrip || ([] as IOriginalTrip));
  }, [originalTrip]);

  useEffect(() => {
    setAltTrips(alternativeTrips || []);
  }, [alternativeTrips]);

  // ? map center

  useEffect(() => {
    if (ogTrip) {
      if (ogTrip?.length > 0) {
        const minLat = Math.min(...ogTrip.map(item => item.lat));
        const maxLat = Math.max(...ogTrip.map(item => item.lat));
        const minLng = Math.min(...ogTrip.map(item => item.lng));
        const maxLng = Math.max(...ogTrip.map(item => item.lng));
        const centerCoOrds = {
          lat: (minLat + maxLat) / 2,
          lng: (minLng + maxLng) / 2
        };
        setCenter(centerCoOrds);
      }
    }
  }, [ogTrip]);

  useEffect(() => {
    if (altTrips.length) {
      const minLat = Math.min(...altTrips[0].logRecords.map(item => item.lat));
      const maxLat = Math.max(...altTrips[0].logRecords.map(item => item.lat));
      const minLng = Math.min(...altTrips[0].logRecords.map(item => item.lng));
      const maxLng = Math.max(...altTrips[0].logRecords.map(item => item.lng));
      const centerCoOrds = {
        lat: (minLat + maxLat) / 2,
        lng: (minLng + maxLng) / 2
      };
      setCenter(centerCoOrds);
    }
  }, [altTrips]);

  // ? set map
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = useCallback((newMap: google.maps.Map | null) => {
    newMap?.setZoom(defaultProps.zoom);
    setMap(newMap);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return (
    <section className={styles.container}>
      {isLoaded && center?.lat !== 0 ? (
        <GoogleMap
          center={center}
          mapContainerClassName={styles.map}
          zoom={defaultProps.zoom}
          onLoad={newMap => onLoad(newMap)}
          onUnmount={onUnmount}
          options={{
            fullscreenControl: false,
            mapTypeControl: false
          }}
        >
          {ogTrip && (
            <Polyline
              path={ogTrip}
              options={{
                map,
                geodesic: true,
                strokeOpacity: 1,
                strokeColor: tripType === 'actual' ? '#706b6b' : '#c0bdb5',
                strokeWeight: 4,
                visible: true,
                zIndex: tripType === 'actual' ? 4 : 2
              }}
            />
          )}
          {/* {altTrips?.length !== 0 && ( */}
          <>
            <Polyline
              path={altTrips[0]?.logRecords}
              options={{
                map,
                geodesic: true,
                strokeOpacity: 1,
                strokeColor: tripType === 'actual' ? '#aec0f5' : '#3b68ed',
                strokeWeight: 4,
                visible: true,
                zIndex: tripType === 'actual' ? 1 : 3
              }}
            />
            {/* {altTrip?.logRecords
                  ?.filter(
                    (record, index) => index !== 0 && index !== altTrip.logRecords.length - 1
                  )
                  .map(record => (
                    <Marker
                      key={uuid()}
                      icon={alternativeMarker}
                      position={record}
                      options={{ zIndex: 1 }}
                    />
                  ))} */}
          </>
          {/* )} */}
          {/* // ? selected toll-highlight polyline */}
          {/* <Polyline
            path={
              ogTrip?.logRecords?.length > 0
                ? [
                    ogTrip.logRecords[1],
                    ogTrip.logRecords[2],
                    ogTrip.logRecords[3],
                    ogTrip.logRecords[4],
                    ogTrip.logRecords[5]
                  ]
                : []
            }
            options={{
              map,
              geodesic: true,
              strokeOpacity: 1.0,
              strokeColor: '#2A28F3',
              strokeWeight: 4,
              visible: true,
              zIndex: 5
            }}
          /> */}
          {/* // ? markers */}
          {ogTrip?.length > 0 && (
            <MarkerF
              icon={tripType === 'actual' ? pin : actualMarker}
              position={ogTrip[0]}
              options={{ zIndex: tripType === 'actual' ? 4 : 2 }}
            />
          )}
          {ogTrip?.length > 0 && (
            <MarkerF
              icon={tripType === 'actual' ? pin : actualMarker}
              position={ogTrip.at(-1) as ITripPoint}
              options={{ zIndex: tripType === 'actual' ? 3 : 1 }}
            />
          )}
          {altTrips[0]?.logRecords?.length > 0 && (
            <MarkerF
              icon={tripType === 'alt' ? pin : alternativeMarker}
              position={altTrips[0].logRecords[0]}
              options={{ zIndex: tripType === 'actual' ? 1 : 3 }}
            />
          )}
          {altTrips[0]?.logRecords?.length > 0 && (
            <MarkerF
              icon={tripType === 'alt' ? pin : alternativeMarker}
              position={altTrips[0].logRecords.at(-1) as ITripPoint}
              options={{ zIndex: tripType === 'actual' ? 1 : 2 }}
            />
          )}
        </GoogleMap>
      ) : (
        <Skeleton
          style={{
            display: 'block',
            width: '100%',
            height: '100%'
          }}
        />
      )}
    </section>
  );
};

export default memo(Map);
