import { FC } from 'react';
import Button from '@common/ui/Button';
import { clearSearch } from '@store/features/search';
import { useAppDispatch } from '@store/hooks';
import { clearFilters } from '@store/features/filters';
import { setCurrentPage } from '@store/features/pagination';
import { LoadedObjectsProps } from './types';
import styles from './loaded-objects.module.scss';

const LoadedObjects: FC<LoadedObjectsProps> = ({ dataLength, tableName }) => {
  const dispatch = useAppDispatch();
  const cancel = () => {
    dispatch(clearSearch(tableName));
    dispatch(clearFilters(tableName));
    dispatch(
      setCurrentPage({
        table: tableName,
        currentPage: 1
      })
    );
  };
  return (
    <section className={styles.container}>
      <p className={styles.objects}>
        {dataLength} {dataLength === 1 ? 'result' : 'results'} found
      </p>
      <Button appearance="button" label="Cancel" variant="tertiary" size="xs" onClick={cancel} />
    </section>
  );
};

export default LoadedObjects;
