export const formatDistance = (
  distance: number,
  from: 'kilometres' | 'metres' | 'miles' = 'metres'
) => {
  const oneMetreToMile = 0.0006213709999975145;
  const oneKilometreToMile = 0.621371;
  return ['kilometres', 'metres'].includes(from)
    ? distance * (from === 'metres' ? oneMetreToMile : oneKilometreToMile)
    : distance / oneKilometreToMile;
};
