/* eslint-disable  */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rangeOfNumbers } from '@common/services/arrays';
import { ETableName } from '@common/utils/enums';
import { IPagination } from './types';
import Cookies from 'js-cookie';
import { cookiePagination } from '@common/services/auth';

const initialTableState: IPagination = {
  showBy: 10,
  showByValues: [10],
  firstRowIndex: 0,
  lastRowIndex: 0,
  currentPage: 1,
  dataLength: 0,
  pagesArray: [],
  totalPages: 1
};

export const initialState = {
  fleet: initialTableState,
  customers: initialTableState,
  roles: initialTableState,
  trailers: initialTableState,
  transactions: initialTableState,
  trips: initialTableState,
  users: initialTableState,
  toll_documents: initialTableState,
  alerts: initialTableState
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    changeShowBy: (state, action: PayloadAction<{ table: ETableName; showBy: number }>) => {
      const pagination = state[action.payload.table];
      pagination.showBy = action.payload.showBy;
      Cookies.set(cookiePagination, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    setCurrentPage: (state, action: PayloadAction<{ table: ETableName; currentPage: number }>) => {
      const pagination = state[action.payload.table];
      pagination.currentPage = action.payload.currentPage || 1;
      const indexOfLastRow = pagination.currentPage * pagination.showBy;
      const indexOfFirstRow = indexOfLastRow - pagination.showBy;
      pagination.firstRowIndex = indexOfFirstRow + 1;
      pagination.lastRowIndex =
        indexOfLastRow > pagination.dataLength ? pagination.dataLength : indexOfLastRow;
      Cookies.set(cookiePagination, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    setPagination: (
      state,
      action: PayloadAction<{ table: ETableName; totalPages: number; dataLength: number }>
    ) => {
      const pagination = state[action.payload.table];
      pagination.totalPages = action.payload.totalPages;
      pagination.dataLength = action.payload.dataLength;
      pagination.pagesArray = rangeOfNumbers(1, action.payload.totalPages);
      const indexOfLastRow = pagination.currentPage * pagination.showBy;
      const indexOfFirstRow = indexOfLastRow - pagination.showBy;
      pagination.firstRowIndex = indexOfFirstRow + 1;
      pagination.lastRowIndex =
        indexOfLastRow > pagination.dataLength ? pagination.dataLength : indexOfLastRow;
      if (action.payload.dataLength > 100) {
        pagination.showByValues = [10, 20, 50, 100, 150];
      } else if (action.payload.dataLength > 50) {
        pagination.showByValues = [10, 20, 50, 100];
      } else if (action.payload.dataLength > 20) {
        pagination.showByValues = [10, 20, 50];
      } else if (action.payload.dataLength > 10) {
        pagination.showByValues = [10, 20];
      } else if (action.payload.dataLength <= 10 && action.payload.dataLength !== 0) {
        pagination.showByValues = [10];
      } else {
        pagination.showByValues = [];
      }
      Cookies.set(cookiePagination, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearPagination: (state, action: { payload: ETableName | undefined }) => {
      if (action.payload) {
        const reserveState = state[action.payload];
        state = { ...initialState, [action.payload]: reserveState };
        return state;
      }
      Cookies.set(cookiePagination, JSON.stringify(state), { sameSite: 'None', secure: true });
      return initialState;
    },
    setSessionPagination: (state, action) => {
      state = JSON.parse(action.payload);
      return state;
    }
  }
});

export const {
  changeShowBy,
  setCurrentPage,
  clearPagination,
  setPagination,
  setSessionPagination
} = paginationSlice.actions;

export default paginationSlice.reducer;
