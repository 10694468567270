import tagLogicLogo from '@assets/images/tag-logic-card-logo.svg';
import violationManagementLogo from '@assets/images/violation-management-logo.svg';
import { CardProps } from '../types/card';

export const cardList: CardProps[] = [
  {
    title: 'taglogic™',
    description: `taglogic™ provides a single accurate bill for your entire fleet, regardless of
        location. Enabling smooth account management of transponders and license plates to keep
        your fleet’s vehicles current. Backed by tollogic™, your fleet will be optimized.`,
    logoSrc: tagLogicLogo,
    logoAlt: 'taglogic',
    withButton: false
    // buttonLink: routes.profile,
    // buttonLabel: 'Add taglogic'
  },
  {
    title: 'Violation Management',
    description: ``,
    logoSrc: violationManagementLogo,
    logoAlt: 'Violation Management',
    withButton: false
  }
];
