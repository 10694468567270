import { TripDataInterface } from '@common/types/data';
import { useGetCalculatedTollsQuery } from '@api/services/trips';
import Loader from '@common/ui/Loader';
import { useAppSelector } from '@store/hooks';
import { useTripsMap } from './hooks';
import Map from './Map';
import MapHeader from './MapHeader';
import styles from './trips-map.module.scss';

const TripsMap = ({ data, timezone }: { data: TripDataInterface; timezone: string }) => {
  const { altTrips, originalTrip, handleTripType, tripType, headerDetails } = useTripsMap(data);
  const { data: estimatedTolls, isLoading } = useGetCalculatedTollsQuery(data.id, {
    refetchOnReconnect: true,
    skip: !data.id
  });

  const headerDetailsD = { ...headerDetails };
  const isMetric = useAppSelector(state => state.auth.isMetric);

  if (isMetric) {
    headerDetailsD.distance = tripType === 'actual' ? data.distance : headerDetails.distance;
  } else {
    headerDetailsD.distance = tripType === 'actual' ? data.distanceInMiles : headerDetails.distance;
  }

  return (
    <section className={styles.container}>
      {isLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <MapHeader
            headerDetails={headerDetailsD}
            tripType={tripType}
            handleTripType={handleTripType}
            altTrips={altTrips}
          />
          <Map
            originalTrip={originalTrip}
            alternativeTrips={altTrips}
            trip={data}
            tripType={tripType}
            handleTripType={handleTripType}
            tollData={estimatedTolls}
            timezone={timezone}
          />
        </>
      )}
    </section>
  );
};

export default TripsMap;
