import {
  ICustomersFilters,
  IFleetFilters,
  ITollDocumentsFilters,
  ITransactionsFilters,
  ITripsFilters,
  IUsersFilters
} from '@common/types/filters';

export const fleetInitialValues: IFleetFilters = {
  vehicleAxles: '',
  vehicleClass: '',
  tagType: '',
  devicePlan: '',
  deviceType: '',
  billingStatus: '',
  date: {
    from: '',
    to: ''
  },
  trailers: false,
  blocked: false,
  deleted: false
};

export const tollDocumentsInitialValues: ITollDocumentsFilters = {
  status: '',
  invoiceIssuer: '',
  date: {
    from: '',
    to: ''
  }
};

export const transactionsInitialValues: ITransactionsFilters = {
  date: {
    from: '',
    to: ''
  },
  toll: {
    from: undefined,
    to: undefined
  }
};

export const tripsInitialValues: ITripsFilters = {
  date: {
    from: '',
    to: ''
  },
  timeZone: '',
  tripDistance: {
    from: undefined,
    to: undefined
  },
  toll: {
    from: undefined,
    to: undefined
  },
  tripDuration: {
    from: undefined,
    to: undefined
  }
};

export const usersInitialValues: IUsersFilters = {
  app_role: ''
};

export const customersInitialValues: ICustomersFilters = {
  statusCode: ''
};
