import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { AppProvider } from '@common/providers';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider
import 'react-loading-skeleton/dist/skeleton.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const container = document.getElementById('cl-root') as HTMLElement;
const root = createRoot(container);
if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '');
}
const theme = createTheme({
  // Your theme configuration here
  typography: {
    fontFamily: 'Inter, sans-serif'
  }
  // palette: {
  //   // Customize palette colors, typography, etc.
  //   primary: {
  //     main: '#3f51b5'
  //   }
  // }
});
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <AppProvider>
      <Router>
        <App />
        <ToastContainer />
      </Router>
    </AppProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
