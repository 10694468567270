import { useEffect, useCallback, useRef } from 'react';

export const useSlider = (min: number, max: number, userMin: number, userMax: number) => {
  const rangeRef = useRef<HTMLSpanElement>(null);
  const userMinRef = useRef<HTMLInputElement>(null);
  const userMaxRef = useRef<HTMLInputElement>(null);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );
  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (userMaxRef.current) {
      const minPercent = getPercent(userMin);
      const maxPercent = getPercent(+userMaxRef.current.value);
      // Precede with '+' to convert the value from type string to type number

      if (rangeRef.current) {
        rangeRef.current.style.left = `${minPercent}%`;
        rangeRef.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [userMin, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (userMinRef.current) {
      const minPercent = getPercent(+userMinRef.current.value);
      const maxPercent = getPercent(userMax);

      if (rangeRef.current) {
        rangeRef.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [userMax, getPercent]);

  return { rangeRef, userMinRef, userMaxRef };
};
