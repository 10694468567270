import { Formik } from 'formik';
import { useAppSelector } from '@store/hooks';
import { EAuthRole } from '@common/utils/enums';
import { useHandleSubmit } from './hooks/useHandleSubmit';
import { initialValues, ICreateUserType } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';

export const CreateUserFormik = ({ children }: ReactChildren) => {
  const { handleSubmit } = useHandleSubmit();

  const userRole = useAppSelector(state => state.auth.app_role);

  const customerId = useAppSelector(state => state.customer.id);

  const isEmptyCustomer = userRole === EAuthRole.SUPER_ADMIN && !customerId;

  if (isEmptyCustomer) {
    initialValues.app_role = 'SUPERADMIN';
  } else {
    initialValues.app_role = '';
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const modValues: ICreateUserType = {
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          app_role: values.app_role,
          password: values.password
        };
        handleSubmit(modValues, setSubmitting);
      }}
    >
      {children}
    </Formik>
  );
};
