import _ from 'lodash';

export const getSearchResults = (
  data: AnyObject[],
  searchBy?: string | number,
  activeHeadings?: string[]
) => {
  const searchValues =
    searchBy !== undefined
      ? _.compact(
          data?.filter(obj => {
            return Object.entries(obj)
              .filter(entry => activeHeadings?.includes(entry[0]))
              .find((value: [string, string | number | string[] | number[]]) => {
                const modValue = Array.isArray(value[1]) ? value[1].join(', ') : value[1];
                return _.kebabCase(`${modValue}`.toLowerCase()).includes(
                  _.kebabCase(`${searchBy}`.toLowerCase())
                );
              });
          })
        )
      : data;

  return searchValues;
};
