/* eslint-disable */
import { timeMultipliers } from '@common/utils/time-multipliers';

/* eslint-disable no-nested-ternary */
type TimeVariants = 'secs' | 'mins' | 'hrs' | 'days';
const timeVariants: TimeVariants[] = ['secs', 'mins', 'hrs', 'days'];
const singleUnitTimeVariant = ['sec', 'min', 'hr', 'day'];
export const formatTime = (item: number, from: TimeVariants = 'mins', to: TimeVariants = 'hrs') => {
  const singleUnitTo = singleUnitTimeVariant[timeVariants.findIndex(unit => unit === to)];
  const remainderUnit =
    timeVariants.findIndex(unit => unit === to) !== 0
      ? timeVariants[timeVariants.findIndex(unit => unit === to) - 1]
      : from;
  const singleRemainderUnit =
    singleUnitTimeVariant[timeVariants.findIndex(unit => unit === remainderUnit)];
  const convertMainBy = timeMultipliers[from][to];
  const convertRemainderBy = timeMultipliers[from][remainderUnit];
  const remainder = item % convertMainBy;

  return `${
    Math.floor(item / convertMainBy).toFixed(0) === '0'
      ? ''
      : Math.floor(item / convertMainBy).toFixed(0) !== '1'
      ? `${Math.floor(item / convertMainBy).toFixed(0)}${to} `
      : `${Math.floor(item / convertMainBy).toFixed(0)}${singleUnitTo} `
  }${
    remainder === 0 && Math.floor(item / convertMainBy).toFixed(0) !== '0'
      ? ''
      : remainder !== 1
      ? `${Math.floor(remainder / convertRemainderBy).toFixed(0)}${remainderUnit}`
      : `${Math.floor(remainder / convertRemainderBy).toFixed(0)}${singleRemainderUnit}`
  }`;
};

export function secondsToHms(d: any, hideSecs?: boolean) {
  const days = Math.floor(d / 86400);
  const hours = Math.floor((d % 86400) / 3600);
  const minutes = Math.floor((d % 3600) / 60);
  const seconds = Math.floor(d % 60);

  const daysDisplay = days > 0 ? days + 'd ' : '';
  const hoursDisplay = hours > 0 ? hours + 'h ' : '';
  const minutesDisplay = minutes > 0 ? minutes + 'm ' : '';
  const secondsDisplay = seconds > 0 ? seconds + 's' : '';

  return `${daysDisplay}${hoursDisplay}${minutesDisplay}${!hideSecs ? secondsDisplay : ''}`;
}

export function TimeSavedSecondsToHms(d: any) {
  const isNegative = d < 0;
  const absD = Math.abs(d);
    
  const days = Math.floor(absD / 86400);
  const hours = Math.floor((absD % 86400) / 3600);
  const minutes = Math.floor((absD % 3600) / 60);
  const seconds = Math.floor(absD % 60);

  const daysDisplay = days > 0 ? days + 'd ' : '';
  const hoursDisplay = hours > 0 ? hours + 'h ' : '';
  const minutesDisplay = minutes > 0 ? minutes + 'm ' : '';
  const secondsDisplay = seconds > 0 ? seconds + 's' : '';

  const result = `${daysDisplay}${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
  return isNegative ? `-${result}` : result;
}
