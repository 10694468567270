import { useState, FC } from 'react';
import { standardDropdownMotionVariants } from '@common/utils/motion-variants';
import { useField } from 'formik';
import Dropdown from '@common/ui/Dropdown';
import styles from './select-input.module.scss';
import SelectInputDisplay from './SelectInputDisplay';
import { SelectInputProps } from './types';
import { useSelectionState } from './hooks';

const SelectInput: FC<SelectInputProps> = ({
  label = '',
  className = '',
  dropdownClassName = '',
  placeholder,
  formField,
  values,
  disabled,
  icon,
  dropdownContainerEl,
  type = 'simple',
  manualTouchEnable,
  enableTooltip = true,
  ...props
}) => {
  const name = formField;
  const [, meta] = useField({ name });

  const { selection, handleSelectionState } = useSelectionState(name, type);
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => setIsOpen(value);
  const { error } = meta;
  let { touched } = meta;
  if (manualTouchEnable) {
    touched = true;
  }

  const selectInputStyles =
    name && error && touched
      ? `${styles.container} ${className} ${styles['container-error']}`
      : `${styles.container} ${className}`;
  return (
    <section
      aria-label="select-input"
      data-select-input-disabled={disabled}
      data-select-is-tags={type === 'tags'}
      className={selectInputStyles}
    >
      {label && <label htmlFor={label}>{label}</label>}
      <Dropdown
        disabled={disabled}
        className={`${styles.dropdown} ${dropdownClassName}`}
        selection={selection}
        type="select"
        handleSelectionState={handleSelectionState}
        values={values}
        isOpen={isOpen}
        handleOpenState={handleDropdownOpenState}
        boundaryElement={dropdownContainerEl}
        customDropdownItems={props.customDropdownItems}
        includeSearch={props.includeSearch}
        position="bottom"
        offset={[0, 6]}
        motionVariants={standardDropdownMotionVariants}
        sameWidth
      >
        <SelectInputDisplay
          placeholder={placeholder}
          selection={selection}
          disabled={disabled}
          icon={icon}
          isOpen={isOpen}
          handleSelectionState={handleSelectionState}
          error={!!(name && error && touched)}
          enableTooltip={enableTooltip}
        />
      </Dropdown>
      {touched && error && !disabled && (
        <span aria-label="select-input-error" className={styles['error-description']}>
          {error}
        </span>
      )}
    </section>
  );
};

export default SelectInput;
