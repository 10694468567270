import { baseApi, SSO_LOGIN_API, SSO_REGISTER_API, SSO_VALIDATE_API } from '@api/config';

export const geotabSSOApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['SSO', 'GEOTAB'] })
  .injectEndpoints({
    endpoints: builder => ({
      validateUserAPI: builder.mutation<unknown, unknown>({
        query: body => ({ url: SSO_VALIDATE_API, method: 'POST', body }),
        invalidatesTags: ['GEOTAB', 'SSO']
      }),
      registerUserAPI: builder.mutation<unknown, unknown>({
        query: () => ({ url: SSO_REGISTER_API }),
        invalidatesTags: ['GEOTAB', 'SSO']
      }),
      loginUserAPI: builder.mutation<unknown, unknown>({
        query: body => ({ url: SSO_LOGIN_API, method: 'POST', body }),
        invalidatesTags: ['GEOTAB', 'SSO']
      })
    })
  });

export const { useValidateUserAPIMutation, useLoginUserAPIMutation, useRegisterUserAPIMutation } =
  geotabSSOApi;
