import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarChartProps } from './types';

const BarChart: React.FC<BarChartProps> = ({
  data,
  plugins = [],
  options = {},
  width = '100%',
  height = '100%'
}) => {
  return (
    <div style={{ width, height }}>
      {/* eslint-disable-next-line */}
      <Bar data={data} options={options} plugins={plugins}/>
    </div>
  );
};

export default memo(BarChart);
