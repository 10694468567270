/*eslint-disable */
import { FC, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown, GMap, Dollar, Truck } from '@assets/icons';
import styles from './accordion.module.scss';
import { AccordionProps } from './types';

const Accordion: FC<AccordionProps> = ({
  label,
  headerElement,
  disabled = false,
  children,
  tableName,
  className,
  withArrow = true,
  onlyArrowClick = false,
  onclick,
  onAccordionClick,
  minHeight,
  open = false
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleSectionClick = () => {
    if (!onlyArrowClick) {
      setIsOpen(prev => !prev);
      if (onAccordionClick) {
        onAccordionClick();
      }
    }
  };
  const handleArrowClick = () => {
    if (onlyArrowClick) {
      setIsOpen(prev => !prev);
      if (onAccordionClick) {
        onAccordionClick();
      }
    }
  };
  return (
    <section
      className={
        isOpen
          ? `${styles.container} ${className || ''} ${styles.open}`
          : `${styles.container} ${className || ''}`
      }
    >
      <section
        className={styles.summary}
        role="button"
        onClick={() => handleSectionClick()}
        tabIndex={0}
      >
        <button
          type="button"
          disabled={disabled}
          data-testid="accordion-control"
          className={styles.title}
        >
          {withArrow && (
            <span className={styles.icon} role="button" onClick={handleArrowClick} tabIndex={0}>
              <ChevronDown />
            </span>
          )}
          {label && <h3>{label}</h3>}
          {headerElement ? headerElement : ''}
        </button>
        <ul className={styles['icon-group']}>
          {tableName && ['transactions', 'fleet'].includes(tableName) && (
            <li className={styles.map} data-testid="accordion-map-icon">
              <button disabled={disabled} type="button" onClick={onclick}>
                <GMap />
              </button>
            </li>
          )}
          {tableName && ['trips', 'transactions'].includes(tableName) && (
            <li className={styles.truck} data-testid="accordion-truck-icon">
              <button disabled={disabled} type="button">
                <Truck />
              </button>
            </li>
          )}
          {tableName && ['trips'].includes(tableName) && (
            <li className={styles.transaction} data-testid="accordion-transaction-icon">
              <button disabled={disabled} type="button">
                <Dollar />
              </button>
            </li>
          )}
          {/* <li className={styles.delete}>
            <button type="button">
              <Delete />
            </button>
          </li> */}
        </ul>
      </section>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={
              minHeight
                ? {
                    minHeight: minHeight,
                    opacity: 1
                  }
                : {
                    height: 'fit-content',
                    opacity: 1
                  }
            }
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            layout
            className={styles.content}
            data-testid="accordion-detail"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Accordion;
