import uniqolor from 'uniqolor';
import styles from './customer-icon.module.scss';
import { CustomerIconProps } from './types';

const CustomerIcon = ({
  logo,
  name,
  imageClassName = '',
  letterClassName = ''
}: CustomerIconProps) => {
  const randomColor = uniqolor.random();

  if (logo) {
    return (
      <picture className={`${styles.picture} ${imageClassName}`}>
        <img src={logo} alt="" />
      </picture>
    );
  }
  return (
    <div
      className={`${styles['letter-box']} ${letterClassName}`}
      style={{
        background: `${randomColor.color}`
      }}
    >
      {name?.slice(0, 1)}
    </div>
  );
};

export default CustomerIcon;
