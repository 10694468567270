/* eslint-disable */
import { IUnionFilters } from '@common/types/filters';
import { ETableName } from '@common/utils/enums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { cookieFilters } from '@common/services/auth';
import { IFilter, ITableFilter } from './types';
import {
  fleetInitialValues,
  customersInitialValues,
  transactionsInitialValues,
  tripsInitialValues,
  usersInitialValues,
  tollDocumentsInitialValues
} from './initValues';

export * from './types';

const initialTableState = (values: IUnionFilters): IFilter => ({
  active: false,
  by: values
});
export const initialState: Partial<{ [key in ETableName]: IFilter }> = {
  fleet: initialTableState(fleetInitialValues) as ITableFilter['fleet'],
  customers: initialTableState(customersInitialValues) as ITableFilter['customers'],
  transactions: initialTableState(transactionsInitialValues) as ITableFilter['transactions'],
  trips: initialTableState(tripsInitialValues) as ITableFilter['trips'],
  users: initialTableState(usersInitialValues) as ITableFilter['users'],
  toll_documents: initialTableState(tollDocumentsInitialValues) as ITableFilter['toll_documents']
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<{ table: ETableName; filters: IUnionFilters }>) => {
      const filter = state[action.payload.table];
      if (filter) {
        const values = Object.values(action.payload.filters)
          .map((item: unknown) => {
            if (typeof item === 'object') {
              return Object.values(item as object) as unknown[];
            }
            return [item];
          })
          .reduce((prev, next) => [...prev].concat(next));
        const activeFilters = _.compact(values).length;
        filter.active = activeFilters > 0;
        filter.by = action.payload.filters;
      }
      Cookies.set(cookieFilters, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    setDateFilters: (state, action: PayloadAction<{ table: ETableName; dates: string[] }>) => {
      const filter = state[action.payload.table];
      if (filter?.by && 'date' in filter.by && filter.by.date) {
        filter.by.date.from = action.payload.dates[0];
        filter.by.date.to = action.payload.dates[1];
        filter.active = Object.values(filter.by.date).length > 0;
      }
      Cookies.set(cookieFilters, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearFilters: (state, action: { payload: ETableName }) => {
      const filter = state[action.payload];
      if (filter) {
        filter.active = false;
        filter.by = initialState[action.payload]?.by;
      }
      Cookies.set(cookieFilters, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearAllFilters: (state, action: { payload: ETableName | undefined }) => {
      if (action.payload) {
        const reserveState = state[action.payload];
        state = { ...initialState, [action.payload]: reserveState };
        return state;
      }
      Cookies.set(cookieFilters, JSON.stringify(state), { sameSite: 'None', secure: true });
      return initialState;
    },
    setSessionFilters: (state, action) => {
      state = JSON.parse(action.payload);
      return state;
    }
  }
});

export const { setFilters, clearAllFilters, clearFilters, setDateFilters, setSessionFilters } =
  filtersSlice.actions;

export default filtersSlice.reducer;
