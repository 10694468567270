import { baseApi, TRIPS_API } from '../config';
import { IAltTrip, IOriginalTrip } from '../types';

export const quicksightApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['QuicksightAltTrips', 'QuicksightActualTrip']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getQuicksightAltTrips: builder.query<IAltTrip[], string | number>({
        query: trip_id => `${TRIPS_API}/${trip_id}/alternative-trip`,
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'QuicksightAltTrips' as const, id })),
                { type: 'QuicksightAltTrips', id: 'LIST' }
              ]
            : [{ type: 'QuicksightAltTrips', id: 'LIST' }]
      }),
      getQuicksightActualTrip: builder.query<IOriginalTrip, string | number>({
        query: trip_id => `${TRIPS_API}/${trip_id}/original-trip`,
        providesTags: ['QuicksightActualTrip']
      })
    })
  });

export const { useGetQuicksightActualTripQuery, useGetQuicksightAltTripsQuery } = quicksightApi;
