/*eslint-disable */
import { useState } from 'react';
import SelectInput from '@common/ui/FilterDropdown/SelectInput';
import { CustomDatePicker } from '@common/ui/Form';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  clearAlertTypes,
  clearVehicleNames,
  setAlertTypes,
  setDate,
  setVehicleNames
} from '@store/data/fleetTabs';
import { Calendar } from '@assets/icons';
import styles from './alert.module.scss';
import moment from 'moment-timezone';
import { setCurrentPage } from '@store/features/pagination';
import { ETableName } from '@common/utils/enums';

const AlertFilters = () => {
  const dispatch = useAppDispatch();
  const fleetQuickFilters = useAppSelector(state => state.table.fleetQuickFilters);
  const alertFilters = useAppSelector(state => state.fleetTabs.alertFilters);
  const [startDate, setStartDate] = useState<string | null>(alertFilters.date.start);
  const [endDate, setEndDate] = useState<string | null>(alertFilters.date.end);

  const alertTypes = [
    'Incorrect Plate on Vehicle',
    'Missing Plate on Vehicle',
    'Missing Plate',
    'Transponder Needed',
    'Tag not Valid',
    'Unresponsive Transponder',
    'Intermittent Tag Read Issue',
    'Incorrect Plate State on Vehicle',
    'Maximum Toll',
    'Vehicle Misclassification',
    'Duplicate Tolls'
  ];

  const handleAddFilters = (filter: string, data: any) => {
    if (filter === 'name') {
      dispatch(setVehicleNames(data));
    } else if (filter === 'alert') {
      const replacedIndices = data.map((value: string) => {
        if (value === 'Maximum Toll') return 11;
        if (value === 'Vehicle Misclassification') return 12;
        if (value === 'Duplicate Tolls') return [16, 17, 18];
        return alertTypes.indexOf(value) + 1;
      });
      dispatch(setAlertTypes(replacedIndices.flat()));
    }
  };

  const handleRemoveFilters = (filter: string, data: any) => {
    if (filter === 'name') {
      dispatch(clearVehicleNames(data));
    } else if (filter === 'alert') {
      const replacedIndices = data.map((value: string) => {
        if (value === 'Maximum Toll') return 11;
        if (value === 'Vehicle Misclassification') return 12;
        if (value === 'Duplicate Tolls') return [16, 17, 18];
        alertTypes.indexOf(value) + 1;
      });
      dispatch(clearAlertTypes(replacedIndices.flat()));
    }
  };

  const handleSetDate = (period: string, date: string | null) => {
    let dateObject: { start: string | null; end: string | null } = {
      start: null,
      end: null
    };
    if (period === 'start') {
      dateObject = {
        start: date,
        end: endDate
      };
      setStartDate(date);
    } else {
      dateObject = {
        start: startDate,
        end: date
      };
      setEndDate(date);
    }
    // if (
    //   (dateObject?.start &&
    //     dateObject.start.length > 0 &&
    //     dateObject.end &&
    //     dateObject.end.length > 0) ||
    //   (dateObject.start === null && dateObject.end === null)
    // ) {
    //   dispatch(setDate(dateObject));
    // }
    dispatch(setDate(dateObject));
  };

  return (
    <div className={styles.filterContainer}>
      <SelectInput
        className=""
        handleChange={data => handleAddFilters('name', data)}
        includeSearch
        values={fleetQuickFilters?.names || []}
        selection={alertFilters.name}
        clearFilterSelection={data => handleRemoveFilters('name', data)}
        disabled={false}
        type="tags"
        placeholder="Name"
      />
      <SelectInput
        className={styles['select-items']}
        handleChange={data => handleAddFilters('alert', data)}
        includeSearch
        values={alertTypes || []}
        selection={
          alertFilters.alert_type
            ?.filter(index => ![17, 18].includes(index))
            .map(index => {
              if (index === 11) return 'Maximum Toll';
              if (index === 12) return 'Vehicle Misclassification';
              if ([16, 17, 18].includes(index)) return 'Duplicate Tolls';
              return alertTypes[index - 1];
            }) || []
        }
        clearFilterSelection={data => handleRemoveFilters('alert', data)}
        disabled={false}
        type="tags"
        placeholder="Alert Type"
      />
      {/* <SelectInput
        className={styles['select-items']}
        handleChange={() => {}}
        values={[]}
        selection={[]}
        clearFilterSelection={() => {}}
        disabled={false}
        type="tags"
        placeholder="Date"
      /> */}

      <CustomDatePicker
        className={styles['date-filters']}
        icon={<Calendar />}
        placeholder="Start Date"
        handleDate={date => {
          date && dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 0 }));
          handleSetDate(
            'start',
            date ? moment.utc(moment(date).startOf('day').utc()).format() : null
          );
        }}
        dateFormat="MM/dd/yyyy"
        maxDate={moment(endDate).toDate()}
        initialDate={startDate || ''}
        errorMessage={endDate ? (startDate ? '' : ' * Start Date Required') : ''}
        isEndOfDay
      />
      <CustomDatePicker
        className={styles['date-filters']}
        icon={<Calendar />}
        maxDate={new Date()}
        placeholder="End Date"
        handleDate={date => {
          date && dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 0 }));
          handleSetDate('end', date ? moment.utc(moment(date).endOf('day').utc()).format() : null);
        }}
        dateFormat="MM/dd/yyyy"
        minDate={moment(startDate).toDate()}
        initialDate={endDate || ''}
        errorMessage={startDate ? (endDate ? '' : ' * End Date Required') : ''}
      />
    </div>
  );
};

export default AlertFilters;
