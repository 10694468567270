import { useAppSelector } from '@store/hooks';
import logo from '@assets/images/logo.svg';
import { motion } from 'framer-motion';
import { navTextMotionVariants } from '@common/utils/motion-variants';
import CustomerIcon from '@common/ui/CustomerIcon';
import styles from './choose-customer.module.scss';

const ChooseCustomer = ({ navCollapse }: { navCollapse: boolean }) => {
  const customer = useAppSelector(state => state.customer);
  return (
    <div className={!navCollapse ? `${styles.container} ${styles.open}` : styles.container}>
      <div
        className={`${styles['nav-item']} ${styles['active-customer']} ${
          !navCollapse ? styles.open : ''
        }`}
      >
        {!customer.companyName ? (
          <picture>
            <img src={logo} alt="" />
          </picture>
        ) : (
          <CustomerIcon
            logo={customer.logoContent}
            name={customer.companyName}
            imageClassName={styles.picture}
            letterClassName={styles['letter-box']}
          />
        )}
        {!navCollapse && (
          <motion.span {...navTextMotionVariants} className={styles.text}>
            {customer.companyName || 'Select Customer'}
          </motion.span>
        )}
      </div>
    </div>
  );
};

export default ChooseCustomer;
