/* eslint-disable */
import { Filter } from '@assets/icons';
import { ETableName } from '@common/utils/enums';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { motion } from 'framer-motion';
// import { AnimatePresence } from 'framer-motion';
import RowCheckbox from '@pages/tables/layouts/web-portal/components/table/Row/Checkbox/RowCheckbox';
import { useCheckboxState } from '@pages/tables/layouts/web-portal/components/table/Row/hooks';
import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
// import ModifyColumnsModal from '../modify-columns-modal/ModifyColumnsModal';
import Column from './Column/Column';
import styles from './header.module.scss';
import { HeaderProps } from './types';

const Header = ({ headings, tableName }: HeaderProps) => {
  const table = useAppSelector(state => state.table);
  const dispatch = useAppDispatch();
  // const { activeModal: modal, id: storedModalId } = useAppSelector(state => state.modal);
  const { checkboxState } = useCheckboxState('header', tableName, table.data || []);
  const rowRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(20);
  useEffect(() => {
    setHeight(rowRef.current?.clientHeight || 0);
  }, [rowRef.current]);

  return (
    <>
      <header className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles['filter-icon']}>
            <RowCheckbox
              height={height}
              checkboxState={checkboxState}
              variant={'header'}
              // @ts-ignore
              data={table?.data}
              table={table}
              geoTab
            />
            <Filter />
          </div>
          <div className={styles.columns}>
            {headings
              ?.filter(header => header.active)
              .map(header => (
                <Column key={uuid()} {...header} tableName={tableName} />
              ))}
          </div>
          {![ETableName.USERS].includes(tableName) && (
            <div className={styles['columns-control']}>
              <button
                onClick={() => dispatch(openModal(['table-display', tableName]))}
                type="button"
              >
                Show/Hide Columns
              </button>
            </div>
          )}
        </div>
      </header>
      {/* <AnimatePresence>
        {modal === 'table-display' && tableName === storedModalId && (
          <ModifyColumnsModal headings={headings} tableName={tableName} />
        )}
      </AnimatePresence> */}
    </>
  );
};

export default Header;
