export function FilledCancel({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 21.5999C17.3018 21.5999 21.5999 17.3018 21.5999 11.9999C21.5999 6.69797 17.3018 2.3999 11.9999 2.3999C6.69797 2.3999 2.3999 6.69797 2.3999 11.9999C2.3999 17.3018 6.69797 21.5999 11.9999 21.5999Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9997 21.5997C17.3016 21.5997 21.5997 17.3017 21.5997 11.9997C21.5997 6.69782 17.3016 2.39975 11.9997 2.39975C6.69772 2.39975 2.39966 6.69782 2.39966 11.9997C2.39966 17.3017 6.69772 21.5997 11.9997 21.5997ZM9.20686 7.79274C8.81634 7.40222 8.18317 7.40222 7.79265 7.79274C7.40212 8.18326 7.40212 8.81643 7.79265 9.20695L10.5855 11.9998L7.79265 14.7927C7.40212 15.1833 7.40212 15.8164 7.79265 16.207C8.18317 16.5975 8.81634 16.5975 9.20686 16.207L11.9998 13.4141L14.7926 16.207C15.1832 16.5975 15.8163 16.5975 16.2069 16.207C16.5974 15.8164 16.5974 15.1833 16.2069 14.7927L13.414 11.9998L16.2069 9.20695C16.5974 8.81643 16.5974 8.18326 16.2069 7.79274C15.8163 7.40222 15.1832 7.40222 14.7926 7.79274L11.9998 10.5856L9.20686 7.79274Z"
      />
    </svg>
  );
}
