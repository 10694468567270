import Modal from '@common/ui/Modal';
import { TransactionDataInterface } from '@common/types/data';
import { formatTransactionDate } from '@common/services/format/formatTimeZone';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import { CrossFilterButtons, FormSubmitButton } from '../../../buttons';
import { UpdateTransaction, UpdateTransactionFormik } from '../../../forms/Update';
import styles from './transaction.module.scss';

const Transaction = ({ data }: { data: TransactionDataInterface }) => {
  const modifyData = { ...data };
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  modifyData.date = formatTransactionDate(modifyData, false, isActualTollTab);
  modifyData.calculatedAmount = Number(modifyData.calculatedAmount).toFixed(2);
  return (
    <UpdateTransactionFormik data={modifyData}>
      <Modal
        activeTitle="singleHighlight"
        navigation={['Details']}
        customPrimaryButton={<FormSubmitButton />}
        customExtraButtons={
          <CrossFilterButtons tableName={ETableName.TRANSACTIONS} entryData={[modifyData]} />
        }
        cancelButton={false}
        className={styles.modal}
        helpLocation="transaction_"
      >
        <UpdateTransaction />
      </Modal>
    </UpdateTransactionFormik>
  );
};

export default Transaction;
