/* eslint-disable import/no-cycle */
import { SUPERADMIN_API, USERS_API } from '@api/config';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UserDataInterface } from '@common/types/data';
import { EAuthRole } from '@common/utils/enums';
import { IInvoice, IManualInvoice } from '@pages/settings/subscription/types/subscription-invoice';
import api from '../../../api/services/common';

interface ISubscription {
  timestamp: string;
  billed_prior_month: string;
  billed_this_month: string;
  recently_subscribed: string;
  recently_unsubscribed: string;
  active: string;
}

export interface INotification {
  acknowledgement_email: boolean;
  statement_processed_email: boolean;
  failure_notification_email: boolean;
  auto_subscribe_email: boolean;
}

interface InitialState {
  loading: boolean;
  invoiceLoading: boolean;
  manualInvoiceLoading: boolean;
  open: boolean;
  company: [] | null;
  users: [UserDataInterface] | null;
  notification: INotification | null;
  subscription: ISubscription | null;
  invoices: Array<IInvoice> | [];
  manualInvoices: Array<IManualInvoice> | [];
}

const initialState: InitialState = {
  open: false,
  loading: false,
  invoiceLoading: false,
  manualInvoiceLoading: false,
  company: null,
  users: null,
  notification: null,
  subscription: null,
  invoices: [],
  manualInvoices: []
};

export const userData = createAsyncThunk(
  'getUsers',
  async (data: { customerId: string; appRole: string }) => {
    const isEmptyCustomer = data?.appRole === EAuthRole.SUPER_ADMIN && !data?.customerId;
    const res: [UserDataInterface] = (await api.JavaGet(
      `${isEmptyCustomer ? SUPERADMIN_API : USERS_API}?customer-id=${data.customerId}`
    )) as [UserDataInterface];
    return res;
  }
);

export const subscriptionData = createAsyncThunk('getSubscription', async () => {
  const res = (await api.get('/vehicle/vehicle-subscription-activity')) as {
    isSuccess: true;
    data: ISubscription;
  };
  return res.data;
});

export const subscriptionInvoiceData = createAsyncThunk('getSubscriptionInvoices', async () => {
  const res = (await api.get('/stripe-invoice/list')) as {
    isSuccess: true;
    data: [IInvoice];
  };
  return res.data || [];
});

export const manualSubscriptionInvoiceData = createAsyncThunk(
  'getManualSubscriptionInvoices',
  async () => {
    const res = (await api.get('/stripe-invoice/list-manual-invoices')) as {
      isSuccess: boolean;
      data: [IManualInvoice];
    };
    return res.data || [];
  }
);

export const notificationData = createAsyncThunk('getNotification', async () => {
  const res = (await api.get('/toll-document/notification-setting')) as {
    isSuccess: true;
    data: INotification;
  };
  return res.data;
});

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    openCompanyEdit: state => {
      return { ...state, open: true };
    },
    closeCompanyEdit: state => {
      return { ...state, open: false };
    },
    updatedNotifications: (state, action: PayloadAction<INotification>) => {
      return { ...state, notification: action.payload };
    }
  },
  extraReducers: builder => {
    builder.addCase(userData.pending, state => {
      return { ...state, loading: true };
    });

    builder.addCase(userData.fulfilled, (state, action) => {
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    });

    builder.addCase(subscriptionData.pending, state => {
      return { ...state, loading: true };
    });

    builder.addCase(subscriptionInvoiceData.pending, state => {
      return { ...state, invoiceLoading: true };
    });

    builder.addCase(manualSubscriptionInvoiceData.pending, state => {
      return { ...state, manualInvoiceLoading: true };
    });

    builder.addCase(subscriptionData.fulfilled, (state, action) => {
      return {
        ...state,
        subscription: action.payload,
        loading: false
      };
    });

    builder.addCase(subscriptionInvoiceData.rejected, state => {
      return {
        ...state,
        invoiceLoading: false
      };
    });

    builder.addCase(manualSubscriptionInvoiceData.rejected, state => {
      return {
        ...state,
        manualInvoiceLoading: false
      };
    });

    builder.addCase(subscriptionInvoiceData.fulfilled, (state, action) => {
      return {
        ...state,
        invoices: action.payload,
        invoiceLoading: false
      };
    });

    builder.addCase(manualSubscriptionInvoiceData.fulfilled, (state, action) => {
      return {
        ...state,
        manualInvoices: action.payload,
        manualInvoiceLoading: false
      };
    });

    builder.addCase(notificationData.pending, state => {
      return { ...state, loading: true };
    });

    builder.addCase(notificationData.fulfilled, (state, action) => {
      return {
        ...state,
        notification: action.payload,
        loading: false
      };
    });
  }
});

export const { openCompanyEdit, closeCompanyEdit, updatedNotifications } = settingsSlice.actions;

export default settingsSlice.reducer;
