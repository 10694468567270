import Modal from '@common/ui/Modal';
import { CreateUser, CreateUserFormik } from '../../../forms/Create';
import { FormSubmitButton } from '../../../buttons';

const User = () => {
  return (
    <CreateUserFormik>
      <Modal
        activeTitle="singleHighlight"
        navigation={['New user']}
        customPrimaryButton={<FormSubmitButton label="Create User" type="create" />}
        cancelButton={false}
      >
        <CreateUser />
      </Modal>
    </CreateUserFormik>
  );
};

export default User;
