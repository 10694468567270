export const helpLinks: { [key: string]: string } = {
  // ? inline links
  dashboard_0:
    'https://commerce-logic.com/knowledge/how-do-i-view-the-insights-dashboard#dashboard.insights?location=dashboard_0',
  dashboard_1:
    'https://commerce-logic.com/knowledge/how-do-i-view-the-gps-calculated-toll-dashboard#dashboard.gpscalculatedtolls?location=dashboard_1',
  dashboard_2:
    'https://commerce-logic.com/knowledge/how-do-i-view-the-actual-toll-cost-dashboard#dashboard.actualtollcost?location=dashboard_2',
  dashboard_3:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=dashboard_3',
  toll_bill_overview: 'https://commerce-logic.com/knowledge/toll-bill-overview',
  overcharges: 'https://commerce-logic.com/knowledge/overcharges',
  toll_insights: 'https://commerce-logic.com/knowledge/toll-insights',
  trip_insights: 'https://commerce-logic.com/knowledge/trip-insights',
  trip_value: 'https://commerce-logic.com/knowledge/trip-value',
  month_tolls: 'https://commerce-logic.com/knowledge/month-tolls',
  month_trips: 'https://commerce-logic.com/knowledge/month-trips',
  potential_savings: 'https://commerce-logic.com/knowledge/potential-savings ',
  recent_alerts: 'https://commerce-logic.com/knowledge/general#alerts',
  month_detail: 'https://commerce-logic.com/knowledge/month-detail',
  geotab_onboarding_0:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=geotab_onboarding_0',
  geotab_onboarding_1:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=geotab_onboarding_1',
  geotab_onboarding_2:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=geotab_onboarding_2',
  geotab_onboarding_3:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=geotab_onboarding_3',
  trip_details:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-for-a-trip#trips.details',
  trip_gps_calculated_tolls:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-for-a-trip#trips.GPScalculatedtolls',
  trip_map: 'https://commerce-logic.com/knowledge/how-do-i-view-more-details-for-a-trip#trips.map',
  vehicle_details:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-of-an-asset#fleet.details',
  vehicle_trips:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-of-an-asset#fleet.trips',
  vehicle_history:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-of-an-asset#fleet.history',
  vehicle_alerts:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-of-an-asset#fleet.alerts',
  transaction_details:
    'https://commerce-logic.com/knowledge/how-do-i-view-more-details-of-a-toll-transaction#tolltransaction.details',

  // ? all pages links
  dashboard:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=dashboard',
  trips:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=trips',
  fleet:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=fleet',
  toll_transactions:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=toll_transactions',
  toll_document_management:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=toll_document_management',
  users:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=users',
  customers:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=customers',
  profile:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=profile',
  additional_services:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=additional_services',
  geotab_onboarding:
    'https://commerce-logic.atlassian.net/wiki/spaces/CLDEV/pages/91422724/Product+Documentation3?location=geotab_onboarding'
};
