import { useEffect, useState } from 'react';
import { DropdownTypesProps } from '../types';
import { ContextMenuItemProps } from '../ContextMenu/types';

export const useDropdownSearch = (
  values: (string | number)[] | ContextMenuItemProps[],
  type: DropdownTypesProps
) => {
  const [searchValue, setSearchValue] = useState<string | number>('');
  const handleSearchValue = (value: string | number) => setSearchValue(value);

  const [searchResults, setSearchResults] = useState<
    (string | number)[] | ContextMenuItemProps[] | null
  >(null);

  useEffect(() => {
    if (searchValue) {
      setSearchResults(() => {
        if (type === 'select') {
          return (values as (string | number)[]).filter(item =>
            `${item}`.toLowerCase().includes(`${searchValue}`.toLowerCase().trim())
          );
        }
        if (type === 'context') {
          return (values as ContextMenuItemProps[]).filter((item: ContextMenuItemProps) =>
            item.text.toLowerCase().includes(`${searchValue}`.toLowerCase().trim())
          );
        }
        return [];
      });
    } else {
      setSearchResults(null);
    }
  }, [searchValue, values, type]);

  return { searchValue, searchResults, handleSearchValue };
};
