import { TripDataInterface } from '@common/types/data';
import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';

const UpdateTripFormik = ({ children, data }: ReactChildren & { data: TripDataInterface }) => {
  return (
    <Formik
      initialValues={data || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default UpdateTripFormik;
