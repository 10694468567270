import _ from 'lodash';
import { FC } from 'react';
import styles from './status.module.scss';
import { StatusProps } from './types';

const Status: FC<StatusProps & ReactChildren> = ({
  uppercase = false,
  status,
  label = status,
  children
}) => (
  <span
    aria-label="status"
    data-status-uppercase={uppercase}
    className={`${styles.container} ${styles[`container-${_.kebabCase(status)}`]}`}
  >
    {children || (label === 'archived' ? 'in archive' : label)}
  </span>
);

export default Status;
