export const VEHICLE_CLASSES = [
  'CLASS_1',
  'CLASS_2A',
  'CLASS_2B',
  'CLASS_3',
  'CLASS_4',
  'CLASS_5',
  'CLASS_6',
  'CLASS_7',
  'CLASS_8'
];

export const VEHICLE_TYPES = ['AUTO', 'TRUCK', 'PICKUP', 'TRAILER'];

export const VEHICLE_TAG_TYPES = ['TOLLTAG', 'EZPASS', 'KTAG', 'SUNPASS'];

export const VEHICLE_ISSUING_AGENCY = ['AGENCY_1', 'AGENCY_2', 'AGENCY_3'];
