import { FC } from 'react';
import { SelectInput } from '@common/ui/Form';
import { ExclamationMark1 } from '@assets/icons';
import { OptionsByTableProps } from './types';

const Customers: FC<OptionsByTableProps> = ({ ...props }) => {
  return (
    <SelectInput
      {...props}
      type="simple"
      label="Status"
      formField="statusCode"
      icon={<ExclamationMark1 />}
      values={['active', 'inactive', 'invite sent']}
      placeholder="Select Customer Status"
    />
  );
};

export default Customers;
