import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: SchemaOf<typeof initialValues> = Yup.object().shape({
  file: Yup.mixed<File>().default(null).required('file is required'),
  fileName: Yup.string().required('file name is required'),
  invoicingAgency: Yup.string().default(''),
  tollDocumentType: Yup.string().default('')
});
