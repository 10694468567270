/* eslint-disable */
import { Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';

const TableSkeleton = () => {
  const skeletonRows = Array(5)
    .fill(null)
    .map(() => (
      <tr key={uuid()}>
        {Array(5)
          .fill(null)
          .map(() => (
            <td key={uuid()}>
              <Skeleton variant="text" />
            </td>
          ))}
      </tr>
    ));

  return (
    <table>
      <thead>
        <tr>
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <th key={index}>
                <Skeleton variant="text" />
              </th>
            ))}
        </tr>
      </thead>
      <tbody>{skeletonRows}</tbody>
    </table>
  );
};

export default TableSkeleton;
