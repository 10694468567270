import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setFilters } from '@store/features/filters';
import Meta from '@common/components/meta';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
// import Help from '@components/help';
import { useSetTable } from './hooks';
import ViewVehicle from './modals/view/View';
import WebPortalTable from '../layouts/web-portal';
import GeotabTable from '../layouts/geotab-addin';

const Fleet = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const metaOptions = {
    title: 'Fleet',
    keywords: 'Fleet'
  };

  const name = ETableName.FLEET;
  useSetTable({ name });
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const crossFilterDate = {
      from: queryParams.get('from')?.trim() || '',
      to: queryParams.get('to')?.trim() || ''
    };
    if (crossFilterDate.from && crossFilterDate.to) {
      dispatch(
        setFilters({
          table: ETableName.FLEET,
          filters: {
            date: crossFilterDate
          }
        })
      );
    }
  }, [dispatch, location.search]);

  return (
    <>
      {/* <Help location="fleet" /> */}
      <Meta {...metaOptions} />
      {isGeotab ? (
        <GeotabTable title={name} tableName={name} ViewModal={ViewVehicle} />
      ) : (
        <WebPortalTable title="fleet" />
      )}
    </>
  );
};

export default Fleet;
