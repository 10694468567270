import Meta from '@components/meta';
import Card from './components/Card/Card';

const Errors = ({ code }: { code: number }) => {
  const metaOptions = {
    title: `Error ${code}`,
    keywords: `Error ${code}`
  };
  return (
    <>
      <Meta {...metaOptions} />
      <Card code={code} />
    </>
  );
};

export default Errors;
