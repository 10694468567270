/* eslint-disable import/no-cycle */
import { ILogin, ILogout, IRefreshToken, ISessionUser, IUserRolePerms } from '@api/types';
import {
  AUTH_BE_URL,
  baseApi,
  COGNITO_PASSWORD_ACTIONS_URL,
  COGNITO_USER_ACTIONS_URL,
  WEB_BE_URL
} from '../config';

export interface ICreatePassword {
  email: string;
  password: string;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    createPassword: builder.mutation<unknown, ICreatePassword>({
      queryFn: async (_args, _queryApi, _extraOptions, fetchWithBQ) => {
        const amplifyResponse = await fetchWithBQ({
          url: COGNITO_PASSWORD_ACTIONS_URL || '',
          method: 'post',
          body: _args,
          headers: {
            'Content-Type': 'application/json'
          },
          responseHandler: response => response.text()
        });
        if (amplifyResponse.error) {
          return { error: amplifyResponse.error };
        }
        return { data: amplifyResponse.data };
      }
    }),
    forgotPassword: builder.mutation<unknown, string>({
      query: email => ({
        url: COGNITO_PASSWORD_ACTIONS_URL || '',
        method: 'post',
        body: {
          email,
          type: 'sendPasswordResetCode'
        },
        responseHandler: response => response.text()
      })
    }),
    resetPassword: builder.mutation<unknown, { email: string; code: string; password: string }>({
      query: ({ email, code, password }) => ({
        url: COGNITO_PASSWORD_ACTIONS_URL || '',
        method: 'post',
        body: {
          email,
          code,
          password,
          type: 'confirmCodeAndResetPassword'
        },
        responseHandler: response => response.text()
      })
    }),
    getUserAttributes: builder.mutation<unknown, string>({
      query: email => ({
        url: COGNITO_USER_ACTIONS_URL || '',
        method: 'post',
        body: {
          email,
          type: 'getUsers'
        }
      })
    }),
    logIn: builder.mutation<ILogin, [string, string]>({
      query: ([email, password]) => ({
        url: `${AUTH_BE_URL}/signin`,
        method: 'post',
        body: {
          email,
          password
        }
      })
    }),
    getUserSession: builder.mutation<ISessionUser, { email: string; accessToken: string }>({
      query: ({ email, accessToken }) => ({
        url: `${AUTH_BE_URL}/checksession`,
        method: 'post',
        body: {
          email,
          token: accessToken
        }
      })
    }),
    refreshToken: builder.mutation<IRefreshToken, { email: string; accessToken: string }>({
      query: ({ email, accessToken }) => ({
        url: `${AUTH_BE_URL}/refresh`,
        method: 'post',
        body: {
          email,
          accessToken
        }
      })
    }),
    logOut: builder.mutation<ILogout, { email: string; accessToken: string }>({
      query: ({ email, accessToken }) => ({
        url: `${AUTH_BE_URL}/signout`,
        method: 'post',
        body: {
          email,
          accessToken
        }
      })
    }),
    getUserRolePerms: builder.mutation<IUserRolePerms, { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: `${WEB_BE_URL}/user-permission/all`,
        headers: {
          authorization: accessToken
        },
        method: 'get'
      })
    })
  })
});

export const {
  useCreatePasswordMutation,
  useGetUserAttributesMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogInMutation,
  useLogOutMutation,
  useGetUserSessionMutation,
  useRefreshTokenMutation,
  useGetUserRolePermsMutation
} = authApi;
