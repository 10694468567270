import { TransactionDataInterface } from '@common/types/data';

export const initialValues: Pick<
  TransactionDataInterface,
  | 'axles'
  | 'vehicleClass'
  | 'tollAgency'
  | 'plaza'
  | 'licensePlate'
  | 'facility'
  | 'tagTransponder'
  | 'toll'
> = {
  axles: 0,
  vehicleClass: '',
  tollAgency: '',
  plaza: '',
  licensePlate: '',
  facility: '',
  tagTransponder: '',
  toll: 0
};
