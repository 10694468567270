/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef, useState } from 'react';
import { useSSOContext } from '@pages/geotab/context/SSOContext';
import HtmlParse from 'html-react-parser';
import Footer from '../layout/footer/Footer';
import styles from './eula.module.scss';

const Eula = () => {
  const { gUser } = useSSOContext();
  const [isEULAFullyScrolled, setIsEULAFullyScrolled] = useState(false);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const bodyElement = bodyRef.current;

    if (bodyElement) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.target.nodeName === 'P') {
            return setIsEULAFullyScrolled(true);
          }
          return null;
        });
      });

      const lastParagraph = bodyElement.querySelector('p:last-child');
      if (lastParagraph) {
        observer.observe(lastParagraph);
      }

      return (): void => {
        observer.disconnect();
      };
    }
    return (): void => {
      // Do nothing or add any required cleanup logic
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>Terms of Service</div>
      <div className={styles.body} ref={bodyRef}>
        {HtmlParse(gUser.eulaContent || '')}
        <p />
      </div>
      <Footer isEULAFullyScrolled={!isEULAFullyScrolled} />
    </div>
  );
};

export default Eula;
