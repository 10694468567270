import Logo from '@common/ui/Logo';
import styles from './error.module.scss';

const SSOError = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <h2 className={styles.title}>Something went wrong!</h2>
        <div className={styles.text}>
          <h3>We’re sorry but something went wrong.</h3>
          <p>Please try reloading the screen or contact your administrator.</p>
        </div>
      </div>
      <footer>
        <button type="button" className={styles.reload} onClick={() => window.location.reload()}>
          Reload
        </button>
      </footer>
    </div>
  );
};

export default SSOError;
