import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@common/ui/Button';
import errorZero from '@assets/images/error-zero.svg';
import Logo from '@common/ui/Logo';
import { routes } from '@common/utils/route-names';
import styles from './card.module.scss';
import { errorsContent } from './utils';
import { IErrorCode, IError } from '../../types';

const Card: FC<IErrorCode> = ({ code }) => {
  const navigate = useNavigate();
  const [errorContent, setErrorContent] = useState<IError>(Object);

  useEffect(() => {
    const currentErrorContent = errorsContent.find(item => item.code === code);
    if (currentErrorContent) setErrorContent(currentErrorContent);
  }, [code]);

  return (
    <section className={styles.container}>
      <Logo className={styles.logo} />
      {code.toString()[1] === '0' ? (
        <h1>
          {code.toString()[0]} <img src={errorZero} alt="" /> {` ${code.toString()[2]}`}
        </h1>
      ) : (
        <h1 className={styles['no-zero']}>{code}</h1>
      )}
      <h2>{errorContent.title}</h2>
      <p>{errorContent.text}</p>
      {code === 403 && (
        <Button
          appearance="button"
          variant="primary"
          size="s"
          label="Go back"
          onClick={() => navigate(routes.dashboard)}
        />
      )}
      {code === 404 && (
        <Button
          appearance="button"
          variant="primary"
          size="s"
          label="Back to homepage"
          link={routes.dashboard}
        />
      )}
    </section>
  );
};

export default Card;
