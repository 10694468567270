/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useState } from 'react';
import { FilledCancel, Search } from '@assets/icons';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { initSearch } from '@store/features/search';
import { useAppDispatch } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import { SearchBarProps } from './types';
import styles from './search-bar.module.scss';

const SearchBar: FC<SearchBarProps> = ({
  collapsed = true,
  size = 's',
  className = '',
  stayOpen,
  autoComplete = true,
  searchValue = '',
  handleSearchValue,
  inDropdown,
  placeholder,
  showSearchIcon = true,
  clearIcon,
  type = 'text'
}) => {
  const [input, setInput] = useState<string | number>(searchValue);
  const [isOpen, setIsOpen] = useState(!collapsed);
  const [focus, setFocus] = useState(false);
  const collapsedStyle = stayOpen ? false : !isOpen;
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: false });
  const dispatch = useAppDispatch();
  const term = `${input}`;
  useEffect(() => {
    setInput(searchValue);
    if (!stayOpen) {
      setIsOpen(`${searchValue}`.length > 0);
      if (`${searchValue}`.length === 0) {
        setFocus(false);
      }
    }
  }, [searchValue, stayOpen]);

  useEffect(() => {
    const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
    if (crossFilterSource && (params.destination as ETableName)) {
      dispatch(
        initSearch({
          table: params.destination as ETableName,
          searchBy: (params.filter as string) || '',
          field:
            // eslint-disable-next-line no-nested-ternary
            params.destination === ETableName.FLEET
              ? 'name'
              : params.destination === ETableName.TRIPS
              ? 'vehicle name'
              : ''
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${styles.container} ${collapsedStyle ? styles.collapsed : ''} ${className}`}
      data-searchbar-size={size}
    >
      <input
        min={type === 'number' ? 0 : undefined}
        type={type}
        className={styles.input}
        data-search-icon={showSearchIcon}
        value={input}
        onChange={e => {
          setInput(e.currentTarget.value);
          if (inDropdown) handleSearchValue(e.currentTarget.value);
        }}
        onKeyDown={({ key, currentTarget }) => {
          if (key === 'Enter') {
            if (params['cross-filter']) navigate(location.pathname);
            handleSearchValue(currentTarget.value);
          }
        }}
        autoComplete={autoComplete ? 'on' : 'off'}
        onBlur={() => {
          if (term.length === 0 && !stayOpen) {
            setIsOpen(false);
            setFocus(false);
          }
        }}
        ref={el => focus && el?.focus()}
        placeholder={placeholder || ''}
        title={placeholder || ''}
      />
      {showSearchIcon && (
        <button
          type="button"
          data-button-action="search"
          className={term.length > 0 ? `${styles.search} ${styles.active}` : styles.search}
          onClick={() => {
            if (!isOpen && !stayOpen) {
              setIsOpen(true);
              setFocus(true);
            }
          }}
        >
          <Search />
        </button>
      )}
      {term.length > 0 && (
        <button
          type="button"
          data-button-action="clear"
          className={styles.clear}
          onClick={e => {
            e.stopPropagation();
            handleSearchValue('');
            setInput('');
            if (!stayOpen) setFocus(false);
            if (params['cross-filter']) navigate(location.pathname);
          }}
        >
          {clearIcon || <FilledCancel />}
        </button>
      )}
    </div>
  );
};

export default SearchBar;
