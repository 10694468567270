import Loader from '@common/ui/Loader';
import styles from './table-loading.module.scss';

const TableLoading = () => {
  return (
    <div className={styles.container}>
      <Loader className={styles.loader} />
    </div>
  );
};

export default TableLoading;
