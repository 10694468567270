import { useState } from 'react';
import { ChevronDown } from '@assets/icons';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './legend.module.scss';

const Legend = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      onClick={() => setIsOpen(prev => !prev)}
      type="button"
      className={`${styles.container} ${isOpen ? styles.open : ''}`}
    >
      <p className={styles.legend}>
        <span className={styles.icon}>
          <ChevronDown />
        </span>
        Legend
      </p>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={styles.details}
          >
            <p className={styles.suggested}>
              <span className={styles.highlight} />
              <span className={styles.text}>Actual trip</span>
            </p>
            <p className={styles.alternative}>
              <span className={styles.highlight} />
              <span className={styles.text}>Alternative trip</span>
            </p>
            <p className={styles.tolls}>
              <span className={styles.highlight} />
              <span className={styles.text}>Tolls</span>
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

export default Legend;
