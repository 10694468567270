import Meta from '@common/components/meta';
import { ETableName } from '@common/utils/enums';
// import Help from '@components/help';
import { useSetTable } from './hooks';
import WebPortalTable from '../layouts/web-portal';

const TollDocuments = () => {
  const metaOptions = {
    title: 'Toll Document Management',
    keywords: 'Toll Document Management'
  };
  const name = ETableName.TOLLDOCUMENTS;
  useSetTable({ name });

  return (
    <>
      <Meta {...metaOptions} />
      {/* <Help location="toll_document_management" /> */}
      <WebPortalTable title="Toll Document Management" />
    </>
  );
};

export default TollDocuments;
