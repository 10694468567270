import { setCurrentPage } from '@store/features/pagination';
import { initSearch } from '@store/features/search';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
// import _ from 'lodash';

export const useColumnSearch = () => {
  const tableName = useAppSelector(state => state.table.name) as ETableName;
  const searchValue = useAppSelector(state => state.search[tableName]);
  const dispatch = useAppDispatch();

  const handleSearchValue = (term: string | number, field: string) => {
    // // eslint-disable-next-line no-param-reassign
    // term =
    //   // eslint-disable-next-line no-nested-ternary
    //   field && field === 'toll' && term
    //     ? _.isInteger(Number(term))
    //       ? Number(term).toFixed(1)
    //       : Number(term)
    //     : term;
    dispatch(
      initSearch({
        table: tableName,
        field,
        searchBy: term
      })
    );
    dispatch(
      setCurrentPage({
        table: tableName,
        currentPage: 1
      })
    );
  };
  return { searchValue, handleSearchValue };
};
