import { FC, ReactHTMLElement } from 'react';
import { PopperControlProps } from './types';
import styles from './popper-control.module.scss';

const PopperControl: FC<Partial<ReactHTMLElement<HTMLDivElement>> & PopperControlProps> = ({
  children,
  handleOpenState,
  handleReferenceElement,
  ...props
}) => {
  return (
    <div
      {...props}
      ref={handleReferenceElement}
      className={`${styles.container}`}
      role="button"
      tabIndex={0}
      onClick={!props.disabled ? handleOpenState : () => {}}
      onKeyDown={!props.disabled ? handleOpenState : () => {}}
    >
      {children}
    </div>
  );
};

export default PopperControl;
