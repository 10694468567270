import Dropdown from '@common/ui/Dropdown';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@store/hooks';
import { useMenuValues } from './hooks/useMenuValues';
import styles from './customers-menu.module.scss';
import ChooseCustomer from './ChooseCustomer/ChooseCustomer';

export const CustomersMenu = ({ navCollapse }: { navCollapse: boolean }) => {
  const values = useMenuValues();
  const menuVisible: boolean | undefined = useAppSelector(state => state.customer.isVisible);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(menuVisible || false);
  const handleDropdownOpenState = (value: boolean) => setDropdownIsOpen(value);

  useEffect(() => {
    setDropdownIsOpen(menuVisible || false);
  }, [menuVisible]);

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      includeSearch
      type="context"
      handleOpenState={handleDropdownOpenState}
      values={values}
      className={styles.dropdown}
      dropdownItemClassName={styles['dropdown-item']}
      position="right-start"
      offset={[10, -5]}
    >
      <ChooseCustomer navCollapse={navCollapse} />
    </Dropdown>
  );
};
