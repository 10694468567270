import { Formik } from 'formik';
import { useState } from 'react';
import { ICreateVehicle } from '@api/services/fleet';
import { useHandleSubmit } from './hooks/useHandleSubmit';
import { validationSchema } from './utils/validationSchema';

export const CreateVehicleFormik = ({ children }: ReactChildren) => {
  const { handleSubmit } = useHandleSubmit();
  const [initialValues] = useState<ICreateVehicle>({
    isTouched: false,
    name: '',
    vehicleClass: '',
    vin: '',
    type: '',
    weight: null,
    height: null,
    axles: null,
    tagType: 'Third Party',
    tagTypeId: 'THIRDPARTY',
    tagTransponder: '',
    plateState: '',
    plateNumber: '',
    tollConsolidator: true,
    issuingAgencyId: 162,
    agencyName: 'CommerceLogic',
    customerId: '',
    childTags: null,
    deviceType: 2
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};
