/* eslint-disable */
import { RoleDataInterface } from '@common/types/data';
import { baseApi } from '../config';
import { store } from '@store/index';
import _ from 'lodash';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';

export const rolesApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Roles', 'Role']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getRoles: builder.query<RoleDataInterface[], string>({
        query: () => `${window.location.origin}/data/placeholderData.json`,
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue?.roles;
          }
        },
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'Roles' as const, id })),
                { type: 'Roles', id: 'LIST' }
              ]
            : [{ type: 'Roles', id: 'LIST' }]
      })
    })
  });

export const { useGetRolesQuery } = rolesApi;
