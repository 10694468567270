import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import SearchBar from '@common/ui/SearchBar';
import { useTableSearch } from './hooks';

const TableSearch = () => {
  const table = useAppSelector(state => state.table);
  const search = useAppSelector(state => state.search[table.name as ETableName]);
  const handleSearchData = useTableSearch();
  const placeholders: Partial<{ [key: string]: string }> = {
    trips: 'Vehicle name, license plate and trip id',
    transactions: 'Search toll agency name and plaza',
    fleet: 'Search name, vin and license plate',
    toll_documents: 'Search name',
    customers: 'Search company name',
    users: 'Search name and email address'
  };

  return (
    <SearchBar
      searchValue={search?.by || ''}
      placeholder={placeholders[table.name as ETableName] || ''}
      handleSearchValue={handleSearchData}
    />
  );
};

export default TableSearch;
