/* eslint-disable */
import { useAppDispatch, useAppSelector } from '@store/hooks';
import styles from './billing.module.scss';

const BillDetails = () => {
  const dispatch = useAppDispatch();
  const subscribedVehicle = useAppSelector(state => state.subscribe.subscribedVehicle);
  const alreadySubscribedVehicle = useAppSelector(
    state => state.subscribe.previouslySubscribedVehicle
  );

  const totalSubscribedVehicle = subscribedVehicle.length + alreadySubscribedVehicle;
  const billSummary = useAppSelector(state => state.subscribe.billSummary);
  const withoutTaxTotal = totalSubscribedVehicle * billSummary?.monthly_price_per_vehicle;
  const totalTaxAmount = billSummary?.estimated_tax.reduce(
    (a: any, b: any) =>
      Number(a) +
      (withoutTaxTotal * (Number(b.tax_percentage) / 100) + Number(b.fixed_additional_amount)),
    0
  );

  const totalAmount = withoutTaxTotal + totalTaxAmount;

  return (
    <div className={styles.rightComponent}>
      <div className={styles.summary}>
        <div className={styles['summary-wrapper']}>
          <h2 className={styles['summary-heading']}>Billing Summary</h2>
          <div className={styles['summary-body']}>
            <>
              <ul className={styles['subscribed-vehicles']}>
                <li>
                  <p>
                    <span>Subscribed Vehicle{`${subscribedVehicle.length > 1 ? 's' : ''}`}</span>
                  </p>
                  <p>{subscribedVehicle.length}</p>
                </li>
                <li>
                  <p>
                    <span>
                      Total Subscribed Vehicle{`${totalSubscribedVehicle > 1 ? 's' : ''}`}
                    </span>
                  </p>
                  <p>{totalSubscribedVehicle}</p>
                </li>
              </ul>
              <ul className={styles['monthly-rate']}>
                <li>
                  <p>
                    <span>Monthly rate per vehicle</span>
                  </p>
                  <p>$ {billSummary?.monthly_price_per_vehicle?.toFixed(2)}</p>
                </li>
              </ul>
              <ul className={styles['total-before-tax']}>
                <li>
                  <p>
                    <span>Total before tax</span>
                    {/* <span
                      title={`Estimated tax of $${Number(totalTaxAmount).toFixed(2)} will be added`}
                      className={styles['tax-value']}
                    >
                      {`+ $ ${Number(totalTaxAmount).toFixed(2)}`}
                    </span> */}
                  </p>
                  <p>$ {withoutTaxTotal?.toFixed(2)}</p>
                </li>
                <li>
                  <p>
                    <span>Estimated tax</span>
                  </p>
                  <p>$ {totalTaxAmount?.toFixed(2)}</p>
                </li>
              </ul>
              <ul className={styles['total']}>
                <li>
                  <p>
                    <span>Total</span>
                  </p>
                  <p>$ {totalAmount?.toFixed(2)}</p>
                </li>
              </ul>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDetails;
