import { TripDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<TripDataInterface>[] = [
  {
    heading: 'vehicle name',
    field: 'vehicleName',
    active: true,
    filterType: 'text',
    placeholder: 'search name'
  },
  // {
  //   heading: 'license plate state',
  //   field: 'plateState',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate state'
  // },
  // {
  //   heading: 'license plate number',
  //   field: 'plateNumber',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate number'
  // },
  {
    heading: 'license plate',
    field: 'licensePlate',
    active: true,
    filterType: 'text',
    placeholder: 'search license plate'
  },
  {
    heading: 'start point',
    field: 'startAddress',
    active: true,
    filterType: 'text',
    placeholder: 'search start point'
  },
  {
    heading: 'end point',
    field: 'endAddress',
    active: true,
    filterType: 'text',
    placeholder: 'search end point'
  },
  {
    heading: 'start date',
    field: 'startDateTime',
    active: true,
    filterType: 'date',
    placeholder: 'search start date'
  },
  {
    heading: 'end date',
    field: 'stopDateTime',
    active: false,
    filterType: 'date',
    placeholder: 'search end date'
  },
  {
    heading: 'trip distance',
    field: 'distanceD',
    active: true,
    filterType: 'number',
    placeholder: 'search distance'
  },
  {
    heading: 'trip duration',
    field: 'timeSpent',
    active: true,
    filterType: 'duration'
  },
  {
    heading: 'GPS Calculated Tolls',
    field: 'moneySpent',
    active: true,
    filterType: 'number',
    placeholder: 'search gps tolls'
  },
  {
    heading: 'vehicle axles',
    field: 'axles',
    active: false,
    filterType: 'number',
    placeholder: 'search vehicle axles'
  },
  {
    heading: 'tag type',
    field: 'tagType',
    active: false,
    filterType: 'text',
    placeholder: 'search tag type'
  },
  {
    heading: 'Trip Id',
    field: 'id',
    active: false,
    filterType: 'text',
    placeholder: 'search trip id'
  },
  {
    heading: 'GPS Reliability',
    field: 'gpsReliability',
    active: false,
    filterType: 'text',
    placeholder: 'search gps reliability'
  }
];
