/* eslint-disable */
// import { DATE_FIELDS_ARRAY } from '@common/utils/constants';
import dayjs from 'dayjs';
import { formatFieldData } from '@common/services/format';
import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import _ from 'lodash';
import { UnionDataInterface } from '@common/types/data';
import styles from './empty.module.scss';

const Empty = ({
  by,
  field,
  dateRange
}: {
  by: string | number;
  field: string;
  dateRange?: { to?: string; from?: string } | null;
}) => {
  const table = useAppSelector(state => state.table);
  const headings = useAppSelector(state => state.headings[table.name as ETableName]);
  const isMetric = useAppSelector((state: any) => state.auth.isMetric);
  const timeZone = useAppSelector((state: any) => state.auth.timeZoneId);

  const rangeActive = dateRange && dateRange.to && dateRange.from;
  const value = formatFieldData(
    by,
    field,
    table.name as ETableName,
    isMetric,
    timeZone,
    [...headings].find(column => _.kebabCase(column.heading) === _.kebabCase(field))
      ?.field as keyof UnionDataInterface
  );
  return (
    <div className={styles.container}>
      No results {(field || rangeActive) && 'for'}
      {field ? <span>&quot;{value as any}&quot;</span> : ''}
      {field && ' in '} {field && <span>&quot;{field}&quot;</span>}
      {field && rangeActive && ' between '}
      {rangeActive ? (
        <span>
          &quot;{dayjs(dateRange.from as string).format('MM/DD/YYYY')} -{' '}
          {dayjs(dateRange.to).format('MM/DD/YYYY')}&quot;
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default Empty;
