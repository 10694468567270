import { Approve } from '@assets/icons';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button';
import { clearSearch } from '@store/features/search';
import { useNavigate, useLocation } from 'react-router-dom';

const ClearPageFilters = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tableName = useAppSelector(state => state.table.name as ETableName);
  return (
    <Button
      label="Done"
      icon={<Approve />}
      appearance="button"
      variant="primary"
      size="s"
      onClick={() => {
        dispatch(clearSearch(tableName));
        navigate(location.pathname);
      }}
    />
  );
};

export default ClearPageFilters;
