/* eslint-disable */
import { useSSOContext } from '@pages/geotab/context/SSOContext';
import { useVehicleFileActions } from '@pages/geotab/hooks/useVehicleFileActions';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import Modal from '@common/ui/Modal/Modal';
import { v4 as uuid } from 'uuid';
import { AnimatePresence } from 'framer-motion';
import MotionDiv from '@pages/tables/layouts/geotab-addin/components/motion-div';
import Checkbox from '@common/ui/Checkbox/Checkbox';
import { IGeotabSuggestion } from '@pages/geotab/types';
import styles from './action-block.module.scss';
import SelectInput from '@common/ui/FilterDropdown/SelectInput';
import { ImportActionProps } from './types';
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri';

const ActionBlock: FC<ImportActionProps> = ({ setImportAction }) => {
  const { gVehicles, initialVehicles, handleVehicleDetails } = useSSOContext();
  const formik = useFormikContext<any>();
  const suggestionTableHeaders = ['Vehicle Name', 'Suggestions', 'Action'];
  const {
    isExported,
    exportVehicles,
    startImport,
    hasSuggestion,
    vehicles,
    suggestionList,
    isImported,
    importVehicle
  } = useVehicleFileActions(gVehicles, handleVehicleDetails, initialVehicles);
  const [accordion, setAccordion] = useState(false);
  const [suggestionModal, setSuggestionModal] = useState(false);
  const [suggestions, setSuggestions] = useState<IGeotabSuggestion[]>([]);
  const suggestionAction = (index: number) => {
    if (suggestionList.length > 0) {
      suggestions[index].isAccepted = !suggestions[index].isAccepted;
      setSuggestions((data: IGeotabSuggestion[]) => [...data]);
    }
  };
  const setParsedTagType = (index: number, value?: string | number | (string | number)[]) => {
    if (value) {
      suggestionList[index].parsedTagType = value as string;
      setSuggestions((data: IGeotabSuggestion[]) => [...data]);
    }
  };
  const acceptSuggestions = () => {
    const systemVehicles = [...vehicles];

    suggestions.forEach(suggestion => {
      if (suggestion.isAccepted) {
        const foundVehicle = systemVehicles.find(
          x =>
            x['VEHICLE NAME']?.toLowerCase()?.trim() ===
            suggestion.vehicleName?.toLowerCase()?.trim()
        );
        if (foundVehicle) {
          if (suggestion.parsedIssuingAgency) {
            foundVehicle['TAG ISSUING AGENCY'] = suggestion.parsedIssuingAgency;
          }
          if (suggestion.parsedTagType || suggestion.parsedTagTypeList) {
            foundVehicle['TAG TYPE'] = suggestion.parsedTagType
              ? suggestion.parsedTagType
              : suggestion.parsedTagTypeList
              ? suggestion.parsedTagTypeList[0]
              : '';
          }
        }
      }
    });
    formik.setFieldValue('vehicles', systemVehicles);
    setSuggestionModal(false);
  };
  useEffect(() => {
    setImportAction(isImported);
  }, [isImported]);

  useEffect(() => {
    setSuggestionModal(hasSuggestion || false);
    setSuggestions(suggestionList);
  }, [hasSuggestion]);
  return (
    <div className={styles['action-block']}>
      <div className={styles.accordion}>
        {!accordion ? (
          <div className={styles.help} onClick={() => setAccordion(true)}>
            <RiArrowRightSLine size={20} />
            <h1>Help</h1>
          </div>
        ) : (
          <>
            <div className={styles.help} onClick={() => setAccordion(false)}>
              <RiArrowDownSLine size={20} />
              <h1>Help</h1>
            </div>
            <div className={styles.line}>
              <p className={styles['help-text']}>
                If you do not have all information on hand, you can enter vehicle details after
                completing the onboarding.
                <br />
                <br />
                For fleets of 30+ vehicles, we recommend entering asset details by downloading the
                spreadsheet template. You can upload the template to this screen when ready.
              </p>
            </div>
          </>
        )}
      </div>
      <div className={styles.container}>
        <button
          type="button"
          disabled={isExported}
          className={styles.export}
          onClick={() => exportVehicles()}
        >
          Download Template
        </button>
        <input
          type="file"
          onChange={event => startImport(event, formik)}
          accept=".csv"
          hidden
          id="cl-import"
        />
        <button type="button" onClick={() => importVehicle()} className={styles.import}>
          Upload CSV
        </button>
        {hasSuggestion && (
          <button type="button" onClick={() => setSuggestionModal(true)} className={styles.import}>
            Show Suggestions
          </button>
        )}
        {suggestionModal && (
          <Modal
            className={styles.modal}
            title="Parsed Issuing Agency Suggestions"
            primaryButton
            customCloseFunc={() => setSuggestionModal(false)}
            onActionClick={() => acceptSuggestions()}
            hasFooter={suggestions.findIndex(x => x.isAccepted) !== -1}
            // hasHeader={false}
            // hasFooter={false}
          >
            <AnimatePresence exitBeforeEnter initial={false}>
              <MotionDiv activeTitle="activeTitle" prevTitle="prevTitle">
                <table className={styles['modal-content']}>
                  <tr className={styles['table-header']}>
                    {suggestionTableHeaders.map(header => (
                      <th className={styles[`header-${header}`]}>{header}</th>
                    ))}
                  </tr>
                  {suggestions.map(
                    (suggestionItem: IGeotabSuggestion, suggestionItemIndex: number) => (
                      <tr className={styles['table-body']} key={uuid()}>
                        <td className={styles[`table-body`]} key={uuid()}>
                          <span className={styles['vehicle-name']}>
                            {suggestionItem.vehicleName}
                          </span>
                        </td>
                        <td className={styles[`table-body`]} key={uuid()}>
                          <table className={styles['suggestion-table']}>
                            <tr className={styles['parsed-headers']}>
                              <td>Parsed field name</td>
                              <td>From</td>
                              <td>To</td>
                            </tr>
                            {suggestionItem.parsedIssuingAgency && (
                              <tr>
                                <td> Issuing Agency</td>
                                <td>
                                  <span className={styles[`table-col-old-data`]}>
                                    {suggestionItem?.issuingAgency || '-'}
                                  </span>
                                </td>
                                {/* <td>
                              <span className={styles['table-col-arrow']}>
                                <ArrowRight />
                              </span>
                            </td> */}
                                <td>
                                  <span className={styles[`table-col-new-data`]}>
                                    {suggestionItem.parsedIssuingAgency}
                                  </span>
                                </td>
                              </tr>
                            )}
                            {suggestionItem.parsedTagTypeList && (
                              <tr>
                                <td> Tag Type</td>
                                <td>
                                  <span className={styles[`table-col-old-data`]}>
                                    {suggestionItem?.tagType || '-'}
                                  </span>
                                </td>
                                {/* <td>
                                <span className={styles['table-col-arrow']}>
                                  <ArrowRight />
                                </span>
                              </td> */}
                                <td>
                                  {suggestionItem.parsedTagTypeList.length > 0 ? (
                                    <SelectInput
                                      className={styles['select-suggestion']}
                                      values={suggestionItem.parsedTagTypeList}
                                      selection={
                                        suggestionItem.parsedTagType ||
                                        suggestionItem.parsedTagTypeList[0]
                                      }
                                      clearFilterSelection={() => {}}
                                      type="simple"
                                      handleChange={data =>
                                        setParsedTagType(suggestionItemIndex, data)
                                      }
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </td>
                              </tr>
                            )}
                          </table>
                        </td>
                        <td className={styles[`table-body`]} key={uuid()}>
                          <Checkbox
                            className={styles['chk-box-action']}
                            state={suggestionItem.isAccepted ? 'checked' : 'unchecked'}
                            onClick={() => suggestionAction(suggestionItemIndex)}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </MotionDiv>
            </AnimatePresence>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ActionBlock;
