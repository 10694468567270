export function ChevronDown({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 9.16997C16.8126 8.98372 16.5591 8.87918 16.295 8.87918C16.0308 8.87918 15.7773 8.98372 15.59 9.16997L11.9999 12.71L8.45995 9.16997C8.27259 8.98372 8.01913 8.87918 7.75495 8.87918C7.49076 8.87918 7.23731 8.98372 7.04995 9.16997C6.95622 9.26294 6.88183 9.37354 6.83106 9.4954C6.78029 9.61726 6.75415 9.74796 6.75415 9.87997C6.75415 10.012 6.78029 10.1427 6.83106 10.2645C6.88183 10.3864 6.95622 10.497 7.04995 10.59L11.2899 14.83C11.3829 14.9237 11.4935 14.9981 11.6154 15.0489C11.7372 15.0996 11.8679 15.1258 11.9999 15.1258C12.132 15.1258 12.2627 15.0996 12.3845 15.0489C12.5064 14.9981 12.617 14.9237 12.7099 14.83L17 10.59C17.0937 10.497 17.1681 10.3864 17.2188 10.2645C17.2696 10.1427 17.2958 10.012 17.2958 9.87997C17.2958 9.74796 17.2696 9.61726 17.2188 9.4954C17.1681 9.37354 17.0937 9.26294 17 9.16997Z" />
    </svg>
  );
}
