/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { useAppSelector } from '@store/hooks';
import { IApiResponse, IDate, ITollInsights, ILineChartData } from '../types';
import { getOrdinalSuffix } from '@pages/dashboard/common/helper';

const useTollInsightsData = (
  selectedMonth: string
): {
  tollTransactionCostData: ILineChartData | undefined;
  tollTransactionCountData: ILineChartData | undefined;
  averageTollPerHourData: ILineChartData | undefined;
  averageTollPerMileData: ILineChartData | undefined;

  isLoading: boolean;
  showError: boolean;
  aggData?: any;
  fetchTollInsightsData: () => Promise<void>;
} => {
  const [tollTransactionCostData, setTollTransactionCostData] = useState<ILineChartData>();
  const [tollTransactionCountData, setTollTransactionCountData] = useState<ILineChartData>();
  const [averageTollPerHourData, setAverageTollPerHourData] = useState<ILineChartData>();
  const [averageTollPerMileData, setAverageTollPerMileData] = useState<ILineChartData>();

  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);

  const transformDataForLineChart = (data: ITollInsights[], dateRange: IDate[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.month_date).getTime() - new Date(b.month_date).getTime());

    let labels;
    if (selectedMonth.length > 0) {
      // labels = sortedData.map(item => `${getOrdinalSuffix(item.month_date)} - ${getOrdinalSuffix(item.end_date)}`);
      labels = sortedData.map((item, index) => `Week ${index + 1}`);
    } else {
      labels = dateRange.map(item => moment.utc(item.month_date).format('MMM'));
    }
    const tollTransactionCostchartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: '',
          data: sortedData.map(item => Math.floor(item.tollbill_toll_amount)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#B44A2D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#B44A2D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: sortedData.reduce((acc, item) => acc + Math.floor(item.tollbill_toll_amount), 0)
        },
        {
          label: '',
          data: sortedData.map(item => Math.floor(item.tollogic_toll_amount)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#004C6D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#004C6D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: sortedData.reduce((acc, item) => acc + Math.floor(item.tollogic_toll_amount), 0)
        }
      ]
    };
    const tollTransactionCountchartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: '',
          data: sortedData.map(item => Math.floor(item.tollbill_toll_count)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#B44A2D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#B44A2D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: sortedData.reduce((acc, item) => acc + Math.floor(item.tollbill_toll_count), 0)
        },
        {
          label: '',
          data: sortedData.map(item => Math.floor(item.tollogic_toll_count)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#004C6D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#004C6D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: sortedData.reduce((acc, item) => acc + Math.floor(item.tollogic_toll_count), 0)
        }
      ]
    };
    const averageTollPerHourchartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: '',
          data: sortedData.map(item => Number(item.tollbill_avgtoll_per_hour).toFixed(2)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#B44A2D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#B44A2D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: (
            sortedData.reduce((acc, item) => acc + Number(item.tollbill_avgtoll_per_hour), 0) /
            labels.length
          ).toFixed(2)
        },
        {
          label: '',
          data: sortedData.map(item => Number(item.tollogic_avgtoll_per_hour).toFixed(2)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#004C6D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#004C6D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: (
            sortedData.reduce((acc, item) => acc + Number(item.tollogic_avgtoll_per_hour), 0) /
            labels.length
          ).toFixed(2)
        }
      ]
    };
    const averageTollPerMilechartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: '',
          data: sortedData.map(item => Number(item.tollbill_avgtoll_per_mile).toFixed(2)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#B44A2D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#B44A2D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: (
            sortedData.reduce((acc, item) => acc + Number(item.tollbill_avgtoll_per_mile), 0) /
            labels.length
          ).toFixed(2)
        },
        {
          label: '',
          data: sortedData.map(item => Number(item.tollogic_avgtoll_per_mile).toFixed(2)),
          backgroundColor: (context: any) => getColor(context, '#ffffff', '#004C6D'),
          borderColor: (context: any) => getColor(context, '#cbcbcb', '#004C6D'),
          hoverBorderWidth: 2.1,
          tension: 0,
          borderWidth: 2.1,
          total: (
            sortedData.reduce((acc, item) => acc + Number(item.tollogic_avgtoll_per_mile), 0) /
            labels.length
          ).toFixed(2)
        }
      ]
    };

    setTollTransactionCostData(tollTransactionCostchartData);
    setTollTransactionCountData(tollTransactionCountchartData);
    setAverageTollPerHourData(averageTollPerHourchartData);
    setAverageTollPerMileData(averageTollPerMilechartData);

    function getColor(context: any, colorifZero: string, colorifNonZero: string): string {
      let index = context.dataIndex;
      let value: any = context.dataset.data[index];
      return value == 0 ? colorifZero : colorifNonZero;
    }
  };

  const fetchTollInsightsData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/insights/toll-insights', {
        moduleId: selectedMonth.length > 0 ? 'MONTHLY_DETAIL' : 'INSIGHTS',
        monthDate: selectedMonth
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForLineChart(response.data.data, response.data.dateRange);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling TollInsights data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTollInsightsData().catch(err => {
      console.log('Error in TollInsights data ==> ', err);
    });
  }, [customerId, selectedMonth]);

  return {
    tollTransactionCostData,
    tollTransactionCountData,
    averageTollPerHourData,
    averageTollPerMileData,
    isLoading,
    showError,
    fetchTollInsightsData
  };
};

export default useTollInsightsData;
