import { Outlet } from 'react-router-dom';
import {
  AddNewModal,
  ViewTollDocumentModal
} from '@pages/tables/layouts/web-portal/components/modals';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import Navigation from './components/Navigation';
import styles from './main.module.scss';
import GeotabNavigation from './components/GeotabNavigation';

const MainLayout = () => {
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <section className={`${styles.container} ${isGeotab ? styles['is-geotab'] : ''}`}>
      {isGeotab ? <GeotabNavigation /> : <Navigation />}
      <main className={styles.main}>
        <Outlet />
      </main>
      <AddNewModal />
      <ViewTollDocumentModal />
    </section>
  );
};

export default MainLayout;
