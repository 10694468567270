import { FC } from 'react';
import { FilledCancel, ChevronDown } from '@assets/icons';
import styles from './select-input-icons.module.scss';
import { SelectInputIconsProps } from './types';

const SelectInputIcons: FC<SelectInputIconsProps> = ({
  selection,
  handleSelectionState,
  isOpen
}) => {
  const isTags = Array.isArray(selection);

  return (
    <div aria-label="select-display-icons" className={styles.container}>
      {isTags && selection.length !== 0 && (
        <button
          aria-label="select-input-clear"
          className={styles.clear}
          onClick={() => {
            (handleSelectionState as (value: string[]) => void)([]);
          }}
          type="button"
          title={`${selection.length} selected`}
        >
          <FilledCancel />
        </button>
      )}
      <ChevronDown className={isOpen ? `${styles.arrow} ${styles['arrow-open']}` : styles.icon} />
    </div>
  );
};

export default SelectInputIcons;
