import { useAppSelector } from '@store/hooks';
import { EAuthRole, ETableName } from '@common/utils/enums';
import { FC } from 'react';
import Basic from './Basic';

const EmptyView: FC<{ tableType: ETableName }> = ({ tableType }) => {
  const auth = useAppSelector(state => state.auth);
  const customerId = useAppSelector(state => state.customer.id);
  const isEmptyCustomer =
    auth.app_role === EAuthRole.SUPER_ADMIN && !customerId && tableType !== ETableName.CUSTOMERS;
  return <Basic tableType={tableType} isEmptyCustomer={isEmptyCustomer} />;
};

export default EmptyView;
