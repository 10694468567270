import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isActualTollTab: true,
  isNavCollapse: true
};

export const helpersSlice = createSlice({
  name: 'helpers',
  initialState,
  reducers: {
    setTransactionTab: (state, action: PayloadAction<boolean>) => {
      return { ...state, isActualTollTab: action.payload };
    },
    setNavCollapsed: (state, action: PayloadAction<boolean>) => {
      return { ...state, isNavCollapse: action.payload };
    }
  }
});

export const { setTransactionTab, setNavCollapsed } = helpersSlice.actions;

export default helpersSlice.reducer;
