/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETableName } from '@common/utils/enums';
import { TableSortingOrderType } from '@common/types';
import Cookies from 'js-cookie';
import { cookieSort } from '@common/services/auth';

interface ISort {
  active: boolean;
  by: string | null;
  order: TableSortingOrderType | null;
}

const initialTableState: ISort = {
  active: false,
  by: null,
  order: null
};

export const initialState: { [key in ETableName]: ISort } = {
  fleet: initialTableState,
  customers: initialTableState,
  roles: initialTableState,
  trailers: initialTableState,
  transactions: initialTableState,
  trips: initialTableState,
  users: initialTableState,
  toll_documents: { active: true, by: 'uploaded_date', order: 'desc' }
};

const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    enableSorting: (state, action: PayloadAction<{ table: ETableName; sortBy: string }>) => {
      const sort = state[action.payload.table];
      if (sort.order === null || sort.by !== action.payload.sortBy) {
        sort.order = 'asc';
        sort.active = true;
        sort.by = action.payload.sortBy;
      } else if (sort.order === 'asc') {
        sort.active = true;
        sort.by = action.payload.sortBy;
        sort.order = 'desc';
      } else if (sort.order === 'desc') {
        if (action.payload.table !== ETableName.TOLLDOCUMENTS) {
          sort.active = false;
          sort.by = null;
          sort.order = null;
        } else {
          sort.active = true;
          sort.by = 'uploaded_date';
          sort.order = 'asc';
        }
      }
      Cookies.set(cookieSort, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    disableSorting: (state, action: PayloadAction<{ table: ETableName }>) => {
      const sort = state[action.payload.table];
      if (action.payload.table !== ETableName.TOLLDOCUMENTS) {
        sort.active = false;
        sort.by = null;
        sort.order = null;
      } else {
        sort.active = true;
        sort.by = 'uploaded_date';
        sort.order = 'desc';
      }
      Cookies.set(cookieSort, JSON.stringify(state), { sameSite: 'None', secure: true });
    },
    clearAllSorting: state => {
      Cookies.set(cookieSort, JSON.stringify(state), { sameSite: 'None', secure: true });
      return initialState;
    },
    setSessionSort: (state, action) => {
      state = JSON.parse(action.payload);
      return state;
    }
  }
});

export const { enableSorting, disableSorting, clearAllSorting, setSessionSort } = sortSlice.actions;

export default sortSlice.reducer;
