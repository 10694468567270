import Button from '@common/ui/Button';
import Modal from '@common/ui/Modal';
import { useAppSelector } from '@store/hooks';
import { useParams } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import tollDocumentImage from '@assets/images/enlarge-toll-document-image.png';
import styles from './view-toll-document.module.scss';

const ViewTollDocument = () => {
  const { tollDocumentNumber } = useParams();
  const { activeModal: modal, id: modalId } = useAppSelector(state => state.modal);
  return (
    <AnimatePresence exitBeforeEnter>
      {modal === 'view' && modalId === 'toll_document' && (
        <Modal
          title={`#${tollDocumentNumber || ''}`}
          cancelButton={false}
          customSecondaryButton={
            <a download href={tollDocumentImage}>
              <Button appearance="button" variant="tertiary" label="Download" size="xs" />
            </a>
          }
          className={styles.modal}
        >
          <div className={styles.container}>
            <picture className={styles['toll-document']}>
              <img src={tollDocumentImage} alt="toll-document" />
            </picture>
          </div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ViewTollDocument;
