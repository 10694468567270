/* eslint-disable */
import styles from './billing.module.scss';
import BillTable from './billTable';
import BillDetails from './billDetails';

const Billing = ({ subscribedVehicle }: any) => {
  return (
    <div className={styles.container}>
      <BillTable subscribedVehicle={subscribedVehicle} />
      <div className={styles.divider} />
      <BillDetails />
    </div>
  );
};

export default Billing;
