import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Loader from '@common/ui/Loader';
import { manualSubscriptionInvoiceData } from '@store/data';
import Button from '@common/ui/Button';
import { Export } from '@assets/icons';
import styles from './subscription.module.scss';
import SelectCustomer from '../selectCustomer';
import { useSubscriptionExport } from './exportHook';
import ManualInvoices from './ManualInvoices';

const Subscription = () => {
  const { subscriptionExport } = useSubscriptionExport();
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);
  const loading = useAppSelector(state => state.settings.loading);
  const subscription = useAppSelector(state => state.settings.subscription);
  // useEffect(() => {
  //   if (customerId) {
  //     dispatch(subscriptionInvoiceData()).catch(err =>
  //       console.log('error in fetching invoices ', err)
  //     );
  //   }
  // }, [customerId, dispatch]);
  useEffect(() => {
    if (customerId) {
      dispatch(manualSubscriptionInvoiceData()).catch(err =>
        console.log('error in fetching manual invoices ', err)
      );
    }
  }, [customerId, dispatch]);

  if (customerId) {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      );
    }

    return (
      <>
        <div className={styles['common-container']}>
          <div className={styles['widget-container']}>
            <div className={styles['activity-heading']}>
              <h3>Vehicle Subscription Activity</h3>
            </div>
            <div className={styles.data}>
              <div className={styles['activity-data']}>
                <h6 className={styles['top-paragraph']}>Prior</h6>
                <p className={styles['bottom-paragraph']}>{subscription?.billed_prior_month}</p>
              </div>
              <div className={styles['activity-data']}>
                <h6 className={styles['top-paragraph']}>Subscribed</h6>
                <p className={styles['bottom-paragraph']}>{subscription?.recently_subscribed}</p>
              </div>
              <div className={styles['activity-data']}>
                <h6 className={styles['top-paragraph']}>Unsubscribed</h6>
                <p className={styles['bottom-paragraph']}>{subscription?.recently_unsubscribed}</p>
              </div>
              <div className={styles['activity-data']}>
                <h6 className={styles['top-paragraph']}>Current</h6>
                <p className={styles['bottom-paragraph']}>{subscription?.billed_this_month}</p>
              </div>
            </div>
          </div>
          <div className={styles.export}>
            <Button
              onClick={() => subscriptionExport()}
              appearance="button"
              size="xs"
              label="Export Last Month's Vehicles"
              icon={<Export />}
            />
          </div>
        </div>
        {/* <SubscriptionTable /> */}
        <div className={styles['widget-container']}>
          <div className={styles['invoices-heading']}>
            <h3>Invoices</h3>
          </div>
          <ManualInvoices />
        </div>
      </>
    );
  }

  return <SelectCustomer label="Subscription" />;
};

export default Subscription;
