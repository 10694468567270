/* eslint-disable import/no-cycle */
import { EAuthRole } from '@common/utils/enums';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '@store/types';
import { BE_URL } from './api-urls';

export const rtkqController = new AbortController();

export const baseApi = createApi({
  reducerPath: 'baseApi',
  refetchOnReconnect: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      headers.set('X-Content-Type-Options', 'nosniff');
      const userId = (getState() as RootState).auth.id;
      const userRole = (getState() as RootState).auth.app_role;
      const customerId = (getState() as RootState).customer.id;
      if (token) {
        headers.set('authorization', token);
      }
      if (userRole === EAuthRole.SUPER_ADMIN) {
        if (userId) {
          headers.set('userId', userId);
        }
        if (customerId) {
          headers.set('customerId', customerId);
        }
      }
      if (headers.get('content-type') === 'file') {
        headers.delete('content-type');
      } else if (!headers.get('content-type')) {
        headers.set('content-type', 'application/json');
      }
      headers.set('accept', '*/*');
      return headers;
    },
    signal: rtkqController.signal
  }),
  endpoints: () => ({})
});
