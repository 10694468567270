import { FC } from 'react';
import { tollDocumentStatuses } from '@common/utils/constants';
import { useAppSelector } from '@store/hooks';
import { Invoice, TollAgency } from '@assets/icons';
import { SelectInput } from '@common/ui/Form';
import { OptionsByTableProps } from './types';
import StartAndEndDate from '../StartAndEndDate/StartAndEndDate';

const TollDocuments: FC<OptionsByTableProps> = ({ ...props }) => {
  const statementTypes = useAppSelector(state => state.fleetTabs.statementTypes);
  const statementTypeValues = statementTypes?.map(statementType => statementType.type) || [];
  return (
    <>
      <StartAndEndDate
        startLabel="Toll Document Date From"
        startPlaceholder="Toll Document Date From"
        endLabel="Toll Document Date To"
        endPlaceholder="Toll Document Date To"
        startField="date.from"
        endField="date.to"
        {...props}
      />
      <SelectInput
        {...props}
        type="simple"
        label="Invoice issuer"
        formField="invoiceIssuer"
        icon={<TollAgency />}
        values={statementTypeValues}
        placeholder="Select Invoice Issuer"
      />
      <SelectInput
        {...props}
        type="simple"
        label="Status"
        formField="status"
        icon={<Invoice />}
        values={Object.values(tollDocumentStatuses)}
        placeholder="Select Status"
      />
      {/* <SelectInput
        {...props}
        type="simple"
        label="Invoice type"
        formField="invoiceType"
        icon={<Invoice />}
        values={Object.values(invoiceTypes)}
        placeholder="Select Invoice Type"
      /> */}
    </>
  );
};

export default TollDocuments;
