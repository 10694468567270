/* eslint-disable */
import { useState, FC, useEffect } from 'react';
import { motion } from 'framer-motion';
import { isArray } from 'lodash';
import { standardDropdownMotionVariants } from '@common/utils/motion-variants';
import Dropdown from '@common/ui/Dropdown';
import styles from './select-input.module.scss';
import SelectInputDisplay from './SelectInputDisplay';
import { SelectInputProps } from './types';
import { Popper } from '../Popper';
import SliderInput from './SliderInput/SliderInput';
import { ChevronDown, FilledCancel } from '@assets/icons';
import SelectInputContent from './SelectInputContent/SelectInputContent';

const SelectInput: FC<SelectInputProps> = ({
  label = '',
  className = '',
  dropdownClassName = '',
  placeholder,
  values,
  disabled,
  icon,
  handleChange,
  clearFilterSelection,
  hideArrows,
  noSelection = false,
  dropdownContainerEl,
  selection,
  rangeSelection,
  type = 'simple',
  ...props
}) => {
  const [inputSelection, setInputSelection] = useState<string | number | (string | number)[]>(
    selection
  );
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => setIsOpen(value);
  const [userSliderValues, setUserSliderValues] = useState({
    min: 0,
    max: 0
  });
  const handleSelection = (data: any) => {
    if (data) {
      setInputSelection(data);
      handleChange(data);
    }
  };
  const clearSelection = (data: any) => {
    setInputSelection(data);
    setUserSliderValues({ min: 0, max: 0 });
    clearFilterSelection(data);
  };
  const handleSlider = (type: string, value: number) => {
    if (type && value) {
      if (type === 'min') {
        userSliderValues.min = value;
      } else if (type === 'max') {
        userSliderValues.max = value;
      }
      setUserSliderValues({ ...userSliderValues });
    }
  };
  useEffect(() => {
    if ((props.max || props.max === 0) && (props.min || props.min === 0)) {
      if (rangeSelection && rangeSelection.from !== rangeSelection.to) {
        userSliderValues.max = rangeSelection.to;
        userSliderValues.min = rangeSelection.from;
      } else {
        userSliderValues.max = props.max;
        userSliderValues.min = props.min;
      }
      setUserSliderValues({ ...userSliderValues });
    }
  }, [props.max, props.min, rangeSelection]);
  useEffect(() => {
    setInputSelection(selection);
  }, [selection]);
  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);
  return (
    <section
      aria-label="select-input"
      data-select-input-disabled={disabled}
      data-select-is-tags={type === 'tags'}
      title={
        !noSelection
          ? !rangeSelection && isArray(selection) && selection.length > 0
            ? `Selected items ( ${selection.join(', ')} )`
            : rangeSelection && rangeSelection.from !== rangeSelection.to
            ? `${rangeSelection.from} - ${rangeSelection.to}`
            : `${isArray(selection) ? 'No applied filters' : selection}`
          : label
      }
      className={`${styles['max-width']} ${className}`}
    >
      {label && !noSelection && <label htmlFor={label}>{label}</label>}
      {type === 'slider' ? (
        <>
          {/* <button disabled={disabled} onClick={() => handleDropdownOpenState(!isOpen)}>
            {placeholder}
          </button> */}
          <div
            aria-label="select-display-icons"
            className={`${styles['slider-container']} ${disabled ? styles['disable-slider'] : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => {
              if (!disabled) {
                handleDropdownOpenState(!isOpen);
              }
            }}
          >
            {!noSelection && (
              <SelectInputContent
                placeholder={placeholder}
                className={styles['container-selection']}
                selection={placeholder || ''}
                handleSelectionState={() => {}}
              />
            )}
            {!noSelection && rangeSelection && rangeSelection.from !== rangeSelection.to ? (
              <button
                aria-label="select-input-clear"
                className={styles.clear}
                onClick={clearSelection}
                type="button"
                title={`Clear range`}
              >
                <FilledCancel />
              </button>
            ) : (
              <ChevronDown
                className={isOpen ? `${styles.arrow} ${styles['arrow-open']}` : styles.icon}
              />
            )}
          </div>
          <Popper
            controlDisabled={disabled}
            position={props.position}
            offset={[70, -45]}
            sameWidth={props.sameWidth}
            boundaryElement={dropdownContainerEl}
            control={props.children}
            handleOpenState={() => handleDropdownOpenState(false)}
            isOpen={isOpen}
            usePortal={props.usePortal}
          >
            {(handlePopperElement, popperStyles, popperAttributes) => (
              <motion.div
                ref={handlePopperElement}
                aria-label="dropdown"
                variants={standardDropdownMotionVariants}
                {...standardDropdownMotionVariants}
                {...popperAttributes.popper}
                style={{ ...popperStyles.popper, width: `${!noSelection ? '23rem' : 'auto'}` }}
                className={`${styles.container} ${styles['is-geotab']} ${className} ignore-onclickoutside`}
                data-dropdown-type={type}
              >
                <SliderInput
                  label={label}
                  icon={icon}
                  handleInputFocus={isFocussed => {
                    if (!isFocussed) {
                      handleChange(undefined, {
                        min: userSliderValues.min,
                        max: userSliderValues.max
                      });
                    }
                  }}
                  disabled={disabled}
                  min={props.min || 0}
                  max={props.max || 0}
                  userMax={userSliderValues.max}
                  userMin={userSliderValues.min}
                  handleUserSlider={handleSlider}
                />
              </motion.div>
            )}
          </Popper>
        </>
      ) : (
        <Dropdown
          disabled={disabled}
          className={`${styles.dropdown} ${dropdownClassName}`}
          selection={inputSelection}
          type={!noSelection ? 'select' : 'list'}
          handleSelectionState={handleSelection}
          handleListSelection={data => {
            setIsOpen(!isOpen);
            handleSelection(data);
          }}
          values={values}
          isOpen={isOpen}
          handleOpenState={handleDropdownOpenState}
          boundaryElement={dropdownContainerEl}
          customDropdownItems={props.customDropdownItems}
          includeSearch={props.includeSearch}
          position="bottom"
          offset={[0, 6]}
          motionVariants={standardDropdownMotionVariants}
          sameWidth
        >
          <SelectInputDisplay
            placeholder={placeholder}
            type={type}
            selection={inputSelection}
            noSelection={noSelection}
            hideArrows={hideArrows}
            label={label}
            disabled={disabled}
            icon={icon}
            isOpen={isOpen}
            handleSelectionState={clearSelection}
          />
        </Dropdown>
      )}
    </section>
  );
};

export default SelectInput;
