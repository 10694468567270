/* eslint-disable */
import { ArrowLeft } from '@assets/icons';
import { FC } from 'react';
import styles from './log.module.scss';
import { UIDateTimeformatHelper } from '@common/services/format/formatTimeZone';

const Log: FC<any> = ({
  name,
  type,
  itemIcon,
  previous_fields,
  modifiedFields,
  updated_at,
  formatted_updated_at,
  className = '',
  ...props
}) => {
  const isMobileView = window.matchMedia('(max-width: 480px)').matches;
  const UpdatedFields = ({
    keys,
    values,
    index,
    ...props
  }: {
    keys: any;
    values: any;
    index: number;
  }) => {
    const parsedPreviousFields = JSON.parse(previous_fields);

    const renderValue = (value: any) => {
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
      }
      return value || 'none';
    };

    return (
      <div className={isMobileView ? styles['mobile-info'] : styles.info}>
        <span aria-label="sort-type" className={styles.create}>
          Updated
        </span>
        <h4 className={styles.item}>{keys.slice(0, 1).toUpperCase() + keys.slice(1)}</h4>
        <h4 className={styles.change}>
          {keys !== 'childTags' && keys !== 'childTagLogs' ? (
            <>
              {parsedPreviousFields[keys] ? renderValue(parsedPreviousFields[keys]) : 'none'}
              <span className={styles.arrow}>
                <ArrowLeft />
              </span>
              {renderValue(values)}
            </>
          ) : (
            <>{renderValue(values)}</>
          )}
        </h4>
      </div>
    );
  };

  return Object.entries(modifiedFields).length > 0 ? (
    <article className={`${styles.container} ${className}`}>
      <section className={styles.action}>
        <h3 className={styles.author}>{name}</h3>
      </section>
      <section>
        <>
          {type === 'update' &&
            Object.entries(modifiedFields).map(([keys, values], index) => (
              <UpdatedFields
                key={index}
                keys={keys}
                values={
                  keys === 'childTags' || keys === 'childTagLogs'
                    ? `${keys} updated`
                    : typeof values === 'object' && !Array.isArray(values)
                    ? JSON.stringify(values)
                    : Array.isArray(values)
                    ? values.map(val => JSON.stringify(val)).join(', ')
                    : values
                }
                index={index}
              />
            ))}
        </>
        {type === 'new' && <h4 className={styles.create}>{'created new records'}</h4>}
        <small className={styles.date}>{UIDateTimeformatHelper(formatted_updated_at)}</small>
      </section>
    </article>
  ) : (
    <></>
  );
};

export default Log;
