/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import Modal from '@common/ui/Modal';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button/Button';
import {
  closeSubscribe,
  getUnSubscribedVehicle,
  saveSubscribedVehicle
} from '@store/features/subscribe';
import MotionDiv from '../../geotab-addin/components/motion-div';
import { useHandleSubscribeTitle } from './useHandleSubscribeTitle';
import styles from './subscribe-modal.module.scss';
import SubscribeTable from './SubscribeTable/subscribeTable';
import Confirmation from './confirmation';
import Loader from '@common/ui/Loader/Loader';
import { cookiesSessionUser, credentials } from '@common/services/auth';
import _ from 'lodash';
import { useSearchFleetMutation } from '@api/services/fleet';
import { useModifyData } from '@pages/tables/hooks/useModifyData';
import { ETableName } from '@common/utils/enums';
import { setTable } from '@store/data';
import { setPagination } from '@store/features/pagination';
import Cookies from 'js-cookie';

const SubscribeModal: FC = () => {
  const dispatch = useAppDispatch();
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleSubscribeTitle('Subscribe');
  const status = useAppSelector(state => state.subscribe.open);
  const subscribedVehicle = useAppSelector(state => state.subscribe.subscribedVehicle);
  const unSubscribedVehicle = useAppSelector(state => state.subscribe.unSubscribedVehicle);
  const isSuccess = useAppSelector(state => state.subscribe.isSuccess);
  const loading = useAppSelector(state => state.subscribe.loading);
  const user = useAppSelector(state => state.user);
  const customer = useAppSelector(state => state.customer);
  const [successTab, setSuccessTab] = useState(false);
  const body = useModifyData(ETableName.FLEET);

  const data = [...subscribedVehicle, ...unSubscribedVehicle];

  const [searchVehicles] = useSearchFleetMutation();

  useEffect(() => {
    dispatch(
      //@ts-ignore
      getUnSubscribedVehicle({
        sort_by: '',
        sort_order: '',
        subscribe: activeTitle === 'Subscribe' ? false : true
      })
    );
  }, [activeTitle]);

  function successCloseTab() {
    dispatch(closeSubscribe());
    handleActiveTitle('Subscribe');
    searchVehicles({ ...body })
      .unwrap()
      .then(res => {
        dispatch(
          setTable({
            data: res.pageContent,
            isLoading: false,
            isError: false,
            isSuccess: true,
            error: undefined,
            isFetching: false,
            fetch: false
          })
        );
        dispatch(
          setPagination({
            table: ETableName.FLEET,
            dataLength: res.totalElements || 0,
            totalPages: res.totalPages || 0
          })
        );
      })
      .catch(e => {
        console.log(e);
        dispatch(
          setTable({
            data: undefined,
            isLoading: false,
            isError: true,
            isSuccess: false,
            error: undefined,
            isFetching: false,
            fetch: false
          })
        );
      });
  }

  return (
    <div>
      {status && !successTab && (
        <Modal
          activeTitle={activeTitle}
          customCloseFunc={() => {
            dispatch(closeSubscribe());
            handleActiveTitle('Subscribe');
          }}
          navigation={['Subscribe', 'UnSubscribe', 'Confirmation']}
          customPrimaryButton={
            activeTitle === 'Subscribe' ? (
              <Button
                appearance="button"
                size="xs"
                label={subscribedVehicle.length > 0 ? 'Submit' : 'Skip'}
                variant="g-secondary"
                onClick={() => {
                  handleActiveTitle('UnSubscribe');
                }}
              />
            ) : activeTitle === 'UnSubscribe' ? (
              <Button
                appearance="button"
                size="xs"
                label={unSubscribedVehicle.length > 0 ? 'Submit' : 'Skip'}
                variant="g-secondary"
                onClick={() => {
                  handleActiveTitle('Confirmation');
                }}
                disabled={!(subscribedVehicle.length > 0 || unSubscribedVehicle.length > 0)}
              />
            ) : (
              <Button
                appearance="button"
                size="xs"
                label="Confirm"
                variant="g-secondary"
                onClick={() => {
                  let request: any = {
                    vehicles: [],
                    unSubscribeVehicle: [],
                    session_id: null,
                    mode: 'web'
                  };
                  subscribedVehicle.map(item => {
                    request.vehicles.push(item.vehicle_id);
                  });
                  unSubscribedVehicle.map(item => {
                    request.unSubscribeVehicle.push(item.vehicle_id);
                  });
                  const isGeotab = user?.sourceType
                    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
                    : window.location !== window.parent.location;
                  if (isGeotab) {
                    const credential = cookiesSessionUser.get();
                    const sessionCredentials = JSON.parse(Cookies.get(credentials) || '');
                    request.session_id = sessionCredentials?.sessionId;
                    request.user_name = credential.email;
                    request.database = customer.geotabDatabase;
                  }
                  //@ts-ignore
                  dispatch(saveSubscribedVehicle(request));
                  setSuccessTab(true);
                }}
              />
            )
          }
          //   customExtraButtons={<CrossFilter tableName={ETableName.FLEET} entryData={[data]} />}
          customSecondaryButton={
            activeTitle === 'Subscribe' ? (
              <Button
                appearance="button"
                size="xs"
                label="Cancel"
                variant="g-tertiary"
                onClick={() => {
                  dispatch(closeSubscribe());
                  handleActiveTitle('Subscribe');
                }}
              />
            ) : activeTitle === 'UnSubscribe' ? (
              <Button
                appearance="button"
                size="xs"
                label="Back"
                variant="g-tertiary"
                onClick={() => handleActiveTitle('Subscribe')}
              />
            ) : (
              <Button
                appearance="button"
                size="xs"
                label="Back"
                variant="g-tertiary"
                onClick={() => handleActiveTitle('UnSubscribe')}
              />
            )
          }
          cancelButton={false}
          className={styles.modal}
        >
          <AnimatePresence exitBeforeEnter initial={false}>
            <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
              {activeTitle === 'Subscribe' && <SubscribeTable subscribe={false} />}
              {activeTitle === 'UnSubscribe' && <SubscribeTable subscribe />}
              {activeTitle === 'Confirmation' && <Confirmation data={data} />}
            </MotionDiv>
          </AnimatePresence>
        </Modal>
      )}
      {successTab && (
        <Modal
          customCloseFunc={successCloseTab}
          customPrimaryButton={
            !loading ? (
              <Button
                appearance="button"
                size="xs"
                label="Done"
                variant="g-secondary"
                onClick={successCloseTab}
              />
            ) : (
              <></>
            )
          }
          title={loading ? 'Subscribing...' : isSuccess ? 'Success' : 'Failed'}
          cancelButton={false}
          className={styles.success}
        >
          {loading ? (
            <Loader className={styles.loader} />
          ) : (
            <p className={styles.text}>
              {loading
                ? `Subscribing....`
                : isSuccess
                ? `Your request to has been
              completed successfully`
                : `Your request to has been
              Failed`}
            </p>
          )}
        </Modal>
      )}
    </div>
  );
};

export default SubscribeModal;
