/* eslint-disable  */
import _ from 'lodash';
import { TollDocumentsDataInterface } from '@common/types/data';
import { BETDResponse } from '@api/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { TOLL_DOCUMENTS_API, baseApi } from '../config';
import { store } from '@store/index';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { routes } from '@common/utils/route-names';

export const tollDocumentsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Toll Document', 'Toll Documents']
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchTollDocuments: builder.mutation<
        BETDResponse<TollDocumentsDataInterface>,
        [unknown, string]
      >({
        queryFn: async ([body, email], _queryApi, _extraOptions, fetchWithBQ) => {
          const getTollDocuments = await fetchWithBQ({
            // url: `${'http://localhost:5005/web/api/v1/toll-document'}/list`,
            url: `${TOLL_DOCUMENTS_API}/list`,
            method: 'post',
            headers: {
              email
            },
            body
          });
          if (getTollDocuments.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (getTollDocuments.meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (getTollDocuments.error) {
            return { error: getTollDocuments.error };
          }
          const tollDocumentRes = (getTollDocuments.data as any)
            .data as BETDResponse<TollDocumentsDataInterface>;
          const modTollDocuments = tollDocumentRes.pageContent.map(
            document =>
              ({
                ...document,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                id: document.statement_id
              } as TollDocumentsDataInterface)
          );
          return { data: { ...tollDocumentRes, pageContent: modTollDocuments } };
        },
        invalidatesTags: [{ type: 'Toll Documents', id: 'LIST' }]
      }),
      uploadTollDocument: builder.mutation<any, [FormData, string]>({
        queryFn: async ([body, email], _queryApi, _extraOptions, fetchWithBQ) => {
          const uploadDocument = await fetchWithBQ({
            url: `${TOLL_DOCUMENTS_API}`,
            method: 'post',
            body,
            headers: {
              email,
              'Content-type': 'file'
            }
          });
          if (uploadDocument.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (uploadDocument.error) {
            return { error: uploadDocument.error };
          }
          const tollDocumentRes = uploadDocument.data as { isSuccess: boolean; data: unknown };
          if (!tollDocumentRes.isSuccess) {
            return { error: uploadDocument.data as FetchBaseQueryError };
          }
          return { data: { ...tollDocumentRes } };
        },
        invalidatesTags: [{ type: 'Toll Documents', id: 'LIST' }]
      })
    })
  });

export const { useSearchTollDocumentsMutation, useUploadTollDocumentMutation } = tollDocumentsApi;
