import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
import Button from '@common/ui/Button';
import { Notifications } from '@assets/icons';
import Modal from '@common/ui/Modal';
import Toggle from '@common/ui/Toggle';
import { setTransactionTab } from '@store/features/helpers';
import { clearAlertFilters } from '@store/data/fleetTabs';
import { setCurrentPage } from '@store/features/pagination';
import { store } from '@store/index';
import { clearAllSelectionIDs } from '@store/features/selection';
import { clearTransactionsType } from '@store/features/quick-filters';
import { refetchTableData } from '@store/data';
import TripSplitPaneView from '@pages/tables/trips/TripSplitPaneView';
import Empty from '../empty-states/TableEmpty';
import Loading from '../loading-states/TableLoading';
import Pagination from '../pagination';
import SearchBar from '../searchbar';
import SmartFilters from '../smart-filters';
import Table from '../table/Table';
import styles from './content.module.scss';
import Title from '../title-block/title/Title';
import Alert from '../modals/RowModal/Vehicle/Alert';

const Content = ({ title }: { title: string }) => {
  const dispatch = useAppDispatch();
  const table = useAppSelector(state => state.table);
  const customerId = useAppSelector(state => state.customer.id);
  const pagination = useAppSelector(state => state.pagination[table.name as ETableName]);
  const search = useAppSelector(state => state.search[table.name as ETableName]);
  const filters = useAppSelector(state => state.filters[table.name as ETableName]);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const [showAlert, setShowAlert] = useState(false);
  const isGeotab = store.getState().user?.sourceType
    ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  const changeTransactionToggle = () => {
    dispatch(clearAllSelectionIDs());
    dispatch(setTransactionTab(!isActualTollTab));
    dispatch(clearTransactionsType());
    if (pagination.currentPage === 1) {
      dispatch(refetchTableData(true));
    } else {
      dispatch(setCurrentPage({ table: ETableName.TRANSACTIONS, currentPage: 1 }));
    }
  };

  return (
    <>
      {/* <Title title={title} /> */}
      <Title title={title} hideButtons={ETableName.CUSTOMERS === table.name} />
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`${styles.container} ${
          table.name === ETableName.USERS ? styles['is-users'] : ''
        } ${isGeotab ? styles['geotab-container'] : ''}`}
      >
        <section className={styles.actions}>
          <SmartFilters />
          {customerId && table.name === ETableName.TRANSACTIONS && (
            <div className={styles['toggle-div']}>
              <Toggle
                label1="Invoiced Tolls"
                label2="tollogic™ Tolls"
                state={isActualTollTab}
                handleToggle={() => changeTransactionToggle()}
              />
            </div>
          )}
          {customerId && table.name === ETableName.FLEET && (
            <Button
              className={styles.map}
              variant="tertiary"
              appearance="icon"
              size="s"
              icon={<Notifications className={styles.icon} />}
              onClick={() => {
                setShowAlert(!showAlert);
              }}
            />
          )}
          {showAlert && (
            <Modal
              activeTitle="Alerts"
              navigation={['Alerts']}
              primaryButton={false}
              customCloseFunc={() => {
                dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 1 }));
                dispatch(clearAlertFilters());
                setShowAlert(false);
              }}
            >
              <AnimatePresence exitBeforeEnter initial={false}>
                <Alert />
              </AnimatePresence>
            </Modal>
          )}
          <SearchBar />
        </section>
        {(table.isLoading || (table.isFetching && (!table.data || table.data.length === 0))) && (
          <Loading />
        )}
        {!table.isLoading &&
          !table.isFetching &&
          !search?.active &&
          !filters?.active &&
          (!table.data || table.data.length === 0) && (
            <Empty tableType={table.name as ETableName} />
          )}
        {table.name !== ETableName.TRIPS &&
          !table.isLoading &&
          table.data &&
          (search?.active || filters?.active || table.data.length > 0) && (
            <Table pageData={_.uniqBy(table.data, 'id') || []} loading={table.isFetching} />
          )}
        {table.name === ETableName.TRIPS &&
          !table.isLoading &&
          table.data &&
          (search?.active || filters?.active || table.data.length > 0) && (
            <TripSplitPaneView
              pageData={_.uniqBy(table.data, 'id') || []}
              loading={table.isFetching}
            />
          )}
        {table.name !== ETableName.USERS &&
          table.name !== ETableName.TRIPS &&
          pagination?.pagesArray?.length !== 0 && (
            <div className={styles.pagination}>
              <Pagination />
            </div>
          )}
      </motion.section>
    </>
  );
};

export default Content;
