import Meta from '@components/meta';

const Notifications = () => {
  const metaOptions = {
    title: 'Notifications',
    keywords: 'Notifications'
  };
  return <Meta {...metaOptions} />;
};

export default Notifications;
