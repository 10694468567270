export function ArrowsFilled({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 7 11"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.407215 6.73962C0.509129 6.63769 0.647354 6.58042 0.791482 6.58042H6.20369C6.27505 6.58042 6.34572 6.59448 6.41165 6.6218C6.47758 6.64911 6.53749 6.68915 6.58795 6.73962C6.63842 6.79009 6.67845 6.85001 6.70576 6.91595C6.73307 6.9819 6.74712 7.05258 6.74712 7.12395C6.74712 7.19533 6.73307 7.26601 6.70576 7.33196C6.67845 7.3979 6.63842 7.45782 6.58795 7.50829L3.88185 10.2149C3.83154 10.2656 3.77168 10.3059 3.70573 10.3334C3.63977 10.3609 3.56903 10.375 3.49759 10.375C3.42614 10.375 3.3554 10.3609 3.28944 10.3334C3.22349 10.3059 3.16363 10.2656 3.11332 10.2149L0.407215 7.50829C0.305302 7.40636 0.248047 7.26811 0.248047 7.12395C0.248047 6.9798 0.305302 6.84155 0.407215 6.73962Z" />
      <path d="M6.41183 4.37887C6.34588 4.40635 6.27514 4.4205 6.20369 4.4205L0.791482 4.41958C0.647354 4.41958 0.509129 4.36231 0.407215 4.26038C0.305302 4.15845 0.248047 4.0202 0.248047 3.87605C0.248047 3.73189 0.305302 3.59364 0.407215 3.49171L3.11332 0.785121C3.16363 0.734384 3.22349 0.694113 3.28944 0.666631C3.3554 0.639149 3.42614 0.625 3.49759 0.625C3.56903 0.625 3.63977 0.639149 3.70573 0.666631C3.77168 0.694113 3.83154 0.734384 3.88185 0.785121L6.58795 3.49171C6.63868 3.54203 6.67895 3.6019 6.70642 3.66787C6.7339 3.73383 6.74805 3.80459 6.74805 3.87605C6.74805 3.94751 6.7339 4.01826 6.70642 4.08422C6.67895 4.15019 6.63868 4.21006 6.58795 4.26038C6.53764 4.31112 6.47778 4.35139 6.41183 4.37887Z" />
    </svg>
  );
}
