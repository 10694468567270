import _ from 'lodash';

export const sortData = (data: AnyObject[], sortingBy?: string, sortingOrder?: 'asc' | 'desc') => {
  const nullData = sortingBy ? data.filter(row => !row[sortingBy]) : [];
  const notNullData = sortingBy ? data.filter(row => row[sortingBy]) : [];
  const sortedValues =
    sortingBy && sortingOrder
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        _.orderBy(notNullData, [row => `${row[sortingBy]}`.toLowerCase()], sortingOrder).concat(
          nullData
        )
      : data;
  return sortedValues;
};
