import { TollDocumentsDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<TollDocumentsDataInterface>[] = [
  {
    heading: 'status',
    field: 'status',
    active: true,
    filterType: 'text',
    placeholder: 'search status'
  },
  {
    heading: 'reject reason',
    field: 'reject_reason',
    active: false,
    filterType: 'text',
    placeholder: 'search reject reason'
  },
  {
    heading: 'toll document name',
    field: 'toll_document_name',
    active: true,
    filterType: 'text',
    placeholder: 'search document name'
  },
  {
    heading: 'uploaded date',
    field: 'uploaded_date',
    active: true,
    filterType: 'text',
    placeholder: 'search uploaded date'
  },
  {
    heading: 'total amount',
    field: 'total_amount',
    active: true,
    filterType: 'text',
    placeholder: 'search total amount'
  },
  {
    heading: 'invoice issuer',
    field: 'invoice_issuer',
    active: true,
    filterType: 'text',
    placeholder: 'search invoice issuer'
  },
  {
    heading: 'invoice version',
    field: 'invoice_version',
    active: false,
    filterType: 'text',
    placeholder: 'search invoice version'
  },
  {
    heading: 'toll document id',
    field: 'statement_id',
    active: false,
    filterType: 'text',
    placeholder: 'search document id'
  }
];
