/* eslint-disable */
import { vehicleHeaders } from '@pages/geotab/utils/vehicle-headers';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import styles from './header.module.scss';

const Header = ({
  isSubscription,
  className = ''
}: {
  isSubscription: boolean;
  className?: string;
}) => {
  const isMetric = useAppSelector(state => state.auth.isMetric) as boolean;
  return (
    <thead className={`${styles.container} ${className}`}>
      <tr>
        {isSubscription ? (
          <>
            {vehicleHeaders.subscriptionHeaders.map((header: { label: string; value: string }) => (
              <th
                className={`${styles.heading} ${styles.item} ${styles[_.kebabCase(header.label)]}`}
                key={uuid()}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {`${header.label}`}
              </th>
            ))}
          </>
        ) : (
          <>
            {vehicleHeaders.normalHeaders.map((header: { label: string; value: string }) => (
              <>
                <th
                  className={`${styles.heading} ${styles.item} ${
                    styles[_.kebabCase(header.label === 'TAG #' ? 'Tag Transponder' : header.label)]
                  }`}
                  key={uuid()}
                >
                  {header.label === 'VEHICLE WEIGHT' ? (
                    <>
                      {header.label}
                      {isMetric ? (
                        <p className={styles.unit}>&nbsp;(kg)</p>
                      ) : (
                        <p className={styles.unit}>&nbsp;(lbs)</p>
                      )}
                    </>
                  ) : header.label === 'VEHICLE HEIGHT' ? (
                    <>
                      {header.label}
                      {isMetric ? (
                        <p className={styles.unit}>&nbsp;(meter)</p>
                      ) : (
                        <p className={styles.unit}>&nbsp;(inch)</p>
                      )}
                    </>
                  ) : (
                    `${header.label}`
                  )}
                </th>
              </>
            ))}
          </>
        )}
      </tr>
    </thead>
  );
};

export default Header;
