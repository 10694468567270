import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  vehicleClass: Yup.string().nullable(),
  vehicleClassD: Yup.string().nullable(),
  vin: Yup.string().nullable(),
  type: Yup.string().nullable(),
  weight: Yup.number()
    // .integer('value must be an integer')
    // .positive('only positive numbers allowed')
    .nullable(),
  height: Yup.number()
    // .positive('only positive numbers allowed')
    .nullable(),
  axles: Yup.number()
    // .positive('only positive numbers allowed')
    .nullable(),
  tagType: Yup.string().nullable(),
  tagTypeD: Yup.string()
    // .when('tagTransponder', {
    //   is: (val: any) => val !== null && val !== undefined && val !== '',
    //   then: Yup.string().required('Tag Type is required'),
    //   otherwise: Yup.string().nullable()
    // })
    .nullable(),
  tagTransponder: Yup.string().nullable(),
  // .default('')
  // .min(9, 'Tag Transponder must be at least 9 characters long'),
  // .when(['tollConsolidator', 'tagType'], {
  //   is: (tollConsolidator: boolean, tagType: string) =>
  //     !tollConsolidator && tagType !== null && tagType !== undefined && tagType !== '',
  //   then: Yup.string().required('Tag Transponder is required'),
  //   otherwise: Yup.string().nullable()
  // }),
  tollConsolidator: Yup.boolean().default(false),
  secondTagExists: Yup.boolean().default(false),
  issuingAgencyId: Yup.string().nullable(),
  issuingAgencyIdD: Yup.string()
    // .when('tagTransponder', {
    //   is: (val: any) => val !== null && val !== undefined && val !== '',
    //   then: Yup.string().required('Issuing Agency is required'),
    //   otherwise: Yup.string().nullable()
    // })
    .nullable(),
  secTagType: Yup.string().nullable(),
  secTagTypeD: Yup.string()
    // .when('secondTagExists', {
    //   is: true,
    //   then: Yup.string().required('Tag Type is required'),
    //   otherwise: Yup.string().nullable()
    // })
    .nullable(),
  secTagTransponder: Yup.string().nullable(),
  // .default('')
  // .min(9, 'Second Tag Transponder must be at least 9 characters long'),
  // .when(['tollConsolidator', 'secTagType', 'secondTagExists'], {
  //   is: (tollConsolidator: boolean, secTagType: string, secondTagExists: boolean) =>
  //     secondTagExists &&
  //     !tollConsolidator &&
  //     secTagType !== null &&
  //     secTagType !== undefined &&
  //     secTagType !== '',
  //   then: Yup.string().required('Second Tag Transponder is required'),
  //   otherwise: Yup.string().nullable()
  // }),
  secIssuingAgencyId: Yup.number().nullable(),
  secIssuingAgencyIdD: Yup.string()
    // .when('secondTagExists', {
    //   is: true,
    //   then: Yup.string().required('Second Issuing Agency is required'),
    //   otherwise: Yup.string().nullable()
    // })
    .nullable(),
  plateStateD: Yup.string().nullable(),
  plateState: Yup.string().nullable(),
  plateNumber: Yup.string().nullable(),
  currentDriver: Yup.string().nullable(),
  vehicleModel: Yup.string().nullable()
  // licensePlate: Yup.string()
  //   .default('')
  //   .matches(/[A-Z]{2} [A-Z0-9]+$/, {
  //     excludeEmptyString: true,
  //     message: 'format should be XX XXXXXX, where first 2 symbols are state code'
  //   })
  //   .test('state-check', 'Incorrect state code', val => checkStateCode(val, 'US'))
  //   .max(11)
});
