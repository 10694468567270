import styles from './refresh-geotab.module.scss';

const RefreshGeotab = () => {
  return (
    <div className={styles.container}>
      <p>Please refresh app to authenticate user.</p>
    </div>
  );
};

export default RefreshGeotab;
