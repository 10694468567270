export function ChevronRight({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 8 12"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.82994 5.29001L2.58994 1.05001C2.49698 0.956281 2.38638 0.881887 2.26452 0.831118C2.14266 0.780349 2.01195 0.754211 1.87994 0.754211C1.74793 0.754211 1.61723 0.780349 1.49537 0.831118C1.37351 0.881887 1.26291 0.956281 1.16994 1.05001C0.983692 1.23737 0.87915 1.49082 0.87915 1.75501C0.87915 2.0192 0.983692 2.27265 1.16994 2.46001L4.70994 6.00001L1.16994 9.54001C0.983692 9.72737 0.87915 9.98082 0.87915 10.245C0.87915 10.5092 0.983692 10.7626 1.16994 10.95C1.26338 11.0427 1.3742 11.116 1.49604 11.1658C1.61787 11.2155 1.74834 11.2408 1.87994 11.24C2.01155 11.2408 2.14201 11.2155 2.26385 11.1658C2.38569 11.116 2.4965 11.0427 2.58994 10.95L6.82994 6.71001C6.92367 6.61705 6.99806 6.50645 7.04883 6.38459C7.0996 6.26273 7.12574 6.13202 7.12574 6.00001C7.12574 5.868 7.0996 5.73729 7.04883 5.61543C6.99806 5.49357 6.92367 5.38297 6.82994 5.29001Z" />
    </svg>
  );
}
