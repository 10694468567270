import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import Button from '@common/ui/Button';
import { FC } from 'react';
import styles from './footer.module.scss';
import { FooterProps } from './types';

const Footer: FC<FooterProps> = ({ resetForm, resetFilters }) => {
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  return (
    <section className={styles.container}>
      <Button
        appearance="button"
        label="Reset"
        variant="tertiary"
        size="xs"
        onClick={() => {
          resetForm();
          resetFilters();
        }}
      />
      <Button
        appearance="button"
        label="Show results"
        buttonType="submit"
        variant={isGeotab ? 'g-tertiary' : 'primary'}
        size="xs"
      />
    </section>
  );
};

export default Footer;
