export function Hamburger({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 35 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2H33" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
      <path d="M2 14H33" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
      <path d="M2 26H33" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
