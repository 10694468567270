import React, { memo } from 'react';
import { Line } from 'react-chartjs-2';
import { v4 as uuid } from 'uuid';
import LineChartProps from './types';

const LineChart: React.FC<LineChartProps> = ({
  data,
  options,
  width = '100%',
  height = '100%'
}) => {
  const roundedData = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      data: dataset.data.map(value => {
        if (typeof value === 'number') {
          return value % 1 !== 0 ? value.toFixed(2) : value.toString(); // Handle numbers
        }
        return value?.toString();
      })
    }))
  };
  return (
    <Line
      datasetIdKey={uuid()}
      width={width}
      height={height}
      options={options}
      data={roundedData}
    />
  );
};

export default memo(LineChart);
