/* eslint-disable */
import { WEB_BE_URL } from '@api/config';
import { usePromiseToastNotify } from '@common/hooks';
import { axios } from '@common/services/axios';
import Logo from '@common/ui/Logo';
import { useSSOContext } from '@pages/geotab/context/SSOContext';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Footer from '../layout/footer/Footer';
import styles from './thank-you.module.scss';
import PaymentLoader from '../payment-loader/PaymentLoader';
import { useEffect, useState } from 'react';

const ThankYou = ({ success = true }: { success?: boolean }) => {
  const {
    registerLoader,
    gSubscriptionStatus,
    accountId,
    customerId,
    isCustomerNew,
    isOrdernowCustomer,
    gUser,
    registrationSuccessful,
    trialPeriod
  } = useSSOContext();
  const [userName, setUserName] = useState<string | null>('');
  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    setUserName(storedUserName);
  }, []);
  console.log('thank you username', userName);
  // const { userName } = useParams();
  const toastify = usePromiseToastNotify();
  const sendEmail = async () => {
    if (customerId && userName) {
      const mailBody = {
        customerId: customerId as string,
        mailId: gUser.email,
        customerExist: !isCustomerNew
      };
      toastify(
        async () => {
          (await axios.post(`${WEB_BE_URL}/customer-onboard/sendmail`, mailBody)).data;
        },
        'resend',
        'email'
      );
    }
  };
  return (
    <div className={`${styles.container} ${!registrationSuccessful.value ? styles.warning : ''}`}>
      <div className={styles.wrapper}>
        <Logo className={styles.background} />
        <div className={styles.content}>
          <Logo className={styles.logo} />
          {!isCustomerNew && !gSubscriptionStatus ? (
            <>
              <h2 className={styles.heading}>
                Your last payment was not successful
                {!gUser.isAdmin && <>, please contact your administrator for further assistance.</>}
              </h2>
              {/* <h2 className={styles.heading}>You will be redirected to the payment page.</h2> */}
              {gUser.isAdmin && (
                <p className={styles.text}>
                  <PaymentLoader /> Please wait while you are being redirected to the payment page.
                </p>
              )}
            </>
          ) : (
            <>
              <h2 className={styles.heading}>
                {registerLoader ? (
                  <>
                    Please wait while you are being onboarded <PaymentLoader />
                  </>
                ) : (
                  <>
                    {registrationSuccessful.value
                      ? 'Thank you'
                      : registrationSuccessful.message || 'Something went wrong!'}
                  </>
                )}
              </h2>
              {registrationSuccessful.value && (
                <h4 className={styles['second-heading']}>for choosing tollogic™</h4>
              )}
              {!registerLoader && registrationSuccessful.value ? (
                <h4 className={styles['sub-heading']}>
                  <span>
                    {isCustomerNew ? (
                      <>
                        <p className={styles['account-id']}>
                          Your account ID is <b>{accountId}</b>
                        </p>
                        {!isOrdernowCustomer && (
                          <>
                            <p className={styles.subscription}>
                              Your trial starts today and will end by{' '}
                              {trialPeriod?.trialEndDate && (
                                <b>{dayjs(trialPeriod.trialEndDate).format('MMMM D, YYYY')}</b>
                              )}
                              .
                            </p>
                            <p className={styles.subscription}>
                              You will be billed at the start of your subscription.
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>You are successfully onboarded</>
                    )}
                  </span>
                </h4>
              ) : (
                <h4 className={styles['sub-heading']}>
                  {!registrationSuccessful.value
                    ? `${registrationSuccessful.message}`
                    : `We're sorry but something went wrong`}
                </h4>
              )}
              <p className={styles.text}>
                {!registerLoader ? (
                  <>
                    {registrationSuccessful.value ? (
                      <>
                        {isCustomerNew
                          ? 'You should receive a confirmation email in few minutes.'
                          : ''}
                      </>
                    ) : (
                      `${
                        (!registrationSuccessful.value &&
                          !registrationSuccessful.message.toLowerCase().includes('payment') &&
                          `Please try changing the first name ,last name or email in GeoTab and try again`) ||
                        'Please try reloading the screen or contact your administrator.'
                      }`
                    )}
                  </>
                ) : (
                  <>
                    {isOrdernowCustomer
                      ? 'Once you are onboarded, you will be redirected to the Toll Document Management page'
                      : 'Once you are onboarded, you will be redirected to the payment page'}
                  </>
                )}
              </p>

              {isCustomerNew && registrationSuccessful.value && (
                <p className={styles['email-text']}>
                  No e-mail in your inbox or spam folder? Let’s{' '}
                  <span role="presentation" onClick={sendEmail} className={styles['email-resend']}>
                    {' '}
                    resend{' '}
                  </span>
                  it.
                </p>
              )}
              {isCustomerNew && !registerLoader && (
                <p className={styles.text}>
                  <PaymentLoader />{' '}
                  {isOrdernowCustomer && (
                    <>
                      {
                        'To complete the tollogic™ onboarding process, please upload your most recent toll statements, invoices, and/or toll transponder lists via the Toll Document Management page.'
                      }
                      <br />
                    </>
                  )}
                  {isOrdernowCustomer
                    ? 'Please wait while you are being redirected to the Toll Document Management page.'
                    : 'Please wait while you are being redirected to the payment page.'}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYou;
