import { ETableName } from '@common/utils/enums';
import ActionsBlock from './components/actions-block';
import Content from './components/content';
import TablePagination from './components/pagination';
import TitleBlock from './components/title-block';
import { GeotabTableProps } from './types';

const GeotabTable = ({
  title,
  tableName,
  ViewModal,
  datesFiltering,
  displayName,
  button
}: GeotabTableProps) => {
  return (
    <>
      <TitleBlock
        title={title}
        displayName={displayName}
        button={button}
        datesFiltering={datesFiltering}
      />
      {tableName !== ETableName.TRIPS && (
        <ActionsBlock tableName={tableName} datesFiltering={datesFiltering} />
      )}
      <Content ViewModal={ViewModal} />
      {tableName !== 'trips' && <TablePagination />}
    </>
  );
};

export default GeotabTable;
