import { v4 as uuid } from 'uuid';
import Tag from '@common/ui/Tag';
import styles from './filter-tags.module.scss';
import { useFilterTags } from './hooks';

const FilterTags = () => {
  // handle filters state
  const { filters, handleRemoveFilter } = useFilterTags();
  return (
    <section
      ref={el =>
        (el?.scrollWidth || 0) > (el?.clientWidth || 0)
          ? el?.classList?.add(styles['with-scrollbar'])
          : el?.classList?.remove(styles['with-scrollbar'])
      }
      className={styles.container}
    >
      {filters.length > 0 &&
        filters.map(filter => (
          <Tag
            size="l"
            item={filter.item}
            itemGroup={filter.itemGroup}
            itemDisplayName={filter.displayName}
            selection={filters}
            handleSelectionState={handleRemoveFilter}
            key={uuid()}
            separator=": "
            ignoreCheck
          />
        ))}
    </section>
  );
};

export default FilterTags;
