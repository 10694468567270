/* eslint-disable import/no-cycle */
import { useLogOutMutation } from '@api/services/auth';
import { cookiesSessionUser } from '@common/services/auth';
import { logOut, setAuthLoaded } from '@store/features/auth';
import { rtkqController } from '@api/config/base-api';
import { useAppDispatch } from '@store/hooks';

export const useLogOut = (isUserLogout = true) => {
  const dispatch = useAppDispatch();
  const [logUserOut] = useLogOutMutation();
  const handleLogOut = () => {
    const cookieSession = cookiesSessionUser.get();
    if (cookieSession.email) {
      const { email, accessToken } = cookieSession;
      rtkqController.abort();
      logUserOut({ email, accessToken })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (isUserLogout) {
            dispatch(logOut());
          }
          cookiesSessionUser.clear();
          dispatch(setAuthLoaded(false));
        });
    } else {
      if (isUserLogout) {
        dispatch(logOut());
      }
      cookiesSessionUser.clear();
      dispatch(setAuthLoaded(false));
    }
  };
  return handleLogOut;
};
