/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETableName } from '@common/utils/enums';
import { UnionDataInterface } from '@common/types/data';

export type ISelectionIDs = {
  [key in ETableName]: UnionDataInterface[];
};

const initialState: ISelectionIDs = {
  transactions: [],
  fleet: [],
  trips: [],
  trailers: [],
  customers: [],
  roles: [],
  users: [],
  toll_documents: []
};

const selectionSlice = createSlice({
  name: 'selections',
  initialState,
  reducers: {
    setSelectionIDs: (
      state,
      action: PayloadAction<{ value: UnionDataInterface[]; table: ETableName }>
    ) => {
      const selectionIds = state[action.payload.table];
      if (action.payload.value.length > 0) {
        action.payload.value.forEach(item => {
          if (!selectionIds.find(el => el.id === item.id)) {
            selectionIds.push(item);
          }
        });
      }
    },
    addSelectionID: (
      state,
      action: PayloadAction<{ value: UnionDataInterface; table: ETableName }>
    ) => {
      const selectionIds = state[action.payload.table];
      if (!selectionIds.find(el => el.id === action.payload.value.id)) {
        selectionIds.push(action.payload.value);
      }
    },
    removeSelectionID: (
      state,
      action: PayloadAction<{ value: UnionDataInterface; table: ETableName }>
    ) => {
      const selectionIds = state;
      if (selectionIds[action.payload.table].find(el => el.id === action.payload.value.id)) {
        selectionIds[action.payload.table] = selectionIds[action.payload.table].filter(
          el => el.id !== action.payload.value.id
        );
      }
    },
    removeMultipleSelectionIDs: (
      state,
      action: PayloadAction<{ value: UnionDataInterface[]; table: ETableName }>
    ) => {
      const selectionIds = state;
      const valueSet = new Set(action.payload.value);
      selectionIds[action.payload.table] = selectionIds[action.payload.table].filter(
        id => !valueSet.has(id)
      );
    },
    clearSelectionIDs: (
      state,
      action: PayloadAction<{ value: UnionDataInterface[]; table: ETableName }>
    ) => {
      const selectionIds = state[action.payload.table];
      if (action.payload.value.length > 0) {
        action.payload.value.forEach(value => {
          const index = selectionIds.findIndex(obj => obj.id === value.id);
          if (index !== -1) {
            selectionIds.splice(index, 1);
          }
        });
      }
    },
    clearAllSelectionIDs: (state, action: { payload: ETableName | undefined }) => {
      if (action.payload) {
        const reserveState = state[action.payload];
        state = { ...initialState, [action.payload]: reserveState };
        return state;
      }
      return initialState;
    }
  }
});

export const {
  addSelectionID,
  removeSelectionID,
  removeMultipleSelectionIDs,
  setSelectionIDs,
  clearSelectionIDs,
  clearAllSelectionIDs
} = selectionSlice.actions;

export default selectionSlice.reducer;
