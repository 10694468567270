import { useEffect, useState } from 'react';
import api from '@api/services/common';
import { useAppSelector } from '@store/hooks';
import { ApiResponse } from './types';

export const useDateRangeData = () => {
  const customerId = useAppSelector(state => state.customer.id);
  const [data, setData] = useState<string[]>([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    api
      .post(`/insights/date-range`, { moduleId: 'MONTHLY_DETAIL' })
      .then((res: ApiResponse) => {
        setLoader(true);
        if (res.isSuccess) {
          setData(res.data);
        }
      })
      .catch(err => {
        console.error('err ', err);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [customerId]);

  return {
    data,
    loader
  };
};
