import { SecondaryLayout } from '@app/layouts';
import { routes } from '@common/utils/route-names';
import { Login, ForgotPassword, ResetPassword, CreatePassword, UserInvitation } from '@pages/auth';
import GeoTabSso from '@pages/geotab/GeoTabSso';
import SAOpenRoute from '@pages/geotab/components/serviceAgreement/saOpenRoute';
import { Route } from 'react-router-dom';

const AuthRoutes = () => {
  return (
    <Route element={<SecondaryLayout />}>
      <Route path={routes.openRouteServiceAgreement} element={<SAOpenRoute />} />
      <Route path={routes.geotab_login} element={<GeoTabSso />} />
      <Route path={routes.geotab_sso_landing} element={<>Logging you in</>} />
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.user_invitation} element={<UserInvitation />} />
      <Route path={routes.forgot_password} element={<ForgotPassword />} />
      <Route path={routes.reset_password} element={<ResetPassword />} />
      <Route path={routes.create_password} element={<CreatePassword />} />
    </Route>
  );
};

export default AuthRoutes;
