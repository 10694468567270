import { UserDataInterface } from '@common/types/data';
import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';
import { useHandleSubmit } from './hooks/useHandleSubmit';

const UpdateUserFormik = ({ children, data }: ReactChildren & { data: UserDataInterface }) => {
  const { handleSubmit } = useHandleSubmit();
  const newUpdateData = { ...data };
  newUpdateData.isPasswordChanged = false;
  newUpdateData.password = '';
  newUpdateData.createType = 'changePassword';
  return (
    <Formik
      initialValues={newUpdateData || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default UpdateUserFormik;
