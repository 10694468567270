export function Height({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.6099 6.04999L15.9999 0.389992C15.9065 0.297311 15.7957 0.223986 15.6738 0.174221C15.552 0.124456 15.4215 0.0992314 15.2899 0.0999923C15.0276 0.101097 14.7762 0.205248 14.5899 0.389992L0.389914 14.54C0.203663 14.7274 0.0991211 14.9808 0.0991211 15.245C0.0991211 15.5092 0.203663 15.7626 0.389914 15.95L6.04991 21.61C6.14335 21.7027 6.25417 21.776 6.37601 21.8258C6.49784 21.8755 6.62831 21.9008 6.75991 21.9C7.02225 21.8989 7.27365 21.7947 7.45991 21.61L10.2899 18.78L18.7799 10.29L21.6099 7.45999C21.7962 7.27263 21.9007 7.01918 21.9007 6.75499C21.9007 6.49081 21.7962 6.23736 21.6099 6.04999ZM18.0699 8.16999L17.3599 7.45999C17.1726 7.27374 16.9191 7.1692 16.6549 7.1692C16.3907 7.1692 16.1373 7.27374 15.9499 7.45999C15.8562 7.55296 15.7818 7.66356 15.731 7.78542C15.6803 7.90727 15.6541 8.03798 15.6541 8.16999C15.6541 8.302 15.6803 8.43271 15.731 8.55457C15.7818 8.67643 15.8562 8.78703 15.9499 8.87999L16.6599 9.58999L15.2399 11L13.1199 8.87999C12.9286 8.71617 12.6825 8.63056 12.4309 8.64028C12.1792 8.65 11.9404 8.75433 11.7624 8.93243C11.5843 9.11052 11.4799 9.34926 11.4702 9.60094C11.4605 9.85261 11.5461 10.0987 11.7099 10.29L13.8299 12.41L12.4099 13.83L11.7099 13.12C11.5216 12.9317 11.2662 12.8259 10.9999 12.8259C10.7336 12.8259 10.4782 12.9317 10.2899 13.12C10.1016 13.3083 9.99582 13.5637 9.99582 13.83C9.99582 14.0963 10.1016 14.3517 10.2899 14.54L10.9999 15.24L9.58991 16.66L7.45991 14.54C7.27255 14.3537 7.0191 14.2492 6.75491 14.2492C6.49073 14.2492 6.23728 14.3537 6.04991 14.54C5.86366 14.7274 5.75912 14.9808 5.75912 15.245C5.75912 15.5092 5.86366 15.7626 6.04991 15.95L8.16991 18.07L6.75991 19.49L2.50991 15.24L15.2399 2.50999L19.4899 6.75999L18.0699 8.16999Z" />
    </svg>
  );
}
