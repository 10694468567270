import { useState } from 'react';
import { UploadTollDocumentFormik } from '../../../forms/Create';
import TollDocumentForm from './TollDocumentForm/TollDocumentForm';
import SelectFile from './SelectFile/SelectFile';

const TollDocument = () => {
  const [fileSelected, setFileSelected] = useState(false);
  const handleFileSelected = (value: boolean) => setFileSelected(value);

  return (
    <UploadTollDocumentFormik>
      {!fileSelected ? (
        <SelectFile handleFileSelected={handleFileSelected} />
      ) : (
        <TollDocumentForm handleFileSelected={handleFileSelected} />
      )}
    </UploadTollDocumentFormik>
  );
};

export default TollDocument;
