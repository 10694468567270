import Meta from '@components/meta';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect } from 'react';
import DashboardNavigation from './InsightDashboardNavigation';
import Main from './InsightDashboards';

const Dashboard = () => {
  useEffect(() => {
    /* Register all ChartJS components that are to be used for dashboard */
    ChartJS.register(
      ArcElement,
      CategoryScale,
      LinearScale,
      BarElement,
      PointElement,
      LineElement,
      // TreemapController,
      // TreemapElement,
      Legend,
      Tooltip,
      ChartDataLabels,
      Title
    );
  }, []);
  const metaOptions = {
    title: 'Dashboard',
    keywords: 'Dashboard'
  };
  return (
    <div>
      <DashboardNavigation />
      <Meta {...metaOptions} />
      <Main />
    </div>
  );
};

export default Dashboard;
