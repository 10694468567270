/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '@store/hooks';
import { EModule } from '@common/utils/enums';
import styles from './mobile-menu.module.scss';
import { navigationLinks } from '../utils/navigation-links';
import NavItem from '../NavItem';

const MobileMenu = ({
  handleOpenMobileMenu
}: {
  handleOpenMobileMenu: (value: boolean) => void;
}) => {
  const customer = useAppSelector(state => state.customer);
  const menuOverlayRef = useRef(null);
  const handleCloseModal = (e: { target: EventTarget }) => {
    if (menuOverlayRef.current === e.target) {
      handleOpenMobileMenu(false);
    }
  };
  return (
    <motion.div
      className={styles.overlay}
      ref={menuOverlayRef}
      onClick={handleCloseModal}
      onKeyDown={handleCloseModal}
      role="button"
      tabIndex={0}
      initial={{ background: styles.backgroundHidden }}
      animate={{ background: styles.backgroundVisible }}
      exit={{ background: styles.backgroundHidden }}
      transition={{ duration: 0.4, delayChildren: 0.5 }}
    >
      <motion.div
        className={styles.container}
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '100%', opacity: 0 }}
        transition={{ delay: 0.4, type: 'tween' }}
      >
        {navigationLinks
          ?.filter(item => !item.mobileVisible)
          ?.map(
            navItem =>
              ((navItem.title === EModule.DASHBOARD && customer.id) ||
                (navItem.title !== EModule.DASHBOARD && navItem.isPermitted(navItem.link))) && (
                <button
                  className={styles.link}
                  type="button"
                  key={uuid()}
                  onClick={() => handleOpenMobileMenu(false)}
                >
                  <NavItem
                    isMobile
                    className={styles['mobile-nav-item']}
                    activeCustomClassName={styles.active}
                    navItem={{
                      ...navItem,
                      title:
                        // eslint-disable-next-line no-nested-ternary
                        navItem.title === 'toll documents'
                          ? 'toll document management'
                          : navItem.title === 'toll transactions'
                          ? 'transactions'
                          : navItem.title
                    }}
                  />
                </button>
              )
          )}
      </motion.div>
    </motion.div>
  );
};

export default MobileMenu;
