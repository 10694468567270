import Meta from '@components/meta';
import Card from './components/Card/Card';

const Login = () => {
  const metaOptions = {
    title: 'Login',
    keywords: 'authentication, login'
  };
  return (
    <>
      <Meta {...metaOptions} />
      <Card />
    </>
  );
};

export default Login;
