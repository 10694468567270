import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clearTable, setTable, setTableName } from '@store/data';
import { setPagination } from '@store/features/pagination';
import { useModifyData } from '@pages/tables/hooks/useModifyData';
import { ETableName } from '@common/utils/enums';
import { useSearchTollDocumentsMutation } from '@api/services/toll-documents';

export const useSetTable = ({ name }: { name: ETableName }) => {
  const dispatch = useAppDispatch();

  const body = useModifyData(name);
  const [searchTollDocuments] = useSearchTollDocumentsMutation();

  const customerId = useAppSelector(state => state.customer.id);
  const table = useAppSelector(state => state.table);
  const auth = useAppSelector(state => state.auth);
  // const pagination = useAppSelector(state => state.pagination);
  // const { data, isLoading, isSuccess, isError, error, isFetching } = useGetTollDocumentsQuery(
  //   [customerId, pagination.trips.currentPage - 1, pagination.trips.showBy],
  //   {
  //     skip: !customerId,
  //     pollingInterval: 600000
  //   }
  // );
  const handleTollDocuments = useCallback(async () => {
    dispatch(clearTable());
    dispatch(setTableName(name));
    if (
      customerId &&
      body.customerId &&
      name === ETableName.TOLLDOCUMENTS &&
      customerId === body.customerId
    ) {
      //   dispatch(setTable({ data, isLoading, isError, isSuccess, error, name, isFetching }));
      //   dispatch(
      //     setPagination({
      //       table: name,
      //       dataLength: totalElements || 0,
      //       totalPages: totalPages || 0
      //     })
      //   );
      // const { customerId: id, ...rest } = body;
      searchTollDocuments([{ ...body }, auth.email])
        .unwrap()
        .then(res => {
          dispatch(
            setTable({
              data: res.pageContent,
              isLoading: false,
              isError: false,
              isSuccess: true,
              error: undefined,
              isFetching: false
            })
          );
          dispatch(
            setPagination({
              table: ETableName.TOLLDOCUMENTS,
              dataLength: res.totalElements || 0,
              totalPages: res.totalPage || 0
            })
          );
        })
        .catch(e => {
          console.log(e);
          dispatch(
            setTable({
              data: undefined,
              isLoading: false,
              isError: true,
              isSuccess: false,
              error: undefined,
              isFetching: false
            })
          );
        });
    } else {
      dispatch(
        setTable({
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          error: undefined,
          isFetching: false
        })
      );
    }
  }, [body, dispatch, searchTollDocuments, name, customerId, auth.email]);

  useEffect(() => {
    handleTollDocuments().catch(e => console.log(e));
  }, [handleTollDocuments, table.fetch]);
};
