import styles from './email-sent.module.scss';

const EmailSent = ({
  handleEmailSent,
  action = 'forgot-password'
}: {
  action?: 'forgot-password';
  handleEmailSent: (value: [boolean, string]) => void;
}) => {
  return (
    <div className={styles.container}>
      {action === 'forgot-password' && (
        <>
          <p>
            A message has been sent to you by email with instructions on how to reset your password.
          </p>
          <p>
            No e-mail in your inbox or spam folder?{' '}
            <button type="button" onClick={() => handleEmailSent([false, ''])}>
              Let’s resend it.
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default EmailSent;
