import { v4 as uuid } from 'uuid';
import { formatNumber } from '@common/services/format';
import { GPSTollTimezone } from '@common/services/format/formatTimeZone';
import Highlighter from 'react-highlight-words';
import { FC } from 'react';
import styles from './table.module.scss';
import { ModalTableProps } from './types';

const Table: FC<ModalTableProps> = ({ estimatedTolls, searchValue, timezone }) => {
  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <section className={styles.row}>
          <section className={styles.text}>
            <h3 className={styles.date}>Date/Time</h3>
            <h3 className={styles.state}>State</h3>
            <h3 className={styles.agency}>Agency</h3>
            <h3 className={styles.facility}>Facility</h3>
            <h3 className={styles.plaza}>Plaza</h3>
            <h3 className={styles.toll}>GPS Calculated Tolls</h3>
          </section>
        </section>
      </div>
      <div className={styles.body}>
        {estimatedTolls &&
          estimatedTolls.map(toll => (
            <section className={styles.row} key={uuid()}>
              <section className={styles.text}>
                <p className={`${styles.date} ${toll.exitTime ? '' : styles['not-found']}`}>
                  {toll.exitTime ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                      autoEscape
                      textToHighlight={GPSTollTimezone(toll, timezone)}
                    />
                  ) : (
                    'Not Found'
                  )}
                </p>
                <p className={`${styles.state} ${toll.state ? '' : styles['not-found']}`}>
                  {toll.state ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                      autoEscape
                      textToHighlight={toll.state || ''}
                    />
                  ) : (
                    'Not Found'
                  )}
                </p>
                <p className={`${styles.agency} ${toll.agency ? '' : styles['not-found']}`}>
                  {toll.agency ? (
                    <abbr title={toll.agencyName || ''}>
                      <Highlighter
                        highlightClassName={styles['search-highlight']}
                        searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                        autoEscape
                        textToHighlight={toll.agency || ''}
                      />
                    </abbr>
                  ) : (
                    'Not Found'
                  )}
                </p>
                <p className={`${styles.facility} ${toll.facility ? '' : styles['not-found']}`}>
                  {toll.facility ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                      autoEscape
                      textToHighlight={toll.facility || ''}
                    />
                  ) : (
                    'Not Found'
                  )}
                </p>
                <p className={`${styles.plaza} ${toll.plaza ? '' : styles['not-found']}`}>
                  {toll.plaza ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                      autoEscape
                      textToHighlight={toll.plaza || ''}
                    />
                  ) : (
                    'Not Found'
                  )}
                </p>
                <p
                  className={`${styles.toll} ${
                    typeof toll?.calculatedAmount !== null &&
                    typeof toll?.calculatedAmount !== undefined
                      ? ''
                      : styles['not-found']
                  }`}
                >
                  {typeof toll?.calculatedAmount !== null &&
                  typeof toll?.calculatedAmount !== undefined ? (
                    <Highlighter
                      highlightClassName={styles['search-highlight']}
                      searchWords={searchValue ? [`${searchValue}`.trim()] : []}
                      autoEscape
                      textToHighlight={formatNumber(toll.calculatedAmount, 2, '$') || '0'}
                    />
                  ) : (
                    'Not Found'
                  )}
                </p>
              </section>
            </section>
          ))}
      </div>
    </section>
  );
};

export default Table;
