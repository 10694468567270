import Switch from '@common/ui/Switch';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FC, useEffect, useState } from 'react';
import Loader from '@common/ui/Loader';
import { INotification, updatedNotifications } from '@store/data';
import { usePromiseToastNotify } from '@common/hooks';
import api from '@api/services/common';
import SelectCustomer from '../selectCustomer';
import styles from './notification.module.scss';

const Notification: FC<{ notifyData: INotification | null }> = ({ notifyData }) => {
  const [emailReceive, setEmailReceive] = useState(false);
  const [emailProcess, setEmailProcess] = useState(false);
  const [issueProcess, setIssueProcess] = useState(false);
  const [autoSubcribeEmail, setAutoSubscribeEmail] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);
  const loading = useAppSelector(state => state.settings.loading);
  const dispatch = useAppDispatch();

  const toastify = usePromiseToastNotify();

  const handleToggle = (service: string) => {
    interface IRes {
      isSuccess: boolean;
      data: INotification;
    }

    if (service === 'email receive') {
      setEmailReceive(!emailReceive);
      toastify(
        async () => {
          const res = (await api.post('/toll-document/notification-setting', {
            acknowledgement_email: !emailReceive,
            statement_processed_email: emailProcess,
            failure_notification_email: issueProcess,
            auto_subscribe_email: autoSubcribeEmail
          })) as IRes;
          if (!res?.isSuccess) {
            setEmailReceive(emailReceive);
            throw new Error();
          } else {
            dispatch(
              updatedNotifications({
                acknowledgement_email: !emailReceive,
                statement_processed_email: emailProcess,
                failure_notification_email: issueProcess,
                auto_subscribe_email: autoSubcribeEmail
              })
            );
          }
        },
        'update',
        `${service} notification`
      );
    } else if (service === 'email process') {
      setEmailProcess(!emailProcess);
      toastify(
        async () => {
          const res = (await api.post('/toll-document/notification-setting', {
            acknowledgement_email: emailReceive,
            statement_processed_email: !emailProcess,
            failure_notification_email: issueProcess,
            auto_subscribe_email: autoSubcribeEmail
          })) as IRes;
          if (!res?.isSuccess) {
            setEmailProcess(emailProcess);
            throw new Error();
          } else {
            dispatch(
              updatedNotifications({
                acknowledgement_email: emailReceive,
                statement_processed_email: !emailProcess,
                failure_notification_email: issueProcess,
                auto_subscribe_email: autoSubcribeEmail
              })
            );
          }
        },
        'update',
        `${service} notification`
      );
    } else if (service === 'issue process') {
      setIssueProcess(!issueProcess);
      toastify(
        async () => {
          const res = (await api.post('/toll-document/notification-setting', {
            acknowledgement_email: emailReceive,
            statement_processed_email: emailProcess,
            failure_notification_email: !issueProcess,
            auto_subscribe_email: autoSubcribeEmail
          })) as IRes;
          if (!res?.isSuccess) {
            setIssueProcess(issueProcess);
            throw new Error();
          } else {
            dispatch(
              updatedNotifications({
                acknowledgement_email: emailReceive,
                statement_processed_email: emailProcess,
                failure_notification_email: !issueProcess,
                auto_subscribe_email: autoSubcribeEmail
              })
            );
          }
        },
        'update',
        `${service} notification`
      );
    } else if (service === 'auto subscribe process') {
      setAutoSubscribeEmail(!autoSubcribeEmail);
      toastify(
        async () => {
          const res = (await api.post('/toll-document/notification-setting', {
            acknowledgement_email: emailReceive,
            statement_processed_email: emailProcess,
            failure_notification_email: issueProcess,
            auto_subscribe_email: !autoSubcribeEmail
          })) as IRes;
          if (!res?.isSuccess) {
            setAutoSubscribeEmail(autoSubcribeEmail);
            throw new Error();
          } else {
            dispatch(
              updatedNotifications({
                acknowledgement_email: emailReceive,
                statement_processed_email: emailProcess,
                failure_notification_email: issueProcess,
                auto_subscribe_email: !autoSubcribeEmail
              })
            );
          }
        },
        'update',
        `${service} notification`
      );
    }
  };

  useEffect(() => {
    if (notifyData) {
      setEmailReceive(notifyData?.acknowledgement_email || false);
      setEmailProcess(notifyData?.statement_processed_email || false);
      setIssueProcess(notifyData?.failure_notification_email || false);
      setAutoSubscribeEmail(notifyData?.auto_subscribe_email || false);
    }
  }, [notifyData]);

  if (customerId) {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={styles['notify-container']}>
        <div className={styles.heading}>
          <h1>Email Uploads</h1>
        </div>
        <div className={styles['switch-container']}>
          <Switch
            toggle={emailReceive}
            handleToggle={() => {
              handleToggle('email receive');
            }}
          />
          <p className={styles['text-style']}>Email received successfully</p>
        </div>
        <div className={styles['switch-container']}>
          <Switch
            toggle={emailProcess}
            handleToggle={() => {
              handleToggle('email process');
            }}
          />
          <p className={styles['text-style']}>Email processed successfully</p>
        </div>
        <div className={styles['switch-container']}>
          <Switch
            toggle={issueProcess}
            handleToggle={() => {
              handleToggle('issue process');
            }}
          />
          <p className={styles['text-style']}>Issue processing email</p>
        </div>
        <div className={styles.heading}>
          <h1>Subscription Activity</h1>
        </div>
        <div className={styles['switch-container']}>
          <Switch
            toggle={autoSubcribeEmail}
            handleToggle={() => handleToggle('auto subscribe process')}
          />
          <p className={styles['text-style']}>Asset subscribed automatically</p>
        </div>
      </div>
    );
  }

  return <SelectCustomer label="Notifications" />;
};

export default Notification;
