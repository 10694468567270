/* eslint-disable */

import { useEffect } from 'react';

const SAOpenRoute = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const customer = searchParams.get('customer');

  const hashName = process.env.REACT_APP_ADDIN_HASH_NAME;

  useEffect(() => {
    window.location.href = `https://my.geotab.com/${customer?.toLowerCase()}/?page=sa#${hashName}`;
  }, []);

  return <div />;
};

export default SAOpenRoute;
