/* eslint-disable */
import { toast } from 'react-toastify';

export const usePromiseToastNotify = () => {
  return (
    promise: (() => Promise<void | unknown>) | Promise<void | unknown>,
    action:
      | 'delete'
      | 'refresh'
      | 'create'
      | 'send'
      | 'resend'
      | 'update'
      | 'upload'
      | 'import'
      | 'export',
    info: string,
    autoClose = true,
    altInfo?: string
  ) => {
    const actionText = {
      create: ['Creating', 'created', 'creating'],
      delete: ['Deleting', 'deleted', 'deleting'],
      send: ['Sending', 'send', 'sending'],
      resend: ['Resending', 'resent', 'resending'],
      refresh: ['Refreshing', 'refresh', 'refreshing'],
      update: ['Updating', 'updated', 'updating'],
      upload: ['Uploading', 'uploaded', 'uploading'],
      import: ['Importing', 'imported', 'importing'],
      export: ['Exporting', 'exported', 'exporting']
    };
    toast
      .promise(
        promise,
        {
          pending: `${actionText[action][0]} ${info}`,
          success: {
            render() {
              if (altInfo) {
                return altInfo;
              } else {
                return `Successfully ${actionText[action][1]} ${info}`;
              }
            }
          },
          error: {
            render({ data: res }) {
              return `Error occurred while ${actionText[action][2]} ${info}${
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ((res as any).message as string) || ((res as any).data as string)
                  ? `: ${((res as any).message as string) || ((res as any).data as string)}`
                  : ''
              }`;
            }
          }
        },
        {
          autoClose: autoClose ? 3000 : false,
          position: toast.POSITION.TOP_CENTER,
          style: { minHeight: 'fit-content', minWidth: 'fit-content' }
        }
      )
      .catch(e => console.log(e));
  };
};

export const useToast = () => {};
