import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { navigationBottom } from '../utils/navigation-links';
import { NavProps } from '../types';
import NavItem from '../NavItem';
import styles from './bottom-nav.module.scss';

const BottomNav: FC<NavProps> = ({ handleNavCollapse, navCollapse, notifications }) => {
  return (
    <div className={!navCollapse ? `${styles.container} ${styles.open}` : styles.container}>
      {navigationBottom?.map(
        navItem =>
          (navItem.title === 'signout' ||
            (navItem.title !== 'signout' && navItem.isPermitted(navItem.link))) && (
            <NavItem
              navCollapse={navCollapse}
              handleNavCollapse={handleNavCollapse}
              navItem={navItem}
              notifications={notifications}
              key={uuid()}
            />
          )
      )}
    </div>
  );
};

export default BottomNav;
