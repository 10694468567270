import styles from './no-query.module.scss';

const NoQuery = () => {
  return (
    <section className={styles.container}>
      <h3>No queries in search</h3>
    </section>
  );
};

export default NoQuery;
