import AlertTable from './Alert';
import { useAlert } from './hooks';

const Alert = ({
  vehicleId,
  commonTab,
  alertId
}: {
  vehicleId?: string;
  commonTab?: boolean;
  alertId?: string;
}) => {
  useAlert({ vehicleId });

  return <AlertTable vehicleId={vehicleId} commonTab={commonTab} alertId={alertId} />;
};

export default Alert;
