import { setCurrentPage } from '@store/features/pagination';
import { initSearch } from '@store/features/search';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
// import { UnionDataInterface, UserDataInterface } from '@common/types/data';
// import { searchUser } from '@pages/tables/users/helpers/searchUser';
import { setTable } from '@store/data';
import { useGetUsersQuery } from '@api/services/users';

export const useTableSearch = () => {
  const tableName = useAppSelector(state => state.table.name) as ETableName;
  // const tableData = useAppSelector(state => state.table.data) as UnionDataInterface[];
  const customerId = useAppSelector(state => state.customer.id);
  const dispatch = useAppDispatch();

  const { data, isLoading, isSuccess, isError, error, isFetching } = useGetUsersQuery(
    [customerId],
    {
      skip: tableName !== ETableName.USERS,
      pollingInterval: 600000
    }
  );

  const handleSearchValue = (term: string | number) => {
    // if (tableName === ETableName.USERS) {
    //   if (term !== '') {
    //     const filteredData = searchUser(term, tableData as UserDataInterface[]);
    //     console.log('filteredData', filteredData);
    //     dispatch(clearTable());
    //     dispatch(
    //       setTable({
    //         data: filteredData,
    //         name: ETableName.USERS,
    //         isLoading,
    //         isError,
    //         isSuccess,
    //         isFetching,
    //         error
    //       })
    //     );
    //   }
    if (tableName === ETableName.USERS && term === '') {
      dispatch(
        setTable({
          data,
          isLoading,
          isError,
          isSuccess,
          error,
          name: ETableName.USERS,
          isFetching
        })
      );
    }
    // } else {
    dispatch(
      initSearch({
        table: tableName,
        searchBy: term
      })
    );
    dispatch(
      setCurrentPage({
        table: tableName,
        currentPage: 1
      })
    );
    // }
  };
  return handleSearchValue;
};
