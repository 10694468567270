import Button from '@common/ui/Button';
import { Approve } from '@assets/icons';
import { useFormikContext } from 'formik';
import Loader from '@common/ui/Loader';

const SubmitButton = ({
  label = 'Done',
  icon,
  type = 'update',
  closeModal,
  fleet
}: {
  label?: string;
  icon?: JSX.Element;
  type?: 'create' | 'update';
  closeModal?: () => void;
  fleet?: boolean;
}) => {
  const formik = useFormikContext();

  return (
    <>
      {type === 'update' && (
        <>
          {!fleet ? (
            <Button
              icon={icon}
              appearance="button"
              size="xs"
              label={label}
              variant="g-secondary"
              onClick={() => {
                if (closeModal) closeModal();
              }}
            />
          ) : (
            <Button
              appearance="button"
              buttonType="submit"
              size="xs"
              label={formik.isSubmitting ? 'Saving...' : 'Save'}
              icon={formik.isSubmitting ? <Loader /> : <Approve />}
              variant="success-primary"
              disabled={formik.isSubmitting || !formik.dirty}
              onClick={formik.handleSubmit}
            />
          )}
        </>
      )}
      {type === 'create' && (
        <Button
          appearance="button"
          label={formik.isSubmitting ? 'Please wait...' : label}
          icon={formik.isSubmitting ? <Loader /> : icon}
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
          size="xs"
        />
      )}
    </>
  );
};

export default SubmitButton;
