/* eslint-disable */
import { UserDataInterface } from '@common/types/data';
import {
  baseApi,
  USERS_API,
  COGNITO_USER_ACTIONS_URL,
  AUTH_BE_URL,
  SUPERADMIN_API
} from '../config';
import { store } from '@store/index';
import _ from 'lodash';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { routes } from '@common/utils/route-names';
import { EAuthRole } from '@common/utils/enums';

export interface ICreateUser extends Omit<UserDataInterface, 'id' | 'statusCode' | 'sourceType'> {
  customerId: string;
}

export const usersApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Users', 'User']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getUsers: builder.query<UserDataInterface[], [string]>({
        query: ([customer_id]) => {
          const auth = store.getState().auth.app_role;
          const customerId = store.getState().customer.id;
          const isEmptyCustomer = (auth === EAuthRole.SUPER_ADMIN || auth === '') && !customerId;
          return {
            url: isEmptyCustomer ? SUPERADMIN_API : USERS_API,
            params: { 'customer-id': customer_id }
          };
        },
        providesTags: result => {
          return result
            ? [
                ...result.map(({ id }) => ({ type: 'Users' as const, id })),
                { type: 'Users', id: 'LIST' }
              ]
            : [{ type: 'Users', id: 'LIST' }];
        },
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            const transformedResponse = baseQueryReturnValue?.map((x: any) => {
              return {
                ...x,
                lastLogin: x?.lastLogin || x?.lastAccess || ''
              };
            });
            return transformedResponse;
          }
        }
      }),
      getUserById: builder.query<UserDataInterface, { id: string; token?: string }>({
        query: ({ id, token }) => ({
          url: `${USERS_API}/${id}`,
          headers: token
            ? {
                authorization: token
              }
            : undefined
        }),
        providesTags: ['User'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      getUserByEmail: builder.query<UserDataInterface, [string, string]>({
        query: ([customer_id, email]) => ({
          url: USERS_API,
          params: { email, 'customer-id': customer_id }
        }),
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue[0];
          }
        },
        providesTags: ['User']
      }),
      createUser: builder.mutation<UserDataInterface, ICreateUser>({
        queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          const body = {
            ..._arg,
            createType: 'addGeotabUser',
            type: _arg.app_role,
            status: 'active',
            sourceType: isGeotab ? 'GEOTAB' : 'DIRECT',
            userId: ''
          };
          const createBEUser = await fetchWithBQ({
            url: `${AUTH_BE_URL}/createuser`,
            method: 'post',
            body,
            responseHandler: response => response.json()
          });
          if (createBEUser.meta?.response?.status === 401) {
            SessionLogOut(isGeotab);
          }
          if (createBEUser.error) {
            return { error: createBEUser.error };
          }
          return { data: createBEUser.data as UserDataInterface };
        },
        invalidatesTags: [{ type: 'Users', id: 'LIST' }]
      }),
      updateUser: builder.mutation<unknown, UserDataInterface>({
        query: body => ({
          url: `${AUTH_BE_URL}/update-user`,
          method: 'put',
          body,
          responseHandler: async response => {
            if (response?.status === 401) {
              const isGeotab = store.getState().user?.sourceType
                ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
                : window.location !== window.parent.location;
              SessionLogOut(isGeotab);
            } else {
              const data = await response.json();
              return data;
            }
          }
        }),
        invalidatesTags: [{ type: 'Users', id: 'LIST' }, 'User']
      }),
      deleteUser: builder.mutation<unknown, string>({
        queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
          const getUser = await fetchWithBQ(`${USERS_API}/${_arg}`);
          if (getUser.error) {
            return { error: getUser.error };
          }
          const getUserData = getUser.data as UserDataInterface;
          const deleteBEUser = await fetchWithBQ({
            url: `${USERS_API}/${_arg}`,
            method: 'delete',
            headers: {
              'Content-type': 'application/json'
            }
          });
          if (deleteBEUser.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (deleteBEUser.error) {
            return { error: deleteBEUser.error };
          }
          const deleteAmplifyUser = await fetchWithBQ({
            url: COGNITO_USER_ACTIONS_URL || '',
            method: 'post',
            body: {
              type: 'delete',
              email: getUserData.email
            },
            responseHandler: response => response.json()
          });
          if (deleteAmplifyUser.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (deleteAmplifyUser.error) {
            return { error: deleteAmplifyUser.error };
          }
          return { data: deleteBEUser.data };
        },
        invalidatesTags: [{ type: 'Users', id: 'LIST' }, 'User']
      })
    })
  });

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserByEmailQuery,
  useLazyGetUserByEmailQuery,
  useLazyGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = usersApi;
