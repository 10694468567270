/*eslint-disable */
import { motion } from 'framer-motion';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useLogOut } from '@common/hooks';
import { navTextMotionVariants } from '@common/utils/motion-variants';
import styles from './nav-item.module.scss';
import { NavItemProps } from './types';
import Button from '@common/ui/Button';
import { supportLink } from '@common/utils/route-names';

const NavItem: FC<NavItemProps> = ({ navCollapse, handleNavCollapse, notifications, navItem }) => {
  const handleLogOut = useLogOut();
  const activeClassName = ({ isActive }: { isActive: boolean }) =>
    `${styles['nav-item']} ${!navCollapse ? styles.open : ''} ${
      isActive && navItem.title !== 'signout' ? styles.active : ''
    }`;

  return (
    <>
      {navItem.title === 'Support' ? (
        <div
          role="presentation"
          className={activeClassName({ isActive: false })}
          onClick={() => window.open(supportLink)}
        >
          <span className={styles.icon}>
            <navItem.icon />
          </span>
        </div>
      ) : navItem.title !== 'signout' ? (
        <NavLink
          onClick={() => {
            handleNavCollapse(true);
          }}
          className={activeClassName}
          to={navItem.link}
        >
          <span
            className={
              navItem.title === 'notifications' && notifications?.length !== 0 && navCollapse
                ? `${styles.icon} ${styles['notifications-icon']}`
                : styles.icon
            }
          >
            <navItem.icon />
          </span>
          {!navCollapse && (
            <motion.span {...navTextMotionVariants} className={styles.text}>
              {navItem.title}
              {navItem.title === 'notifications' && notifications?.length !== 0 && (
                <span className={styles['text-notifications']}>
                  {!(notifications && notifications?.length > 9) ? notifications?.length : '9+'}
                </span>
              )}
            </motion.span>
          )}
        </NavLink>
      ) : (
        <div className={`${styles['nav-item']} ${!navCollapse ? styles.open : ''}`}>
          <Button
            onClick={() => {
              handleLogOut();
            }}
            className={styles.icon}
            variant="tertiary"
            icon={<navItem.icon />}
            appearance="icon"
          />
          {!navCollapse && (
            <motion.span {...navTextMotionVariants} className={styles.text}>
              {navItem.title}
            </motion.span>
          )}
        </div>
      )}
    </>
  );
};

export default NavItem;
