/* eslint-disable */
import { IAuth } from '@store/features/auth';
import { validateToken } from './validate-token';

/* @typescript-eslint/no-unsafe-member-access */
export const authUserObj = (user: AnyObject): IAuth => ({
  id: user.username as string,
  app_role: user.app_role,
  email: user.email ? user.email : (user.attributes.email as string),
  modules: user.modules,
  sessionIsValid: validateToken(
    (user.accessToken
      ? user.accessToken
      : (user.signInUserSession.accessToken.jwtToken as string)) || ''
  ),
  accessToken: user.accessToken
    ? user.accessToken
    : (user.signInUserSession.accessToken.jwtToken as string),
  loading: false
});

export const authObj = (user: AnyObject): IAuth => ({
  id: user.data.userId || 'cognito',
  app_role: user.data.app_role,
  email: user.data.email,
  modules: user.data.modules,
  sessionIsValid: validateToken(user.data.accessToken || ''),
  accessToken: user.data.accessToken,
  loading: false
});
