/* eslint-disable */
import Accordion from '@common/ui/Accordion';
import { AlertType } from '@store/types';
import { Delete, GMap, NoteClipBoard } from '@assets/icons';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import Button from '@common/ui/Button';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Loader from '@common/ui/Loader';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { reloadAlerts, searchTrip } from '@store/data/fleetTabs';
import HtmlParse from 'html-react-parser';
import styles from './alert.module.scss';
import AlertPagination from './Pagination';
import AlertFilters from './AlertFilters';
import Trip from '../../Trip/Trip';
import api from '@api/services/common';
import { usePromiseToastNotify } from '@common/hooks';
import { formatAlertDate } from '@common/services/format/formatTimeZone';

const AlertTable = ({
  vehicleId,
  commonTab,
  alertId
}: {
  vehicleId?: string;
  commonTab?: boolean;
  alertId?: string;
}) => {
  const dispatch = useAppDispatch();
  const alertData = useAppSelector(state => state.fleetTabs.alerts);
  const loading = useAppSelector(state => state.fleetTabs.alertsLoading);
  const customerId = useAppSelector(state => state.customer.id);
  const tripData = useAppSelector(state => state.fleetTabs.tripData);
  const searchLoading = useAppSelector(state => state.fleetTabs.searchLoading);
  const toastify = usePromiseToastNotify();

  const data = alertData.slice().sort((a, b) => {
    if (a.id === alertId) {
      return -1;
    } else if (b.id === alertId) {
      return 1;
    } else {
      return 0;
    }
  });

  const [showTripDetail, setShowTripDetail] = useState(false);
  const header = ({
    alert_type,
    vehicle_name,
    timezone_converted_date,
    tz,
    trip_id,
    id
  }: AlertType) => (
    <div className={styles.header}>
      <span className={styles['col-1']}>
        <NoteClipBoard />
        <strong>{alert_type}</strong>
        detected on
        <span className={styles['vehicle-name']}>
          {vehicle_name ? vehicle_name : 'Unknown Vehicle'}
        </span>
      </span>
      <span className={styles['col-2']}>
        <span className={styles['time-stamp']}>{formatAlertDate(timezone_converted_date, tz)}</span>
        <span>
          <Button
            className={styles.map}
            variant="tertiary"
            appearance="icon"
            size="xs"
            icon={<GMap className={styles.icon} />}
            disabled={!trip_id}
            onClick={() => {
              setShowTripDetail(true);
              dispatch(
                //@ts-ignore
                searchTrip({
                  toll: {},
                  date: {
                    startDate: '',
                    endDate: ''
                  },
                  isMetric: false,
                  customerId: customerId,
                  pageIndex: 0,
                  pageSize: 10,
                  searchKey: trip_id,
                  sort: {
                    fieldName: '',
                    order: ''
                  },
                  quickFilter: {
                    vehicleNames: [],
                    licensePlateNumbers: [],
                    licensePlateStates: []
                  }
                })
              );
            }}
          />
          <Button
            className={styles.map}
            variant="tertiary"
            appearance="icon"
            size="xs"
            icon={<Delete className={styles.icon} />}
            onClick={e => {
              e.stopPropagation();
              toastify(
                api
                  .post(`/vehicle/alerts/remove?toll_insight_id=${id}`)
                  .then((res: { isSuccess: boolean }) => {
                    if (res.isSuccess) {
                      dispatch(reloadAlerts());
                    } else {
                      throw Error;
                    }
                  }),
                'delete',
                'alert'
              );
            }}
          />
        </span>
      </span>
    </div>
  );

  if (loading) {
    return (
      <div
        className={`${styles.loading} ${
          commonTab ? styles['common-loading-height'] : styles['loading-height']
        }`}
      >
        <Loader />
      </div>
    );
  }
  return (
    <>
      {!vehicleId && <AlertFilters />}
      <section
        className={`${styles.container} ${
          commonTab ? styles['common-container-height'] : styles['container-height']
        }`}
      >
        {data.length > 0 ? (
          <>
            {data.map((item: any) => (
              <Accordion
                minHeight="15rem"
                key={uuid()}
                headerElement={header(item)}
                className={styles.accordion}
                open={alertId === item.id}
              >
                <div className={styles.subject}>
                  {item.alert_type === 'Maximum Toll' ||
                  item.alert_type === 'Vehicle Misclassification' ||
                  item.alert_type === 'Near Duplicate' ||
                  item.alert_type === 'Exact Duplicate' ? (
                    <>
                      <span className={styles.amount}>
                        <b className={styles['text-header']}>BILLED AMOUNT</b>
                        <p>
                          {item?.billed_amount ? `$${Number(item?.billed_amount).toFixed(2)}` : '-'}
                        </p>

                        <b className={styles['text-header']}>EXPECTED AMOUNT</b>
                        <p>
                          {item?.expected_amount
                            ? `$${Number(item?.expected_amount).toFixed(2)}`
                            : '-'}
                        </p>
                      </span>
                      <span className={styles.description}>
                        <b className={styles['text-header']}>DESCRIPTION</b>
                        <div>{HtmlParse((item?.description || '').trim())}</div>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={styles['col-1']}>
                        <b className={styles['text-header']}>DESCRIPTION</b>
                        <div>{HtmlParse((item?.description || '').trim())}</div>
                      </span>
                      <span className={styles['col-2']}>
                        <b className={styles['text-header']}>ACTION</b>
                        <div>{HtmlParse((item?.action || '').trim())}</div>
                      </span>
                    </>
                  )}
                </div>
              </Accordion>
            ))}
            {showTripDetail && (
              <AnimatePresence exitBeforeEnter initial={false}>
                <div>
                  <Trip
                    data={tripData && tripData?.length > 0 ? tripData[0] : []}
                    loading={searchLoading}
                    customCloseFunc={() => {
                      setShowTripDetail(false);
                    }}
                    hasFooter={false}
                  />
                </div>
              </AnimatePresence>
            )}
          </>
        ) : (
          <span
            className={`${styles['no-data']} ${
              commonTab ? styles['common-no-data'] : styles['no-data-height']
            }`}
          >
            No Alerts to display
          </span>
        )}
      </section>
      <AlertPagination />
    </>
  );
};

export default AlertTable;
