import { FC } from 'react';
import Button from '@common/ui/Button';
import { tableNameByLength } from '@common/services/format';
import { useAppDispatch } from '@store/hooks';
import { selectVisibleCustomerOption } from '@store/data';
// import { routes } from '@common/utils/route-names';
//  import { Link, useLocation} from 'react-router-dom';
import styles from './basic-view.module.scss';
import { BasicViewProps } from './types';

const BasicView: FC<BasicViewProps> = ({ tableType, isEmptyCustomer }) => {
  //  const location = useLocation();
  const dispatch = useAppDispatch();
  return (
    <section className={styles.container}>
      <h3>No {tableNameByLength(2, tableType)} to display</h3>
      {isEmptyCustomer && (
        // <Link to={routes.customers} state={{ from: location }}>
        <Button
          className={styles['customer-button']}
          label={`Select customer to view ${tableNameByLength(2, tableType)}`}
          appearance="text"
          size="l"
          onClick={() => dispatch(selectVisibleCustomerOption())}
        />
        // </Link>
      )}
      {/* {!isEmptyCustomer &&
        ![
          ETableName.FLEET,
          ETableName.TRANSACTIONS,
          ETableName.TOLLDOCUMENTS,
          ETableName.TRIPS,
          ETableName.ROLES
        ].includes(tableType) && (
          <>
            <p>Click to add a new {tableNameByLength(1, tableType)}</p>
            <Button
              appearance="button"
              variant="primary"
              icon={<Plus />}
              label="Add new"
              size="l"
              onClick={openAddNewModal}
            />
          </>
        )} */}
    </section>
  );
};

export default BasicView;
