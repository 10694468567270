/* eslint-disable */
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { FieldArray, Formik, Form } from 'formik';
import { useAppSelector } from '@store/hooks';
import { getStateByCodeAndCountry } from '@common/utils/constants/helpers';
import { IGeotabVehicle } from '../../types';
import styles from './vehicles.module.scss';
import { initialValues, validationSchema } from './utils/form';
import Footer from '../layout/footer/Footer';
import { useSSOContext } from '../../context/SSOContext';
import ActionBlock from './action-block/ActionBlock';
import HistoricalTripNotice from './historical-trip-notice/HistoricalTripNotice';
import Empty from './empty/Empty';
import Header from './header/Header';
import Row from './row/Row';

const Vehicles = () => {
  const { gVehicles, handleVehicleDetails, handleNextTab } = useSSOContext();

  const isMetric = useAppSelector(state => state.auth.isMetric) as boolean;
  const [modifyVehicles] = useState([...gVehicles]);
  const [isImported, setIsImported] = useState(false);
  if (isMetric) {
    modifyVehicles.forEach(modifyVehicle => {
      modifyVehicle['VEHICLE HEIGHT'] =
        Math.round((modifyVehicle['VEHICLE HEIGHT'] / 39.37) * 100) / 100;
      modifyVehicle['VEHICLE WEIGHT'] =
        Math.round((modifyVehicle['VEHICLE WEIGHT'] / 2.205) * 100) / 100;
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={gVehicles.length > 0 ? { vehicles: gVehicles } : initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleVehicleDetails(values.vehicles);
        setSubmitting(false);
        handleNextTab(null, values.vehicles);
      }}
    >
      {formik => (
        <Form className={styles.container}>
          <div className={styles.content}>
            <ActionBlock setImportAction={data => setIsImported(data)} />
            <div className={styles['table-wrapper']}>
              <table className={styles.table}>
                <Header isSubscription={false} />
                {formik.values.vehicles?.length > 0 ? (
                  <FieldArray
                    name="vehicles"
                    render={() => (
                      <>
                        {formik.values.vehicles
                          // .filter(x => x.SUBSCRIPTION === 'ACTIVE')
                          ?.map((vehicle: IGeotabVehicle, index: number) => (
                            <Row
                              isSubscription={false}
                              key={uuid()}
                              index={index}
                              isImported={isImported}
                              vehicle={vehicle}
                              formikValues={formik}
                            />
                          ))}
                      </>
                    )}
                  />
                ) : (
                  <Empty />
                )}
              </table>
            </div>
          </div>
          <HistoricalTripNotice />
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default Vehicles;
