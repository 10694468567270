import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { useUploadTollDocumentMutation } from '@api/services/toll-documents';
import { refetchTableData } from '@store/data';

export const useHandleSubmit = () => {
  const dispatch = useAppDispatch();
  const [uploadTollDocument] = useUploadTollDocumentMutation();
  const toastify = usePromiseToastNotify();
  const { handleError, handleSuccess } = useHandleApiResponse();
  const auth = useAppSelector(state => state.auth);
  const user = useAppSelector(state => state.user);
  const customer = useAppSelector(state => state.customer);
  const handleSubmit = (
    values: {
      file: File | null;
      fileName?: string;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const formData = new FormData();
    const data = {
      file: values.file as File,
      fileName: values.fileName || values.file?.name || '',
      userId: user.id || auth.id || '',
      customerId: customer.id || ''
    };
    formData.set('file', data.file, data.fileName);
    formData.set('customerId', data.customerId);
    formData.set('userId', data.userId);
    toastify(
      uploadTollDocument([formData, auth.email])
        .unwrap()
        .then(() => {
          handleSuccess('Toll document uploaded successfully');
        })
        .catch((error: FetchBaseQueryError) => {
          console.log(error);
          handleError(error, 'upload', 'toll document');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(refetchTableData(true));
          dispatch(openModal(['api-response', 'add-new']));
        }),
      'upload',
      'toll document'
    );
  };

  return { handleSubmit };
};
