/*eslint-disable */
import Pagination from '@common/ui/Pagination';
import ShowBy from '@common/ui/ShowBy';
import { standardDropdownMotionVariants } from '@common/utils/motion-variants';
import { useTablePaginate } from './PageHook';
import styles from './alert.module.scss';

const AlertPagination = () => {
  const {
    currentPage,
    handlePageState,
    totalPages,
    showBy,
    showByValues,
    handleShowByState,
    dataLength,
    pagesArray
  } = useTablePaginate();

  return (
    <section className={styles.pagination}>
      <Pagination
        page={currentPage}
        handlePageState={handlePageState}
        totalPages={totalPages}
        pagesArray={pagesArray}
        dataLength={dataLength}
      />
      <ShowBy
        values={showByValues?.map((value: any) => `${value}`)}
        label="Show by"
        selection={showBy}
        handleSelectionChange={handleShowByState}
        disabled={showByValues?.length === 0}
        motionVariants={standardDropdownMotionVariants}
      />
    </section>
  );
};

export default AlertPagination;
