export function DoubleArrows({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1699 7.00029C9.98369 7.18766 9.87915 7.44111 9.87915 7.70529C9.87915 7.96948 9.98369 8.22293 10.1699 8.41029L13.7099 12.0003L10.1699 15.5403C9.98369 15.7277 9.87915 15.9811 9.87915 16.2453C9.87915 16.5095 9.98369 16.7629 10.1699 16.9503C10.2629 17.044 10.3735 17.1184 10.4954 17.1692C10.6172 17.22 10.7479 17.2461 10.8799 17.2461C11.012 17.2461 11.1427 17.22 11.2645 17.1692C11.3864 17.1184 11.497 17.044 11.5899 16.9503L15.8299 12.7103C15.9237 12.6173 15.9981 12.5067 16.0488 12.3849C16.0996 12.263 16.1257 12.1323 16.1257 12.0003C16.1257 11.8683 16.0996 11.7376 16.0488 11.6157C15.9981 11.4939 15.9237 11.3833 15.8299 11.2903L11.5899 7.00029C11.497 6.90656 11.3864 6.83217 11.2645 6.7814C11.1427 6.73063 11.012 6.70449 10.8799 6.70449C10.7479 6.70449 10.6172 6.73063 10.4954 6.7814C10.3735 6.83217 10.2629 6.90656 10.1699 7.00029Z"
        fill="currentColor"
      />
      <path
        d="M16.2908 7.00029C16.1045 7.18766 16 7.44111 16 7.70529C16 7.96948 16.1045 8.22293 16.2908 8.41029L19.8308 12.0003L16.2908 15.5403C16.1045 15.7277 16 15.9811 16 16.2453C16 16.5095 16.1045 16.7629 16.2908 16.9503C16.3838 17.044 16.4944 17.1184 16.6162 17.1692C16.7381 17.22 16.8688 17.2461 17.0008 17.2461C17.1328 17.2461 17.2635 17.22 17.3854 17.1692C17.5072 17.1184 17.6178 17.044 17.7108 16.9503L21.9508 12.7103C22.0445 12.6173 22.1189 12.5067 22.1697 12.3849C22.2205 12.263 22.2466 12.1323 22.2466 12.0003C22.2466 11.8683 22.2205 11.7376 22.1697 11.6157C22.1189 11.4939 22.0445 11.3833 21.9508 11.2903L17.7108 7.00029C17.6178 6.90656 17.5072 6.83217 17.3854 6.7814C17.2635 6.73063 17.1328 6.70449 17.0008 6.70449C16.8688 6.70449 16.7381 6.73063 16.6162 6.7814C16.4944 6.83217 16.3838 6.90656 16.2908 7.00029Z"
        fill="currentColor"
      />
    </svg>
  );
}
