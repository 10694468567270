/*eslint-disable */
import { VehicleDataInterface } from '@common/types/data';
import { useAppSelector } from '@store/hooks';
import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';
import { useHandleSubmit } from './hooks/useHandleSubmit';

const UpdateVehicleFormik = ({ children, data }: ReactChildren & { data: any }) => {
  const { handleSubmit } = useHandleSubmit();
  const user = useAppSelector(state => state.user);

  return (
    <Formik
      initialValues={data || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        values['userId'] = user.id;
        handleSubmit(values, setSubmitting);
      }}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default UpdateVehicleFormik;
