import * as Yup from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
  adminEmail: Yup.string().email('enter a valid email').required('email is required'),
  companyName: Yup.string().required('company name is required'),
  companyAddress: Yup.string().required('company address is required'),
  statusCode: Yup.string().default('INACTIVE'),
  geotabIntegration: Yup.boolean().default(false),
  geotabOrderNow: Yup.boolean().default(false),
  geotabDatabase: Yup.string().when('geotabIntegration', {
    is: (val: boolean) => val,
    then: Yup.string().required('geotab database is required')
  }),
  geotabServiceUser: Yup.string().when('geotabIntegration', {
    is: (val: boolean) => val,
    then: Yup.string().required('geotab service user is required')
  }),
  geotabServicePassword: Yup.string().when('geotabIntegration', {
    is: (val: boolean) => val,
    then: Yup.string().required('geotab service password is required')
  }),
  logo: Yup.mixed().default(null).nullable()
  // phoneNumber: Yup.string().matches(
  //   /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  //   'Invalid phone number'
  // )
});
