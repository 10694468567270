import { v4 as uuid } from 'uuid';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import Loader from '@common/ui/Loader/Loader';
import SearchBar from '@common/ui/SearchBar/SearchBar';
import { getSearchResults } from '@common/services/search';
import { useState } from 'react';
import Button from '@common/ui/Button/Button';
import { Plus } from '@assets/icons';
import { openModal } from '@store/features/modal';
import { ETableName } from '@common/utils/enums';
import { rowModalId } from '@common/services/format';
import { AnimatePresence } from 'framer-motion';
import { RowModal } from '@pages/tables/layouts/web-portal/components/modals';
import { UserDataInterface } from '@common/types/data';
import styles from './settingUserTable.module.scss';

const Users = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);
  const loading = useAppSelector(state => state.settings.loading);
  const data = useAppSelector(state => state.settings.users);
  const sourceType = useAppSelector(state => state.user.sourceType);
  const [editData, setEditData] = useState<UserDataInterface>({
    id: '',
    name: '',
    email: '',
    customerId: '',
    statusCode: '',
    roleCode: '',
    sourceType: ''
  });

  const isGeotab = sourceType ? sourceType.toUpperCase() === 'GEOTAB' : false;

  const [searchString, setSearchString] = useState<string | number>('');

  const header = ['name', 'email', 'role'];

  const searchValues = getSearchResults(data || [], searchString, [
    'name',
    'email',
    'roleCode'
  ]) as [UserDataInterface];

  const { activeModal: modal, id: modalId } = useAppSelector(state => state.modal);

  return loading ? (
    <Loader className={styles.loader} />
  ) : (
    <section className={styles.tableWrapper}>
      <div className={styles.title}>
        <SearchBar
          size="xs"
          className={styles['search-bar']}
          searchValue={searchString}
          handleSearchValue={text => {
            setSearchString(text);
          }}
          stayOpen={false}
          placeholder="Search name, email and role"
        />
        {!isGeotab && (
          <Button
            onClick={() => dispatch(openModal(['add-new', ETableName.USERS]))}
            appearance="button"
            size="s"
            label={`${customerId ? 'Add User' : 'Add Super Admin'}`}
            icon={<Plus />}
          />
        )}
      </div>
      <div className={styles.container}>
        {data && data.length > 0 ? (
          <>
            {searchValues && searchValues.length > 0 ? (
              <>
                <div className={styles.header}>
                  <section className={styles.row}>
                    <section className={styles.text}>
                      {header.map(heading => (
                        <h3>{heading}</h3>
                      ))}
                    </section>
                  </section>
                </div>
                <div className={styles.body}>
                  {searchValues &&
                    searchValues.map((item: UserDataInterface) => (
                      <section
                        role="button" // Add a role to indicate this is a button-like element
                        onClick={() => {
                          setEditData(item);
                          dispatch(
                            openModal(['data', rowModalId(ETableName.USERS, item.id || '')])
                          );
                        }}
                        onKeyDown={event => {
                          if (event.key === 'Enter' || event.key === 'Space') {
                            setEditData(item);
                            dispatch(
                              openModal(['data', rowModalId(ETableName.USERS, item.id || '')])
                            );
                          }
                        }}
                        tabIndex={0} // Ensure it's focusable
                        className={styles.row}
                        key={uuid()}
                      >
                        <section className={styles.text}>
                          <p id="name">{item?.name ? item.name : '-'}</p>
                          <p id="email">{item?.email ? item.email : '-'}</p>
                          <p id="role">{item?.roleCode ? item.roleCode : '-'}</p>
                        </section>
                      </section>
                    ))}
                </div>
              </>
            ) : (
              <div className={styles.noData}>
                <h3>{`No Result for  "${searchString}"`}</h3>
              </div>
            )}
          </>
        ) : (
          <div className={styles.noData}>
            <h3>No users to display</h3>
          </div>
        )}
      </div>
      <AnimatePresence exitBeforeEnter>
        {modal === 'data' && modalId === rowModalId(ETableName.USERS, editData.id) && (
          <RowModal data={editData} />
        )}
      </AnimatePresence>
    </section>
  );
};

export default Users;
