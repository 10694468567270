import { motion } from 'framer-motion';
import { FC } from 'react';
import styles from './switch.module.scss';
import { SwitchProps } from './types';

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30
};

const Switch: FC<SwitchProps> = ({ toggle, handleToggle, label = false }) => (
  <button
    data-switch-is-on={toggle}
    onClick={() => handleToggle()}
    type="button"
    className={styles.container}
  >
    <span className={styles.toggle}>
      <motion.span className={styles.handle} layout transition={spring} />
    </span>
    {label && <span className={styles.label}>{toggle ? 'Enabled' : 'Disabled'}</span>}
  </button>
);

export default Switch;
