/* eslint-disable no-unneeded-ternary */
import { FC } from 'react';
import { useAppDispatch } from '@store/hooks';
import { closeModal } from '@store/features/modal';
import Button from '@common/ui/Button';
import Checkbox from '@common/ui/Checkbox';
import styles from './modal-footer.module.scss';
import { ModalFooterProps } from './types';

const ModalFooter: FC<ModalFooterProps> = ({
  footerClassName = '',
  withCheckbox,
  checkboxLabel,
  isChecked,
  handleCheckbox,
  primaryButton = true,
  onActionClick,
  cancelButton = true,
  customExtraButtons,
  customPrimaryButton,
  customSecondaryButton,
  customCloseFunc
}) => {
  const dispatch = useAppDispatch();
  return (
    <footer className={`${styles.container} ${footerClassName}`}>
      {customExtraButtons ||
        (withCheckbox && (
          <Checkbox
            label={checkboxLabel}
            state={isChecked || 'unchecked'}
            onClick={() => handleCheckbox && handleCheckbox()}
          />
        ))}
      <div className={styles['default-buttons']}>
        {cancelButton && (
          <Button
            appearance="button"
            size="xs"
            label="Cancel"
            variant="tertiary"
            onClick={() => (customCloseFunc ? customCloseFunc() : dispatch(closeModal()))}
          />
        )}
        {customSecondaryButton ? customSecondaryButton : ''}
        {customPrimaryButton ||
          (primaryButton && (
            <Button
              appearance="button"
              size="xs"
              label="Done"
              variant="primary"
              onClick={() => onActionClick && onActionClick()}
            />
          ))}
      </div>
    </footer>
  );
};

export default ModalFooter;
