/* eslint-disable */
import styles from './confirm.module.scss';
import BillTable from './confirmTable';

const Confirmation = ({ data }: any) => {
  return (
    <div className={styles.container}>
      <BillTable data={data} />
      {/* <div className={styles.divider} />
      <BillDetails /> */}
    </div>
  );
};

export default Confirmation;
