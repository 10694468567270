import Button from '@common/ui/Button';
import { selectVisibleCustomerOption } from '@store/data';
import { useAppDispatch } from '@store/hooks';
import styles from './select-customer.module.scss';

const SelectCustomer = ({ label }: { label: string }) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles['select-customer']}>
      <Button
        className={styles['select-customer-button']}
        label={`Select customer to view ${label}`}
        appearance="text"
        size="l"
        onClick={() => dispatch(selectVisibleCustomerOption())}
      />
    </div>
  );
};

export default SelectCustomer;
