/*eslint-disable */
import Checkbox from '@common/ui/Checkbox';
import SelectInput2 from '@common/ui/FilterDropdown/SelectInput';
import { SelectInput } from '@common/ui/Form';
import { getStatesOfCountry } from '@common/utils/constants/helpers';
import { useSSOContext } from '@pages/geotab/context/SSOContext';
import { IAgencyList, IGeotabVehicle, ITagTypes } from '@pages/geotab/types';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { VEHICLE_CLASSES, VEHICLE_TYPES } from '../utils/constants';
import styles from './row.module.scss';
import axios from 'axios';
import { WEB_BE_URL } from '@api/config';
import { toast } from 'react-toastify';

const Row = ({
  index,
  vehicle,
  isImported,
  isSubscription,
  formikValues
}: {
  index: number;
  vehicle: IGeotabVehicle;
  isImported?: boolean;
  isSubscription: boolean;
  formikValues?: any;
}) => {
  const { selectSubscription, gAgencyList, gTagTypeList } = useSSOContext();
  const formik = useFormikContext<AnyObject>();
  const states = useMemo(() => getStatesOfCountry('US'), []);
  const parseAction = async (data: string, index: number, type: 'tag#' | 'issuingAgency') => {
    try {
      const clonedVehicles = [...formik.values.vehicles];
      clonedVehicles[index]['tagTypeList'] = null;
      if (type === 'tag#') {
        const transponderParseRes = (
          await axios.post(`${WEB_BE_URL}/customer-onboard/parse-tagnumber`, {
            tag_number: data
          })
        ).data;
        if (transponderParseRes.isSuccess && transponderParseRes.data) {
          const parsedIssuingAgency = gAgencyList?.find(
            x => Number(x.id) === transponderParseRes.data
          );
          if (parsedIssuingAgency && parsedIssuingAgency.name) {
            clonedVehicles[index]['TAG ISSUING AGENCY'] = parsedIssuingAgency.name;
            if (parsedIssuingAgency.is_toll_consolidator) {
              clonedVehicles[index]['TAG TYPE'] = 'Third Party';
              clonedVehicles[index]['tollConsolidator'] = true;
            } else {
              clonedVehicles[index]['TAG TYPE'] = '';
              clonedVehicles[index]['tollConsolidator'] = false;
              const tagTypeParseRes = (
                await axios.get(
                  `${WEB_BE_URL}/customer-onboard/parse-agency?agency_id=${transponderParseRes.data}`
                )
              ).data;
              if (
                tagTypeParseRes?.isSuccess &&
                tagTypeParseRes?.data &&
                tagTypeParseRes.data?.length
              ) {
                if (tagTypeParseRes.data?.length === 1) {
                  clonedVehicles[index]['TAG TYPE'] = tagTypeParseRes.data[0];
                } else {
                  clonedVehicles[index]['tagTypeList'] = tagTypeParseRes.data;
                }
              }
            }
          }
        } else {
          toast.info(`There is no 'Issuing Agency' for the entered 'Tag #'`, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } else if (type === 'issuingAgency') {
        const parsedIssuingAgency = gAgencyList?.find(x => x.name === data);
        clonedVehicles[index]['TAG ISSUING AGENCY'] = data;
        if (data.toLowerCase() !== 'none' && parsedIssuingAgency) {
          if (parsedIssuingAgency.is_toll_consolidator) {
            clonedVehicles[index]['TAG TYPE'] = 'Third Party';
            clonedVehicles[index]['tollConsolidator'] = true;
          } else {
            clonedVehicles[index]['TAG TYPE'] = '';
            clonedVehicles[index]['tollConsolidator'] = false;
            const tagTypeParseRes = (
              await axios.get(
                `${WEB_BE_URL}/customer-onboard/parse-agency?agency_id=${parsedIssuingAgency.id}`
              )
            ).data;
            if (
              tagTypeParseRes?.isSuccess &&
              tagTypeParseRes?.data &&
              tagTypeParseRes.data?.length
            ) {
              if (tagTypeParseRes.data) {
                clonedVehicles[index]['tagTypeList'] = tagTypeParseRes.data;
              }
            }
          }
        }
      }
      formik.setFieldValue('vehicles', clonedVehicles);
    } catch (err) {
      console.log('Err in parsing tag transponder ==> ', err);
    }
  };

  const statesNames = states.map(state =>
    state.isoCode ? `${state.isoCode} - ${state.name}` : state.name
  );
  return (
    <tbody className={styles.container}>
      {isSubscription ? (
        <tr
          className={`${styles['data-group']} ${
            vehicle.SUBSCRIPTION === 'ACTIVE' && styles['active']
          }`}
        >
          <td className={`${styles.data} ${styles.item} ${styles.vin}`}>
            <p>{vehicle.VIN}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-name']}`}>
            <p>{vehicle['VEHICLE NAME']}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-make']}`}>
            <p>{vehicle['VEHICLE MAKE']}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-model']}`}>
            <p>{vehicle['VEHICLE MODEL']}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-model']}`}>
            <Checkbox
              onClick={() =>
                selectSubscription(index, vehicle.SUBSCRIPTION === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
              }
              state={vehicle.SUBSCRIPTION === 'ACTIVE' ? 'checked' : 'unchecked'}
            />
          </td>
        </tr>
      ) : (
        <tr className={styles['data-group']}>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-subscription']}`}>
            <Checkbox
              onClick={() =>
                selectSubscription(index, vehicle.SUBSCRIPTION === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
              }
              state={vehicle.SUBSCRIPTION === 'ACTIVE' ? 'checked' : 'unchecked'}
            />
          </td>
          <td className={`${styles.data} ${styles.item} ${styles.vin}`}>
            <p>{vehicle.VIN}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-name']}`}>
            <p>{vehicle['VEHICLE NAME']}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-make']}`}>
            <p>{vehicle['VEHICLE MAKE']}</p>
          </td>
          <td className={`${styles.data} ${styles.item}  ${styles['vehicle-model']}`}>
            <p>{vehicle['VEHICLE MODEL']}</p>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['license-plate-number']}`}>
            {/* <p>{vehicle['LICENSE PLATE NUMBER']}</p> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['LICENSE PLATE NUMBER']`}
                disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
                defaultValue={formikValues.values.vehicles[index]['LICENSE PLATE NUMBER']}
                onChange={e => {
                  formikValues.values.vehicles[index]['LICENSE PLATE NUMBER'] = e.target.value;
                }}
                placeholder="Enter plate number"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['license-plate-state']}`}>
            <SelectInput
              className={styles.input}
              type="simple"
              disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
              includeSearch
              formField={`vehicles[${index}]['LICENSE PLATE STATE']`}
              values={statesNames}
              placeholder="Select License Plate State"
            />
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-type']}`}>
            <SelectInput
              className={styles.input}
              disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
              type="simple"
              formField={`vehicles[${index}]['VEHICLE TYPE']`}
              values={VEHICLE_TYPES}
              placeholder="Select Vehicle Type"
            />
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-class']}`}>
            <SelectInput
              className={styles.input}
              disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
              type="simple"
              formField={`vehicles[${index}]['VEHICLE CLASS']`}
              values={VEHICLE_CLASSES}
              placeholder="Select Vehicle Class"
            />
          </td>
          {/* <td className={`${styles.data} ${styles.item} ${styles['license-plate-state']}`}>
            <TextInput
              className={styles.input}
              name={`vehicles[${index}]['LICENSE PLATE']`}
              type="text"
              placeholder="Enter vehicle license plate"
            />
          </td> */}
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-axles']}`}>
            {/* <TextInput
              className={styles.input}
              name={`vehicles[${index}]['AXLE COUNT']`}
              type="number"
              placeholder="Enter vehicle axles"
            /> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['AXLE COUNT']`}
                disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
                defaultValue={formikValues.values.vehicles[index]['AXLE COUNT']}
                onChange={e => {
                  formikValues.values.vehicles[index]['AXLE COUNT'] = e.target.value;
                }}
                type={'number'}
                placeholder="Enter vehicle axles"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-tires']}`}>
            {/* <TextInput
              className={styles.input}
              name={`vehicles[${index}]['TIRE COUNT']`}
              type="number"
              placeholder="Enter vehicle tires"
            /> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['TIRE COUNT']`}
                disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
                defaultValue={formikValues.values.vehicles[index]['TIRE COUNT']}
                onChange={e => {
                  formikValues.values.vehicles[index]['TIRE COUNT'] = e.target.value;
                }}
                type={'number'}
                placeholder="Enter tire count"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-weight']}`}>
            {/* <TextInput
              className={styles.input}
              name={`vehicles[${index}]['VEHICLE WEIGHT']`}
              type="number"
              placeholder="Enter vehicle weight"
            /> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['VEHICLE WEIGHT']`}
                disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
                defaultValue={formikValues.values.vehicles[index]['VEHICLE WEIGHT']}
                onChange={e => {
                  formikValues.values.vehicles[index]['VEHICLE WEIGHT'] = e.target.value;
                }}
                type={'number'}
                placeholder="Enter vehicle weight"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['vehicle-height']}`}>
            {/* <TextInput
              className={styles.input}
              name={`vehicles[${index}]['VEHICLE HEIGHT']`}
              type="number"
              placeholder="Enter vehicle height"
            /> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['VEHICLE HEIGHT']`}
                disabled={vehicle.SUBSCRIPTION !== 'ACTIVE'}
                defaultValue={formikValues.values.vehicles[index]['VEHICLE HEIGHT']}
                onChange={e => {
                  formikValues.values.vehicles[index]['VEHICLE HEIGHT'] = e.target.value;
                }}
                type={'number'}
                placeholder="Enter vehicle height"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['tag-transponder']}`}>
            {/* <TextInput
              className={styles.input}
              name={`vehicles[${index}]['TAG #']`}
              type="text"
              placeholder="Enter vehicle tag/transponder"
            /> */}
            <div className={styles['input-container']}>
              <input
                id={`vehicles[${index}]['TAG #']`}
                disabled={
                  vehicle.SUBSCRIPTION !== 'ACTIVE' ||
                  formikValues.values.vehicles[index]['VEHICLE TYPE'] === 'TRAILER'
                }
                defaultValue={formikValues.values.vehicles[index]['TAG #']}
                onChange={e => {
                  formikValues.values.vehicles[index]['TAG #'] = e.target.value;
                }}
                onBlur={e => {
                  parseAction(e.target.value as string, index, 'tag#');
                }}
                type={'text'}
                placeholder="Enter vehicle tag/transponder"
              />
            </div>
          </td>
          <td className={`${styles.data} ${styles.item} ${styles['tag-type']}`}>
            <SelectInput2
              className={`${styles.input} ${styles['custom-select']}`}
              type="simple"
              includeSearch
              disabled={
                vehicle.SUBSCRIPTION !== 'ACTIVE' ||
                formikValues.values.vehicles[index]['VEHICLE TYPE'] === 'TRAILER'
              }
              selection={formik.values.vehicles[index]['TAG ISSUING AGENCY'] || ''}
              handleChange={data => parseAction(data as string, index, 'issuingAgency')}
              clearFilterSelection={() => {}}
              values={gAgencyList.map((x: IAgencyList) => x?.name)}
              placeholder="Select Vehicle Issuing Agency"
            />
          </td>
          <td className={`${styles.data} ${styles.item} `}>
            <SelectInput
              className={`${styles.input}${styles['tag-type']}`}
              type="simple"
              includeSearch
              formField={`vehicles[${index}]['TAG TYPE']`}
              disabled={
                vehicle.SUBSCRIPTION !== 'ACTIVE' ||
                formik.values?.vehicles[index]['TAG TYPE']?.toLowerCase() === 'third party' ||
                formikValues.values.vehicles[index]['VEHICLE TYPE'] === 'TRAILER'
              }
              values={
                formik.values.vehicles[index].tagTypeList &&
                formik.values.vehicles[index].tagTypeList.length > 0
                  ? formik.values.vehicles[index].tagTypeList
                  : gTagTypeList
                      ?.filter(x => x.tag_type_name.toLowerCase() !== 'third party')
                      .map((x: ITagTypes) => x?.tag_type_name)
              }
              placeholder="Select Vehicle Tag Type"
            />
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Row;
