import { closeModal } from '@store/features/modal';
import { useAppDispatch } from '@store/hooks';
import { motion } from 'framer-motion';
import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal-overlay.module.scss';

const ModalOverlay: FC<ReactChildren & { customCloseFunc?: () => void }> = ({
  children,
  customCloseFunc
}) => {
  const dispatch = useAppDispatch();
  const modalOverlayRef = useRef(null);
  const handleCloseModal = (e: { target: EventTarget }) => {
    if (modalOverlayRef.current === e.target) {
      if (customCloseFunc) {
        customCloseFunc();
      } else {
        dispatch(closeModal());
      }
    }
  };
  return createPortal(
    <motion.div
      ref={modalOverlayRef}
      className={styles.container}
      onClick={handleCloseModal}
      onKeyDown={handleCloseModal}
      role="button"
      tabIndex={0}
      initial={{ background: styles.backgroundHidden }}
      animate={{ background: styles.backgroundVisible }}
      exit={{ background: styles.backgroundHidden }}
      transition={{ duration: 0.4, delayChildren: 0.5 }}
    >
      {children}
    </motion.div>,
    document.getElementById('cl-portal') as HTMLDivElement
  );
};

export default ModalOverlay;
