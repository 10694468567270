/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-param-reassign */
import { Modifier, ModifierPhases, Placement, VirtualElement } from '@popperjs/core';
import { RefObject, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

export const usePopperWrapper = (
  boundaryElement?: RefObject<HTMLElement>,
  offset?: [number, number],
  position?: Placement,
  sameWidth?: boolean
) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const handleReferenceElement = (el: HTMLDivElement) => setReferenceElement(el);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const handlePopperElement = (el: HTMLElement) => setPopperElement(el);

  const modifiers: readonly Partial<Modifier<string, object>>[] = useMemo(
    () => [
      {
        name: 'flip',
        enabled: true,
        options: {
          boundary: boundaryElement?.current as HTMLElement
        }
      },
      {
        name: 'offset',
        options: {
          offset: offset || [0, 0]
        }
      },
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as ModifierPhases,
        requires: ['computeStyles'],
        fn({ state }) {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        effect({ state }) {
          state.elements.popper.style.minWidth = `${
            (state.elements.reference as (VirtualElement | Element) & { offsetWidth: number })
              .offsetWidth
          }px`;
        }
      }
    ],
    [boundaryElement, offset]
  );

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: 'fixed',
      placement: position || 'top',
      modifiers: sameWidth ? modifiers : modifiers.filter(modifier => modifier.name !== 'sameWidth')
    }
  );

  return {
    handleReferenceElement,
    handlePopperElement,
    popperStyles,
    popperAttributes,
    referenceElement
  };
};
