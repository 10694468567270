export const initialValues: {
  file: File | null;
  fileName: string;
  tollDocumentType: string;
  invoicingAgency: string;
} = {
  file: null,
  fileName: '',
  tollDocumentType: '',
  invoicingAgency: ''
};
