export const SignalMedium = ({ className }: ClassName) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15526_95616)">
        <rect x="0.773438" y="16.7461" width="3.87097" height="5.51724" rx="1" fill="#FFBF66" />
        <rect x="5.41992" y="12.8047" width="3.87097" height="9.45813" rx="1" fill="#FFBF66" />
        <rect x="10.0645" y="8.86719" width="3.87097" height="13.399" rx="1" fill="#FFBF66" />
        <rect x="14.709" y="4.92578" width="3.87097" height="17.3399" rx="1" fill="#DCDCE4" />
        <rect x="19.3555" y="0.984375" width="3.87097" height="21.2808" rx="1" fill="#DCDCE4" />
      </g>
      <defs>
        <clipPath id="clip0_15526_95616">
          <rect width="24" height="22.8571" fill="white" transform="translate(0 0.195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};
