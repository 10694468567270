/* eslint-disable @typescript-eslint/no-floating-promises */
import { Form, useFormikContext } from 'formik';
import { useState } from 'react';
import { FileInput, TextInput } from '@common/ui/Form';
import Toggle from '@common/ui/Toggle';
import styles from './create-customer.module.scss';

const CreateCustomer = () => {
  const formik = useFormikContext();
  const [toggle, setToggle] = useState(false);
  const handleToggle = (value: boolean) => {
    setToggle(value);
    formik.setFieldValue('geotabIntegration', value);
  };

  return (
    <Form className={styles.container}>
      <TextInput
        className={styles.input}
        label="Company Name*"
        name="companyName"
        type="text"
        placeholder="Enter company name"
      />
      <TextInput
        className={styles.input}
        label="Admin E-mail*"
        name="adminEmail"
        type="email"
        placeholder="Enter admin E-mail"
      />
      <TextInput
        className={styles.input}
        label="Company Address*"
        name="companyAddress"
        type="text"
        placeholder="Enter company address"
      />
      <FileInput
        className={styles.input}
        label="Company Logo"
        field="logo"
        format={['.jpeg', '.jpg', '.png', '.svg', '.webp']}
      />
      <TextInput
        className={styles.input}
        label="GeoTab Database"
        name="geotabDatabase"
        type="text"
        placeholder="Enter database name"
      />
      <div className={styles['geotab-toggle']}>
        <span className={styles.label}>GeoTab Integration</span>
        <Toggle className={styles.toggle} state={toggle} handleToggle={handleToggle} />
      </div>
      <TextInput
        className={styles.input}
        label="GeoTab Service User"
        name="geotabServiceUser"
        type="text"
        placeholder="Enter service user"
      />
      <TextInput
        type="password"
        className={styles.input}
        label="GeoTab Service Password"
        name="geotabServicePassword"
        placeholder="Enter service password"
      />
    </Form>
  );
};

export default CreateCustomer;
