import { CustomDatePicker } from '@common/ui/Form';
import { Calendar, Repeat } from '@assets/icons';
import Button from '@common/ui/Button';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { setDateFilters } from '@store/features/filters';
import { setCurrentPage } from '@store/features/pagination';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@common/utils/route-names';

dayjs.extend(duration);

const initDates = {
  from: dayjs().subtract(1, 'M').startOf('M').format('YYYY-MM-DDTHH:mm:ss'),
  to: dayjs().subtract(1, 'M').endOf('M').format('YYYY-MM-DDTHH:mm:ss')
};
const DateFilters = ({
  startLabel,
  endLabel,
  startPlaceholder,
  endPlaceholder,
  tableName,
  ...props
}: {
  className?: string | undefined;
  dropdownContainerEl?: React.RefObject<HTMLElement> | undefined;
  dropdownClassName?: string | undefined;
  startLabel?: string;
  endLabel?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
  tableName: ETableName;
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.filters[tableName]?.by);
  const dates = filters && 'date' in filters ? filters.date : { to: '', from: '' };
  const handleDates = (value: string, position: 'start' | 'end') => {
    if (position === 'start') {
      dispatch(setDateFilters({ table: tableName, dates: [value, dates?.to || ''] }));
    } else if (position === 'end') {
      dispatch(setDateFilters({ table: tableName, dates: [dates?.from || '', value] }));
    }
    dispatch(setCurrentPage({ table: tableName, currentPage: 1 }));
  };
  const navigate = useNavigate();
  const resetDates = () => {
    dispatch(
      tableName === ETableName.TRIPS
        ? setDateFilters({ table: tableName, dates: ['', ''] })
        : setDateFilters({ table: tableName, dates: [initDates.from || '', initDates.to || ''] })
    );
    if (tableName === ETableName.TRANSACTIONS) {
      navigate(`${routes.transactions}`);
    }
    dispatch(setCurrentPage({ table: tableName, currentPage: 1 }));
  };

  useEffect(() => {
    if (!dates?.to && !dates?.from && !params.document) {
      dispatch(
        tableName === ETableName.TRIPS
          ? setDateFilters({ table: tableName, dates: ['', ''] })
          : setDateFilters({ table: tableName, dates: [initDates.from || '', initDates.to || ''] })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);
  return (
    <>
      <CustomDatePicker
        {...props}
        maxDate={
          dates?.to && dayjs(dates.to).isValid()
            ? dayjs(dayjs(dates.to).subtract(1, 'day')).toDate()
            : dayjs().toDate()
        }
        minDate={dayjs().subtract(6, 'months').startOf('month').toDate()}
        label={startLabel || `Start Date`}
        icon={<Calendar />}
        initialDate={dates?.from || ''}
        handleDate={(date: string) => {
          handleDates(date, 'start');
        }}
        placeholder={startPlaceholder || `Select Start Date`}
        dates={dates}
        isStart
        // errorMessage={
        //   !checkSixMonthsAgo() ? 'Trips from the last six months can only be filtered' : ''
        // }
      />
      <CustomDatePicker
        {...props}
        minDate={
          dates?.from && dayjs(dates.from).isValid()
            ? dayjs(dayjs(dates.from).add(1, 'day')).toDate()
            : null
        }
        maxDate={dayjs().toDate()}
        label={endLabel || `End Date`}
        icon={<Calendar />}
        initialDate={dates?.to || ''}
        handleDate={(date: string) => handleDates(date, 'end')}
        placeholder={endPlaceholder || `Select End Date`}
        isEndOfDay
        dates={dates}
        isEnd
      />
      <Button
        appearance="button"
        label="Reset Calendar"
        icon={<Repeat />}
        variant="tertiary"
        size="s"
        onClick={resetDates}
      />
    </>
  );
};

export default DateFilters;
