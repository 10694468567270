import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { openModal } from '@store/features/modal';
import { useAppDispatch } from '@store/hooks';
import { ICreateCustomer, useCreateCustomerMutation } from '@api/services/customers';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';

export const useHandleSubmit = () => {
  const dispatch = useAppDispatch();
  const [createCustomer] = useCreateCustomerMutation();
  const { handleError, handleSuccess } = useHandleApiResponse();
  const toastify = usePromiseToastNotify();

  const handleSubmit = (
    values: ICreateCustomer & { logo: File | null },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const geotabIntegration = values.geotabIntegration || false;
    const formData = new FormData();
    formData.set('file', values.logo as File);
    toastify(
      createCustomer([{ ...values, geotabIntegration }, values.logo ? formData : null])
        .unwrap()
        .then(() => {
          handleSuccess(
            'Customer added successfully',
            'A message has been sent to the customer by email with login instructions.'
          );
        })
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'create', 'customer');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(openModal(['api-response', 'add-new']));
        }),
      'create',
      'customer'
    );
  };

  return { handleSubmit };
};
