export function Plaza({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3636 0.00951909C15.8165 0.0850569 15.4342 0.5898 15.5098 1.13689L17.9919 19.1145C18.0675 19.6616 18.5722 20.0439 19.1193 19.9683C19.6664 19.8928 20.0487 19.3881 19.9731 18.841L17.491 0.863348C17.4154 0.316254 16.9107 -0.0660187 16.3636 0.00951909ZM3.63769 0.0096302C4.18472 0.0856071 4.56659 0.590657 4.49061 1.13769L1.99061 19.1377C1.91464 19.6847 1.40959 20.0666 0.862554 19.9906C0.31552 19.9146 -0.0663467 19.4096 0.0096302 18.8626L2.50963 0.862554C2.58561 0.31552 3.09066 -0.0663467 3.63769 0.0096302ZM11.0002 1.00012C11.0002 0.447837 10.5525 0.000122071 10.0002 0.000122071C9.44796 0.000122071 9.00024 0.447837 9.00024 1.00012V4.00012C9.00024 4.55241 9.44796 5.00012 10.0002 5.00012C10.5525 5.00012 11.0002 4.55241 11.0002 4.00012V1.00012ZM10.0002 14.5001C10.5525 14.5001 11.0002 14.9478 11.0002 15.5001V19.0001C11.0002 19.5524 10.5525 20.0001 10.0002 20.0001C9.44796 20.0001 9.00024 19.5524 9.00024 19.0001V15.5001C9.00024 14.9478 9.44796 14.5001 10.0002 14.5001ZM11.0002 8.00012C11.0002 7.44784 10.5525 7.00012 10.0002 7.00012C9.44796 7.00012 9.00024 7.44784 9.00024 8.00012V11.5001C9.00024 12.0524 9.44796 12.5001 10.0002 12.5001C10.5525 12.5001 11.0002 12.0524 11.0002 11.5001V8.00012Z"
      />
    </svg>
  );
}
