/*eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETableName } from '@common/utils/enums';
import {
  fleetHeaders,
  customersHeaders,
  rolesHeaders,
  trailersHeaders,
  transactionsHeaders,
  tripsHeaders,
  usersHeaders,
  tollDocumentsHeaders
} from '@common/utils/table-headers';
import api from '@api/services/common';
import { store } from '../../index';
import { ITableHeadings, IHeading } from './types';

export * from './types';

export const initialState: ITableHeadings = {
  fleet: fleetHeaders,
  customers: customersHeaders,
  roles: rolesHeaders,
  trailers: trailersHeaders,
  transactions: transactionsHeaders,
  trips: tripsHeaders,
  users: usersHeaders,
  toll_documents: tollDocumentsHeaders
};

export const saveSettings = createAsyncThunk('saveSettings', async (data, thunkAPI) => {
  const res = await api.post('/ui-settings/save-ui-setting', data);
  return res;
});

export const resetToDefault = createAsyncThunk('resetToDefault', async (data, thunkAPI) => {
  const res = await api.post('/ui-settings/save-default-setting', data);
  return res.data;
});

const headingsSlice = createSlice({
  name: 'headings',
  initialState,
  reducers: {
    modifyActiveHeadings: (
      state,
      action: PayloadAction<{ table: ETableName; modHeadings: IHeading[] }>
    ) => {
      const headings = state;
      headings[action.payload.table] = action.payload.modHeadings;
    },
    setDefaultHeadings: (state, action: { payload: any }) => {
      let headings = state;
      headings = action.payload;
      return headings;
    },
    // resetHeadings: (state, action: { payload: ETableName }) => {
    //   const headings = state;
    //   headings[action.payload] = initialState[action.payload] as IHeading[];
    // },
    clearAllHeadings: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(resetToDefault.pending, (state, action) => {
      //store.getState().table.isLoading = true;
      return state;
    });
    builder.addCase(resetToDefault.fulfilled, (state, action) => {
      state = action.payload;
      //store.getState().table.isLoading = false;
      return state;
    });
  }
});

export const { modifyActiveHeadings, setDefaultHeadings, clearAllHeadings } = headingsSlice.actions;

export default headingsSlice.reducer;
