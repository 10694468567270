import { TollID } from '@assets/icons';
import Button from '@common/ui/Button';
import { openSubscribe } from '@store/features/subscribe';
import { useAppDispatch } from '@store/hooks';

const SubscribeButton = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <Button
        appearance="button"
        label="Subscribe"
        icon={<TollID />}
        variant="g-tertiary"
        size="s"
        onClick={() => dispatch(openSubscribe(true))}
        disabled={disabled}
      />
    </div>
  );
};

export default SubscribeButton;
