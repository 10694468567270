import { EModule } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';

export const useEditPermission = (route?: string) => {
  const modules = useAppSelector(state => state.auth.modules);

  const hasAccess = () => {
    if (route) {
      const hashRemovedRoute = route.replace(/^\/+/, '');
      return (
        modules.find(x => EModule[x.module_id] === hashRemovedRoute)?.permission.can_edit || false
      );
    }
    return false;
  };
  return hasAccess();
};
