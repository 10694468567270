export enum EAuthRole {
  VIEWER = 'VIEWER',
  SUPERVISOR = 'SUPERVISOR',
  CUSTOMER_ADMIN = 'ADMINISTRATOR',
  SUPER_ADMIN = 'SUPERADMIN',
  DEFAULTUSER = 'DEFAULTUSER'
}

export enum EUserRole {
  ADMIN = 'ADMINISTRATOR',
  SUPERVISOR = 'SUPERVISOR',
  VIEWER = 'VIEWER',
  SUPER_ADMIN = 'SUPERADMIN',
  DEFAULTUSER = 'DEFAULTUSER'
}
