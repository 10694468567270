import { IGeotabVehicle } from '@pages/geotab/types';
import * as Yup from 'yup';

export const initialValues = {
  vehicles: [] as IGeotabVehicle[]
};

export const validationSchema = Yup.object({
  vehicles: Yup.array().of(
    Yup.object().shape({
      VIN: Yup.string().default(''),
      // 'VEHICLE NAME': Yup.string().default(''),
      'VEHICLE NAME': Yup.string().default(''),
      'VEHICLE TYPE': Yup.string().default(''),
      'VEHICLE MODEL': Yup.string().default(''),
      'CURRENT DRIVER': Yup.string().default(''),
      'LICENSE PLATE NUMBER': Yup.string().default(''),
      // 'LICENSE PLATE': Yup.string()
      //   .default('')
      //   .matches(/[A-Z]{2} [A-Z0-9]+$/, { excludeEmptyString: true })
      //   .test('state-check', 'Uncorrect state code', val => checkStateCode(val, 'US'))
      //   .max(11),
      'TAG/TRANSPONDER': Yup.string().default(''),
      'VEHICLE WEIGHT': Yup.number(),
      'VEHICLE HEIGHT': Yup.number(),
      // 'TAG TYPE': Yup.string().required('vehicle tag type is required'),
      // 'VEHICLE CLASS': Yup.string().required('vehicle class is required'),
      'TAG TYPE': Yup.string().default(''),
      'VEHICLE CLASS': Yup.string().default(''),
      'VEHICLE WHEELS': Yup.number(),
      // .default(0)
      // .required('vehicle wheels is required')
      // .integer('value must be an integer')
      // .positive('only positive numbers allowed'),
      // .min(4, 'minimum of 4 wheels'),
      'VEHICLE AXLES': Yup.number(),
      // .required('vehicle axles is required')
      // .default(0)
      // .positive('only positive numbers allowed'),
      'VEHICLE TIRES': Yup.number()
      // .required('vehicle tires is required')
      // .default(0)
      // .positive('only positive numbers allowed')
    })
  )
});
