import { FC } from 'react';
import styles from './slider.module.scss';
import { useSlider } from './hooks';
import { SliderProps } from './types';

const Slider: FC<SliderProps> = ({
  min,
  max,
  step,
  className = '',
  handleInputFocus,
  userMin,
  userMax,
  handleUserMin,
  handleUserMax,
  disabled
}) => {
  const { rangeRef, userMinRef, userMaxRef } = useSlider(
    min,
    max,
    userMin as number,
    userMax as number
  );
  const inputBaseAttributes = {
    min,
    max,
    step: 1
  };

  return (
    <div
      aria-label="slider"
      className={`${styles.container} ${className}`}
      data-slider-disabled={disabled}
    >
      <span ref={rangeRef} className={styles.range} />
      <input
        {...inputBaseAttributes}
        className={`${styles.thumb1} ${styles.thumb} ${
          (userMin as number) > max - 100 ? styles['increase-index'] : ''
        }`}
        ref={userMinRef}
        type="range"
        step={step}
        data-index={0}
        aria-label="slider-min"
        aria-valuenow={userMin}
        aria-valuetext={`${userMin || min}`}
        value={userMin}
        onChange={e => {
          const value = Math.min(+e.target.value, (userMax as number) - 1);
          if (handleUserMin && !disabled) handleUserMin(value);
        }}
        disabled={disabled}
        onFocus={() => handleInputFocus && !disabled && handleInputFocus(true)}
        // onBlur={() => handleInputFocus && !disabled && handleInputFocus(false)}
        onMouseUp={() => {
          if (handleInputFocus && !disabled) {
            handleInputFocus(false);
          }
        }}
      />
      <input
        {...inputBaseAttributes}
        className={`${styles.thumb2} ${styles.thumb}`}
        ref={userMaxRef}
        type="range"
        step={step}
        data-index={1}
        aria-label="slider-max"
        aria-valuenow={userMax}
        aria-valuetext={`${userMax || max}`}
        value={userMax}
        onChange={e => {
          const value = Math.max(+e.target.value, (userMin as number) + 1);
          if (handleUserMax && !disabled) {
            handleUserMax(value);
          }
        }}
        disabled={disabled}
        onFocus={() => handleInputFocus && !disabled && handleInputFocus(true)}
        // onBlur={() => {
        //   if (handleInputFocus && !disabled) {
        //     handleInputFocus(false);
        //   }
        // }}
        onMouseUp={() => {
          if (handleInputFocus && !disabled) {
            handleInputFocus(false);
          }
        }}
      />
    </div>
  );
};

export default Slider;
