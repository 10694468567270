import { FC } from 'react';
import _ from 'lodash';
import SelectInput from '@common/ui/FilterDropdown/SelectInput';
import { Export } from '@assets/icons';
import { useDataExport } from '@common/hooks';
import { useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import styles from './export-dropdown.module.scss';

const ExportDropdown: FC<{ className?: string }> = ({ className }) => {
  const { handleDataExport } = useDataExport();
  const table = useAppSelector(state => state.table);
  const selected = useAppSelector(state => state.selectionIds[table.name as ETableName]);
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  const exportData = (type: string) => {
    if (table.name) {
      if (type === 'export') {
        handleDataExport('xlsx', table.name, table.data || [], selected);
      } else if (type === 'exportDetailData') {
        handleDataExport('xlsx', table.name, table.data || [], selected, true);
      }
    }
  };
  return (
    <div className={`${styles.container} ${className || ''}`}>
      <SelectInput
        className={`${styles.input}${className || ''}`}
        handleChange={data => {
          if (data && typeof data === 'string') {
            exportData(data);
          }
        }}
        includeSearch={false}
        hideArrows
        values={['Export', 'Export Detail Data']}
        selection={[]}
        icon={<Export />}
        label={isGeotab ? 'Export' : ''}
        noSelection
        clearFilterSelection={data => {
          console.log('Clear selection data ==> ', data);
        }}
        disabled={false}
        placeholder={isGeotab ? 'Export' : ''}
        type="simple"
      />
    </div>
  );
};

export default ExportDropdown;
