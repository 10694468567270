import Button from '@common/ui/Button';
import Modal from '@common/ui/Modal';
import { useFormikContext } from 'formik';
import { UploadTollDocument } from '../../../../forms/Create';
import { FormSubmitButton } from '../../../../buttons';
import styles from './toll-document-form.module.scss';

const TollDocumentForm = ({
  handleFileSelected
}: {
  handleFileSelected: (value: boolean) => void;
}) => {
  const formik = useFormikContext();
  return (
    <Modal
      withBackButton
      handleBackButtonClick={() => handleFileSelected(false)}
      title="Toll document upload"
      cancelButton={false}
      customPrimaryButton={<FormSubmitButton label="Done" type="create" />}
      customSecondaryButton={
        <Button
          label="Cancel"
          appearance="button"
          variant="tertiary"
          size="xs"
          onClick={() => {
            formik.resetForm();
            handleFileSelected(false);
          }}
        />
      }
      className={styles.modal}
    >
      <UploadTollDocument />
    </Modal>
  );
};

export default TollDocumentForm;
