import { Plus } from '@assets/icons';
import { tableNameByLength } from '@common/services/format';
import { EAuthRole, ETableName } from '@common/utils/enums';
import { openModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button';

const AddNew = () => {
  const dispatch = useAppDispatch();
  const tableName = useAppSelector(state => state.table.name as ETableName);
  const auth = useAppSelector(state => state.auth.app_role);
  const customerId = useAppSelector(state => state.customer.id);

  const isEmptyCustomer =
    auth === EAuthRole.SUPER_ADMIN && !customerId && tableName === ETableName.USERS;

  const addButtonLabel = isEmptyCustomer
    ? `Add new Super Admin`
    : `Add new ${tableNameByLength(1, tableName)}`;
  return (
    <Button
      label={addButtonLabel}
      icon={<Plus />}
      appearance="button"
      variant="primary"
      size="s"
      onClick={() => dispatch(openModal(['add-new', tableName]))}
    />
  );
};

export default AddNew;
