import Meta from '@components/meta';
import Card from './components/Card/Card';

const ForgotPassword = () => {
  const metaOptions = {
    title: 'Forgot Password',
    keywords: 'authentication, forgot password'
  };
  return (
    <>
      <Meta {...metaOptions} />
      <Card />
    </>
  );
};

export default ForgotPassword;
