import Errors from '@pages/errors';
import { SecondaryLayout } from '@app/layouts';
import { Route } from 'react-router-dom';

const ErrorRoutes = () => {
  return (
    <Route element={<SecondaryLayout />}>
      <Route path="/403" element={<Errors code={403} />} />
      <Route path="/501" element={<Errors code={501} />} />
      <Route path="*" element={<Errors code={404} />} />
    </Route>
  );
};

export default ErrorRoutes;
