/* eslint-disable */
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@store/hooks';
import { IHeading } from '@store/features/headings';
import { ETableName } from '@common/utils/enums';
import { AnimatePresence, motion } from 'framer-motion';
import RowCheckbox from './Checkbox/RowCheckbox';
import RowTextContent from './TextContent/RowTextContent';
// import TollDocumentIcons from './TollDocumentIcons/TollDocumentIcons';
import MenuIcon from './MenuIcon/MenuIcon';
import { useCheckboxState } from './hooks';
import { RowProps } from './types';
import styles from './row.module.scss';
import { ConfirmDeleteModal, TableDisplayModal } from '../../modals';

const Row: FC<RowProps> = ({ variant, data, dropdownContainerEL }) => {
  const table = useAppSelector(state => state.table);
  const tableName = table.name as ETableName;
  const { id } = useParams();
  let headings = useAppSelector(state => state.headings[tableName]) as IHeading[];
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  if (tableName === ETableName.TRANSACTIONS) {
    if (isActualTollTab) {
      const fieldsToRemove = ['trip_id'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    } else {
      const fieldsToRemove = ['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    }
  }
  const { activeModal: modal, id: storedModalId } = useAppSelector(state => state.modal);
  const { checkboxState } = useCheckboxState(variant, tableName, table.data || [], data);
  const rowRef = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(0);
  const [isTripToBeHighlighted, setIsTripToBeHighlighted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (id && tableName) {
      setIsTripToBeHighlighted(tableName === ETableName.TRIPS && data?.id === id);
    }
  }, [id, table.name]);
  useEffect(() => {
    setHeight(rowRef.current?.clientHeight || 0);
  }, [rowRef.current]);

  return (
    <>
      <motion.section
        ref={rowRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={`${styles.container} ${id == data?.id || isHovered ? styles.checked : ''} ${
          styles[`container-${variant}`]
        } ${styles[checkboxState]} ${isTripToBeHighlighted ? styles['highlight'] : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <RowCheckbox
          height={height}
          checkboxState={checkboxState}
          variant={variant}
          data={data}
          table={table}
          isHovered={id == data?.id || isHovered}
        />
        <RowTextContent variant={variant} data={data} table={table} />
        {/* {tableName === ETableName.TOLLDOCUMENTS && (
          <TollDocumentIcons variant={variant} data={data} />
        )} */}
        <MenuIcon
          height={height}
          variant={variant}
          checkboxState={checkboxState}
          data={data}
          table={table}
          dropdownContainerEL={dropdownContainerEL}
          isHovered={id == data?.id || isHovered}
        />
      </motion.section>
      <ConfirmDeleteModal
        tableName={tableName}
        data={data ? [data] : []}
        modalId={data?.id as string}
      />
      <AnimatePresence>
        {variant === 'header' && modal === 'table-display' && tableName === storedModalId && (
          <TableDisplayModal headings={headings} tableName={tableName} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Row;
