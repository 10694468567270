import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import TollBillSummary from './toll-bill-summary/TollBillSummary';
import MonthlyDetailHeader from './header/monthlyDetailHeader';
import TollInsights from '../history/toll-insights/TollInsights';
import styles from './index.module.scss';
import TripInsights from '../history/trip-insights/TripInsights';
import SavingsOpportunities from './savings-opportunities/SavingsOpportunities';
import { useDateRangeData } from './header/hooks/useDateRangeData';

const MonthlyDetail = () => {
  const { data, loader } = useDateRangeData();

  const [selectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    const generateMonthOptions = () => {
      return data.map(date => moment.utc(date).format('MMMM YYYY'));
    };
    if (data.length > 0) {
      const months = generateMonthOptions();
      if (!months.includes(selectedMonth)) {
        setSelectedMonth(months[0]);
      }
    }
  }, [data, selectedMonth]);

  return (
    <div className={styles.container}>
      {loader ? (
        // Skeleton Loader
        <>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ marginBottom: '16px' }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ marginBottom: '16px' }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ marginBottom: '16px' }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ marginBottom: '16px' }}
          />
        </>
      ) : (
        selectedMonth && (
          <>
            <MonthlyDetailHeader
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              dateRanges={data}
            />
            <SavingsOpportunities selectedMonth={selectedMonth} />
            <TollBillSummary selectedMonth={selectedMonth} />
            <TollInsights selectedMonth={selectedMonth} />
            <TripInsights selectedMonth={selectedMonth} />
          </>
        )
      )}
    </div>
  );
};

export default MonthlyDetail;
