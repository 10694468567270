import { CustomerDataInterface } from '@common/types/data';
import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';
import { useHandleSubmit } from './hooks/useHandleSubmit';

const UpdateCustomerFormik = ({
  children,
  data
}: ReactChildren & { data: CustomerDataInterface & { logo?: File | null } }) => {
  const { handleSubmit } = useHandleSubmit();

  return (
    <Formik
      initialValues={data || initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default UpdateCustomerFormik;
