/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useFormikContext } from 'formik';
import { RcFile, UploadProgressEvent, UploadProps } from 'rc-upload/lib/interface';
import { useState } from 'react';

export const useFileUpload = (
  field: string,
  handleFileSelected: (value: boolean) => void,
  format?: string[]
) => {
  const formik = useFormikContext();
  const [file, setFile] = useState<RcFile | null>(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState([false, '']);

  const handleFile = (value: RcFile | null) => setFile(value);
  const handleDragging = (value: boolean) => setDragging(value);

  const props: UploadProps = {
    type: 'drag',
    customRequest: async ({ onSuccess }) => {
      setTimeout(() => {
        if (onSuccess) onSuccess('ok');
      }, 0);
    },
    accept: format?.join(', '),
    beforeUpload(rcFile: RcFile) {
      setDragging(false);
      setUploading(true);
      const currentSize = rcFile.size;

      if (
        currentSize === 0 ||
        currentSize >= 10000000 ||
        (format && !format?.includes(`.${rcFile.name.split('.').pop() as string}`.toLowerCase()))
      ) {
        setFile(null);
        setError([
          true,
          format
            ? `File should be of valid format (${format?.join(', ')}) and should be less than 10MB`
            : 'File should be less than 10MB'
        ]);
        if (field) {
          formik.setFieldValue(field, null);
          formik.setFieldError('fileName', '');
        }
        setUploading(false);
        return Promise.reject(rcFile);
      }
      setFile(rcFile);
      if (field) {
        formik.setFieldValue(field, rcFile);
        formik.setFieldValue('fileName', rcFile.name);
      }
      return new Promise(resolve => {
        resolve(rcFile);
      });
    },
    onSuccess() {
      setError([false, '']);
      setUploading(false);
      handleFileSelected(true);
    },
    onProgress(step: UploadProgressEvent) {
      const fulfilled = Math.round(step.percent as number) === 100;
      setUploading(!fulfilled);
    },
    onError() {
      setError([true, '']);
      setUploading(false);
    }
  };

  return { file, dragging, uploading, error, props, handleFile, handleDragging };
};
