import Modal from '@common/ui/Modal';
import { CustomerDataInterface } from '@common/types/data';
import { FormSubmitButton } from '../../../buttons';
import { UpdateCustomer, UpdateCustomerFormik } from '../../../forms/Update';
import styles from './customer.module.scss';

const Customer = ({ data }: { data: CustomerDataInterface }) => {
  return (
    <UpdateCustomerFormik data={data}>
      <Modal
        activeTitle="singleHighlight"
        navigation={[data?.companyName || 'Details']}
        customPrimaryButton={<FormSubmitButton customer />}
        cancelButton={false}
        className={styles.modal}
      >
        <UpdateCustomer />
      </Modal>
    </UpdateCustomerFormik>
  );
};

export default Customer;
