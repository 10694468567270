export function DragIcon({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="7.5" r="1.5" fill="#666687" />
      <circle cx="9.5" cy="12" r="1.5" fill="#666687" />
      <circle cx="9.5" cy="16.5" r="1.5" fill="#666687" />
      <circle cx="14.5" cy="7.5" r="1.5" fill="#666687" />
      <circle cx="14.5" cy="12" r="1.5" fill="#666687" />
      <circle cx="14.5" cy="16.5" r="1.5" fill="#666687" />
    </svg>
  );
}
