import { useAppSelector } from '@store/hooks';
import { AnimatePresence } from 'framer-motion';
import ApiResponse from '../ApiResponse';
import Customer from './Customer/Customer';
import User from './User/User';
import TollDocument from './TollDocument/TollDocument';
import Fleet from './Vehicle/Vehicle';
import Transaction from './Transaction/Transaction';

const AddNewModal = () => {
  const {
    activeModal: modal,
    id: modalId,
    responseType,
    responseAction,
    responseMessage
  } = useAppSelector(state => state.modal);

  return (
    <AnimatePresence exitBeforeEnter>
      {modal === 'add-new' && modalId === 'customers' && <Customer key="customers" />}
      {modal === 'add-new' && modalId === 'users' && <User key="users" />}
      {modal === 'add-new' && modalId === 'toll_documents' && <TollDocument key="toll-documents" />}
      {modal === 'add-new' && modalId === 'transactions' && <Transaction key="transactions" />}
      {modal === 'add-new' && modalId === 'fleet' && <Fleet key="fleet" />}
      {responseType && modal === 'api-response' && modalId === 'add-new' && (
        <ApiResponse
          key="api-response"
          type={responseType}
          action={responseAction as string}
          message={responseMessage}
        />
      )}
    </AnimatePresence>
  );
};

export default AddNewModal;
