import { useState } from 'react';

export const useHandleActiveTitle = (initialTitle: string) => {
  const [activeTitle, setActiveTitle] = useState(initialTitle || 'details');
  const [prevTitle, setPrevTitle] = useState('');
  const handleActiveTitle = (value: string) =>
    setActiveTitle(prev => {
      setPrevTitle(prev);
      return value;
    });

  return { activeTitle, prevTitle, handleActiveTitle };
};
