/* eslint-disable */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api/services/common';
import { AlertType } from '@store/types';

interface IFleetTabs {
  history: any;
  trips: any;
  loading: boolean;
  alertsLoading: boolean;
  searchLoading: boolean;
  tagTypes?: {
    tag_type_id: string;
    tag_type_name: string;
  }[];
  filteredTagTypes?: {
    tag_type_id: string;
    tag_type_name: string;
  }[];
  statementTypes?: {
    type: string;
    description: string;
  }[];
  alerts: AlertType[];
  alertFilters: {
    name: string[];
    alert_type: number[];
    date: {
      start: string | null;
      end: string | null;
    };
  };
  tripData: any;
  reloadAlerts: number;
}

const initialState: IFleetTabs = {
  history: null,
  trips: null,
  loading: false,
  alertsLoading: false,
  searchLoading: false,
  tagTypes: [],
  filteredTagTypes: [],
  statementTypes: [],
  alerts: [],
  alertFilters: {
    name: [],
    alert_type: [],
    date: {
      start: '',
      end: ''
    }
  },
  tripData: {},
  reloadAlerts: 0
};

export const fleetHistory = createAsyncThunk('getHistory', async (data, thunkAPI) => {
  const res = await api.post('/vehicle/audit', data);
  return res;
});

export const fleetTrips = createAsyncThunk('getTrips', async (data, thunkAPI) => {
  const res = await api.JavaPost('/trips/v1/search', data);
  return res;
});

export const fleetAlerts = createAsyncThunk('getAlerts', async data => {
  const res = await api.post(`/vehicle/alerts`, data);
  return res.data;
});

export const getTagTypes = createAsyncThunk('getTagTypes', async () => {
  const tagTypes = await api.get(`/vehicle/tag-types`);
  tagTypes.data.sort((a: any, b: any) => {
    if (a.tag_type_name < b.tag_type_name) {
      return -1;
    }
    if (a.tag_type_name > b.tag_type_name) {
      return 1;
    }
    return 0;
  });
  tagTypes.data.unshift({
    tag_type_id: null,
    tag_type_name: 'None'
  });
  return tagTypes.data;
});

export const getFilteredTagTypes = createAsyncThunk(
  'getFilteredTagTypes',
  async (data: { agencyID: number }, thunkAPI) => {
    const tagTypes = await api.get(`/customer-onboard/parse-agency?agency_id=${data.agencyID}`);
    tagTypes.data.sort((a: any, b: any) => {
      if (a.tag_type_name < b.tag_type_name) {
        return -1;
      }
      if (a.tag_type_name > b.tag_type_name) {
        return 1;
      }
      return 0;
    });
    return tagTypes.data;
  }
);

export const getStatementTypes = createAsyncThunk('getStatementTypes', async () => {
  const statementTypes = await api.JavaGet(`/lists/v1/statement-types`);
  statementTypes.sort((a: any, b: any) => {
    if (a.type < b.type) {
      return -1;
    }
    if (a.type > b.type) {
      return 1;
    }
    return 0;
  });
  return statementTypes;
});

export const searchTrip = createAsyncThunk('searchTrip', async data => {
  const res = await api.JavaPost(`/trips/v1/search`, data);
  return res;
});

export const FleetTabSlice = createSlice({
  name: 'FleetTabs',
  initialState,
  reducers: {
    alertApiInitiate: state => {
      return { ...state, alertsLoading: true };
    },
    setAlertData: (state, action) => {
      return { ...state, alertsLoading: false, alerts: action.payload };
    },
    setVehicleNames: (state, action: PayloadAction<string[]>) => {
      state.alertFilters.name = action.payload;
    },
    clearVehicleNames: state => {
      state.alertFilters.name = [];
    },
    setAlertTypes: (state, action: PayloadAction<number[]>) => {
      state.alertFilters.alert_type = action.payload;
    },
    clearAlertTypes: state => {
      state.alertFilters.alert_type = [];
    },
    setDate: (state, action: PayloadAction<{ start: string | null; end: string | null }>) => {
      state.alertFilters.date = action.payload;
    },
    clearAlertFilters: state => {
      state.alertFilters.name = [];
      state.alertFilters.alert_type = [];
      state.alertFilters.date = {
        start: '',
        end: ''
      };
    },
    reloadAlerts: state => {
      return { ...state, reloadAlerts: state.reloadAlerts + 1 };
    }
  },
  extraReducers: builder => {
    builder.addCase(fleetHistory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fleetHistory.fulfilled, (state, action) => {
      state.history = action.payload;
      state.loading = false;
    });
    builder.addCase(fleetTrips.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fleetTrips.fulfilled, (state, action) => {
      state.trips = action.payload;
      state.loading = false;
    });
    builder.addCase(getTagTypes.pending, state => {
      state.loading = true;
    });
    builder.addCase(getTagTypes.fulfilled, (state, action) => {
      state.tagTypes = action.payload;
      state.loading = false;
    });
    builder.addCase(getFilteredTagTypes.pending, state => {
      state.loading = true;
    });
    builder.addCase(getFilteredTagTypes.fulfilled, (state, action) => {
      state.filteredTagTypes = action.payload;
      state.loading = false;
    });
    builder.addCase(getStatementTypes.pending, state => {
      state.loading = true;
    });
    builder.addCase(getStatementTypes.fulfilled, (state, action) => {
      state.statementTypes = action.payload;
      state.loading = false;
    });
    builder.addCase(fleetAlerts.pending, state => {
      state.alertsLoading = true;
    });
    builder.addCase(fleetAlerts.fulfilled, (state, action) => {
      state.alerts = action.payload;
      state.alertsLoading = false;
    });
    builder.addCase(searchTrip.pending, state => {
      state.searchLoading = true;
    });
    builder.addCase(searchTrip.fulfilled, (state, action) => {
      state.tripData = action.payload.pageContent;
      state.searchLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  alertApiInitiate,
  setAlertData,
  setVehicleNames,
  clearVehicleNames,
  setAlertTypes,
  clearAlertTypes,
  clearAlertFilters,
  setDate,
  reloadAlerts
} = FleetTabSlice.actions;

export default FleetTabSlice.reducer;
