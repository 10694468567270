/* eslint-disable */
import HtmlParse from 'html-react-parser';
import styles from './sa.module.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { saTemplate } from '@store/data/serviceAgreement';
import Loader from '@common/ui/Loader/Loader';
import Button from '@common/ui/Button/Button';
import api from '@api/services/common';
import { EAuthRole, EUserRole } from '@common/utils/enums';

const ServiceAgreement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const auth = useAppSelector(state => state.auth);
  const customerId = useAppSelector(state => state.customer.id);
  const load = useAppSelector(state => state.serviceAgreement.loading);
  const SA_Template = useAppSelector(state => state.serviceAgreement.saTemplate);

  useEffect(() => {
    dispatch(saTemplate({ customer_id: customerId }));
  }, [customerId]);

  if (!load && customerId?.length > 0) {
    if (auth?.app_role === EAuthRole.CUSTOMER_ADMIN) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>SERVICE AGREEMENT</div>
          <div className={styles.body}>
            {HtmlParse(
              `${SA_Template?.service_agreement ? JSON.parse(SA_Template?.service_agreement) : ''}`
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.buttonWrapper}>
              {SA_Template?.agreement_accepted && (
                <Button
                  appearance="button"
                  size="xs"
                  label={'Back To Dashboard'}
                  variant="g-secondary"
                  onClick={() => {
                    navigate('/');
                  }}
                />
              )}
              <Button
                appearance="button"
                size="xs"
                label={SA_Template?.agreement_accepted ? 'Accepted' : 'Accept'}
                variant="g-secondary"
                disabled={SA_Template?.agreement_accepted}
                onClick={async () => {
                  await api
                    .post(`/save-service-agreement?customerId=${customerId}&userId=${auth.id}`, {
                      agreement_accepted: true,
                      customerId: customerId,
                      userId: auth?.id
                    })
                    .then(res => {
                      toast.success('Thank you for choosing us !', {
                        position: toast.POSITION.TOP_CENTER
                      });
                      window.open(res?.payment_url, '_blank');
                    })
                    .catch(err => {
                      console.log('save agreement error', err);
                      toast.warn('Please try again or contact support.', {
                        position: toast.POSITION.TOP_CENTER
                      });
                    })
                    .finally(() => navigate('/'));
                }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <div className={styles.header}>SERVICE AGREEMENT</div>
          <div className={styles.non_admin_body}>
            Please login as Administrator to accept service agreement.
          </div>
          <div className={styles.footer}>
            <div className={styles.buttonWrapper}>
              <Button
                appearance="button"
                size="xs"
                label={'Back To Dashboard'}
                variant="g-secondary"
                onClick={() => {
                  navigate('/');
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <Loader className={styles.loader} />;
  }
};

export default ServiceAgreement;
