import { useGetCalculatedTollsQuery } from '@api/services/trips';
import { ICalculatedToll } from '@api/types';
import Loader from '@common/ui/Loader';
import { useEffect, useState } from 'react';
import styles from './tolls-list.module.scss';
import Table from './Table/Table';

const TollsList = ({ tripId, timezone }: { tripId: string | number; timezone: string }) => {
  const { data: estimatedTolls, isLoading } = useGetCalculatedTollsQuery(tripId, {
    refetchOnReconnect: true,
    skip: !tripId
  });
  const [tolls, setTolls] = useState<ICalculatedToll[]>([]);

  useEffect(() => {
    if (estimatedTolls && !isLoading) {
      setTolls(estimatedTolls || []);
    }
  }, [estimatedTolls, isLoading]);

  return (
    <section
      ref={el =>
        (el?.scrollHeight || 0) > (el?.clientHeight || 0)
          ? el?.classList?.add(styles['with-scrollbar'])
          : el?.classList?.remove(styles['with-scrollbar'])
      }
      className={styles.container}
    >
      {isLoading && !estimatedTolls ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          {tolls?.length !== 0 ? (
            <Table estimatedTolls={tolls} timezone={timezone} />
          ) : (
            <h3>No tolls to display</h3>
          )}
        </>
      )}
    </section>
  );
};

export default TollsList;
