import { EAuthRole, ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';
import { selectVisibleCustomerOption } from '@store/data';
import _ from 'lodash';
import { AddNewButton, ClearPageFiltersButton, GoBackButton } from '../buttons';
import { useCanShowButtons } from '../buttons/hooks';
import styles from './title.module.scss';

const Title = ({ title, hideButtons }: { title: string; hideButtons?: boolean }) => {
  const dispatch = useAppDispatch();
  const table = useAppSelector(state => state.table);
  const tableName = table.name as ETableName;

  const auth = useAppSelector(state => state.auth.app_role);
  const customerId = useAppSelector(state => state.customer.id);
  const isEmptyCustomer =
    auth === EAuthRole.SUPER_ADMIN && !customerId && title === ETableName.USERS;

  const sourceType = useAppSelector(state => state.user.sourceType);
  const isGeotab = sourceType
    ? _.kebabCase(sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  const Access = useEditPermission(
    tableName === ETableName.USERS ? routes.users : routes.toll_documents
  );

  const hasAccess = tableName === ETableName.USERS ? Access && !isGeotab : Access;

  const pagination = useAppSelector(state => state.pagination[tableName]);
  const companyName = useAppSelector(state => state.customer.companyName);
  // const userRole: string[] | string = useAppSelector(state => state.auth.app_role);
  const showCompanyName =
    companyName &&
    (tableName !== ETableName.CUSTOMERS || title === 'dashboard' || title === 'Dispute Reports');
  const location = useLocation();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
  const canShowButtons = useCanShowButtons(crossFilterSource, tableName, title);
  const backButtonDestination = () => {
    if (crossFilterSource) {
      return crossFilterSource || undefined;
    }
    return undefined;
  };
  const source = crossFilterSource as string;
  const subtitle = crossFilterSource
    ? `${pagination?.dataLength || 0} ${pagination?.dataLength === 1 ? 'entry' : 'entries'} found`
    : undefined;
  return (
    <header
      className={
        !crossFilterSource ? styles.container : `${styles.container} ${styles['active-filter']}`
      }
    >
      <section className={styles.title}>
        {crossFilterSource && (
          <GoBackButton
            destination={backButtonDestination()}
            showDestination={!!crossFilterSource}
          />
        )}
        <h3 className={styles.name}>{!isEmptyCustomer ? title : 'Super Admins'}</h3>
        {showCompanyName && <span className={styles.customer}>{companyName}</span>}
        {tableName === ETableName.TRIPS && <span className={styles.customer}>Last 6 months</span>}
        {isEmptyCustomer && (
          <button
            type="button"
            onClick={() => dispatch(selectVisibleCustomerOption())}
            className={styles.superadmin}
          >
            Select customer to view Users
          </button>
        )}
        {subtitle && <span className={source ? styles.entries : styles.subtitle}>{subtitle}</span>}
      </section>
      {((!canShowButtons && !hideButtons) || isEmptyCustomer) && (
        <div className={styles.buttons}>
          {crossFilterSource && <ClearPageFiltersButton />}
          {!crossFilterSource && hasAccess && <AddNewButton />}
          {/* {!crossFilterSource && <ViewDocButton />} */}
        </div>
      )}
    </header>
  );
};

export default Title;
