import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import styles from './title-block.module.scss';
import { useAddNewVisible } from './hooks';
import Buttons from './buttons/Buttons';
import { TitleBlockProps } from './types';
import Title from './title/Title';
import AddNewButton from '../../../web-portal/components/title-block/buttons/AddNew/AddNew';
import ActionsBlock from '../actions-block';

const TitleBlock = ({ title, displayName, button, datesFiltering }: TitleBlockProps) => {
  const location = useLocation();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
  const addNewVisible = useAddNewVisible(title);
  const table = useAppSelector(state => state.table);

  return (
    <header
      style={{ padding: table.name === ETableName.TRIPS ? '' : '2.8rem 0 0' }}
      className={
        !crossFilterSource ? styles.container : `${styles.container} ${styles['active-filter']}`
      }
    >
      <Title title={displayName || title} crossFilter={crossFilterSource as string} />
      {button && (
        <Buttons
          {...button}
          hide={button.hide || !addNewVisible}
          crossFilterActive={!!crossFilterSource}
          tableName={title}
        />
      )}
      {table.name === ETableName.TRIPS && (
        <div className={styles['action-block']}>
          <ActionsBlock tableName={table.name} datesFiltering={datesFiltering} />
        </div>
      )}
      {title === ETableName.FLEET && <AddNewButton />}
    </header>
  );
};

export default TitleBlock;
