import { ArrowLeft } from '@assets/icons';
import Button from '@common/ui/Button';
import { ETableName } from '@common/utils/enums';
import { clearFilters } from '@store/features/filters';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useNavigate } from 'react-router-dom';
import styles from './title.module.scss';

const Title = ({ title, crossFilter }: { title: string; crossFilter: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const table = useAppSelector(state => state.table);
  const tableName = table.name as ETableName;
  const pagination = useAppSelector(state => state.pagination[tableName]);
  const companyName = useAppSelector(state => state.customer.companyName);
  const showCompanyName = companyName && tableName !== ETableName.CUSTOMERS;
  const destination = crossFilter ? crossFilter || undefined : undefined;
  const source = crossFilter;
  const subtitle = crossFilter
    ? `${pagination?.dataLength || 0} ${pagination?.dataLength === 1 ? 'entry' : 'entries'} found`
    : undefined;
  const clearTableState = () => {
    dispatch(clearFilters(tableName));
  };
  const handleClick = () => {
    clearTableState();
    navigate(-1);
  };
  return (
    <section className={styles.container}>
      {crossFilter && (
        <Button
          variant="g-secondary"
          label={
            !!crossFilter && destination ? `Back to ${destination.split('_').join(' ')}` : 'Back'
          }
          link={destination ? `/${destination}` : undefined}
          appearance="text"
          size="s"
          icon={<ArrowLeft />}
          onClick={handleClick}
        />
      )}
      <h3 className={styles.name}>{title}</h3>
      {showCompanyName && <span className={styles.customer}>{companyName}</span>}
      {tableName === ETableName.TRIPS && <span className={styles.customer}>Last 6 months</span>}
      {subtitle && <span className={source ? styles.entries : styles.subtitle}>{subtitle}</span>}
    </section>
  );
};

export default Title;
