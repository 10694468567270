/* eslint-disable */
import { ICreateVehicle, useCreateVehicleMutation } from '@api/services/fleet';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { closeModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { refetchTableData } from '@store/data';
import { vehicleClassesList } from '@common/utils/constants/lists';
import { getStatesOfCountry } from '@common/utils/constants/helpers';
import { useMemo } from 'react';

export const useHandleSubmit = () => {
  const states = useMemo(() => getStatesOfCountry('US') || [], []);
  const [createVehicle] = useCreateVehicleMutation();
  const toastify = usePromiseToastNotify();
  const dispatch = useAppDispatch();
  const { handleError } = useHandleApiResponse();
  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const tagTypes = useAppSelector(state => state.fleetTabs.tagTypes);
  const customer_id = useAppSelector(state => state.customer.id);
  const handleSubmit = (values: ICreateVehicle, setSubmitting: (isSubmitting: boolean) => void) => {
    const foundVehicleClass = vehicleClassesList?.find(
      vehicleClass => vehicleClass.displayName === values.vehicleClass
    );
    values.vehicleClass = foundVehicleClass?.classCode || values.vehicleClass;

    values.tagTypeId =
      values.tagType && values.tagType.toLowerCase() !== 'none'
        ? tagTypes?.find(item => item.tag_type_name.toLowerCase() === values.tagType?.toLowerCase())
            ?.tag_type_id || null
        : null;

    values.plateState =
      values.plateState === 'None'
        ? ''
        : values.plateState
        ? states?.find(state => state.isoCode === values.plateState?.split(/ -/)[0])?.isoCode ||
          values.plateState
        : '';

    if (
      values.childTags &&
      values.childTags?.length > 0 &&
      values.childTags &&
      values.childTags?.length > 0
    ) {
      values.tollConsolidator = true;
      const updatedChildTags = values.childTags.map(childTag => {
        const updatedChildTag = { ...childTag }; // Create a new object with the same properties

        updatedChildTag.agencyName =
          updatedChildTag.issuingAgencyId &&
          updatedChildTag.issuingAgencyId.toString().toLowerCase() !== 'none'
            ? tagIssuingAgencies?.find(x => x.name === updatedChildTag.issuingAgencyId)?.name
            : null;

        updatedChildTag.issuingAgencyId =
          updatedChildTag.issuingAgencyId &&
          updatedChildTag.issuingAgencyId.toString().toLowerCase() !== 'none'
            ? Number(tagIssuingAgencies?.find(x => x.name === updatedChildTag.issuingAgencyId)?.id)
            : null;

        updatedChildTag.tagTypeId =
          updatedChildTag.tagType && updatedChildTag.tagType.toLowerCase() !== 'none'
            ? tagTypes?.find(item => item.tag_type_name === updatedChildTag.tagType)?.tag_type_id ||
              ''
            : null;

        return updatedChildTag;
      });
      values.childTags = updatedChildTags;
    }

    values.customerId = customer_id;

    console.log('End Values', values);
    toastify(
      createVehicle(values)
        .unwrap()
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'create', 'customer');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(refetchTableData(true));
          dispatch(closeModal());
        }),
      'create',
      'vehicle'
    );
  };

  return { handleSubmit };
};
