export const vehicleHeaders = {
  normalHeaders: [
    {
      label: 'Subscribe Vehicle',
      value: 'isSubscribed'
    },
    {
      label: 'VIN',
      value: 'vin'
    },
    {
      label: 'VEHICLE NAME',
      value: 'name'
    },
    {
      label: 'VEHICLE MAKE',
      value: 'make'
    },
    {
      label: 'VEHICLE MODEL',
      value: 'model'
    },
    {
      label: 'LICENSE PLATE NUMBER',
      value: 'licensePlateNumber'
    },
    {
      label: 'LICENSE PLATE STATE',
      value: 'licensePlateState'
    },
    {
      label: 'VEHICLE TYPE',
      value: 'vehicleType'
    },
    {
      label: 'VEHICLE CLASS',
      value: 'vehicleClass'
    },
    {
      label: 'AXLE COUNT',
      value: 'vehicleAxles'
    },
    {
      label: 'TIRE COUNT',
      value: 'tires'
    },
    {
      label: 'VEHICLE WEIGHT',
      value: 'weightLBS'
    },
    {
      label: 'VEHICLE HEIGHT',
      value: 'height'
    },
    {
      label: 'TAG #',
      value: 'tagTransponder'
    },
    {
      label: 'Tag Issuing Agency',
      value: 'tagIssuingAgency'
    },
    {
      label: 'Tag Type',
      value: 'tagType'
    }
  ],
  subscriptionHeaders: [
    {
      label: 'VIN',
      value: 'vin'
    },
    {
      label: 'Vehicle Name',
      value: 'name'
    },
    {
      label: 'Vehicle Make',
      value: 'make'
    },
    {
      label: 'Vehicle Model',
      value: 'model'
    },
    {
      label: 'Subscribe Vehicle',
      value: 'isSubscribed'
    }
  ]
};
