import styles from './historical-trip-notice.module.scss';

const HistoricalTripNotice = () => {
  return (
    <div className={styles.container}>
      {/* <div className={styles['trial-desc']}>
        <p>
          Your trial starts today and will end by{' '}
          {trialPeriod.trialStartDate && (
            <b>{dayjs(trialPeriod.trialEndDate).format('MMMM D, YYYY')}</b>
          )}
          .
        </p>
      </div> */}
      <p className={styles.notice}>
        Historical trip information will be available in tollogic within 24 hours.
      </p>
    </div>
  );
};

export default HistoricalTripNotice;
