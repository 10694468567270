/* eslint-disable */

import axios, { AxiosError } from 'axios';
import { usePromiseToastNotify } from '@common/hooks';
import { http } from '../../config/axios';
import { WEB_BE_URL, BE_URL } from '@api/config';
import { setHeaders } from '@api/config/header';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { store } from '@store/index';
import _ from 'lodash';
import { toast } from 'react-toastify';

const toastify = usePromiseToastNotify();

class api {
  static async get(endPoint: string) {
    try {
      const res = await axios.get(`${WEB_BE_URL}${endPoint}`, {
        headers: setHeaders()
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
      const error = err as AxiosError; 
      if (error?.response?.status == 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user?.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        }
    }
  }

  static async post(endPoint: string, data?: any) {
    try {
      const res = await axios.post(`${WEB_BE_URL}${endPoint}`, data, {
        headers: setHeaders()
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
      const error = err as AxiosError; 
      if (error?.response?.status == 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        }
    }
  }
  static async put(endPoint: string, data: any) {
    try {
      const res = await http.put(`${WEB_BE_URL}${endPoint}`, data, {
        headers: setHeaders()
      });
      if (res.status !== 200 || !res.data.isSuccess) {
        toast.error(`Error in updating company profile `, {
          position: toast.POSITION.TOP_CENTER
        });
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        toast.success(`Company profile updated successfully`, {
          position: toast.POSITION.TOP_CENTER
        });
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
      const error = err as AxiosError; 
      if (error?.response?.status == 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        }
    }
  }

  static async JavaPost(endPoint: string, data?: any) {
    try {
      const res = await axios.post(`${BE_URL}${endPoint}`, data, {
        headers: setHeaders()
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
      const error = err as AxiosError; 
      if (error?.response?.status == 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        }
    }
  }

  static async JavaGet(endPoint: string) { 
    try {
      const res = await axios.get(`${BE_URL}${endPoint}`, {
        headers: setHeaders()
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
      const error = err as AxiosError; 
      if (error?.response?.status == 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        }
    }
  }
}

export default api;
