import {
  CustomerDataInterface,
  UserDataInterface,
  VehicleDataInterface,
  UnionDataInterface,
  TransactionDataInterface
} from '@common/types/data';
import { useAppSelector } from '@store/hooks';
import { AnimatePresence } from 'framer-motion';
import { rowModalId } from '@common/services/format';
import { ETableName } from '@common/utils/enums';
import ApiResponse from '../ApiResponse';
import Customer from './Customer/Customer';
import User from './User/User';
import Vehicle from './Vehicle/Vehicle';
import Transaction from './Transaction/Transaction';

const RowModal = ({ data }: { data: UnionDataInterface }) => {
  const {
    activeModal: modal,
    id: modalId,
    responseType,
    responseAction,
    responseMessage
  } = useAppSelector(state => state.modal);

  return (
    <AnimatePresence exitBeforeEnter>
      {modal === 'data' && modalId === rowModalId(ETableName.CUSTOMERS, data.id) && (
        <Customer key="customers" data={data as CustomerDataInterface} />
      )}
      {modal === 'data' && modalId === rowModalId(ETableName.USERS, data.id) && (
        <User key="users" data={data as UserDataInterface} />
      )}
      {/* {modal === 'data' && modalId === rowModalId(ETableName.TRIPS, data.id) && (
        <Trip key="trips" data={data as TripDataInterface} />
      )} */}
      {modal === 'data' && modalId === rowModalId(ETableName.FLEET, data.id) && (
        <Vehicle key="fleet" data={data as VehicleDataInterface} />
      )}
      {modal === 'data' && modalId === rowModalId(ETableName.TRANSACTIONS, data.id) && (
        <Transaction key="transactions" data={data as TransactionDataInterface} />
      )}
      {responseType && modal === 'api-response' && modalId === `row-modal/${data.id}` && (
        <ApiResponse
          key="api-response"
          type={responseType}
          action={responseAction as string}
          message={responseMessage}
        />
      )}
    </AnimatePresence>
  );
};

export default RowModal;
