import { TextInput } from '@common/ui/Form';
import styles from './upload-toll-document.module.scss';

const UploadTollDocument = () => {
  return (
    <div className={styles.container}>
      <TextInput
        name="fileName"
        label="File name"
        className={styles.input}
        placeholder="Enter file name"
      />
      {/* <div className={styles.group}>
        <SelectInput
          values={['Invoice', 'Pre paid', 'Post paid', 'Violation', 'Third party']}
          label="Toll Document type"
          formField="tollDocumentType"
          placeholder="Select Toll Document Type"
          className={styles.input}
        />
        <SelectInput
          values={['MDTA', 'MTA', 'NTTA', 'NJTA']}
          label="Invoicing agency"
          formField="invoicingAgency"
          placeholder="Select Invoicing Agency"
          className={styles.input}
        />
      </div> */}
    </div>
  );
};

export default UploadTollDocument;
