import Button from '@common/ui/Button/Button';
import { Edit } from '@assets/icons';
import Modal from '@common/ui/Modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { openCompanyEdit, closeCompanyEdit } from '@store/data';
import { AnimatePresence } from 'framer-motion';
import { IProfile, updateProfile } from '@store/data/profile';
import MotionDiv from '@pages/tables/layouts/geotab-addin/components/motion-div';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import Loader from '@common/ui/Loader/Loader';
import { EditCompanyFormik, EditAddress, EditContacts, EditOrganization } from './EditCompanyInfo';
import { useHandleCompanyInfoTitle } from './useHandleCompanyInfoTitle';
import styles from './companyInfo.module.scss';
import SelectCustomer from '../selectCustomer';

interface ICompanyProfile {
  company_name?: string;
  geotab_db?: string;
  federal_id?: string;
  company_register_address?: string;
  company_billing_address?: string;
  company_shipping_address?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  admin_name?: string;
  admin_phone?: string;
  admin_email?: string;
}

const SubmitButton = () => {
  const formik: {
    values: IProfile;
    errors: ICompanyProfile;
  } = useFormikContext();
  const dispatch = useAppDispatch();
  const { handleActiveTitle } = useHandleCompanyInfoTitle('Organization');

  return (
    <Button
      appearance="button"
      size="xs"
      label="Save"
      variant="primary"
      disabled={Object.keys(formik.errors).length !== 0}
      onClick={() => {
        const profileUpdateData = {
          company_name: formik.values.company_name,
          geotab_db: formik.values.geotab_db,
          federal_id: formik.values.federal_id,
          company_register_address: formik.values.company_register_address,
          company_billing_address: formik.values.company_billing_address,
          company_shipping_address: formik.values.company_shipping_address,
          contact_name: formik.values.contact_name,
          contact_phone: formik.values.contact_phone,
          contact_email: formik.values.contact_email,
          admin_name: formik.values.admin_name,
          admin_phone: formik.values.admin_phone,
          admin_email: formik.values.admin_email
        };
        dispatch(updateProfile(profileUpdateData)).finally(() => {
          dispatch(closeCompanyEdit());
          handleActiveTitle('Organization');
        });
      }}
    />
  );
};

const CompanyInfo: FC<{ profileData: IProfile | null }> = ({ profileData }) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(state => state.settings.open);

  const loading = useAppSelector(state => state.profile?.loading);
  const customerId = useAppSelector(state => state.customer.id);
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleCompanyInfoTitle('Organization');

  const [matches, setMatches] = useState<boolean>(window.matchMedia('(max-width: 1000px)').matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-height: 700px)');

    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQueryList.addEventListener('change', handleMediaQueryChange);
    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  useEffect(() => {}, [profileData]);

  if (customerId) {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={styles['company-container']}>
        {status && (
          <EditCompanyFormik data={profileData} onValueSubmit={() => {}}>
            <Modal
              activeTitle={activeTitle}
              customCloseFunc={() => {
                dispatch(closeCompanyEdit());
                handleActiveTitle('Organization');
              }}
              navigation={['Organization', 'Addresses', 'Contacts']}
              handleActiveTitle={handleActiveTitle}
              customPrimaryButton={<SubmitButton />}
              customSecondaryButton={
                <Button
                  appearance="button"
                  size="xs"
                  label="Cancel"
                  variant="g-tertiary"
                  onClick={() => {
                    dispatch(closeCompanyEdit());
                    handleActiveTitle('Organization');
                  }}
                />
              }
              cancelButton={false}
              className={`${styles.modal} ${matches ? styles['small-modal'] : ''}`}
            >
              <AnimatePresence exitBeforeEnter initial={false}>
                <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
                  {activeTitle === 'Organization' && <EditOrganization />}
                  {activeTitle === 'Addresses' && <EditAddress />}
                  {activeTitle === 'Contacts' && <EditContacts />}
                </MotionDiv>
              </AnimatePresence>
            </Modal>
          </EditCompanyFormik>
        )}
        <div className={styles.organization}>
          <h2>Organization</h2>
          <p className={styles['sub-header']}>Company Name</p>
          <p className={styles.values}>{profileData?.company_name}</p>
          <p className={styles['sub-header']}>DBA Name</p>
          <p className={styles.values}>{profileData?.geotab_db}</p>
          <p className={styles['sub-header']}>Federal ID</p>
          <p className={styles.values}>{profileData?.federal_id}</p>
        </div>
        <div className={styles['address-block']}>
          <h2>Addresses</h2>
          <p className={styles['sub-header']}>Registered</p>
          {profileData?.company_register_address?.street1 && (
            <p title="Address line 1" className={styles.values}>
              {profileData?.company_register_address?.street1}
            </p>
          )}
          {profileData?.company_register_address?.street2 && (
            <p title="Address line 2" className={styles.values}>
              {profileData?.company_register_address?.street2}
            </p>
          )}

          {profileData?.company_register_address?.city && (
            <p title="City" className={styles.values}>
              {profileData?.company_register_address?.city}
            </p>
          )}
          {profileData?.company_register_address?.state && (
            <p title="State" className={styles.values}>
              {profileData?.company_register_address?.state?.split('-')[0]}
            </p>
          )}

          {profileData?.company_register_address?.country && (
            <p title="Country" className={styles.values}>
              {profileData?.company_register_address?.country}
            </p>
          )}
          {profileData?.company_register_address?.zipCode && (
            <p title="Zipcode" className={styles.values}>
              {profileData?.company_register_address?.zipCode}
            </p>
          )}
          <p className={styles['sub-header']}>Billing</p>
          {profileData?.company_billing_address?.street1 && (
            <p title="Address line 1" className={styles.values}>
              {profileData?.company_billing_address?.street1}
            </p>
          )}
          {profileData?.company_billing_address?.street2 && (
            <p title="Address line 2" className={styles.values}>
              {profileData?.company_billing_address?.street2}
            </p>
          )}

          {profileData?.company_billing_address?.city && (
            <p title="City" className={styles.values}>
              {profileData?.company_billing_address?.city}
            </p>
          )}
          {profileData?.company_billing_address?.state && (
            <p title="State" className={styles.values}>
              {profileData?.company_billing_address?.state?.split('-')[0]}
            </p>
          )}

          {profileData?.company_billing_address?.country && (
            <p title="Country" className={styles.values}>
              {profileData?.company_billing_address?.country}
            </p>
          )}
          {profileData?.company_billing_address?.zipCode && (
            <p title="Zipcode" className={styles.values}>
              {profileData?.company_billing_address?.zipCode}
            </p>
          )}

          <p className={styles['sub-header']}>Shipping</p>
          {profileData?.company_shipping_address?.street1 && (
            <p title="Address line 1" className={styles.values}>
              {profileData?.company_shipping_address?.street1}
            </p>
          )}
          {profileData?.company_shipping_address?.street2 && (
            <p title="Address line 2" className={styles.values}>
              {profileData?.company_shipping_address?.street2}
            </p>
          )}
          {profileData?.company_shipping_address?.city && (
            <p title="City" className={styles.values}>
              {profileData?.company_shipping_address?.city}
            </p>
          )}
          {profileData?.company_shipping_address?.state && (
            <p title="State" className={styles.values}>
              {profileData?.company_shipping_address?.state?.split('-')[0]}
            </p>
          )}
          {profileData?.company_shipping_address?.country && (
            <p title="Country" className={styles.values}>
              {profileData?.company_shipping_address?.country}
            </p>
          )}
          {profileData?.company_shipping_address?.zipCode && (
            <p title="Zipcode" className={styles.values}>
              {profileData?.company_shipping_address?.zipCode}
            </p>
          )}
        </div>
        <div>
          <h2>Contacts</h2>
          <p className={styles['sub-header']}>Admin</p>
          <p className={styles.values}>{profileData?.admin_name}</p>
          <p className={styles.values}>{profileData?.admin_phone}</p>
          <p className={styles.values}>{profileData?.admin_email}</p>
          <p className={styles['sub-header']}>Billing</p>
          <p className={styles.values}>{profileData?.contact_name}</p>
          <p className={styles.values}>{profileData?.contact_phone}</p>
          <p className={styles.values}>{profileData?.contact_email}</p>
        </div>
        <div>
          <Button
            label="Edit"
            appearance="button"
            icon={<Edit />}
            onClick={() => dispatch(openCompanyEdit())}
          />
        </div>
      </div>
    );
  }

  return <SelectCustomer label="Company Information" />;
};

export default CompanyInfo;
