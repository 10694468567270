import { VehicleDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<VehicleDataInterface>[] = [
  {
    heading: 'name',
    field: 'name',
    active: true,
    filterType: 'text',
    placeholder: 'search name'
  },
  {
    heading: 'vehicle type',
    field: 'type',
    active: true,
    filterType: 'text',
    placeholder: 'search type'
  },
  {
    heading: 'vin',
    field: 'vin',
    active: true,
    filterType: 'text',
    placeholder: 'search vin'
  },
  {
    heading: 'vehicle class',
    field: 'vehicleClassD',
    active: true,
    filterType: 'text',
    placeholder: 'search class'
  },
  // {
  //   heading: 'license plate state',
  //   field: 'plateState',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate state'
  // },
  // {
  //   heading: 'license plate number',
  //   field: 'plateNumber',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate number'
  // },
  {
    heading: 'license plate',
    field: 'licensePlate',
    active: true,
    filterType: 'text',
    placeholder: 'search license plate'
  },
  {
    heading: 'weight',
    field: 'weight',
    active: false,
    filterType: 'number',
    placeholder: 'search weight'
  },
  {
    heading: 'height',
    field: 'height',
    active: false,
    filterType: 'number',
    placeholder: 'search height'
  },
  {
    heading: 'vehicle axles',
    field: 'axles',
    active: true,
    filterType: 'number',
    placeholder: 'search axles'
  },
  {
    heading: 'vehicle tires',
    field: 'tires',
    active: false,
    filterType: 'number',
    placeholder: 'search tires'
  },
  {
    heading: 'tag type',
    field: 'tagType',
    active: true,
    filterType: 'text',
    placeholder: 'search tag type'
  },
  {
    heading: 'issuing agency',
    field: 'issuingAgencyIdD',
    active: false,
    filterType: 'text',
    placeholder: 'search agency'
  },
  {
    heading: 'tag/transponder',
    field: 'tagTransponder',
    active: true,
    filterType: 'text',
    placeholder: 'search tag/transponder'
  },
  {
    heading: 'second tag type',
    field: 'secTagType',
    active: false,
    filterType: 'text',
    placeholder: 'search second tag type'
  },
  {
    heading: 'second issuing agency',
    field: 'secIssuingAgencyIdD',
    active: false,
    filterType: 'text',
    placeholder: 'search second agency'
  },
  {
    heading: 'second tag/transponder',
    field: 'secTagTransponder',
    active: false,
    filterType: 'text',
    placeholder: 'search second tag/transponder'
  },
  {
    heading: 'third tag type',
    field: 'trdTagType',
    active: false,
    filterType: 'text',
    placeholder: 'search second tag type'
  },
  {
    heading: 'third issuing agency',
    field: 'trdIssuingAgencyIdD',
    active: false,
    filterType: 'text',
    placeholder: 'search third agency'
  },
  {
    heading: 'third tag/transponder',
    field: 'trdTagTransponder',
    active: false,
    filterType: 'text',
    placeholder: 'search third tag/transponder'
  },
  // {
  //   heading: 'current driver',
  //   field: 'currentDriver',
  //   active: false,
  //   filterType: 'text',
  //   placeholder: 'search current driver'
  // },
  {
    heading: 'date of creation',
    field: 'createdAt',
    active: false,
    filterType: 'date',
    placeholder: 'search date created'
  },
  {
    heading: 'Vehicle Id',
    field: 'id',
    active: false,
    filterType: 'text',
    placeholder: 'search vehicle id'
  },
  {
    heading: 'Device Plan',
    field: 'devicePlanD',
    active: false,
    filterType: 'text',
    placeholder: 'search device plan'
  },
  {
    heading: 'Billing Status',
    field: 'billingStatusD',
    active: true,
    filterType: 'text',
    placeholder: 'search billing status'
  },
  {
    heading: 'Billing Status Updated On',
    field: 'billingStatusUpdatedAt',
    active: false,
    filterType: 'date',
    placeholder: 'search date of billing status updated'
  },
  {
    heading: 'GPS Reliability',
    field: 'gpsReliability',
    active: false,
    filterType: 'text',
    placeholder: 'search gps reliability'
  },
  {
    heading: 'missing item',
    field: 'missingItemDesc',
    active: true,
    filterType: 'text',
    placeholder: 'search missing item'
  }
];
