/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '@common/utils/route-names';
import { authObj, cookiesSessionUser } from '@common/services/auth';
import { useState } from 'react';
import { setAuthUser } from '@store/features/auth';
import { useAppDispatch } from '@store/hooks';
import { useGetUserRolePermsMutation, useLogInMutation } from '@api/services/auth';
import { setDefaultHeadings } from '@store/features/headings';
import { EAuthRole } from '@common/utils/enums';
import { getTagTypes, getStatementTypes } from '@store/data/fleetTabs';
import { useSelector } from 'react-redux';
import { customerId } from '@store/data/customer/selector';
import { userEmail } from '@store/features/auth/selector';

export const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([false, '']);
  const [logIn] = useLogInMutation();
  const [getUserRolePerms] = useGetUserRolePermsMutation();
  const customer_id = useSelector(customerId);
  const user_email = useSelector(userEmail);
  const handleLogin = (
    email: string,
    password: string,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(true);
    const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');
    sessionStorage.removeItem('redirectAfterLogin');

    logIn([email, password])
      .unwrap()
      .then(async user => {
        if (user.isSuccess) {
          sessionStorage.setItem('sessionExpiredToastShown', JSON.stringify(false));
          cookiesSessionUser.set(
            user.data.email,
            user.data.accessToken,
            user.data.idToken,
            user.data.refreshToken
          );
          const userRolePerms = await getUserRolePerms({
            accessToken: user.data.accessToken
          }).unwrap();

          const modAuthObj: any = {
            data: {
              ...user.data
            }
          };
          if (userRolePerms.isSuccess) {
            modAuthObj.data.modules = userRolePerms.data;
          }
          const newAuth = authObj(modAuthObj);

          dispatch(setDefaultHeadings(user?.data?.ui_table_settings));

          dispatch(setAuthUser([newAuth, user.data.userId]))
            .unwrap()
            .then(() => {
              if (user.data.app_role === EAuthRole.SUPER_ADMIN) {
                const from =
                  customer_id && user_email == email
                    ? redirectAfterLogin ?? routes.customers
                    : routes.customers;
                navigate(from, { replace: true });
              } else {
                const from = customer_id && user_email == email ? redirectAfterLogin ?? '/' : '/';
                navigate(from, { replace: true });
              }
            })
            .catch(e => console.log(e))
            .finally(() => {
              dispatch(getTagTypes()).catch(err => {
                console.log('Err in fetching issuing tag types ==> ', err);
              });
              dispatch(getStatementTypes()).catch(err => {
                console.log('Err in fetching statement types ==> ', err);
              });
            });
        }
        // else if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //   navigate(routes.user_invitation, { state: { email } });
        // }
      })
      .catch(err => {
        console.log(err);
        setError([true, err?.data?.message || err]);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };
  return { handleLogin, loading, error };
};
