export enum ETableName {
  FLEET = 'fleet',
  TRIPS = 'trips',
  TRANSACTIONS = 'transactions',
  TRAILERS = 'trailers',
  CUSTOMERS = 'customers',
  ROLES = 'roles',
  USERS = 'users',
  TOLLDOCUMENTS = 'toll_documents'
}

export enum EDTableName {
  DISPUTEREPORTS = 'disputereports'
}

export enum EDeviceType {
  GEOTAB = 1,
  MANUAL = 2,
  SAMSARA = 3
}
