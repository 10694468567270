/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import { emailCookie, userCanAccessRoute } from '@common/services/auth';
import { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '@store/hooks';
import { EModule } from '@common/utils/enums';
import { routes } from '@common/utils/route-names';
import { Graph } from '@assets/icons';
import { navigationTop } from '../utils/navigation-links';
import { NavProps } from '../types';
import NavItem from '../NavItem';
import styles from './top-nav.module.scss';

const TopNav: FC<NavProps> = ({ handleNavCollapse, navCollapse }) => {
  const customer = useAppSelector(state => state.customer);
  const [navItems, setNavItems] = useState([...navigationTop]);

  useEffect(() => {
    if (Cookies.get(emailCookie)) {
      const userEmail = Cookies.get(emailCookie) as string;
      const valuesFromEnv = process.env.REACT_APP_TEST_EMAILS as string;
      if (valuesFromEnv) {
        const testUser = valuesFromEnv.split(',');
        const isTestUser = testUser.includes(userEmail);
        const isAddedAlready = navItems.findIndex(x => x.link === routes.newDashboard) !== -1;
        if (!isAddedAlready) {
          if (process.env.REACT_APP_ENVIRONMENT && isTestUser && customer.id) {
            navItems.unshift(
              // {
              //   link: routes.dashboard,
              //   title: 'dashboard',
              //   icon: Graph,
              //   isPermitted: userCanAccessRoute
              // },
              {
                link: routes.newDashboard,
                title: 'dashboard',
                icon: Graph,
                isPermitted: userCanAccessRoute
              }
            );
            setNavItems(data => [...data]);
          } else if (customer.id) {
            navItems.unshift({
              link: routes.newDashboard,
              title: 'dashboard',
              icon: Graph,
              isPermitted: userCanAccessRoute
            });
            setNavItems(data => [...data]);
          }
        }
      }
    }
  }, [customer]);
  return (
    <div className={!navCollapse ? `${styles.container} ${styles.open}` : styles.container}>
      {navItems?.map(
        navItem =>
          ((navItem.title === EModule.DASHBOARD && customer.id) ||
            (navItem.title !== EModule.DASHBOARD && navItem.isPermitted(navItem.link))) && (
            <NavItem
              navCollapse={navCollapse}
              handleNavCollapse={handleNavCollapse}
              navItem={navItem}
              key={uuid()}
            />
          )
      )}
    </div>
  );
};

export default TopNav;
