/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import { baseApi } from '@api/config/base-api';
import authReducer from '../features/auth';
import modalReducer from '../features/modal';
import sortReducer from '../features/sort';
import searchReducer from '../features/search';
import filtersReducer from '../features/filters';
import headingsReducer from '../features/headings';
import selectionIdsReducer from '../features/selection';
import paginationReducer from '../features/pagination';
import quickFilterReducer from '../features/quick-filters';
import tableReducer from '../data/table';
import userReducer from '../data/user';
import customerReducer from '../data/customer';
import profileReducer from '../data/profile';
import fleetTabsReducer from '../data/fleetTabs';
import subscribeReducer from '../features/subscribe';
import serviceAgreementReducer from '../data/serviceAgreement';
import settingsReducer from '../data/settings';
import helpersReducer from '../features/helpers';

export const combinedReducers = combineReducers({
  table: tableReducer,
  user: userReducer,
  customer: customerReducer,
  auth: authReducer,
  modal: modalReducer,
  pagination: paginationReducer,
  sort: sortReducer,
  search: searchReducer,
  filters: filtersReducer,
  headings: headingsReducer,
  selectionIds: selectionIdsReducer,
  profile: profileReducer,
  fleetTabs: fleetTabsReducer,
  subscribe: subscribeReducer,
  serviceAgreement: serviceAgreementReducer,
  quickFilters: quickFilterReducer,
  settings: settingsReducer,
  helpers: helpersReducer,
  [baseApi.reducerPath]: baseApi.reducer
});
