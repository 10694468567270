/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-unneeded-ternary */
import { FiMinus } from 'react-icons/fi';
import { HiCheck } from 'react-icons/hi';
import styles from './checkbox.module.scss';
import { CheckboxProps } from './types';

const Checkbox = ({
  label,
  state,
  disabled,
  onClick,
  className = '',
  isLabelBold = false
}: CheckboxProps) => {
  const mode = label
    ? `${styles[`container-${state}`]} ${styles['container-labelled']}`
    : styles[`container-${state}`];
  const labelStrength: string | null = isLabelBold ? `${styles['label-strength']}` : null;
  return (
    <button
      aria-label="checkbox"
      data-disabled={disabled}
      className={`${styles.container} ${mode} ${className}`}
      onClick={onClick}
      type="button"
    >
      <span className={styles.box}>
        {state === 'checked' && <HiCheck className={styles.checked} />}
        {state === 'intermediate' && <FiMinus className={styles.intermediate} />}
      </span>
      {label && (
        <span className={`${styles.label} ${labelStrength ? labelStrength : ''}`}>{label}</span>
      )}
    </button>
  );
};

export default Checkbox;
