export function EndPoint({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 14 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 3H10V1C10 0.734784 9.89468 0.48043 9.70714 0.292893C9.51961 0.105357 9.26525 0 9.00004 0C8.73482 0 8.48047 0.105357 8.29293 0.292893C8.10539 0.48043 8.00004 0.734784 8.00004 1V3H3.00004C2.84983 3.00069 2.70171 3.03521 2.56668 3.101C2.43164 3.16679 2.31316 3.26215 2.22004 3.38L0.220037 5.88C0.0805279 6.05655 0.00463867 6.27499 0.00463867 6.5C0.00463867 6.72501 0.0805279 6.94345 0.220037 7.12L2.22004 9.62C2.31316 9.73785 2.43164 9.83321 2.56668 9.899C2.70171 9.96479 2.84983 9.99931 3.00004 10H8.00004V18H6.00004C5.73482 18 5.48047 18.1054 5.29293 18.2929C5.10539 18.4804 5.00004 18.7348 5.00004 19C5.00004 19.2652 5.10539 19.5196 5.29293 19.7071C5.48047 19.8946 5.73482 20 6.00004 20H12C12.2653 20 12.5196 19.8946 12.7071 19.7071C12.8947 19.5196 13 19.2652 13 19C13 18.7348 12.8947 18.4804 12.7071 18.2929C12.5196 18.1054 12.2653 18 12 18H10V10H13C13.2653 10 13.5196 9.89464 13.7071 9.70711C13.8947 9.51957 14 9.26522 14 9V4C14 3.73478 13.8947 3.48043 13.7071 3.29289C13.5196 3.10536 13.2653 3 13 3ZM12 8H3.48004L2.28004 6.5L3.48004 5H12V8Z" />
    </svg>
  );
}
