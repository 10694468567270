export enum EModule {
  DASHBOARD = 'dashboard',
  HOME = 'home',
  GPSCALCULATEDTOLLS = 'gpscalculatedtolls',
  ACTUALTOLLCOST = 'actualtollcost',
  INSIGHTS = 'insights',
  NOTIFICATIONS = 'notifications',
  SETTINGS = 'settings',
  FLEET = 'fleet',
  TRIP = 'trips',
  TOLLTRANSACTION = 'transactions',
  TOLLDOCUMENT = 'toll-documents',
  MANAGEUSER = 'users',
  PROFILE = 'profile',
  ADDITIONALSERVICES = 'additional-services',
  CUSTOMER = 'customers',
  DISPUTEREPORTS = 'dispute-reports'
}

export enum EDbModule {
  dashboard = 'DASHBOARD',
  home = 'HOME',
  gpscalculatedtolls = 'GPSCALCULATEDTOLLS',
  actualtollcost = 'ACTUALTOLLCOST',
  insights = 'INSIGHTS',
  notifications = 'NOTIFICATIONS',
  settings = 'SETTINGS',
  fleet = 'FLEET',
  trips = 'TRIP',
  transactions = 'TOLLTRANSACTION',
  'toll-documents' = 'TOLLDOCUMENT',
  users = 'MANAGEUSER',
  profile = 'PROFILE',
  'additional-services' = 'ADDITIONALSERVICES',
  customers = 'CUSTOMER',
  'dispute-reports' = 'DISPUTEREPORTS'
}
