/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { snakeCase } from 'lodash';
import { useAppSelector } from '@store/hooks';
import {
  IApiResponse,
  IBarChartData,
  IDate,
  IPotentialSavingsTotals,
  IPotentialSavings
} from '../type';

const usePotentialSavingsData = (
  isParentLoading: boolean
): {
  potentialSavingsData: IBarChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IPotentialSavingsTotals;
  fetchPotentialSavingsData: () => Promise<void>;
} => {
  const [potentialSavingsData, setPotentialSavingsData] = useState<IBarChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IPotentialSavingsTotals>({
    totalAmount: 0,
    totalTolls: 0
  });
  const [showError, setShowError] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);

  const getTotalPotentialSavings = (data: IPotentialSavings[]): IPotentialSavingsTotals => {
    return data.reduce(
      (totals, current) => {
        if (current.amount && current.amount !== null) {
          totals.totalAmount += Number(current.amount);
        }
        if (current.instance_count && current.instance_count !== null) {
          totals.totalTolls += Number(current.instance_count);
        }
        return totals;
      },
      { totalAmount: 0, totalTolls: 0 }
    );
  };

  const transformDataForBarChart = (data: IPotentialSavings[], dateRange: IDate[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.month_date).getTime() - new Date(b.month_date).getTime());

    const filterAndMapData = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item => (item.amount ? parseFloat(item.amount as unknown as string) : 0));
    };
    const filterAndMapInstanceCount = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item =>
          item.instance_count ? parseFloat(item.instance_count as unknown as string) : 0
        );
    };
    const filterAndMapTollDocUploaded = (subcategoryFilter: string) => {
      return sortedData
        .filter(item => snakeCase(item.subcategory) === subcategoryFilter)
        .map(item => (item.tolltransaction_count ? Number(item.tolltransaction_count) : 0));
    };

    const labels = dateRange.map(item => moment.utc(item.month_date).format('MMM'));
    const chartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'License Plate Fixes',
          data: filterAndMapData('license_plate_fixes'),
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('license_plate_fixes'),
          tolltransaction_count: filterAndMapTollDocUploaded('license_plate_fixes')
        },
        {
          label: 'Toll Transponder Fixes',
          data: filterAndMapData('toll_transponder_fixes'),
          backgroundColor: '#739CBD',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('toll_transponder_fixes'),
          tolltransaction_count: filterAndMapTollDocUploaded('toll_transponder_fixes')
        },
        // {
        //   label: 'Delayed Postings',
        //   data: filterAndMapData('delayed_postings'),
        //   backgroundColor: '#437394',
        //   borderRadius: 2,
        //   instanceCount: filterAndMapInstanceCount('delayed_postings'),
        //   tolltransaction_count: filterAndMapTollDocUploaded('delayed_postings')
        // },
        {
          label: 'Potential Misuse',
          data: filterAndMapData('potential_misuse'),
          backgroundColor: '#004C6D',
          borderRadius: 2,
          instanceCount: filterAndMapInstanceCount('potential_misuse'),
          tolltransaction_count: filterAndMapTollDocUploaded('potential_misuse')
        }
      ]
    };
    const totals = getTotalPotentialSavings(data);
    aggData.totalTolls = totals.totalTolls;
    aggData.totalAmount = totals.totalAmount;
    aggData.grandTotalAmount = data[0] ? data[0].grand_total : 0;
    aggData.grandTotalCount = data[0] ? data[0].grand_total_count : 0;

    setAggData({ ...aggData });
    setPotentialSavingsData(chartData);
  };

  const fetchPotentialSavingsData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/insights/potential-savings', {
        moduleId: 'INSIGHTS'
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForBarChart(response.data.data, response.data.dateRange);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling Potential Savings data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchPotentialSavingsData().catch(err => {
        console.log('Error in Potential Savings data ==> ', err);
      });
    }
  }, [customerId]);

  return {
    potentialSavingsData,
    isLoading,
    showError,
    aggData,
    fetchPotentialSavingsData
  };
};

export default usePotentialSavingsData;
