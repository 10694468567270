import { useState } from 'react';

export const useHandleCompanyInfoTitle = (initialTitle: string) => {
  const [activeTitle, setActiveTitle] = useState<string>(initialTitle || 'Organization');
  const [prevTitle, setPrevTitle] = useState<string>('');
  const handleActiveTitle = (value: string) =>
    setActiveTitle(prev => {
      setPrevTitle(prev);
      return value;
    });

  return { activeTitle, prevTitle, handleActiveTitle };
};
