/* eslint-disable */
import { ETableName } from '@common/utils/enums';

export const tableNameByLength = (length: number, tableName: ETableName) => {
  const single = length === 1;
  const titles = {
    trips: single ? 'trip' : 'trips',
    fleet: single ? 'vehicle' : 'vehicles',
    transactions: single ? 'transaction' : 'transactions',
    trailers: single ? 'trailer' : 'trailers',
    customers: single ? 'customer' : 'customers',
    roles: single ? 'role' : 'roles',
    users: single ? 'user' : 'users',
    toll_documents: single ? 'toll document' : 'toll documents'
  };
  return titles[tableName];
};
