import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * @param handlePrompt - function to notify user when idle prompt is triggered
 * @param handleIdle - function to notify user when user is idle
 * @param idleTime - number of seconds to wait before user is logged out
 */

interface UseIdleTimeoutProps {
  handleIdle?: () => void;
  idleTime?: number;
}

export const useIdleTimeout = ({ handleIdle, idleTime = 1 }: UseIdleTimeoutProps) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const ignoreIdling =
    process.env.REACT_APP_DISABLE_IDLING && Number(process.env.REACT_APP_DISABLE_IDLING) === 1;

  const handleOnIdle = () => {
    if (!ignoreIdling) {
      setIdle(true);
      if (handleIdle) {
        handleIdle();
      }
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500
  });

  return {
    isIdle,
    setIdle,
    idleTimer
  };
};
