/* eslint-disable import/no-cycle */
import { errorMessage } from '@common/services/format';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { setApiResponse } from '@store/features/modal';
import { useAppDispatch } from '@store/hooks';
import { useLogOut } from './useLogOut';

type TAction = 'delete' | 'create' | 'update' | 'upload' | 'import' | 'export';
const actionText = {
  create: ['Creating', 'created', 'creating'],
  delete: ['Deleting', 'deleted', 'deleting'],
  update: ['Updating', 'updated', 'updating'],
  upload: ['Uploading', 'uploaded', 'uploading'],
  import: ['Importing', 'imported', 'importing'],
  export: ['Exporting', 'exported', 'exporting']
};

export const useHandleApiResponse = () => {
  const logOut = useLogOut();
  const dispatch = useAppDispatch();

  const handleError = (error: FetchBaseQueryError, action: TAction, extraInfo: string) => {
    const message = errorMessage(error);
    dispatch(
      setApiResponse({
        type: 'error',
        apiAction: `Error occurred while ${actionText[action][2]} ${extraInfo}`,
        message
      })
    );
    if (error.status === 401) {
      logOut();
    }
    throw new Error(message);
  };
  const handleSuccess = (action: string, message = '') => {
    dispatch(
      setApiResponse({
        type: 'success',
        apiAction: action,
        message
      })
    );
  };

  return { handleError, handleSuccess };
};
