import queryString from 'query-string';
import {
  useGetQuicksightActualTripQuery,
  useGetQuicksightAltTripsQuery
} from '@api/services/quicksight';
import { formatDistance } from '@common/services/format';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetSingleTripQuery } from '@api/services/trips';
import { useAppSelector } from '@store/hooks';

export const useQuickSightMap = () => {
  const location = useLocation();
  const queries = queryString.parse(location.search, {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true
  }) as {
    tripId: string | number;
  };
  const { data: trip } = useGetSingleTripQuery(queries.tripId as string, {
    refetchOnReconnect: true,
    skip: Object.values(queries)?.length === 0 || !queries.tripId
  });
  const { data: originalTrip } = useGetQuicksightActualTripQuery(queries.tripId, {
    refetchOnReconnect: true,
    skip: Object.values(queries)?.length === 0 || !queries.tripId
  });
  const { data: altTrips } = useGetQuicksightAltTripsQuery(queries.tripId, {
    refetchOnReconnect: true,
    skip: Object.values(queries)?.length === 0 || !queries.tripId
  });
  const [tripType, setTripType] = useState<'actual' | 'alt'>('alt');
  const handleTripType = (value: 'actual' | 'alt') => {
    setTripType(value);
  };

  const isMetric = useAppSelector(state => state.auth.isMetric);
  const actualTripDistance = isMetric ? trip?.distance : trip?.distanceInMiles;
  let altTripDistance = altTrips && altTrips[0] && altTrips[0].distance;
  if (altTripDistance && isMetric) {
    altTripDistance = formatDistance(altTripDistance, 'miles');
  }

  const headerDetails = {
    duration:
      tripType === 'actual'
        ? (altTrips && altTrips[0] && altTrips[0].duration) || undefined
        : trip?.timeSpent,
    cost: tripType === 'actual' ? trip?.moneySpent : undefined,
    distance: tripType === 'actual' ? actualTripDistance : altTripDistance || undefined
  };

  return { originalTrip, altTrips, queries, headerDetails, tripType, handleTripType };
};
