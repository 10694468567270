import { supportLink } from '@common/utils/route-names';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './nav-item.module.scss';
import { NavItemProps } from './types';

const NavItem: FC<NavItemProps> = ({
  notifications,
  navItem,
  activeCustomClassName = '',
  className = '',
  isMobile
}) => {
  const activeClassName = ({ isActive }: { isActive: boolean }) =>
    `${styles['nav-item']} ${
      isActive && navItem.title !== 'signout' ? activeCustomClassName || styles.active : ''
    } ${isMobile ? styles['mobile-nav-item'] : ''} ${className}`;

  return (
    <>
      {navItem.title === 'support' ? (
        <div
          role="presentation"
          className={activeClassName({ isActive: false })}
          onClick={() => window.open(supportLink)}
        >
          <span className={styles.icon}>
            <navItem.icon />
          </span>
          <span className={styles.text}>{navItem.title}</span>
        </div>
      ) : (
        <NavLink className={activeClassName} to={navItem.link}>
          <span
            className={
              navItem.title === 'notifications' && notifications?.length !== 0
                ? `${styles.icon} ${styles['notifications-icon']}`
                : styles.icon
            }
          >
            <navItem.icon />
          </span>
          <span className={styles.text}>
            {navItem.title}
            {navItem.title === 'notifications' && notifications?.length !== 0 && (
              <span className={styles['text-notifications']}>
                {!(notifications && notifications?.length > 9) ? notifications?.length : '9+'}
              </span>
            )}
          </span>
        </NavLink>
      )}
    </>
  );
};

export default NavItem;
