/* eslint-disable */
import Meta from '@common/components/meta';
import WebPortalTable from './dispute-table';
import DisputesGeoTabTable from './geotab-table';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';

const DisputeReports = () => {
  const metaOptions = {
    title: 'Dispute Reports',
    keywords: 'Dispute Reports'
  };

  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <>
      <Meta {...metaOptions} />

      {isGeotab ? (
        <DisputesGeoTabTable title="Dispute Reports" />
      ) : (
        <WebPortalTable title="Dispute Reports" />
      )}
    </>
  );
};

export default DisputeReports;
