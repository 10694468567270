/* eslint-disable */
import styles from './billing.module.scss';

const BillTable = ({ subscribedVehicle }: any) => {
  const header = ['name', 'vin', 'state', 'license plate'];

  return (
    <div className={styles.leftComponent}>
      <section className={styles.rightContainer}>
        <div className={styles.header}>
          <section className={styles.row}>
            <section className={styles.text}>
              {header.map(heading => (
                <>
                  <h3>{heading}</h3>
                </>
              ))}
            </section>
          </section>
        </div>
        <div className={styles.body}>
          {subscribedVehicle &&
            subscribedVehicle.map((item: any) => (
              <section className={styles.row}>
                <section className={styles.text}>
                  <p id="name">{item?.name ? item.name : '-'}</p>
                  <p id="vin">{item?.vin ? item.vin : '-'}</p>
                  <p id="state">{item?.plate_state ? item.plate_state.toUpperCase() : '-'}</p>
                  <p id="licensePlate">
                    {item?.plate_number ? item.plate_number.toUpperCase() : '-'}
                  </p>
                </section>
              </section>
            ))}
        </div>
      </section>
    </div>
  );
};

export default BillTable;
