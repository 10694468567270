import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clearFilters, setFilters } from '@store/features/filters';
import { ETableName } from '@common/utils/enums';
import { IUnionFilters } from '@common/types/filters';
import { setCurrentPage } from '@store/features/pagination';
// import { UserDataInterface } from '@common/types/data';
// import { filterUsers } from '@pages/tables/users/helpers/filterUsers';
// import { clearTable, setTable } from '@store/data';

export const useFiltersForm = () => {
  const table = useAppSelector(state => state.table);
  const filtering = useAppSelector(state => state.filters[table.name as ETableName]);

  const initialValues = <IUnionFilters>filtering?.by;

  const dispatch = useAppDispatch();

  const showResults = (values: IUnionFilters) => {
    // if (table.name === ETableName.USERS) {
    //   const filteredData = filterUsers(values, table.data as UserDataInterface[]);
    //   const isLoading = false;
    //   const isError = false;
    //   const isSuccess = true;
    //   const isFetching = false;
    //   const error = undefined;
    //   dispatch(clearTable());
    //   dispatch(
    //     setTable({
    //       data: filteredData,
    //       name: ETableName.USERS,
    //       isLoading,
    //       isError,
    //       isSuccess,
    //       isFetching,
    //       error
    //     })
    //   );
    // }
    dispatch(
      setFilters({
        table: table.name as ETableName,
        filters: values
      })
    );
    dispatch(
      setCurrentPage({
        table: table.name as ETableName,
        currentPage: 1
      })
    );
  };

  const clearResults = () => {
    if (table.name) dispatch(clearFilters(table.name));
  };

  return { initialValues, showResults, clearResults };
};
