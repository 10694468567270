import Logo from '@common/ui/Logo';
import { FC } from 'react';
import AuthTitle from '../AuthTitle/AuthTitle';
import styles from './auth-body.module.scss';
import { AuthBodyProps } from './types';

const Body: FC<AuthBodyProps> = ({ className = '', children, title }) => {
  return (
    <section className={`${styles.container} ${className}`}>
      <Logo className={styles.logo} />
      <AuthTitle title={title} />
      {children}
    </section>
  );
};

export default Body;
