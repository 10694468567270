/* eslint-disable @typescript-eslint/no-unsafe-return */
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDragActions = (data: { id: string; text: string; active: boolean }[]) => {
  const [cards, setCards] = useState(data);

  const handleOnDragEnd = (event: DragEndEvent, identifiers: string[]) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setCards(() => {
        const oldIndex = identifiers.indexOf(active.id as string);
        const newIndex = identifiers.indexOf(over?.id as string);

        return arrayMove(cards, oldIndex, newIndex);
      });
    }
  };

  const handleActiveState = (text: string, active: boolean) => {
    const updatedCards = [...cards];
    const cardIndex = updatedCards.findIndex(item => item.text === text);
    const prevCard = updatedCards.find(item => item.text === text);
    if (cardIndex || cardIndex === 0) {
      updatedCards[cardIndex] = {
        id: prevCard?.id || '',
        text: prevCard?.text || '',
        active
      };
      setCards(updatedCards);
    }
  };

  const deselectAllItems = () => {
    setCards(cards.map(item => ({ ...item, active: false })));
  };

  const selectAllItems = () => {
    setCards(cards.map(item => ({ ...item, active: true })));
  };
  const isTrueToggle = cards.every(item => item.active);
  const isFalseToggle = cards.every(item => !item.active);

  return {
    cards,
    isTrueToggle,
    isFalseToggle,
    handleOnDragEnd,
    handleActiveState,
    deselectAllItems,
    selectAllItems
  };
};
