import { useAppDispatch, useAppSelector } from '@store/hooks';
import { changeShowBy, setCurrentPage } from '@store/features/pagination';
import { ETableName } from '@common/utils/enums';

export const useTablePaginate = () => {
  const dispatch = useAppDispatch();

  const pagination = useAppSelector(state => state.pagination.alerts);

  const handlePageState = (activePage: number) =>
    dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: activePage }));
  const handleShowByState = (value: string | number) => {
    dispatch(changeShowBy({ table: 'alerts' as ETableName, showBy: Number(value) }));
    dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 1 }));
  };

  return {
    showBy: pagination?.showBy || 10,
    showByValues: pagination?.showByValues || [],
    handleShowByState,
    currentPage: pagination?.currentPage || 1,
    handlePageState,
    totalPages: pagination?.totalPages || 1,
    dataLength: pagination?.dataLength || 0,
    pagesArray: pagination?.pagesArray || []
  };
};
