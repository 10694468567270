import * as Yup from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
  email: Yup.string().email('enter a valid email').required('email is required'),
  name: Yup.string().required('name is required'),
  roleCode: Yup.string().required('role is required'),
  isPasswordChanged: Yup.boolean().required(),
  password: Yup.string().when('isPasswordChanged', {
    is: true,
    then: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        'Password must be at least 8 characters long, contain at least one number, one special character, one uppercase letter, and one lowercase letter'
      ),
    otherwise: Yup.string().nullable()
  })
});
