/* eslint-disable */
import { useGetCalculatedTollsQuery } from '@api/services/trips';
import { ICalculatedToll } from '@api/types';
import Loader from '@common/ui/Loader';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import styles from './tolls-list.module.scss';
import { ModalActions } from '../../shared';
import Table from './Table/Table';

const TollsList = ({ tripId, timezone }: { tripId: string | number; timezone: string }) => {
  const { data: estimatedTolls, isLoading } = useGetCalculatedTollsQuery(tripId, {
    refetchOnReconnect: true,
    skip: !tripId
  });

  const [tolls, setTolls] = useState<ICalculatedToll[]>([]);
  const [searchBy, setSearchBy] = useState<string | number>('');
  const handleSearchBy = (value: string | number) => setSearchBy(value);

  useEffect(() => {
    if (searchBy) {
      setTolls(
        estimatedTolls?.filter(toll =>
          Object.values(toll).find((value: string | number) =>
            _.kebabCase(`${value}`.toLowerCase()).includes(_.kebabCase(`${searchBy}`.toLowerCase()))
          )
        ) || []
      );
    } else if (estimatedTolls && !isLoading) {
      setTolls(estimatedTolls || []);
    }
  }, [estimatedTolls, searchBy, isLoading]);

  return (
    <section
      ref={el =>
        (el?.scrollHeight || 0) > (el?.clientHeight || 0)
          ? el?.classList?.add(styles['with-scrollbar'])
          : el?.classList?.remove(styles['with-scrollbar'])
      }
      className={styles.container}
    >
      {/* <ModalActions type="searching" handleSearchBy={handleSearchBy} /> */}
      {isLoading && !estimatedTolls ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          {tolls?.length !== 0 ? (
            <Table estimatedTolls={tolls} searchValue={searchBy} timezone={timezone} />
          ) : (
            <h3>No tolls to display</h3>
          )}
        </>
      )}
    </section>
  );
};

export default TollsList;
