/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerDataInterface } from '@common/types/data';
import { customersApi } from '@api/services/customers';
import Cookies from 'js-cookie';
import { cookieCustomer } from '@common/services/auth';

const initialState: CustomerDataInterface & { isVisible?: boolean; loading?: boolean } = {
  id: '',
  companyName: '',
  isSuperAdmin: false,
  adminEmail: '',
  companyAddress: '',
  statusCode: '',
  companyLogo: false,
  geotabIntegration: false,
  geotabOrderNow: false,
  geotabDatabase: '',
  geotabServiceUser: '',
  geotabServicePassword: '',
  phoneNumber: '',
  logoContent: '',
  sourceType: '',
  isVisible: false,
  loading: false
};

export const getCustomerData = createAsyncThunk(
  'customer/fetchById',
  async ([customerId]: [string], thunkAPI) => {
    const customer = await thunkAPI.dispatch(
      customersApi.endpoints.getSingleCustomer.initiate(customerId)
    );
    const customerData = customer.data || ({} as CustomerDataInterface);
    // if (!!roleCode?.match(/Administrator/i) && !customerData.statusCode?.match(/active/i)) {
    //   await thunkAPI
    //     .dispatch(
    //       customersApi.endpoints.updateCustomer.initiate([
    //         { ...customerData, statusCode: 'ACTIVE' },
    //         null
    //       ])
    //     )
    //     .catch(e => console.log(e));
    // }
    return customerData;
  }
);

export const customerSlice = createSlice({
  name: 'current customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerDataInterface>) => {
      const { isSuperAdmin } = action.payload;
      if (isSuperAdmin) {
        Cookies.set(cookieCustomer, JSON.stringify(action.payload));
      }
      return action.payload;
    },
    selectVisibleCustomerOption: state => {
      return { ...state, isVisible: true };
    },
    disableVisibleCustomerOption: state => {
      return { ...state, isVisible: false };
    },
    clearCustomer: () => initialState
  },
  extraReducers(builder) {
    builder.addCase(getCustomerData.pending, state => {
      return { ...state, loading: true };
    });
    builder.addCase(getCustomerData.fulfilled, (state, action) => action.payload);
  }
});

export const {
  setCustomer,
  clearCustomer,
  selectVisibleCustomerOption,
  disableVisibleCustomerOption
} = customerSlice.actions;

export default customerSlice.reducer;
