import React from 'react';
import { Skeleton, Box } from '@mui/material';
import styles from './common.module.scss';

const BarChartSkeleton: React.FC = () => {
  return (
    <Box className={styles.skeletonContainer}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(key => (
        <Skeleton
          key={key}
          variant="rectangular"
          height={`${Math.floor(Math.random() * 100) + 50}px`}
          width="100%"
          className={styles.skeletonBar}
        />
      ))}
    </Box>
  );
};

export default BarChartSkeleton;
