import Dropdown from '@common/ui/Dropdown';
import { useState } from 'react';

const DurationDropdown = ({
  values,
  selected,
  handleChange,
  dropdownClassName = '',
  children
}: {
  values: string[];
  selected: string;
  dropdownClassName?: string;
  children?: React.ReactNode;
  handleChange: VoidFuncSP<string | number>;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = (isOpen: boolean) => setOpen(isOpen);
  return (
    <Dropdown
      isOpen={open}
      values={values}
      handleOpenState={handleOpen}
      handleSelectionState={handleChange}
      type="select"
      selection={selected}
      className={dropdownClassName}
      position="bottom"
      offset={[5, 0]}
    >
      {children}
    </Dropdown>
  );
};

export default DurationDropdown;
