/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { useAppSelector } from '@store/hooks';
import { IApiResponse, IBarChartData, IDate, ITripInsights } from '../types';
import { getOrdinalSuffix } from '@pages/dashboard/common/helper';

const useTripInsightsData = (
  selectedMonth: string
): {
  tripInsightsData: IBarChartData | undefined;
  tollTripCostData: IBarChartData | undefined;
  tollTripCountData: IBarChartData | undefined;
  averageTollPerHourData: IBarChartData | undefined;
  averageTollPerMileData: IBarChartData | undefined;

  isLoading: boolean;
  showError: boolean;
  fetchTripInsightsData: () => Promise<void>;
} => {
  const [tripInsightsData, setTripInsightsData] = useState<IBarChartData>();
  const [tollTripCostData, setTollTripCostData] = useState<IBarChartData>();
  const [tollTripCountData, setTollTripCountData] = useState<IBarChartData>();
  const [averageTollPerHourData, setAverageTollPerHourData] = useState<IBarChartData>();
  const [averageTollPerMileData, setAverageTollPerMileData] = useState<IBarChartData>();

  const [isLoading, setIsLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);

  const transformDataForBarChart = (data: ITripInsights[], dateRange: IDate[]) => {
    let sortedData = data
      .slice()
      .sort((a, b) => new Date(a.month_date).getTime() - new Date(b.month_date).getTime());

    let labels;
    if (selectedMonth.length > 0) {
      const updatedData = sortedData.map((item, index) => ({
        ...item,
        // xaxis: `${getOrdinalSuffix(item.month_date)} - ${getOrdinalSuffix(item.end_date)}`
        xaxis: `Week ${index + 1}`
      }));

      sortedData = updatedData;
      // labels = sortedData.map(item => `${getOrdinalSuffix(item.month_date)} - ${getOrdinalSuffix(item.end_date)}`);
      labels = sortedData.map((item, index) => `Week ${index + 1}`);
    } else {
      const updatedData = sortedData.map(item => ({
        ...item,
        xaxis: moment.utc(item.month_date).format('MMM')
      }));

      sortedData = updatedData;
      labels = dateRange.map(item => moment.utc(item.month_date).format('MMM'));

      const chartData: IBarChartData = {
        labels,
        datasets: [
          {
            label: 'Value Trips',
            data: sortedData, //  sortedData.map(item => Math.floor(item.valued_trip_count)),
            parsing: {
              yAxisKey: 'valued_trip_count'
            },
            backgroundColor: '#A3C8E7',
            borderRadius: 2
          },
          {
            label: 'No-value Trips',
            data: sortedData, //sortedData.map(item => Math.floor(item.novalued_trip_count)),
            parsing: {
              yAxisKey: 'novalued_trip_count'
            },
            backgroundColor: '#004C6D',
            borderRadius: 2
          }
        ]
      };

      setTripInsightsData(chartData);
    }

    const tollTripCostChartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'valued_trip_total'
          },
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          trip_total: sortedData
            .reduce((acc, item) => acc + Number(item.valued_trip_total), 0)
            .toFixed(2)
        },
        {
          label: 'No-value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'novalued_trip_total'
          },
          backgroundColor: '#004C6D',
          borderRadius: 2,
          trip_total: sortedData
            .reduce((acc, item) => acc + Number(item.novalued_trip_total), 0)
            .toFixed(2)
        }
      ]
    };
    const tollTripCountChartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'valued_trip_count'
          },
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          trip_total: sortedData
            .reduce((acc, item) => acc + Number(item.valued_trip_count), 0)
            .toFixed(2)
        },
        {
          label: 'No-value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'novalued_trip_count'
          },
          backgroundColor: '#004C6D',
          borderRadius: 2,
          trip_total: sortedData
            .reduce((acc, item) => acc + Number(item.novalued_trip_count), 0)
            .toFixed(2)
        }
      ]
    };
    const averageTollPerHourChartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'valued_avgtoll_per_hour'
          },
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          avgtoll_per_hour: (
            sortedData.reduce((acc, item) => acc + Number(item.valued_avgtoll_per_hour), 0) /
            labels.length
          ).toFixed(2)
        },
        {
          label: 'No-value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'novalued_avgtoll_per_hour'
          },
          backgroundColor: '#004C6D',
          borderRadius: 2,
          avgtoll_per_hour: (
            sortedData.reduce((acc, item) => acc + Number(item.novalued_avgtoll_per_hour), 0) /
            labels.length
          ).toFixed(2)
        }
      ]
    };
    const averageTollPerMileChartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'valued_avgtoll_per_mile'
          },
          backgroundColor: '#A3C8E7',
          borderRadius: 2,
          avgtoll_per_mile: (
            sortedData.reduce((acc, item) => acc + Number(item.valued_avgtoll_per_mile), 0) /
            labels.length
          ).toFixed(2)
        },
        {
          label: 'No-value Trips',
          data: sortedData,
          parsing: {
            yAxisKey: 'novalued_avgtoll_per_mile'
          },
          backgroundColor: '#004C6D',
          borderRadius: 2,
          avgtoll_per_mile: (
            sortedData.reduce((acc, item) => acc + Number(item.novalued_avgtoll_per_mile), 0) /
            labels.length
          ).toFixed(2)
        }
      ]
    };

    setTollTripCostData(tollTripCostChartData);
    setTollTripCountData(tollTripCountChartData);
    setAverageTollPerHourData(averageTollPerHourChartData);
    setAverageTollPerMileData(averageTollPerMileChartData);
  };

  const fetchTripInsightsData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/insights/trip-insights', {
        moduleId: selectedMonth.length > 0 ? 'MONTHLY_DETAIL' : 'INSIGHTS',
        monthDate: selectedMonth
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForBarChart(response.data.data, response.data.dateRange);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling Trip Insights data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTripInsightsData().catch(err => {
      console.log('Error in Trip Insights data ==> ', err);
    });
  }, [customerId, selectedMonth]);

  return {
    tripInsightsData,
    tollTripCostData,
    tollTripCountData,
    averageTollPerHourData,
    averageTollPerMileData,
    isLoading,
    showError,
    fetchTripInsightsData
  };
};

export default useTripInsightsData;
