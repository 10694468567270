import { FC } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import styles from './context-menu.module.scss';
import { ContextMenuProps, ContextMenuItemProps } from './types';

const ContextMenuItem: FC<ContextMenuItemProps> = ({ ...value }) => {
  return (
    <li aria-label="dropdown-actions-item" key={uuid()}>
      <button
        type="button"
        className={`${styles.container} ${styles[value.text?.toLowerCase()]} ${
          value.className || ''
        }`}
        onClick={e => {
          if (value.onClick) value.onClick();
          e.stopPropagation();
        }}
        title={value?.title}
      >
        <span className={styles['item-icon']}>{value?.icon}</span>
        {value?.text}
      </button>
    </li>
  );
};

const ContextMenu: FC<ContextMenuProps> = ({ searchResults, values, handleOpenState }) => {
  return (
    <>
      {(searchResults !== null ? searchResults : values)?.map((value: ContextMenuItemProps) =>
        value.link ? (
          <Link to={value.link} key={uuid()}>
            <ContextMenuItem
              {...value}
              onClick={() => {
                if (value.onClick) value.onClick();
                handleOpenState(false);
              }}
            />
          </Link>
        ) : (
          <ContextMenuItem
            key={uuid()}
            {...value}
            onClick={() => {
              if (value.onClick) value.onClick();
              handleOpenState(false);
            }}
          />
        )
      )}
    </>
  );
};

export default ContextMenu;
