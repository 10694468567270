/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable @typescript-eslint/naming-convention */
import api from '@api/services/common';
import { usePromiseToastNotify } from '@common/hooks';
import { ExportDateTimeformatHelper } from '@common/services/format/formatTimeZone';
import * as XLSX from 'xlsx';

interface ClassifiedVehicleData {
  vehicle_id: string;
  vehicle_name: string;
  license_plate: string;
  billing_status: string;
  updated_date: string;
  classification: string;
}

interface IApiResponse {
  isSuccess: boolean;
  data: ClassifiedVehicleData[];
}

interface SeparatedData {
  [key: string]: ClassifiedVehicleData[];
}

export const useSubscriptionExport = () => {
  const toastify = usePromiseToastNotify();

  const subscriptionExport = () => {
    toastify(
      async () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const response: IApiResponse = await api.get('/vehicle/subscription-activity-export');
        if (response && response.isSuccess && response.data) {
          if (response.data.length > 0) {
            const separatedData: SeparatedData = {
              newly_subscribed: [],
              newly_unsubscribed: [],
              total_subscribed: []
            };
            response.data.forEach((item: ClassifiedVehicleData) => {
              if (item.classification && item.classification in separatedData) {
                separatedData[item.classification].push(item);
              }
            });
            const wb = XLSX.utils.book_new();

            const ws = XLSX.utils.aoa_to_sheet([]);

            let rowIndex = 0;

            const classificationOrder = [
              'total_subscribed',
              'newly_subscribed',
              'newly_unsubscribed'
            ];

            const customHeadings: Record<string, string> = {
              total_subscribed: 'Total Subscribed Vehicle',
              newly_subscribed: 'Newly Subscribed Vehicle',
              newly_unsubscribed: 'Newly Unsubscribed Vehicle'
            };

            const columnNames: { [key: string]: string } = {
              vehicle_id: 'Vehicle ID',
              vehicle_name: 'Vehicle Name',
              license_plate: 'License Plate',
              billing_status: 'Billing Status',
              updated_date: 'Billing Status Updated On'
            };

            classificationOrder.forEach(category => {
              const data = separatedData[category];
              const categoryHeading = [customHeadings[category]];
              if (data.length > 0) {
                XLSX.utils.sheet_add_aoa(ws, [categoryHeading], {
                  origin: { r: rowIndex, c: 0 }
                });
                rowIndex += 1;
                const keys = Object.keys(data[0]).filter(key => key !== 'classification');
                const columnHeaders = keys.map(key => columnNames[key]);
                XLSX.utils.sheet_add_aoa(ws, [columnHeaders], { origin: { r: rowIndex, c: 0 } });
                rowIndex += 1;

                data.forEach(item => {
                  const { vehicle_id, vehicle_name, license_plate, billing_status, updated_date } =
                    item;
                  const row = [
                    vehicle_id,
                    vehicle_name,
                    license_plate,
                    billing_status,
                    ExportDateTimeformatHelper(updated_date)
                  ];
                  XLSX.utils.sheet_add_aoa(ws, [row], { origin: { r: rowIndex, c: 0 } });
                  rowIndex += 1;
                });

                rowIndex += 1;
              } else {
                XLSX.utils.sheet_add_aoa(ws, [categoryHeading], {
                  origin: { r: rowIndex, c: 0 }
                });
                rowIndex += 1;
                const noRecordsMessage = ['No Records'];
                XLSX.utils.sheet_add_aoa(ws, [noRecordsMessage], {
                  origin: { r: rowIndex, c: 0 }
                });
                rowIndex += 2;
              }
            });

            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `subscription.xlsx`);
          } else {
            throw { data: 'No record for export' };
          }
        } else {
          throw { data: 'Error in export' };
        }
      },
      'export',
      'data'
    );
  };

  return { subscriptionExport };
};
