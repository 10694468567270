import { Formik, Form } from 'formik';
import _ from 'lodash';
import TextInput from '@common/ui/Form/TextInput';
import Button from '@common/ui/Button';
import { useResetPasswordMutation } from '@api/services/auth';
import { useNavigate } from 'react-router-dom';
import { routes } from '@common/utils/route-names';
import { resetInitialValues } from './utils/initialValues';
import { resetValidationSchema } from './utils/validationSchema';
import styles from './form.module.scss';

const FormComp = ({
  handlePasswordChanged,
  email
}: {
  handlePasswordChanged: (value: boolean) => void;
  email: string;
}) => {
  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={resetInitialValues}
      validationSchema={resetValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          email,
          code: values.code,
          password: values.password
        };
        resetPassword(data)
          .unwrap()
          .then(() => {
            handlePasswordChanged(true);
          })
          .catch((error: unknown) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ errors, values, isSubmitting }) => (
        <Form className={styles.container}>
          <TextInput
            className={styles.input}
            label="Code"
            name="code"
            type="text"
            placeholder="find code in received email."
          />
          <TextInput
            className={styles.input}
            label="New password"
            name="password"
            type="password"
          />
          <TextInput
            className={styles.input}
            label="Repeat password"
            name="repeat_password"
            type="password"
          />
          <Button
            appearance="button"
            disabled={
              _.compact(Object.values(values || {})).length < 2 ||
              _.compact(Object.values(errors || {})).length > 0 ||
              isSubmitting
            }
            size="s"
            label={isSubmitting ? 'Resetting...' : 'Confirm password'}
            buttonType="submit"
          />
          <Button
            appearance="text"
            size="s"
            label="Back"
            onClick={() => {
              navigate(routes.forgot_password, { replace: true });
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FormComp;
