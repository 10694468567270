import { AddDocument /* , QRCode */ } from '@assets/icons';
import Button from '@common/ui/Button';
import Modal from '@common/ui/Modal';
import Upload from 'rc-upload';
import { useFileUpload } from './hooks/useFileUpload';
import styles from './select-file.module.scss';

const SelectFile = ({ handleFileSelected }: { handleFileSelected: (value: boolean) => void }) => {
  const { props, error, dragging, handleDragging } = useFileUpload('file', handleFileSelected, [
    '.xlsx',
    '.xls',
    '.csv',
    '.pdf'
  ]);

  return (
    <Modal className={styles.modal} hasFooter={false} hasHeader={false} cancelButton={false}>
      <div className={styles.container}>
        <Upload {...props}>
          <section
            onDragEnter={() => handleDragging(true)}
            onDragOver={() => handleDragging(true)}
            onDragLeave={() => handleDragging(false)}
            onDragExit={() => handleDragging(false)}
            className={`${styles['drag-box']} ${dragging ? styles.dragging : ''} ${
              error[0] ? styles.error : ''
            }`}
          >
            <figure>
              <AddDocument />
            </figure>
            {!error[0] ? (
              <>
                <span>Drag your documents to start uploading</span>
                <span>or</span>
              </>
            ) : (
              <span>{error[1].toString() || 'error occurred, try again'}</span>
            )}
            <Button size="xs" label="Browse files" appearance="button" />
          </section>
        </Upload>
        {/* <section className={styles['scan-box']}>
          <figure>
            <QRCode />
          </figure>
          <span>Scan QR code to take a photo</span>
        </section> */}
      </div>
    </Modal>
  );
};

export default SelectFile;
