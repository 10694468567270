/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useState } from 'react';
import { Close, Search } from '@assets/icons';
import styles from './duration-picker.module.scss';
import DurationDropdown from './Dropdown/Dropdown';

const toSeconds = (value: { days: number; hours: number; minutes: number; seconds: number }) =>
  (value.days || 0) * 3600 * 24 +
  (value.hours || 0) * 3600 +
  (value.minutes || 0) * 60 +
  (value.seconds || 0);

const DurationPicker = ({
  initial,
  handleDurationChange,
  className = ''
}: {
  initial: number;
  handleDurationChange: (value: number) => void;
  className?: string;
}) => {
  const [duration, setDuration] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const handleChange = (value: number, key: string) => {
    setDuration(prev => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    if (initial) {
      const isNegative = initial < 0;
      const absoluteInitial = Math.abs(initial);
      const day = Math.floor(absoluteInitial / (3600 * 24)) || 0;
      const hour = Math.floor((absoluteInitial % (3600 * 24)) / 3600);
      const minute = Math.floor((absoluteInitial % 3600) / 60);
      const second = Math.floor(absoluteInitial % 60);

      const days = isNegative ? -day : day;
      const hours = isNegative ? -hour : hour;
      const minutes = isNegative ? -minute : minute;
      const seconds = isNegative ? -second : second;
      setDuration({
        days,
        hours,
        minutes,
        seconds
      });
    }
  }, [initial]);

  const getRange = () => {
    const daterange = Array.from({ length: 121 }, (_, i) => i - 60)
      .filter(i => i !== 0)
      .map(i => i.toString());
    return daterange;
  };

  return (
    <div
      className={`${styles.container} ${className}`}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleDurationChange(toSeconds(duration));
        }
      }}
    >
      <div className={`${styles.box} ${styles.days}`}>
        <input
          type="number"
          placeholder="d"
          value={duration.days || ''}
          onChange={e => handleChange(e.target.valueAsNumber, 'days')}
          min={1}
        />
      </div>
      <div className={`${styles.box} ${styles.hours}`}>
        <DurationDropdown
          dropdownClassName={styles.dropdown}
          // values={range(-24, 25).map(i => i.toString())}
          values={Array.from({ length: 121 }, (_, i) => i - 60)
            .filter(i => i !== 0)
            .map(i => i.toString())}
          selected={duration.hours ? duration.hours.toString() : ''}
          handleChange={value => handleChange(Number(value), 'hours')}
        >
          <input
            type="number"
            placeholder="h"
            value={duration.hours || ''}
            onChange={e => handleChange(e.target.valueAsNumber, 'hours')}
            min={1}
          />
        </DurationDropdown>
      </div>
      <div className={`${styles.box} ${styles.minutes}`}>
        <DurationDropdown
          dropdownClassName={styles.dropdown}
          // values={range(-60, 61).map(i => i.toString())}
          values={getRange()}
          selected={duration.minutes ? duration.minutes.toString() : ''}
          handleChange={value => handleChange(Number(value), 'minutes')}
        >
          <input
            type="number"
            placeholder="m"
            value={duration.minutes || ''}
            onChange={e => handleChange(e.target.valueAsNumber, 'minutes')}
            min={1}
          />
        </DurationDropdown>
      </div>
      <div className={`${styles.box} ${styles.seconds}`}>
        <DurationDropdown
          dropdownClassName={styles.dropdown}
          // values={range(-60, 61).map(i => i.toString())}
          values={getRange()}
          selected={duration.seconds ? duration.seconds.toString() : ''}
          handleChange={value => handleChange(Number(value), 'seconds')}
        >
          <input
            type="number"
            placeholder="s"
            value={duration.seconds || ''}
            onChange={e => handleChange(e.target.valueAsNumber, 'seconds')}
            min={1}
          />
        </DurationDropdown>
      </div>
      <div className={styles.buttons}>
        <button
          type="button"
          className={styles.search}
          onClick={() => {
            handleDurationChange(toSeconds(duration));
          }}
          aria-label="search"
        >
          <Search />
        </button>
        <button
          type="button"
          className={styles.clear}
          onClick={() => {
            setDuration({
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0
            });
            handleChange(0, 'minutes');
            handleDurationChange(0);
          }}
          aria-label="clear"
        >
          <Close />
        </button>
      </div>
    </div>
  );
};

export default DurationPicker;
