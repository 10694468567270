import { FiArrowRightCircle } from 'react-icons/fi';
import Footer from '../layout/footer/Footer';
import styles from './overview.module.scss';

const OverView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.scrollableContent}>
        <main className={styles['welcome-text']}>
          While not required, we recommend gathering the following information before moving forward
          for a quick onboarding:
        </main>
        <div>
          <h1>Company Details</h1>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Account admin contact information</p>
          </span>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Registered & billing addresses</p>
          </span>
        </div>
        <div>
          <h1>Fleet specifications</h1>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>License plate numbers & states</p>
          </span>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Assets weight & height</p>
          </span>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Assets axle & tire counts</p>
          </span>
        </div>
        <div>
          <h1>Toll transponder information</h1>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Device ID numbers</p>
          </span>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Device issuer (agency or consolidator)</p>
          </span>
          <span>
            <FiArrowRightCircle className={styles.icon} />
            <p>Device type (ex. E-ZPass, NTTA TollTag, SunPass, etc.)</p>
          </span>
        </div>
        <div className={styles.notice_div}>
          <h2>Notice:</h2>
          <span>
            <p>
              This add-in creates a service account within Geotab for integration purposes. It has
              view-only rights and is not a billable Geotab account.
            </p>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OverView;
