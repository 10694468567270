import { useRef, FC } from 'react';
import styles from './select-input-display.module.scss';
import { SelectInputDisplayProps } from './types';
import SelectInputIcons from '../SelectInputIcons';
import SelectInputContent from '../SelectInputContent';

const SelectInputDisplay: FC<SelectInputDisplayProps> = ({
  isOpen,
  disabled,
  selection,
  icon,
  placeholder,
  label,
  hideArrows = false,
  noSelection = false,
  error = false,
  type,
  handleSelectionState
}) => {
  const selectionBoxRef = useRef<HTMLDivElement>(null);
  const selectionBoxStyles =
    isOpen && !error && !disabled
      ? `${styles.container} ${styles['container-active']}`
      : styles.container;

  return (
    <div
      aria-label="select-input-display"
      data-select-error={!disabled && error}
      style={{
        width: `${!label && !placeholder ? '3.2rem' : ''}`,
        height: `${!label && !placeholder ? '3.2rem' : ''}`
      }}
      className={`${selectionBoxStyles} ${noSelection ? styles['hover-selection'] : ''}`}
      ref={selectionBoxRef}
    >
      {icon && (
        <span
          className={`${
            !label && !placeholder ? styles['only-icon-container'] : styles['icon-container']
          }`}
        >
          {icon}
        </span>
      )}
      {!noSelection ? (
        <SelectInputContent
          placeholder={placeholder}
          className={styles['container-selection']}
          type={type}
          selection={selection}
          handleSelectionState={handleSelectionState}
        />
      ) : (
        <>{label ? <span className={styles['container-selection']}>{label}</span> : ''}</>
      )}
      {!hideArrows && (
        <SelectInputIcons
          selection={selection}
          handleSelectionState={handleSelectionState}
          isOpen={isOpen}
        />
      )}
    </div>
  );
};

export default SelectInputDisplay;
