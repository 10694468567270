/* eslint-disable */
import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  clearTable,
  getIssuingAgency,
  setTable,
  setTableName,
  getFleetQuickFilters
} from '@store/data';
import { setPagination } from '@store/features/pagination';
import { useModifyData } from '@pages/tables/hooks/useModifyData';
import { ETableName } from '@common/utils/enums';
import { useSearchFleetMutation } from '@api/services/fleet';
import { store } from '@store/index';
import _, { isArray } from 'lodash';
import { fleetHelperTypes } from '../layouts/web-portal/components/smart-filters/QuickFilters/helper';
import { openModal } from '@store/features/modal';
import { rowModalId } from '@common/services/format';
import queryString from 'query-string';

export const useSetTable = ({ name }: { name: ETableName }, quickFilters?: any) => {
  const dispatch = useAppDispatch();
  const missingItemsQuickFilter = useAppSelector(state => state.quickFilters.fleet.missingItems);

  const body = useModifyData(name);
  const [searchVehicles] = useSearchFleetMutation();

  const customerId = useAppSelector(state => state.customer.id);
  const table = useAppSelector(state => state.table);
  const isGeotab = store.getState().user?.sourceType
    ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  // const pagination = useAppSelector(state => state.pagination);
  // const { data, isLoading, isSuccess, isError, error, isFetching } = useGetFleetQuery(
  //   [customerId, pagination.trips.currentPage - 1, pagination.trips.showBy],
  //   {
  //     skip: !customerId,
  //     pollingInterval: 600000
  //   }
  // );

      const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: false });
      const isModalOpen = params.modal;
      const data = useAppSelector(state => state.table.data);
      if(isModalOpen && data && isArray(data)){
        const id =  data[0].id 
        dispatch(openModal(['data', rowModalId(ETableName.FLEET, id || '')]));
      }

  if (missingItemsQuickFilter.length > 0) {
    const missingItems: number[] = (missingItemsQuickFilter as string[]).map((label: string) => {
      const type = fleetHelperTypes.find(item => item.label === label);
      return type?.value ?? 0;
    });
    body.quickFilter = {
        ...(isGeotab ? {} : body.quickFilter),
        missingItems: missingItems
      }
  } else {
      body.quickFilter = {
      ...(isGeotab ? {} : body.quickFilter),
      missingItems: []
    };
  }
  const handleVehicles = useCallback(async () => {
    dispatch(clearTable());
    dispatch(setTableName(name));
    if (quickFilters) {
      body.quickFilter = {
        vehicleNames: quickFilters.vehicleNames || [],
        licensePlateNumbers: quickFilters.licensePlateNumbers || [],
        licensePlateStates: quickFilters.licensePlateStates || [],
        tagNumbers: quickFilters.tagNumbers || [],
        missingItems: quickFilters.missingAssetsAndTransponders || []
      };
    }
    if (
      customerId &&
      body.customerId &&
      name === ETableName.FLEET &&
      customerId === body.customerId
    ) {
      //   dispatch(setTable({ data, isLoading, isError, isSuccess, error, name, isFetching }));
      //   dispatch(
      //     setPagination({
      //       table: name,
      //       dataLength: totalElements || 0,
      //       totalPages: totalPages || 0
      //     })
      //   );
      searchVehicles({ ...body })
        .unwrap()
        .then(res => {
          dispatch(
            setTable({
              data: res.pageContent,
              isLoading: false,
              isError: false,
              isSuccess: true,
              error: undefined,
              isFetching: false,
              fetch: false
            })
          );
          dispatch(
            setPagination({
              table: ETableName.FLEET,
              dataLength: res.totalElements || 0,
              totalPages: res.totalPages || 0
            })
          );
          dispatch(getIssuingAgency()).catch(err => {
            console.log('Err in fetching issuing agencies ==> ', err);
          });
          dispatch(getFleetQuickFilters()).catch(err => {
            console.log('Err in fetching fleet quick filters ==> ', err);
          });
        })
        .catch(e => {
          console.log(e);
          dispatch(
            setTable({
              data: undefined,
              isLoading: false,
              isError: true,
              isSuccess: false,
              error: undefined,
              isFetching: false,
              fetch: false
            })
          );
        });
    } else {
      dispatch(
        setTable({
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          error: undefined,
          isFetching: false,
          fetch: false
        })
      );
    }
  }, [body, dispatch, searchVehicles, name, customerId]);

  useEffect(() => {
    handleVehicles().catch(e => console.log(e));
  }, [handleVehicles, table.fetch]);
};
