import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { SHOW_DELETE_MODAL_CONSTANT } from '@common/utils/constants';
import { IModalSlice, ModalTypes } from './types';

const initialState: IModalSlice = {
  id: undefined,
  activeModal: undefined,
  responseType: undefined,
  responseAction: '',
  responseMessage: ''
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: { payload: [ModalTypes, string | number] }) => {
      let modal = state;
      if (action.payload[0] === 'delete') {
        if (!Cookies.get(SHOW_DELETE_MODAL_CONSTANT)) {
          [modal.activeModal, modal.id] = action.payload;
        } else {
          modal = initialState;
        }
      } else {
        [modal.activeModal, modal.id] = action.payload;
      }
    },
    setApiResponse: (
      state,
      action: PayloadAction<{
        type: 'success' | 'error' | undefined;
        apiAction: string;
        message: string;
      }>
    ) => {
      const { type, apiAction, message } = action.payload;
      const modal = state;
      modal.responseType = type;
      modal.responseMessage = message;
      modal.responseAction = apiAction;
    },
    closeModal: () => {
      return initialState;
    }
  }
});

export const { openModal, closeModal, setApiResponse } = modalSlice.actions;

export default modalSlice.reducer;
