/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

export const baseURL = '';
export const controller = new AbortController();

export const retryDelay = (retryNumber = 0) => {
  const seconds = 2 ** retryNumber * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

export const baseRequestOptions: AxiosRequestConfig = {
  baseURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  },
  signal: controller.signal
};

export const axiosRetryFunc = (instance: AxiosInstance) => {
  return axiosRetry(instance, {
    retries: 2,
    retryDelay,
    // retry on Network Error & 5xx responses
    retryCondition: error => axiosRetry.isRetryableError(error)
  });
};

export const axiosErrorHandler = (err: AxiosError) => {
  if (err.response) {
    // ? client received an error response (5xx, 4xx)
    return err.response;
  }
  if (err.request) {
    // ? client never received a response, or request never left
    return err.request;
  }
  // ? anything else
  return err;
};
