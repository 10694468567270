/* eslint-disable @typescript-eslint/no-floating-promises */
import { UserDataInterface } from '@common/types/data';
import { useUpdateUserMutation } from '@api/services/users';
import { useHandleApiResponse, usePromiseToastNotify } from '@common/hooks';
import { closeModal } from '@store/features/modal';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { userData } from '@store/data';

export const useHandleSubmit = () => {
  const [updateUser] = useUpdateUserMutation();
  const customerId = useAppSelector(state => state.customer.id);
  const appRole = useAppSelector(state => state.auth.app_role);
  const toastify = usePromiseToastNotify();
  const dispatch = useAppDispatch();
  const { handleError } = useHandleApiResponse();

  const handleSubmit = (
    values: UserDataInterface,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    toastify(
      updateUser(values)
        .unwrap()
        .catch((error: FetchBaseQueryError) => {
          handleError(error, 'update', 'user');
        })
        .finally(() => {
          setSubmitting(false);
          dispatch(userData({ customerId, appRole }));
          dispatch(closeModal());
        }),
      'update',
      'user'
    );
  };

  return { handleSubmit };
};
