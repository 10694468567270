/* eslint-disable */
import { useAppSelector } from '@store/hooks';
import moment from 'moment-timezone';
import { Select, MenuItem } from '@mui/material';
import styles from './monthly-detail-header.module.scss';
import { IMonthlyDetailProps } from '../types';

const MonthlyDetailHeader = ({
  selectedMonth,
  setSelectedMonth,
  dateRanges
}: IMonthlyDetailProps) => {
  const companyName = useAppSelector(state => state.customer.companyName);
  let months: string[] = [];
  // Function to generate month options till current month
  const generateMonthOptions = () => {
    months = dateRanges.map(date => moment.utc(date).format('MMMM YYYY'));
    return months;
  };

  const handleMonthChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <header className={styles.container}>
      <section className={styles.title}>
        <span className={styles.customer}>{companyName}</span>
        <p>Showing {selectedMonth}</p>
      </section>
      <section className={styles.title}>
        {/* <select className={styles.month} value={selectedMonth} onChange={handleMonthChange}>
          {generateMonthOptions().map(month => (
            <option key={month} value={month}>
              {month.slice(0, -4)}
            </option>
          ))}
        </select> */}

        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          className={styles.selectBox}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Adjust the height as needed
                overflowY: 'auto'
              }
            }
          }}
        >
          {generateMonthOptions().map(month => (
            <MenuItem
              key={month}
              value={month}
              sx={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '1.3rem'
              }}
            >
              {moment(month).format("MMMM 'YY")}
            </MenuItem>
          ))}
        </Select>
      </section>
    </header>
  );
};

export default MonthlyDetailHeader;
