export function PlateState({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 8.8C8.39556 8.8 8.78224 8.6827 9.11114 8.46294C9.44004 8.24317 9.69638 7.93082 9.84776 7.56536C9.99913 7.19991 10.0387 6.79778 9.96157 6.40982C9.8844 6.02185 9.69392 5.66549 9.41421 5.38578C9.13451 5.10608 8.77814 4.9156 8.39018 4.83843C8.00222 4.76126 7.60009 4.80086 7.23463 4.95224C6.86918 5.10361 6.55682 5.35996 6.33706 5.68886C6.1173 6.01775 6 6.40443 6 6.8C6 7.33043 6.21071 7.83914 6.58579 8.21421C6.96086 8.58928 7.46957 8.8 8 8.8ZM7.29 15.71C7.38296 15.8037 7.49356 15.8781 7.61542 15.9289C7.73728 15.9797 7.86799 16.0058 8 16.0058C8.13201 16.0058 8.26272 15.9797 8.38458 15.9289C8.50644 15.8781 8.61704 15.8037 8.71 15.71L12.8 11.61C13.75 10.6605 14.397 9.45068 14.6593 8.13346C14.9216 6.81623 14.7873 5.4508 14.2735 4.20989C13.7597 2.96897 12.8894 1.90831 11.7727 1.16205C10.656 0.415803 9.34309 0.0174866 8 0.0174866C6.65691 0.0174866 5.344 0.415803 4.22731 1.16205C3.11062 1.90831 2.24033 2.96897 1.72652 4.20989C1.2127 5.4508 1.07845 6.81623 1.34073 8.13346C1.60301 9.45068 2.25005 10.6605 3.2 11.61L7.29 15.71ZM3.23 6.34C3.29833 5.62701 3.52502 4.93829 3.89352 4.32411C4.26203 3.70993 4.76305 3.1858 5.36 2.79C6.1442 2.27509 7.06186 2.00076 8 2.00076C8.93814 2.00076 9.8558 2.27509 10.64 2.79C11.233 3.18445 11.7311 3.7054 12.0987 4.31538C12.4663 4.92537 12.6942 5.60923 12.7659 6.31778C12.8376 7.02634 12.7514 7.74198 12.5135 8.41325C12.2756 9.08452 11.8919 9.69473 11.39 10.2L8 13.59L4.61 10.2C4.10752 9.69963 3.72293 9.09353 3.48421 8.4258C3.24548 7.75807 3.15864 7.04552 3.23 6.34ZM15 18H1C0.734784 18 0.48043 18.1054 0.292893 18.2929C0.105357 18.4804 0 18.7348 0 19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19C16 18.7348 15.8946 18.4804 15.7071 18.2929C15.5196 18.1054 15.2652 18 15 18Z" />
    </svg>
  );
}
