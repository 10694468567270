/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '@store/hooks';
import { EModule } from '@common/utils/enums';
import styles from './tablet-menu.module.scss';
import { navigationLinks } from '../utils/navigation-links';
import NavItem from '../NavItem';

const TabletMenu = ({ handleOpenTabMenu }: { handleOpenTabMenu: (value: boolean) => void }) => {
  const customer = useAppSelector(state => state.customer);
  const menuOverlayRef = useRef(null);
  const handleCloseModal = (e: { target: EventTarget }) => {
    if (menuOverlayRef.current === e.target) {
      handleOpenTabMenu(false);
    }
  };
  return (
    <motion.div
      className={styles.overlay}
      ref={menuOverlayRef}
      onClick={handleCloseModal}
      onKeyDown={handleCloseModal}
      role="button"
      tabIndex={0}
      initial={{ background: styles.backgroundHidden }}
      animate={{ background: styles.backgroundVisible }}
      exit={{ background: styles.backgroundHidden }}
      transition={{ duration: 0.4, delayChildren: 0.5 }}
    >
      <motion.div
        className={styles.container}
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ delay: 0.4 }}
      >
        {navigationLinks?.map(
          navItem =>
            ((navItem.title === EModule.DASHBOARD && customer.id) ||
              (navItem.title !== EModule.DASHBOARD && navItem.isPermitted(navItem.link))) && (
              <button
                className={styles.link}
                type="button"
                key={uuid()}
                onClick={() => handleOpenTabMenu(false)}
              >
                <NavItem
                  navItem={{
                    ...navItem,
                    title:
                      navItem.title === 'toll documents'
                        ? 'toll document management'
                        : navItem.title
                  }}
                />
              </button>
            )
        )}
      </motion.div>
    </motion.div>
  );
};

export default TabletMenu;
