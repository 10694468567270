/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import { FilledCancel, File } from '@assets/icons';
import Loader from '@common/ui/Loader';
import Upload from 'rc-upload';
import styles from './file-input.module.scss';
import { FileInputProps } from './types';
import { useFileUpload } from './hooks';

const FileInput: FC<FileInputProps> = ({
  className = '',
  label,
  field,
  format,
  customRequest,
  disabled
}) => {
  const { props, error, dragging, uploading, file, handleFile, handleDragging } = useFileUpload(
    field || '',
    format,
    customRequest
  );

  return (
    <Upload {...props} className={styles.upload} disabled={disabled}>
      <div
        role="button"
        tabIndex={0}
        onDragEnter={() => handleDragging(true)}
        onDragOver={() => handleDragging(true)}
        onDragLeave={() => handleDragging(false)}
        onDragExit={() => handleDragging(false)}
        className={`${styles.container} ${dragging ? styles.dragging : ''} ${
          error[0] ? styles['container-error'] : ''
        } ${className}`}
      >
        {label && <label htmlFor="cl-file-input">{label}</label>}
        {!disabled ? (
          <div id="cl-file-input" className={styles.input}>
            {!file && !dragging && (
              <p className={styles.placeholder}>
                Drag and drop or{' '}
                <button type="button" className={styles.browse}>
                  browse files
                </button>
              </p>
            )}
            {dragging && (
              <p className={styles.placeholder}>
                <span className={styles.file}>
                  <File />
                </span>{' '}
                Drop file here
              </p>
            )}
            {file && !dragging && <p className={styles.filename}>{file.name.toLowerCase()}</p>}
            {uploading && <Loader className={styles.loader} />}
            {file && !dragging && !uploading && (
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  handleFile(null);
                }}
                className={styles.clear}
              >
                <FilledCancel />
              </button>
            )}
          </div>
        ) : (
          <div className={styles['disabled-input']}>
            <p className={styles.placeholder}>
              <span className={styles.file}>
                <File />
              </span>{' '}
              Drop file here
            </p>
          </div>
        )}
        {error[0] && (
          <p className={styles['error-description']}>
            {error[1] ? error[1] : 'error occurred while uploading, please try again.'}
          </p>
        )}
      </div>
    </Upload>
  );
};

export default FileInput;
