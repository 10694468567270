/* eslint-disable */

import { WEB_BE_URL } from '@api/config';
import { usePromiseToastNotify } from '@common/hooks';
import { accessTokenCookie, emailCookie } from '@common/services/auth';
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import api from '../../../api/services/common';

interface IAddress {
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export interface IProfile {
  customer_id?: string;
  federal_id?: string;
  company_register_address?: IAddress;
  company_billing_address?: IAddress;
  company_shipping_address?: IAddress;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  company_name?: string;
  admin_name?: string;
  admin_phone?: string;
  admin_email?: string;
  geotab_db?: string;
}

interface IState {
  profile: IProfile | null;
  loading: boolean;
}

const initialState: IState = {
  profile: null,
  loading: false
};

const toastify = usePromiseToastNotify();

const parseJsonString = (jsonString?: string): IAddress | undefined => {
    if (!jsonString) return undefined;
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        console.error("Failed to parse JSON string:", jsonString);
        return undefined;
    }
};

const parseAddresses = (profile: IProfile) => {
  if (typeof profile.company_register_address === 'string') {
    profile.company_register_address = parseJsonString(profile.company_register_address);
  }
  if (typeof profile.company_billing_address === 'string') {
    profile.company_billing_address = parseJsonString(profile.company_billing_address);
  }
  if (typeof profile.company_shipping_address === 'string') {
    profile.company_shipping_address = parseJsonString(profile.company_shipping_address);
  }
  return profile;
};

export const profileData = createAsyncThunk('getProfile', async () => {
  const res = (await api.get('/profile')) as { isSuccess: true; data: IProfile };
      const profile = res.data;
   if (res.isSuccess) {
    return parseAddresses(profile);
  } else {
    throw new Error('Failed to fetch profile');
  }
});

export const createProfile = createAsyncThunk('createProfile', async (data: IProfile, thunkAPI) => {
  const res = await api.post('/profile', data);
  return res;
});

export const updateProfile = createAsyncThunk('updateProfile', async (data: any, thunkAPI) => {
  const res = await api.put('/profile', data);
      const profile = res.data;
   if (res.isSuccess) {
    return parseAddresses(profile);
  } else {
    throw new Error('Failed to fetch profile');
  }
});

export const generateInvoice = createAsyncThunk('generateInvoice', async (data: any, thunkAPI) => {
  toastify(
    async () => {
      try {
        const res = await axios.post(`${WEB_BE_URL}${'/quickbooks/invoice/generateAll'}`, data, {
          headers: {
            authorization: Cookies.get(accessTokenCookie)
              ? //@ts-ignore
                Cookies.get(accessTokenCookie).trim()
              : '',
            //@ts-ignore
            email: Cookies.get(emailCookie) ? Cookies.get(emailCookie).trim() : '',
            'Content-Type': 'application/json'
          }
        });
        if (res.status !== 200) {
          throw res;
        } else {
          return res.data;
        }
      } catch (err) {
        // const toast = toastError(err);
        console.log('err >>>>:', err);
      }
    },
    'create',
    'invoice'
  );
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(profileData.pending, (state, action) => {
      state.loading = true;
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(profileData.fulfilled, (state, action) => {
      // Add user to the state array
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(createProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    // builder.addCase(generateInvoice.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(generateInvoice.fulfilled, (state, action) => {
    //   state.profile = action.payload;
    //   state.loading = false;
    // });
  }
});

// Action creators are generated for each case reducer function
export const {} = profileSlice.actions;

export default profileSlice.reducer;
