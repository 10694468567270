import Modal from '@common/ui/Modal';
import { CreateVehicle, CreateVehicleFormik } from '../../../forms/Create';
import { FormSubmitButton } from '../../../buttons';

const User = () => {
  return (
    <CreateVehicleFormik>
      <Modal
        activeTitle="singleHighlight"
        navigation={['New Vehicle']}
        customPrimaryButton={<FormSubmitButton label="Create Vehicle" type="create" />}
        cancelButton={false}
      >
        <CreateVehicle />
      </Modal>
    </CreateVehicleFormik>
  );
};

export default User;
