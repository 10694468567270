export function Plus({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 1.99998C10.0222 1.99998 8.08879 2.58647 6.4443 3.68529C4.79981 4.7841 3.51809 6.34589 2.76121 8.17315C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38641 21.2388 8.17315C20.7363 6.95989 19.9997 5.8575 19.0711 4.92892C18.1425 4.00033 17.0401 3.26374 15.8268 2.76119C14.6136 2.25864 13.3132 1.99998 12 1.99998ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6517C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5996 3.84504 11.9911 4.15372 10.4393C4.4624 8.88741 5.22433 7.46195 6.34315 6.34313C7.46197 5.22431 8.88743 4.46238 10.4393 4.1537C11.9911 3.84502 13.5997 4.00345 15.0615 4.60895C16.5233 5.21445 17.7727 6.23983 18.6518 7.55542C19.5308 8.87102 20 10.4177 20 12C20 14.1217 19.1572 16.1565 17.6569 17.6568C16.1566 19.1571 14.1217 20 12 20ZM16 11H13V7.99998C13 7.73477 12.8946 7.48041 12.7071 7.29288C12.5196 7.10534 12.2652 6.99998 12 6.99998C11.7348 6.99998 11.4804 7.10534 11.2929 7.29288C11.1054 7.48041 11 7.73477 11 7.99998V11H8C7.73479 11 7.48043 11.1053 7.2929 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.2929 12.7071C7.48043 12.8946 7.73479 13 8 13H11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V13H16C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1053 16.2652 11 16 11Z" />
    </svg>
  );
}
