import { GMap, Truck } from '@assets/icons';
import { useCrossFiltering } from '@common/hooks';
import { UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import Button from '@common/ui/Button';

const CrossFilter = ({
  tableName,
  entryData
}: {
  tableName: ETableName;
  entryData: UnionDataInterface[];
}) => {
  const { crossFilter } = useCrossFiltering();
  const fleetFilter = entryData
    ? entryData.map(objItem => ('vehicleName' in objItem ? objItem.vehicleName : ''))[0]
    : '';
  const tripsFilter = entryData
    ? entryData.map(objItem => ('name' in objItem ? objItem.name : ''))[0]
    : '';

  return (
    <>
      {tableName && [ETableName.FLEET].includes(tableName) && (
        <Button
          appearance="button"
          label="Show Trips"
          icon={<GMap />}
          variant="tertiary"
          size="xs"
          onClick={() => crossFilter(tripsFilter, ETableName.TRIPS)}
        />
      )}
      {tableName && [ETableName.TRIPS].includes(tableName) && (
        <Button
          appearance="button"
          label="Show Vehicle"
          onClick={() => crossFilter(fleetFilter, ETableName.FLEET)}
          icon={<Truck />}
          variant="tertiary"
          size="xs"
        />
      )}
    </>
  );
};

export default CrossFilter;
