import { FC } from 'react';
import Modal from '@common/ui/Modal';
import { FilledCancel, ApproveCircle } from '@assets/icons';
import Button from '@common/ui/Button';
import { useAppDispatch } from '@store/hooks';
import { closeModal } from '@store/features/modal';
import { ApiResponseProps } from './types';
import styles from './api-response.module.scss';

const ApiResponse: FC<ApiResponseProps> = ({ type, message, action }) => {
  const dispatch = useAppDispatch();
  if (!type) return null;
  return (
    <Modal className={styles.modal} hasFooter={false} hasHeader={false}>
      <section className={`${styles.container} ${type ? styles[type] : ''}`}>
        <span className={styles.icon}>
          {type === 'success' ? <ApproveCircle /> : <FilledCancel />}
        </span>
        <h3>{action}</h3>
        {typeof message === 'string' && <p>{message}</p>}
        {type === 'success' ? (
          <Button
            onClick={() => dispatch(closeModal())}
            appearance="button"
            label="Continue"
            size="xs"
          />
        ) : (
          <Button
            onClick={() => dispatch(closeModal())}
            appearance="button"
            variant="danger-primary"
            label="Close"
            size="xs"
          />
        )}
      </section>
    </Modal>
  );
};

export default ApiResponse;
