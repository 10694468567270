/* eslint-disable no-unneeded-ternary */

import { Children, FC } from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Help from '@components/help/Help';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { ArrowForward } from '@assets/icons';
import { CustomCardProps } from './types';
import style from './material-card.module.scss';

const MaterialCard: FC<CustomCardProps> = ({
  title,
  subTitle,
  cardHeight,
  cardWidth,
  helpLocation,
  cardVariant = 'parent',
  showHeaderArrow = false,
  maxCols = 4,
  hasBorder = false,
  contentColSpacing = 2,
  noGrid = false,
  className = '',
  children,
  directTitle,
  overflow
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  return (
    <Card
      className={`${style.container} ${className} ${hasBorder ? style['top-border'] : ''} ${
        cardVariant === 'parent' ? style['border-margin'] : ''
      }`}
      sx={{
        width: cardWidth ? `${cardWidth}` : '100%',
        height: cardHeight ? `${cardHeight}` : '65rem',
        // eslint-disable-next-line no-nested-ternary
        overflow: overflow !== undefined ? overflow : isXs || isSm ? 'auto' : 'hidden'
      }}
      elevation={5}
    >
      <Typography
        className={style.header}
        variant={cardVariant === 'parent' ? 'h2' : 'h4'}
        gutterBottom
        fontWeight={700}
      >
        {_.startCase(title)}
        {directTitle ? directTitle : ''}
        {showHeaderArrow && (
          <span className={style['fwd-arrow']}>
            <ArrowForward size={15} />
          </span>
        )}

        {helpLocation && <Help className={style.help} location={helpLocation} />}
      </Typography>
      {subTitle && <div className={style['sub-header']}>{subTitle}</div>}
      <div>
        {!noGrid ? (
          <Grid container spacing={contentColSpacing}>
            {Children.map(children, child => (
              <Grid item xs={12} sm={12} md={maxCols} lg={maxCols} key={uuid()}>
                {child}
              </Grid>
            ))}
          </Grid>
        ) : (
          <>{children}</>
        )}
      </div>
    </Card>
  );
};

export default MaterialCard;
