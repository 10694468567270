import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaProps {
  title: string;
  keywords?: string;
  description?: string;
}

const Meta: FC<MetaProps> = ({ title, keywords = '', description }) => {
  const joinedKeywords = `commercelogic, ${keywords}`;
  return (
    <Helmet>
      <title>commercelogic - {title}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=7" />
      <meta name="description" content={description} />
      {/* <meta httpEquiv="Content-Security-Policy" content="frame-ancestors https://my.geotab.com/" /> */}
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="keywords" content={joinedKeywords} />
      <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  );
};

export default Meta;
