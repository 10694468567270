import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import { clearTable, setTable } from '@store/data';
import { setPagination } from '@store/features/pagination';
import { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { getSearchResults } from '@common/services/search';
import { sortData } from '@common/services/sort';
import { UnionDataInterface } from '@common/types/data';
import { useGetCustomersQuery } from '@api/services/customers';

export const useSetTable = ({
  name,
  searchableColumns
}: {
  name: ETableName;
  searchableColumns: string[];
}) => {
  const pagination = useAppSelector(state => state.pagination[name]);
  const { data, isLoading, isSuccess, isError, error, isFetching } = useGetCustomersQuery(
    [pagination.currentPage - 1, pagination.showBy],
    {
      pollingInterval: 600000
    }
  );
  const headings = useAppSelector(state => state.headings[name]);
  const search = useAppSelector(state => state.search[name]);
  const filters = useAppSelector(state => state.filters[name]);
  const sort = useAppSelector(state => state.sort[name]);
  const dispatch = useAppDispatch();

  const handleSetTable = useCallback(() => {
    const activeHeadings = [...headings]
      ?.filter(heading => heading.active)
      .filter(heading => searchableColumns.includes(_.lowerCase(heading.heading)))
      .map(heading => _.camelCase(heading.heading));

    const activeFiltering = filters?.active;
    const filteredValues =
      filters?.active && filters?.by && data?.pageContent
        ? _.uniqBy(
            Object.entries(filters.by || {})
              .map(keyValue => {
                const filtered = data.pageContent.filter(
                  row =>
                    _.kebabCase(`${row[keyValue[0] as keyof UnionDataInterface]}`) ===
                    _.kebabCase(`${keyValue[1] as string | number}`)
                );
                return filtered;
              })
              .reduce((prev, next) => prev.concat(next)),
            'id'
          )
        : data?.pageContent;

    const checkFiltering = activeFiltering ? filteredValues : data?.pageContent;

    const searchValues = getSearchResults(
      checkFiltering || [],
      search?.by as string | number,
      activeHeadings
    ) as UnionDataInterface[];
    const values = search?.active ? searchValues : checkFiltering;

    const sortedValues = sortData(
      values || [],
      sort?.by as string,
      sort?.order as 'asc' | 'desc'
    ) as UnionDataInterface[];

    // ? get pagination data
    // const pageData = sortedValues?.slice(pagination.firstRowIndex - 1, pagination?.lastRowIndex);
    const loading = pagination?.pagesArray.length === 0 && data !== undefined;
    const result = { tableData: sortedValues, sortedValues, loading };

    try {
      dispatch(clearTable());
      dispatch(
        setTable({
          data: result.sortedValues,
          isLoading,
          isError,
          isSuccess,
          error,
          name,
          isFetching
        })
      );
      dispatch(
        setPagination({
          table: name,
          dataLength: data?.totalElements || 0,
          totalPages: data?.totalPages || 0
        })
      );
    } catch {
      dispatch(
        setTable({
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          error: undefined,
          isFetching: false
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    name,
    data,
    filters?.active,
    filters?.by,
    headings,
    pagination?.firstRowIndex,
    pagination?.lastRowIndex,
    pagination?.pagesArray.length,
    pagination?.showBy,
    // searchableColumns,
    search?.by,
    search?.active,
    sort?.by,
    sort?.order
  ]);

  useEffect(() => {
    handleSetTable();
  }, [handleSetTable]);
};
