import dayjs from 'dayjs';

export const extractDatesInRange = (startDate: Date, endDate?: Date) => {
  const date = new Date(startDate.getTime());

  const dates = [];
  if (endDate) {
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  } else {
    dates.push(new Date(date));
  }

  const formattedDates = dates.map(day => dayjs(day).format('MM/DD/YYYY'));

  return { dates, formattedDates };
};
