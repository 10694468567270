import { FC, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ChevronUp, ChevronRight } from '@assets/icons';
import Loader from '@common/ui/Loader';
import styles from './material-accordion.module.scss';
import { CustomAccordionProps } from './types';

const MaterialAccordion: FC<CustomAccordionProps> = ({
  title,
  summary,
  content,
  onClick,
  width = '100%',
  height = '5rem',
  iconPosition = 'end',
  isOpen: propIsOpen = false,
  isContentLoading = false,
  customIcon,
  showTopBorder = true,
  showHoverStyle = false,
  showBorderRadius = false,
  showMarginBottom = false,
  isHeaderThemed = false,
  children,
  ...otherProps
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(propIsOpen);
  const handleChange = (event: React.ChangeEvent<unknown>, expanded: boolean) => {
    setIsAccordionOpen(!isAccordionOpen);
    if (onClick) {
      onClick(event, expanded);
    }
  };
  return (
    <Accordion
      sx={{
        width,
        minHeight: height,
        '&::before': {
          height: 0
        }
      }}
      style={{
        ...(showTopBorder ? { borderTop: '0.8px solid #e2e3e5' } : {}),
        ...(showBorderRadius ? { borderRadius: '8px' } : {}),
        ...(showMarginBottom ? { marginBottom: '10px' } : {})
      }}
      onChange={handleChange}
      expanded={propIsOpen}
      className={`${styles.container} ${showHoverStyle ? styles.hover : ''}`}
      {...otherProps}
    >
      <AccordionSummary
        className={`${styles['summary-wrapper']} ${
          isHeaderThemed && propIsOpen ? styles['themed-header'] : ''
        } ${propIsOpen && showBorderRadius ? styles['curved-header'] : ''}`}
        expandIcon={
          customIcon ? (
            <>
              {propIsOpen ? (
                <div className={styles['opened-custom-icon']}>
                  <ChevronUp />
                </div>
              ) : (
                <div className={styles['closed-custom-icon']}>{customIcon}</div>
              )}
            </>
          ) : (
            <>
              {propIsOpen ? (
                <div className={styles['up-icon']}>
                  <ChevronUp />
                </div>
              ) : (
                <div className={styles['right-icon']}>
                  <ChevronRight />
                </div>
              )}
            </>
          )
        }
        // Set the position of icon based on prop
        sx={{
          ...(iconPosition === 'start' && {
            flexDirection: 'row-reverse' // Reverse icon position to start
          })
        }}
      >
        <div className={styles.summary}>
          <Typography className={styles.title}>{title}</Typography>
          <Typography className={styles.summary}>{summary}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {/* Extract content from children */}
        {isContentLoading ? <Loader /> : <>{children || <Typography>{content}</Typography>}</>}
      </AccordionDetails>
    </Accordion>
  );
};

export default MaterialAccordion;
