/* eslint-disable */

import { createSelector } from '@reduxjs/toolkit';

const selectSlice = (state: any) => state.fleetTabs;

export const loading = createSelector([selectSlice], state => state.loading);

export const history = createSelector([selectSlice], state => state.history);

export const trips = createSelector([selectSlice], state => state.trips);
