import Modal from '@common/ui/Modal/Modal';
import { FC } from 'react';
import { tableNameByLength } from '@common/services/format';
import Button from '@common/ui/Button';
import { useDataDelete } from '@common/hooks';
import { ConfirmDeleteModalProps } from './types';
import styles from './confirm-delete-modal.module.scss';
import { useDontShowAgain } from './hooks';

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({ tableName, data }) => {
  const { isChecked, handleCheckbox } = useDontShowAgain();
  const { handleDelete, deleting } = useDataDelete(data, data[0].id, true);

  return (
    <Modal
      className={styles.modal}
      headerClassName={styles['modal-header']}
      footerClassName={styles['modal-footer']}
      title={`Delete ${tableNameByLength(data.length, tableName)}`}
      withCheckbox
      checkboxLabel="Don't ask again"
      isChecked={isChecked}
      handleCheckbox={handleCheckbox}
      customPrimaryButton={
        <Button
          appearance="button"
          label={deleting ? 'Deleting...' : 'Delete'}
          variant="danger-primary"
          size="xs"
          onClick={handleDelete}
          disabled={deleting}
        />
      }
    >
      <article className={styles.container}>
        <p>
          Are you sure you want to delete the selected {tableNameByLength(data.length, tableName)}?
          Recovery will be impossible.
        </p>
      </article>
    </Modal>
  );
};

export default ConfirmDeleteModal;
