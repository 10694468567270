/* eslint-disable import/no-cycle */
import { useAppDispatch } from '@store/hooks';
import { closeModal } from '@store/features/modal';
import { clearPagination } from '@store/features/pagination';
import { clearAllSelectionIDs } from '@store/features/selection';
import { clearAllHeadings } from '@store/features/headings';
import { clearTableData, clearUser, clearCustomer } from '@store/data';
import { ETableName } from '@common/utils/enums';

export const useClearStore = () => {
  const dispatch = useAppDispatch();

  const clearFeatures = (reserveTable?: ETableName) => {
    dispatch(closeModal());
    dispatch(clearAllSelectionIDs(reserveTable));
    dispatch(clearPagination(reserveTable));
  };

  const clearData = () => {
    dispatch(clearAllHeadings());
    dispatch(clearCustomer());
    dispatch(clearUser());
    dispatch(clearTableData());
  };

  return { clearFeatures, clearData };
};
