/* eslint @typescript-eslint/restrict-template-expressions: "off" */
import { useEffect, useState } from 'react';
import { ICalculatedToll } from '@api/types';
import api from '@api/services/common';
import { IBilledToll } from '@api/types/trip-response';
import Table from './Table';

const TripTransaction = ({ tripId }: { tripId: string }) => {
  const [calculatedData, setCalculatedData] = useState<ICalculatedToll[]>([]);
  const [billData, setBillData] = useState<IBilledToll[]>([]);
  const [calculatedLoading, setCalculatedLoading] = useState(true);
  const [billLoading, setBillLoading] = useState(true);

  useEffect(() => {
    setBillLoading(true);
    setCalculatedLoading(true);
    api
      .JavaGet(`/calc-tolls/v1?trip-id=${tripId}`)
      .then((res: ICalculatedToll[]) => {
        setCalculatedData(res);
        setCalculatedLoading(false);
      })
      .catch(() => {
        setCalculatedLoading(false);
      });
    api
      .get(`/trips/billed-tolls?trip_id=${tripId}`)
      .then((res: { data: IBilledToll[]; isSuccess: boolean }) => {
        setBillData(res.data);
        setBillLoading(false);
      })
      .catch(() => {
        setBillLoading(false);
      });
  }, [tripId]);

  return (
    <>
      <Table title="tollogic&#8482; Tolls" isLoading={calculatedLoading} data={calculatedData} />
      <Table title="Invoiced Tolls" isLoading={billLoading} data={billData} />
    </>
  );
};

export default TripTransaction;
