import { FC, useState } from 'react';
import { CaretDown } from '@assets/icons';
import Dropdown from '@common/ui/Dropdown';
import { SelectionBoxProps } from './types';
import styles from './selection-box.module.scss';

const SelectionBox: FC<SelectionBoxProps> = ({
  values,
  selected = '',
  handleSelection,
  placeholder
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => setDropdownIsOpen(value);
  const stringValues = (values as Array<number | string>).map((value: number | string) =>
    typeof value === 'string' ? value : value.toString()
  );
  return (
    <section className={styles.container}>
      <Dropdown
        isOpen={dropdownIsOpen}
        values={stringValues}
        handleOpenState={handleDropdownOpenState}
        handleSelectionState={handleSelection}
        type="select"
        selection={selected.toString()}
        className={styles.dropdown}
        position="bottom"
        offset={[5, 0]}
      >
        <span className={styles.display}>
          <span className={styles.selected}>{selected || placeholder}</span>
          <span className={styles.arrow}>
            <CaretDown />
          </span>
        </span>
      </Dropdown>
    </section>
  );
};

export default SelectionBox;
