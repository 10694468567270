import { Formik } from 'formik';
import { initialValues } from './utils/initialValues';
import { validationSchema } from './utils/validationSchema';
import { useHandleSubmit } from './hooks/useHandleSubmit';

export const UploadTransactionFormik = ({ children }: ReactChildren) => {
  const { handleSubmit } = useHandleSubmit();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
    >
      {children}
    </Formik>
  );
};
