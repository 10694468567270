import Meta from '@components/meta';
import QuickSight from './components/QuickSight';

const QuicksightMap = () => {
  const metaOptions = {
    title: 'Dashboard Map',
    keywords: 'Dashboard, Map'
  };
  return (
    <>
      <Meta {...metaOptions} />
      <QuickSight />
    </>
  );
};

export default QuicksightMap;
