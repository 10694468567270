import { EModule, ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AddNewButton, ClearPageFiltersButton, GoBackButton } from './buttons';
import { useCanShowButtons } from './hooks';
import styles from './page-header.module.scss';

const PageHeader = ({ title, hideButtons }: { title: string; hideButtons?: boolean }) => {
  const table = useAppSelector(state => state.table);
  const tableName = table.name as ETableName;
  const pagination = useAppSelector(state => state.pagination[tableName]);
  const companyName = useAppSelector(state => state.customer.companyName);
  const showCompanyName =
    companyName &&
    (tableName !== ETableName.CUSTOMERS || title === 'dashboard' || title === 'Dashboard (Beta)');
  const location = useLocation();
  const params = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
  const canShowButtons = useCanShowButtons(crossFilterSource, tableName, title);
  const backButtonDestination = () => {
    if (crossFilterSource) {
      return crossFilterSource || undefined;
    }
    return undefined;
  };
  const source = crossFilterSource as string;
  const subtitle = crossFilterSource
    ? `${pagination?.dataLength || 0} ${pagination?.dataLength === 1 ? 'entry' : 'entries'} found`
    : undefined;

  if (title !== EModule.DASHBOARD) {
    return (
      <header
        className={
          !crossFilterSource ? styles.container : `${styles.container} ${styles['active-filter']}`
        }
      >
        <section className={styles.title}>
          {crossFilterSource && (
            <GoBackButton
              destination={backButtonDestination()}
              showDestination={!!crossFilterSource}
            />
          )}

          <h3 className={styles.name}>{title}</h3>
          {showCompanyName && <span className={styles.customer}>{companyName}</span>}
          {subtitle && (
            <span className={source ? styles.entries : styles.subtitle}>{subtitle}</span>
          )}
        </section>
        {!canShowButtons && !hideButtons && (
          <div className={styles.buttons}>
            {crossFilterSource && <ClearPageFiltersButton />}
            {tableName !== ETableName.USERS && !crossFilterSource && <AddNewButton />}
          </div>
        )}
      </header>
    );
  }

  return <> </>;
};

export default PageHeader;
