import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from '@store/.';
import { FC } from 'react';

const AppProvider: FC<ReactChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      <HelmetProvider>{children}</HelmetProvider>
    </Provider>
  );
};

export default AppProvider;
