export const SignalHigh = ({ className }: ClassName) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.773438" y="17.4414" width="3.87097" height="5.51724" rx="1" fill="#85DD9B" />
      <rect x="5.41992" y="13.5" width="3.87097" height="9.45813" rx="1" fill="#85DD9B" />
      <rect x="10.0645" y="9.5625" width="3.87097" height="13.399" rx="1" fill="#85DD9B" />
      <rect x="14.709" y="5.62109" width="3.87097" height="17.3399" rx="1" fill="#85DD9B" />
      <rect x="19.3555" y="1.67969" width="3.87097" height="21.2808" rx="1" fill="#85DD9B" />
    </svg>
  );
};
