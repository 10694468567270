import { useEffect } from 'react';
import Pagination from '@common/ui/Pagination';
import ShowBy from '@common/ui/ShowBy';
import { standardDropdownMotionVariants } from '@common/utils/motion-variants';
import { useTablePaginate } from './hooks';
import styles from './table-pagination.module.scss';

const TablePagination = () => {
  const {
    currentPage,
    handlePageState,
    totalPages,
    showBy,
    showByValues,
    handleShowByState,
    handleShowByOptionState,
    dataLength,
    pagesArray
  } = useTablePaginate();

  // console.log(
  //   'currentPage',
  //   currentPage,
  //   'handlePageState',
  //   handlePageState,
  //   'totalPages',
  //   totalPages,
  //   'showBy',
  //   showBy,
  //   'showByValues',
  //   showByValues,
  //   'handleShowByState',
  //   handleShowByState,
  //   'dataLength',
  //   dataLength,
  //   'pagesArray',
  //   pagesArray
  // );

  useEffect(() => {
    if (showBy && showByValues.length) {
      const maxShowByOption = showByValues[showByValues.length - 1];
      if (showBy > maxShowByOption) {
        handleShowByOptionState(maxShowByOption);
      } else {
        handleShowByOptionState(showBy);
      }
    }
  }, [showBy, showByValues, handleShowByOptionState]);

  return (
    <section className={styles.container}>
      <Pagination
        page={currentPage}
        handlePageState={handlePageState}
        totalPages={totalPages}
        pagesArray={pagesArray}
        dataLength={dataLength}
      />
      <ShowBy
        values={showByValues.map(value => `${value}`)}
        label="Show by"
        selection={showBy}
        handleSelectionChange={handleShowByState}
        disabled={showByValues.length === 0 || showByValues.length === 1}
        motionVariants={standardDropdownMotionVariants}
      />
    </section>
  );
};

export default TablePagination;
