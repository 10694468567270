import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { useState } from 'react';
import { Filter } from '@assets/icons';
import Button from '@common/ui/Button';
import { setCurrentPage } from '@store/features/pagination';
import {
  setFleetTagNumbers,
  setFleetVehicleLicensePlateNumbers,
  setFleetVehicleLicensePlateStates,
  setFleetVehicleNames,
  setFleetmissingAssetsAndTransponders,
  setTransactionsType,
  setTripAmount,
  setTripIds,
  // setTripAmount,
  setTripVehicleLicensePlateNumbers,
  setTripVehicleLicensePlateStates,
  setTripVehicleNames
} from '@store/features/quick-filters';
import FilterForm from './FilterForm/FilterForm';
import FilterTags from './FilterTags/FilterTags';
import styles from './table-filter.module.scss';
import QuickFilters from './QuickFilters/QuickFilters';

const TableFilter = () => {
  const table = useAppSelector(state => state.table);
  const [isOpen, setIsOpen] = useState(false);
  const handleIsFiltersOpenState = (value: boolean) => {
    setIsOpen(value);
  };

  const user = useAppSelector(state => state.user);
  const customerId = useAppSelector(state => state.customer.id);
  const dispatch = useAppDispatch();
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  const handleQuickFilters = (
    filterName: string,
    data?: string[] | number[],
    range?: { min: number; max: number }
  ) => {
    if (table.name && (data || range)) {
      dispatch(
        setCurrentPage({
          table: table.name,
          currentPage: 1
        })
      );
      if (table.name === ETableName.FLEET && data) {
        if (filterName === 'vehicleNames') {
          dispatch(setFleetVehicleNames(data as string[]));
        } else if (filterName === 'licensePlateNumbers') {
          dispatch(setFleetVehicleLicensePlateNumbers(data as string[]));
        } else if (filterName === 'licensePlateStates') {
          dispatch(setFleetVehicleLicensePlateStates(data as string[]));
        } else if (filterName === 'tagNumbers') {
          dispatch(setFleetTagNumbers(data as string[]));
        } else if (filterName === 'MissingAssetsAndTransponders') {
          dispatch(setFleetmissingAssetsAndTransponders(data as string[]));
        }
      }
      if (table.name === ETableName.TRIPS) {
        if (filterName === 'tripIds' && data) {
          dispatch(setTripIds(data as number[]));
        } else if (filterName === 'vehicleNames' && data) {
          dispatch(setTripVehicleNames(data as string[]));
        } else if (filterName === 'licensePlateNumbers' && data) {
          dispatch(setTripVehicleLicensePlateNumbers(data as string[]));
        } else if (filterName === 'licensePlateStates' && data) {
          dispatch(setTripVehicleLicensePlateStates(data as string[]));
        } else if (filterName === 'amount' && range) {
          const customRange = {
            from: range.min,
            to: range.max
          };
          dispatch(setTripAmount(customRange));
        }
      }
      if (table.name === ETableName.TRANSACTIONS) {
        if (filterName === 'transactionsType' && data) {
          dispatch(setTransactionsType(data as string[]));
        }
      }
    }
  };

  return (
    <div className={styles.container} style={{ marginRight: '2rem !important' }}>
      {(customerId || table.name === ETableName.CUSTOMERS) && (
        <div className={styles['filter-wrapper']}>
          {/* {!isGeotab && table.name === ETableName.TRIPS && <ExportDropdown />} */}
          <Button
            appearance="button"
            variant="tertiary"
            disabled={table.isLoading}
            icon={<Filter />}
            label="Filters"
            size="s"
            onClick={() => {
              handleIsFiltersOpenState(!isOpen);
            }}
            className="ignore-onclickoutside"
          />
          {isOpen && (
            <FilterForm
              tableType={table.name as ETableName}
              className={styles['filters-dropdown']}
              handleOpenState={handleIsFiltersOpenState}
            />
          )}
          {!isGeotab && <QuickFilters handleQuickFilterChange={handleQuickFilters} />}
          {/* <SelectInput
          className={styles.input}
          label="Name"
          formField="vehicleName"
          icon={<TollID />}
          includeSearch
          values={[1, 2, 3]}
          // type="tags"
          placeholder="Select Vehicle Name"
        /> */}
        </div>
      )}
      {isGeotab || <FilterTags />}
    </div>
  );
};

export default TableFilter;
