export interface ICreateUserType {
  name: string;
  email: string;
  app_role: string;
  password: string;
}

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  app_role: '',
  password: ''
};
