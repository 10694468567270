/* eslint-disable */

import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { PageHeader } from '@components/layouts/headers';
import Meta from '@components/meta';
import styles from './additionalServices.module.scss';
import Card from './components/Card/Card';
import { cardList } from './utils/cardList';
import { CardProps } from './types/card';

const AdditionalServices = () => {
  const metaOptions = {
    title: 'Additional Services'
  };

  const [checkedCardList, setCheckedCardList] = useState<CardProps[] | null>(cardList);


  return (
    <>
      <Meta {...metaOptions} />
      {/* <Help location="additional_services" /> */}
      <PageHeader title="Additional services" hideButtons />
      <div className={styles.container}>
        {checkedCardList?.map(card => <Card {...card} key={uuid()} />)}
      </div>
    </>
  );
};

export default AdditionalServices;
