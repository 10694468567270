import { FC } from 'react';
import { CardProps } from '@pages/additionalServices/types/card';
import Button from '@common/ui/Button';
import styles from './card.module.scss';

const Card: FC<CardProps> = ({
  title,
  description,
  logoSrc,
  logoAlt,
  withButton = true,
  buttonLabel,
  ...props
}) => {
  return (
    <div className={styles.comingSoon}>
      <div className={styles.card}>
        <picture>
          <img src={logoSrc} alt={logoAlt || title} />
        </picture>
        <div className={styles.content}>
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>
        {withButton && (
          <Button
            size="s"
            appearance="button"
            link={'buttonLink' in props ? props.buttonLink : ''}
            label={(buttonLabel as string) || `Add ${title}`}
          />
        )}
        {!withButton && (
          <Button
            size="s"
            appearance="button"
            disabled
            label={(buttonLabel as string) || 'Coming Soon'}
          />
        )}
      </div>
    </div>
  );
};

export default Card;
