/*eslint-disable*/
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useCallback, useEffect } from 'react';
import { alertApiInitiate, setAlertData } from '@store/data/fleetTabs';
import api from '@api/services/common';
import { setPagination } from '@store/features/pagination';
import { ETableName } from '@common/utils/enums';
import dayjs from 'dayjs';
import { formatAlertDate } from '@common/services/format/formatTimeZone';

export const useAlert = ({ vehicleId }: { vehicleId?: string }) => {
  const customerId = useAppSelector(state => state.customer.id);
  const pagination = useAppSelector(state => state.pagination.alerts);
  const filters = useAppSelector(state => state.fleetTabs.alertFilters);
  const reloadAlerts = useAppSelector(state => state.fleetTabs.reloadAlerts);

  const dispatch = useAppDispatch();
  const handleAlerts = useCallback(async () => {
    dispatch(alertApiInitiate());
    const res = await api.post(`/vehicle/alerts${vehicleId ? `?vehicle_id=${vehicleId}` : ''}`, {
      name: filters.name,
      alert_type: filters.alert_type,
      date: {
        start: filters.date.start && filters.date.end ? dayjs.utc(filters.date.start) : null,
        end: filters.date.start && filters.date.end ? dayjs.utc(filters.date.end) : null
      },
      pageIndex: (pagination?.currentPage || 1) - 1,
      pageSize: pagination?.showBy || 10
    });
    if (res?.data.length > 0) {
      const formatDescriptionDate = (description: string, tz: string) => {
        const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g;
        const formattedDescription = description.replace(regex, match => {
          return formatAlertDate(match, tz);
        });

        return formattedDescription;
      };

      const formattedData = res?.data.map(
        (item: {
          vehicle_id: string;
          vehicle_name: string;
          trip_id: string;
          id: string;
          ts: string;
          alert_subtype: number;
          alert_type: string;
          description: string;
          tz: string;
        }) => ({
          ...item,
          description: formatDescriptionDate(item.description, item.tz)
        })
      );
      dispatch(setAlertData(formattedData));
      dispatch(
        setPagination({
          table: 'alerts' as ETableName,
          dataLength: res.totalElements || 0,
          totalPages: res.totalPages || 0
        })
      );
    } else {
      dispatch(setAlertData([]));
      dispatch(
        setPagination({
          table: 'alerts' as ETableName,
          dataLength: 0,
          totalPages: 0
        })
      );
    }
  }, [
    vehicleId,
    customerId,
    dispatch,
    api,
    pagination?.currentPage,
    pagination?.showBy,
    filters,
    reloadAlerts
  ]);

  useEffect(() => {
    handleAlerts().catch(e => console.log('err', e));
  }, [handleAlerts]);
};
