export function CaretDown({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 7 5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41659 1.20362C6.41659 1.30393 6.38051 1.39074 6.30835 1.46404L3.75627 4.05663C3.68411 4.12993 3.59866 4.16659 3.49992 4.16659C3.40118 4.16659 3.31573 4.12993 3.24357 4.05663L0.691488 1.46404C0.619331 1.39074 0.583252 1.30393 0.583252 1.20362C0.583252 1.10331 0.619331 1.01651 0.691488 0.943206C0.763645 0.869903 0.849094 0.833252 0.947835 0.833252H6.052C6.15074 0.833252 6.23619 0.869903 6.30835 0.943206C6.38051 1.01651 6.41659 1.10331 6.41659 1.20362Z"
      />
    </svg>
  );
}
