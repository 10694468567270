/*eslint-disable */
import { FieldArray, Form, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
  VIN,
  Truck,
  Weight,
  Height,
  VehicleAxles,
  TollID,
  PlateNumber,
  VehicleClass,
  PlateState,
  Plus,
  Delete
} from '@assets/icons';
import { SelectInput, TextInput } from '@common/ui/Form';
import { useGetListsData } from '../../Update/Vehicle/hooks/useGetListsData';
import styles from './create-vehicle.module.scss';
import Accordion from '@common/ui/Accordion/Accordion';
import { v4 as uuid } from 'uuid';
import Button from '@common/ui/Button/Button';
import { WEB_BE_URL } from '@api/config';
import axios from 'axios';
import { useAppSelector } from '@store/hooks';
import { TagInterface } from '@common/types/data/fleet.interface';
import { EAuthRole } from '@common/utils/enums';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';

const CreateVehicle = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { agencies, secAgencies, tagTypes, vehicleClasses, plateStates } = useGetListsData();
  const hasEditAccess = !useEditPermission(routes.fleet);
  const formik = useFormikContext<AnyObject>();
  const userRole = useAppSelector(state => state.auth.app_role);
  const isMetric = useAppSelector(state => state.auth.isMetric);

  const [disabled, setDisabled] = useState({
    primary: {
      issuingAgency: false,
      tagType: false
    },
    secondary: {
      issuingAgency: false,
      tagType: false
    }
  });

  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const multiTag = (isDisabled: boolean = false, key: string) => (
    <div className={styles['accordion-header']} key={key}>
      <TextInput
        className={styles.input}
        label="Tag/Transponder"
        name="tagTransponder"
        icon={<VehicleAxles />}
        placeholder="Enter Tag/Transponder"
        disabled={isDisabled}
        onSetValue={(e: any) => {
          formik.values['tagTransponder'] = e.target.value;
        }}
      />
      <SelectInput
        className={styles.input}
        label="Issuing Agency"
        formField="agencyName"
        icon={<TollID />}
        includeSearch
        values={agencies}
        dropdownContainerEl={formRef}
        placeholder="Select Issuing Agency"
        disabled
      />
      <SelectInput
        className={styles.input}
        label="Tag Type"
        formField="tagType"
        icon={<TollID />}
        includeSearch
        values={tagTypes}
        dropdownContainerEl={formRef}
        placeholder="Select Tag Type"
        disabled
      />
    </div>
  );

  const parseTagTransponder = async (
    e: any,
    type: string,
    consolidator: { type: string; index: number } | null = null
  ) => {
    if (e.target.value && e.target.value.length > 0) {
      try {
        const parseRes = (
          await axios.post(`${WEB_BE_URL}/customer-onboard/parse-tagnumber`, {
            tag_number: e.target.value
          })
        ).data;
        if (parseRes.isSuccess && parseRes.data) {
          const parsedIssuingAgency = tagIssuingAgencies?.find(x => Number(x.id) === parseRes.data);
          if (parsedIssuingAgency && parsedIssuingAgency.name) {
            if (type === 'consolidator') {
              if (consolidator?.type === 'child') {
                const childTags = [...formik.values.childTags];
                childTags[consolidator.index].issuingAgencyIdD = parsedIssuingAgency?.name;
                formik.setFieldValue('childTags', childTags);
              }
            }
          }
        }
        setDisabled({ ...disabled });
      } catch (error) {
        console.log('Error in parsing tag transponder ==> ', error);
      }
    }
  };
  const deleteChildTag = (index: number) => {
    if (index !== null) {
      let childTags: TagInterface[] = formik.values.childTags;
      if (childTags) {
        const updatedChildTags = [...childTags]; // Create a new array
        updatedChildTags.splice(index, 1); // Modify the new array
        formik.setFieldValue('childTags', updatedChildTags); // Assign the new array back to formik.values.childTags
      }
    }
  };
  const addChildTag = () => {
    let childTags: TagInterface[] = formik.values.childTags || [];
    if (childTags) {
      const updatedChildTags = [...childTags]; // Create a new array
      updatedChildTags.push({
        tagTypeId: '',
        tagType: '',
        tagTransponder: '',
        issuingAgencyId: null,
        issuingAgencyIdD: '',
        agencyName: ''
      }); // Modify the new array
      formik.setFieldValue('childTags', updatedChildTags); // Assign the new array back to formik.values.childTags
    }
  };

  return (
    <>
      <Form className={styles.container} ref={formRef}>
        <section className={styles.form}>
          <TextInput
            className={styles.input}
            label="Vehicle Name"
            name="name"
            icon={<PlateNumber />}
            placeholder="Enter Vehicle Name"
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Class"
            formField="vehicleClass"
            icon={<VehicleClass className={styles.class} />}
            values={vehicleClasses}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Class"
          />
          <SelectInput
            className={styles.input}
            label="License Plate State"
            formField="plateState"
            icon={<PlateState />}
            values={plateStates}
            dropdownContainerEl={formRef}
            placeholder="Select Plate State"
          />

          <TextInput
            className={styles.input}
            label="License Plate Number"
            name="plateNumber"
            icon={<PlateNumber />}
            placeholder="Enter Plate Number"
          />
          <TextInput
            className={styles.input}
            label="VIN"
            name="vin"
            icon={<VIN />}
            placeholder="Enter Vehicle VIN"
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Type"
            formField="type"
            icon={<Truck />}
            values={['AUTO', 'TRUCK', 'TRAILER']}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Type"
          />
          <TextInput
            className={styles.input}
            label={`Weight, ${isMetric ? 'kg' : 'lbs'}`}
            name="weight"
            type="number"
            icon={<Weight />}
            placeholder="Enter Vehicle Weight"
          />
          <TextInput
            className={styles.input}
            label={`Height, ${isMetric ? 'meter' : 'inch'}`}
            name="height"
            type="number"
            icon={<Height />}
            placeholder="Enter Vehicle Height"
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Axles"
            formField="axles"
            icon={<VehicleAxles />}
            values={[2, 3, 4, 5]}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Axles"
          />

          <div>
            {formik.values?.tollConsolidator && (
              <Accordion
                headerElement={multiTag(hasEditAccess, uuid())}
                onlyArrowClick
                className={styles.accordion}
                // disabled={formik.values.childTags?.length === 0}
              >
                <div className={styles['multi-tag-wrapper']}>
                  {formik.values.childTags?.length > 0 ? (
                    <FieldArray
                      name="childTags"
                      render={() => (
                        <>
                          {formik.values.childTags.map((tag: TagInterface, index: number) => (
                            <div className={styles['accordion-header']} key={index}>
                              <TextInput
                                className={styles.input}
                                label="Tag/Transponder"
                                onBlur={e =>
                                  parseTagTransponder(e, 'consolidator', { index, type: 'child' })
                                }
                                name={`childTags[${index}]tagTransponder`}
                                icon={<VehicleAxles />}
                                placeholder="Enter Tag/Transponder"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <SelectInput
                                className={styles.input}
                                label="Issuing Agency"
                                formField={`childTags[${index}]issuingAgencyId`}
                                icon={<TollID />}
                                includeSearch
                                values={secAgencies}
                                dropdownContainerEl={formRef}
                                placeholder="Select Issuing Agency"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <SelectInput
                                className={styles.input}
                                label="Tag Type"
                                formField={`childTags[${index}]tagType`}
                                icon={<TollID />}
                                includeSearch
                                values={tagTypes}
                                dropdownContainerEl={formRef}
                                placeholder="Select Tag Type"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <Button
                                className={styles['del-sec-tag']}
                                appearance="icon"
                                variant="secondary"
                                onClick={() => deleteChildTag(index)}
                                icon={<Delete />}
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                                size="xs"
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                  ) : (
                    <div className={styles['empty-child-tag']}>
                      <p className={styles.text}>No child tags found.</p>
                    </div>
                  )}
                  {userRole &&
                    userRole === EAuthRole.SUPER_ADMIN &&
                    (formik.values.childTags?.length < 2 || !formik.values.childTags) && (
                      <Button
                        className={styles['add-child-tag']}
                        appearance="button"
                        variant="primary"
                        onClick={() => addChildTag()}
                        icon={<Plus />}
                        label="Toll Device"
                        size="xs"
                        disabled={hasEditAccess}
                      />
                    )}
                </div>
                {/* )} */}
              </Accordion>
            )}
          </div>
        </section>
      </Form>
    </>
  );
};

export default CreateVehicle;
