import { Download } from '@assets/icons';
import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import Loader from '@common/ui/Loader';
import { useAppSelector } from '@store/hooks';
import Button from '@common/ui/Button';
import { Chip } from '@mui/material';
import {
  UIDateTimeformatHelper,
  UIDateTimeformatHelper2
} from '@common/services/format/formatTimeZone';
import { formatCurrency } from '@pages/dashboard/common/helper';
import styles from './subscription.module.scss';
import { IManualInvoice } from './types/subscription-invoice';

const ManualInvoices = () => {
  const invoices: Array<IManualInvoice> = useAppSelector(state => state.settings.manualInvoices);
  const invoiceLoading = useAppSelector(state => state.settings.manualInvoiceLoading);
  const [statusModifiedData, setModData] = useState<IManualInvoice[]>([]);
  const headers = ['Invoice Number', 'Amount', 'Due Date', 'Created Date'];
  const getChipStyle = (
    status: string
  ): {
    color: string;
    backgroundColor: string;
    fontWeight: number;
  } => {
    const modStatus = status.toLowerCase();
    if (modStatus === 'open') {
      return {
        color: '#FF1609',
        backgroundColor: '#FFE8E6', // Red color with 80% opacity
        fontWeight: 600
      };
    }
    if (modStatus === 'paid') {
      return {
        color: '#34C759',
        backgroundColor: '#EBF9EE', // Green color with 80% opacity
        fontWeight: 600
      };
    }
    return {
      color: '#586171',
      backgroundColor: '#ebeef1',
      fontWeight: 600
    };
  };
  useEffect(() => {
    if (invoices && invoices.length > 0) {
      setModData(
        invoices.map(item => ({
          ...item,
          dropDownStatus: false
        }))
      );
    }
  }, [invoices]);
  return invoiceLoading ? (
    <Loader className={`${styles.loader}`} />
  ) : (
    <section className={styles.tableWrapper}>
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              {headers.map(header => (
                <th>{header}</th>
              ))}
              {/* eslint-disable jsx-a11y/control-has-associated-label */}
              <th />
            </tr>
          </thead>
          <tbody>
            {statusModifiedData && statusModifiedData?.length > 0 ? (
              <>
                {statusModifiedData.map(invoice => (
                  <tr key={uniqueId()}>
                    <td>{invoice?.invoiceNumber || '-'}</td>
                    <td className={styles['invoice-grid']}>
                      <div className={styles['flex-wrapper']}>
                        <div className={styles['invoice-amount-wrapper']}>
                          <span className={styles['invoice-amt']}>
                            {invoice?.invoiceAmount
                              ? formatCurrency(invoice.invoiceAmount.toString())
                              : '-'}
                          </span>
                        </div>
                        <div className={styles['chip-wrapper']}>
                          <Chip
                            className={styles['square-chip']}
                            variant="outlined"
                            label={invoice?.paymentStatus || '-'}
                            sx={{ border: '1px solid', fontSize: '1.3rem', padding: '6px 12px' }}
                            style={getChipStyle(invoice?.paymentStatus || '')}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      {invoice?.invoiceDueDate
                        ? `${UIDateTimeformatHelper2(invoice?.invoiceDueDate, '', true)}`
                        : '-'}
                    </td>
                    <td>
                      {invoice?.invoiceCreatedDate
                        ? `${UIDateTimeformatHelper(invoice?.invoiceCreatedDate)}`
                        : '-'}
                    </td>
                    <td>
                      <div className={styles['action-handler']}>
                        {invoice?.paymentStatus &&
                          invoice?.paymentStatus?.toLowerCase() === 'open' && (
                            <Button
                              appearance="button"
                              className={styles['pay-btn']}
                              onClick={() => window.open(invoice?.hostedinvoiceURL, '_blank')}
                              variant="primary"
                              label="Pay"
                              size="xs"
                            />
                          )}
                        <Button
                          className={styles['normal-dwnld']}
                          disabled={!invoice?.invoicePDF}
                          onClick={() => window.open(invoice?.invoicePDF, '_blank')}
                          appearance="icon"
                          variant="tertiary"
                          icon={<Download />}
                          size="xs"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={headers.length + 1}>
                  <h6 className={styles.noData}> No Invoice data to display</h6>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ManualInvoices;
