export function VehicleAxles({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 1.45836C0.75 0.929091 1.16719 0.500031 1.68182 0.500031H4.47727C4.9919 0.500031 5.40909 0.929091 5.40909 1.45836V3.37503H7.36366C7.74741 2.25839 8.78289 1.45836 10 1.45836C11.2172 1.45836 12.2527 2.25839 12.6364 3.37503H14.5909V1.45836C14.5909 0.929091 15.0081 0.500031 15.5227 0.500031H18.3182C18.8328 0.500031 19.25 0.929091 19.25 1.45836V7.20836C19.25 7.73764 18.8328 8.1667 18.3182 8.1667H15.5227C15.0081 8.1667 14.5909 7.73764 14.5909 7.20836V5.2917H12.6364C12.3557 6.10855 11.7262 6.75597 10.932 7.04472V12.9554C11.7262 13.2442 12.3556 13.8915 12.6364 14.7083H14.5909V12.7916C14.5909 12.2623 15.0081 11.8333 15.5227 11.8333H18.3182C18.8328 11.8333 19.25 12.2623 19.25 12.7916V18.5416C19.25 19.0709 18.8328 19.4999 18.3182 19.4999H15.5227C15.0081 19.4999 14.5909 19.0709 14.5909 18.5416V16.6249H12.6365C12.2528 17.7417 11.2173 18.5418 10 18.5418C8.78283 18.5418 7.74731 17.7417 7.3636 16.6249H5.40909V18.5416C5.40909 19.0709 4.9919 19.4999 4.47727 19.4999H1.68182C1.16719 19.4999 0.75 19.0709 0.75 18.5416V12.7916C0.75 12.2623 1.16719 11.8333 1.68182 11.8333H4.47727C4.9919 11.8333 5.40909 12.2623 5.40909 12.7916V14.7083H7.36371C7.6445 13.8914 8.27405 13.244 9.06836 12.9553V7.04482C8.274 6.75611 7.64441 6.10864 7.36366 5.2917H5.40909V7.20836C5.40909 7.73764 4.9919 8.1667 4.47727 8.1667H1.68182C1.16719 8.1667 0.75 7.73764 0.75 7.20836V1.45836ZM2.61364 2.4167V6.25003H3.54545V2.4167H2.61364ZM2.61364 13.7499V17.5833H3.54545V13.7499H2.61364ZM17.3864 2.4167H16.4545V6.25003H17.3864V2.4167ZM17.3864 13.7499V17.5833H16.4545V13.7499H17.3864ZM10 3.37503C9.48542 3.37503 9.06823 3.80409 9.06823 4.33336C9.06823 4.86264 9.48542 5.2917 10 5.2917C10.5147 5.2917 10.9319 4.86264 10.9319 4.33336C10.9319 3.80409 10.5147 3.37503 10 3.37503ZM9.06823 15.6668C9.06823 15.1375 9.48542 14.7084 10 14.7084C10.5147 14.7084 10.9319 15.1375 10.9319 15.6668C10.9319 16.1961 10.5147 16.6251 10 16.6251C9.48542 16.6251 9.06823 16.1961 9.06823 15.6668Z"
      />
    </svg>
  );
}
