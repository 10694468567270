export function ExternalLink({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 10.82C18.7348 10.82 18.4804 10.9254 18.2929 11.1129C18.1054 11.3004 18 11.5548 18 11.82V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8.18C8.44522 2 8.69957 1.89464 8.88711 1.70711C9.07464 1.51957 9.18 1.26522 9.18 1C9.18 0.734784 9.07464 0.48043 8.88711 0.292893C8.69957 0.105357 8.44522 0 8.18 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V11.82C20 11.5548 19.8946 11.3004 19.7071 11.1129C19.5196 10.9254 19.2652 10.82 19 10.82ZM19.92 0.62C19.8185 0.375651 19.6243 0.181475 19.38 0.0799999C19.2598 0.028759 19.1307 0.00157999 19 0H13C12.7348 0 12.4804 0.105357 12.2929 0.292893C12.1054 0.48043 12 0.734784 12 1C12 1.26522 12.1054 1.51957 12.2929 1.70711C12.4804 1.89464 12.7348 2 13 2H16.59L9.29 9.29C9.19627 9.38296 9.12188 9.49356 9.07111 9.61542C9.02034 9.73728 8.9942 9.86799 8.9942 10C8.9942 10.132 9.02034 10.2627 9.07111 10.3846C9.12188 10.5064 9.19627 10.617 9.29 10.71C9.38296 10.8037 9.49356 10.8781 9.61542 10.9289C9.73728 10.9797 9.86799 11.0058 10 11.0058C10.132 11.0058 10.2627 10.9797 10.3846 10.9289C10.5064 10.8781 10.617 10.8037 10.71 10.71L18 3.41V7C18 7.26522 18.1054 7.51957 18.2929 7.70711C18.4804 7.89464 18.7348 8 19 8C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7V1C19.9984 0.869323 19.9712 0.740222 19.92 0.62Z" />
    </svg>
  );
}
