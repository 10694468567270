import * as Yup from 'yup';
import { initialValues } from './initialValues';

export const validationSchema: Yup.SchemaOf<typeof initialValues> = Yup.object().shape({
  email: Yup.string().email('enter a valid email').required('email is required'),
  firstName: Yup.string().required('first name is required'),
  lastName: Yup.string().required('last name is required'),
  app_role: Yup.string().required('role is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Password must be at least 8 characters long, contain at least one number, one special character, one uppercase letter, and one lowercase letter'
    )
});
