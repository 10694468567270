export function Repeat({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38126 0.349416C9.34785 -0.184555 11.4306 -0.104972 13.3507 0.577508C15.2708 1.25999 16.9366 2.51279 18.125 4.16817V2.18692C18.125 1.93828 18.2238 1.69982 18.3996 1.524C18.5754 1.34819 18.8139 1.24942 19.0625 1.24942C19.3112 1.24942 19.5496 1.34819 19.7254 1.524C19.9012 1.69982 20 1.93828 20 2.18692V7.49942H14.6875C14.4389 7.49942 14.2004 7.40064 14.0246 7.22483C13.8488 7.04901 13.75 6.81056 13.75 6.56192C13.75 6.31328 13.8488 6.07482 14.0246 5.899C14.2004 5.72319 14.4389 5.62442 14.6875 5.62442H16.8463C15.8671 4.09253 14.4024 2.93331 12.6866 2.33221C10.9707 1.7311 9.10287 1.72286 7.38179 2.30878C5.6607 2.89471 4.18586 4.04095 3.19321 5.56414C2.20055 7.08732 1.74745 8.89943 1.90639 10.7106C2.06532 12.5217 2.82711 14.2272 4.06988 15.5542C5.31265 16.8812 6.96458 17.7531 8.76142 18.0303C10.5582 18.3075 12.3961 17.974 13.9811 17.0833C15.566 16.1925 16.8064 14.7959 17.5038 13.1169C17.549 13.0005 17.6171 12.8943 17.704 12.8046C17.7909 12.7149 17.8948 12.6434 18.0097 12.5944C18.1246 12.5454 18.2481 12.5198 18.373 12.5192C18.4979 12.5186 18.6216 12.543 18.737 12.5909C18.8523 12.6388 18.957 12.7092 19.0447 12.7981C19.1324 12.887 19.2015 12.9925 19.2479 13.1085C19.2943 13.2244 19.3171 13.3485 19.3149 13.4734C19.3126 13.5982 19.2855 13.7214 19.235 13.8357C18.5835 15.4038 17.5434 16.7803 16.2128 17.8352C14.8821 18.8902 13.3047 19.5889 11.6293 19.8655C9.95386 20.1422 8.23558 19.9876 6.63642 19.4164C5.03726 18.8452 3.60991 17.8761 2.4889 16.6006C1.36789 15.3251 0.590158 13.7851 0.229013 12.1259C-0.132132 10.4666 -0.064793 8.74269 0.424682 7.11665C0.914158 5.49062 1.80965 4.016 3.02673 2.83182C4.24382 1.64764 5.74242 0.792899 7.38126 0.348166V0.349416Z"
        fill="currentColor"
      />
    </svg>
  );
}
