/*eslint-disable */
import { FC } from 'react';
import { MinMaxNumberInput, SelectInput } from '@common/ui/Form';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { OptionsByTableProps } from './types';
import StartAndEndDate from '../StartAndEndDate/StartAndEndDate';
import { VehicleTires } from '@assets/icons/VehicleTires';

const Trips: FC<OptionsByTableProps> = ({ ...props }) => {
  const isMetric = useAppSelector(state => state.auth.isMetric);
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  return (
    <>
      {!isGeotab ? (
        <StartAndEndDate
          startLabel="Trip Start Date From"
          startPlaceholder="Trip Start Date From"
          endLabel="Trip Start Date To"
          endPlaceholder="Trip Start Date To"
          startField="date.from"
          endField="date.to"
          {...props}
        />
      ) : (
        ''
      )}
      <MinMaxNumberInput
        className={props.className}
        minName="tripDistance.from"
        maxName="tripDistance.to"
        label={`Trip Distance, ${isMetric ? 'km' : 'miles'}`}
      />
      <MinMaxNumberInput
        className={props.className}
        minName="toll.from"
        maxName="toll.to"
        label="tollogic&#8482; Tolls"
      />
      <MinMaxNumberInput
        className={props.className}
        minName="tripDuration.from"
        maxName="tripDuration.to"
        label="Trip Duration, mins"
      />
      <SelectInput
        {...props}
        type="simple"
        label="GPS Reliability"
        formField="gpsReliability"
        icon={<VehicleTires />}
        values={['HIGH', 'LOW', 'UNKNOWN']}
        placeholder="Select GPS Reliability"
      />
    </>
  );
};

export default Trips;
