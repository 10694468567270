/* eslint-disable */
import Log from '@common/ui/Log';
import { v4 as uuid } from 'uuid';
import styles from './history.module.scss';
import { convertBillingStatus } from '@common/utils/constants/helpers';

const History = ({ historyData }: { historyData: any }) => {
  return (
    <section className={styles.container}>
      {/* <ModalActions type="sorting" componentName="history" /> */}
      <ul className={styles.histories}>
        {historyData?.data?.length > 0 ? (
          historyData?.data?.map((data: any) => {
            let val = { ...data };
            let modifiedFields: any;
            if (data.updated_fields && data.previous_fields !== null) {
              const differentOfObjects = (a: any, b: any) =>
                Object.entries(a).reduce((acc: any, [key, val]) => {
                  if (val !== b[key]) acc[key] = val;
                  return acc;
                }, {});
              modifiedFields = differentOfObjects(
                JSON.parse(data.updated_fields),
                JSON.parse(data.previous_fields)
              );
              delete modifiedFields.id;
              delete modifiedFields.tires;
              delete modifiedFields.deviceId;
              delete modifiedFields.customerId;
              delete modifiedFields.createdAt;
              delete modifiedFields.deleted;
              delete modifiedFields.deletedAt;
              delete modifiedFields.updatedAt;
              delete modifiedFields.lastLogRecordTimestamp;
              delete modifiedFields.trips;
              delete modifiedFields.devicePlan;
              delete modifiedFields.billingStatusUpdatedAt;
              delete modifiedFields.tollConsolidator;
              delete modifiedFields.secondTagExists;
              delete modifiedFields.tagTypeId;
              delete modifiedFields.issuingAgencyId;
              delete modifiedFields.secTagTypeId;
              delete modifiedFields.secIssuingAgencyId;
              delete modifiedFields.trdTagTypeId;
              delete modifiedFields.trdIssuingAgencyId;
              delete modifiedFields.gpsReliability;
              delete modifiedFields.equipmentInvoiceDate;
            }
            if (data.previous_fields === null) {
              modifiedFields = { type: 'new' };
            }

            /** To enable childtags update in history */
            // if (val.previous_fields?.childTags && modifiedFields?.childTags) {
            //   modifiedFields.childTags = 'Child tags has been updated';
            //   JSON.parse(data.previous_fields)['childTags'] = '';
            //   //  ? JSON.parse(data.previous_fields)[keys] : 'none'
            // } else {
            //   delete modifiedFields.childTags;
            // }
            delete modifiedFields.childTags;
            const keysToUpdate = [
              'secTagType',
              'secTagTransponder',
              'secAgencyName',
              'trdTagType',
              'trdTagTransponder',
              'trdAgencyName',
              'billingStatus'
            ];
            const previousFields = JSON.parse(val.previous_fields);
            for (const key of keysToUpdate) {
              if (
                (modifiedFields[key] || modifiedFields[key] === '') &&
                (previousFields[key] || previousFields[key] === '')
              ) {
                if (key === 'billingStatus') {
                  modifiedFields[key] = convertBillingStatus(modifiedFields[key]);
                  previousFields[key] = convertBillingStatus(previousFields[key]);
                } else {
                  let newKey;
                  if (key.startsWith('sec')) {
                    newKey = `Second ${key.charAt(3).toUpperCase() + key.slice(4)}`;
                  } else if (key.startsWith('trd')) {
                    newKey = `Third ${key.charAt(3).toUpperCase() + key.slice(4)}`;
                  } else {
                    newKey = key; // No prefix for keys other than sec and trd
                  }
                  modifiedFields[newKey] = modifiedFields[key] ? modifiedFields[key] : 'None';
                  previousFields[newKey] = previousFields[key] ? previousFields[key] : 'None';
                  delete modifiedFields[key];
                  delete previousFields[key];
                }
              }
            }

            val.previous_fields = JSON.stringify(previousFields);
            val.modifiedFields = modifiedFields;
            if (modifiedFields && Object.entries(modifiedFields).length > 0) {
              return (
                <li key={uuid()}>
                  <Log {...val} className={styles.log} />
                </li>
              );
            }
          })
        ) : (
          <span>No History Available</span>
        )}
      </ul>
    </section>
  );
};

export default History;
