/* eslint-disable */
import Checkbox from '@common/ui/Checkbox/Checkbox';
import { FC } from 'react';
import { useRowsSelection } from './hooks';
import styles from './row-checkbox.module.scss';
import { RowCheckboxProps } from './types';

const RowCheckbox: FC<RowCheckboxProps> = ({
  checkboxState,
  variant,
  table,
  data,
  height,
  geoTab,
  isHovered
}) => {
  const { selectRow } = useRowsSelection(checkboxState, variant, table, data);
  return (
    <div
      className={
        geoTab
          ? ''
          : `${styles.container} ${isHovered ? styles.checked : ''} ${
              styles[`container-${variant}`]
            } ${styles[checkboxState]}`
      }
      style={
        geoTab
          ? variant === 'header'
            ? { marginRight: 10, height: 36 }
            : { marginRight: 10, height: 20 }
          : { height }
      }
    >
      <div className={styles.checkbox} style={{ height }}>
        <Checkbox onClick={() => selectRow()} state={checkboxState} />
      </div>
      <div className={styles['row-gradient']} style={{ height }} />
    </div>
  );
};

export default RowCheckbox;
