/* eslint-disable */
import { WEB_BE_URL } from '@api/config';
import { accessTokenCookie, emailCookie } from '@common/services/auth';
import axios from 'axios';
import Cookies from 'js-cookie';

export const http = axios.create({
  baseURL: WEB_BE_URL,
  headers: {
    authorization: Cookies.get(accessTokenCookie) ? Cookies.get(accessTokenCookie).trim() : '',
    email: Cookies.get(emailCookie) ? Cookies.get(emailCookie).trim() : '',
    'Content-Type': 'application/json'
  }
});

export const setAuthHeader = token => {
  http.defaults.headers['authorization'] = 'Bearer' + token.trim();
};
