export function AddDocument({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 60 60"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.25 45H48.75V37.5H45V45H37.5V48.75H45V56.25H48.75V48.75H56.25V45Z"
        fill="currentColor"
      />
      <path
        d="M30 52.5006H15V7.50064H30V18.7506C30.003 19.7443 30.399 20.6964 31.1016 21.399C31.8042 22.1016 32.7564 22.4977 33.75 22.5006H45V30.0006H48.75V18.7506C48.7566 18.5042 48.7098 18.2593 48.6127 18.0327C48.5156 17.8061 48.3705 17.6033 48.1875 17.4381L35.0625 4.31314C34.8974 4.13003 34.6946 3.98492 34.468 3.88781C34.2414 3.79069 33.9964 3.7439 33.75 3.75064H15C14.0064 3.75361 13.0542 4.14965 12.3516 4.85227C11.649 5.55488 11.253 6.50699 11.25 7.50064V52.5006C11.253 53.4943 11.649 54.4464 12.3516 55.149C13.0542 55.8516 14.0064 56.2477 15 56.2506H30V52.5006ZM33.75 8.25064L44.25 18.7506H33.75V8.25064Z"
        fill="currentColor"
      />
    </svg>
  );
}
