import { useState, useEffect } from 'react';
import { CheckboxStateType } from '@common/ui/Checkbox/types';
import { SHOW_DELETE_MODAL_CONSTANT } from '@common/utils/constants';
import Cookies from 'js-cookie';

export const useDontShowAgain = () => {
  const [isChecked, setIsChecked] = useState<CheckboxStateType>(
    (Cookies.get(SHOW_DELETE_MODAL_CONSTANT) as CheckboxStateType) || 'unchecked'
  );
  const handleCheckbox = () => {
    setIsChecked(isChecked === 'checked' ? 'unchecked' : 'checked');
  };
  useEffect(() => {
    if (isChecked === 'checked') {
      Cookies.set(SHOW_DELETE_MODAL_CONSTANT, 'checked');
    } else {
      Cookies.remove(SHOW_DELETE_MODAL_CONSTANT);
    }
  }, [isChecked]);

  return { isChecked, handleCheckbox };
};
