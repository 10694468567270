import Body from '../layout/body/Body';
import Loader from '../loader/Loader';
import { useSSOContext } from '../../context/SSOContext';
import styles from './content.module.scss';
import SSOError from '../error/Error';
import NotEnrolled from '../NotEnrolled/NotEnrolled';

const Content = () => {
  const { loader, isCustomerNew, gUser, showSsoErr } = useSSOContext();

  if (loader) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  if (showSsoErr) {
    return <SSOError />;
  }
  return (
    <div className={styles.container}>
      {/* <Help location="geotab_onboarding" className={styles.help} /> */}
      {isCustomerNew && !gUser.isAdmin && gUser.email ? <NotEnrolled /> : <Body />}
    </div>
  );
};

export default Content;
