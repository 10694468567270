/* eslint-disable import/no-cycle */
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { removeMultipleSelectionIDs } from '@store/features/selection';
import { closeModal, openModal } from '@store/features/modal';
import { ETableName } from '@common/utils/enums';
import { useDeleteCustomerMutation } from '@api/services/customers';
import { useDeleteUserMutation } from '@api/services/users';
import { useDeleteVehicleMutation } from '@api/services/fleet';
import { useDeleteTransactionMutation } from '@api/services/transactions';
import { SHOW_DELETE_MODAL_CONSTANT } from '@common/utils/constants';
import { refetchTableData } from '@store/data';
import Cookies from 'js-cookie';
import { UnionDataInterface } from '@common/types/data';
import { useState } from 'react';
import { errorMessage, tableNameByLength } from '@common/services/format';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { usePromiseToastNotify } from './useToastNotify';
import { useLogOut } from './useLogOut';

export const useDataDelete = (
  data: UnionDataInterface[],
  modalId: string | number,
  inModal = false
) => {
  const [deleting, setDeleting] = useState(false);
  const table = useAppSelector(state => state.table);
  const tableName = table.name as ETableName;
  const dataIds = data.map(row => row?.id);
  const dispatch = useAppDispatch();
  const [deleteCustomer] = useDeleteCustomerMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [deleteVehicle] = useDeleteVehicleMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const toastify = usePromiseToastNotify();
  const logOut = useLogOut();

  const deleteFunc = (id: string | number) => {
    if (id) {
      if (tableName === ETableName.CUSTOMERS) {
        return deleteCustomer(id).unwrap();
      }
      if (tableName === ETableName.USERS) {
        return deleteUser(id as string).unwrap();
      }
      if (tableName === ETableName.FLEET) {
        return deleteVehicle(id)
          .unwrap()
          .finally(() => dispatch(refetchTableData(true)));
      }
      if (tableName === ETableName.TRANSACTIONS) {
        return deleteTransaction(id as string).unwrap();
      }
      return null;
    }
    return null;
  };

  const handleDelete = () => {
    if (!Cookies.get(SHOW_DELETE_MODAL_CONSTANT) && !inModal) {
      dispatch(openModal(['delete', modalId]));
    } else {
      setDeleting(true);
      new Promise(() => {
        dataIds.forEach(id => {
          toastify(
            (deleteFunc(id) as Promise<unknown>)
              .then(() => {
                dispatch(removeMultipleSelectionIDs({ table: tableName, value: data }));
                dispatch(closeModal());
              })
              .catch((error: FetchBaseQueryError) => {
                if (error.status === 401) {
                  logOut();
                }
                throw new Error(errorMessage(error));
              }),
            'delete',
            tableNameByLength(1, tableName)
          );
        });
      })
        .catch(e => console.log(e))
        .finally(() => setDeleting(false));
    }
  };

  return { handleDelete, deleting };
};
