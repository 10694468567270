/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { ETableName } from '@common/utils/enums';
import { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Allotment, LayoutPriority } from 'allotment';
import 'allotment/dist/style.css';
import { TripDataInterface, UnionDataInterface } from '@common/types/data';
import MaterialAccordion from '@common/ui/Mui/Accordion/MaterialAccordion';
import Cookies from 'js-cookie';
import { cookieTripAllotment, cookieTripTabAllotment } from '@common/services/auth';
import NavControl from '@app/layouts/main/components/Navigation/NavControl';
import styles from './trip-split-pane.module.scss';
import WebportalTable from '../layouts/web-portal/components/table/Table';
import GeotabTable from '../layouts/geotab-addin/components/table';
import TripsMap from '../layouts/web-portal/components/modals/RowModal/Trip/TripsMap/TripsMap';
import TripDetails from './detail/TripDetails';
import TripTransaction from './transactions/TripTransaction';
import GeotabTablePagination from '../layouts/geotab-addin/components/pagination/TablePagination';
import TablePagination from '../layouts/web-portal/components/pagination/TablePagination';
import CrossFilter from '../layouts/web-portal/components/buttons/CrossFilter/CrossFilter';

const TripSplitPaneView = ({
  pageData,
  loading
}: {
  pageData: UnionDataInterface[];
  loading: boolean;
}) => {
  const table = useAppSelector(state => state.table);
  const [activeAccordions, setActiveAccordions] = useState<string[]>(['routes', 'details']);
  const { id } = useParams();
  const tripData = pageData.find(x => x.id === Number(id)) as TripDataInterface;
  const [tripTimeZone] = useState('');
  const navigate = useNavigate();
  const [isSecPaneVisible, setIsSecPaneVisible] = useState(false);

  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  useEffect(() => {
    if (id) {
      setIsSecPaneVisible(true);
    }
  }, [id]);

  useEffect(() => {
    const accToBeOpened: string[] =
      Cookies.get(cookieTripTabAllotment) && JSON.parse(Cookies.get(cookieTripTabAllotment) || '')
        ? JSON.parse(Cookies.get(cookieTripTabAllotment) || '')
        : [];
    if (id) {
      setActiveAccordions(accToBeOpened);
      const isTripIdFound = pageData.findIndex(x => x.id === Number(id)) !== -1;
      if (!isTripIdFound) {
        navigate(`/trips/${pageData[0].id}`);
      }
    }
  }, []);
  function handleSplitterView() {
    navigate(`/trips`);
    setIsSecPaneVisible(!isSecPaneVisible);
  }

  const toggleAccordion = (accordion: string) => {
    const isAccordionPresent = activeAccordions.findIndex(x => x === accordion) !== -1;
    if (isAccordionPresent) {
      const filteredAccordions = activeAccordions.filter(x => x !== accordion);
      setActiveAccordions(filteredAccordions);
      Cookies.set(cookieTripTabAllotment, JSON.stringify(filteredAccordions), {
        sameSite: 'None',
        secure: true
      });
    } else {
      const currentAccordions = [...activeAccordions];
      currentAccordions.push(accordion);
      Cookies.set(cookieTripTabAllotment, JSON.stringify(currentAccordions), {
        sameSite: 'None',
        secure: true
      });
      setActiveAccordions(currentAccordions);
    }
  };

  return (
    <Allotment
      proportionalLayout={false}
      separator={false}
      className={styles.container}
      onDragEnd={e => {
        if (e[1] > 0) {
          Cookies.set(cookieTripAllotment, JSON.stringify(e), { sameSite: 'None', secure: true });
        }
      }}
      defaultSizes={
        Cookies.get(cookieTripAllotment) && JSON.parse(Cookies.get(cookieTripAllotment) || '')
          ? JSON.parse(Cookies.get(cookieTripAllotment) || '')
          : undefined
      }
    >
      <Allotment.Pane minSize={500} priority={LayoutPriority.High} className={styles['pane-1']}>
        {isGeotab ? (
          <>
            <GeotabTable
              data={_.uniqBy(table.data, 'id') || []}
              loading={table.isFetching}
              tableName={table.name as ETableName}
            />
            <GeotabTablePagination />
          </>
        ) : (
          <>
            <WebportalTable pageData={pageData} loading={loading} />
            <div className={styles.pagination}>
              <TablePagination />
            </div>
          </>
        )}
      </Allotment.Pane>
      <Allotment.Pane
        snap
        minSize={500}
        className={styles['pane-2']}
        visible={(id && tripData && isSecPaneVisible) || false}
      >
        <NavControl
          navCollapse={isSecPaneVisible}
          isSplitter
          handleNavCollapse={() => {
            handleSplitterView();
          }}
        />
        <MaterialAccordion
          title={<div className={styles['accordion-header']}>Route</div>}
          summary=""
          iconPosition="start"
          isHeaderThemed
          showHoverStyle={!activeAccordions.includes('routes')}
          showBorderRadius
          showTopBorder
          showMarginBottom
          onClick={() => toggleAccordion('routes')}
          isOpen={activeAccordions.includes('routes')}
        >
          {activeAccordions.includes('routes') && tripData && (
            <TripsMap
              showHeader={false}
              showFullScreenToggle
              data={tripData}
              showFooter
              timezone={tripTimeZone}
            />
          )}
        </MaterialAccordion>
        <MaterialAccordion
          title={<div className={styles['accordion-header']}>Details</div>}
          summary=""
          iconPosition="start"
          isHeaderThemed
          showHoverStyle={!activeAccordions.includes('details')}
          showBorderRadius
          showTopBorder
          showMarginBottom
          onClick={() => toggleAccordion('details')}
          isOpen={activeAccordions.includes('details')}
        >
          {activeAccordions.includes('details') && <TripDetails data={tripData} />}
        </MaterialAccordion>
        <MaterialAccordion
          title={<div className={styles['accordion-header']}>Transactions</div>}
          summary=""
          iconPosition="start"
          isHeaderThemed
          showHoverStyle={!activeAccordions.includes('transactions')}
          showBorderRadius
          showTopBorder
          showMarginBottom
          onClick={() => toggleAccordion('transactions')}
          isOpen={activeAccordions.includes('transactions')}
        >
          {id && activeAccordions.includes('transactions') && <TripTransaction tripId={id} />}
        </MaterialAccordion>
        <div className={styles['action-btn']}>
          <CrossFilter tableName={ETableName.TRIPS} entryData={pageData} />
        </div>
      </Allotment.Pane>
    </Allotment>
  );
};

export default memo(TripSplitPaneView);
