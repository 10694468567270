import Dropdown from '@common/ui/Dropdown';
import { FC, useState } from 'react';
import { ETableName } from '@common/utils/enums';
import { More } from '@assets/icons';
import { TableMenuProps } from './types';
import { useMenuValues } from './hooks/useMenuValues';
import styles from './table-menu.module.scss';

export const TableMenu: FC<TableMenuProps> = ({ dropdownContainerEl, data, tableName }) => {
  const values = useMenuValues(tableName, data);
  // ? handle dropdown
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const handleDropdownOpenState = (value: boolean) => {
    if (![ETableName.TRANSACTIONS].includes(tableName)) setDropdownIsOpen(value);
  };

  return (
    <Dropdown
      type="context"
      isOpen={dropdownIsOpen}
      handleOpenState={handleDropdownOpenState}
      values={values}
      className={styles.dropdown}
      boundaryElement={dropdownContainerEl}
      position="bottom-end"
      offset={[-20, 0]}
      usePortal
    >
      <span className={styles.control}>
        {![ETableName.TRANSACTIONS].includes(tableName) && <More />}
      </span>
    </Dropdown>
  );
};
