/* eslint-disable */
import axios from 'axios';
import Cookies from 'js-cookie';
import { accessTokenCookie, emailCookie } from '@common/services/auth';
import { BE_URL } from '../config/api-urls';
import { setHeaders } from '@api/config/header';
import { store } from '@store/index';
import _ from 'lodash';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';

class exportApi {
  static async get(endPoint: string, params?: any) {
    try {
      const res = await axios.get(`${BE_URL}${endPoint}`, {
        headers: setHeaders(),
        params: params
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
    }
  }

  static async post(endPoint: string, data?: any, params?: any) {
    try {
      const res = await axios.post(`${BE_URL}${endPoint}`, data, {
        headers: setHeaders(),
        params: params
      });
      if (res.status !== 200) {
        if (res.status === 401) {
          const isGeotab = store.getState().user?.sourceType
            ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          SessionLogOut(isGeotab);
        } else {
          throw res;
        }
      } else {
        return res.data;
      }
    } catch (err) {
      console.log('err >>>>:', err);
    }
  }
}
export default exportApi;
