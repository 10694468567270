/* eslint-disable */
import Modal from '@common/ui/Modal';
import { history, trips } from '@store/data/fleetTabs/selector';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fleetHistory, fleetTrips } from '@store/data/fleetTabs';
import { AnimatePresence } from 'framer-motion';
import { UnionDataInterface, VehicleDataInterface } from '@common/types/data';
import Alert from '@pages/tables/layouts/web-portal/components/modals/RowModal/Vehicle/Alert';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import { CrossFilter, SubmitButton } from '../../../layouts/geotab-addin/components/form';
import { useHandleActiveTitle } from '../../../hooks/useHandleActiveTitle';
import MotionDiv from '../../../layouts/geotab-addin/components/motion-div';
import { UpdateVehicle, UpdateVehicleFormik } from './update';
import styles from './view.module.scss';
import TripsList from './trips-list/TripsList';
import History from './history/History';

const ViewVehicle = ({
  data,
  closeModal
}: {
  data: UnionDataInterface;
  closeModal: () => void;
}) => {
  // eslint-disable-next-line no-param-reassign
  const nData = data as VehicleDataInterface;
  const { activeTitle, prevTitle, handleActiveTitle } = useHandleActiveTitle('details');
  const [modifyData] = useState({ ...nData });
  const isMetric = useAppSelector(state => state.auth.isMetric);
  const customerId = useAppSelector(state => state.customer.id);
  const historyData = useSelector(history);
  const tripData = useSelector(trips);
  const dispatch = useDispatch();

  useEffect(() => {
    //@ts-ignore
    dispatch(fleetHistory({ id: modifyData.id, customerId }));
    dispatch(
      //@ts-ignore
      fleetTrips({
        date: {
          startDate: '',
          endDate: ''
        },
        toll: null,
        tripDistance: null,
        tripDuration: null,
        customerId: modifyData.customerId,
        pageIndex: 0,
        pageSize: 10,
        searchKey: modifyData.name,
        sort: {
          fieldName: '',
          order: ''
        }
      })
    );
  }, []);

  if (isMetric) {
    modifyData.height = nData.height && Math.round((nData.height / 39.37) * 100) / 100;
    modifyData.weight = nData.weight && Math.round((nData.weight / 2.205) * 100) / 100;
  } else {
    modifyData.height = nData.height;
    modifyData.weight = nData.weight;
  }
  modifyData.licensePlate = modifyData.licensePlate?.toUpperCase();
  modifyData.plateStateD = modifyData.plateState;
  modifyData.plateStateD = modifyData.plateState?.length > 0 ? modifyData.plateState : 'None';

  return (
    <UpdateVehicleFormik data={modifyData}>
      <Modal
        activeTitle={activeTitle}
        navigation={['details', 'trips', 'history', 'alerts']}
        customPrimaryButton={
          /* eslint-disable react/jsx-no-useless-fragment */
          activeTitle === 'details' ? <SubmitButton fleet={true} closeModal={closeModal} /> : <></>
        }
        customExtraButtons={<CrossFilter tableName={ETableName.FLEET} entryData={[data]} />}
        cancelButton={false}
        className={styles.modal}
        handleActiveTitle={handleActiveTitle}
        helpLocation="vehicle_"
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <MotionDiv activeTitle={activeTitle} prevTitle={prevTitle}>
            {activeTitle === 'details' && <UpdateVehicle updatedAt={nData.updatedAt} />}
            {activeTitle === 'trips' && <TripsList tripsData={tripData} />}
            {activeTitle === 'history' && <History historyData={historyData} />}
            {activeTitle === 'alerts' && <Alert commonTab vehicleId={data.id as string} />}
          </MotionDiv>
        </AnimatePresence>
      </Modal>
    </UpdateVehicleFormik>
  );
};

export default ViewVehicle;
