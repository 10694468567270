import { ETableName } from '@common/utils/enums';
import styles from './actions-block.module.scss';
import Actions from './actions/Actions';
import DisplayIndexes from './display-indexes/DisplayIndexes';
import { ActionsBlockProps } from './types';

const ActionsBlock = ({ tableName, datesFiltering }: ActionsBlockProps) => {
  return (
    <div
      className={styles.container}
      style={{ marginTop: `${tableName === ETableName.TRIPS ? '2rem' : ''}` }}
    >
      <DisplayIndexes tableName={tableName} />
      <Actions tableName={tableName} datesFiltering={datesFiltering} />
    </div>
  );
};

export default ActionsBlock;
