import { useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { EAuthRole } from '@common/utils/enums';
import { userCanAccessRoute } from '@common/services/auth';
import { routes } from '@common/utils/route-names';
import { setNavCollapsed } from '@store/features/helpers';
import { CustomersMenu } from './CustomersMenu/CustomersMenu';
import styles from './navigation.module.scss';
import TopNav from './TopNav';
import BottomNav from './BottomNav';
import NavControl from './NavControl';
import NavLogo from './NavLogo';

const Navigation = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);
  const [navCollapse, setNavCollapse] = useState(true);

  const handleNavCollapse = (value: boolean) => {
    setNavCollapse(value);
    dispatch(setNavCollapsed(value));
  };

  const navigationRef = useRef<HTMLDivElement>(null);
  useOnclickOutside(
    () => {
      setNavCollapse(true);
    },
    { refs: [navigationRef] }
  );
  const [notifications] = useState(['were']);

  return (
    <AnimatePresence>
      <motion.div
        initial={false}
        animate={{
          width: navCollapse ? '8.6rem' : '20.6rem'
        }}
        transition={{ duration: 0.3 }}
        layout
        ref={navigationRef}
        className={styles.container}
      >
        {auth?.id && (
          <menu className={styles.menu}>
            <nav className={styles.top}>
              <NavLogo />
              <TopNav
                navCollapse={navCollapse}
                handleNavCollapse={handleNavCollapse}
                authRole={auth?.app_role as EAuthRole}
              />
            </nav>
            <nav className={styles.bottom}>
              {userCanAccessRoute(routes.customers) && <CustomersMenu navCollapse={navCollapse} />}
              <BottomNav
                navCollapse={navCollapse}
                handleNavCollapse={handleNavCollapse}
                notifications={notifications}
                authRole={auth?.app_role as EAuthRole}
              />
            </nav>
          </menu>
        )}
        <NavControl navCollapse={navCollapse} handleNavCollapse={handleNavCollapse} />
      </motion.div>
    </AnimatePresence>
  );
};

export default Navigation;
