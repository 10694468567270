import { useState } from 'react';
import { Location, Navigate, useLocation } from 'react-router-dom';
import { PasswordAction, AuthBody } from '../../../layout/components';
import FormComp from '../Form/Form';
import styles from './card.module.scss';

const Card = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const handlePasswordChanged = (value: boolean) => setPasswordChanged(value);
  const location: Location = useLocation();
  if (!(location.state as { email: string })?.email) {
    return <Navigate to="/404" replace />;
  }

  return (
    <AuthBody
      className={`${styles.body} ${!passwordChanged ? styles.form : ''}`}
      title={passwordChanged ? 'Password Changed' : 'Reset password?'}
    >
      <section className={`${styles.container} ${!passwordChanged ? styles.form : ''}`}>
        {passwordChanged ? (
          <PasswordAction action="changed" />
        ) : (
          <FormComp
            handlePasswordChanged={handlePasswordChanged}
            email={(location.state as { email: string }).email}
          />
        )}
      </section>
    </AuthBody>
  );
};

export default Card;
