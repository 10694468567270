export function Calendar({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 3.99998H17V2.99998C17 2.73477 16.8946 2.48041 16.7071 2.29288C16.5196 2.10534 16.2652 1.99998 16 1.99998C15.7348 1.99998 15.4804 2.10534 15.2929 2.29288C15.1054 2.48041 15 2.73477 15 2.99998V3.99998H9V2.99998C9 2.73477 8.89464 2.48041 8.70711 2.29288C8.51957 2.10534 8.26522 1.99998 8 1.99998C7.73478 1.99998 7.48043 2.10534 7.29289 2.29288C7.10536 2.48041 7 2.73477 7 2.99998V3.99998H5C4.20435 3.99998 3.44129 4.31606 2.87868 4.87866C2.31607 5.44127 2 6.20434 2 6.99999V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V6.99999C22 6.20434 21.6839 5.44127 21.1213 4.87866C20.5587 4.31606 19.7956 3.99998 19 3.99998ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 9.99999H4V6.99999C4 6.73477 4.10536 6.48041 4.29289 6.29288C4.48043 6.10534 4.73478 5.99999 5 5.99999H7V6.99999C7 7.2652 7.10536 7.51956 7.29289 7.70709C7.48043 7.89463 7.73478 7.99999 8 7.99999C8.26522 7.99999 8.51957 7.89463 8.70711 7.70709C8.89464 7.51956 9 7.2652 9 6.99999V5.99999H15V6.99999C15 7.2652 15.1054 7.51956 15.2929 7.70709C15.4804 7.89463 15.7348 7.99999 16 7.99999C16.2652 7.99999 16.5196 7.89463 16.7071 7.70709C16.8946 7.51956 17 7.2652 17 6.99999V5.99999H19C19.2652 5.99999 19.5196 6.10534 19.7071 6.29288C19.8946 6.48041 20 6.73477 20 6.99999V9.99999Z" />
    </svg>
  );
}
