/* eslint-disable */
import { createTheme, useMediaQuery } from '@mui/material';
import Overcharges from './overcharges/overcharges';
import styles from './index.module.scss';
import DashboardHeader from './dashboard-header/dashoardHeader';
import TollInsights from './toll-insights/TollInsights';
import RecentAlerts from './recent-alerts/RecentAlerts';
import TripInsights from './trip-insights/TripInsights';
import PotentialSavings from './potential-savings/potentialSavings';

const HistoryDashboard = () => {
  let screensize = '';
  const theme = createTheme();
  // Define breakpoints
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  // Determine the current device size
  let currentSize = 'unknown';
  if (isXs) {
    currentSize = 'xs';
  } else if (isSm) {
    currentSize = 'sm';
  } else if (isMd) {
    currentSize = 'md';
  } else if (isLg) {
    currentSize = 'lg';
  } else if (isXl) {
    currentSize = 'xl';
  }
  console.log(`screen: ${currentSize}`);
  return (
    <div className={styles.container}>
      {/* <p>{currentSize}</p>   */}
      <DashboardHeader />
      <Overcharges isParentLoading={false} />
      <TollInsights selectedMonth="" />
      <PotentialSavings isParentLoading={false} />
      <TripInsights selectedMonth="" />
      <RecentAlerts />
    </div>
  );
};

export default HistoryDashboard;
