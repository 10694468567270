/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import { useAppDispatch } from '@store/hooks';
import { clearTable, ITable, setTable } from '@store/data';
import { setPagination } from '@store/features/pagination';

export const useSetTable = ({
  data,
  isLoading,
  isError,
  isSuccess,
  error,
  name,
  isFetching,
  totalPages,
  totalElements
}: ITable & { totalPages?: number; totalElements?: number }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (name) {
      dispatch(clearTable());
      dispatch(setTable({ data, isLoading, isError, isSuccess, error, name, isFetching }));
      dispatch(
        setPagination({
          table: name,
          dataLength: totalElements || 0,
          totalPages: totalPages || 0
        })
      );
    }
  }, [
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    name,
    isFetching,
    totalElements,
    totalPages,
    dispatch
  ]);
};
