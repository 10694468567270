import { UserDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<UserDataInterface>[] = [
  {
    heading: 'name',
    field: 'name',
    active: true,
    filterType: 'text',
    placeholder: 'search name'
  },
  {
    heading: 'email address',
    field: 'email',
    active: true,
    filterType: 'text',
    placeholder: 'search email'
  },
  {
    heading: 'role',
    field: 'app_role',
    active: true,
    filterType: 'text',
    placeholder: 'search role'
  },
  {
    heading: 'status',
    field: 'statusCode',
    active: false,
    filterType: 'text',
    placeholder: 'search status'
  },
  {
    heading: 'Last Login',
    field: 'lastLogin',
    active: true,
    filterType: 'text',
    placeholder: 'search last login'
  }
];
