import { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalOverlay from './ModalOverlay';
import { ModalProps } from './types';
import styles from './modal.module.scss';

const Modal: FC<ModalProps> = ({
  className = '',
  headerClassName,
  footerClassName,
  children,
  withBackButton,
  handleBackButtonClick,
  withCheckbox,
  title,
  checkboxLabel,
  navigation,
  onActionClick,
  isChecked,
  handleCheckbox,
  activeTitle,
  handleActiveTitle,
  primaryButton,
  cancelButton,
  customExtraButtons,
  customPrimaryButton,
  customSecondaryButton,
  hasHeader = true,
  hasFooter = true,
  customCloseFunc,
  helpLocation,
  selectAllElement
}) => {
  const user = useAppSelector(state => state.user);
  const [matches, setMatches] = useState<boolean>(window.matchMedia('(max-width: 1000px)').matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-height: 700px)');

    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQueryList.addEventListener('change', handleMediaQueryChange);
    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <ModalOverlay customCloseFunc={customCloseFunc}>
      <motion.div
        initial={{ opacity: 0, y: '50%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '50%' }}
        transition={{ duration: 0.4, ease: 'anticipate' }}
        className={`${styles.container} ${isGeotab ? styles['is-geotab'] : ''} ${
          matches ? styles['small-modal'] : ''
        } ${className}`}
      >
        {hasHeader && (
          <ModalHeader
            headerClassName={headerClassName}
            withBackButton={withBackButton}
            handleBackButtonClick={handleBackButtonClick}
            title={title}
            navigation={navigation}
            activeTitle={activeTitle}
            handleActiveTitle={handleActiveTitle}
            customCloseFunc={customCloseFunc}
            helpLocation={helpLocation}
            selectAllElement={selectAllElement}
          />
        )}
        {children}
        {hasFooter && (
          <ModalFooter
            footerClassName={footerClassName}
            isChecked={isChecked}
            withCheckbox={withCheckbox}
            checkboxLabel={checkboxLabel}
            handleCheckbox={handleCheckbox}
            primaryButton={primaryButton}
            onActionClick={onActionClick}
            cancelButton={cancelButton}
            customExtraButtons={customExtraButtons}
            customPrimaryButton={customPrimaryButton}
            customSecondaryButton={customSecondaryButton}
            customCloseFunc={customCloseFunc}
          />
        )}
      </motion.div>
    </ModalOverlay>
  );
};

export default Modal;
