import { TripDataInterface } from '@common/types/data';
import { useGetCalculatedTollsQuery } from '@api/services/trips';
import Loader from '@common/ui/Loader';
import { useTripsMap } from './hooks';
import Map from './Map';
import styles from './trips-map.module.scss';
import MapHeader from './MapHeader';
import MapFooter from './MapFooter/MapFooter';

const TripsMap = ({
  data,
  timezone,
  showHeader = true,
  showFooter = false,
  showFullScreenToggle = false
}: {
  data: TripDataInterface;
  timezone: string;
  showHeader?: boolean;
  showFooter?: boolean;
  showFullScreenToggle?: boolean;
}) => {
  const {
    altTrips,
    originalTrip,
    handleTripType,
    headerDetails,
    actualTripDetails,
    altTripDetails,
    tripType
  } = useTripsMap(data);
  const { data: estimatedTolls, isLoading } = useGetCalculatedTollsQuery(data.id, {
    refetchOnReconnect: true,
    skip: !data.id
  });

  const headerDetailsD = { ...headerDetails };

  return (
    <>
      <section className={styles.container}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <>
            {showHeader && (
              <MapHeader
                headerDetails={headerDetailsD}
                tripType={tripType}
                handleTripType={handleTripType}
                altTrips={altTrips}
              />
            )}
            <Map
              originalTrip={originalTrip}
              alternativeTrips={altTrips}
              showFullScreenToggle={showFullScreenToggle}
              trip={data}
              tripType={tripType}
              handleTripType={handleTripType}
              showTollMarkers
              tollData={estimatedTolls}
              timezone={timezone}
            />
          </>
        )}
      </section>
      {showFooter && (
        <MapFooter
          actualTrip={actualTripDetails}
          altTrip={altTripDetails}
          tripType={tripType}
          handleTripType={handleTripType}
          altTrips={altTrips}
          tollCostPerMinute={headerDetailsD.tollCostPerMinute}
          tollCostPerMile={headerDetailsD.tollCostPerMile}
        />
      )}
    </>
  );
};

export default TripsMap;
