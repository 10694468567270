export const timeMultipliers = {
  secs: {
    secs: 1,
    mins: 60,
    hrs: 60 * 60,
    days: 60 * 60 * 24
  },
  mins: {
    secs: 1 / 60,
    mins: 1,
    hrs: 60,
    days: 60 * 24
  },
  hrs: {
    secs: 1 / (60 * 60),
    mins: 1 / 60,
    hrs: 1,
    days: 24
  },
  days: {
    secs: 1 / (60 * 60 * 24),
    mins: 1 / (60 * 24),
    hrs: 1 / 24,
    days: 1
  }
};
